import React, { Component } from 'react'
import TableDragSelect from 'react-table-drag-select'
import data from './data.json'
import { Grid, Typography } from "@material-ui/core"
// Utilities
import theme from "../../../../utilities/theme"
import {
    convertTimeSlots,
    getTimeSlotsFromMatrix,
    getMatrixFromTimeSlots,
    convertTimeSlotsToDbFormat,
    convertTimeSlotsFromDbFormat
} from './methods'

import 'react-table-drag-select/style.css'
import './style.css';

const initialState = {
    cells: [
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
    ]
}
class AvailabilityScheduler extends Component {
    state = initialState

    componentDidMount = () => {
        let utcTimeSlots = this.props.value
        let cells = getMatrixFromTimeSlots(convertTimeSlots(convertTimeSlotsFromDbFormat(utcTimeSlots), 'local'))

        this.setState({
            cells
        })
    }

    onTableChange = (cells) => {
        this.setState({
            cells
        }, () => {
            const { statePath, handleInputChange } = this.props
            let updatedUtcTimeSlots = convertTimeSlotsToDbFormat(convertTimeSlots(getTimeSlotsFromMatrix(this.state.cells), 'UTC'))
            handleInputChange(this.props, { value: updatedUtcTimeSlots, statePath })
        })
    }

    render() {
        return (
            <Grid
                xs={12}
                item
                container
            >
                <Typography
                    variant='subtitle2'
                    gutterBottom
                    style={{ paddingLeft: 40, paddingRight: 40 }}
                >
                    Select Custom Time Entries From Below Table
                </Typography>
                <Grid
                    xs={12}
                    item
                    container
                    style={{ paddingLeft: 8, paddingRight: 16, maxHeight: '70vh', overflowY: 'scroll' }}
                >
                    <TableDragSelect
                        value={this.state.cells}
                        onChange={this.onTableChange}
                        className="table-drag-select"
                    >
                        <tr>
                            <td
                                disabled
                                width={32}
                                style={{ fontWeight: 'bold' }}
                            >
                                {""}
                            </td>
                            {data.daySlots.map((daySlot, index) => (
                                <td
                                    key={`daySlots-${index}`}
                                    disabled
                                    style={{ fontWeight: 'bold', textAlign: 'left', width: 32, paddingLeft: 8, backgroundColor: theme.palette.primary.main }}
                                >
                                    {daySlot.text}
                                </td>
                            ))}
                        </tr>
                        {data.timeSlots.map((timeSlot, index) => (
                            <tr
                                key={`timeSlots-${index}`}
                            >
                                <td
                                    disabled
                                    style={{ textAlign: 'right', width: 32, paddingRight: 8 }}
                                >
                                    {timeSlot.time}
                                </td>
                                {data.daySlots.map((daySlot, timeIndex) => (
                                    <td key={`timeDaySlots-${index}`} />
                                ))}
                            </tr>
                        ))}
                    </TableDragSelect>
                </Grid>
            </Grid>
        )
    }
}

export default AvailabilityScheduler