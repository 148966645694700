// utilities/sortingUtils.js
export const sortTableData = (data, column, sortOrder, isNested = false) => {
    const sortedData = [...data]

    sortedData.sort((a, b) => {
        let aValue, bValue

        if (isNested) {
            const nestedColumns = column.split('.') // Split the nested column string
            aValue = a
            bValue = b
            nestedColumns.forEach((nestedCol) => {
                aValue = aValue?.[nestedCol]
                bValue = bValue?.[nestedCol]
            })
        } else {
            aValue = a[column]
            bValue = b[column]
        }

        // Convert non-string values to strings for comparison
        if (typeof aValue !== 'string') {
            aValue = String(aValue)
        }
        if (typeof bValue !== 'string') {
            bValue = String(bValue)
        }
        if (typeof aValue === 'undefined' || typeof bValue === 'undefined') {
            // Handle undefined values here
            return 0 // Default behavior, you can customize this
        }

        if (sortOrder === 'asc') {
            return aValue.localeCompare(bValue)
        } else {
            return bValue.localeCompare(aValue)
        }
    })

    return sortedData
}