import { createTheme } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import theme from "./theme";

const GetTheme = (currentTheme) => {
    return createTheme({
        palette: {
            type: currentTheme === 'dark' ? 'dark' : 'light',
            primary: {
                light: '#FFFFFF',
                lightGrey: '#E8E8E8',
                main: currentTheme === 'dark' ? '#fff' : '#212121',
                grey: '#848585',
                dark: '#212121',
                darkGrey: '#4d4e4f',
                contrastText: '#fff'
            },
            secondary: {
                light: '#ff7961',
                main: orange[600],
                dark: orange[800],
                contrastText: '#ffffff'
            }
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '*::-webkit-scrollbar': {
                        width: '6px',
                    },
                    '*::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 5px #E8E8E8',
                    },
                    '*::-webkit-scrollbar-thumb': {
                        backgroundColor: '#848585',
                        borderRadius: '6px',
                    },
                    '*::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#212121',
                    },
                },
            },
            MuiButton: {
                root: {
                    fontWeight: 'bold'
                }
            },
            MuiTabs: {
                root: {
                    width: '100%',
                    marginBottom: 16
                },
                indicator: {
                    display: 'none'
                },
                flexContainer: {
                    width: '100%' // Ensure the tabs take up the full width
                }
            },
            MuiTab: {
                root: {
                    flexGrow: 1,
                    maxWidth: "none",
                    flexBasis: 0,
                    flexShrink: 1,
                    borderBottom: `2px solid ${currentTheme === 'dark' ? '#FFFFFF' : '#212121'}`,
                    borderRight: `2px solid ${currentTheme === 'dark' ? '#FFFFFF' : '#212121'}`,
                    height: '64px',
                    color: currentTheme === 'dark' ? '#FFFFFF' : '#212121',
                    backgroundColor: currentTheme === 'dark' ? 'rgb(245,245,245, .1)' : 'rgb(224,224,224, .4)',
                    '&$selected': {
                        borderTop: `2px solid ${currentTheme === 'dark' ? '#FFFFFF' : '#212121'}`,
                        borderBottom: '0px',
                        fontWeight: 'bold',
                        '&:last-child': {
                            borderRight: `2px solid ${currentTheme === 'dark' ? '#FFFFFF' : '#212121'}`
                        },
                        backgroundColor: currentTheme === 'dark' ? 'rgb(0,0,0, 0)' : 'rgb(0,0,0, 0)'
                    },
                    "&:hover": {
                        backgroundColor: currentTheme === 'dark' ? 'rgb(0,0,0, 0)' : 'rgb(0,0,0, 0)'
                    },
                    '&:last-child': {
                        borderRight: '0px'
                    },
                    '&:first-child': {
                        borderLeft: '0px'
                    }
                },
            },
            MuiTooltip: {
                tooltip: {
                    fontSize: "1em"
                },
            },
            MuiTextField: {
                root: {
                    '& label.Mui-focused': {
                        color: currentTheme === 'dark' ? '#FFFFFF' : '#212121'
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: currentTheme === 'dark' ? '#212121' : '#FFFFFF'
                    },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: currentTheme === 'dark' ? '#FFFFFF' : '#212121'
                        }
                    }
                }
            },
            MuiFormLabel: {
                root: {
                    color: currentTheme === 'dark' ? '#FFFFFF' : '#212121'
                }
            },
            MuiTableHead: {
                root: {
                    '& .MuiTableCell-head': {
                        fontWeight: 800, // Make the text bold
                    },
                },
            }
        },
        "&::-webkit-scrollbar": {
            width: 8
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.2)",
            outline: "1px solid slategrey",
            borderRadius: 7
        }
    })
}

export default GetTheme;

export const ScrollBarStyles = {
    "&::-webkit-scrollbar": {
        width: "6px"
    },

    "&::-webkit-scrollbar-track": {
        boxShadow: `inset 0 0 5px ${theme.palette.primary.lightGrey}`,
        //   borderRadius: "10px",
    },

    "&::-webkit-scrollbar-thumb": {
        backgroundColor: `${theme.palette.primary.grey}`,
        borderRadius: "6px"
    },

    "&::-webkit-scrollbar-thumb:hover": {
        background: `${theme.palette.primary.dark}`
    }
}