// React
import React from 'react'

// Material UI
import { Grid, Checkbox, Typography, CircularProgress, withStyles } from '@material-ui/core'


class GeneralCheckboxField extends React.Component {
    handleChange = (e) => {
        let value = e.target.checked
        const { statePath, handleInputChange } = this.props

        handleInputChange(this.props, { value, statePath })
    }

    render() {
        const props = this.props

        return (
            <Grid
                item
                style={props.gridStyle}
                xs={props.width || 6}
                display='flex'
            >
                <Grid
                    item
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    {props.loader ?
                        <CircularProgress
                            size={24}
                            style={{ padding: 0 }}
                        />
                        :
                        <Checkbox
                            disabled={props.disabled}
                            autoFocus={props.autoFocus}
                            id={props.id}
                            size={props.size ? props.size : 'small'}
                            onChange={this.handleChange}
                            checked={props.value}
                            color='primary'
                            style={{ padding: 2, marginRight: 0 }}
                        />
                    }
                    <Typography
                        variant={props.titleVariant || 'subtitle2'}
                    >
                        {props.label}
                    </Typography>
                </Grid>
                <Typography
                    variant='body1'
                >
                    {props.description}
                </Typography>
                {props.error &&
                    <p
                        style={{ color: '#ff0000', margin: 0, marginTop: 8 }}
                    >
                        {props.error}
                    </p>
                }
            </Grid>
        )
    }
}

export default withStyles(null, { withTheme: true })(GeneralCheckboxField)
