const DeepCopy = (arrayOrObject) => {
    let target = Array.isArray(arrayOrObject) ? [] : {}
    for (let key in arrayOrObject) {
      let v = arrayOrObject[key]
      if (v) {
        if (typeof v === "object") {
          target[key] = DeepCopy(v)
        } else {
          target[key] = v
        }
      } else {
        target[key] = v
      }
    }
  
    return target
}

export default DeepCopy;