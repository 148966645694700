import React, { Component } from 'react'
// Material UI
import { Grid, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Button, withStyles, CircularProgress, Chip } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import SettingsIcon from '@material-ui/icons/Settings'
import { withSnackbar } from 'notistack'
// Utilities
import axios from "axios"
import Fuse from 'fuse.js'
import { handleInputChange, handleErrors } from "../../../utilities/handleChange"
import { OpenSnackBar } from '../../../utilities/handleSnackBar'
// Components
import GeneralTextField from "../../general/TextField"
import CreateNewRadioChannelModal from './CreateNewRadioChannelModal'
import EditRadioChannelModal from './EditRadioChannelModal'

const initialState = {
    radioChannels: [],
    info: {
        search: ''
    },
    filteredRadioChannels: [],
    // departments: [],
    radioChannel: {
        description: "",
        radioChannelPort: null
    },
    errors: {
        radioChannel: {
            description: null,
            radioChannelPort: null
        },
        info: {}
    },
    modals: {
        createNewRadioChannel: false,
        editRadioChannel: false
    },
    loaders: {
        getRadioChannels: false,
        createNewRadioChannel: false,
        updateRadioChannel: false,
        deleteRadioChannel: false
    }
}

export class RadioChannels extends Component {

    state = initialState

    // Life cycle functions
    componentDidMount = async () => {
        await this.getRadioChannels()
    }

    componentWillUnmount = () => {
        this.props.closeSnackbar()
    }

    // API
    getRadioChannels = async () => {
        const { loaders } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.getRadioChannels) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getRadioChannels: true } }))
            try {
                const getRadioChannelsResponse = await axios.get('/api/radioChannel', config)
                if (getRadioChannelsResponse?.data?.success === true) {
                    let radioChannels = getRadioChannelsResponse.data.radioChannels
                    this.setState(prevState => ({
                        radioChannels,
                        filteredRadioChannels: radioChannels,
                        loaders: { ...prevState.loaders, getRadioChannels: false }
                    }))
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: getRadioChannelsResponse.data.errors },
                        loaders: { ...prevState.loaders, getRadioChannels: false }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getRadioChannels: false }
                }))
            }
        }
    }

    // General
    handleErrors = () => {
        const errors = { ...this.state.errors }
        const { radioChannel } = this.state
        const fields = [
            {
                path: 'radioChannel.description',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'radioChannel.radioChannelPort',
                type: 'number',
                shouldCheck: true,
                validation: !(radioChannel.radioChannelPort < 1939 || radioChannel.radioChannelPort > 2041),
                validationMessage: "Port should be between 1940 and 2041."

            }
        ]
        const frontEndErrors = handleErrors(this.state, errors, fields)
        return frontEndErrors
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleNumberInputChange = (e, data) => {
        this.setState(handleInputChange(e, { ...data, value: Number(data.value) }, this.state))
    }

    handleSearchInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state), () => {
            this.searchRadioChannels()
        })
    }

    handleModal = (modal, extra) => {
        const { modals } = this.state
        switch (modal) {
            case 'createNewRadioChannel':
                if (modals.createNewRadioChannel) {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, [modal]: !prevState.modals[modal] },
                        radioChannel: initialState.radioChannel,
                        errors: initialState.errors
                    }))
                } else {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, [modal]: !prevState.modals[modal] },
                        errors: initialState.errors
                    }))
                }
            case 'editRadioChannel':
                if (modals.editRadioChannel) {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, editRadioChannel: false },
                        radioChannel: initialState.radioChannel,
                        errors: initialState.errors
                    }))
                } else {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, editRadioChannel: true },
                        radioChannel: extra
                    }))
                }
            default:
                return this.setState(prevState => ({ modals: { ...prevState.modals, [modal]: !prevState.modals[modal] } }))
        }
    }

    searchRadioChannels = () => {
        const { search } = this.state.info
        const { radioChannels } = this.state

        if (search && search.length > 0) {
            const fuseResult = new Fuse(radioChannels, {
                keys: [
                    { name: 'description', weight: 0.8 }
                ],
                threshold: .5,
                includeMatches: true
            })
            let filteredRadioChannels = fuseResult.search(search).map(resultObject => {
                return {
                    ...resultObject.item,
                }
            })

            this.setState({ filteredRadioChannels })
        } else {
            this.setState({
                filteredRadioChannels: [...radioChannels]
            })
        }
    }

    resetSearch = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                search: ""
            }
        }), this.searchRadioChannels)
    }

    // CRUD
    createRadioChannel = async () => {
        let { loaders, radioChannel } = this.state

        if (!loaders.createNewRadioChannel) {

            const { success, errors } = this.handleErrors()
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, createNewRadioChannel: true } }))
                const { accessToken } = this.props
                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                try {
                    const createRadioChannelResponse = await axios.post('/api/radioChannel', radioChannel, config)
                    if (createRadioChannelResponse?.data?.success === true) {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, createNewRadioChannel: false }
                        }))
                        this.handleModal('createNewRadioChannel')
                        this.getRadioChannels()
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Channel created successfully", "success")
                    } else {
                        this.setState(prevState => ({
                            errors: { ...prevState.errors, messages: createRadioChannelResponse.data.errors },
                            loaders: { ...prevState.loaders, createNewRadioChannel: false }
                        }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, createNewRadioChannel: false }
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            } else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }
        }
    }

    updateRadioChannel = async () => {
        const { radioChannel, filteredRadioChannels, loaders } = this.state

        if (!loaders.updateRadioChannel) {
            const { success, errors } = this.handleErrors()
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, updateRadioChannel: true } }))

                let formattedRadioChannel = {
                    ...radioChannel
                }

                const config = {
                    headers: {
                        Authorization: this.props.accessToken
                    }
                }

                try {
                    let updateRadioChannelResponse = await axios.put(`/api/radioChannel/${radioChannel._id}`, formattedRadioChannel, config)

                    if (updateRadioChannelResponse?.data?.success === true) {
                        this.setState(prevState => ({ loaders: { ...prevState.loaders, updateRadioChannel: false } }))
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Channel updated successfully", "success")
                        let updatedRadioChannels = filteredRadioChannels.map(radioChannelObject => {
                            if (radioChannelObject._id === radioChannel._id) {
                                return {
                                    ...radioChannel,
                                }
                            } else {
                                return radioChannelObject
                            }
                        })
                        this.setState(prevState => ({
                            radioChannels: updatedRadioChannels,
                            filteredRadioChannels: updatedRadioChannels
                        }), () => this.getRadioChannels())
                        return true
                    } else {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, updateRadioChannel: false },
                        }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, updateRadioChannel: false },
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            }
            else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }

        }
    }

    deleteRadioChannel = async () => {
        const { loaders, radioChannel } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.deleteRadioChannel) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, deleteRadioChannel: true } }))
            try {
                const deleteRadioChannelResponse = await axios.delete(`/api/radioChannel/${radioChannel._id}`, config)
                if (deleteRadioChannelResponse?.data?.success === true) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, deleteRadioChannel: false }
                    }))
                    this.handleModal('editRadioChannel')
                    this.getRadioChannels()
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Channel deleted successfully", "success")
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: deleteRadioChannelResponse.data.errors },
                        loaders: { ...prevState.loaders, deleteRadioChannel: false }
                    }))
                }
            } catch (e) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, deleteRadioChannel: false }
                }))
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
            }
        }
    }

    render() {
        const { authenticatedUser } = this.props
        const { radioChannel, errors, modals, loaders, info, filteredRadioChannels } = this.state
        return (
            <>
                {modals.createNewRadioChannel &&
                    <CreateNewRadioChannelModal
                        handleInputChange={this.handleInputChange}
                        handleNumberInputChange={this.handleNumberInputChange}
                        handleClose={() => this.handleModal('createNewRadioChannel')}
                        createRadioChannel={this.createRadioChannel}
                        authenticatedUser={authenticatedUser}
                        radioChannel={radioChannel}
                        errors={errors}
                        loaders={loaders}
                        open={modals.createNewRadioChannel}
                    />
                }
                {modals.editRadioChannel &&
                    <EditRadioChannelModal
                        handleInputChange={this.handleInputChange}
                        handleNumberInputChange={this.handleNumberInputChange}
                        handleClose={() => this.handleModal('editRadioChannel')}
                        deleteRadioChannel={this.deleteRadioChannel}
                        radioChannel={radioChannel}
                        errors={errors}
                        loaders={loaders}
                        open={modals.editRadioChannel}
                        updateRadioChannel={this.updateRadioChannel}
                        authenticatedUser={authenticatedUser}
                    />
                }
                <Grid
                    xs={12}
                    container
                    style={{
                        display: 'flex', flex: 1, justifyContent: 'space-between', marginTop: 8,
                        borderRadius: 0,
                        marginBottom: 12
                    }}
                >
                    <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}
                    >
                        <Button
                            onClick={() => this.handleModal('createNewRadioChannel')}
                            variant='outlined'
                        >
                            <AddBoxIcon
                                style={{ marginRight: 8 }}
                            />
                            Add Radio Channel
                        </Button>
                    </Grid>
                    <Grid
                        style={{ display: 'flex', paddingRight: 24 }}
                        item
                    >
                        <GeneralTextField
                            format="search"
                            gridStyle={{ width: 350 }}
                            placeholder='Search radio channels...'
                            statePath={`info.search`}
                            value={info.search}
                            handleInputChange={this.handleSearchInputChange}
                            resetSearch={this.resetSearch}
                        />
                    </Grid>
                </Grid>
                <TableContainer
                    component={Paper}
                    elevation={0}
                    style={{
                        borderRadius: 0,
                        marginBottom: 64
                    }}
                >
                    <Table
                        style={{ minWidth: 650 }}
                        aria-label='Radio Channels'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Description
                                </TableCell>
                                <TableCell>
                                    Departments
                                </TableCell>
                                <TableCell>
                                    Port
                                </TableCell>
                                <TableCell
                                >
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loaders.getRadioChannels &&
                                <TableRow>
                                    <TableCell
                                        style={{ paddingTop: 96, paddingBottom: 96 }}
                                        colSpan={6}
                                        align="center"
                                    >
                                        <CircularProgress
                                            size={36}
                                        />

                                    </TableCell>
                                </TableRow>
                            }
                            {!loaders.getRadioChannels && filteredRadioChannels.map((channel) =>
                                <TableRow
                                    key={channel?._id}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.handleModal('editRadioChannel', channel)}
                                >
                                    <TableCell
                                        component='th'
                                    >
                                        {channel.description}
                                    </TableCell>
                                    <TableCell
                                    >
                                        {!!channel.departments?.length ? channel.departments.map(dept => dept).join(', ') : '--'}
                                    </TableCell>
                                    <TableCell
                                        align='right'
                                        width='10'
                                    >
                                        {channel.radioChannelPort}
                                    </TableCell>
                                    <TableCell
                                        align='right'
                                        width='30'
                                    >
                                        {channel.live ?
                                            <Chip
                                                label='Active'
                                                style={{ backgroundColor: '#00b300', color: '#fff' }}
                                            />
                                            :
                                            <Chip
                                                label='Inactive'
                                                style={{ backgroundColor: '#ff0000', color: '#fff' }}
                                            />
                                        }
                                    </TableCell>
                                    <TableCell
                                        align='right'
                                        width='10'
                                    >
                                        <IconButton
                                            size='small'
                                            disableFocusRipple={true}
                                            disableRipple={true}
                                        >
                                            <SettingsIcon />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
}

export default withSnackbar(withStyles(null, { withTheme: true })(RadioChannels))