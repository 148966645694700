import { TransformToFrontendPhoneNumber } from '../utilities/Validate'

export const getAddressString = (address) => {
  return `${address.addressLine1}${address.addressLine2 ? ` ${address.addressLine2} ` : ' '}${address.city}, ${address.state} ${address.zip}`
}

export const maskPhoneNumber = (number) => {
  try {
    let x = TransformToFrontendPhoneNumber(number).replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/)
    return '(' + x[1] + ') ' + x[2] + '-' + x[3]
  }
  catch (e) {
    return number
  }
}

const mediaQuery = window.matchMedia('(max-width: 767px)')

export const IsMobile = () => {
  return mediaQuery.matches
}