import ApiComponent from '../components/api'
import FacebookComponent from '../components/facebook'

const IntegrationTab = ({
    department, update, authenticatedUser, socket, handleInputChange,
    loaders,
    handleAddFacebookPage,
    handleDeleteFacebookPage
}) => {
    return (
        <>
            <ApiComponent
                authenticatedUser={authenticatedUser}
                department={department}
                update={update}
                socket={socket}
            />
            <FacebookComponent
                department={department}
                loaders={loaders}
                handleInputChange={handleInputChange}
                handleAddFacebookPage={handleAddFacebookPage}
                handleDeleteFacebookPage={handleDeleteFacebookPage}
            />
        </>
    )
}

export default IntegrationTab;
