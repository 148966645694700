import moment from 'moment'

const getUtcOffsetThreshold = () => {
    //this function returns to offset in minutes from UTC to locale timezone
    //we will subtract it from user seleted times to get UTC time to store in db
    let toBeSubtractedFromTime = moment().utcOffset() / 60
    return toBeSubtractedFromTime
}

const formatTimeToHHMM = (time) => {
    return `${("0" + time).slice(-2)}:00`
}

const getDay = (colIndex) => {
    switch (colIndex) {
        case 1:
            return "sunday"
        case 2:
            return "monday"
        case 3:
            return "tuesday"
        case 4:
            return "wednesday"
        case 5:
            return "thursday"
        case 6:
            return "friday"
        case 7:
            return "saturday"
        default:
            return "sunday"
    }
}

const getDayIndex = (day) => {
    switch (day) {
        case "sunday":
            return 1
        case "monday":
            return 2
        case "tuesday":
            return 3
        case "wednesday":
            return 4
        case "thursday":
            return 5
        case "friday":
            return 6
        case "saturday":
            return 7
        default:
            return 1
    }
}

const incrementDay = (dayIndex) => {
    let d = dayIndex+1
    if (d === 8) d = 1
    return d
}

const decrementDay = (dayIndex) => {
    let d = dayIndex-1
    if (d === 0) d = 7
    return d
}

const convertTimeSlots = (timeSlots, to) => {
    let utcTimeSlots = []
    let toBeSubtractedFromTime
    if (to === 'UTC') {
        // convert to utc by actual offset
        toBeSubtractedFromTime = getUtcOffsetThreshold()
    }
    else {
        //convert to local by negating the offset
        toBeSubtractedFromTime = -1*getUtcOffsetThreshold()
    }

    timeSlots.forEach(timeSlot => {
        
        let startTime = timeSlot.startTime - toBeSubtractedFromTime
        let endTime = timeSlot.endTime - toBeSubtractedFromTime
        let day = timeSlot.day

        if (startTime < 0 && endTime <= 0) {
            //move the whole time entry to previous day
            utcTimeSlots.push(
                {
                    startTime: (startTime + 24) % 24,
                    endTime: (endTime + 24) % 24 || 24,
                    day: decrementDay(day)
                }
            )
        }
        else if (startTime < 0) {
            utcTimeSlots.push(
                {
                    startTime: (startTime + 24) % 24,
                    endTime: 24,
                    day: decrementDay(day)
                },
                {
                    startTime: 0,
                    endTime: (endTime + 24) % 24 || 24,
                    day: day
                }
            )
        }
        else if (startTime >= 24 && endTime > 24) {
            utcTimeSlots.push(
                {
                    startTime: startTime % 24,
                    endTime: endTime % 24 || 24,
                    day: incrementDay(day)
                }
            )
        }
        else if (endTime > 24) {
            utcTimeSlots.push(
                {
                    startTime: startTime % 24,
                    endTime: 24,
                    day: day
                },
                {
                    startTime: 0,
                    endTime: endTime % 24 || 24,
                    day: incrementDay(day)
                }
            )
        }
        else {
            utcTimeSlots.push(
                {
                    startTime: startTime,
                    endTime: endTime || 24,
                    day: day
                }
            )
        }
    })

    return utcTimeSlots
}

const getTimeSlotsFromMatrix = (matrix) => {
    const getLocaleTimeEntry = (rowIndex, colIndex) => {
        
        let day = colIndex
        let startTime = rowIndex - 1
        let endTime = rowIndex
    
        return {
            day,
            startTime,
            endTime
        }
    }

    let timeSlots = []

    matrix.forEach((row, rowIndex) => {
        if (rowIndex === 0) {
            return
        }
        row.forEach((col, colIndex) => {
            if (colIndex === 0) {
                return
            }

            if (col) {
                let timeSlot = getLocaleTimeEntry(
                    rowIndex,
                    colIndex
                )
                timeSlots.push(timeSlot)
            }
        })
    })
    return timeSlots
}

const getMatrixFromTimeSlots = (timeSlots) => {
    let matrix = [
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false],
    ]

    timeSlots.forEach(slot => {
        matrix[slot.startTime+1][slot.day] = true
    })

    return matrix

}

const convertTimeSlotsToDbFormat = (timeSlots) => {
    let convertedTimeSlots = []

    timeSlots.forEach(timeSlot => {
        convertedTimeSlots.push({
            startTime: formatTimeToHHMM(timeSlot.startTime),
            endTime: formatTimeToHHMM(timeSlot.endTime),
            day: getDay(timeSlot.day)
        })
    })

    return convertedTimeSlots
}

const convertTimeSlotsFromDbFormat = (timeSlots) => {
    let convertedTimeSlots = []

    timeSlots.forEach(timeSlot => {
        convertedTimeSlots.push({
            startTime: parseInt(timeSlot.startTime),
            endTime: parseInt(timeSlot.endTime),
            day: getDayIndex(timeSlot.day)
        })
    })

    return convertedTimeSlots
}

export {
    getUtcOffsetThreshold,
    formatTimeToHHMM,
    getDay,
    getDayIndex,
    incrementDay,
    decrementDay,
    convertTimeSlots,
    getTimeSlotsFromMatrix,
    getMatrixFromTimeSlots,
    convertTimeSlotsToDbFormat,
    convertTimeSlotsFromDbFormat
};