import { Grid } from '@material-ui/core'
import { getResponderIconAndTextColorHex, shouldRenderMap } from './utils'

const DispatchStaticMap = ({ dispatchLocation, responders, style }) => {
    if (!shouldRenderMap(dispatchLocation, responders)) return null

    const StaticMapImageUrl = constructStaticMapImageUrl(dispatchLocation, responders)
    return (
        <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <Grid style={{ width: '300px', height: '300px', ...style }}>
                <img
                    alt="Emergency Location"
                    src={StaticMapImageUrl}
                    style={{ width: '300px', height: '300px' }}
                />
            </Grid>
        </Grid>
    )
}

export default DispatchStaticMap;

const truncateCoordinatesTo6DecimalPlaces = (latOrLong) => {
    return Number(latOrLong.toFixed(6))
}

const addRespondersLayersByResponseType = (geoJson, responders, center) => {
    if (!responders?.length) return
    const respondersWithCoordinates = responders.filter(resp => typeof resp?.coordinates?.latitude === 'number')

    if (!respondersWithCoordinates.length) return

    const respondingToScene = respondersWithCoordinates.filter(resp => (resp?.responderStatus === 'scene' || resp?.responderStatus === 'arrivedAtScene'))
    if (respondingToScene.length) {
        if (!center.latitude) {
            center.longitude = respondingToScene[0].coordinates.longitude
            center.latitude = respondingToScene[0].coordinates.latitude
        }
        const respondingToSceneLayer = {
            "type": "Feature",
            "properties": {
                "marker-size": "small",
                "marker-color": getResponderIconAndTextColorHex(false, { responderStatus: 'scene' }),
            },
            "geometry": {
                "coordinates": respondingToScene.length > 1 ?
                    respondingToScene.map(resp => ([truncateCoordinatesTo6DecimalPlaces(resp.coordinates.longitude), truncateCoordinatesTo6DecimalPlaces(resp.coordinates.latitude)])) :
                    [truncateCoordinatesTo6DecimalPlaces(respondingToScene[0].coordinates.longitude), truncateCoordinatesTo6DecimalPlaces(respondingToScene[0].coordinates.latitude)],
                "type": respondingToScene.length > 1 ? "MultiPoint" : 'Point'
            }
        }
        geoJson.features.push(respondingToSceneLayer)
    }

    if (!center.latitude) { // if no one is responding to scene, use coordinates of any other responder
        center.longitude = respondersWithCoordinates[0].coordinates.longitude
        center.latitude = respondersWithCoordinates[0].coordinates.latitude
    }
    const respondingToStation = respondersWithCoordinates.filter(resp => resp?.responderStatus === 'station')
    if (respondingToStation.length) {
        const respondingToStationLayer = {
            "type": "Feature",
            "properties": {
                "marker-size": "small",
                "marker-color": getResponderIconAndTextColorHex(false, { responderStatus: 'station' }),
            },
            "geometry": {
                "coordinates": respondingToStation.length > 1 ?
                    respondingToStation.map(resp => ([truncateCoordinatesTo6DecimalPlaces(resp.coordinates.longitude), truncateCoordinatesTo6DecimalPlaces(resp.coordinates.latitude)])) :
                    [truncateCoordinatesTo6DecimalPlaces(respondingToStation[0].coordinates.longitude), truncateCoordinatesTo6DecimalPlaces(respondingToStation[0].coordinates.latitude)],
                "type": respondingToStation.length > 1 ? "MultiPoint" : 'Point'
            }
        }
        geoJson.features.push(respondingToStationLayer)
    }

    const respondingStandby = respondersWithCoordinates.filter(resp => resp?.responderStatus === 'standingBy')
    if (respondingStandby.length) {
        const respondingStandbyLayer = {
            "type": "Feature",
            "properties": {
                "marker-size": "small",
                "marker-color": getResponderIconAndTextColorHex(false, { responderStatus: 'standBy' }),
            },
            "geometry": {
                "coordinates": respondingStandby.length > 1 ?
                    respondingStandby.map(resp => ([truncateCoordinatesTo6DecimalPlaces(resp.coordinates.longitude), truncateCoordinatesTo6DecimalPlaces(resp.coordinates.latitude)])) :
                    [truncateCoordinatesTo6DecimalPlaces(respondingStandby[0].coordinates.longitude), truncateCoordinatesTo6DecimalPlaces(respondingStandby[0].coordinates.latitude)],
                "type": respondingStandby.length > 1 ? "MultiPoint" : 'Point'
            }
        }
        geoJson.features.push(respondingStandbyLayer)
    }

    const respondersWithOnlyLocation = respondersWithCoordinates.filter(resp => !(['scene', 'arrivedAtScene', 'station', 'standingBy'].includes(resp?.responderStatus)))
    if (respondersWithOnlyLocation.length) {
        const respondersWithOnlyLocationLayer = {
            "type": "Feature",
            "properties": {
                "marker-size": "small",
                "marker-color": getResponderIconAndTextColorHex(false, { responderStatus: null }),
            },
            "geometry": {
                "coordinates": respondersWithOnlyLocation.length > 1 ?
                    respondersWithOnlyLocation.map(resp => ([truncateCoordinatesTo6DecimalPlaces(resp.coordinates.longitude), truncateCoordinatesTo6DecimalPlaces(resp.coordinates.latitude)])) :
                    [truncateCoordinatesTo6DecimalPlaces(respondersWithOnlyLocation[0].coordinates.longitude), truncateCoordinatesTo6DecimalPlaces(respondersWithOnlyLocation[0].coordinates.latitude)],
                "type": respondersWithOnlyLocation.length > 1 ? "MultiPoint" : 'Point'
            }
        }
        geoJson.features.push(respondersWithOnlyLocationLayer)
    }
}

const constructStaticMapImageUrl = (dispatchLocation, responders) => {
    /* note: static url has a size limit of 8192 chars, therefore we can have max of 200 responders in geojson */
    const baseUrl = 'https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/'
    const mapSizeAndPadding = '300x300?'
    const accessTokenString = `access_token=pk.eyJ1IjoiZmlyZXRleHQiLCJhIjoiY2ptejZ3dnY1MHQyMDNwcGNlcTZ0NmNxMiJ9.k2hOGHWI3WvYZuryoOODhg`
    const geoJson = {
        "type": "FeatureCollection",
        "features": []
    }
    let center = {
        longitude: null,
        latitude: null,
    }
    addRespondersLayersByResponseType(geoJson, responders, center)
    if (dispatchLocation?.coordinates?.latitude) {
        center.longitude = truncateCoordinatesTo6DecimalPlaces(dispatchLocation.coordinates.longitude)
        center.latitude = truncateCoordinatesTo6DecimalPlaces(dispatchLocation.coordinates.latitude)
        geoJson.features.push({
            "type": "Feature",
            "properties": {
                "type": 'dispatchLocation',
                "marker-url": 'https://firetext.s3.amazonaws.com/emgcyLoc.png',
                "marker-size": "small",
            },
            "geometry": {
                "coordinates": [truncateCoordinatesTo6DecimalPlaces(dispatchLocation.coordinates.longitude), truncateCoordinatesTo6DecimalPlaces(dispatchLocation.coordinates.latitude)],
                "type": "Point"
            }
        })
    }

    const mapCenter = `${center.longitude},${center.latitude},14/`
    const encodedGeoJson = `geojson(${encodeURIComponent(JSON.stringify(geoJson))})/`
    const StaticMapImageUrl = baseUrl + encodedGeoJson + mapCenter + mapSizeAndPadding + accessTokenString
    return StaticMapImageUrl
}