import { forwardRef } from 'react'
// Material UI
import { Grid, Typography, IconButton, Button, Tooltip } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import RemoveIcon from '@material-ui/icons/Remove'
// Components
import GeneralDropdownField from '../../../general/DropdownField'
import { filterDepartments } from '../utils'

const CallTypesTab = ({
    department, errors, loaders,
    departmentOptions, nonDefaultCallTypes, defaultCallTypes,
    handleInputChange, handleAddCallType, handleDeleteCallType
}, focusRef) => {

    const departmentCallTypeIds = department.callTypes.map(callType => callType.callTypeId)
    const callTypeOptions = [...nonDefaultCallTypes, ...defaultCallTypes].map(callType => {
        if (departmentCallTypeIds.includes(callType.value))
            return { ...callType, disabled: true }
        return callType
    })

    return (
        <Grid
            xs={12}
            spacing={2}
            container
        >
            <Grid
                xs={12}
                container
                item
                style={{ marginTop: 16 }}
            >
                <Typography
                    variant='h6'
                >
                    Configure Call Types
                </Typography>
                <Tooltip
                    style={{ marginLeft: 8 }}
                    title='Configure the available call types for this department. Some call types are attached to every department and only the mutual aid departments can be updated for that call type.'
                >
                    <HelpIcon />
                </Tooltip>
            </Grid>
            {department.callTypes.map((callType, index) => {
                return (
                    <>
                        <GeneralDropdownField
                            focusRef={index === 0 ? focusRef : null}
                            key={department.callTypes[index].callTypeId}
                            options={callTypeOptions}
                            format='autocomplete'
                            width={department.allCall ? department.callTypes.length === 0 ? 12 : 11 : 6}
                            label={`Call Type`}
                            hideLabel={index !== 0}
                            placeholder='Select a department...'
                            statePath={`department.callTypes.${index}.callTypeId`}
                            value={department.callTypes[index].callTypeId}
                            handleInputChange={handleInputChange}
                            error={errors.department.callTypes[index].callTypeId}
                        />
                        {!department.allCall &&
                            <GeneralDropdownField
                                format='multiple'
                                options={departmentOptions.map(dept => (department._id === dept.value || department.callTypes[index].mutualAid.includes(dept.value)) ? { ...dept, disabled: true } : dept)}
                                width={department.callTypes.length > 1 ? 5 : 6}
                                label='Mutual Aid'
                                hideLabel={index !== 0}
                                placeholder='Select departments...'
                                statePath={`department.callTypes.${index}.mutualAid`}
                                value={filterDepartments(departmentOptions, department.callTypes[index].mutualAid)}
                                handleInputChange={handleInputChange}
                            />
                        }
                        {(department.callTypes.length > 1) &&
                            <Grid
                                item
                                xs={1}
                                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
                            >
                                <IconButton
                                    aria-label='close'
                                    onClick={() => handleDeleteCallType(index)}
                                    disabled={loaders.createNewDepartment}
                                >
                                    <RemoveIcon style={{ color: '#ff0000' }} />
                                </IconButton>
                            </Grid>
                        }
                    </>
                )
            })}
            <Grid
                item
                xs={12}
            >
                <Button
                    onClick={handleAddCallType}
                    disabled={loaders.createNewDepartment}
                    color='primary'
                    variant='outlined'
                >
                    Add Another Call Type
                </Button>
            </Grid>
        </Grid>
    )
}

export default forwardRef(CallTypesTab);
