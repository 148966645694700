import React from 'react'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import { Grid, TextField, Typography } from '@material-ui/core'
import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'

export const DATE_FORMAT = {
    milliSeconds: 'milliSeconds', // milliSeconds: 1695249000000
    dateString: 'dateString', // dateString (default): Thu Sep 21 2023 03:30:00 GMT+0500 (Pakistan Standard Time)
}

const getDateStringFromMilliSeconds = (ms) => ms ? new Date(ms) : null

const getMilliSecondsFromDateString = (ds) => ds ? moment(ds).valueOf() : null

const DateTimePickerWrapper = (props) => {
    const { label, labelStyle, value, statePath, minDate, format, placeholder, disabled, autoFocus, margin, size, variant, error, width, handleInputChange } = props

    const handleDateChange = (dateString) => {
        if (format === DATE_FORMAT.milliSeconds)
            handleInputChange(props, { value: getMilliSecondsFromDateString(dateString), statePath })
        else
            handleInputChange(props, { value: dateString, statePath })
    }

    const selectedDate = format === DATE_FORMAT.milliSeconds ? getDateStringFromMilliSeconds(value) : value

    return (
        <Grid
            container
            item
            xs={width || 12}
        >
            <Grid
                item
                xs={12}
            >
                <Typography
                    variant='subtitle2'
                    style={labelStyle}
                    gutterBottom
                >
                    {label}
                </Typography>
            </Grid>
            <Grid
                container
                item
                xs={12}
            >
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MM/dd/yyyy h:mm aa"
                    minDate={minDate || new Date().setDate(new Date().getDate() + 1)}
                    timeCaption="Time"
                    placeholderText={placeholder}
                    customInput={
                        <TextField
                            width={12}
                            disabled={disabled}
                            autoFocus={autoFocus}
                            margin={margin}
                            placeholder={placeholder}
                            size={size ? size : 'small'}
                            variant={!variant ? 'outlined' : variant}
                            defaultValue={selectedDate}
                            style={{ width: '100%' }}
                        />
                    }
                    style={{ width: '100%' }}
                />

                {error &&
                    <p
                        style={{ color: '#ff0000', margin: 0, marginTop: 8 }}
                    >
                        {error}
                    </p>
                }
            </Grid>
        </Grid>
    )
}

export default DateTimePickerWrapper;