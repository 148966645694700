import { Component } from 'react'
// Material UI
import { Grid, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button, withStyles, CircularProgress } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { withSnackbar } from 'notistack'
// Utilities
import axios from "axios"
import Fuse from 'fuse.js'
import { handleInputChange, handleErrors } from "../../../utilities/handleChange"
import { OpenSnackBar } from '../../../utilities/handleSnackBar'

// Components
import GeneralTextField from "../../general/TextField"
import CreateNewFiretextAnnouncementModalWrapper from './createNewFiretextAnnouncementModal'
import EditFiretextAnnouncementModal from './editFiretextAnnouncementModal'
import moment from 'moment-timezone'

const initialState = {
    firetextAnnouncements: [],
    info: {
        search: ''
    },
    filteredFiretextAnnouncements: [],
    firetextAnnouncement: {
        text: '',
        departmentId: null,
        expiresMilli: null,
        sendPushNotification: true,
        sendEmailNotification: false,
    },
    errors: {
        firetextAnnouncement: {
            text: null,
            expiresMilli: null
        },
        info: {

        }
    },
    modals: {
        createNewFiretextAnnouncement: false,
        editFiretextAnnouncement: false
    },
    loaders: {
        getFiretextAnnouncements: false,
        createNewFiretextAnnouncement: false,
        updateFiretextAnnouncement: false,
        deleteFiretextAnnouncement: false
    }
}


class FiretextAnnouncement extends Component {

    state = initialState

    // Life cycle functions
    componentDidMount = async () => {
        await this.getFiretextAnnouncements()
    }

    componentWillUnmount = () => {
        this.props.closeSnackbar()
    }

    // General
    handleErrors = () => {
        let errors = { ...this.state.errors }

        let fields = [
            {
                path: 'firetextAnnouncement.text',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'firetextAnnouncement.expiresMilli',
                type: 'number',
                shouldCheck: true
            }
        ]

        let frontEndErrors = handleErrors(this.state, errors, fields)
        return frontEndErrors
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleSearchInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state), () => {
            this.searchFiretextAnnouncements()
        })
    }

    handleModal = (modal, extra) => {
        const { modals } = this.state
        switch (modal) {
            case 'createNewFiretextAnnouncement':
                if (modals.createNewFiretextAnnouncement) {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, [modal]: !prevState.modals[modal] },
                        firetextAnnouncement: initialState.firetextAnnouncement,
                        errors: initialState.errors
                    }))
                } else {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, [modal]: !prevState.modals[modal] },
                        errors: initialState.errors
                    }))
                }
            case 'editFiretextAnnouncement':
                if (extra?.isFacebookAnnouncement) {
                    return window.open(`https://facebook.com/${extra.pageId}`, '_blank')
                }

                if (modals.editFiretextAnnouncement) {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, editFiretextAnnouncement: false },
                        firetextAnnouncement: initialState.firetextAnnouncement,
                        errors: initialState.errors
                    }))
                } else {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, editFiretextAnnouncement: true },
                        firetextAnnouncement: extra
                    }))
                }
            default:
                return this.setState(prevState => ({ modals: { ...prevState.modals, [modal]: !prevState.modals[modal] } }))
        }
    }

    searchFiretextAnnouncements = () => {
        const { search } = this.state.info
        const { firetextAnnouncements } = this.state

        if (search && search.length > 0) {
            const fuseResult = new Fuse(firetextAnnouncements, {
                keys: [
                    { name: 'text', weight: 1.0 }
                ],
                threshold: 0.3,
            })
            let filteredFiretextAnnouncements = fuseResult.search(search).map(resultObject => {
                return {
                    ...resultObject.item,
                }
            })

            this.setState({ filteredFiretextAnnouncements })
        } else {
            this.setState({
                filteredFiretextAnnouncements: [...firetextAnnouncements]
            })
        }
    }

    resetSearch = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                search: ""
            }
        }), this.searchFiretextAnnouncements)
    }

    // CRUD

    getFiretextAnnouncements = async () => {
        const { loaders } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            },
            params: { queryType: 'all' }
        }

        if (!loaders.getFiretextAnnouncements) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getFiretextAnnouncements: true } }))
            try {
                const getFiretextAnnouncementsResponse = await axios.get('/api/firetextAnnouncements', config)
                if (getFiretextAnnouncementsResponse && getFiretextAnnouncementsResponse.data && getFiretextAnnouncementsResponse.data.success) {
                    let firetextAnnouncements = getFiretextAnnouncementsResponse.data.firetextAnnouncements
                    this.setState(prevState => ({
                        firetextAnnouncements,
                        filteredFiretextAnnouncements: firetextAnnouncements,
                        loaders: { ...prevState.loaders, getFiretextAnnouncements: false }
                    }))
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: getFiretextAnnouncementsResponse.data.errors },
                        loaders: { ...prevState.loaders, getFiretextAnnouncements: false }
                    }))
                }
            } catch (error) {
                // console.log(error)
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getFiretextAnnouncements: false }
                }))
            }
        }
    }

    createFiretextAnnouncement = async () => {
        let { loaders, firetextAnnouncement } = this.state

        if (!loaders.createNewFiretextAnnouncement) {

            const { success, errors } = this.handleErrors()
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, createNewFiretextAnnouncement: true } }))
                const { accessToken, enqueueSnackbar, closeSnackbar } = this.props
                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                try {
                    const formattedFiretextAnnouncement = {
                        ...firetextAnnouncement,
                        departmentId: firetextAnnouncement.departmentId === 'global' ? null : firetextAnnouncement.departmentId
                    }
                    const createNewFiretextAnnouncementResponse = await axios.post('/api/firetextAnnouncements', formattedFiretextAnnouncement, config)
                    if (createNewFiretextAnnouncementResponse && createNewFiretextAnnouncementResponse.data && createNewFiretextAnnouncementResponse.data.success) {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, createNewFiretextAnnouncement: false }
                        }))
                        this.handleModal('createNewFiretextAnnouncement')
                        this.getFiretextAnnouncements()
                        OpenSnackBar(enqueueSnackbar, closeSnackbar, "Announcement added successfully", "success")
                    } else {
                        this.setState(prevState => ({
                            errors: { ...prevState.errors, messages: createNewFiretextAnnouncementResponse.data.errors },
                            loaders: { ...prevState.loaders, createNewFiretextAnnouncement: false }
                        }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, createNewFiretextAnnouncement: false }
                    }))
                    OpenSnackBar(enqueueSnackbar, closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            } else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }
        }
    }

    updateFiretextAnnouncement = async (type) => {
        const { firetextAnnouncement, filteredFiretextAnnouncements, loaders } = this.state

        if (!loaders.updateFiretextAnnouncement) {
            const { success, errors } = this.handleErrors()
            // console.log(errors)
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, updateFiretextAnnouncement: true } }))

                let formattedFiretextAnnouncement = {
                    ...firetextAnnouncement
                }

                const config = {
                    headers: {
                        Authorization: this.props.accessToken
                    }
                }

                try {
                    let updateFiretextAnnouncementResponse = await axios.put(`/api/firetextAnnouncements/${firetextAnnouncement._id}`, formattedFiretextAnnouncement, config)

                    if (updateFiretextAnnouncementResponse.data.success) {
                        this.setState(prevState => ({ loaders: { ...prevState.loaders, updateFiretextAnnouncement: false } }))
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Announcement updated successfully", "success")
                        let updatedFiretextAnnouncement = filteredFiretextAnnouncements.map(firetextAnnouncementObject => {
                            if (firetextAnnouncementObject._id === firetextAnnouncement._id) {

                                return {
                                    ...firetextAnnouncement,
                                    ...(updateFiretextAnnouncementResponse.data.firetextAnnouncement ?? {})
                                }
                            } else {
                                return firetextAnnouncementObject
                            }
                        })
                        this.setState(prevState => ({
                            firetextAnnouncements: updatedFiretextAnnouncement,
                            filteredFiretextAnnouncements: updatedFiretextAnnouncement
                        }), () => this.getFiretextAnnouncements())
                        return true
                    } else {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, updateFiretextAnnouncement: false },
                        }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, updateFiretextAnnouncement: false },
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            }
            else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }

        }
    }

    deleteFiretextAnnouncement = async () => {
        const { loaders, firetextAnnouncement } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.deleteFiretextAnnouncement) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, deleteFiretextAnnouncement: true } }))
            try {
                const deleteFiretextAnnouncementResponse = await axios.delete(`/api/firetextAnnouncements/${firetextAnnouncement._id}`, config)
                if (deleteFiretextAnnouncementResponse && deleteFiretextAnnouncementResponse.data && deleteFiretextAnnouncementResponse.data.success) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, deleteFiretextAnnouncement: false }
                    }))
                    this.handleModal('editFiretextAnnouncement')
                    this.getFiretextAnnouncements()
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Announcement deleted successfully", "success")
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: deleteFiretextAnnouncementResponse.data.errors },
                        loaders: { ...prevState.loaders, deleteFiretextAnnouncement: false }
                    }))
                }
            } catch (e) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, deleteFiretextAnnouncement: false }
                }))
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
            }
        }
    }

    render() {
        const { accessToken, authenticatedUser } = this.props
        const { firetextAnnouncement, errors, modals, loaders, info, filteredFiretextAnnouncements } = this.state

        const isSuperAdmin = authenticatedUser?.type === 'superAdmin'

        return (
            <>
                {modals.createNewFiretextAnnouncement &&
                    <CreateNewFiretextAnnouncementModalWrapper
                        handleInputChange={this.handleInputChange}
                        handleClose={() => this.handleModal('createNewFiretextAnnouncement')}
                        createFiretextAnnouncement={this.createFiretextAnnouncement}
                        firetextAnnouncement={firetextAnnouncement}
                        isSuperAdmin={isSuperAdmin}
                        errors={errors}
                        loaders={loaders}
                        open={modals.createNewFiretextAnnouncement}
                        accessToken={accessToken}
                    />
                }
                {modals.editFiretextAnnouncement &&
                    <EditFiretextAnnouncementModal
                        handleInputChange={this.handleInputChange}
                        handleClose={() => this.handleModal('editFiretextAnnouncement')}
                        deleteFiretextAnnouncement={this.deleteFiretextAnnouncement}
                        firetextAnnouncement={firetextAnnouncement}
                        errors={errors}
                        loaders={loaders}
                        open={modals.editFiretextAnnouncement}
                        updateFiretextAnnouncement={this.updateFiretextAnnouncement}
                        accessToken={accessToken}
                    />
                }
                <Grid
                    xs={12}
                    item
                    container
                    style={{
                        display: 'flex', flex: 1, justifyContent: 'space-between', marginTop: 8,
                        borderRadius: 0,
                        marginBottom: 12
                    }}

                >
                    <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}
                    >
                        <Button
                            onClick={() => this.handleModal('createNewFiretextAnnouncement')}
                            variant='outlined'
                        >
                            <AddBoxIcon
                                style={{ marginRight: 8 }}
                            />
                            Add FireTEXT Announcement
                        </Button>
                    </Grid>
                    <Grid
                        style={{ display: 'flex', paddingRight: 24 }}
                        item
                    >
                        <GeneralTextField
                            format="search"
                            gridStyle={{ width: 350 }}
                            placeholder='Search announcements...'
                            statePath={`info.search`}
                            value={info.search}
                            handleInputChange={this.handleSearchInputChange}
                            resetSearch={this.resetSearch}
                        />
                    </Grid>
                </Grid>
                <TableContainer
                    component={Paper}
                    elevation={0}
                    style={{
                        borderRadius: 0,
                        marginBottom: 64
                    }}
                >
                    <Table
                        style={{ minWidth: 650 }}
                        aria-label='FireTEXT Announcements'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Announcement
                                </TableCell>
                                <TableCell>
                                    Department
                                </TableCell>
                                {isSuperAdmin ?
                                    <TableCell>
                                        Created By
                                    </TableCell>
                                    :
                                    null
                                }
                                <TableCell>
                                    Created At
                                </TableCell>
                                <TableCell>
                                    Expires At
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loaders.getFiretextAnnouncements &&
                                <TableRow>
                                    <TableCell
                                        style={{ paddingTop: 96, paddingBottom: 96 }}
                                        colSpan={6}
                                        align="center"
                                    >
                                        <CircularProgress
                                            size={36}
                                        />

                                    </TableCell>
                                </TableRow>
                            }
                            {!loaders.getFiretextAnnouncements && filteredFiretextAnnouncements.map((firetextAnnouncement) =>
                                <TableRow
                                    key={firetextAnnouncement._id}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.handleModal('editFiretextAnnouncement', firetextAnnouncement)}
                                >
                                    <TableCell
                                        style={{
                                            whiteSpace: "normal",
                                            wordBreak: "break-word"
                                        }}
                                    >
                                        {firetextAnnouncement.text}
                                    </TableCell>
                                    <TableCell>
                                        {firetextAnnouncement.departmentObject?.name ? firetextAnnouncement.departmentObject.name : 'FireTEXT'}
                                    </TableCell>
                                    {isSuperAdmin ?
                                        firetextAnnouncement.isFacebookAnnouncement ?
                                            <TableCell>
                                                {`Facebook - ${firetextAnnouncement?.pageName ? firetextAnnouncement.pageName : ''}`}
                                            </TableCell>
                                            :
                                            firetextAnnouncement.createdByObject?.firstName ?
                                                <TableCell>
                                                    {`${firetextAnnouncement.createdByObject.firstName} ${firetextAnnouncement.createdByObject.lastName}`}
                                                </TableCell>
                                                :
                                                null
                                        :
                                        null
                                    }
                                    <TableCell>
                                        {moment.tz(new Date(firetextAnnouncement.createdAt), moment.tz.guess()).format('MM/DD/YY HH:mm:ss z')}
                                    </TableCell>
                                    <TableCell>
                                        {firetextAnnouncement.expiresMilli ? moment.tz(new Date(firetextAnnouncement.expiresMilli), moment.tz.guess()).format('MM/DD/YY HH:mm:ss z') : 'No expiry'}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
}

export default withSnackbar(withStyles(null, { withTheme: true })(FiretextAnnouncement))
