import React from 'react'
import { FormControl, FormLabel, Grid, Tooltip, Typography, withStyles } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import HelpOutlineOutlinedIcon from '@material-ui/icons/Help'
import FeedbackIcon from '@material-ui/icons/Feedback'
import theme from '../../../utilities/theme'
import RadioGroupField from '../../general/RadioGroupField'
import AvailabilityScheduler from './AvailabilityScheduler'
import { filterDepartments, SPECIFIC_DEPARTMENT } from './AlertSettings'

const OFF = 1
const ON = 2
const CUSTOM = 3
const OPTED_OUT = 4
const BLOCKED = 5
const weatherPreferenceOptions = [
    {
        key: "Off",
        value: OFF
    },
    {
        key: "On",
        value: ON
    }
]
const pushNotificationsPreferenceOptions = weatherPreferenceOptions
const emailNotificationsPreferenceOptions = weatherPreferenceOptions
const CUSTOM_AVAILABILITY = "custom"
const AvailabilityTypes = [
    {
        key: "All the time",
        value: "always",
    },
    {
        key: "Only on weekdays",
        value: "weekdays",
    },
    {
        key: "Only on weekends",
        value: "weekends",
    },
    {
        key: "Custom",
        value: CUSTOM_AVAILABILITY,
    },
]

const getTextNotificationsPreferenceOptions = (user, isSuperAdmin) => {
    let bwErrorCode = user?.notifications?.bandwidthPhoneNumberErrorCode

    return [
        {
            key: "Off",
            value: OFF,
            disabled: (user?.notifications && (user.notifications.sms === OPTED_OUT || user.notifications.sms === BLOCKED)) ? true : false
        },
        {
            key: "On",
            value: ON,
            disabled: user.notifications.sms === OPTED_OUT || (!isSuperAdmin && typeof bwErrorCode === 'number' && (bwErrorCode < 4700 || bwErrorCode > 4799))
        },
        {
            key: "If Push Fails",
            value: 3,
            disabled: (user?.notifications && (user.notifications.sms === 4 || user.notifications.sms === BLOCKED)) ? true : false
        },
        {
            key: "Opted-out",
            value: OPTED_OUT,
            invisible: (user?.notifications?.sms === OPTED_OUT) ? false : true
        },
        {
            key: (typeof bwErrorCode === 'number') ? `Blocked (Code: ${bwErrorCode})` : "Blocked",
            value: BLOCKED,
            invisible: (user?.notifications?.sms === BLOCKED) ? false : true
        }
    ]
}

const getPhoneCallNotificationsPreferenceOptions = (user, isSuperAdmin) => {
    let bwErrorCode = user?.notifications?.bandwidthPhoneNumberErrorCode

    return [
        {
            key: "Off",
            value: OFF,
            disabled: (user?.notifications && (user.notifications.phoneCall === OPTED_OUT || user.notifications.phoneCall === BLOCKED)) ? true : false
        },
        {
            key: "On",
            value: ON,
            disabled: user.notifications.phoneCall === OPTED_OUT || (!isSuperAdmin && typeof bwErrorCode === 'number' && (bwErrorCode < 4700 || bwErrorCode > 4799))
        },
        {
            key: "Opted-out",
            value: OPTED_OUT,
            invisible: (user?.notifications?.phoneCall === OPTED_OUT) ? false : true
        },
        {
            key: (typeof bwErrorCode === 'number') ? `Blocked (Code: ${bwErrorCode})` : "Blocked",
            value: BLOCKED,
            invisible: (user?.notifications?.phoneCall === BLOCKED) ? false : true
        }
    ]
}

const useStyles = theme => ({
    customTooltip: {
        maxWidth: "60vw"
    }
})

const CustomWidthTooltip = withStyles(useStyles)((props) => {
    const { title, placement, children, classes } = props
    return (
        <Tooltip
            title={title}
            placement={placement ?? 'top-start'}
            classes={{ tooltip: classes.customTooltip }}
        >
            {children}
        </Tooltip>
    )
})

const NotificationSettings = (props) => {

    const { containerStyle, user, isSuperAdmin, departments, handleInputChange } = props

    if (user?.type === 'dispatcher') return null

    const chatEnabledDepartments = []
    const dispatchEnabledDepartments = []
    const weatherEnabledDepartments = []
    const textNotificationsEnabledDepartments = []
    const phoneCallNotificationsEnabledDepartments = []
    const pushNotificationsEnabledDepartments = []
    const emailNotificationsEnabledDepartments = []

    for (const dept of departments) {
        if (dept?.featuresPermission?.chat === true) {
            chatEnabledDepartments.push(dept)
        }
        if (dept?.featuresPermission?.manualDispatches === true || dept?.featuresPermission?.audioDispatches === true) {
            dispatchEnabledDepartments.push(dept)
        }
        if (dept?.featuresPermission?.weather === true) {
            weatherEnabledDepartments.push(dept)
        }
        if (dept?.featuresPermission?.textNotifications === true) {
            textNotificationsEnabledDepartments.push(dept)
        }
        if (dept?.featuresPermission?.phoneCallNotifications === true) {
            phoneCallNotificationsEnabledDepartments.push(dept)
        }
        if (dept?.featuresPermission?.pushNotifications === true) {
            pushNotificationsEnabledDepartments.push(dept)
        }
        if (dept?.featuresPermission?.emailNotifications === true) {
            emailNotificationsEnabledDepartments.push(dept)
        }
    }

    let chatAlertsTooltip = []
    let alertsTooltip = []

    if (user?.alerts?.chat.type === OFF) {
        chatAlertsTooltip.push('Chat alerts are off. Chat notifications will not be received.')
    }
    else if (user?.alerts?.chat.type === SPECIFIC_DEPARTMENT) {
        const selectedChatDepartments = filterDepartments(departments, user?.alerts?.chat?.departmentIds) // dispatch depts user seleted in case of dispatch alerts for specific departments
        chatAlertsTooltip.push(`Chat alerts are enabled for specific departments [${selectedChatDepartments.map(dept => dept?.name?.toUpperCase?.()).join(', ')}].`)
    }

    let pushTooltip, textTooltip, callTooltip, emailTooltip
    if (user?.type === 'superAdmin') {
        pushTooltip = [...chatAlertsTooltip, 'Super Admins only receive push notifications for chat from any department that has chat and push permissions.']
        textTooltip = ['Super Admins do not receive text notifications as text notifications are only used for dispatch alerts.']
        callTooltip = ['Super Admins do not receive phone call notifications as call notifications are only used for dispatch alerts.']
        emailTooltip = ['Super Admins do not receive email notifications as email notifications are only used for dispatch alertss.']
    }
    else {
        if (user?.alerts?.dispatch.type === OFF) {
            alertsTooltip.push('Dispatch alerts are off. Dispatch notifications will not be received.')
        }
        else if (user?.alerts?.dispatch.type === SPECIFIC_DEPARTMENT) {
            const selectedDispatchDepartments = filterDepartments(departments, user?.alerts?.dispatch?.departmentIds) // dispatch depts user seleted in case of dispatch alerts for specific departments
            alertsTooltip.push(`Dispatch alerts are enabled for specific departments [${selectedDispatchDepartments.map(dept => dept?.name?.toUpperCase?.()).join(', ')}].`)
        }

        pushTooltip = [...alertsTooltip, ...chatAlertsTooltip]
        if (pushNotificationsEnabledDepartments.length === 0) {
            pushTooltip.push('None of the departments have push notifications enabled.')
        }
        else if (departments?.length !== pushNotificationsEnabledDepartments.length) {
            pushTooltip.push(`Only following departments have permissions to send push notifications [${pushNotificationsEnabledDepartments.map(dept => dept?.name?.toUpperCase?.()).join(", ")}].`)
        }
        textTooltip = [...alertsTooltip]
        if (textNotificationsEnabledDepartments.length === 0) {
            textTooltip.push('None of the departments have text notifications enabled.')
        }
        else if (departments?.length !== textNotificationsEnabledDepartments.length) {
            textTooltip.push(`Only following departments have permissions to send text notifications [${textNotificationsEnabledDepartments.map(dept => dept?.name?.toUpperCase()).join(", ")}].`)
        }
        callTooltip = [...alertsTooltip]
        if (phoneCallNotificationsEnabledDepartments.length === 0) {
            callTooltip.push('None of the departments have phone call notifications enabled.')
        }
        else if (departments?.length !== phoneCallNotificationsEnabledDepartments.length) {
            callTooltip.push(`Only following departments have permissions to send phone call notifications [${phoneCallNotificationsEnabledDepartments.map(dept => dept?.name?.toUpperCase()).join(", ")}].`)
        }
        emailTooltip = [...alertsTooltip]
        if (emailNotificationsEnabledDepartments.length === 0) {
            emailTooltip.push('None of the departments have email notifications enabled.')
        }
        else if (departments?.length !== emailNotificationsEnabledDepartments.length) {
            emailTooltip.push(`Only following departments have permissions to send email notifications [${emailNotificationsEnabledDepartments.map(dept => dept?.name?.toUpperCase()).join(", ")}].`)
        }
    }


    return (
        <Grid
            container
            item
            xs={12}
            spacing={2}
            style={containerStyle}
        >
            <Typography style={{ width: "100%" }} variant="h6" gutterBottom>
                Edit Notifications Preference
            </Typography>

            {/* Text Message */}
            <div style={{ paddingLeft: 24, width: "100%" }}>
                <FormControl style={{ width: "100%" }} component="fieldset">
                    <FormLabel
                        component="legend"
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                        <Typography variant="subtitle2">Text Message [Dispatch only] </Typography>
                        {user?.notifications?.sms === OPTED_OUT ?
                            <CustomWidthTooltip
                                title={
                                    <>
                                        <Typography
                                            variant="body2"
                                        >
                                            You are unable to receive text notifications because you opted-out.
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                        >
                                            To opt-in/enable receiving texts, please send the keyword UNSTOP on <span style={{ color: 'blue' }}>+1 (866) 665-0911</span>.
                                        </Typography>
                                    </>
                                }
                                placement="top-start"
                            >
                                <HelpOutlineOutlinedIcon
                                    style={{ color: theme.palette.primary.main, fontSize: 32, paddingLeft: 8, cursor: 'pointer' }}
                                />
                            </CustomWidthTooltip>
                            :
                            user?.notifications?.sms === BLOCKED ?
                                <CustomWidthTooltip
                                    title={
                                        <>
                                            <Typography
                                                variant="body2"
                                            >
                                                You are unable to receive text notifications due to your carrier's limitations.
                                            </Typography>
                                        </>
                                    }
                                >
                                    <HelpOutlineOutlinedIcon
                                        style={{ color: theme.palette.primary.error, fontSize: 32, paddingLeft: 8, cursor: 'pointer' }}
                                    />
                                </CustomWidthTooltip>
                                :
                                (user?.notifications?.sms === ON || user?.notifications?.sms === CUSTOM) && textTooltip.length > 0 &&
                                <CustomWidthTooltip
                                    title={
                                        <div style={{ whiteSpace: 'pre-line' }}>{textTooltip.join('\n')}</div>
                                    }
                                >
                                    <FeedbackIcon
                                        style={{ color: theme.palette.primary.main, fontSize: 32, paddingLeft: 8, cursor: 'pointer' }}
                                    />

                                </CustomWidthTooltip>
                        }
                    </FormLabel>
                    <RadioGroupField
                        name="textNotification"
                        defaultValue={ON}
                        value={user?.notifications?.sms}
                        options={getTextNotificationsPreferenceOptions(user, isSuperAdmin)}
                        statePath={`user.notifications.sms`}
                        handleInputChange={handleInputChange}
                    />
                </FormControl>
            </div>

            {/* Phone Call */}
            <div style={{ paddingLeft: 24, width: "100%" }}>
                <FormControl style={{ width: "100%" }} component="fieldset">
                    <FormLabel
                        component="legend"
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                        <Typography variant="subtitle2">Phone Call [Dispatch only] </Typography>
                        {user?.notifications?.phoneCall === OPTED_OUT ?
                            <CustomWidthTooltip
                                title={
                                    <>
                                        <Typography
                                            variant="body2"
                                        >
                                            You are unable to receive phone calls because you opted-out.
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                        >
                                            To opt-in/enable receiving calls, please send the keyword UNSTOP on <span style={{ color: 'blue' }}>+1 (866) 665-0911</span>.
                                        </Typography>
                                    </>
                                }
                            >
                                <HelpOutlineOutlinedIcon
                                    style={{ color: theme.palette.primary.main, fontSize: 32, paddingLeft: 8, cursor: 'pointer' }}
                                />
                            </CustomWidthTooltip>
                            :
                            user?.notifications?.phoneCall === BLOCKED ?
                                <CustomWidthTooltip
                                    title={
                                        <>
                                            <Typography
                                                variant="body2"
                                            >
                                                You are unable to receive phone calls due to your carrier's limitations.
                                            </Typography>
                                        </>
                                    }
                                >
                                    <HelpOutlineOutlinedIcon
                                        style={{ color: theme.palette.primary.error, fontSize: 32, paddingLeft: 8, cursor: 'pointer' }}
                                    />
                                </CustomWidthTooltip>
                                :
                                user?.notifications?.phoneCall === ON && callTooltip.length > 0 &&
                                <CustomWidthTooltip
                                    title={
                                        <div style={{ whiteSpace: 'pre-line' }}>{callTooltip.join('\n')}</div>
                                    }
                                >
                                    <FeedbackIcon
                                        style={{ color: theme.palette.primary.main, fontSize: 32, paddingLeft: 8, cursor: 'pointer' }}
                                    />
                                </CustomWidthTooltip>
                        }
                    </FormLabel>
                    <RadioGroupField
                        name="phoneCallNotification"
                        defaultValue={OFF}
                        value={user?.notifications?.phoneCall ?? OFF}
                        options={getPhoneCallNotificationsPreferenceOptions(user, isSuperAdmin)}
                        statePath={`user.notifications.phoneCall`}
                        handleInputChange={handleInputChange}
                    />
                </FormControl>
            </div>
            {!user.phoneNumberOnly &&
                <>
                    {/* Push Notifications */}
                    <div style={{ paddingLeft: 24, width: "100%" }}>
                        <FormControl style={{ width: "100%" }} component="fieldset">
                            <FormLabel
                                component="legend"
                                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                                <Typography variant="subtitle2">Push Notification [Dispatch and Chat] </Typography>
                                {user?.notifications?.push === ON && pushTooltip.length > 0 &&
                                    <CustomWidthTooltip
                                        title={
                                            <div style={{ whiteSpace: 'pre-line' }}>{pushTooltip.join('\n')}</div>
                                        }
                                    >
                                        <FeedbackIcon
                                            style={{ color: theme.palette.primary.main, fontSize: 32, paddingLeft: 8, cursor: 'pointer' }}
                                        />
                                    </CustomWidthTooltip>
                                }
                            </FormLabel>
                            <RadioGroupField
                                name="pushNotification"
                                defaultValue={ON}
                                value={user?.notifications?.push}
                                options={pushNotificationsPreferenceOptions}
                                statePath={`user.notifications.push`}
                                handleInputChange={handleInputChange}
                            />
                        </FormControl>
                    </div>

                    {/* Email Notifications */}
                    <div style={{ paddingLeft: 24, width: "100%" }}>
                        <FormControl style={{ width: "100%" }} component="fieldset">
                            <FormLabel
                                component="legend"
                                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                                <Typography variant="subtitle2"> Email Notification [Dispatch only] </Typography>
                                {user?.notifications?.email === ON && emailTooltip.length > 0 &&
                                    <CustomWidthTooltip
                                        title={
                                            <div style={{ whiteSpace: 'pre-line' }}>{emailTooltip.join('\n')}</div>
                                        }
                                    >
                                        <FeedbackIcon
                                            style={{ color: theme.palette.primary.main, fontSize: 32, paddingLeft: 8, cursor: 'pointer' }}
                                        />
                                    </CustomWidthTooltip>
                                }
                            </FormLabel>
                            <RadioGroupField
                                name="emailNotifications"
                                defaultValue={OFF}
                                value={user?.notifications?.email ?? OFF}
                                options={emailNotificationsPreferenceOptions}
                                statePath={`user.notifications.email`}
                                handleInputChange={handleInputChange}
                            />
                        </FormControl>
                    </div>
                </>
            }

            {/* Availability */}
            <div style={{ width: "100%" }}>
                <div style={{ paddingLeft: 24 }}>
                    <FormControl style={{ width: "100%" }} component="fieldset">
                        <FormLabel
                            component="legend"
                        >
                            <Typography variant="subtitle2">Availability</Typography>
                        </FormLabel>
                        <RadioGroupField
                            name="availabilityNotification"
                            defaultValue={ON}
                            value={user?.notifications?.availability?.type}
                            isValueString={true}
                            options={AvailabilityTypes}
                            statePath={`user.notifications.availability.type`}
                            handleInputChange={handleInputChange}
                        />
                    </FormControl>
                </div>
                {user.notifications.availability.type === CUSTOM_AVAILABILITY &&
                    <AvailabilityScheduler
                        value={user.notifications.availability.timeslots}
                        statePath={`user.notifications.availability.timeslots`}
                        handleInputChange={handleInputChange}
                    />
                }
            </div>

        </Grid>
    );
}

export default withSnackbar(withStyles(useStyles)(NotificationSettings))