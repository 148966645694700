// React
import React, { useEffect } from 'react'
import { Prompt } from "react-router-dom"
// Material UI
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, CircularProgress, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import RemoveIcon from '@material-ui/icons/Remove'
// Components
import UserType from './UserType'
import GeneralTextField from '../../../general/TextField'
import GeneralDropdownField from '../../../general/DropdownField'
import GeneralCheckboxField from '../../../general/CheckboxField'
// Utilities
import _ from 'underscore'
import { UserRolesInDepartment } from '../../../../utilities/Options'
import NotificationSettings from '../NotificationSettings'


const CreateNewUserModal = (props) => {
    const { handleInputChange, handleAddDepartment, handleDeleteDepartment, handleUserType, createUser, user, departments, errors, loaders, open, authenticatedUser } = props

    useEffect(() => {
        const beforeunload = (e) => {
            e.preventDefault()
            e.returnValue = true
        }

        window.addEventListener("beforeunload", beforeunload)
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        }
    }, [])

    const disabled = authenticatedUser?.type !== 'superAdmin'
    const userDepartmentIds = user.departments.map(department => department.departmentId)
    const departmentOptions = departments?.map(department => {
        if (userDepartmentIds.includes(department.value))
            return { ...department, disabled: true }
        return department
    })

    const formatUserDeparmentsListForDropdown = (userDepartments) => {
        const formattedUserDeparmentsArray = userDepartments.map(userDepartment => {
            return {
                text: _.findWhere(departments, { value: userDepartment.departmentId })?.text,
                value: userDepartment.departmentId
            }
        })
        return formattedUserDeparmentsArray
    }

    const handleDispatcherDepartmentsChange = (e, data) => {
        const { value: selectedDepartmentIds } = data

        if (selectedDepartmentIds) {
            const formattedUserDepartments = selectedDepartmentIds.map(departmentId => ({ departmentId, role: user.type }))
            const formattedData = { ...data, value: formattedUserDepartments }

            handleInputChange(e, formattedData)
        }
    }

    const handleClose = () => {
        let shouldCloseModal = window.confirm(
            "Changes you made may not be saved. Are you sure you want to leave this page?"
        )
        if (!shouldCloseModal) return
        else props.handleClose()
    }

    return (
        <>
            <Prompt
                when={true}
                message={() => "Changes you made may not be saved. Are you sure you want to leave this page?"}
            />
            <Dialog
                onClose={!loaders.createNewUser && handleClose}
                open={open}
                maxWidth='sm'
                PaperProps={{ style: { flex: 1 } }}
            >
                <DialogTitle
                    disableTypography
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        variant='h6'
                    >
                        Add New User
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        disabled={loaders.createNewUser}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    dividers
                >
                    <Grid
                        xs={12}
                        spacing={2}
                        container
                    >
                        {!user.type ?
                            <UserType
                                authenticatedUser={authenticatedUser}
                                handleUserType={handleUserType}
                            />
                            :
                            <>
                                {!disabled && user.type === 'user' &&
                                    <GeneralCheckboxField
                                        gridStyle={{ display: 'flex', alignItems: 'center', marginTop: 8 }}
                                        width={12}
                                        size='small'
                                        label='Is this a phone number only user?'
                                        statePath={`user.phoneNumberOnly`}
                                        value={user.phoneNumberOnly}
                                        error={errors.user.phoneNumberOnly}
                                        handleInputChange={handleInputChange}
                                    />
                                }
                                {!user.phoneNumberOnly ?
                                    <GeneralTextField
                                        autoFocus={true}
                                        width={12}
                                        label={`Email Address ${(!user.email && !user.phone) || user.email ? ' *' : ''}`}
                                        labelTooltipTitle={disabled && 'If this user does not have an email account. Please, contact support to help you set up this user.'}
                                        labelTooltipIcon='info'
                                        placeholder='Enter email address...'
                                        statePath={`user.email`}
                                        value={user.email}
                                        error={user.type === 'user' && user.phone ? null : errors.user.email}
                                        handleInputChange={handleInputChange}
                                    />
                                    :
                                    user.type === 'user' ?
                                        <>
                                            <GeneralTextField
                                                autoFocus={true}
                                                width={6}
                                                label={`First Name *`}
                                                placeholder='Enter first name...'
                                                statePath={`user.firstName`}
                                                value={user.firstName}
                                                error={errors.user.firstName}
                                                handleInputChange={handleInputChange}
                                            />
                                            <GeneralTextField
                                                width={6}
                                                label={`Last Name *`}
                                                placeholder='Enter last name...'
                                                statePath={`user.lastName`}
                                                value={user.lastName}
                                                error={errors.user.lastName}
                                                handleInputChange={handleInputChange}
                                            />
                                        </>
                                        :
                                        null
                                }

                                {user.type === 'user' &&
                                    <GeneralTextField
                                        width={12}
                                        format='phoneNumber'
                                        label={`Phone Number ${(!user.email && !user.phone) || user.phone ? ' *' : ''}`}
                                        placeholder='Enter phone number...'
                                        statePath={`user.phone`}
                                        value={user.phone}
                                        error={user.email ? null : errors.user.phone}
                                        handleInputChange={handleInputChange}
                                    />
                                }

                                {user.type === 'dispatcher' &&
                                    <GeneralDropdownField
                                        options={departmentOptions}
                                        format='multiple'
                                        width={12}
                                        label='Departments *'
                                        placeholder='Select Departments...'
                                        statePath={`user.departments`}
                                        value={formatUserDeparmentsListForDropdown(user.departments)}
                                        handleInputChange={handleDispatcherDepartmentsChange}
                                        error={errors.user.departments}
                                    />
                                }

                                {user.type === 'user' && !disabled &&
                                    <>
                                        {user.departments.map((department, index) => (
                                            <React.Fragment
                                                key={`CUDRDD-${department.key}`}
                                            >
                                                <GeneralDropdownField
                                                    options={departmentOptions}
                                                    format="autocomplete"
                                                    width={6}
                                                    label={`Department ${index + 1} *`}
                                                    placeholder='Select a department...'
                                                    statePath={`user.departments.${index}.departmentId`}
                                                    value={department.departmentId}
                                                    handleInputChange={handleInputChange}
                                                    error={errors.user.departments[index].departmentId}
                                                />
                                                <GeneralDropdownField
                                                    options={!user.phoneNumberOnly ? UserRolesInDepartment : UserRolesInDepartment.filter(option => option.value === 'subscriber')}
                                                    width={user.departments.length === 1 ? 6 : 5}
                                                    label='Role *'
                                                    placeholder='Select a role...'
                                                    statePath={`user.departments.${index}.role`}
                                                    value={department.role}
                                                    handleInputChange={handleInputChange}
                                                    error={errors.user.departments[index].role}
                                                />
                                                {user.departments.length > 1 &&
                                                    <Grid
                                                        item
                                                        xs={1}
                                                        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
                                                    >
                                                        <IconButton
                                                            aria-label='close'
                                                            onClick={() => handleDeleteDepartment(department.key)}
                                                            disabled={loaders.createNewUser}
                                                        >
                                                            <RemoveIcon style={{ color: '#ff0000' }} />
                                                        </IconButton>
                                                    </Grid>
                                                }
                                            </React.Fragment>
                                        ))}
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <Button
                                                onClick={handleAddDepartment}
                                                disabled={loaders.createNewUser}
                                                color='primary'
                                                variant='outlined'
                                            >
                                                Add Another Department
                                            </Button>
                                        </Grid>
                                    </>
                                }
                                {user.type === 'user' && disabled &&
                                    <GeneralDropdownField
                                        options={!user.phoneNumberOnly ? UserRolesInDepartment : UserRolesInDepartment.filter(option => option.value === 'subscriber')}
                                        width={12}
                                        label='Role In Department *'
                                        placeholder='Select user role for current department...'
                                        statePath={`user.departments.${0}.role`}
                                        value={user.departments[0].role}
                                        handleInputChange={handleInputChange}
                                        error={errors.user.departments[0].role}
                                    />
                                }
                            </>
                        }
                        {user.type === 'user' && user.phoneNumberOnly &&
                            <NotificationSettings
                                user={user}
                                isSuperAdmin={authenticatedUser?.type === 'superAdmin' || user?.type === 'superAdmin'} // if authUser or edited user is superAdmin, they can unblock sms notificaitons
                                departments={!disabled ? departments.filter(dept => user.departments.findIndex(userDept => userDept.departmentId === dept._id) > -1) : []}
                                handleInputChange={handleInputChange}
                                containerStyle={{
                                    padding: 16
                                }}
                            />
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {!!user.type &&
                        <Button
                            onClick={createUser}
                            color='secondary'
                            variant='contained'
                            disabled={loaders.createNewUser}
                        >
                            {loaders.createNewUser &&
                                <CircularProgress
                                    size={18}
                                    style={{ marginRight: 8 }}
                                />
                            }
                            Create
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreateNewUserModal;
