import React from 'react'
//MUI
import { Button, Grid, withStyles } from '@material-ui/core'
// DnD
import { useDrop } from 'react-dnd'
//utils
import theme from '../../../utilities/theme'
//icons
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'


const DeleteFavouriteDepartmentCard = (props) => {
    const { deleteDepartmentFromFavourite } = props

    const [{ isOver, canDrop, handlerId }, deleteDrop] = useDrop({
        accept: 'favourite',
        collect: monitor => ({
            handlerId: monitor.getHandlerId(),
            isOver: !!monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        drop: (item, monitor) => {
            return isOver ? deleteDepartmentFromFavourite(item._id) : null
        }
    })

    if (!canDrop) return null

    return (
        <Grid
            key='deleteFromFavourite'
            ref={deleteDrop}
            data-handler-id={handlerId}
            xs={3}
            container
            wrap='nowrap'
            item
        >
            <Button
                style={{ width: '100%', height: '100%', display: 'flex', minHeight: 128, justifyContent: 'center', alignItems: 'center', textAlign: 'center', backgroundColor: '#FF0000', opacity: 0.5, color: theme.palette.primary.light, padding: 40 }}
            >
                <Grid item xs zeroMinWidth>
                    <DeleteForeverIcon />
                </Grid>
            </Button>
        </Grid>
    )
}

export default withStyles(null, { withTheme: true })(DeleteFavouriteDepartmentCard);
