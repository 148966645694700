import React from 'react';
import { FormControl, FormLabel, Grid, Typography } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import GeneralDropdownField from '../../general/DropdownField'
import RadioGroupField from '../../general/RadioGroupField'


const OFF = 1
const ON = 2
export const SPECIFIC_DEPARTMENT = 3
const chatPreferenceOptions = [
    {
        key: "Off",
        value: OFF
    },
    {
        key: "On",
        value: ON
    },
    {
        key: "Specific Department",
        value: SPECIFIC_DEPARTMENT
    }
]
const dispatchPreferenceOptions = chatPreferenceOptions
const weatherPreferenceOptions = [
    {
        key: "Off",
        value: OFF
    },
    {
        key: "On",
        value: ON
    }
]
export const filterDepartments = (departments, departmentIds) => {
    if (Array.isArray(departments) && Array.isArray(departmentIds)) return departments.filter(department => departmentIds.findIndex(id => department.value === id) !== -1)

    return []
}


const AlertSettings = (props) => {

    const { containerStyle, user, departments, errors, handleInputChange } = props

    if (user?.type === 'dispatcher') return null

    const chatEnabledDepartments = []
    const dispatchEnabledDepartments = []
    const weatherEnabledDepartments = []
    const textNotificationsEnabledDepartments = []
    const phoneCallNotificationsEnabledDepartments = []
    const pushNotificationsEnabledDepartments = []
    const emailNotificationsEnabledDepartments = []

    for (const dept of departments) {
        if (dept?.featuresPermission?.chat === true) {
            chatEnabledDepartments.push(dept)
        }
        if (dept?.featuresPermission?.manualDispatches === true || dept?.featuresPermission?.audioDispatches === true) {
            dispatchEnabledDepartments.push(dept)
        }
        if (dept?.featuresPermission?.weather === true) {
            weatherEnabledDepartments.push(dept)
        }
        if (dept?.featuresPermission?.textNotifications === true) {
            textNotificationsEnabledDepartments.push(dept)
        }
        if (dept?.featuresPermission?.phoneCallNotifications === true) {
            phoneCallNotificationsEnabledDepartments.push(dept)
        }
        if (dept?.featuresPermission?.pushNotifications === true) {
            pushNotificationsEnabledDepartments.push(dept)
        }
        if (dept?.featuresPermission?.emailNotifications === true) {
            emailNotificationsEnabledDepartments.push(dept)
        }
    }

    const selectedChatDepartments = filterDepartments(departments, user?.alerts?.chat?.departmentIds) // chat depts user seleted in case of chat alerts for specific departments
    const selectedDispatchDepartments = filterDepartments(departments, user?.alerts?.dispatch?.departmentIds) // dispatch depts user seleted in case of dispatch alerts for specific departments

    return (
        <Grid
            container
            item
            xs={12}
            spacing={2}
            style={containerStyle}
        >
            <Typography style={{ width: "100%" }} variant="h6" gutterBottom>
                Edit Alerts Preference
            </Typography>
            {(chatEnabledDepartments.length === 0 && dispatchEnabledDepartments.length === 0 && weatherEnabledDepartments.length === 0 && user.type !== 'superAdmin') ?
                <Grid>
                    <Typography variant="subtitle2" gutterBottom>You are not part of any department that utilizes any usecase for alerts (i.e. Chat, Dispatch or Weather)</Typography>
                </Grid>
                :
                <>
                    {/* Chat alerts */}
                    {(user?.type === 'superAdmin' || chatEnabledDepartments.length > 0) &&
                        <div style={{ width: "100%" }}>
                            <div style={{ paddingLeft: 24 }}>
                                <FormControl style={{ width: "100%" }} component="fieldset">
                                    <FormLabel
                                        component="legend"
                                    >
                                        <Typography variant="subtitle2">Chat</Typography>
                                    </FormLabel>
                                    <RadioGroupField
                                        name="chatAlert"
                                        defaultValue={ON}
                                        value={user?.alerts?.chat?.type}
                                        options={chatPreferenceOptions}
                                        statePath={`user.alerts.chat.type`}
                                        handleInputChange={handleInputChange}
                                    />
                                </FormControl>
                            </div>
                            {user?.alerts?.chat?.type === SPECIFIC_DEPARTMENT &&
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    spacing={2}
                                    style={{ width: "100%", paddingLeft: 40, paddingBottom: 8 }}
                                >
                                    <GeneralDropdownField
                                        options={chatEnabledDepartments}
                                        format="multiple"
                                        width={12}
                                        label="Select Departments For Chat Alerts"
                                        placeholder="Select Departments..."
                                        statePath={`user.alerts.chat.departmentIds`}
                                        value={selectedChatDepartments}
                                        handleInputChange={handleInputChange}
                                        error={errors.user.alerts.chat?.departmentIds}
                                    />
                                </Grid>
                            }
                        </div>
                    }
                    {/* Dispatch alerts */}
                    {(user?.type === 'superAdmin' || dispatchEnabledDepartments.length > 0) &&
                        <div style={{ width: "100%" }}>
                            <div style={{ paddingLeft: 24 }}>
                                <FormControl style={{ width: "100%" }} component="fieldset">
                                    <FormLabel
                                        component="legend"
                                    >
                                        <Typography variant="subtitle2">Dispatch</Typography>
                                    </FormLabel>
                                    {user.type !== 'superAdmin' ?
                                        <RadioGroupField
                                            name="dispatchAlert"
                                            defaultValue={ON}
                                            value={user?.alerts?.dispatch?.type}
                                            options={dispatchPreferenceOptions}
                                            statePath={`user.alerts.dispatch.type`}
                                            handleInputChange={handleInputChange}
                                        />
                                        :
                                        <Grid>
                                            <Typography variant="subtitle2" gutterBottom>Super Admins do not receive dispatch alerts.</Typography>
                                        </Grid>
                                    }
                                </FormControl>
                            </div>
                            {user?.alerts?.dispatch?.type === SPECIFIC_DEPARTMENT &&
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    spacing={2}
                                    style={{ width: "100%", paddingLeft: 40, paddingBottom: 8 }}
                                >
                                    <GeneralDropdownField
                                        options={dispatchEnabledDepartments}
                                        format="multiple"
                                        width={12}
                                        label="Select Departments For Dispatch Alerts"
                                        placeholder="Select Departments..."
                                        statePath={`user.alerts.dispatch.departmentIds`}
                                        value={selectedDispatchDepartments}
                                        handleInputChange={handleInputChange}
                                        error={errors.user.alerts.dispatch?.departmentIds}
                                    />
                                </Grid>
                            }
                        </div>
                    }
                    {/* Weather alerts */}
                    {(user?.type === 'superAdmin' || weatherEnabledDepartments.length > 0) &&
                        <div style={{ paddingLeft: 24 }}>
                            <FormControl style={{ width: "100%" }} component="fieldset">
                                <FormLabel
                                    component="legend"
                                >
                                    <Typography variant="subtitle2">EAS (weather)</Typography>
                                </FormLabel>
                                <RadioGroupField
                                    name="weatherAlert"
                                    defaultValue={ON}
                                    value={user?.alerts?.weather?.type}
                                    options={weatherPreferenceOptions}
                                    statePath={`user.alerts.weather.type`}
                                    handleInputChange={handleInputChange}
                                />
                            </FormControl>
                        </div>
                    }
                </>
            }
        </Grid>
    );
}

export default withSnackbar(AlertSettings)