import produce from 'immer'
import _ from 'underscore'

//added this function to handle errors object, incase we want to use handleChange to modify an object or array instead of a single field
const resetErrorsOnChange = (err) => {
    if (Object.prototype.toString.call(err) === '[object Object]') {
        const errors = { ...err }
        Object.keys(errors).forEach(key => {
            errors[key] = resetErrorsOnChange(errors[key])
        })
        return errors
    }
    else if (Object.prototype.toString.call(err) === '[object Array]') {
        const errors = [...err]
        errors.forEach((error, index) => {
            errors[index] = resetErrorsOnChange(error)
        })
        return errors
    }
    else {
        return null
    }
}

export const handleInputChange = (e, data, state) => {
    let value = data.value
    let path = data.statePath
    const pathArray = path.split('.')
    const lastKey = pathArray.pop()

    let stateObject = produce(state, draft => {
        draft = pathArray.reduce((obj, key) => obj[key], draft)
        draft[lastKey] = value
    })

    let finalManipulation = produce(stateObject, draft => {
        pathArray.unshift('errors')
        draft = pathArray.reduce((obj, key) => obj[key], draft)
        if (draft?.[lastKey]) draft[lastKey] = resetErrorsOnChange(draft[lastKey])
    })
    return finalManipulation
}

export const handleDropdownChange = (data, state) => {
    let value = data.value
    let path = data.statePath
    const pathArray = path.split('.')
    const lastKey = pathArray.pop()

    let stateObject = produce(state, draft => {
        draft = pathArray.reduce((obj, key) => obj[key], draft)
        draft[lastKey] = value
    })

    let finalManipulation = produce(stateObject, draft => {
        pathArray.unshift('errors')
        draft = pathArray.reduce((obj, key) => obj[key], draft)
        // draft[lastKey] = null
        if (draft?.[lastKey]) draft[lastKey] = resetErrorsOnChange(draft[lastKey])
    })
    return finalManipulation
}

export const handleErrors = (state, errors, fields) => {
    let success = true
    let errorFields = []

    _.each(fields, field => {
        if (field.shouldCheck) {
            const pathArray = field.path.split('.')
            const lastKey = pathArray.pop()
            let stateCopy = { ...state }
            let reducedStateObj = pathArray.reduce((obj, key) => obj[key], stateCopy)
            let stateValue = reducedStateObj[lastKey]

            if (field.type === 'stringArray') {
                if (!stateValue?.length) {
                    success = false
                    errorFields.push(field)
                    errors = produce(errors, draft => {
                        draft = pathArray.reduce((obj, key) => obj[key], draft)
                        draft[lastKey] = 'This field is required'
                    })
                }
                else {
                    _.each(stateValue, (value, index) => {
                        if (!stateValue[index] || typeof stateValue[index] !== 'string') {
                            success = false
                            errorFields.push(field)
                            errors = produce(errors, draft => {
                                draft = pathArray.reduce((obj, key) => obj[key], draft)
                                draft[lastKey][index] = 'Invalid values'
                            })
                        }
                    })
                }
            }
            else if (field.type === 'objectArray') {
                if (!stateValue?.length) {
                    success = false
                    errorFields.push(field)
                    errors = produce(errors, draft => {
                        draft = pathArray.reduce((obj, key) => obj[key], draft)
                        draft[lastKey] = 'This field is required'
                    })
                }
                else {
                    _.each(stateValue, (stateObj, index) => {
                        _.each(Object.keys(field.keys), key => {
                            if (field.keys[key].shouldCheck) {
                                if (!stateValue[index][key]) {
                                    success = false
                                    errorFields.push(field)
                                    errors = produce(errors, draft => {
                                        draft = pathArray.reduce((obj, key) => obj[key], draft)
                                        draft[lastKey][index][key] = 'Invalid field type'
                                    })
                                }
                                if (field.keys[key].type && typeof stateValue[index][key] !== field.keys[key].type) {
                                    success = false
                                    errorFields.push(field)
                                    errors = produce(errors, draft => {
                                        draft = pathArray.reduce((obj, key) => obj[key], draft)
                                        draft[lastKey][index][key] = 'Invalid field type'
                                    })
                                }
                                if (field.keys[key].validate !== undefined) {
                                    if (field.keys[key].validate(stateValue[index][key]) !== true) {
                                        success = false
                                        errorFields.push(field)
                                        errors = produce(errors, draft => {
                                            draft = pathArray.reduce((obj, key) => obj[key], draft)
                                            if (field.keys[key].validationMessage) {
                                                draft[lastKey][index][key] = field.keys[key].validationMessage
                                            } else {
                                                draft[lastKey][index][key] = 'Invalid value.'
                                            }
                                        })
                                    }
                                }
                            }
                        })
                    })
                }
            }
            else if (field.type === 'dynamic_object') {
                _.each(stateValue, (stateObj, index) => {
                    if (stateObj.name === field._id) {
                        _.each(field.checks, checkObj => {
                            const { key, type, required } = checkObj
                            let targetField = stateObj[key]
                            let primativeType = type === 'array' ? 'object' : type

                            // nested value is required but not provided
                            if (required && (!targetField || (type === 'array' && targetField.length === 0))) {
                                success = false
                                errorFields.push(field)
                                errors = produce(errors, draft => {
                                    draft = pathArray.reduce((obj, key) => obj[key], errors)
                                    draft[lastKey][index][key] = 'This field is required'
                                })
                                // nested value is not required but provided so check type
                            } else if (typeof targetField !== primativeType) {
                                errorFields.push(field)
                                errors = produce(errors, draft => {
                                    draft = pathArray.reduce((obj, key) => obj[key], draft)
                                    draft[lastKey][index][key] = 'Invalid field'
                                })
                            }
                        })
                    }
                })
            }

            else if (field.type === 'image') {
                if (stateValue) {
                    if (!stateValue.type.match('image.*')) {
                        success = false
                        errorFields.push(field)
                        errors = produce(errors, draft => {
                            draft = pathArray.reduce((obj, key) => obj[key], draft)
                            draft[lastKey] = 'Invalid image format.'
                        })
                    }
                }
                else {
                    success = false
                    errorFields.push(field)
                    errors = produce(errors, draft => {
                        draft = pathArray.reduce((obj, key) => obj[key], draft)
                        draft[lastKey] = 'This field is required.'
                    })
                }
            }
            else if (field.type === 'bool') {
                if (stateValue !== true && stateValue !== false) {
                    success = false
                    errorFields.push(field)
                    errors = produce(errors, draft => {
                        draft = pathArray.reduce((obj, key) => obj[key], draft)
                        draft[lastKey] = 'This field is required.'
                    })
                }
            }
            else {
                let checkType = () => {
                    let primativeType = field.type === 'array' ? 'object' : field.type
                    // value is not required but provided so check type
                    if (typeof stateValue !== primativeType) {
                        success = false
                        errorFields.push(field)
                        //let errorCopy = { ...errors }
                        //let testErrors = pathArray.reduce((obj, key) => obj[key], errorCopy)
                        errors = produce(errors, draft => {
                            draft = pathArray.reduce((obj, key) => obj[key], draft)
                            draft[lastKey] = 'Invalid field'
                        })
                    }
                }

                if (stateValue) {
                    if (field.validation !== undefined) {
                        if (field.validation !== true) {
                            success = false
                            errorFields.push(field)
                            errors = produce(errors, draft => {
                                draft = pathArray.reduce((obj, key) => obj[key], draft)
                                if (field.validationMessage) {
                                    draft[lastKey] = field.validationMessage
                                } else {
                                    draft[lastKey] = 'Invalid value.'
                                }
                            })
                        } else {
                            checkType()
                        }
                    } else {
                        checkType()
                    }
                } else {
                    success = false
                    errorFields.push(field)
                    errors = produce(errors, draft => {
                        draft = pathArray.reduce((obj, key) => obj[key], draft)
                        draft[lastKey] = field.errorMessage || 'This field is required.'
                    })
                }
            }
        }
    })

    return {
        success,
        errors,
        errorFields
    }
}

