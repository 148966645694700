import React from 'react'
// Router
import { withRouter } from 'react-router-dom'
// Material UI
import { Checkbox, Dialog, withStyles, DialogContent, DialogTitle, Typography, FormGroup, FormControlLabel, DialogActions, Grid } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
// Components
import GeneralDropdownField from '../../general/DropdownField'


class UsersFilterModal extends React.Component {

    formatDeparmentsListForMultiDropdown = () => {
        const { filterOptions, activeFilters } = this.props
        const { options: departments } = filterOptions.departmentsFilter
        const formattedUserDeparmentsArray = activeFilters.departments.map(departmentId => {
            return {
                text: departments.find(dept => dept._id === departmentId)?.text,
                value: departmentId
            }
        })
        return formattedUserDeparmentsArray
    }

    render() {
        const { open, filterName, filterOptions, handleFilterModal, activeFilters, setActiveFilters } = this.props

        return (
            <Dialog
                open={open}
                onClose={() => handleFilterModal(false)}
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        Filter {filterName}
                        <CloseIcon style={{ cursor: 'pointer' }} onClick={() => handleFilterModal(false)} />
                    </div>

                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        style={{ marginBottom: 16 }}
                    >
                        <GeneralDropdownField
                            key={`mddf-${filterOptions.departmentsFilter?.options?.[0] ?? null}`}
                            options={filterOptions.departmentsFilter?.options}
                            format='multiple'
                            width={12}
                            autoFocus={true}
                            label='Departments *'
                            placeholder='Select Departments...'
                            statePath={`multiDepartmentIds`}
                            value={this.formatDeparmentsListForMultiDropdown()}
                            handleInputChange={(e, data) => setActiveFilters(filterOptions.departmentsFilter.key, data.value)}
                            error={null}
                        />
                    </Grid>
                    <div
                        style={{ marginBottom: 32 }}
                        key={filterOptions.userTypeFilter?.name}
                    >
                        <div
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}
                        >
                            <Typography
                                variant={'h6'}
                            >
                                {filterOptions.userTypeFilter.name}
                            </Typography>
                        </div>

                        <FormGroup
                            row
                        >
                            {filterOptions.userTypeFilter.options.map(option =>
                                <FormControlLabel
                                    key={option?.text}
                                    label={option.text}
                                    control={
                                        <Checkbox
                                            onChange={() => setActiveFilters(filterOptions.userTypeFilter.key, option.value)}
                                            checked={activeFilters[filterOptions.userTypeFilter.key] ? activeFilters[filterOptions.userTypeFilter.key].includes(option.value) : false}
                                        />
                                    }
                                />
                            )}
                        </FormGroup>
                    </div>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withRouter(withStyles(null, { withTheme: true })(UsersFilterModal))