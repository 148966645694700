import { createTheme } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'

const theme = createTheme({
    palette: {
        primary: {
            light: '#FFFFFF',
            lightGrey: '#F5F4F4',
            grey: grey[400],
            main: '#ff9c00',
            dark: '#3B3535',
            contrastText: '#fff',
            error: '#f44336'
        },
        secondary: {
            light: '#ff7961',
            main: '#ff9c00',
            dark: '#ba000d',
            contrastText: '#000'
        }
    }
})

export default theme