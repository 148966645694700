// React
import React, { Component } from 'react'
import { Prompt } from "react-router-dom"

// Material UI
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, CircularProgress, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

// Components
import GeneralTextField from '../general/TextField'
import GeneralDropdownField from '../general/DropdownField'
import ActionConfirmation from '../general/ActionConfirmation'
import { cadTypeOptions } from './createCadDepartmentModal'


class EditCadDepartmentModal extends Component {
    state = {
        isCadDepartmentUnsaved: false,
        confirmDeletion: false
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.beforeunload)
    }

    componentDidUpdate(prevProps, prevState) {
        const { isCadDepartmentUnsaved } = this.state
        if (prevProps.cadDepartment !== this.props.cadDepartment && !isCadDepartmentUnsaved) {
            this.setState({ isCadDepartmentUnsaved: true })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.beforeunload)
    }

    beforeunload = (e) => {
        const { isCadDepartmentUnsaved } = this.state
        if (isCadDepartmentUnsaved) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    openConfirmDeletion = () => {
        this.setState({ confirmDeletion: true })
    }

    closeConfirmDeletion = () => {
        this.setState({ confirmDeletion: false })
    }

    handleClose = () => {
        const { isCadDepartmentUnsaved } = this.state
        if (isCadDepartmentUnsaved) {
            let shouldCloseModal = window.confirm(
                "Changes you made may not be saved. Are you sure you want to leave this page?"
            )
            if (!shouldCloseModal) return
            else this.setState({ isCadDepartmentUnsaved: false })
        }
        this.props.handleClose()
    }

    updateCadDepartment = async () => {
        const response = await this.props.updateCadDepartment()
        const { isCadDepartmentUnsaved } = this.state
        if (response && isCadDepartmentUnsaved) {
            this.setState({ isCadDepartmentUnsaved: false })
        }
    }

    render() {
        const { handleInputChange, deleteCadDepartment, cadDepartment, departments, errors, loaders, open } = this.props
        const { isCadDepartmentUnsaved, confirmDeletion } = this.state

        return (
            <>
                <Prompt
                    when={isCadDepartmentUnsaved}
                    message={() =>
                        "Changes you made may not be saved. Are you sure you want to leave this page?"
                    }
                />
                <Dialog
                    onClose={!loaders.updateCadDepartment && !loaders.deleteCadDepartment && this.handleClose}
                    open={open}
                    maxWidth='md'
                    PaperProps={{ style: { flex: 1 } }}
                >
                    <DialogTitle
                        disableTypography
                        style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <Typography
                            variant='h6'
                        >
                            Edit CAD Department
                        </Typography>
                        <IconButton
                            aria-label='close'
                            onClick={this.handleClose}
                            disabled={loaders.updateCadDepartment}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent
                        dividers
                    >
                        <Grid
                            xs={12}
                            spacing={2}
                            container
                        >
                            <GeneralTextField
                                autoFocus={true}
                                width={12}
                                label='CAD Email *'
                                placeholder='Enter email for CAD integration...'
                                statePath={`cadDepartment.email`}
                                value={cadDepartment.email}
                                error={errors.cadDepartment.email}
                                handleInputChange={handleInputChange}
                            />

                            <GeneralDropdownField
                                options={departments}
                                format="autocomplete"
                                width={12}
                                label={`Department *`}
                                placeholder='Select department...'
                                statePath={`cadDepartment.departmentId`}
                                value={cadDepartment.departmentId}
                                handleInputChange={handleInputChange}
                                error={errors.cadDepartment?.departmentId}
                            />

                            <GeneralDropdownField
                                options={cadTypeOptions}
                                format="autocomplete"
                                width={12}
                                label={`CAD Type *`}
                                placeholder='Select CAD type...'
                                statePath={`cadDepartment.cadType`}
                                value={cadDepartment.cadType}
                                handleInputChange={handleInputChange}
                                error={errors.cadDepartment?.cadType}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <div
                            style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}
                        >
                            <Button
                                onClick={this.openConfirmDeletion}
                                variant='outlined'
                                color='primary'
                            >
                                {loaders.deleteCadDepartment &&
                                    <CircularProgress
                                        size={18}
                                        style={{ marginRight: 4 }}
                                    />
                                }
                                Delete
                            </Button>
                            <Button
                                onClick={this.updateCadDepartment}
                                color='secondary'
                                variant='contained'
                            >
                                {loaders.updateCadDepartment &&
                                    <CircularProgress
                                        size={18}
                                        style={{ marginRight: 4 }}
                                    />
                                }
                                Update
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
                <ActionConfirmation
                    open={confirmDeletion}
                    onClose={this.closeConfirmDeletion}
                    title='Are you sure you want to delete this CAD Department?'
                    onConfirmAction={deleteCadDepartment}
                />
            </>
        )
    }
}

export default EditCadDepartmentModal;
