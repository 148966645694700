import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core';
import moment from 'moment';

/**** the need to have this as a separate component is because we need to keep updating "created at" information
 * e.g. 5 seconds ago, a minute ago, an hour ago ...
 */
const DispatchCreatedAt = ({ createdAt }) => {
    // eslint-disable-next-line no-unused-vars
    const [_time, setTime] = useState(Date.now())
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(Date.now())
        }, 60000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <Typography
            variant='caption'
        >
            {moment(createdAt).fromNow()}
        </Typography>
    )
}

export default DispatchCreatedAt;
