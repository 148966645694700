// React
import React from 'react'
// Material UI
import {
    Grid, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Typography,
    IconButton, Paper, CircularProgress, Button
} from '@material-ui/core'
import MessageIcon from '@material-ui/icons/Message'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline'
import LaunchIcon from '@material-ui/icons/Launch'
import MaterialIcon from 'material-icons-react'
import RadioIcon from '@material-ui/icons/Radio'
// Components
import ViewDispatchModal from '../viewDispatch/index'
import GeneralTextField from '../general/TextField'
import TablePagination from '../general/TablePagination'
import DispatchAudioPlayer from '../general/audio-player/NewDispatchAudioPlayer'
import DispatchStaticMap from '../general/maps/DispatchStaticMap'
// Utilities
import axios from 'axios'
import moment from 'moment'
import theme from '../../utilities/theme'
import { handleInputChange } from '../../utilities/handleChange'
import { populateResponders } from '../general/maps/utils'

const initialState = {
    filteredDispatches: [],
    dispatches: [],
    viewType: 'grid',
    loaders: {
        getDispatches: false
    },
    openDispatch: null,
    info: {
        search: '',
        page: 1, //currentPage
        limit: 25, //itemsPerPage
        pagination: {
            "total": 0,
            "limit": 25,
            "currentPage": 1,
            "totalPages": 0,
            "previous": null,
            "next": null
        }
    },
    errors: {
        info: {

        }
    }
}

class History extends React.Component {
    constructor(props) {
        super(props)
        let viewType = localStorage.getItem('viewType') ?? 'grid'
        this.state = {
            ...initialState,
            viewType
        }
    }

    componentDidMount = async () => {
        await this.getDispatches()

        const { socket } = this.props
        socket.on('change-dispatches', this.getDispatches)
    }

    componentWillUnmount() {
        const { socket } = this.props
        socket.off('change-dispatches', this.getDispatches)
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleViewTypeChange = (viewType) => {
        this.setState({ viewType }, () => (
            localStorage.setItem('viewType', viewType)
        ))
    }

    getDispatches = async () => {
        const { accessToken } = this.props
        const { loaders, info } = this.state
        const { page, limit, search } = info

        if (!loaders.getDispatches) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getDispatches: true } }))

            const config = {
                headers: { Authorization: accessToken },
                params: {
                    page,
                    limit,
                    search
                }
            }

            try {
                const getDispatchesResponse = await axios.get('/api/dispatches', config)
                // console.log({ getDispatchesResponse })

                if (getDispatchesResponse.data.success) {
                    let dispatches = getDispatchesResponse.data.dispatches
                    let pagination = getDispatchesResponse.data.pagination || initialState.info.pagination
                    this.setState(prevState => ({
                        dispatches,
                        filteredDispatches: dispatches,
                        info: { ...prevState.info, pagination },
                        loaders: {
                            ...prevState.loaders,
                            getDispatches: false
                        }
                    }))
                } else {
                    this.setState(prevState => ({
                        dispatches: [],
                        filteredDispatches: [],
                        loaders: {
                            ...prevState.loaders,
                            getDispatches: false
                        }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    dispatches: [],
                    loaders: {
                        ...prevState.loaders,
                        getDispatches: false
                    }
                }))
            }
        }
    }

    searchDispatches = () => {

        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page: 1
            }
        }), this.getDispatches)
    }

    resetSearch = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                search: ''
            }
        }), this.getDispatches)
    }

    handleOpenDispatch = (dispatch) => {
        this.setState({ openDispatch: dispatch })
    }

    //pagination methods
    handleChangePage = (page) => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page
            }
        }), this.getDispatches)
    }

    handleChangeRowsPerPage = (limit) => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page: 1,
                limit
            }
        }), this.getDispatches)
    }

    render() {
        const { accessToken, user } = this.props
        const { dispatches, viewType, openDispatch, info, filteredDispatches, loaders } = this.state
        const { pagination, limit, page, search } = info


        const renderLoader = (isInTable = false) => {
            if (isInTable) {
                if (loaders.getDispatches)
                    return (
                        <TableRow>
                            <TableCell
                                style={{ paddingTop: 96, paddingBottom: 96 }}
                                colSpan={6}
                                align="center"
                            >
                                <CircularProgress
                                    size={36}
                                />

                            </TableCell>
                        </TableRow>
                    )
            }
            else if (!isInTable) {
                if (loaders.getDispatches)
                    return (
                        <Grid
                            item
                            xs={12}
                            style={{ alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 96 }}
                        >
                            <CircularProgress
                                size={36}
                            />
                        </Grid>
                    )
            }
            return
        }
        const renderResetButtons = (isInTable = false) => {
            if (isInTable) {
                if ((!filteredDispatches || !filteredDispatches.length) && !loaders.getDispatches)
                    return (
                        <TableRow>
                            <TableCell
                                style={{ paddingTop: 96, paddingBottom: 96 }}
                                colSpan={6}
                                align="center"
                            >
                                <Typography
                                    variant='body1'
                                >
                                    No dispatches were found.
                                </Typography>
                                {search && search.length &&
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => this.resetSearch()}
                                    >
                                        Reset Search
                                    </Button>
                                }

                            </TableCell>
                        </TableRow>
                    )
            }
            else if (!isInTable) {
                if ((!filteredDispatches || !filteredDispatches.length) && !loaders.getDispatches)
                    return (
                        <Grid
                            item
                            xs={12}
                            style={{ alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 96 }}
                        >
                            <Typography
                                variant='body1'
                            >
                                No dispatches were found.
                            </Typography>
                            {search && search.length &&
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => this.resetSearch()}
                                >
                                    Reset Search
                                </Button>
                            }
                        </Grid>
                    )
            }
            return
        }

        return (
            <>
                {openDispatch &&
                    <ViewDispatchModal
                        accessToken={accessToken}
                        userId={user._id}
                        dispatch={openDispatch}
                        open={openDispatch !== null}
                        closeModal={() => this.handleOpenDispatch(null)}
                        showCreatedBy={user?.type === 'superAdmin' || (user?.type === 'user' && user?.departments?.find(dept => dept.departmentId === openDispatch.departmentId)?.role === 'departmentAdmin')}
                        showIncidentId={typeof openDispatch?.incidentId === 'string' && (user?.type === 'superAdmin' || user?.type === 'dispatcher' || (user?.type === 'user' && user?.departments?.find(dept => dept.departmentId === openDispatch.departmentId)?.role === 'departmentAdmin'))}
                    />
                }
                <Grid
                    container
                    item
                    xs={12}
                    spacing={4}
                    style={viewType === 'grid' ? { padding: 16, margin: 0 } : { paddingTop: 16, paddingBottom: 16, margin: 0 }}
                >
                    <Grid
                        container
                        justifyContent='space-between'
                        style={viewType === 'grid' ? {} : { paddingLeft: `32px !important`, paddingRight: `32px !important` }}
                    >
                        <Grid
                            item
                        >
                            <GeneralTextField
                                format="search"
                                gridStyle={{ width: 350 }}
                                placeholder='Search dispatches...'
                                statePath={`info.search`}
                                value={info.search}
                                handleInputChange={this.handleInputChange}
                                resetSearch={this.resetSearch}
                                onSearch={this.searchDispatches}
                            />
                        </Grid>
                        <Grid
                            item
                        >
                            <IconButton
                                onClick={() => this.handleViewTypeChange('grid')}
                            >
                                <ViewModuleIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => this.handleViewTypeChange('list')}
                            >
                                <ViewHeadlineIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {/* top pagination */}
                    {viewType === 'grid' &&
                        <>
                            <Grid
                                container
                                justify='flex-end'
                            >
                                <TablePagination
                                    showInGrid={true}
                                    label="Items Per Page"
                                    total={pagination.total}
                                    rowsPerPage={limit}
                                    page={page}
                                    handleChangePage={this.handleChangePage}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Grid>
                            {renderLoader()}
                            {renderResetButtons()}
                        </>
                    }
                    {viewType === 'grid' && !loaders.getDispatches && filteredDispatches && filteredDispatches.map((call, index) => (
                        <Grid
                            key={call._id}
                            xs={4}
                            item
                            onClick={() => this.handleOpenDispatch(call)}
                            style={call?.deleted ? { cursor: 'pointer', borderLeft: '4px solid #f04141', borderRadius: 4, opacity: 0.5 } : { cursor: 'pointer' }}
                        >
                            <Grid
                                xs={12}
                                item
                                style={{ borderRadius: 10, backgroundColor: theme.palette.primary.backgroundGrey, height: '100%', }}
                            >
                                <Grid
                                    xs={12}
                                    container
                                    alignItems='center'
                                    justifyContent='space-between'
                                    style={{ padding: 16, paddingTop: 20, paddingLeft: 0 }}
                                >
                                    <span
                                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: theme.palette.primary.main }}
                                    >
                                        {call.callTypeObject ?
                                            <span
                                                style={call.callTypeObject.icon2 ? { marginRight: 24 } : { marginRight: 8 }}
                                            >
                                                <div
                                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                                >
                                                    <MaterialIcon icon={call.callTypeObject.icon1} size='tiny' color={theme.palette.primary.main} />
                                                    {call.callTypeObject.icon2 &&
                                                        <span
                                                            style={{ position: 'absolute', marginRight: -30, marginTop: -10 }}
                                                        >
                                                            <MaterialIcon icon={call.callTypeObject.icon2} size='tiny' color='#ff9800' />
                                                        </span>
                                                    }
                                                </div>
                                            </span>
                                            : (call.isVoice || call.voiceUrl) ?
                                                <span
                                                    style={{ marginRight: 8 }}
                                                >
                                                    <div
                                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                                    >
                                                        <RadioIcon style={{ fontSize: 18, color: theme.palette.primary.main }} />
                                                    </div>
                                                </span>
                                                :
                                                <span
                                                    style={{ marginRight: 8 }}
                                                >
                                                    <div
                                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                                    >
                                                        <MessageIcon style={{ fontSize: 18, color: theme.palette.primary.main }} />
                                                    </div>
                                                </span>
                                        }
                                        <Typography
                                            variant='subtitle2'
                                        >
                                            {call.callTypeObject ? call.callTypeObject.callType : (call.voiceUrl || call.isVoice) ? 'Automated Dispatch' : 'Other'}
                                        </Typography>
                                    </span>
                                    <Typography
                                        variant='caption'
                                    >
                                        {moment(call.createdAt).fromNow()}
                                    </Typography>
                                </Grid>
                                <Grid
                                    xs={12}
                                    style={{ marginBottom: 16, paddingRight: 16 }}
                                >
                                    <Typography
                                        variant='subtitle2'
                                    >
                                        {call.department ? call.department?.name : 'Deleted Department'}
                                    </Typography>
                                </Grid>

                                <DispatchStaticMap
                                    userId={user._id}
                                    dispatchLocation={call?.location}
                                    responders={populateResponders(call)}
                                    style={{ margin: 8 }}
                                />

                                {(call.voiceUrl || call.isVoice) &&
                                    <DispatchAudioPlayer
                                        dispatch={call}
                                    />
                                }
                                {
                                    call.transcriptionText ?
                                        <Grid>
                                            <Typography
                                                variant='body2'
                                                style={{ margin: '0 10px' }}
                                            >
                                                <b>Transcription</b>{`: ${call.transcriptionText}`}
                                            </Typography>
                                        </Grid>
                                        :
                                        null
                                }
                                <Grid
                                    xs={12}
                                    style={{ paddingRight: 16, marginBottom: 16 }}
                                >
                                    <Typography
                                        variant='body1'
                                        style={{ wordBreak: 'break-word' }}
                                    >
                                        {call.message}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>

                    ))
                    }
                    {/* bottom pagination */}
                    {viewType === 'grid' && !loaders.getDispatches && filteredDispatches?.length > 0 &&
                        <>
                            <Grid
                                container
                                justify='flex-end'
                            >
                                <TablePagination
                                    showInGrid={true}
                                    label="Items Per Page"
                                    total={pagination.total}
                                    rowsPerPage={limit}
                                    page={page}
                                    handleChangePage={this.handleChangePage}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Grid>
                        </>
                    }

                    {viewType === 'list' && dispatches &&
                        <TableContainer
                            component={Paper}
                            elevation={0}
                            style={{ borderRadius: 0, marginBottom: 64, width: '100%' }}
                        >
                            <Table
                                aria-label='Users'
                            >
                                <TablePagination
                                    showAtTop={true}
                                    total={pagination.total}
                                    rowsPerPage={limit}
                                    page={page}
                                    handleChangePage={this.handleChangePage}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Department
                                        </TableCell>
                                        <TableCell>
                                            Call Type
                                        </TableCell>
                                        <TableCell>
                                            Address
                                        </TableCell>
                                        <TableCell>
                                            Message
                                        </TableCell>
                                        <TableCell>
                                            Date
                                        </TableCell>
                                        <TableCell
                                            align='right'
                                        >

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!loaders.getDispatches && filteredDispatches.map((call) =>
                                        <TableRow
                                            key={call._id}
                                            onClick={() => this.handleOpenDispatch(call)}
                                            style={call?.deleted ? { cursor: 'pointer', borderLeft: '4px solid #f04141', borderRadius: 4, opacity: 0.5 } : { cursor: 'pointer' }}
                                        >
                                            <TableCell>
                                                {call.department ? call.department.name : 'Deleted Department'}
                                            </TableCell>
                                            <TableCell>
                                                {call.callTypeObject ? call.callTypeObject.callType : (call.voiceUrl || call.isVoice) ? 'Automated Dispatch' : 'Other'}
                                            </TableCell>
                                            <TableCell>
                                                {call?.location?.addressString}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    whiteSpace: "normal",
                                                    wordBreak: "break-word"
                                                }}
                                            >
                                                {call.message}
                                            </TableCell>
                                            <TableCell>
                                                {moment(call.createdAt).fromNow()}
                                            </TableCell>
                                            <TableCell
                                                align='right'
                                                width='10'
                                            >
                                                <IconButton
                                                    // onClick = { () => this.handleModal('editUser', call) }
                                                    size='small'
                                                    disableFocusRipple={true}
                                                    disableRipple={true}
                                                >
                                                    <LaunchIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {renderLoader(true)}
                                    {renderResetButtons(true)}
                                </TableBody>


                                <TablePagination
                                    total={pagination.total}
                                    rowsPerPage={limit}
                                    page={page}
                                    handleChangePage={this.handleChangePage}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Table>
                        </TableContainer>
                    }
                </Grid>
            </>
        )
    }
}

export default History
