// React
import React from 'react'
import { withRouter } from 'react-router-dom'
// Material UI
import { Typography, withStyles, Grid, Paper } from '@material-ui/core'
// Utilities
import theme from '../../../utilities/theme'
import moment from 'moment-timezone'

const firetextAnnouncementStyle = { minWidth: 440, maxWidth: 660, padding: 16, marginBottom: 16, cursor: 'pointer', backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.light }
class FiretextAnnouncement extends React.Component {



    render() {
        const { user, firetextAnnouncement } = this.props

        return (
            firetextAnnouncement?.isFacebookAnnouncement ?
                <FacebookAnnouncementCard
                    {...this.props}
                />
                :
                <FiretextAnnouncementCard
                    {...this.props}
                />
        )
    }
}

export default withRouter(withStyles(null, { withTheme: true })(FiretextAnnouncement));



const FiretextAnnouncementCard = ({ firetextAnnouncement, user, firetextAnnouncementMarkAsRead, history, handleNotificationsDropdownClick }) => {

    const onFiretextAnnouncementNotificationClick = async () => {
        if (!firetextAnnouncement.readByUserIds?.includes?.(user._id)) {
            firetextAnnouncementMarkAsRead(firetextAnnouncement._id)
        }
        handleNotificationsDropdownClick()
        history.push({ pathname: '/firetext-announcement', state: { firetextAnnouncement } })
    }

    return (
        <Paper
            key={firetextAnnouncement._id}
            onClick={onFiretextAnnouncementNotificationClick}
            style={firetextAnnouncement.readByUserIds.includes(user._id) ? firetextAnnouncementStyle : { ...firetextAnnouncementStyle, border: `2px solid ${theme.palette.secondary.main}` }}
        >
            <Grid container direction="row" >
                <Grid container>
                    <Grid container style={{ flexDirection: 'column', marginBottom: 12 }}>
                        <Typography variant="h6" gutterBottom style={{ whiteSpace: 'pre-line', fontSize: 18 }}>
                            {firetextAnnouncement.departmentObject?.name ?? 'FireTEXT'}
                        </Typography>
                        <Typography variant="caption" gutterBottom style={{ whiteSpace: 'pre-line' }}>
                            FireTEXT Announcement
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography variant="body1" gutterBottom style={{ whiteSpace: 'pre-line' }}>
                            {firetextAnnouncement.text ?? ''}
                        </Typography>
                    </Grid>
                    <Grid container justifyContent='space-between' style={{ marginTop: 16 }}>
                        {firetextAnnouncement.createdByObject?.firstName && firetextAnnouncement.createdByObject?.lastName &&
                            <Typography variant="caption" gutterBottom>
                                {`Created by: ${firetextAnnouncement.createdByObject.lastName} ${firetextAnnouncement.createdByObject.lastName}`}
                            </Typography>
                        }
                        <Typography variant="caption" gutterBottom>
                            Created at: {moment.tz(new Date(firetextAnnouncement.createdAt), moment.tz.guess()).format('MM/DD/YY HH:mm:ss z')}
                        </Typography>

                    </Grid>
                </Grid>
            </Grid>
        </Paper >
    )
}
const FacebookAnnouncementCard = ({ firetextAnnouncement, user, firetextAnnouncementMarkAsRead, history, handleNotificationsDropdownClick }) => {

    const onFiretextAnnouncementNotificationClick = async () => {
        if (!firetextAnnouncement.readByUserIds?.includes?.(user._id)) {
            firetextAnnouncementMarkAsRead(firetextAnnouncement._id)
        }
        handleNotificationsDropdownClick()
        if (firetextAnnouncement?.pageId)
            return window.open(`https://facebook.com/${firetextAnnouncement.pageId}`, '_blank')
    }

    return (
        <Paper
            key={firetextAnnouncement._id}
            onClick={onFiretextAnnouncementNotificationClick}
            style={firetextAnnouncement.readByUserIds.includes(user._id) ? firetextAnnouncementStyle : { ...firetextAnnouncementStyle, border: `2px solid ${theme.palette.secondary.main}` }}
        >
            <Grid container direction="row" >
                <Grid container>
                    <Grid container item style={{ flexDirection: 'column', marginBottom: 12 }}>
                        <Typography variant="h6" gutterBottom style={{ whiteSpace: 'pre-line', fontSize: 18 }}>
                            {firetextAnnouncement.departmentObject?.name ?? 'FireTEXT'}
                        </Typography>
                        <Typography variant="caption" gutterBottom style={{ whiteSpace: 'pre-line' }}>
                            {`Facebook Announcement - ${firetextAnnouncement?.pageName ? firetextAnnouncement.pageName : ''}`}
                        </Typography>

                    </Grid>
                    <Grid container>
                        <Typography variant="body1" gutterBottom style={{ whiteSpace: 'pre-line' }}>
                            {firetextAnnouncement.text ?? ''}
                        </Typography>
                    </Grid>
                    <Grid container justifyContent='space-between' style={{ marginTop: 16 }}>
                        {firetextAnnouncement.createdByObject?.firstName && firetextAnnouncement.createdByObject?.lastName &&
                            <Typography variant="caption" gutterBottom>
                                {`Created by: ${firetextAnnouncement.createdByObject.lastName} ${firetextAnnouncement.createdByObject.lastName}`}
                            </Typography>
                        }
                        <Typography variant="caption" gutterBottom>
                            Created at: {moment.tz(new Date(firetextAnnouncement.createdAt), moment.tz.guess()).format('MM/DD/YY HH:mm:ss z')}
                        </Typography>

                    </Grid>
                </Grid>
            </Grid>
        </Paper >
    )
}