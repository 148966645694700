// React
import { Component } from 'react'
import { Prompt } from "react-router-dom"

// Material UI
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, CircularProgress, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

// Components
import GeneralTextField from '../../general/TextField'
import GeneralCheckboxField from '../../general/CheckboxField'
import ActionConfirmation from '../../general/ActionConfirmation'

// Utilities
import DateTimePickerWrapper, { DATE_FORMAT } from '../../general/dateTimePicker/DateTimePicker'

class EditFiretextAnnouncementModal extends Component {
    state = {
        isUnsaved: false,
        confirmDeletion: false
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.beforeunload)
    }

    componentDidUpdate(prevProps, prevState) {
        const { isUnsaved } = this.state
        if (prevProps.firetextAnnouncement !== this.props.firetextAnnouncement && !isUnsaved) {
            this.setState({ isUnsaved: true })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.beforeunload)
    }

    beforeunload = (e) => {
        const { isUnsaved } = this.state
        if (isUnsaved) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    openConfirmDeletion = () => {
        this.setState({ confirmDeletion: true })
    }

    closeConfirmDeletion = () => {
        this.setState({ confirmDeletion: false })
    }

    handleClose = () => {
        const { isUnsaved } = this.state
        if (isUnsaved) {
            let shouldCloseModal = window.confirm(
                "Changes you made may not be saved. Are you sure you want to leave this page?"
            )
            if (!shouldCloseModal) return
            else this.setState({ isUnsaved: false })
        }
        this.props.handleClose()
    }

    updateFiretextAnnouncement = async () => {
        const response = await this.props.updateFiretextAnnouncement()
        const { isUnsaved } = this.state
        if (response && isUnsaved) {
            this.setState({ isUnsaved: false })
        }
    }

    render() {
        const { handleInputChange, deleteFiretextAnnouncement, firetextAnnouncement, errors, loaders, open } = this.props
        const { isUnsaved, confirmDeletion } = this.state

        return (
            <>
                <Prompt
                    when={isUnsaved}
                    message={() =>
                        "Changes you made may not be saved. Are you sure you want to leave this page?"
                    }
                />
                <Dialog
                    onClose={!loaders.updateFiretextAnnouncement && !loaders.deleteFiretextAnnouncement ? this.handleClose : undefined}
                    open={open}
                    maxWidth='md'
                    PaperProps={{ style: { flex: 1 } }}
                >
                    <DialogTitle
                        disableTypography
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            variant='h6'
                        >
                            {`Edit ${firetextAnnouncement.departmentObject?.name ?? 'FireTEXT'} Announcement`}
                        </Typography>
                        <IconButton
                            aria-label='close'
                            onClick={this.handleClose}
                            disabled={loaders.updateFiretextAnnouncement}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent
                        dividers
                        style={{ minHeight: '500px' }}
                    >
                        <Grid
                            xs={12}
                            spacing={2}
                            container
                        >
                            <GeneralTextField
                                autoFocus={true}
                                multiline={true}
                                row={3}
                                width={12}
                                label='Announcement *'
                                placeholder='Enter announcement...'
                                statePath={`firetextAnnouncement.text`}
                                value={firetextAnnouncement.text}
                                error={errors.firetextAnnouncement.text}
                                handleInputChange={handleInputChange}
                            />

                            <DateTimePickerWrapper
                                width={12}
                                label='Expires'
                                placeholder='Specify announcement duration...'
                                statePath={`firetextAnnouncement.expiresMilli`}
                                value={firetextAnnouncement.expiresMilli}
                                error={errors.firetextAnnouncement.expiresMilli}
                                handleInputChange={handleInputChange}
                                format={DATE_FORMAT.milliSeconds}
                            />

                            <Grid
                                xs={12}
                                container
                                item
                            >

                                <Typography
                                    variant='subtitle2'
                                    style={{ marginBottom: 8 }}
                                >
                                    Notification Channels
                                </Typography>

                                <Grid
                                    xs={12}
                                    container
                                    item
                                >
                                    <GeneralCheckboxField
                                        width={12}
                                        size='small'
                                        label='Push Notification'
                                        statePath={`firetextAnnouncement.sendPushNotification`}
                                        value={firetextAnnouncement.sendPushNotification}
                                        error={errors?.firetextAnnouncement.sendPushNotification}
                                        handleInputChange={handleInputChange}
                                        loader={false}
                                        disabled={true}
                                    />
                                    <GeneralCheckboxField
                                        gridStyle={{ marginTop: 8 }}
                                        width={12}
                                        size='small'
                                        label='Email Notification'
                                        statePath={`firetextAnnouncement.sendEmailNotification`}
                                        value={firetextAnnouncement.sendEmailNotification}
                                        error={errors?.firetextAnnouncement.sendEmailNotification}
                                        handleInputChange={handleInputChange}
                                        loader={false}
                                        disabled={true}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <div
                            style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}
                        >
                            <Button
                                onClick={this.openConfirmDeletion}
                                variant='contained'
                                color='primary'
                                style={{ backgroundColor: '#ff0000' }}
                            >
                                {loaders.deleteFiretextAnnouncement &&
                                    <CircularProgress
                                        size={18}
                                        style={{ marginRight: 8 }}
                                    />
                                }
                                Delete
                            </Button>
                            <Button
                                onClick={this.updateFiretextAnnouncement}
                                color='secondary'
                                variant='contained'
                            >
                                {loaders.updateFiretextAnnouncement &&
                                    <CircularProgress
                                        size={18}
                                        style={{ marginRight: 8 }}
                                    />
                                }
                                Update
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
                <ActionConfirmation
                    open={confirmDeletion}
                    onClose={this.closeConfirmDeletion}
                    title='Are you sure you want to delete this announcement?'
                    onConfirmAction={deleteFiretextAnnouncement}
                />
            </>
        )
    }
}

export default EditFiretextAnnouncementModal;
