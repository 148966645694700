// React
import React, { createRef } from 'react'

// Material UI
import {
    Grid, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, FormLabel, FormControlLabel,
    RadioGroup, Radio, Typography, CircularProgress, Button, IconButton, Tooltip
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import InfoIcon from '@material-ui/icons/Info'

// Utilities
import axios from 'axios'
import GeneralTextField from '../general/TextField'
import GeneralDropdownField from '../general/DropdownField'
import { handleInputChange, handleErrors } from '../../utilities/handleChange'
import AttachmentsComponent from '../viewDispatch/AttachmentsComponent'


const initialState = {
    allCallTypes: [],
    selectedDepartmentCallTypes: [],
    multiDepartmentIds: [],
    singleDepartmentId: null,
    dispatch: {
        callTypeId: null,
        location: {
            address: null,
            addressString: "",
            coordinates: null,
            googlePlaceId: null
        },
        message: null
    },
    newAttachments: [],
    errors: {
        dispatch: {
            location: {
                address: {},
                coordinates: {}
            }
        }
    },
    loaders: {
        getAllCallTypes: false,
        getSelectedDepartmentCallTypes: false,
    }
}

class DispatchCallModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...initialState,
            singleDepartmentId: props.departmentId ?? null,
            department: props.departments?.find(dept => dept._id === props.departmentId) ?? null
        }
        this.mapRef = createRef()
        this.radioRef = createRef(null)
    }

    componentDidMount = async () => {
        this.state.singleDepartmentId ? await this.getDepartmentCallTypes() : await this.getAllCallTypes()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.selectedDepartmentCallTypes?.length === 0 && this.state.selectedDepartmentCallTypes?.length > 0) {
            this.radioRef.current?.focusVisible()
        }
        if (prevState.allCallTypes?.length === 0 && this.state.allCallTypes?.length > 0) {
            this.radioRef.current?.focusVisible()
        }
    }

    setDispatchLocation = (place) => {
        if (!place.geometry || !place.geometry.location) {
            const location = {
                address: null,
                addressString: null,
                coordinates: null,
                googlePlaceId: null
            }
            window.alert("No details available for location: '" + place.name + "'")

            this.setState(prevState => ({
                dispatch: { ...prevState.dispatch, location }
            }))
            return
        }

        const coordinates = {
            latitude: place.geometry?.location?.lat(),
            longitude: place.geometry?.location?.lng()
        }
        const location = {
            address: null,
            addressString: place.formatted_address,
            coordinates,
            googlePlaceId: place?.place_id
        }

        this.setState(handleInputChange(null, { statePath: 'dispatch.location', value: location }, this.state))
    }

    handleDispatchLocationChange = (e, data) => {
        const statePath = 'dispatch.location'
        const value = {
            address: data.value,
            addressString: data.value,
            coordinates: null,
            googlePlaceId: null
        }

        this.setState(handleInputChange(e, { statePath, value }, this.state))
    }

    getDepartmentCallTypes = async () => {
        const { singleDepartmentId, loaders } = this.state

        if (!singleDepartmentId)
            return


        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.getSelectedDepartmentCallTypes) {
            this.setState(prevState => ({
                multiDepartmentIds: [],
                loaders: { ...prevState.loaders, getSelectedDepartmentCallTypes: true }
            }))
            try {
                const getSelectedDepartmentCallTypesResponse = await axios.get(`/api/departments/${singleDepartmentId}/calltypes`, config)

                if (getSelectedDepartmentCallTypesResponse && getSelectedDepartmentCallTypesResponse.data && getSelectedDepartmentCallTypesResponse.data.success) {
                    let selectedDepartmentCallTypes = getSelectedDepartmentCallTypesResponse.data.callTypes
                    this.setState(prevState => ({
                        selectedDepartmentCallTypes: selectedDepartmentCallTypes.filter(ct => ct.callType !== 'Automatic Dispatch').sort((a, b) => a.callType.localeCompare(b.callType)),
                        loaders: { ...prevState.loaders, getSelectedDepartmentCallTypes: false }
                    }))
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: getSelectedDepartmentCallTypesResponse.data.errors },
                        loaders: { ...prevState.loaders, getSelectedDepartmentCallTypes: false }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getSelectedDepartmentCallTypes: false }
                }))
            }
        }
    }

    getAllCallTypes = async () => {
        const { loaders } = this.state

        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.getAllCallTypes) {
            this.setState(prevState => ({
                singleDepartmentId: null,
                loaders: { ...prevState.loaders, getAllCallTypes: true }
            }))
            try {
                const getAllCallTypesResponse = await axios.get(`/api/calltypes`, config)

                if (getAllCallTypesResponse?.data?.success === true) {
                    let allCallTypes = getAllCallTypesResponse.data.callTypes
                    this.setState(prevState => ({
                        allCallTypes: allCallTypes.filter(ct => ct.callType !== 'Automatic Dispatch').sort((a, b) => a.callType.localeCompare(b.callType)),
                        loaders: { ...prevState.loaders, getAllCallTypes: false }
                    }))
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: getAllCallTypesResponse.data.errors },
                        loaders: { ...prevState.loaders, getAllCallTypes: false }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getAllCallTypes: false }
                }))
            }
        }
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleSelectedAttachments = (event) => {
        let files = [...event.target.files]

        if (!files?.length) return
        if (files.length > 5 || (files.length + this.state.newAttachments.length) > 5) {
            return alert('Can only add upto 5 attachments at once')
        }

        for (const file of files) {
            const fileSizeInMbs = file.size / 1000000
            if (fileSizeInMbs > 5) {
                return alert('Each attachment must be less than 5MB')
            }
        }

        const newAttachments = []
        for (const file of files) {
            newAttachments.push(file)
        }
        this.setState({ newAttachments: [...this.state.newAttachments, ...newAttachments] })
    }

    deleteAttachment = ({ type, url, index }) => {
        const { dispatch } = this.state
        if (type === 'attachment') {
            this.setState(prevState => ({
                dispatch: {
                    ...prevState.dispatch,
                    attachments: dispatch.attachments.filter(att => att?.url !== url)
                }
            }))
        }
        else if (type === 'newAttachments') {
            this.setState(prevState => ({
                newAttachments: prevState.newAttachments.filter((att, idx) => idx !== index)
            }))
        }
    }

    formatDeparmentsListForMultiDropdown = () => {
        const formattedUserDeparmentsArray = this.state.multiDepartmentIds.map(departmentId => {
            return {
                text: this.props.departments?.find(dept => dept._id === departmentId)?.text,
                value: departmentId
            }
        })
        return formattedUserDeparmentsArray
    }

    handleErrors = () => {
        const { singleDepartmentId } = this.state
        let errors = { ...this.state.errors }
        let fields = [
            {
                path: 'singleDepartmentId',
                type: 'string',
                shouldCheck: !!singleDepartmentId
            },
            {
                path: 'multiDepartmentIds',
                type: 'stringArray',
                shouldCheck: !singleDepartmentId
            }
        ]

        let frontEndErrors = handleErrors(this.state, errors, fields)

        return frontEndErrors
    }

    sendDispatch = () => {
        const { dispatch, singleDepartmentId, multiDepartmentIds, newAttachments } = this.state
        const { loaders, sendDispatch } = this.props
        if (!loaders.sendDispatch) {
            const { success, errors } = this.handleErrors()
            if (success) {
                sendDispatch({ dispatch: { ...dispatch, newAttachments }, singleDepartmentId, multiDepartmentIds })
            } else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }
        }
    }

    render() {
        const { handleClose, open, departments, loaders: propLoaders } = this.props
        const { allCallTypes, selectedDepartmentCallTypes, dispatch, singleDepartmentId, multiDepartmentIds, department, newAttachments, loaders, errors } = this.state

        const titleText = singleDepartmentId ? `Send a dispatch to ${department?.name}` : `Send a dispatch`
        const callTypes = singleDepartmentId ? selectedDepartmentCallTypes : allCallTypes

        const departmentOptions = departments?.map(department => {
            if (multiDepartmentIds.includes(department.value))
                return { ...department, disabled: true }
            return department
        })

        return (
            <Dialog
                onClose={handleClose}
                aria-labelledby='create-dispatch-dialog'
                open={open}
                maxWidth='md'
                fullWidth
                TransitionProps={{
                    onExited: () => this.setState(initialState)
                }}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <DialogTitle
                        id='create-dispatch-title'
                        onClose={handleClose}
                    >
                        {titleText}
                    </DialogTitle>
                    <IconButton
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogContent
                    style={{ overflowY: 'auto' }}
                    dividers
                >
                    <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        style={{ marginBottom: 16 }}
                    >
                        {!singleDepartmentId &&
                            <GeneralDropdownField
                                key={`mdd-${multiDepartmentIds[0] ?? null}`}
                                options={departmentOptions}
                                format='multiple'
                                width={12}
                                autoFocus={true}
                                label='Departments *'
                                placeholder='Select Departments...'
                                statePath={`multiDepartmentIds`}
                                value={this.formatDeparmentsListForMultiDropdown()}
                                handleInputChange={this.handleInputChange}
                                error={errors.multiDepartmentIds}
                            />
                        }
                    </Grid>
                    <FormControl
                        style={{ width: '100%', marginBottom: 16 }}
                        component='fieldset'
                    >
                        <FormLabel
                            component='legend'
                            style={{ display: 'flex' }}
                        >
                            <Typography
                                variant='subtitle2'
                            >
                                Call Type
                            </Typography>
                            <Tooltip
                                title={'To request a new Call Type, please contact support via the side menu.'}
                                placement="top-start"
                            >
                                <InfoIcon
                                    fontSize='small'
                                    style={{ marginLeft: 2 }}
                                />
                            </Tooltip>
                        </FormLabel>
                        {!singleDepartmentId && !multiDepartmentIds?.length &&
                            <Typography
                                variant='subtitle2'
                                style={{ marginTop: 4 }}
                            >
                                Please select a department to select a call type.
                            </Typography>
                        }

                        <RadioGroup
                            style={{ flex: 1 }}
                            row
                            aria-label='callTypeId'
                            name='callTypeId'
                            value={dispatch.callTypeId}
                            onChange={(e, value) => this.setState(prevState => ({ dispatch: { ...prevState.dispatch, callTypeId: value } }))}
                        >
                            {(!loaders.getSelectedDepartmentCallTypes && !loaders.getAllCallTypes) ?
                                <Grid
                                    container
                                    item
                                    xs={12}
                                >
                                    {callTypes.map((callType, idx) => (
                                        <Grid
                                            key={`${callType._id}`}
                                            container
                                            item
                                            xs={callTypes.length > 22 ? 4 : 6}
                                            justifyContent='flex-start'
                                            style={{ display: 'flex', height: 26 }}
                                        >
                                            <FormControlLabel
                                                value={callType._id}
                                                control={
                                                    <Radio
                                                        color='primary'
                                                        size="small"
                                                        autoFocus={idx === 0 && !!singleDepartmentId}
                                                        action={(idx === 0 && !!singleDepartmentId) ? this.radioRef : null}
                                                    />
                                                }
                                                label={
                                                    <div
                                                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
                                                    >
                                                        <Typography
                                                            variant='body2'
                                                            style={{ textAlign: 'left' }}
                                                        >
                                                            {callType.callType.toUpperCase()}
                                                        </Typography>
                                                    </div>
                                                }
                                                labelPlacement='end'
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                :
                                <CircularProgress
                                    size={18}
                                    style={{ marginLeft: "auto", marginRight: "auto" }}
                                />
                            }
                        </RadioGroup>
                    </FormControl>
                    <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                    >
                        <GeneralTextField
                            multiline
                            width={12}
                            label='Message'
                            placeholder='Enter message here...'
                            statePath={`dispatch.message`}
                            value={dispatch.message}
                            error={errors.dispatch.message}
                            handleInputChange={this.handleInputChange}
                        />
                        <Typography
                            variant='caption'
                            style={{ marginLeft: 15 }}
                        >
                            Certain words may be subject to censorship or alteration based on prevailing laws and ethical standards.
                        </Typography>
                        <GeneralTextField
                            id='pac-input'
                            format="googlePlacesAutocomplete"
                            width={12}
                            label='Address'
                            placeholder='Enter address here...'
                            statePath={`dispatch.location.addressString`}
                            value={dispatch.location.addressString}
                            error={errors.dispatch.location.addressString}
                            handleInputChange={this.handleDispatchLocationChange}
                            setLocation={this.setDispatchLocation}
                        />
                    </Grid>
                    <AttachmentsComponent
                        newAttachments={newAttachments}
                        attachments={[]}
                        allowEdit={true}
                        allowDelete={true}
                        handleSelectedAttachments={this.handleSelectedAttachments}
                        deleteAttachment={this.deleteAttachment}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={propLoaders.sendDispatch}
                        onClick={this.sendDispatch}
                        color='secondary'
                        variant='contained'
                    >
                        {propLoaders.sendDispatch &&
                            <CircularProgress
                                size={18}
                                style={{ marginRight: 8 }}
                            />
                        }
                        Send Dispatch
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default DispatchCallModal
