const counties = [
    { label: "Arkansas County", val: "AWW AR CO" },
    { label: "Ashley County", val: "AWW AS CO" },
    { label: "Baxter County", val: "AWW BX CO" },
    { label: "Benton County", val: "AWW BT CO" },
    { label: "Boone County", val: "AWW BN CO" },
    { label: "Bradley County", val: "AWW BR CO" },
    { label: "Calhoun County", val: "AWW CN CO" },
    { label: "Carroll County", val: "AWW CA CO" },
    { label: "Chicot County", val: "AWW CH CO" },
    { label: "Clark County", val: "AWW CK CO" },
    { label: "Clay County", val: "AWW CL CO" },
    { label: "Cleburne County", val: "AWW CB CO" },
    { label: "Cleveland County", val: "AWW CV CO" },
    { label: "Columbia County", val: "AWW CO CO" },
    { label: "Conway County", val: "AWW CW CO" },
    { label: "Craighead County", val: "AWW CG CO" },
    { label: "Crawford County", val: "AWW CF CO" },
    { label: "Crittenden County", val: "AWW CD CO" },
    { label: "Cross County", val: "AWW CR CO" },
    { label: "Dallas County", val: "AWW DA CO" },
    { label: "Desha County", val: "AWW DE CO" },
    { label: "Drew County", val: "AWW DR CO" },
    { label: "Faulkner County", val: "AWW FA CO" },
    { label: "Franklin County", val: "AWW FK CO" },
    { label: "Fulton County", val: "AWW FU CO" },
    { label: "Garland County", val: "AWW GD CO" },
    { label: "Grant County", val: "AWW GR CO" },
    { label: "Greene County", val: "AWW GN CO" },
    { label: "Hempstead County", val: "AWW HE CO" },
    { label: "Hot Spring County", val: "AWW HS CO" },
    { label: "Howard County", val: "AWW HO CO" },
    { label: "Independence County", val: "AWW IN CO" },
    { label: "Izard County", val: "AWW IZ CO" },
    { label: "Jackson County", val: "AWW JK CO" },
    { label: "Jefferson County", val: "AWW JE CO" },
    { label: "Johnson County", val: "AWW JN CO" },
    { label: "Lafayette County", val: "AWW LF CO" },
    { label: "Lawrence County", val: "AWW LW CO" },
    { label: "Lee County", val: "AWW LE CO" },
    { label: "Lincoln County", val: "AWW LN CO" },
    { label: "Little River County", val: "AWW LR CO" },
    { label: "Logan County", val: "AWW LG CO" },
    { label: "Lonoke County", val: "AWW LO CO" },
    { label: "Madison County", val: "AWW MA CO" },
    { label: "Marion County", val: "AWW MR CO" },
    { label: "Miller County", val: "AWW MI CO" },
    { label: "Mississippi County", val: "AWW MS CO" },
    { label: "Monroe County", val: "AWW MO CO" },
    { label: "Montgomery County", val: "AWW MN CO" },
    { label: "Nevada County", val: "AWW NV CO" },
    { label: "Newton County", val: "AWW NE CO" },
    { label: "Ouachita County", val: "AWW OU CO" },
    { label: "Perry County", val: "AWW PY CO" },
    { label: "Phillips County", val: "AWW PH CO" },
    { label: "Pike County", val: "AWW PI CO" },
    { label: "Poinsett County", val: "AWW PS CO" },
    { label: "Polk County", val: "AWW PK CO" },
    { label: "Pope County", val: "AWW PO CO" },
    { label: "Prairie County", val: "AWW PR CO" },
    { label: "Pulaski County", val: "AWW PU CO" },
    { label: "Randolph County", val: "AWW RH CO" },
    { label: "St. Francis County", val: "AWW SF CO" },
    { label: "Saline County", val: "AWW SA CO" },
    { label: "Scott County", val: "AWW SC CO" },
    { label: "Searcy County", val: "AWW SR CO" },
    { label: "Sebastian County", val: "AWW SB CO" },
    { label: "Sevier County", val: "AWW SV CO" },
    { label: "Sharp County", val: "AWW SH CO" },
    { label: "Stone County", val: "AWW ST CO" },
    { label: "Union County", val: "AWW UN CO" },
    { label: "Van Buren County", val: "AWW VB CO" },
    { label: "Washington County", val: "AWW WA CO" },
    { label: "White County", val: "AWW WH CO" },
    { label: "Woodruff County", val: "AWW WD CO" },
    { label: "Yell County", val: "AWW YL CO" }
]

export default counties;