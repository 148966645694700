import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import theme from '../../utilities/theme'

const UpgradeToPremium = (props) => {
    const { isModal, closeModal, isOpen, upgradeToPremiumText } = props

    if (isModal) {
        // Render content in a Dialog
        return (
            <Dialog
                open={isOpen}
                onClose={closeModal}
                fullWidth
                maxWidth='md'
                PaperProps={{ style: { flex: 1, width: '100%', height: '40%' } }}
            >
                <DialogTitle
                    disableTypography
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        minHeight: 64,
                        maxHeight: 64
                    }}
                >
                    <Typography
                        variant='h6'
                    >
                        Upgrade To Premium
                    </Typography>
                    <div
                        style={{ display: 'flex' }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={closeModal}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}
                    dividers
                >
                    <UpgradeToPremiumText
                        upgradeToPremiumText={upgradeToPremiumText}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={closeModal}
                        color='secondary'
                        variant='contained'
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    } else return (
        <UpgradeToPremiumText
            upgradeToPremiumText={upgradeToPremiumText}
        />
    )
}

export default UpgradeToPremium


const UpgradeToPremiumText = ({ upgradeToPremiumText }) => {
    const href = 'https://firetext.net/support'

    return (
        <Grid
            style={{ flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            container
        >
            <Typography variant="body1" align='center'>{upgradeToPremiumText}</Typography>
            <Grid
                style={{ marginTop: 12 }}
            >
                <a
                    href={href}
                    target='_blank'
                    rel="noreferrer"
                    // onClick={handleLinkClick}
                    style={{ color: theme.palette.primary.main, fontSize: 16, fontWeight: 'bold', marginTop: 12 }}
                >
                    {` Upgrade To Premium. `}
                </a>
            </Grid>
        </Grid>
    )
}
