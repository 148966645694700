// React
import React from 'react'

// Material UI
import { Grid, Tabs, Tab, withStyles, Button, styled } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { withSnackbar } from 'notistack'

// Components
import CreateNewUserModal from '../../users/CreateNewUserModal/index'
import EditUserModal from '../../users/EditUserModal'
import GeneralTextField from '../../../general/TextField'
import UsersTable from '../../users/UsersTable'

// Utilities
import axios from 'axios'
import _ from 'underscore'
import { handleInputChange, handleErrors } from '../../../../utilities/handleChange'
import { Email, ValidateAndTransformToBackendPhoneNumber } from '../../../../utilities/Validate'
import { OpenSnackBar } from '../../../../utilities/handleSnackBar'
import theme from '../../../../utilities/theme'
import { UserRolesInDepartment } from '../../../../utilities/Options'


const TABS_USERS_TYPES = {
    user: 'user',
    dispatcher: 'dispatcher'
}
const initialState = {
    users: [],
    filteredUsers: [],
    user: {
        email: null,
        phone: null,
        type: null,
        departments: [],
        deactivated: false,
        phoneNumberOnly: false,
        notifications: {
            sms: 2,
            phoneCall: 1,
            push: 1,
            email: 1,
            availability: {
                type: "always",
                timeslots: []
            }
        }
    },
    tabsUserType: TABS_USERS_TYPES.user,
    info: {
        search: '',
        page: 1, //currentPage
        limit: 25, //itemsPerPage
        pagination: {
            "total": 0,
            "limit": 25,
            "currentPage": 1,
            "totalPages": 0,
            "previous": null,
            "next": null
        }
    },
    errors: {
        user: {
            departments: [
                { deactivated: null, departmentId: null, role: null } // this is here because of default selected department on create user
            ],
            alerts: {},
            notifications: {}
        },
        info: {

        }
    },
    modals: {
        createNewUser: false,
        editUser: false,
        filterModal: false
    },
    loaders: {
        getUsers: false,
        createNewUser: false,
        updateUser: false,
        reactivateUser: false,
        deactivateUser: false,
        forgotPassword: false,
        resendActivationLink: false,
        sendTestSms: false
    },
    activeFilters: {
        type: []
    },
    filterOptions: [
        {
            name: 'User Types',
            key: 'type',
            options: [
                { text: 'User', value: 'user' },
                { text: 'Dispatcher', value: 'dispatcher' }
            ]
        }
    ]
}


const UserTypeTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
        width: '100%',
        backgroundColor: theme.palette.secondary.main,
    },
})

const UserTypeTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        border: 0,
        fontWeight: theme.typography.fontWeightBold,
        '&.Mui-selected': {
            border: 0,
            '&:last-child': {
                border: 0
            }
        }
    }),
)

class DepartmentUsersTab extends React.Component {
    constructor(props) {
        super(props)
        const { department, departments } = props
        this.state = {
            ...initialState,
            department,
            departments
        }
    }

    // Life cycle functions
    componentDidMount = async () => {
        await this.getUsers()
    }

    componentWillUnmount = () => {
        this.props.closeSnackbar()
    }

    onUserTypeChange = (type) => {
        let departments = this.state.user?.departments || []
        let invites = this.state.user?.invites || []
        let errors = this.state.errors
        switch (type) {
            case "superAdmin":
                departments = []
                invites = []
                break
            case "user":
                departments = (this.state.user?.departments || []).map(dept => ({ ...dept, role: "subscriber" }))
                if (!departments?.length) {
                    departments = [{ departmentId: null, role: null }]
                    errors = {
                        ...this.state.errors,
                        user: {
                            ...this.state.errors.user,
                            departments: [{}]
                        }
                    }
                }
                invites = (this.state.user?.invites || []).map(dept => ({ ...dept, role: "subscriber" }))
                break
            case "dispatcher":
                departments = (this.state.user?.departments || []).filter(dept => dept?.departmentId).map(dept => ({ ...dept, role: "dispatcher" }))
                invites = (this.state.user?.invites || []).map(dept => ({ ...dept, role: "dispatcher" }))
                break
            default:
                return
        }
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                departments,
                invites
            },
            errors
        }))
    }

    // API
    getUsers = async () => {
        const { tabsUserType, department, loaders, info } = this.state
        const { page, limit, search } = info

        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            },
            params: {
                page,
                limit,
                search,
                queryType: tabsUserType === TABS_USERS_TYPES.dispatcher ? 'departmentDispatchers' : 'departmentUsers',
                departmentId: department._id
            }
        }

        if (!loaders.getUsers) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getUsers: true } }))
            try {
                const getUsersResponse = await axios.get('/api/users', config)

                if (getUsersResponse && getUsersResponse.data && getUsersResponse.data.success) {
                    const users = [...getUsersResponse.data.users]
                    const pagination = getUsersResponse.data.pagination || initialState.info.pagination
                    this.setState(prevState => ({
                        users,
                        sortedUser: users,
                        info: { ...prevState.info, pagination },
                        filteredUsers: users,
                        loaders: { ...prevState.loaders, getUsers: false }
                    }))
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: getUsersResponse.data.errors },
                        loaders: { ...prevState.loaders, getUsers: false }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getUsers: false }
                }))
            }
        }
    }

    // General
    handleErrors = (requestType) => {
        const { user } = this.state

        let fields = []

        if (requestType === 'create') {
            fields = [
                {
                    path: 'user.email',
                    type: 'string',
                    shouldCheck: user.type !== 'user' || (user.type === 'user' && !user.phone),
                    validation: Email(user.email),
                    validationMessage: "Please provide a valid email address."
                },
                {
                    path: 'user.phone',
                    type: 'string',
                    shouldCheck: user.type === 'user' && !user.email,
                    validation: !!ValidateAndTransformToBackendPhoneNumber(user.phone),
                    validationMessage: "Please provide a valid phone number."
                },
                {
                    path: 'user.type',
                    type: 'string',
                    shouldCheck: true
                },
                {
                    path: 'user.departments',
                    type: 'objectArray',
                    keys: {
                        departmentId: {
                            type: 'string',
                            shouldCheck: true
                        },
                        role: {
                            type: 'string',
                            shouldCheck: true
                        }
                    },
                    shouldCheck: user.type !== 'superAdmin'
                },
                {
                    path: 'user.firstName',
                    type: 'string',
                    shouldCheck: user.phoneNumberOnly === true
                },
                {
                    path: 'user.lastName',
                    type: 'string',
                    shouldCheck: user.phoneNumberOnly === true
                },
            ]
        }
        else if (requestType === 'update') {
            fields = [
                {
                    path: 'user.email',
                    type: 'string',
                    shouldCheck: !user.phoneNumberOnly,
                    validation: Email(user.email),
                    validationMessage: "Please provide a valid email address."
                },
                {
                    path: 'user.phone',
                    type: 'string',
                    shouldCheck: true,
                    validation: !!ValidateAndTransformToBackendPhoneNumber(user.phone),
                    validationMessage: "Please provide a valid phone number."
                },
                {
                    path: 'user.firstName',
                    type: 'string',
                    shouldCheck: true
                },
                {
                    path: 'user.lastName',
                    type: 'string',
                    shouldCheck: true
                },
                {
                    path: 'user.type',
                    type: 'string',
                    shouldCheck: true
                },
                {
                    path: 'user.type',
                    type: 'string',
                    shouldCheck: true
                },
                {
                    path: 'user.departments',
                    type: 'objectArray',
                    keys: {
                        departmentId: {
                            type: 'string',
                            shouldCheck: true
                        },
                        role: {
                            type: 'string',
                            shouldCheck: true
                        }
                    },
                    shouldCheck: (user.type !== 'superAdmin' && !!user.departments?.length)
                }
            ]
        }
        else if (requestType === 'updateInactiveUser') {
            fields = [{
                path: 'user.type',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'user.departments',
                type: 'objectArray',
                keys: {
                    departmentId: {
                        type: 'string',
                        shouldCheck: true
                    },
                    role: {
                        type: 'string',
                        shouldCheck: true
                    }
                },
                shouldCheck: user.type !== 'superAdmin'
            }
            ]
        }

        let errors = { ...this.state.errors }
        let frontEndErrors = handleErrors(this.state, errors, fields)
        return frontEndErrors
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleUserTypeInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state), () => { this.onUserTypeChange(data.value) })
    }

    handleFilterModal = (open) => {
        this.setState(prevState => ({
            modals: {
                ...prevState.modals,
                filterModal: open
            }
        }))
    }

    handleModal = (modal, user) => {
        const { tabsUserType, department, modals } = this.state

        switch (modal) {
            case 'createNewUser':
                return this.setState(prevState => ({
                    modals: { ...prevState.modals, [modal]: !prevState.modals[modal] },
                    user: {
                        ...initialState.user,
                        type: tabsUserType,
                        departments: [
                            {
                                departmentId: department._id,
                                role: tabsUserType === TABS_USERS_TYPES.dispatcher ? TABS_USERS_TYPES.dispatcher : UserRolesInDepartment.find(type => type.text === 'Subscriber')?.value
                            }
                        ]
                    },
                    errors: {
                        ...initialState.errors,
                        user: {
                            ...initialState.errors.user,
                            ...(tabsUserType === TABS_USERS_TYPES.dispatcher && { departments: null })
                        }
                    }
                }))
            case 'editUser':
                if (modals.editUser) {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, editUser: false },
                        user: initialState.user,
                        errors: initialState.errors
                    }))
                } else {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, editUser: true },
                        user: {
                            ...user,
                            departments: user.departments.map((dept, idx) => ({
                                ...dept,
                                key: idx
                            }))
                        },
                        errors: {
                            ...initialState.errors,
                            user: {
                                ...initialState.errors.user,
                                departments: user.departments.map(department => { return { deactivated: null, departmentId: null, role: null } })
                            }
                        }
                    }))
                }
            default:
                return this.setState(prevState => ({ modals: { ...prevState.modals, [modal]: !prevState.modals[modal] } }))
        }
    }

    searchUsers = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page: 1
            }
        }), this.getUsers)
    }

    resetSearch = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                search: ''
            }
        }), this.getUsers)
    }

    setActiveFilters = (key, value) => {
        const { activeFilters } = this.state

        if (activeFilters[key].includes(value)) {
            this.setState(prevState => ({
                activeFilters: {
                    ...prevState.activeFilters,
                    [key]: activeFilters[key].filter(existingValue => {
                        return value !== existingValue
                    })
                }
            }))
        } else {
            this.setState(prevState => ({
                activeFilters: {
                    ...prevState.activeFilters,
                    [key]: activeFilters[key].concat(value)
                }
            }))
        }
    }

    resetFilters = () => {
        this.setState(prevState => ({
            activeFilters: initialState.activeFilters,
            filteredUsers: this.state.users
        }))
    }

    checkIfFiltersSelected = () => {
        const { activeFilters } = this.state
        let someFiltersSelected = false
        _.mapObject(activeFilters, (val, key) => {
            if (val.length > 0) {
                someFiltersSelected = true
            }
        })
        return someFiltersSelected
    }

    filterUsers = () => {
        const { activeFilters, users } = this.state

        let someFiltersSelected = false
        _.mapObject(activeFilters, (val, key) => {
            if (val.length > 0) {
                someFiltersSelected = true
            }
        })

        let filteredUsers = users

        if (someFiltersSelected) {
            let departmentIdsFilter = activeFilters.departments
            if (departmentIdsFilter.length > 0) {
                filteredUsers = filteredUsers.filter((user) => {
                    let userDepartmentIds = user?.departments?.map(dept => dept.departmentId) || []
                    for (let currentDepartmentId of departmentIdsFilter) {
                        if (userDepartmentIds.includes(currentDepartmentId)) return true
                    }
                    return false
                })
            }

            let userTypeFilters = activeFilters.type
            if (userTypeFilters.length > 0) {
                filteredUsers = filteredUsers.filter((user) => {
                    for (let type of userTypeFilters) {
                        if (user.type === type) return true
                    }
                    return false
                })
            }
        }

        this.setState({ filteredUsers }, () => this.handleFilterModal(false))
    }

    // CRUD
    createUser = async () => {
        let { loaders, user } = this.state

        if (!loaders.createNewUser) {

            const { success, errors } = this.handleErrors('create')
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, createNewUser: true } }))
                try {
                    const { accessToken } = this.props
                    const config = {
                        headers: {
                            Authorization: accessToken
                        }
                    }
                    let formattedUser = !user.phoneNumberOnly ?
                        {
                            ...user,
                            phone: ValidateAndTransformToBackendPhoneNumber(user.phone)
                        }
                        :
                        {
                            phoneNumberOnly: user.phoneNumberOnly,
                            email: null,
                            phone: ValidateAndTransformToBackendPhoneNumber(user.phone),
                            departments: user.departments,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            notifications: user.notifications,
                            type: user.type
                        }

                    const createUserResponse = await axios.post('/api/users', formattedUser, config) // requestType: createAccount sends the fully formatted email where no request just sends an email confirmation link
                    if (createUserResponse && createUserResponse.data && createUserResponse.data.success) {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, createNewUser: false }
                        }))
                        this.handleModal('createNewUser')
                        this.getUsers()
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "User created successfully", "success")
                    } else {
                        this.setState(prevState => ({
                            errors: { ...prevState.errors, messages: createUserResponse.data.errors },
                            loaders: { ...prevState.loaders, createNewUser: false }
                        }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, createNewUser: false }
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            } else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }
        }
    }

    updateUser = async (type) => {
        const { user, loaders } = this.state

        if (loaders.updateUser || loaders.deactivateUser || loaders.reactivateUser) return

        this.setState(prevState => ({
            loaders: {
                ...prevState.loaders,
                deactivateUser: type === 'deactivate',
                reactivateUser: type === 'reactivate',
                updateUser: type !== 'reactivate' && type !== 'deactivate'
            }
        }))

        let formattedUser = null
        let errors = null
        let updateInactiveUser = false

        const { success, errors: updateErrors } = this.handleErrors('update')

        if (success) {
            formattedUser = {
                ...user,
                phone: ValidateAndTransformToBackendPhoneNumber(user.phone),
                deactivated: type === 'deactivate' ? true : type === 'reactivate' ? false : user.deactivated
            }
        }
        else {
            errors = updateErrors
            if (!user.activated) {
                const { success: validateDepartmentsSuccess, errors: validateDepartmentsErrors } = this.handleErrors('updateInactiveUser')
                if (validateDepartmentsSuccess) {
                    updateInactiveUser = true
                    formattedUser = {
                        departments: user.departments,
                        type: user.type,
                        email: user.email ?? null,
                        phone: ValidateAndTransformToBackendPhoneNumber(user.phone)
                    }
                }
                else {
                    errors = validateDepartmentsErrors
                }
            }
        }

        if (formattedUser) {
            try {
                const config = {
                    headers: { Authorization: this.props.accessToken },
                    params: { updateInactiveUser }
                }
                let updateUserResponse = await axios.put(`/api/users/${user._id}`, formattedUser, config)

                if (updateUserResponse.data.success) {
                    if (updateUserResponse.data?.responseMessage) {
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, updateUserResponse.data.responseMessage.text, updateUserResponse.data.responseMessage.type)
                        if (updateUserResponse.data.responseMessage.type === 'User deleted') {
                            this.setState(prevState => ({
                                modals: { ...prevState.modals, editUser: false },
                                user: initialState.user,
                                loaders: {
                                    ...prevState.loaders,
                                    deactivateUser: false,
                                    reactivateUser: false,
                                    updateUser: false
                                },
                                errors: initialState.errors
                            }), this.getUsers)
                            return false
                        }
                    }
                    else {
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "User updated successfully", "success")
                    }
                    this.setState(prevState => ({
                        user: {
                            ...updateUserResponse.data.user,
                            departments: user.departments.map((dept, idx) => ({
                                ...dept,
                                key: idx
                            }))
                        },
                        loaders: {
                            ...prevState.loaders,
                            deactivateUser: false,
                            reactivateUser: false,
                            updateUser: false
                        }
                    }), this.getUsers)

                    return true
                }
            }
            catch (e) {
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                this.setState(prevState => ({
                    loaders: {
                        ...prevState.loaders,
                        deactivateUser: false,
                        reactivateUser: false,
                        updateUser: false
                    }
                }))
                return false
            }
        }

        alert('Please fill out all of the required fields.')
        this.setState(prevState => ({
            errors,
            loaders: {
                ...prevState.loaders,
                deactivateUser: false,
                reactivateUser: false,
                updateUser: false
            }
        }))
        return false

    }

    handleAddDepartment = () => {
        return this.setState(prevState => ({
            user: {
                ...prevState.user,
                departments: [
                    ...prevState.user.departments,
                    {
                        departmentId: null,
                        role: UserRolesInDepartment.find(type => type.text === 'Subscriber')?.value,
                        key: Date.now()
                    }
                ]
            },
            errors: {
                ...prevState.errors,
                user: {
                    ...prevState.errors.user,
                    departments: [...prevState.errors.user.departments, {}]
                }
            }
        }))
    }

    handleDeleteDepartment = (key) => {
        let index = this.state.user.departments?.findIndex?.(dept => dept?.key === key)
        index = index > -1 ? index : 0

        return this.setState(prevState => ({
            user: {
                ...prevState.user,
                departments: prevState.user.departments.filter((dept) => dept.key !== key)
            },
            errors: {
                ...prevState.errors,
                user: {
                    ...prevState.errors.user,
                    departments: prevState.errors.user.departments.filter((department, departmentIndex) => departmentIndex !== index)
                }
            }
        }))
    }

    handleRemoveDepartment = () => {
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                departments: prevState.user.departments.filter((_, i) => i !== prevState.errors.user.departments.length - 1)
            },
            errors: {
                ...prevState.errors,
                user: {
                    ...prevState.errors.user,
                    departments: prevState.errors.user.departments.filter((_, i) => i !== prevState.errors.user.departments.length - 1)
                }
            }
        }))
    }

    handleForgotPassword = async (user) => {
        if (!this.state.loaders.forgotPassword) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, forgotPassword: true } }))

            try {
                const config = {
                    headers: {
                        Authorization: this.props.accessToken
                    }
                }

                const userInformation = {
                    email: user.email
                }
                let forgotPasswordResponse = await axios.post(`/api/users/reset-password`, { ...userInformation }, config)

                if (forgotPasswordResponse && forgotPasswordResponse.data && forgotPasswordResponse.data.success) {
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, `Sent reset password link to ${user.email}`, "success")
                }

            } catch (error) {
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Failed to send reset password link", "error")
            }

            this.setState(prevState => ({ loaders: { ...prevState.loaders, forgotPassword: false } }))
        }
    }

    handleSendTestSms = async (userId) => {
        if (this.state.loaders.sendTestSms) return
        if (!userId) return

        this.setState(prevState => ({ loaders: { ...prevState.loaders, sendTestSms: true } }))

        try {
            const config = {
                headers: {
                    Authorization: this.props.accessToken
                }
            }

            let sendTestSmsResponse = await axios.post(`/api/users/${userId}/send-sms`, null, config)

            if (sendTestSmsResponse && sendTestSmsResponse.data && sendTestSmsResponse.data.success) {
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, `SMS delivered to the carrier.`, "success")
            }

        } catch (error) {
            OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Failed to send SMS", "error")
        }

        this.setState(prevState => ({ loaders: { ...prevState.loaders, sendTestSms: false } }))
    }

    handleResendActivationLink = async (user) => {
        if (!this.state.loaders.resendActivationLink) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, resendActivationLink: true } }))
            try {
                const config = {
                    headers: {
                        Authorization: this.props.accessToken
                    }
                }

                const userInformation = {
                    userId: user._id
                }

                let resendActivationLinkResponse = await axios.post(`/api/users/resend-activation`, { ...userInformation }, config)

                if (!!resendActivationLinkResponse?.data?.success) {
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, `Resent activation link to ${user.email}`, "success")
                }
            }
            catch (error) {
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Failed to resend activation link", "error")
            }
            this.setState(prevState => ({ loaders: { ...prevState.loaders, resendActivationLink: false } }))
        }
    }

    //pagination methods
    handleChangePage = (page) => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page
            }
        }), this.getUsers)
    }

    handleChangeRowsPerPage = (limit) => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page: 1,
                limit
            }
        }), this.getUsers)
    }

    handleTabUserTypeChange = (e, value) => {
        this.setState({ tabsUserType: value }, this.getUsers)
    }

    handleSelectInvite = (departmentId) => {
        const acceptedInvite = this.state.user.invites.find(invite => invite.departmentId === departmentId)

        if (!acceptedInvite) return

        const acceptedDepartment = {
            departmentId: acceptedInvite.departmentId,
            role: acceptedInvite.role,
            deactivated: false,
            key: Date.now()
        }
        return this.setState(prevState => ({
            user: {
                ...prevState.user,
                departments: [
                    ...prevState.user.departments,
                    acceptedDepartment
                ],
                invites: prevState.user.invites.filter(invite => invite.departmentId !== departmentId)
            },
            errors: {
                ...prevState.errors,
                user: {
                    ...prevState.errors.user,
                    departments: [...prevState.errors.user.departments, {}]
                }
            }
        }))
    }

    render() {
        const { authenticatedUser } = this.props
        const { user, tabsUserType, department, errors, modals, loaders, departments, info, filteredUsers } = this.state
        const { search } = info

        return (
            <>
                {modals.createNewUser &&
                    <CreateNewUserModal
                        handleInputChange={this.handleInputChange}
                        handleAddDepartment={this.handleAddDepartment}
                        handleDeleteDepartment={this.handleDeleteDepartment}
                        handleRemoveDepartment={this.handleRemoveDepartment}
                        handleClose={() => this.handleModal('createNewUser')}
                        createUser={this.createUser}
                        authenticatedUser={authenticatedUser}
                        user={user}
                        departments={departments}
                        errors={errors}
                        loaders={loaders}
                        open={modals.createNewUser}
                    />
                }
                {modals.editUser &&
                    <EditUserModal
                        handleAddDepartment={this.handleAddDepartment}
                        handleDeleteDepartment={this.handleDeleteDepartment}
                        handleForgotPassword={this.handleForgotPassword}
                        handleSendTestSms={this.handleSendTestSms}
                        handleResendActivationLink={this.handleResendActivationLink}
                        handleInputChange={this.handleInputChange}
                        handleUserTypeInputChange={this.handleUserTypeInputChange}
                        handleClose={() => this.handleModal('editUser')}
                        handleSelectInvite={this.handleSelectInvite}
                        user={user}
                        departments={departments}
                        errors={errors}
                        loaders={loaders}
                        open={modals.editUser}
                        updateUser={this.updateUser}
                        authenticatedUser={authenticatedUser}
                    />
                }
                <Grid
                    xs={12}
                    container
                    style={{
                        display: 'flex', flex: 1, flexDirection: 'column', marginTop: 8,
                        borderRadius: 0,
                        marginBottom: 12
                    }}
                >
                    <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                        <UserTypeTabs
                            value={tabsUserType}
                            onChange={this.handleTabUserTypeChange}
                            variant='fullWidth'
                            aria-label="tabsUserType"
                        >
                            <UserTypeTab label="Users" value={TABS_USERS_TYPES.user} style={{ opacity: 1 }} />
                            <UserTypeTab label="Dispatchers" value={TABS_USERS_TYPES.dispatcher} style={{ opacity: 1 }} />
                        </UserTypeTabs>

                    </Grid>
                    <Grid
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}
                        item
                    >
                        <Button
                            onClick={() => this.handleModal('createNewUser')}
                            variant='outlined'
                        >
                            <AddBoxIcon
                                style={{ marginRight: 4 }}
                            />
                            Add {tabsUserType === TABS_USERS_TYPES.dispatcher ? TABS_USERS_TYPES.dispatcher : TABS_USERS_TYPES.user}
                        </Button>
                        <GeneralTextField
                            format="search"
                            gridStyle={{ width: 350 }}
                            placeholder='Search users...'
                            statePath={`info.search`}
                            value={search}
                            handleInputChange={this.handleInputChange}
                            resetSearch={this.resetSearch}
                            onSearch={this.searchUsers}
                        />
                    </Grid>
                </Grid>
                <UsersTable
                    users={filteredUsers}
                    selectedDepartmentId={department._id}
                    loaders={loaders}
                    info={info}
                    noUsersPlaceHolderText={`There are no ${tabsUserType}s in this department`}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    handleModal={this.handleModal}
                    resetSearch={this.resetSearch}
                    resetFilters={this.resetFilters}
                    checkIfFiltersSelected={this.checkIfFiltersSelected}
                />
            </>
        )
    }
}

export default withSnackbar(withStyles(null, { withTheme: true })(DepartmentUsersTab))