import React from "react"
import { Prompt } from "react-router-dom"

// Material UI
import { Button, Grid, Typography, Switch, CircularProgress } from "@material-ui/core"

// Utilities
import axios from "axios"
import { handleInputChange, handleErrors } from "../../utilities/handleChange"
import { Email, ValidateAndTransformToBackendPhoneNumber } from '../../utilities/Validate'
import { UserRolesInDepartment, DispatcherRolesInDepartment } from '../../utilities/Options'
import { withSnackbar } from 'notistack'
import { OpenSnackBar } from '../../utilities/handleSnackBar'

// Components
import GeneralTextField from "../general/TextField"
import GeneralDropdownField from "../general/DropdownField"
import NotificationSettings from "./users/NotificationSettings"
import AlertSettings, { SPECIFIC_DEPARTMENT } from "./users/AlertSettings"

class Account extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {
        ...props.authenticatedUser,
        department: props.authenticatedUser.departments.sort((a, b) => (
          props.authenticatedUser?.departmentObjects.find(dept => dept._id === a.departmentId)?.name?.localeCompare(
            props.authenticatedUser?.departmentObjects.find(dept => dept._id === b.departmentId)?.name
          )))
      },
      departments: props.authenticatedUser?.departmentObjects?.map?.(dept => ({
        ...dept,
        value: dept._id,
        text: `${dept.name} ${dept.agency ?? ''}`
      })).sort((a, b) => a.name.localeCompare(b.name)),
      isUserUnsaved: false,
      errors: {
        user: {
          alerts: {
            chat: {
              type: null,
              departmentIds: null
            },
            dispatch: {
              type: null,
              departmentIds: null
            },
            weather: {
              type: null
            }
          },
          departments: props.authenticatedUser.departments.map(department => { return { departmentId: null, role: null } }),
          notifications: {
            sms: null,
            push: null,
            availability: {
              type: null,
              timeslots: null
            }
          }
        },
      },
      loaders: {
        updateUser: false,
      },
    }
  }

  componentDidMount = async () => {
    window.addEventListener("beforeunload", this.beforeunload)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.authenticatedUser !== this.props.authenticatedUser) {
      this.setState(prevState => ({
        user: {
          ...this.props.authenticatedUser,
          department: this.props.authenticatedUser.departments.sort((a, b) => (
            this.props.authenticatedUser?.departmentObjects.find(dept => dept._id === a.departmentId)?.name?.localeCompare(
              this.props.authenticatedUser?.departmentObjects.find(dept => dept._id === b.departmentId)?.name
            )))
        },
        departments: this.props.authenticatedUser?.departmentObjects?.map?.(dept => ({
          ...dept,
          value: dept._id,
          text: `${dept.name} ${dept.agency ?? ''}`
        })).sort((a, b) => a.name.localeCompare(b.name)),
        errors: {
          ...prevState.errors,
          user: {
            ...prevState.errors.user,
            departments: this.props.authenticatedUser.departments.map(department => { return { departmentId: null, role: null } })
          }
        }
      }))
    }
  }

  componentWillUnmount = () => {
    if (this.state.isUserUnsaved) {
      this.props.setTheme(this.props.authenticatedUser.theme)
    }
    this.props.closeSnackbar()
    window.removeEventListener("beforeunload", this.beforeunload)
  }

  beforeunload = (e) => {
    if (this.state.isUserUnsaved) {
      e.preventDefault()
      e.returnValue = true
    }
  }

  setUnsavedChangesFlags = (flag) => {
    const { setAccountInfoUnsavedFlag } = this.props
    const { isUserUnsaved } = this.state

    if (flag && !isUserUnsaved) {
      this.setState({ isUserUnsaved: true })//, setParentFlagToPreventTabChange)
      if (!this.props.isAccountInfoUnsaved) {
        setAccountInfoUnsavedFlag(true)
      }
    }
    else if (!flag && isUserUnsaved) {
      this.setState({ isUserUnsaved: false })
      if (this.props.isAccountInfoUnsaved) {
        setAccountInfoUnsavedFlag(false)
      }
    }
  }

  handleInputChange = (e, data, callback = null) => {
    this.setState(handleInputChange(e, data, this.state), () => this.setUnsavedChangesFlags(true))
  }

  handleErrors = () => {
    const { user } = this.state

    let fields = [
      {
        path: 'user.email',
        type: 'string',
        shouldCheck: true,
        validation: Email(user.email),
        validationMessage: "Invalid email"
      },
      {
        path: 'user.phone',
        type: 'string',
        shouldCheck: true,
        validation: !!ValidateAndTransformToBackendPhoneNumber(user.phone),
        validationMessage: "Invalid phone"
      },
      {
        path: 'user.firstName',
        type: 'string',
        shouldCheck: true
      },
      {
        path: 'user.lastName',
        type: 'string',
        shouldCheck: true
      },
      {
        path: 'user.type',
        type: 'string',
        shouldCheck: true
      },
      {
        path: 'user.departments',
        type: 'objectArray',
        keys: {
          departmentId: {
            type: 'string',
            shouldCheck: true
          },
          role: {
            type: 'string',
            shouldCheck: true
          }
        },
        shouldCheck: user.type !== 'superAdmin' && user.departments?.length > 0
      },
      {
        path: 'user.alerts.chat.departmentIds',
        type: 'stringArray',
        shouldCheck: user.alerts.chat.type === SPECIFIC_DEPARTMENT
      },
      {
        path: 'user.alerts.dispatch.departmentIds',
        type: 'stringArray',
        shouldCheck: user.alerts.dispatch.type === SPECIFIC_DEPARTMENT
      }
    ]

    let errors = { ...this.state.errors }
    let frontEndErrors = handleErrors(this.state, errors, fields)
    return frontEndErrors
  }

  updateUser = async () => {
    const { user, loaders } = this.state
    if (!loaders.updateUser) {
      const { success, errors } = this.handleErrors()
      if (success) {
        this.setState((prevState) => ({
          loaders: { ...prevState.loaders, updateUser: true },
        }))
        let formattedUser = {
          ...user,
          phone: ValidateAndTransformToBackendPhoneNumber(user.phone),
          alerts: {
            ...user.alerts,
            chat: {
              ...user.alerts.chat,
              departmentIds: (Number(user.alerts.chat.type) !== SPECIFIC_DEPARTMENT) ? [] : user.alerts.chat.departmentIds
            },
            dispatch: {
              ...user.alerts.dispatch,
              departmentIds: (Number(user.alerts.dispatch.type) !== SPECIFIC_DEPARTMENT) ? [] : user.alerts.dispatch.departmentIds
            },
          },
          notifications: {
            ...user.notifications,
            availability: {
              ...user.notifications.availability,
              timeslots: (user.notifications.availability.type !== 'custom') ? [] : user.notifications.availability.timeslots
            }
          }
        }

        const config = {
          headers: {
            Authorization: this.props.accessToken,
          }
        }

        try {
          const updateUserResponse = await axios.put(`/api/users/${user._id}`, formattedUser, config)

          if (updateUserResponse.data.success) {
            this.setState((prevState) => ({
              loaders: { ...prevState.loaders, updateUser: false }
            }), () => this.setUnsavedChangesFlags(false))
            if (updateUserResponse.data?.responseMessage) {
              OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, updateUserResponse.data.responseMessage.text, updateUserResponse.data.responseMessage.type)
            }
            else {
              OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Account updated successfully", "success")
            }
          } else {
            this.setState((prevState) => ({
              loaders: { ...prevState.loaders, updateUser: false },
            }))
          }
        } catch (e) {
          this.setState(prevState => ({
            loaders: { ...prevState.loaders, updateUser: false }
          }))
          OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
        }
      } else {
        alert('Please fill out all of the required fields.')
        this.setState({ errors })
      }
    }
  }

  setTheme = () => {
    const { user } = this.state
    const theme = user.theme === 'light' ? 'dark' : 'light'

    this.setState({
      user: {
        ...user,
        theme
      }
    }, () => {
      this.setUnsavedChangesFlags(true)
      this.props.setTheme(theme)
    })
  }

  render() {
    const { user, departments, errors, loaders, isUserUnsaved } = this.state

    if (user) {
      return (
        <>
          <Prompt
            when={isUserUnsaved}
            message={() =>
              "Changes you made may not be saved. Are you sure you want to leave this page?"
            }
          />
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ padding: 32 }}
          >
            <Typography style={{ width: "100%" }} variant="h6" gutterBottom>
              Edit Account Information
            </Typography>
            <GeneralTextField
              autoFocus={true}
              width={6}
              label="First Name *"
              placeholder="Enter first name..."
              statePath={`user.firstName`}
              value={user.firstName}
              error={errors.user.firstName}
              handleInputChange={this.handleInputChange}
            />
            <GeneralTextField
              width={6}
              label="Last Name *"
              placeholder="Enter last name..."
              statePath={`user.lastName`}
              value={user.lastName}
              error={errors.user.lastName}
              handleInputChange={this.handleInputChange}
            />
            <GeneralTextField
              width={6}
              label="Email Address *"
              placeholder="Enter email address..."
              statePath={`user.email`}
              value={user.email}
              error={errors.user.email}
              handleInputChange={this.handleInputChange}
              labelTooltipTitle="if you update email, it remains unchanged until the confirmation link sent to the new email address is clicked."
              labelTooltipIcon="info"
            />
            <GeneralTextField
              width={6}
              format="phoneNumber"
              label="Phone Number *"
              placeholder="Enter phone number..."
              statePath={`user.phone`}
              value={user.phone}
              error={errors.user.phone}
              handleInputChange={this.handleInputChange}
              labelTooltipTitle="If you update phone number, it remains unchanged until the confirmation link sent to the new phone number is clicked."
              labelTooltipIcon="info"
            />
            {user.departments.map((department, index) => (
              <>
                <GeneralDropdownField
                  disabled
                  key={user.departments[index].departmentId}
                  options={departments}
                  width={6}
                  label={`Department`}
                  value={user.departments[index].departmentId}
                  hideLabel={index !== 0}
                />
                <GeneralDropdownField
                  disabled
                  key={user.departments[index].departmentId + user.departments[index].role}
                  options={user.type === 'user' ? UserRolesInDepartment : DispatcherRolesInDepartment}
                  width={6}
                  label='Role'
                  value={user.departments[index].role}
                  hideLabel={index !== 0}
                />
              </>
            ))}
          </Grid>

          {/*  Theme Settings */}
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{
              paddingLeft: 32,
              paddingRight: 32,
              paddingBottom: 32,
            }}
          >
            <Typography style={{ width: "100%" }} variant="h6">
              Edit Theme Preference
            </Typography>
            <div style={{ paddingLeft: 24 }}>
              <Typography component="div">
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>light</Grid>
                  <Grid item>
                    <Switch
                      checked={user?.theme === 'dark'}
                      onChange={this.setTheme}
                      name="checkedC"
                    />
                  </Grid>
                  <Grid item>dark</Grid>
                </Grid>
              </Typography>
            </div>
          </Grid>

          {/*  Alerts Settings */}
          <AlertSettings
            user={user}
            departments={departments}
            handleInputChange={this.handleInputChange}
            errors={errors}
            containerStyle={{
              paddingLeft: 32,
              paddingRight: 32,
              paddingBottom: 32,
            }}
          />

          {/*  Notifications Settings */}
          <NotificationSettings
            user={user}
            isSuperAdmin={user?.type === 'superAdmin'}
            departments={departments}
            handleInputChange={this.handleInputChange}
            errors={errors}
            containerStyle={{
              paddingLeft: 32,
              paddingRight: 32,
              paddingBottom: 32,
            }}
          />

          {/* Update Button */}
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{
              paddingLeft: 32,
              paddingRight: 32,
              paddingBottom: 32,
            }}
          >
            <Button
              onClick={this.updateUser}
              variant='contained'
              color='secondary'
              fullWidth

            >
              {loaders.updateUser && <CircularProgress color='inherit' size={18} style={{ marginRight: 8 }} />}
              Update
            </Button>
          </Grid>
        </>
      )
    } else {
      return <CircularProgress size="large" />
    }
  }
}

export default withSnackbar(Account)
