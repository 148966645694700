import React from 'react'
// Material UI
import { Grid, Button, Typography } from '@material-ui/core'
import MaterialIcon from 'material-icons-react'
import SwapVertOutlinedIcon from '@material-ui/icons/SwapVertOutlined';
// componenets 
import IconsSelector from './iconsSelector'
// Utilities
import googleMaterialIconsList from '../../../assets/google-material-icons'
import theme from '../../../utilities/theme'


const iconsNamesList = googleMaterialIconsList.iconsNames

const CallTypeIconsMenu = (props) => {
    const {
        icon1,
        statePathIcon1,
        icon2,
        statePathIcon2,
        handleInputChange,
        errors,
        swapCallTypeIcons
    } = props

    return (
        <Grid
            xs={12}
            spacing={3}
            style={{
                backgroundColor: theme.palette.primary.background,
                display: 'flex',
                flexGrow: 1,
            }}
            container
        >
            <Grid
                item
            >
                <IconsSelector
                    label='Icon 1 *'
                    value={icon1}
                    statePath={statePathIcon1}
                    handleInputChange={handleInputChange}
                    error={errors.callType.icon1}
                />
                <IconsSelector
                    label='Icon 2'
                    value={icon2}
                    statePath={statePathIcon2}
                    handleInputChange={handleInputChange}
                    error={errors.callType.icon2}
                />

            </Grid>
            <Grid
                style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 48 }}
            >
                {(iconsNamesList.includes(icon1) || iconsNamesList.includes(icon2)) &&
                    <Typography
                        variant='subtitle2'
                        style={{ justifySelf: 'center', alignSelf: 'flex-start', paddingBottom: 32, paddingLeft: 16 }}
                    >
                        Preview
                    </Typography>
                }
                <Grid
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    item
                >
                    <div
                        style={{ flexDirection: 'column' }}
                    >
                        {icon1 &&
                            <MaterialIcon key={icon1} icon={icon1} size={36} color={theme.palette.primary.main} />
                        }
                        {icon2 &&
                            <span
                                style={{ position: 'absolute', marginRight: -45, marginTop: -10 }}
                            >
                                <MaterialIcon key={icon2} icon={icon2} size={24} color='#ff9800' />
                            </span>
                        }
                    </div>
                    <br />
                </Grid>
                {iconsNamesList.includes(icon1) && iconsNamesList.includes(icon2) &&
                    <Button
                        size="small"
                        onClick={swapCallTypeIcons}
                        color='primary'
                        variant='outlined'
                        style={{ paddingLeft: 24 }}
                        endIcon={<SwapVertOutlinedIcon />}
                    >
                        Swap
                    </Button>
                }
            </Grid>
        </Grid>
    )
}


export default CallTypeIconsMenu;