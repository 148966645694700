import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import theme from './theme';

const defaultSortOrder = "asc"

function ToggleIconButton(props) {
    const { onClick, selected, sortOrder } = props

    const [state, setState] = useState({
        selected: false,
        sortOrder: defaultSortOrder
    })

    useEffect(() => {
        setState({
            selected: props.selected,
            sortOrder: props.selected ? props.sortOrder : defaultSortOrder
        })
    }, [props])

    const onClickHandler = () => {
        setState({
            selected,
            sortOrder: selected ? sortOrder : defaultSortOrder
        })
        onClick()
    }

    return (
        <IconButton
            onClick={onClickHandler}
            size="small"
        >
            {state.sortOrder === 'asc' ?
                <ArrowUpwardIcon
                    fontSize="inherit"
                    style={{ color: state.selected ? theme.palette.secondary.main : 'inherit' }}
                />
                :
                <ArrowDownwardIcon
                    fontSize="inherit"
                    style={{ color: state.selected ? theme.palette.secondary.main : 'inherit' }}
                />
            }

        </IconButton>
    )
}

export default ToggleIconButton;
