import React, { useRef, useState } from 'react'
// Material UI
import { Grid, Button, Typography, withStyles, IconButton, Tooltip, SvgIcon } from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'
import StarOutlineIcon from '@material-ui/icons/StarOutline'
//icons
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import MapIcon from '@material-ui/icons/Map'
// drag and drop
import { useDrag, useDrop } from 'react-dnd'
// Utilities
import theme from '../../../utilities/theme'


function DepartmentCard(props) {
    const { department, departmentType, deptTypeIdx, departmentIndex, openDispatchModal, openQuickAccessMapsModal, openCreateFiretextAnnouncementModal, isSuperAdmin, moveDepartment,
        moveDepartmentToFavourites, deleteDepartmentFromFavourite } = props

    const ref = useRef(null)

    const [{ isDragging }, drag] = useDrag({
        type: departmentType,
        item: {
            _id: department._id,
            departmentType,
            departmentIndex,
            deptTypeIdx
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    })

    const [{ handlerId }, drop] = useDrop({
        accept: departmentType,
        collect: (monitor) => ({
            handlerId: monitor.getHandlerId(),
            isOver: !!monitor.isOver()
        }),
        hover: (item, monitor) => {
            if (!ref.current) {
                return
            }

            const dragIndex = item.index
            const hoverIndex = departmentIndex
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            const isMovingInSameRow = () => {
                const itemsPerRow = 4 // we have four dept cards per row
                return parseInt(dragIndex / itemsPerRow) === parseInt(hoverIndex / itemsPerRow)
            }
            if (isMovingInSameRow()) {
                // Determine rectangle on screen
                const hoverBoundingRect = ref.current?.getBoundingClientRect()
                // Get horizontal middle
                const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
                // Determine mouse position
                const clientOffset = monitor.getClientOffset()
                // Get pixels to the left
                const hoverClientX = clientOffset.x - hoverBoundingRect.left
                // Only perform the move when the mouse has crossed half of the items width
                // When dragging leftwards, only move when the cursor is before 50% width
                // When dragging rightwards, only move when the cursor is after 50% width
                // Dragging rightwards
                if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                    return
                }
                // Dragging leftwards
                if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
                    return
                }
            }
            else {
                // Determine rectangle on screen
                const hoverBoundingRect = ref.current?.getBoundingClientRect()
                // Get vertical middle
                const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
                // Determine mouse position
                const clientOffset = monitor.getClientOffset()
                // Get pixels to the top
                const hoverClientY = clientOffset.y - hoverBoundingRect.top
                // Only perform the move when the mouse has crossed half of the items height
                // When dragging downwards, only move when the cursor is below 50%
                // When dragging upwards, only move when the cursor is above 50%
                // Dragging downwards
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return
                }
                // Dragging upwards
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return
                }
            }
            // Time to actually perform the action
            moveDepartment(dragIndex, hoverIndex, deptTypeIdx)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [state, setState] = useState({
        hovered: null
    })

    const onMouseEnterOnDepartment = (departmentIdString) => {
        setState(prevState => ({
            ...prevState,
            hovered: departmentIdString
        }))
    }

    const onMouseLeaveOffDepartment = () => {
        setState(prevState => ({
            ...prevState,
            hovered: null
        }))
    }

    const onFavouriteIconPress = (e) => {
        e?.preventDefault?.()
        e?.stopPropagation?.()

        if (departmentType === 'favourite')
            deleteDepartmentFromFavourite(department._id)
        else
            moveDepartmentToFavourites(deptTypeIdx, department._id)
    }

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    return (
        <Grid
            key={department.name}
            ref={ref}
            data-handler-id={handlerId}
            xs={3}
            container
            wrap='nowrap'
            item
            style={{ opacity }}
            onMouseEnter={() => onMouseEnterOnDepartment(`dept-${department._id}`)}
            onMouseLeave={() => onMouseLeaveOffDepartment()}
        >
            <Button
                style={{
                    width: '100%', height: '100%', display: 'flex', minHeight: 128, justifyContent: 'center', alignItems: 'center', textAlign: 'center',
                    color: theme.palette.primary.light, padding: 40, '&:hover': { backgroundColor: '#b71c1c' },
                    backgroundColor: department.color ? department.color : theme.palette.primary.main
                }}
                onClick={() => openDispatchModal(department._id)}
            >
                <Grid item xs zeroMinWidth>
                    <Typography
                        style={{ fontWeight: 'bold', overflowWrap: 'break-word' }}

                    >
                        {department.name}
                    </Typography>
                </Grid>
            </Button>
            <Grid
                style={{ position: 'relative' }}
            >
                <Tooltip
                    aria-label='agency-info'
                    style={{
                        position: 'absolute',
                        top: 2,
                        right: 2,
                        fontSize: 18,
                        zIndex: 1000,
                        color: '#fff'
                    }}
                    title={department?.agency ? department.agency : `Agency info not added in department's settings.`}
                >
                    <InfoOutlined />
                </Tooltip>
            </Grid>
            <Grid
                style={{ position: 'relative' }}
            >
                <Grid
                    style={{
                        position: 'absolute',
                        bottom: 2,
                        right: 2,
                        fontSize: 24,
                        zIndex: 1000, display: 'flex', flex: 1, flexDirection: 'row'
                    }}
                >

                    {/* quick access map */}
                    {openQuickAccessMapsModal &&
                        <Tooltip
                            aria-label='agency-info'
                            title={`Quick access department maps.`}
                        >
                            <IconButton
                                aria-label='view'
                                onClick={() => openQuickAccessMapsModal(department._id)}
                                style={{ color: "#fff" }}
                            >
                                <MapIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    }
                    {/* Create announcement modal */}
                    {(openCreateFiretextAnnouncementModal && (isSuperAdmin || !!department?.featuresPermission?.firetextAnnouncements)) &&
                        <Tooltip
                            aria-label='agency-info'
                            title={`Create an announcement.`}
                        >
                            <IconButton
                                aria-label='view'
                                onClick={() => openCreateFiretextAnnouncementModal(department._id)}
                                style={{ color: '#fff' }}
                            >
                                <SvgIcon>
                                    <path d="M18 11v2h4v-2h-4zm-2 6.61c.96.71 2.21 1.65 3.2 2.39.4-.53.8-1.07 1.2-1.6-.99-.74-2.24-1.68-3.2-2.4-.4.54-.8 1.08-1.2 1.61zM20.4 5.6c-.4-.53-.8-1.07-1.2-1.6-.99.74-2.24 1.68-3.2 2.4.4.53.8 1.07 1.2 1.6.96-.72 2.21-1.65 3.2-2.4zM4 9c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h1v4h2v-4h1l5 3V6L8 9H4zm11.5 3c0-1.33-.58-2.53-1.5-3.35v6.69c.92-.81 1.5-2.01 1.5-3.34z"></path>
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    }

                    {/* add to fav btn */}
                    <Tooltip
                        aria-label='agency-info'
                        title={`Add/remove favorite.`}
                    >
                        <IconButton
                            aria-label='view'
                            onClick={onFavouriteIconPress}
                            style={{ visibility: state.hovered === `dept-${department._id}` ? 'visible' : 'hidden', color: '#fff' }}
                        >
                            {departmentType === 'favourite' ? <StarOutlineIcon fontSize="inherit" /> : <StarIcon fontSize="inherit" />}
                        </IconButton>
                    </Tooltip>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default withStyles(null, { withTheme: true })(DepartmentCard);
