// React
import React from 'react'

// React Router
import { withRouter } from 'react-router-dom'

// Material UI
import { Grid, Typography, withStyles } from '@material-ui/core'

class FiretextAnnouncementScreen extends React.Component {

    render() {
        const { location } = this.props
        const { firetextAnnouncement } = location.state

        return (
            <Grid container style={{ padding: 16 }}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        FireTEXT Announcement
                    </Typography>
                </Grid>

                <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    style={{ marginTop: 24 }}
                >
                    <Typography variant="h6" gutterBottom style={{ whiteSpace: 'pre-line', fontSize: 18 }}>
                        {firetextAnnouncement.departmentObject?.name ?? 'FireTEXT'}
                    </Typography>

                    <Grid
                        container
                        style={{ padding: 8, marginTop: 24 }}
                    >
                        <Typography variant="body2" gutterBottom style={{ whiteSpace: 'pre-line', }}>
                            {firetextAnnouncement.text ?? ''}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withRouter(withStyles(null, { withTheme: true })(FiretextAnnouncementScreen))