import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

/****
 * Variants
 	* success
	* error
    * warning
	* info 	
 */
export const OpenSnackBar = (enqueueSnackbar, closeSnackbar, message, variant) => {
	enqueueSnackbar(message, {
		variant,
		action: key => (
			<IconButton
			size="small"
			aria-label="close"
			color="inherit"
			onClick={() => closeSnackbar(key)}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		)
	})
}