const styles = (theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    img: {
        width: '60vw',
        height: 'auto',
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(4),
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        borderRadius: 8,
        width: '100%',
        maxWidth: 600,
    },
    formControl: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(2),
        width: '100%',
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    logo: {
        height: 60,
        marginBottom: theme.spacing(1),
    },
    title: {
        fontSize: 40,
        color: theme.palette.primary.light,
    },
    subtitle: {
        fontSize: 18,
        position: 'relative',
        top: -12,
    },
    contactSupport: {
        marginTop: theme.spacing(2),
        textAlign: 'center'
    },
})

export default styles;