import React, { useRef } from 'react'
// Material UI
import { Grid, IconButton, Typography, withStyles } from '@material-ui/core'
// icons
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
//dnd
import { useDrag, useDrop } from 'react-dnd'
// Utilities
import DepartmentCard from './DepartmentCard'

const DepartmentTypesBox = (props) => {
    const { departmentType, deptTypeIdx, handleExpandable, openDispatchModal, openQuickAccessMapsModal, openCreateFiretextAnnouncementModal, isSuperAdmin, moveDepartment, moveDepartmentToFavourites, deleteDepartmentFromFavourite, moveDepartmentTypesBox } = props

    const ref = useRef(null)

    const [{ isDragging }, drag] = useDrag({
        type: 'departmentTypesBox',
        item: {
            departmentType,
            deptTypeIdx
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    })

    const [{ handlerId }, drop] = useDrop({
        accept: 'departmentTypesBox',
        collect: (monitor) => ({
            handlerId: monitor.getHandlerId(),
            isOver: !!monitor.isOver()
        }),
        hover: (item, monitor) => {
            if (!ref.current) {
                return
            }

            const dragIndex = item.index
            const hoverIndex = deptTypeIdx
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveDepartmentTypesBox(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    return (
        <React.Fragment>
            {departmentType.departments.length > 0 &&
                <Grid
                    ref={ref}
                    data-handler-id={handlerId}
                    style={{ opacity }}
                    xs={12}
                    container
                    item
                >
                    <Grid
                        xs={12}
                        item
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 16
                        }}
                    >
                        <Typography
                            variant='h6'
                            style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                        >
                            {departmentType.name} ({departmentType.departments.length})
                        </Typography>
                        <IconButton
                            size='small'
                            onClick={() => handleExpandable(departmentType)}
                        >
                            {departmentType.expanded ?
                                <ExpandLessIcon />
                                :
                                <ExpandMoreIcon />
                            }
                        </IconButton>
                    </Grid>
                    {departmentType.expanded &&
                        <Grid
                            spacing={2}
                            container
                        >
                            {departmentType.departments.filter(dept => typeof dept?.name === 'string').map((department, index) => (
                                <DepartmentCard
                                    key={department.name}
                                    department={department}
                                    departmentType={departmentType.type}
                                    deptTypeIdx={deptTypeIdx}
                                    departmentIndex={index}
                                    openDispatchModal={openDispatchModal}
                                    openQuickAccessMapsModal={openQuickAccessMapsModal}
                                    openCreateFiretextAnnouncementModal={openCreateFiretextAnnouncementModal}
                                    isSuperAdmin={isSuperAdmin}
                                    moveDepartment={moveDepartment}
                                    moveDepartmentToFavourites={moveDepartmentToFavourites}
                                    deleteDepartmentFromFavourite={deleteDepartmentFromFavourite}
                                />
                            ))}
                        </Grid>
                    }
                </Grid>
            }
        </React.Fragment>
    )
}

export default withStyles(null, { withTheme: true })(DepartmentTypesBox);