import React, { Component } from 'react'
// Material UI
import { Grid, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button, withStyles, CircularProgress } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { withSnackbar } from 'notistack'
// Utilities
import axios from "axios"
import Fuse from 'fuse.js'
import { handleInputChange, handleErrors } from "../../../utilities/handleChange"
import { OpenSnackBar } from '../../../utilities/handleSnackBar'
// Components
import GeneralTextField from "../../general/TextField"
import { ValidateSemVer } from '../../../utilities/Validate'
import CreateNewAppVersionModal from './createNewAppVersionModal'
import EditAppVersionModal from './editAppVersionModal'

const initialState = {
    appVersions: [],
    info: {
        search: ''
    },
    filteredAppVersions: [],
    appVersion: {
        versionNumber: '',
        releaseNotes: ''
    },
    errors: {
        appVersion: {
            versionNumber: null,
            releaseNotes: null
        },
        info: {

        }
    },
    modals: {
        createNewAppVersion: false,
        editAppVersion: false
    },
    loaders: {
        getAppVersions: false,
        createNewAppVersion: false,
        updateAppVersion: false,
        deleteAppVersion: false
    }
}
export class MobileAppVersion extends Component {
    state = initialState

    // Life cycle functions
    componentDidMount = async () => {
        await this.getMobileAppVersions()
    }

    componentWillUnmount = () => {
        this.props.closeSnackbar()
    }

    // API
    getMobileAppVersions = async () => {
        const { loaders } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            },
            params: {
                queryType: 'all'
            }
        }

        if (!loaders.getAppVersions) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getAppVersions: true } }))
            try {
                const getAppVersionsResponse = await axios.get('/api/mobileAppVersion', config)
                if (getAppVersionsResponse && getAppVersionsResponse.data && getAppVersionsResponse.data.success) {
                    let appVersions = getAppVersionsResponse.data.appVersions
                    this.setState(prevState => ({
                        appVersions,
                        filteredAppVersions: appVersions,
                        loaders: { ...prevState.loaders, getAppVersions: false }
                    }))
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: getAppVersionsResponse.data.errors },
                        loaders: { ...prevState.loaders, getAppVersions: false }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getAppVersions: false }
                }))
            }
        }
    }

    // General
    handleErrors = () => {
        const { appVersion } = this.state
        let errors = { ...this.state.errors }

        let fields = [
            {
                path: 'appVersion.versionNumber',
                type: 'string',
                shouldCheck: true,
                validation: !!ValidateSemVer(appVersion.versionNumber),
                validationMessage: "Invalid version. (x.x.x)"
            },
            {
                path: 'appVersion.releaseNotes',
                type: 'string',
                shouldCheck: true
            },
        ]

        let frontEndErrors = handleErrors(this.state, errors, fields)
        return frontEndErrors
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleSearchInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state), () => {
            this.searchAppVersions()
        })
    }

    handleModal = (modal, extra) => {
        const { modals } = this.state
        switch (modal) {
            case 'createNewAppVersion':
                if (modals.createNewAppVersion) {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, [modal]: !prevState.modals[modal] },
                        appVersion: initialState.appVersion,
                        errors: initialState.errors
                    }))
                } else {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, [modal]: !prevState.modals[modal] },
                        errors: initialState.errors
                    }))
                }
            case 'editAppVersion':
                if (modals.editAppVersion) {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, editAppVersion: false },
                        appVersion: initialState.appVersion,
                        errors: initialState.errors
                    }))
                } else {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, editAppVersion: true },
                        appVersion: extra
                    }))
                }
            default:
                return this.setState(prevState => ({ modals: { ...prevState.modals, [modal]: !prevState.modals[modal] } }))
        }
    }

    searchAppVersions = () => {
        const { search } = this.state.info
        const { appVersions } = this.state

        if (search && search.length > 0) {
            const fuseResult = new Fuse(appVersions, {
                keys: [
                    { name: 'versionNumber', weight: 1.0 },
                    { name: 'releaseNotes', weight: 0.3 }
                ],
                threshold: 0.3,
            })
            let filteredAppVersions = fuseResult.search(search).map(resultObject => {
                return {
                    ...resultObject.item,
                }
            })

            this.setState({ filteredAppVersions })
        } else {
            this.setState({
                filteredAppVersions: [...appVersions]
            })
        }
    }

    resetSearch = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                search: ""
            }
        }), this.searchAppVersions)
    }

    // CRUD
    createAppVersion = async () => {
        let { loaders, appVersion } = this.state

        if (!loaders.createNewAppVersion) {

            const { success, errors } = this.handleErrors()
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, createNewAppVersion: true } }))
                const { accessToken } = this.props
                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                try {
                    const createNewAppVersionResponse = await axios.post('/api/mobileAppVersion', { ...appVersion }, config)
                    if (createNewAppVersionResponse && createNewAppVersionResponse.data && createNewAppVersionResponse.data.success) {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, createNewAppVersion: false }
                        }))
                        this.handleModal('createNewAppVersion')
                        this.getMobileAppVersions()
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "App version added successfully", "success")
                    } else {
                        this.setState(prevState => ({
                            errors: { ...prevState.errors, messages: createNewAppVersionResponse.data.errors },
                            loaders: { ...prevState.loaders, createNewAppVersion: false }
                        }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, createNewAppVersion: false }
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            } else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }
        }
    }

    updateAppVersion = async (type) => {
        const { appVersion, filteredAppVersions, loaders } = this.state

        if (!loaders.updateAppVersion) {
            const { success, errors } = this.handleErrors()
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, updateAppVersion: true } }))

                let formattedAppVersion = {
                    ...appVersion
                }

                const config = {
                    headers: {
                        Authorization: this.props.accessToken
                    }
                }

                try {
                    let updateAppVersionResponse = await axios.put(`/api/mobileAppVersion/${appVersion._id}`, formattedAppVersion, config)

                    if (updateAppVersionResponse.data.success) {
                        this.setState(prevState => ({ loaders: { ...prevState.loaders, updateAppVersion: false } }))
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "App version updated successfully", "success")
                        let updatedAppVersions = filteredAppVersions.map(appVersionObject => {
                            if (appVersionObject._id === appVersion._id) {

                                return {
                                    ...appVersion,
                                    ...(updateAppVersionResponse.data.appVersion ?? {})
                                }
                            } else {
                                return appVersionObject
                            }
                        })
                        this.setState(prevState => ({
                            appVersions: updatedAppVersions,
                            filteredAppVersions: updatedAppVersions
                        }), () => this.getMobileAppVersions())
                        return true
                    } else {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, updateAppVersion: false },
                        }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, updateAppVersion: false },
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            }
            else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }

        }
    }

    deleteAppVersion = async () => {
        const { loaders, appVersion } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.deleteAppVersion) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, deleteAppVersion: true } }))
            try {
                const deleteAppVersionResponse = await axios.delete(`/api/mobileAppVersion/${appVersion._id}`, config)
                if (deleteAppVersionResponse && deleteAppVersionResponse.data && deleteAppVersionResponse.data.success) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, deleteAppVersion: false }
                    }))
                    this.handleModal('editAppVersion')
                    this.getMobileAppVersions()
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "App version deleted successfully", "success")
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: deleteAppVersionResponse.data.errors },
                        loaders: { ...prevState.loaders, deleteAppVersion: false }
                    }))
                }
            } catch (e) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, deleteAppVersion: false }
                }))
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
            }
        }
    }

    render() {
        const { appVersion, errors, modals, loaders, info, filteredAppVersions } = this.state
        return (
            <>
                {modals.createNewAppVersion &&
                    <CreateNewAppVersionModal
                        handleInputChange={this.handleInputChange}
                        handleClose={() => this.handleModal('createNewAppVersion')}
                        createAppVersion={this.createAppVersion}
                        appVersion={appVersion}
                        errors={errors}
                        loaders={loaders}
                        open={modals.createNewAppVersion}
                    />
                }
                {modals.editAppVersion &&
                    <EditAppVersionModal
                        handleInputChange={this.handleInputChange}
                        handleClose={() => this.handleModal('editAppVersion')}
                        deleteAppVersion={this.deleteAppVersion}
                        appVersion={appVersion}
                        errors={errors}
                        loaders={loaders}
                        open={modals.editAppVersion}
                        updateAppVersion={this.updateAppVersion}
                    />
                }
                <Grid
                    xs={12}
                    container
                    style={{
                        display: 'flex', flex: 1, justifyContent: 'space-between', marginTop: 8,
                        borderRadius: 0,
                        marginBottom: 12
                    }}
                >
                    <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}
                    >
                        <Button
                            onClick={() => this.handleModal('createNewAppVersion')}
                            variant='outlined'
                        >
                            <AddBoxIcon
                                style={{ marginRight: 8 }}
                            />
                            Add App Version
                        </Button>
                    </Grid>
                    <Grid
                        style={{ display: 'flex', paddingRight: 24 }}
                        item
                    >
                        <GeneralTextField
                            format="search"
                            gridStyle={{ width: 350 }}
                            placeholder='Search app versions...'
                            statePath={`info.search`}
                            value={info.search}
                            handleInputChange={this.handleSearchInputChange}
                            resetSearch={this.resetSearch}
                        />
                    </Grid>
                </Grid>
                <TableContainer
                    component={Paper}
                    elevation={0}
                    style={{
                        borderRadius: 0,
                        marginBottom: 64
                    }}
                >
                    <Table
                        style={{ minWidth: 650 }}
                        aria-label='App Versions'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Version Number
                                </TableCell>
                                <TableCell>
                                    Release Notes
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loaders.getAppVersions &&
                                <TableRow>
                                    <TableCell
                                        style={{ paddingTop: 96, paddingBottom: 96 }}
                                        colSpan={6}
                                        align="center"
                                    >
                                        <CircularProgress
                                            size={36}
                                        />

                                    </TableCell>
                                </TableRow>
                            }
                            {!loaders.getAppVersions && filteredAppVersions.map((appVersion) =>
                                <TableRow
                                    key={appVersion._id}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.handleModal('editAppVersion', appVersion)}
                                >
                                    <TableCell
                                        component='th'
                                    >
                                        {appVersion.versionNumber}
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                    >
                                        {appVersion.releaseNotes}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
}

export default withSnackbar(withStyles(null, { withTheme: true })(MobileAppVersion))
