import React from 'react'
// Material UI
import { Grid, Dialog, DialogTitle, DialogContent, Typography, Button, List, ListItem, ListItemText, Divider, CircularProgress, IconButton, ListItemSecondaryAction, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, DialogActions } from '@material-ui/core'
import MessageIcon from '@material-ui/icons/Message'
import MaterialIcon from 'material-icons-react'
import LocationOn from '@material-ui/icons/LocationOn'
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import RadioIcon from '@material-ui/icons/Radio'
import MyLocationIcon from '@material-ui/icons/MyLocation'
// css
import '../../index.css'
// Theme
import theme from '../../utilities/theme'
// Utilities
import axios from 'axios'
import moment from 'moment-timezone'
import { handleInputChange } from '../../utilities/handleChange'
import { withSnackbar } from 'notistack'
import { OpenSnackBar } from '../../utilities/handleSnackBar'
//components
import DispatchAudioPlayer from '../general/audio-player/NewDispatchAudioPlayer'
import GeneralTextField from '../general/TextField'
import AttachmentsComponent from './AttachmentsComponent'
import ActionConfirmation from '../general/ActionConfirmation'
import DispatchDynamicMap from '../general/maps/DispatchDynamicMap'
import { getResponderIconAndTextColor, populateResponders } from '../general/maps/utils'
import { CalculateDistanceInMiles } from '../../utilities/CalculateDistance'
import ToggleWrapper from '../general/ToggleWrapper'
import DispatchComments from './DispatchComments'

export const ResponderStatusKeyValue = {
	'station': 'Responding to Station',
	'scene': 'Responding to Scene',
	'arrivedAtScene': 'Arrived at Scene',
	'standingBy': 'Standing By',
	'notAvailable': 'Not Available'
}
class ViewDispatchModal extends React.Component {
	constructor(props) {
		super(props)

		this.mapRef = React.createRef()

		this.state = {
			dispatch: props.dispatch,
			map: null,
			callTypes: [],
			newAttachments: [],
			confirmDeletion: false,
			errors: {
				dispatch: {
					location: {}
				}
			},
			loaders: {
				getCallTypes: false,
				markDispatchInactive: false,
				updateDispatch: false,
				deleteDispatch: false
			},
		}
	}

	componentDidMount = async () => {
		if (this.props.allowEdit === true)
			await this.getCallTypes()

		if (this.props.socket?.on) {
			this.props.socket.on(`change-dispatch-${this.state.dispatch._id}`, this.onDispatchUpdated)
			this.props.socket.on(`realtime-location-${this.state.dispatch._id}`, this.onResponderUpdated)
			this.props.socket.on(`delete-dispatch-${this.state.dispatch._id}`, this.onDispatchDeleted)
		}
	}

	componentWillUnmount = () => {
		if (this.props.socket?.off) {
			this.props.socket.off(`change-dispatch-${this.state.dispatch._id}`, this.onDispatchUpdated)
			this.props.socket.off(`realtime-location-${this.state.dispatch._id}`, this.onResponderUpdated)
			this.props.socket.off(`delete-dispatch-${this.state.dispatch._id}`, this.onDispatchDeleted)
		}

		this.props.closeSnackbar()
	}

	handleInputChange = (e, data) => {
		this.setState(handleInputChange(e, data, this.state))
	}

	onDispatchUpdated = (dispatch) => {
		if (dispatch.active) {
			this.setState({ dispatch })
		}
		else {
			this.props.closeModal()
		}
	}

	onResponderUpdated = ({ index, coordinates, responderStatus }) => {
		try {
			const responders = this.state.dispatch.responders
			if (index >= 0 && index < responders.length && responders[index] !== undefined) {
				const updatedResponders = [...responders]
				updatedResponders[index] = {
					...updatedResponders[index]
				}

				if (coordinates) {
					updatedResponders[index].coordinates = coordinates
				}
				if (responderStatus) {
					updatedResponders[index].responderStatus = responderStatus
				}

				this.setState(prevState => ({
					...prevState,
					dispatch: {
						...prevState.dispatch,
						responders: updatedResponders
					}
				}))
			}
		} catch (error) {

		}
	}

	onDispatchDeleted = () => {
		OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "This dispatch has been deleted", "error")
		setTimeout(this.props.closeModal, 1500)
	}

	getCallTypes = async () => {
		const { dispatch, loaders } = this.state

		if (!dispatch?.departmentId)
			return


		const { accessToken } = this.props
		const config = {
			headers: {
				Authorization: accessToken
			}
		}

		if (!loaders.getCallTypes) {
			this.setState(prevState => ({
				multiDepartmentIds: [],
				loaders: { ...prevState.loaders, getCallTypes: true }
			}))
			try {
				const getSelectedDepartmentCallTypesResponse = await axios.get(`/api/departments/${dispatch.departmentId}/calltypes`, config)

				if (getSelectedDepartmentCallTypesResponse && getSelectedDepartmentCallTypesResponse.data && getSelectedDepartmentCallTypesResponse.data.success) {
					let callTypes = getSelectedDepartmentCallTypesResponse.data.callTypes.sort((a, b) => a.callType.localeCompare(b.callType))
					if (dispatch.callTypeId) {
						let dispatchCallTypeIndex = callTypes.findIndex(ct => ct._id === dispatch.callTypeId)
						if (dispatchCallTypeIndex === -1) // in case dispatch was created with calltype from another dept using multi department dispatch
							callTypes = [dispatch.callTypeObject, ...callTypes]
						else if (dispatchCallTypeIndex !== 0) { // in case selected calltype was not at index zero, move it to index zero for better visibility
							let ctAtIdxZero = callTypes[0]
							callTypes[0] = callTypes[dispatchCallTypeIndex]
							callTypes[dispatchCallTypeIndex] = ctAtIdxZero
						}
					}
					this.setState(prevState => ({
						callTypes: callTypes.filter(ct => ct.callType !== 'Automatic Dispatch'),
						loaders: { ...prevState.loaders, getCallTypes: false }
					}))
				} else {
					this.setState(prevState => ({
						errors: { ...prevState.errors, messages: getSelectedDepartmentCallTypesResponse.data.errors },
						loaders: { ...prevState.loaders, getCallTypes: false }
					}))
				}
			} catch (error) {
				this.setState(prevState => ({
					loaders: { ...prevState.loaders, getCallTypes: false }
				}))
			}
		}
	}

	setDispatchLocation = (place) => {
		if (!place.geometry || !place.geometry.location) {
			const location = {
				address: null,
				addressString: null,
				coordinates: null,
				googlePlaceId: null
			}
			window.alert("No details available for location: '" + place.name + "'")

			this.setState(prevState => ({
				dispatch: { ...prevState.dispatch, location }
			}))
			return
		}

		const coordinates = {
			latitude: place.geometry?.location?.lat(),
			longitude: place.geometry?.location?.lng()
		}
		const location = {
			address: null,
			addressString: place.formatted_address,
			coordinates,
			googlePlaceId: place?.place_id
		}

		this.setState(handleInputChange(null, { statePath: 'dispatch.location', value: location }, this.state))
	}

	handleDispatchLocationChange = (e, data) => {
		const statePath = 'dispatch.location'
		const value = {
			address: data.value,
			addressString: data.value,
			coordinates: null,
			googlePlaceId: null
		}

		this.setState(handleInputChange(e, { statePath, value }, this.state))
	}

	handleSelectedAttachments = (event) => {
		let files = [...event.target.files]

		if (!files?.length) return
		if (files.length > 5 || (files.length + this.state.newAttachments.length) > 5) {
			return alert('Can only add upto 5 attachments at once')
		}

		for (const file of files) {
			const fileSizeInMbs = file.size / 1000000
			if (fileSizeInMbs > 5) {
				return alert('Each attachment must be less than 5MB')
			}
		}

		const newAttachments = []
		for (const file of files) {
			newAttachments.push(file)
		}
		this.setState({ newAttachments: [...this.state.newAttachments, ...newAttachments] })
	}

	deleteResponder = (responderId) => {
		const { dispatch } = this.state
		const responders = dispatch.responders.filter(resp => resp.userId !== responderId)

		this.setState(prevState => ({
			dispatch: {
				...prevState.dispatch,
				responders
			}
		}))

	}

	deleteAttachment = ({ type, url, index }) => {
		const { dispatch } = this.state
		if (type === 'attachment') {
			this.setState(prevState => ({
				dispatch: {
					...prevState.dispatch,
					attachments: dispatch.attachments.filter(att => att?.url !== url)
				}
			}))
		}
		else if (type === 'newAttachments') {
			this.setState(prevState => ({
				newAttachments: prevState.newAttachments.filter((att, idx) => idx !== index)
			}))
		}
	}

	markCallAsInactive = async () => {
		const { accessToken, closeModal } = this.props
		const { dispatch, loaders } = this.state
		const config = {
			headers: {
				Authorization: accessToken
			}
		}
		if (!loaders.markDispatchInactive) {
			this.setState(prevState => ({
				loaders: {
					...prevState.loaders,
					markDispatchInactive: true
				}
			}))

			let markDispatchInactiveResponse = await axios.patch(`api/dispatches/${dispatch?._id}/markInactive`, null, config)
			if (markDispatchInactiveResponse.data.success) {
				closeModal()
			} else {
				this.setState(prevState => ({
					loaders: {
						...prevState.loaders,
						markDispatchInactive: false
					}
				}))
			}
		}
	}

	handleErrors = () => {
		const { dispatch } = this.state

		if (!dispatch.active) {
			alert('Update not allowed. Dispatch has been set to inactive now.')
			return
		}

		return { success: true }
	}

	updateDispatch = async () => {
		const { accessToken } = this.props
		const { dispatch, newAttachments, loaders } = this.state

		if (!loaders.updateDispatch) {
			this.setState(prevState => ({
				loaders: {
					...prevState.loaders,
					updateDispatch: true
				}
			}))

			const { success, errors } = this.handleErrors()
			if (!success) {
				alert('Please fill out all of the required fields.')
				this.setState(prevState => ({
					errors,
					loaders: {
						...prevState.loaders,
						updateDispatch: false
					}
				}))
				return
			}

			const dispatchFormData = new FormData()

			for (const key of Object.keys(dispatch)) {
				dispatchFormData.append(key, typeof dispatch[key] === 'object' ? JSON.stringify(dispatch[key]) : dispatch[key])
			}
			if (newAttachments.length > 0) {
				for (const file of newAttachments) {
					dispatchFormData.append('imageFilesArray', file)
				}
			}

			try {
				const config = {
					headers: {
						Authorization: accessToken,
						'Content-Type': 'multipart/form-data'
					}
				}

				await axios.put(`api/dispatches/${dispatch?._id}`, dispatchFormData, config)

				this.setState(prevState => ({
					newAttachments: [],
					loaders: {
						...prevState.loaders,
						updateDispatch: false
					}
				}))
			} catch (error) {
				OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
				this.setState(prevState => ({
					loaders: {
						...prevState.loaders,
						updateDispatch: false
					}
				}))
			}
		}
	}

	deleteDispatch = async () => {
		const { accessToken, closeModal } = this.props
		const { dispatch, loaders } = this.state

		if (!loaders.deleteDispatch) {
			this.setState(prevState => ({
				loaders: {
					...prevState.loaders,
					deleteDispatch: true
				}
			}))

			try {
				const config = {
					headers: {
						Authorization: accessToken,
						'Content-Type': 'multipart/form-data'
					}
				}

				await axios.delete(`api/dispatches/${dispatch?._id}`, config)
				closeModal()
			} catch (error) {
				OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
				this.setState(prevState => ({
					loaders: {
						...prevState.loaders,
						deleteDispatch: false
					}
				}))
			}
		}
	}

	openConfirmDeletion = () => {
		this.setState({ confirmDeletion: true })
	}

	closeConfirmDeletion = () => {
		this.setState({ confirmDeletion: false })
	}

	render() {
		const { dispatch, newAttachments, callTypes, confirmDeletion, loaders, errors } = this.state
		const { userId, open, showCreatedBy, allowEdit, allowDelete, showIncidentId, closeModal } = this.props

		const responders = populateResponders(dispatch)
		const isDispatchDelete = dispatch?.deleted === true
		return (
			<>
				<Dialog open={open} onClose={closeModal} maxWidth="xl" fullWidth>
					<DialogTitle
						disableTypography
						style={isDispatchDelete ? { margin: 0, padding: 12, background: '#f04141' } : { margin: 0, padding: 12 }}
					>
						<Grid
							container
							style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}
						>

							<Grid
								style={{ display: 'flex', flexDirection: 'column' }}
							>
								<Grid
									style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start' }}
								>
									{!allowEdit ?
										<div
											style={{
												display: 'flex', marginTop: 12, marginRight: 12,
												flexDirection: 'row',
												alignItems: 'baseline',
												color: theme.palette.primary.main
											}}
										>
											{dispatch.callTypeObject ? (
												<span
													style={dispatch?.callTypeObject?.icon2 ? { marginRight: 16 } : { marginRight: 8 }}
												>
													<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
														<MaterialIcon icon={dispatch?.callTypeObject?.icon1} size="tiny" color="#ff9800" />
														{dispatch?.callTypeObject?.icon2 && (
															<span style={{ position: 'absolute', marginRight: -30, marginTop: -10 }}>
																<MaterialIcon icon={dispatch?.callTypeObject?.icon2} size="tiny" color="#ff9800" />
															</span>
														)}
													</div>
												</span>
											) : (
												<span style={{ marginRight: 8 }}>
													<div style={{ display: 'flex', alignItems: 'center' }}>
														{dispatch.isVoice ?
															<RadioIcon style={{ fontSize: 18, color: theme.palette.primary.main }} />
															:
															<MessageIcon style={{ fontSize: 18, color: theme.palette.primary.main }} />
														}
													</div>
												</span>
											)}
											<Typography style={{ color: theme.palette.primary.main, marginLeft: 4, marginTop: -4 }} variant="h6">
												{dispatch.callTypeObject ? dispatch.callTypeObject.callType : dispatch.isVoice ? 'Automated' : 'Other'}
											</Typography>
										</div>
										:
										dispatch.isVoice ?
											<div
												style={{
													display: 'flex', alignItems: 'center',
													flexDirection: 'row',
													color: theme.palette.primary.main
												}}
											>
												<span style={{ marginRight: 8 }}>
													<RadioIcon style={{ fontSize: 18, color: theme.palette.primary.main }} />
												</span>
											</div>
											:
											null
									}
									<Typography variant="h6" style={{ textAlign: 'baseline' }}>
										{(dispatch.department ? dispatch.department.name : 'Deleted Department') + (isDispatchDelete ? '(Dispatch deleted)' : '')}
									</Typography>
								</Grid>
								{dispatch.department.agency ?
									<Typography
										variant="caption"
										style={{ marginTop: 8 }}
									>
										Agency {dispatch.department.agency}
									</Typography>
									:
									null
								}
								<Typography
									variant="caption"
								>
									Created {showCreatedBy === true && dispatch.createdBy?.userName ? `by ${dispatch.createdBy?.userName}` : ''} {moment.tz(dispatch.createdAt, moment.tz.guess()).format('MM/DD/YY HH:mm:ss z')}
								</Typography>
								{showIncidentId &&
									<Typography
										variant="caption"
										style={{ marginTop: 4 }}
									>
										CAD Incident Number {dispatch.incidentId}
									</Typography>
								}

							</Grid>

							<Grid
								style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: 56 }}
							>
								{allowEdit === true && dispatch.active &&
									<Button
										size="small"
										startIcon={<RemoveFromQueueIcon />}
										onClick={() => this.markCallAsInactive()}
										color='primary'
										variant='outlined'
										style={{ position: 'absolute', right: 8, top: 8 }}
									>
										{loaders.markDispatchInactive && <CircularProgress size={18} />}
										Mark as inactive
									</Button>
								}
								{dispatch.editedBy?.updatedAt &&
									<Typography
										variant='caption'
										style={{ position: 'relative', marginTop: 'auto' }}
									>
										Edited {moment.tz(dispatch.editedBy.updatedAt, moment.tz.guess()).format('MM/DD/YY HH:mm:ss z')}
									</Typography>
								}
							</Grid>
						</Grid>
					</DialogTitle>

					<DialogContent dividers>
						<Grid container item xs={12}>
							<Grid
								style={{ borderRight: `1px solid lightgrey`, paddingRight: 12 }}
								item
								xs={8}
							>

								{/* Address and map */}
								<Grid item xs={12}
									style={{ marginTop: 16, marginBottom: 16 }}
								>
									<DispatchDynamicMap
										ref={this.mapRef}
										accessToken={this.props.accessToken}
										userId={userId}
										dispatchLocation={dispatch.location}
										responders={responders}
										departmentId={dispatch.departmentId}
										departmentHasWeatherPermissions={dispatch.department?.featuresPermission?.weather}
										mapStyle={{ height: '500px', width: '100%' }}
										containerStyle={{ marginBottom: 16 }}
									/>

									{!allowEdit && dispatch?.location?.addressString && (
										<Grid
											container
										>
											<MyLocationIcon />
											<Typography
												style={{ marginLeft: 4, fontWeight: 'bold', fontSize: 16 }}
											>
												{dispatch.location.addressString}
											</Typography>
										</Grid>
									)}

									{allowEdit === true &&
										<Grid
											container
											item
											xs={12}
											style={{ marginBottom: 16 }}
										>
											<GeneralTextField
												id='pac-input'
												format="googlePlacesAutocomplete"
												width={12}
												label='Address'
												placeholder='Enter address here...'
												statePath={`dispatch.location.addressString`}
												value={dispatch.location?.addressString}
												error={errors.dispatch.location.addressString}
												handleInputChange={this.handleDispatchLocationChange}
												setLocation={this.setDispatchLocation}
											/>
										</Grid>
									}
								</Grid>

								{/* Message */}
								{(!allowEdit && dispatch.message) &&
									<Grid
										container
									>
										<MessageIcon />
										<Typography
											style={{ marginLeft: 4, wordBreak: 'break-word', fontSize: 16 }}
										>
											{dispatch.message}
										</Typography>
									</Grid>
								}
								{allowEdit === true && !dispatch.isVoice &&
									<GeneralTextField
										multiline
										width={12}
										label='Message'
										placeholder='Enter message here...'
										statePath={`dispatch.message`}
										value={dispatch.message}
										handleInputChange={this.handleInputChange}
									/>
								}

								{/* audio player */}
								{(dispatch.isVoice || dispatch.voiceUrl) &&
									<DispatchAudioPlayer
										dispatch={dispatch}
									/>
								}
								{/* Audio Transcription */}
								{
									dispatch.transcriptionText ?
										<Grid>
											<Typography
												variant='body2'
												style={{ margin: '0 10px' }}
											>
												<b>Transcription</b>{`: ${dispatch.transcriptionText}`}
											</Typography>
										</Grid>
										:
										null
								}


								{/* call types menu for admins and dispatchers */}
								{allowEdit === true && !dispatch.isVoice &&
									<FormControl
										style={{ width: '100%' }}
										component='fieldset'
									>
										<FormLabel
											component='legend'
										>
											<Typography
												variant='subtitle2'
												style={{ color: 'inherit', marginTop: 16 }}
											>
												Call Type
											</Typography>
										</FormLabel>

										<RadioGroup
											style={{ flex: 1 }}
											row
											aria-label='callTypeId'
											name='callTypeId'
											value={dispatch.callTypeId}
											onChange={(e, value) => this.setState(prevState => ({ dispatch: { ...prevState.dispatch, callTypeId: value } }))}
										>
											{!loaders.getCallTypes ?
												<Grid
													container
													item
													xs={12}
												>
													{callTypes.filter(ct => ct?._id ?? false).map((callType, idx) => (
														<Grid
															key={callType._id}
															container
															item
															xs={callTypes.length > 22 ? 4 : 6}
															justifyContent='flex-start'
															style={{ display: 'flex', height: 26, marginBottom: 16 }}
														>
															<FormControlLabel
																value={callType._id}
																control={
																	<Radio
																		color='primary'
																		autoFocus={idx === 0}
																		size="small"
																	/>
																}
																label={
																	<div
																		style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
																	>
																		<Typography
																			variant='body2'
																			style={{ textAlign: 'left' }}
																		>
																			{callType.callType.toUpperCase()}
																		</Typography>
																	</div>
																}
																labelPlacement='end'
															/>
														</Grid>
													))}
												</Grid>
												:
												<CircularProgress
													size={18}
													style={{ marginLeft: "auto", marginRight: "auto" }}
												/>
											}
										</RadioGroup>
									</FormControl>
								}

								{/* Attachments */}
								<AttachmentsComponent
									newAttachments={newAttachments}
									attachments={dispatch.attachments}
									allowEdit={allowEdit}
									allowDelete={allowDelete}
									handleSelectedAttachments={this.handleSelectedAttachments}
									deleteAttachment={this.deleteAttachment}
								/>
							</Grid>
							<Grid style={{ paddingLeft: 12, paddingRight: 12, }} item xs={4}>
								{/* comments, responeders*/}
								<Grid>
									<DispatchComments
										comments={dispatch.comments}
										accessToken={this.props.accessToken}
										dispatchId={dispatch._id}
										isActive={dispatch.active}
									/>
								</Grid>
								<Grid>
									<ToggleWrapper
										headerComponent={
											<>
												<Typography variant="h6">Responders ({responders?.length ?? 0})</Typography>
												<Divider style={{ marginTop: 4 }} />
											</>
										}
										children={
											<List>
												{responders?.length > 0 &&
													responders.map((responder, index) => {
														const distance = CalculateDistanceInMiles(dispatch.location?.coordinates, responder?.coordinates)
														return (
															<ListItem
																key={responder.userId}
																style={{
																	background: getResponderIconAndTextColor(responder.userId === userId, responder, 0.8),
																	borderRadius: 4, marginTop: 4
																}}
															>
																<ListItemText
																	primary={<>
																		<Typography variant="body1">
																			{responder.userId === userId ? 'Me' : `${responder?.firstName} ${responder?.lastName}`}
																		</Typography>
																		<Typography variant="body2">
																			{ResponderStatusKeyValue[responder.responderStatus]}
																		</Typography>
																	</>}
																	secondary={`${moment(responder?.createdAt).fromNow()}${distance ? `, ~${distance} mi` : ''}`}
																/>

																<ListItemSecondaryAction
																	style={{ justifyContent: 'flex-start' }}
																>
																	{typeof responder?.coordinates?.latitude === 'number' &&
																		<IconButton
																			edge="end"
																			aria-label="locationPin"
																			onClick={() => this.mapRef?.current?.animateToCoordinates(responder?.coordinates)}
																		>
																			<LocationOn />
																		</IconButton>
																	}
																	{allowDelete === true &&
																		<IconButton
																			aria-label='close'
																			onClick={() => this.deleteResponder(responder.userId)}
																		>
																			<DeleteOutlineIcon />
																		</IconButton>
																	}
																</ListItemSecondaryAction>

															</ListItem>
														)
													}
													)}
											</List>
										}
									/>
								</Grid>
							</Grid>
						</Grid>

					</DialogContent>
					{allowEdit === true &&
						<DialogActions>
							<Grid
								container
								style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
							>
								{allowDelete === true &&
									<Button
										onClick={() => this.openConfirmDeletion()}
										color='secondary'
										variant='contained'
										style={{ backgroundColor: '#ff0000', marginRight: 4 }}
									>
										{loaders.deleteDispatch &&
											<CircularProgress
												size={18}
												style={{ marginRight: 8 }}
											/>
										}
										Delete
									</Button>
								}
								<Grid
									style={!allowDelete ? { display: 'flex', flex: 1, width: '100%', justifyContent: 'space-between', alignItems: 'center' } : {}}
								>
									<Button
										onClick={() => closeModal()}
										color='primary'
										variant='outlined'
										style={!allowDelete ? { marginRight: 'auto' } : { marginRight: 16 }}
									>
										Cancel
									</Button>
									<Button
										style={{ marginLeft: 'auto' }}
										onClick={() => this.updateDispatch()}
										color='secondary'
										variant='contained'
									>
										{loaders.updateDispatch &&
											<CircularProgress
												size={18}
												style={{ marginRight: 8 }}
											/>
										}
										Update
									</Button>
								</Grid>

							</Grid>
						</DialogActions>
					}
				</Dialog>
				<ActionConfirmation
					open={confirmDeletion}
					onClose={this.closeConfirmDeletion}
					title='Are you sure you want to delete this Dispatch?'
					onConfirmAction={this.deleteDispatch}
				/>
			</>
		)
	}
}

export default (withSnackbar(ViewDispatchModal));