// React
import React from 'react'
// React Router
import { withRouter } from 'react-router-dom'
// Material UI
import { Typography, IconButton, withStyles, Menu, Grid, Paper } from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'
// Utilities
import axios from 'axios'
import theme from '../../../utilities/theme'
import DepartmentInvite from './DepartmentInviteNotification'
import FiretextAnnouncementNotification from './FiretextAnnouncementNotification'

const initialState = {
    notificationsElement: null,
    firetextAnnouncements: [],
    loaders: {
        getActiveFiretextAnnouncements: -1
    }
}

class NotificationsMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...initialState,
            user: props.user
        }
    }

    onNewFiretextAnnouncementSocketEvent = (firetextAnnouncement) => {
        if (!this.state.firetextAnnouncements.find(fA => fA._id === firetextAnnouncement._id)) {
            this.setState({
                firetextAnnouncements: [firetextAnnouncement, ...this.state.firetextAnnouncements]
            })
        }
    }

    async componentDidMount() {
        await this.getActiveFiretextAnnouncements()

        this.props.socket?.on('newFiretextAnnouncement', this.onNewFiretextAnnouncementSocketEvent)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user !== this.props.user) {
            this.setState({ user: this.props.user })
        }
    }

    componentWillUnmount = () => {
        this.props.socket?.off('newFiretextAnnouncement', this.onNewFiretextAnnouncementSocketEvent)
    }

    handleNotificationsDropdownClick = (e) => {
        const { notificationsElement } = this.state

        if (notificationsElement) {
            this.setState({ notificationsElement: null })
        } else {
            this.setState({ notificationsElement: e?.currentTarget })
        }
    }

    getActiveFiretextAnnouncements = async () => {
        const { accessToken } = this.props
        const { loaders } = this.state

        if (!loaders.getFiretextAnnouncements) {
            this.setState(prevState => ({
                loaders: {
                    ...prevState.loaders,
                    getFiretextAnnouncements: true
                }
            }))
        }


        try {
            const config = {
                headers: {
                    Authorization: accessToken
                },
                params: {
                    queryType: 'active'
                }
            }
            const getFiretextAnnouncementsResponse = await axios.get('/api/firetextAnnouncements', config)
            if (getFiretextAnnouncementsResponse.data.success) {
                this.setState(prevState => ({
                    firetextAnnouncements: getFiretextAnnouncementsResponse.data.firetextAnnouncements,
                    loaders: {
                        ...prevState.loaders,
                        getFiretextAnnouncements: false
                    }
                }))
            }
        } catch (error) {
            this.setState(prevState => ({
                loaders: {
                    ...prevState.loaders,
                    getFiretextAnnouncements: false
                }
            }))
        }
    }

    firetextAnnouncementMarkAsRead = async (firetextAnnouncementId) => {
        const { accessToken } = this.props
        try {
            const config = {
                headers: {
                    Authorization: accessToken
                }
            }
            const body = {
                firetextAnnouncementId
            }
            await axios.patch(`/api/firetextAnnouncements/markAsRead/`, body, config)
            this.getActiveFiretextAnnouncements()
        } catch (error) {
            // console.log(error)
        }
    }

    render() {
        const { user, accessToken } = this.props
        const { notificationsElement, firetextAnnouncements } = this.state

        const newInvites = user?.invites?.filter(invite => new Date(invite?.expires || null).getTime() > new Date().getTime())
        const hasNotifications = !!newInvites?.length || !!firetextAnnouncements.length
        const hasUnreadNotifications = !!newInvites?.length || !!firetextAnnouncements.filter(an => !an.readByUserIds.includes(user._id)).length

        return (
            <>
                <IconButton
                    onClick={this.handleNotificationsDropdownClick}
                >
                    <NotificationsIcon
                        style={{ color: theme.palette.primary.light }}
                    />
                    {hasUnreadNotifications &&
                        <div
                            style={{ position: 'absolute', zIndex: 100, right: '0.625rem', top: '0.5rem', width: '0.625rem', height: '0.625rem', backgroundColor: theme.palette.primary.main, borderRadius: '50%' }}>
                        </div>
                    }
                </IconButton>
                <Menu
                    // keepMounted
                    anchorEl={notificationsElement}
                    open={notificationsElement ? true : false}
                    onClose={this.handleNotificationsDropdownClick}
                    style={{ marginTop: 24 }}
                    disableScrollLock={true}
                    variant="selectedMenu"
                    autoFocus={false}
                >
                    {hasNotifications ?
                        <Paper
                            elevation={0}
                            style={{ minWidth: '450px', padding: 16, marginBottom: 16 }}
                        >
                            {newInvites.map((invite, index) => (
                                <DepartmentInvite
                                    key={invite.departmentId}
                                    user={user}
                                    invite={invite}
                                    accessToken={accessToken}
                                    handleNotificationsDropdownClick={this.handleNotificationsDropdownClick}
                                />
                            ))}
                            {firetextAnnouncements.map(firetextAnnouncement => (
                                <FiretextAnnouncementNotification
                                    key={firetextAnnouncement._id}
                                    user={user}
                                    firetextAnnouncement={firetextAnnouncement}
                                    firetextAnnouncementMarkAsRead={this.firetextAnnouncementMarkAsRead}
                                    handleNotificationsDropdownClick={this.handleNotificationsDropdownClick}
                                />
                            ))}
                        </Paper>
                        :
                        <Paper
                            elevation={0}
                            style={{ minWidth: '450px', padding: 16, marginBottom: 16 }}
                        >
                            <Grid item xs={12} container style={{ marginTop: 32, flex: 1, justifyContent: 'center', alightItems: 'center' }}>
                                <Typography
                                    variant='body1'
                                >
                                    All good, no new notifications.
                                </Typography>
                            </Grid>
                        </Paper>
                    }
                </Menu>
            </>
        )
    }
}

export default withRouter(withStyles(null, { withTheme: true })(NotificationsMenu))