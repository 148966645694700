import React, { useState } from 'react'
import { Box, IconButton } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const ToggleWrapper = ({ headerComponent, children }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const handleToggle = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    width: '100%'
                }}
            >
                {headerComponent && headerComponent}
                <IconButton onClick={handleToggle}>
                    {isExpanded ? (
                        <ExpandLessIcon fontSize="medium" color="black" />
                    ) : (
                        <ExpandMoreIcon fontSize="medium" color="black" />
                    )}
                </IconButton>
            </Box>

            {isExpanded && children}
        </Box>
    )
}

export default ToggleWrapper;
