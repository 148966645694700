// React
import React from 'react'
// MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
// Components
import AttachmentsComponent from '../../viewDispatch/AttachmentsComponent'
import RadioGroupField from '../RadioGroupField'
import GeneralTextField from '../TextField'
// Utils
import { formatDate } from './utils'
import theme from '../../../utilities/theme'

const LayerMarkerPropertiesModal = ({ visible, marker, isFullScreenMap, onClose }) => {
    const coordinates = marker.point.geometry.coordinates
    const markerProperties = marker.point.properties
    const layer = marker.layer

    const markerPropertiesComponent = (paramIsFullScreenMap) => (
        <DialogContent
            dividers
        >
            {layer === 'hydrant' &&
                <HydrantProperties
                    coordinates={coordinates}
                    properties={markerProperties}
                    isFullScreenMap={paramIsFullScreenMap}
                />
            }
            {layer === 'hazmat' &&
                <HazmatProperties
                    coordinates={coordinates}
                    properties={markerProperties}
                    isFullScreenMap={paramIsFullScreenMap}
                />
            }
            {layer === 'station' &&
                <StationProperties
                    coordinates={coordinates}
                    properties={markerProperties}
                    isFullScreenMap={paramIsFullScreenMap}
                />
            }
            {layer === 'aed' &&
                <AedProperties
                    coordinates={coordinates}
                    properties={markerProperties}
                    isFullScreenMap={paramIsFullScreenMap}
                />
            }

            {layer === 'evac' &&
                <EvacProperties
                    coordinates={coordinates}
                    properties={markerProperties}
                    isFullScreenMap={paramIsFullScreenMap}
                />
            }

            {layer === 'tornadoSiren' &&
                <TornadoSirenProperties
                    coordinates={coordinates}
                    properties={markerProperties}
                    isFullScreenMap={paramIsFullScreenMap}
                />
            }
        </DialogContent>
    )

    if (isFullScreenMap) return (
        <>
            <Typography
                variant='h6'
            >
                {`${layer.toLocaleUpperCase()}# ${markerProperties.id}`}
            </Typography>
            {markerPropertiesComponent(isFullScreenMap)}
        </>
    )

    return (
        <Dialog
            onClose={onClose}
            open={visible}
            maxWidth='md'
            PaperProps={{ style: { flex: 1, width: '100%', height: '100%' } }}
        >
            <DialogTitle
                disableTypography
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    minHeight: 64,
                    maxHeight: 64
                }}
            >
                <Typography
                    variant='h6'
                >
                    {`${layer.toLocaleUpperCase()}# ${markerProperties.id}`}
                </Typography>
                <div
                    style={{ display: 'flex' }}
                >
                    <IconButton
                        aria-label='close'
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            {markerPropertiesComponent(isFullScreenMap)}
            <DialogActions>
                <Button
                    onClick={onClose}
                    color='secondary'
                    variant='contained'
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const HydrantProperties = ({ properties, coordinates, isFullScreenMap }) => {
    const handleInputChange = () => null

    return (
        <Grid
            container
            item
            xs={12}
        >
            {/* color */}
            <Grid
                container
                item
                style={{ display: 'flex', flexDirection: 'row', marginTop: 12, marginBottom: 8 }}
            >
                <Typography
                    variant='subtitle2'
                >
                    Color
                </Typography>
                <Grid
                    container
                    item
                    xs={12}
                    style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}
                >
                    <div
                        style={{ height: 20, width: 20, borderRadius: 10, marginLeft: 12, backgroundColor: 'red', border: `4px solid ${properties.color === 'red' ? theme.palette.primary.main : 'transparent'}` }}
                    ></div>
                    <Grid
                        style={{ height: 20, width: 20, borderRadius: 10, marginLeft: 12, borderWidth: 4, backgroundColor: 'yellow', border: `4px solid ${properties.color === 'yellow' ? theme.palette.primary.main : 'transparent'}` }}
                    ></Grid>
                    <Grid
                        style={{ height: 20, width: 20, borderRadius: 10, marginLeft: 12, borderWidth: 4, backgroundColor: 'green', marginRight: 32, border: `4px solid ${properties.color === 'green' ? theme.palette.primary.main : 'transparent'}` }}
                    ></Grid>
                </Grid>
            </Grid>

            {/* Service status */}
            <Grid
                container
                item
                style={{ display: 'flex', flexDirection: 'row', marginTop: 12, marginBottom: 16, alignItems: 'center' }}
            >
                <Typography
                    variant='subtitle2'
                    style={{ marginRight: '16%' }}
                >
                    In Service
                </Typography>
                <RadioGroupField
                    name="In Service"
                    value={properties.inService}
                    options={[
                        { key: 'Yes', value: true },
                        { key: 'No', value: false },
                    ]}
                    statePath=''
                    handleInputChange={handleInputChange}
                />
            </Grid>

            {/* Local id */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Local Id'
                placeholder='Local id for the hydrant (optional).'
                statePath={``}
                value={properties.localId}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Adress */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Address'
                placeholder='Name of the street.'
                statePath={``}
                value={properties.address}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Address description */}
            <GeneralTextField
                readOnly={true}
                multiline={true}
                row={4}
                width={12}
                label='Address Description'
                placeholder='corner close to light pole or in front of white house e.t.c.'
                value={properties.addressDescription}
                statePath={``}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Coordinates View */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Coordinates'
                placeholder="Coordinates of hydrant."
                statePath={``}
                value={`${coordinates[1].toFixed(6)}, ${coordinates[0].toFixed(6)}`}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Last Flow Test Date */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Last Flow Test Date'
                placeholder="Date of hydrant last flow test."
                statePath={``}
                value={formatDate(properties.lastFlowTestAtInSeconds)}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Installation Date */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Installation Date'
                placeholder="Date hydrant was installed."
                statePath={``}
                value={formatDate(properties.installationDate)}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Numberof outlets */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Numberof outlets'
                placeholder="Total number of outlets on hydrant."
                statePath={``}
                value={properties.numberOfOutlets}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Outlet Type */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Outlet Type'
                placeholder="Outlet type."
                statePath={``}
                value={properties.outletType}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Outlet Size (Inches) */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Outlet Size (Inches)'
                placeholder="Outlet size in Inches."
                statePath={``}
                value={properties.outletSize}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Static Reading (GPM) */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Static Reading (GPM)'
                placeholder='Pressure in the system with no flow.'
                statePath={``}
                value={properties.staticReading}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Flow Reading (GPM) */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Flow Reading (GPM)'
                placeholder="Pressure in the system during flow."
                statePath={``}
                value={properties.flowReading}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Flow Reading Using Pilot Guage */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Flow Reading Using Pilot Guage'
                placeholder="Flow reading using pilot guage."
                statePath={``}
                value={properties.flowReadingUsingPilotGuage}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Elevation (Feet) */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Elevation (Feet)'
                placeholder="Elevation in Feet."
                statePath={``}
                value={properties.elevation}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Water Main Diameter (Inches) */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Water Main Diameter (Inches)'
                placeholder="Additional notes about hydrant."
                statePath={``}
                value={properties.waterMainDiameterInches}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Notes */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Notes'
                placeholder="Additional notes about hydrant."
                statePath={``}
                value={properties.notes}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* attachments */}
            {!isFullScreenMap &&
                <AttachmentsComponent
                    attachments={properties.attachments}
                />
            }
        </Grid>
    )
}

export const HazmatProperties = ({ properties, coordinates, isFullScreenMap }) => {
    const handleInputChange = () => null

    return (
        <Grid
            container
            item
            xs={12}
        >

            {/* Name */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Name'
                placeholder='Name of the hazmat.'
                statePath={``}
                value={properties.name}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Type */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Type'
                placeholder='Type of hazmat.'
                statePath={``}
                value={properties.type}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Business Name */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Business Name'
                placeholder='Name of the business dealing with hazmat material.'
                statePath={``}
                value={properties.businessName}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Address */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Address'
                placeholder='Name of the street.'
                statePath={``}
                value={properties.address}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Coordinates View */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Coordinates'
                placeholder='Coordinates of hazmat.'
                statePath={``}
                value={`${coordinates[1].toFixed(6)}, ${coordinates[0].toFixed(6)}`}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Contact Information */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Contact Information'
                placeholder='Phone or email for the business.'
                statePath={``}
                value={properties.contactInformation}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* MSDS Location */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='MSDS Location'
                placeholder='Could be that we need to call someone to get the data.'
                statePath={``}
                value={properties.msdsLocation}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Nature */}
            <Grid
                container
                item
                style={{ display: 'flex', flexDirection: 'row', marginTop: 12, marginBottom: 8, alignItems: 'center' }}
            >
                <Typography
                    variant='subtitle2'
                    style={{ marginRight: '16%' }}
                >
                    Nature
                </Typography>
                <RadioGroupField
                    name="Nature"
                    value={properties.nature}
                    options={[
                        { key: 'Permanent', value: 'permanent' },
                        { key: 'Temporary', value: 'temporary' },
                    ]}
                    statePath=''
                    handleInputChange={handleInputChange}
                />
            </Grid>

            {/* Completion Date */}
            {properties.nature === 'temporary' &&
                <GeneralTextField
                    readOnly={true}
                    width={12}
                    label='Completion Date'
                    placeholder="Scheduled date of completion for temp hazard such as broken bridge."
                    statePath={``}
                    value={formatDate(properties.completionDate)}
                    handleInputChange={handleInputChange}
                    gridStyle={{ display: 'flex', marginBottom: 16 }}
                    labelStyle={{ width: 170, marginRight: 32 }}
                />
            }

            {/* attachments */}
            {!isFullScreenMap &&
                <AttachmentsComponent
                    attachments={properties.attachments}
                />
            }
        </Grid>
    )
}

const StationProperties = ({ properties, coordinates, isFullScreenMap }) => {
    const handleInputChange = () => null

    return (
        <Grid
            container
            item
            xs={12}
        >

            {/* Name */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Name'
                placeholder='Name of the station.'
                statePath={``}
                value={properties.name}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Address */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Address'
                placeholder='Address of the station.'
                statePath={``}
                value={properties.address}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Coordinates View */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Coordinates'
                placeholder='Coordinates of hazmat.'
                statePath={``}
                value={`${coordinates[1].toFixed(6)}, ${coordinates[0].toFixed(6)}`}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Comments */}
            <GeneralTextField
                readOnly={true}
                multiline={true}
                row={4}
                width={12}
                label='Comments'
                placeholder='Comments for station.'
                statePath={``}
                value={properties.comments}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* attachments */}
            {!isFullScreenMap &&
                <AttachmentsComponent
                    attachments={properties.attachments}
                />
            }
        </Grid>
    )
}

const AedProperties = ({ properties, coordinates, isFullScreenMap }) => {
    const handleInputChange = () => null

    return (
        <Grid
            container
            item
            xs={12}
        >

            {/* Name */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Business Name'
                placeholder='Name of the business providing AED services.'
                statePath={``}
                value={properties.businessName}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Address */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Address'
                placeholder='Name of the street.'
                statePath={``}
                value={properties.address}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Coordinates View */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Coordinates'
                placeholder='Coordinates of AED.'
                statePath={``}
                value={`${coordinates[1].toFixed(6)}, ${coordinates[0].toFixed(6)}`}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Contact Information */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Contact Information'
                placeholder='Phone or email for the business.'
                statePath={``}
                value={properties.contactInformation}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Comments */}
            <GeneralTextField
                readOnly={true}
                multiline={true}
                row={4}
                width={12}
                label='Comments'
                placeholder='Any additional info that might be useful.'
                statePath={``}
                value={properties.comments}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* attachments */}
            {!isFullScreenMap &&
                <AttachmentsComponent
                    attachments={properties.attachments}
                />
            }
        </Grid>
    )
}

const EvacProperties = ({ properties, coordinates, isFullScreenMap }) => {
    const handleInputChange = () => null

    return (
        <Grid
            container
            item
            xs={12}
        >

            {/* Evac point */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Evacuation Point Name'
                placeholder='Name of the evacuation point.'
                statePath={``}
                value={properties.evacPointName}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Address */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Address'
                placeholder='Name of the street.'
                statePath={``}
                value={properties.address}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Coordinates View */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Coordinates'
                placeholder='Coordinates of Evac.'
                statePath={``}
                value={`${coordinates[1].toFixed(6)}, ${coordinates[0].toFixed(6)}`}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Contact Information */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Contact Information'
                placeholder='Phone or email for the evac.'
                statePath={``}
                value={properties.contactInformation}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Capacity  */}
            <GeneralTextField
                readOnly={true}
                multiline={true}
                row={4}
                width={12}
                label='Capacity'
                placeholder='Capacity of evacuation point.'
                statePath={``}
                value={properties.capacity}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Status  */}
            <GeneralTextField
                readOnly={true}
                multiline={true}
                row={4}
                width={12}
                label='Status'
                placeholder='Status of evacuation point.'
                statePath={``}
                value={properties.status}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Facilities  */}
            <GeneralTextField
                readOnly={true}
                multiline={true}
                row={4}
                width={12}
                label='Facilities'
                placeholder='Facilities as evacuation point.'
                statePath={``}
                value={properties.facilities}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Special Instructions  */}
            <GeneralTextField
                readOnly={true}
                multiline={true}
                row={4}
                width={12}
                label='Special Instructions'
                placeholder='Any special instructions.'
                statePath={``}
                value={properties.specialInsctructions}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* attachments */}
            {!isFullScreenMap &&
                <AttachmentsComponent
                    attachments={properties.attachments}
                />
            }
        </Grid>
    )
}

const TornadoSirenProperties = ({ properties, coordinates, isFullScreenMap }) => {
    const handleInputChange = () => null

    return (
        <Grid
            container
            item
            xs={12}
        >

            {/* Tornado Siren type */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Type'
                placeholder='Type of the siren e.g. "Rotating", "Electronic".'
                statePath={``}
                value={properties.type}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Address */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Address'
                placeholder='Physical location where the tornado siren is installed.'
                statePath={``}
                value={properties.address}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Address Description */}
            <GeneralTextField
                readOnly={true}
                width={12}
                multiline={true}
                label='Address Description'
                placeholder='Info about landmarks, nearby buildings, or any distinguishing features.'
                statePath={``}
                value={properties.addressDescription}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Elevation */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Elevation (Feet)'
                placeholder='The elevation or height at which tornado sirens are installed.'
                statePath={``}
                value={properties.elevation}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* Color */}
            <GeneralTextField
                readOnly={true}
                width={12}
                label='Color'
                placeholder='Color associated with the tornado siren.'
                statePath={``}
                value={properties.color}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* In Service  */}
            <Grid
                container
                item
                style={{ display: 'flex', flexDirection: 'row', marginTop: 8, marginBottom: 16, alignItems: 'center' }}
            >
                <Typography
                    variant='subtitle2'
                    style={{ marginRight: '16%' }}
                >
                    In Service
                </Typography>
                <RadioGroupField
                    name="In Service"
                    value={properties.inService}
                    options={[
                        { key: 'Yes', value: true },
                        { key: 'No', value: false },
                    ]}
                    statePath=''
                    handleInputChange={handleInputChange}
                />
            </Grid>

            {/* Notes  */}
            <GeneralTextField
                readOnly={true}
                multiline={true}
                row={4}
                width={12}
                label='Notes'
                placeholder='This field can include any relevant details, maintenance schedules, historical information, or other important notes related to the installation or maintenance.'
                statePath={``}
                value={properties.notes}
                handleInputChange={handleInputChange}
                gridStyle={{ display: 'flex', marginBottom: 16 }}
                labelStyle={{ width: 170, marginRight: 32 }}
            />

            {/* attachments */}
            {!isFullScreenMap &&
                <AttachmentsComponent
                    attachments={properties.attachments}
                />
            }
        </Grid>
    )
}

export default LayerMarkerPropertiesModal;