// React
import React from 'react'
// React Router
import { withRouter } from 'react-router-dom'
// Material UI
import { Grid, CssBaseline, Avatar, Typography, Paper, Button, CircularProgress, withStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
// Components
import GeneralTextField from '../general/TextField'
// Assets
import HomepagePhoto from '../../assets/homepage.jpg'
import LogoIcon from '../../assets/logo.png'
// Utilities
import axios from 'axios'
import { handleInputChange, handleErrors } from '../../utilities/handleChange'
import theme from '../../utilities/theme'


const initialState = {
    info: {
        password: null,
        password2: null
    },
    errorMessage: null,
    loading: true,
    errors: {
        info: {

        }
    },
    loaders: {
        resetPassword: false
    },
    valid: false,
    success: false
}

class ResetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...initialState,
            id: props.match.params.id,
            tempHash: props.match.params.tempHash,
        }
    }

    componentDidMount = async () => {
        this.validateResetPassword()
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleErrors = () => {
        const { info } = this.state
        let errors = { ...this.state.errors }

        let fields = [
            {
                path: 'info.password',
                type: 'string',
                shouldCheck: true,
                validation: info.password && info.password.length > 6,
                validationMessage: 'Your password must be more than 6 characters.'
            },
            {
                path: 'info.password2',
                type: 'string',
                shouldCheck: true,
                validation: info.password === info.password2,
                validationMessage: 'Passwords do not match.'
            }
        ]

        let frontEndErrors = handleErrors(this.state, errors, fields)
        return frontEndErrors
    }

    validateResetPassword = async () => {
        const { id, tempHash } = this.state

        try {
            const resetPasswordValidationResponse = await axios.get(`/api/auth/reset-password/${id}/${tempHash}`)
            // console.log({ resetPasswordValidationResponse })
            this.setState(prevState => ({ valid: true, loading: false, info: { ...prevState.info, email: resetPasswordValidationResponse.data.email } }))

        } catch (error) {
            // console.log(error?.response?.data?.errors)

            this.setState({
                valid: false,
                loading: false,
                errorMessage: !!error?.response?.data?.errors?.length ? error?.response?.data?.errors?.[0]?.message : 'Request failed due to connectivity issues. Check your internet connection or contact support if problem persists.'
            })
        }
    }

    resetPassword = async () => {
        const { id, tempHash, loaders, info } = this.state

        if (!loaders.resetPassword) {
            this.setState(prevState => ({
                loaders: {
                    ...prevState.loaders,
                    resetPassword: true
                }
            }))

            const { success, errors } = this.handleErrors()
            if (success) {
                const userInformation = {
                    password: info.password
                }
                try {
                    const resetPasswordResponse = await axios.post(`/api/auth/reset-password/${id}/${tempHash}`, { ...userInformation })
                    // console.log({ resetPasswordResponse })
                    if (!!resetPasswordResponse?.data?.success) {
                        this.setState(prevState => ({
                            success: true,
                            errors: initialState.errors,
                            loaders: {
                                ...prevState.loaders,
                                resetPassword: false
                            }
                        }))
                    }
                } catch (error) {
                    // console.log(error?.response?.data?.errors)
                    this.setState(prevState => ({
                        success: false,
                        errors: initialState.errors,
                        loaders: {
                            ...prevState.loaders,
                            resetPassword: false
                        },
                        errorMessage: !!error?.response?.data?.errors?.length ? error?.response?.data?.errors?.[0]?.message : 'Request failed due to connectivity issues. Check your internet connection or contact support if problem persists.'
                    }))
                }
            } else {
                this.setState(prevState => ({
                    success: false,
                    errors,
                    loaders: {
                        ...prevState.loaders,
                        resetPassword: false
                    }
                }))
            }
        }
    }

    render() {
        const { loading, loaders, valid, success, errorMessage, info, errors } = this.state

        return (
            <>
                <Grid
                    container
                    component='main'
                    style={{
                        height: '100vh',
                    }}
                >
                    <CssBaseline />
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                        component={Paper}
                        container
                        elevation={6}
                        square
                        style={{
                            backgroundColor: theme.palette.primary.background,
                            color: theme.palette.primary.dark
                        }}
                        alignItems='center'
                    >
                        <div
                            style={{
                                flex: 1,
                                margin: '64px 32px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Grid
                                container
                                justify='center'
                                alignItems='center'
                                xs={12}
                            >
                                <Avatar
                                    style={{
                                        margin: 8,
                                        backgroundColor: theme.palette.primary.background,
                                        color: theme.palette.primary.dark
                                    }}
                                    src={LogoIcon}
                                />
                                <Typography
                                    component='h1'
                                    variant='h5'
                                >
                                    FireTEXT<sup style={{ fontSize: 18, position: 'relative', top: -6 }}>®</sup>
                                </Typography>
                            </Grid>
                            {loading ?
                                <>
                                    <CircularProgress />
                                </>
                                :
                                <>
                                    {success ?
                                        <>
                                            <Alert
                                                severity="success"
                                                style={{ marginBottom: 16 }}
                                            >
                                                Your password has been reset!
                                            </Alert>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginTop: 32
                                                }}
                                            >
                                                <Button
                                                    variant='contained'
                                                    href='/login'
                                                >
                                                    Go to login
                                                </Button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {errorMessage &&
                                                <>
                                                    <Alert
                                                        severity="error"
                                                        onClose={() => this.setState({ errorMessage: '' })}
                                                        style={{ marginBottom: 16 }}
                                                    >
                                                        {errorMessage}
                                                    </Alert>
                                                </>
                                            }
                                            {valid &&
                                                <>
                                                    <Typography
                                                        align='center'
                                                        variant='subtitle1'
                                                    >
                                                        Enter a new password to reset your account.
                                                    </Typography>
                                                    <Grid
                                                        style={{ marginTop: 16 }}
                                                        xs={12}
                                                        spacing={2}
                                                        container
                                                    >
                                                        <GeneralTextField
                                                            width={12}
                                                            label='Password'
                                                            placeholder='Enter password...'
                                                            type='password'
                                                            statePath={`info.password`}
                                                            value={info.password}
                                                            error={errors.info.password}
                                                            handleInputChange={this.handleInputChange}
                                                        />
                                                        <GeneralTextField
                                                            width={12}
                                                            label='Confirm Password'
                                                            placeholder='Enter password again...'
                                                            type='password'
                                                            statePath={`info.password2`}
                                                            value={info.password2}
                                                            error={errors.info.password2}
                                                            handleInputChange={this.handleInputChange}
                                                        />
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                marginTop: 32
                                                            }}
                                                        >
                                                            <Button
                                                                fullWidth
                                                                color='secondary'
                                                                onClick={this.resetPassword}
                                                                variant='contained'
                                                            >
                                                                {loaders.resetPassword &&
                                                                    <CircularProgress
                                                                        size={18}
                                                                        style={{ marginRight: 8 }}
                                                                    />
                                                                }
                                                                Reset password
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </Grid>
                    <Grid
                        container
                        alignItems='center'
                        justifyContent='center'
                        xs={false}
                        sm={4}
                        md={7}
                        style={{
                            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${HomepagePhoto})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: theme.palette.primary.background,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            padding: 64
                        }}
                    >

                    </Grid>
                </Grid>
            </>
        )
    }
}

export default withRouter(withStyles(null, { withTheme: true })(ResetPassword))