// React
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// MUI
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
// Utils
import axios from 'axios'
// Custom
import LogoIcon from '../assets/logo.png'
import theme from '../utilities/theme'

const InfoChangeRequestConfirmation = (props) => {
    const { id: requestId } = useParams()

    const [state, setState] = useState({
        requestId,
        responseStatus: null, // 'success' | 'error'
        responseMessage: null
    })

    const { responseStatus, responseMessage } = state

    useEffect(() => {
        const confrimInfoChange = async () => {
            try {
                const response = await axios.patch(`/api/users/info-change/`, { requestId })
                setState({
                    responseStatus: 'success',
                    responseMessage: response.data.responseMessage
                })
            } catch (error) {
                setState({
                    responseStatus: 'error',
                    responseMessage: error?.response?.data?.errors?.[0]?.message ?? 'Something went wrong.'
                })
            }
        }
        confrimInfoChange()
    }, [requestId])

    return (
        <Grid
            container
            item
            style={{ background: theme.palette.primary.dark, display: 'flex', flexGrow: 1, flexDirection: 'column', justifyContent: 'center', position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, margin: 0, padding: 0 }}
        >
            <Grid
                item
                style={{
                    marginTop: 64,
                    marginBottom: 64,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <img
                    style={{
                        height: 60,
                        marginRight: 8,
                        backgroundColor: theme.palette.primary.background,
                        color: theme.palette.primary.dark
                    }}
                    src={LogoIcon}
                    alt="FireTEXT"
                />
                <h1
                    style={{ fontSize: 40, color: theme.palette.primary.light }}
                >
                    FireTEXT<sup style={{ fontSize: 18, position: 'relative', top: -12 }}>®</sup>
                </h1>

            </Grid>

            {responseStatus === null ?
                <Grid
                    container
                    item
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <CircularProgress
                        size={36}
                        style={{ color: theme.palette.primary.light }}
                    />
                </Grid>
                :
                <>
                    <Grid
                        container
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        {responseStatus === 'success' ?
                            <CheckCircleOutlineIcon
                                size={36}
                                htmlColor='#00b300'
                            />
                            :
                            <ErrorOutlineIcon
                                size={36}
                                htmlColor='#ff0000'
                            />
                        }
                        <Typography
                            variant='body1'
                            style={{ padding: 16, color: theme.palette.primary.light, textAlign: 'center' }}
                        >
                            {responseMessage}
                        </Typography>
                    </Grid>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 32
                        }}
                    >
                        <Button
                            variant='contained'
                            href='/login'
                        >
                            Go to login
                        </Button>
                    </div>
                </>
            }
        </Grid>
    )
}

export default InfoChangeRequestConfirmation
