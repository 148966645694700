import React, { Component } from 'react'
// Material UI
import { Typography, withStyles } from '@material-ui/core'
//utilities
import moment from 'moment'

const initialState = {
	time: null,
	day: null,
	date: null
}

class Timer extends Component {
	state = initialState

	componentDidMount() {
		const date = new Date()//.toLocaleString()
		this.setState({ time: moment(date).format('HH:mm:ss A'), day: moment(date).format('dddd'), date: moment(date).format('LL') })

		this.timer = setInterval(() => {
			const date = new Date()//.toLocaleString()
			this.setState({ time: moment(date).format('HH:mm:ss A'), day: moment(date).format('dddd'), date: moment(date).format('LL') })
		}, 1000)
	}

	componentWillUnmount() {
		clearInterval(this.timer)
	}

	render() {
		const { time, day, date } = this.state

		return (
			<div
				style={{
					paddingLeft: 16,
					paddingRight: 16
				}}
			>
				<Typography
					gutterBottom
					variant='h4'
				>
					{time ?
						time
						:
						<div>
							&nbsp;
						</div>
					}
				</Typography>
				<Typography
					gutterBottom
					variant='subtitle1'
				>
					{day ?
						day
						:
						<div>
							&nbsp;
						</div>
					}
				</Typography>
				<Typography
					variant='subtitle1'
				>
					{date ?
						date
						:
						<div>
							&nbsp;
						</div>
					}
				</Typography>
			</div>
		)
	}
}

export default withStyles(null, { withTheme: true })(Timer)