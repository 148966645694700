import React, { useCallback, useState } from 'react'
import { Card, CardActionArea, CardMedia, Grid, IconButton, Typography } from '@material-ui/core'
// icons
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import ViewAttachment from '../general/ViewAttachment'

const styles = {
    attachmentGallery: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    attachment: {
        width: 180,
        height: 180,
        marginLeft: 4,
        marginRight: 4,
        objectFit: 'cover'
    },
    attachmentInput: {
        display: 'none'
    },
    blur: {
        'webkit-filter': "blur(2px)",
        filter: "blur(2px)",
        opacity: 0.5,
        backgroundColor: '#757575'
    }
}

const initialState = {
    allAttachments: [],
    attachmentModalOpen: false,
    currentAttachmentIndex: 0,
    hovered: null
}

const AttachmentsComponent = (props) => {
    const { newAttachments = [], attachments, allowEdit, allowDelete, handleSelectedAttachments, deleteAttachment } = props

    const [state, setState] = useState(initialState)

    const { attachmentModalOpen, currentAttachmentIndex, allAttachments, hovered } = state

    const openAttachmentModal = useCallback(({ type, url, index }) => {
        let currentAttachmentIndex = index ?? 0
        if (type === 'attachment') {
            let idx = attachments.findIndex(att => att.url === url)
            if (idx > -1)
                currentAttachmentIndex = !!newAttachments?.length ? newAttachments.length + idx : idx
        }

        setState(prevState => ({
            ...prevState,
            allAttachments: [...(newAttachments ?? []).map(file => ({ url: URL.createObjectURL(file) })), ...attachments],
            currentAttachmentIndex,
            attachmentModalOpen: true
        }))

    }, [attachments, newAttachments])

    const closeAttachmentModal = () => {
        setState(prevState => ({
            ...prevState,
            attachmentModalOpen: false
        }))
    }

    const onMouseEnterOnAttachment = (attachmentIdString) => {
        setState(prevState => ({
            ...prevState,
            hovered: attachmentIdString
        }))
    }

    const onMouseLeaveOffAttachment = () => {
        setState(prevState => ({
            ...prevState,
            hovered: null
        }))
    }

    return (
        <>
            {/* attachment modal */}
            {attachmentModalOpen ?
                <ViewAttachment
                    attachmentModalOpen={attachmentModalOpen}
                    currentAttachmentIndex={currentAttachmentIndex}
                    allAttachments={allAttachments}
                    closeAttachmentModal={closeAttachmentModal}
                />
                :
                null
            }
            <Grid container style={{ marginTop: 16 }} direction="column" item xs={12}>
                <Typography gutterBottom variant="h6">
                    Attachments ({newAttachments?.length ?? 0 + attachments?.length ?? 0})
                </Typography>
                <Grid style={styles.attachmentGallery}>
                    {allowEdit === true &&
                        <>
                            <input
                                accept="image/*"
                                style={styles.attachmentInput}
                                id="contained-attachment-input"
                                name="imageFilesArray"
                                multiple
                                type="file"
                                onChange={handleSelectedAttachments}
                            />
                            <Card
                                variant='outlined'
                                style={{ maxHeight: 180, marginLeft: 4, marginRight: 4 }}
                            >
                                <CardActionArea>
                                    <label
                                        style={getAttachmentsButtonStyle(attachments, newAttachments)}
                                        htmlFor="contained-attachment-input"
                                    >
                                        <AddPhotoAlternateIcon />
                                        <Typography
                                            variant='body2'
                                            style={{ marginTop: 8, textAlign: 'center', fontWeight: 'bold' }}
                                        >
                                            {'ADD ATTACHMENTS \n (max 5MB)'}
                                        </Typography>
                                    </label>
                                </CardActionArea>
                            </Card>
                        </>
                    }
                    {(newAttachments ?? []).map((file, idx) => (
                        <CardMedia
                            key={`newAttachment-${idx}`}
                            onMouseEnter={() => onMouseEnterOnAttachment(`newAttachment-${idx}`)}
                            onMouseLeave={() => onMouseLeaveOffAttachment()}
                        >
                            {hovered === `newAttachment-${idx}` &&
                                <Grid
                                    style={{ position: 'relative' }}
                                >
                                    <IconButton
                                        aria-label='view'
                                        onClick={() => openAttachmentModal({ type: 'newAttachments', index: idx })}
                                        style={{
                                            position: 'absolute',
                                            top: 10,
                                            right: 10,
                                            fontSize: 40,
                                            zIndex: 1000
                                        }}
                                    >
                                        <VisibilityIcon fontSize="inherit" />
                                    </IconButton>
                                    {allowDelete === true &&
                                        <IconButton
                                            aria-label='delete'
                                            onClick={() => deleteAttachment({ type: 'newAttachments', index: idx })}
                                            style={{
                                                position: 'absolute',
                                                top: 70,
                                                right: 10,
                                                fontSize: 40,
                                                zIndex: 1000
                                            }}
                                        >
                                            <DeleteOutlineIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                </Grid>
                            }
                            <img
                                src={URL.createObjectURL(file)}
                                // onClick={openAttachmentModal}
                                style={hovered === `newAttachment-${idx}` ? { ...styles.attachment, ...styles.blur } : styles.attachment}
                                alt="newAttachment"
                            />
                        </CardMedia>
                    ))}
                    {(attachments ?? []).map((attachment, idx) => (
                        <CardMedia
                            key={`attachment-${idx}`}
                            onMouseEnter={() => onMouseEnterOnAttachment(`attachment-${idx}`)}
                            onMouseLeave={() => onMouseLeaveOffAttachment()}
                        >
                            {hovered === `attachment-${idx}` &&
                                <Grid
                                    style={{ position: 'relative' }}
                                >
                                    <IconButton
                                        aria-label='view'
                                        onClick={() => openAttachmentModal({ type: 'attachment', url: attachment?.url })}
                                        style={{
                                            position: 'absolute',
                                            top: 10,
                                            right: 10,
                                            fontSize: 40,
                                            zIndex: 1000
                                        }}
                                    >
                                        <VisibilityIcon fontSize="inherit" />
                                    </IconButton>
                                    {allowDelete === true &&
                                        <IconButton
                                            aria-label='delete'
                                            onClick={() => deleteAttachment({ type: 'attachment', url: attachment?.url })}
                                            style={{
                                                position: 'absolute',
                                                top: 70,
                                                right: 10,
                                                fontSize: 40,
                                                zIndex: 1000
                                            }}
                                        >
                                            <DeleteOutlineIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                </Grid>
                            }
                            <img
                                src={attachment?.url}
                                // onClick={openAttachmentModal}
                                style={hovered === `attachment-${idx}` ? { ...styles.attachment, ...styles.blur } : styles.attachment}
                                alt="dispatchAttachment"
                            />
                        </CardMedia>
                    ))}
                </Grid>
            </Grid>
        </>
    )
}


const getAttachmentsButtonStyle = (attachments, newAttachments) => {
    const commongProps = {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        paddingLeft: 4,
        paddingRight: 4,
        cursor: 'pointer'
    }
    if ((newAttachments?.length ?? 0 + attachments?.length ?? 0) > 0)
        return {
            flexDirection: 'column',
            justifyContent: 'center',
            height: 180,
            width: 180,
            ...commongProps
        }
    else
        return {
            justifyContent: 'space-between',
            width: 240,
            height: 45,
            ...commongProps
        }

}
export default AttachmentsComponent;