import { CircularProgress, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import DynamicMap from '../general/maps/DynamicMap'
import GeneralDropdownField from '../general/DropdownField'
import axios from 'axios'

const Maps = (props) => {
    const [state, setState] = useState({
        departments: [],
        selectedDepartment: null,
        loaders: {
            getDepartments: false
        }
    })

    const { departments, selectedDepartment, loaders } = state

    const getDepartments = async () => {
        const { accessToken } = props
        const { loaders } = state

        if (!loaders.getDepartments) {
            setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: true } }))

            try {
                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                const getDepartmentsResponse = await axios.get('/api/departments', config)
                if (getDepartmentsResponse.data.success) {
                    const departments = getDepartmentsResponse.data.departments

                    setState(prevState => ({
                        departments,
                        loaders: { ...prevState.loaders, getDepartments: false },
                    }))
                }
                else {
                    setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: false } }))
                }
            } catch (error) {
                setState(prevState => ({
                    loaders: { ...prevState.loaders, getDepartments: false }
                }))
            }
        }
    }

    useEffect(() => {
        getDepartments()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loaders.getDepartments) return (
        <Grid
            item
            xs={12}
            style={{ height: '60vh', alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 24, marginBottom: 24 }}
        >
            <CircularProgress
                size={36}
            />
        </Grid>
    )

    if (!departments?.length > 0) return (
        <Grid
            container
        >
            <Grid
                item
                xs={12}
                style={{ height: '60vh', alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 24, marginBottom: 24 }}
            >
                <Typography
                    variant='body1'
                >
                    No departments were found.
                </Typography>
            </Grid>
        </Grid>
    )

    return (
        <Grid
            style={{ margin: 16 }}
        >
            <Grid
                container
                style={{ marginBottom: 8 }}
            >
                <Typography variant="h6" >
                    Select Department *
                </Typography>
                <GeneralDropdownField
                    width={12}
                    options={
                        departments?.sort((a, b) => a.name.localeCompare(b.name))?.map(department => ({
                            value: department._id,
                            text: `${department.name} ${department.agency ?? ''}`
                        })
                        )}
                    format="autocomplete"
                    placeholder='Select a department...'
                    statePath={`selectedDepartment`}
                    value={selectedDepartment?._id}
                    handleInputChange={(_, { value }) => setState(prevState => ({ ...prevState, selectedDepartment: departments.find(d => d._id === value) }))}
                />
            </Grid>
            {!!selectedDepartment?._id ?
                <DynamicMap
                    departmentId={selectedDepartment?._id}
                    accessToken={props.accessToken}
                    center={[-103.485771, 40.954580]}
                    departmentHasWeatherPermissions={selectedDepartment?.featuresPermission?.weather}
                    markers={[]}
                    zoom={4}
                    mapViewHeight={'82vh'}
                />
                :
                null
            }
        </Grid>
    )
}

export default Maps;