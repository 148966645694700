import React, { useEffect, useRef, useState } from 'react'
import ToggleWrapper from '../general/ToggleWrapper'
import { Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import GeneralTextField from '../../components/general/TextField'
import axios from 'axios'
import { withSnackbar } from 'notistack'
import { OpenSnackBar } from '../../utilities/handleSnackBar'

const DispatchComments = ({ comments, dispatchId, accessToken, isActive }) => {
    const gridRef = useRef()

    useEffect(() => {
        scrollToBottom();
    }, [comments])

    const scrollToBottom = () => {
        if (gridRef.current) gridRef.current.scrollTop = gridRef.current.scrollHeight;
    }

    return (
        <ToggleWrapper
            headerComponent={
                <>
                    <Typography variant="h6">Comments ({comments?.length ?? 0})</Typography>
                    <Divider style={{ marginTop: 4 }} />
                </>
            }
            children={
                <>
                    <Grid
                        ref={gridRef}
                        style={{ maxHeight: 400, overflowY: 'scroll' }}
                    >
                        {!!comments?.length && comments.map((comment, index) => (
                            <Grid
                                container
                                direction='column'
                                key={comment.userName + index}
                                xs={12}
                                style={{
                                    marginBottom: 4,
                                    background: '#f6f4f4',
                                    paddingLeft: 8,
                                    marginRight: 'auto',
                                    maxWidth: '100%',
                                    borderRadius: 1,
                                    borderBottomLeftRadius: 14
                                }}
                            >
                                <Typography
                                    variant='subtitle2'
                                    style={{
                                        fontWeight: 'bold',
                                        color: '#055f59',
                                        fontSize: 16
                                    }}
                                >
                                    {comment.userName}
                                </Typography>
                                <Typography
                                    style={{
                                        marginTop: 4,
                                        maxWidth: '100%',
                                        wordWrap: 'break-word',
                                        color: 'black',
                                        fontSize: 14
                                    }}
                                >
                                    {comment.comment}
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: 12,
                                        marginLeft: 'auto',
                                        marginRight: 12,
                                        color: 'black'
                                    }}
                                >
                                    {moment(comment.createdAt).format('LLLL')}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                    {isActive &&
                        <PostComment
                            accessToken={accessToken}
                            dispatchId={dispatchId}
                        />
                    }
                </>
            }
        />
    )
}

export default DispatchComments;


const PostComment = withSnackbar(({ dispatchId, accessToken, enqueueSnackbar, closeSnackbar }) => {
    const [comment, setComment] = useState('')
    const [postCommentLoading, setPostCommentLoading] = useState(false)

    const asyncPostComment = async () => {
        if (postCommentLoading) return

        if (typeof comment !== 'string' || !comment?.length) {
            alert('Enter a valid comment')
            return
        }

        setPostCommentLoading(true)

        try {
            const config = {
                headers: {
                    Authorization: accessToken
                }
            }

            await axios.post(`/api/dispatches/${dispatchId}/comment`, { comment }, config)
        } catch (error) {
            OpenSnackBar(enqueueSnackbar, closeSnackbar, error?.response?.data?.errors?.[0]?.message ?? 'Something went wrong', "error")
        }

        setPostCommentLoading(false)
        setComment('')
    }

    return (
        <Grid
            container
            style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4 }}
        >
            <GeneralTextField
                multiline
                width={10}
                placeholder='Post a comment'
                value={comment}
                handleInputChange={(_, input) => setComment(input.value)}
            />
            <Button
                onClick={asyncPostComment}
                variant='outlined'
                style={{ marginLeft: 4 }}
                loading
            >
                {postCommentLoading ?
                    <CircularProgress
                        size={18}
                    />
                    :
                    "Post"
                }
            </Button>
        </Grid>
    )
})