// React
import React, { createRef, forwardRef, useImperativeHandle } from 'react'
// Components
import { ResponderStatusKeyValue } from '../../viewDispatch'
import DynamicMap from './DynamicMap'
// utils
import { getResponderIconAndTextColor, shouldRenderMap } from './utils'
import './dynamicMapMB.css'

const DispatchDynamicMap = forwardRef(({ userId, accessToken, departmentId, dispatchLocation, responders, containerStyle, mapStyle }, ref) => {
    let mapCenter = null
    const markers = []
    const mapRef = createRef(null)

    useImperativeHandle(ref, () => ({
        animateToCoordinates(coords) {
            animateToCoordinates(coords)
        }
    }))

    const animateToCoordinates = (coords) => {
        if (coords?.latitude) {
            mapRef.current?.animateToCoordinates(coords)
        }
    }

    if (!shouldRenderMap(dispatchLocation, responders)) return null

    if (dispatchLocation?.coordinates?.latitude) {
        mapCenter = [dispatchLocation.coordinates.longitude.toFixed(6), dispatchLocation.coordinates.latitude.toFixed(6)]
        const lat = dispatchLocation.coordinates.latitude.toFixed(6)
        const lng = dispatchLocation.coordinates.longitude.toFixed(6)
        let marker = {
            lat,
            lng,
            iconURL: 'https://firetext.s3.amazonaws.com/emgcyLoc.png',
            iconSize: '45px',
            type: 'dispatch',
            tooltipComponent: `
                <div>
                    <h3>
                        Emergency Location
                    </h3>
                    <p
                        style={{ padding: 8 }}
                        variant='body1'
                    >
                        Coordinates: ${lat}, ${lng}
                    </p>
                </div>`

        }
        markers.push(marker)
    }
    for (const responder of responders) {
        if (responder?.coordinates?.latitude) {
            if (!mapCenter) mapCenter = [responder.coordinates.longitude.toFixed(6), responder.coordinates.latitude.toFixed(6)]
            const lat = responder.coordinates.latitude.toFixed(6)
            const lng = responder.coordinates.longitude.toFixed(6)
            const responderTitle = responder?.userId === userId ? 'Me' : `${responder.firstName[0].toUpperCase()}${responder.lastName[0].toUpperCase()}`
            const responderIconAndTextColor = getResponderIconAndTextColor(responder?.userId === userId, responder)
            markers.unshift({
                lat,
                lng,
                type: 'responder',
                responderTitle,
                responderIconAndTextColor,
                tooltipComponent: `
                    <div style="color:${responderIconAndTextColor}">
                        <h3>
                            ${responder?.userId === userId ? 'Me' : `${responder.firstName} ${responder.lastName}`}
                        </h3>
                        ${responder?.responderStatus ?
                        `<p style="padding-left: 8; padding-bottom: 8" >
                                ${ResponderStatusKeyValue[responder.responderStatus]}
                            </p>`
                        :
                        ''
                    }
                        <p style="padding-left: 8; line-height: 14px;" >
                            Coordinates:
                        </p>
                        <p style="padding-left: 8;" >
                            ${lat}, ${lng}
                        </p>
                    </div>
                `

            })
        }
    }

    return (
        <DynamicMap
            ref={mapRef}
            departmentId={departmentId}
            accessToken={accessToken}
            departmentHasWeatherPermissions={false}
            center={mapCenter}
            markers={markers}
            containerStyle={containerStyle ?? { margin: 8 }}
            mapStyle={mapStyle ?? { width: '300px', height: '250px' }}
        />
    )
})

export default DispatchDynamicMap;

