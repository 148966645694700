import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'

const ActionConfirmation = (props) => {
    const { open, onClose, title, description, onConfirmAction } = props
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            {description ?
                <DialogContent>
                    <Typography
                        variant="subtitle2"
                    >
                        {description}
                    </Typography>
                </DialogContent>
                :
                null
            }
            <DialogActions>
                <Button
                    onClick={onClose}
                    color='primary'
                >
                    No
                </Button>
                <Button
                    onClick={
                        () => {
                            onClose()
                            onConfirmAction()
                        }
                    }
                    color='primary'
                    autoFocus
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default ActionConfirmation;