import React, { useEffect, useRef } from 'react'
import ReactHlsPlayer from 'react-hls-player/dist'

const DispatchHlsPlayer = React.memo(({ hlsStreamUrl, callbackGetIsPlayerPaused }) => {
    const playerRef = useRef(null)

    useEffect(() => {
        callbackGetIsPlayerPaused.current = () => {
            return !!(playerRef.current.paused || playerRef.current.ended)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ReactHlsPlayer
            playerRef={playerRef}
            src={hlsStreamUrl}
            hlsConfig={{
                startPosition: 0
            }}
            style={{ padding: 8 }}
            height={54}
            width={'100%'}
            autoPlay={false}
            controls={true}
        />
    )
})

export default DispatchHlsPlayer;
