const earthRadianInMiles = 3958.8
const radianUnit = 0.01745329251 //Math.PI/180


// this ftn calculates straight line distance between two points as the crow flies from point a to point b.
export const CalculateDistanceInMiles = (coordA, coordB) => {
    if (!coordA?.latitude || !coordB?.latitude) return null

    const rlat1 = coordA.latitude * radianUnit
    const rlat2 = coordB.latitude * radianUnit

    const difflat = rlat2 - rlat1
    const difflon = (coordB.longitude - coordA.longitude) * radianUnit

    const distanceInMiles = 2 * earthRadianInMiles * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)))

    return Number(distanceInMiles.toFixed(2))
}