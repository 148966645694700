// React
import React from 'react'

// Material UI
import { Tabs, Tab } from '@material-ui/core'

// Components
import Users from './users/index'
import Departments from './departments'
import Account from './Account'
import CallTypes from './CallType'
import RadioChannels from './RadioChannel'
import Department from './Department'

// Utilities
import theme from '../../utilities/theme'
import MobileAppVersion from './mobileAppVersion'

const initialState = {
    selectedTab: null,
    isAccountInfoUnsaved: false,
    isDepartmentInfoUnsaved: false
}

class Settings extends React.Component {
    state = initialState

    componentDidMount = () => {
        const { user } = this.props
        if (user.type === 'superAdmin' /*|| (user?.departments?.findIndex(department => department.role === 'departmentAdmin') !== -1)*/) {
            this.setState({ selectedTab: 'users' })
        } else {
            this.setState({ selectedTab: 'account' })
        }
    }

    setAccountInfoUnsavedFlag = (flag) => {
        this.setState({ isAccountInfoUnsaved: flag })
    }

    setDepartmentInfoUnsavedFlag = (flag) => {
        this.setState({ isDepartmentInfoUnsaved: flag })
    }

    handleTabChange = (e, tab) => {
        const { selectedTab, isAccountInfoUnsaved, isDepartmentInfoUnsaved } = this.state

        if ((selectedTab === 'account' && isAccountInfoUnsaved) || (selectedTab === 'department' && isDepartmentInfoUnsaved)) {
            let leaveTab = window.confirm(
                "Changes you made may not be saved. Are you sure you want to leave this page?"
            )
            if (!leaveTab) return
            else return this.setState({ isAccountInfoUnsaved: false, isDepartmentInfoUnsaved: false, selectedTab: tab })
        }
        this.setState({ selectedTab: tab })
    }

    render() {
        const { user, socket, accessToken, setTheme, currentTheme, playRadio } = this.props
        const { selectedTab, isAccountInfoUnsaved, isDepartmentInfoUnsaved } = this.state

        return (
            <div>
                {selectedTab &&
                    <Tabs
                        onChange={this.handleTabChange}
                        value={selectedTab}
                        variant="scrollable"
                        scrollButtons="auto"
                        style={{ backgroundColor: theme.palette.primary.backgroundGrey }}
                    >
                        {user.type === 'superAdmin' &&
                            <Tab
                                label='Users'
                                value='users'
                            />
                        }
                        {user.type === 'superAdmin' &&

                            <Tab
                                label='Departments'
                                value='departments'
                            />
                        }
                        {user.type === 'superAdmin' &&
                            <Tab
                                label='Call Types'
                                value='callTypes'
                            />
                        }
                        {user.type === 'superAdmin' &&
                            <Tab
                                label='Radio Channels'
                                value='radioChannels'
                            />
                        }
                        <Tab
                            label='Account'
                            value='account'
                        />
                        {/* if user is departmentAdmin in one or more departments */}
                        {(user?.departments?.findIndex(department => department.role === 'departmentAdmin') !== -1) &&
                            <Tab
                                label='Department'
                                value='department'
                            />
                        }
                        {user.type === 'superAdmin' &&
                            <Tab
                                label='Mobile App Versions'
                                value='mobileAppVersions'
                            />
                        }
                    </Tabs>
                }
                {selectedTab === 'account' &&
                    <Account
                        authenticatedUser={user}
                        accessToken={accessToken}
                        isAccountInfoUnsaved={isAccountInfoUnsaved}
                        setAccountInfoUnsavedFlag={this.setAccountInfoUnsavedFlag}
                        setTheme={setTheme}
                        currentTheme={currentTheme}
                    />
                }
                {selectedTab === 'users' &&
                    <Users
                        authenticatedUser={user}
                        accessToken={accessToken}
                    />
                }
                {selectedTab === "departments" &&
                    <Departments
                        authenticatedUser={user}
                        accessToken={accessToken}
                        playRadio={playRadio}
                        socket={socket}
                    />
                }

                {selectedTab === 'callTypes' &&
                    <CallTypes
                        authenticatedUser={user}
                        accessToken={accessToken}
                    />
                }
                {selectedTab === 'radioChannels' &&
                    <RadioChannels
                        authenticatedUser={user}
                        accessToken={accessToken}
                    />
                }
                {selectedTab === 'department' &&
                    <Department
                        authenticatedUser={user}
                        accessToken={accessToken}
                        isDepartmentInfoUnsaved={isDepartmentInfoUnsaved}
                        setDepartmentInfoUnsavedFlag={this.setDepartmentInfoUnsavedFlag}
                        playRadio={playRadio}
                    />
                }

                {selectedTab === 'mobileAppVersions' &&
                    <MobileAppVersion
                        authenticatedUser={user}
                        accessToken={accessToken}
                    />
                }

            </div>
        )
    }
}

export default Settings;
