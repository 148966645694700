// EMAIL
export const Email = (email) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(email)
}

export function ValidateAndTransformEmail(email) {
    if (!email || typeof email !== 'string') return null
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(email.toLocaleLowerCase().trim())) return null
    return email.toLocaleLowerCase().trim()
}

export function TransformToFrontendPhoneNumber(phone) {
    // input => [(345) 059-5732, +13450595732, 3450595732] 
    // output => '' or '3450595732' 
    return phone ? String(phone).replace(/\D/g, '').slice(-10) : null
}

export function ValidateAndTransformToBackendPhoneNumber(phone) {
    // input => [(345) 059-5732, +13450595732, 3450595732] 
    // output => +13450595732
    let transformedPhoneNumber = TransformToFrontendPhoneNumber(phone)
    if (transformedPhoneNumber?.length !== 10) return null

    return `+1${transformedPhoneNumber}`
}

export const Password = (password) => {
    var strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(password)
    var mediumRegex = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/.test(password)

    if (strongRegex) {
        return 'strong'
    } else if (mediumRegex) {
        return 'medium'
    } else if (!strongRegex && !mediumRegex) {
        return 'weak'
    }
}

export function ValidateSemVer(versionNumber) {
    const semVerExpr = /^((([0-9]+)\.([0-9]+)\.([0-9]+)(?:-([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?)(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?)$/
    return (semVerExpr.test(versionNumber))
}