import React, { Component } from 'react'
// Material UI
import { Grid, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Button, withStyles, CircularProgress } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import SettingsIcon from '@material-ui/icons/Settings'
import MaterialIcon from 'material-icons-react'
import { withSnackbar } from 'notistack'
// Utilities
import axios from "axios"
import Fuse from 'fuse.js'
import theme from "../../../utilities/theme"
import { handleInputChange, handleErrors } from "../../../utilities/handleChange"
import { OpenSnackBar } from '../../../utilities/handleSnackBar'
// Components
import GeneralTextField from "../../general/TextField"
import CreateNewCallTypeModal from './createNewCallTypeModal'
import EditCallTypeModal from './editCallTypeModal'

const initialState = {
    callTypes: [],
    info: {
        search: ''
    },
    filteredCallTypes: [],
    // departments: [],
    callType: {
        callType: "",
        icon1: "",
        icon2: "",
        default: false
    },
    errors: {
        callType: {
            callType: null,
            icon1: null,
            icon2: null,
            default: null
        },
        info: {

        }
    },
    modals: {
        createNewCallType: false,
        editCallType: false
    },
    loaders: {
        getCallTypes: false,
        // getDepartments: false,
        createNewCallType: false,
        updateCallType: false,
        deleteCallType: false
    }
}

export class CallTypes extends Component {

    state = initialState

    // Life cycle functions
    componentDidMount = async () => {
        await this.getCallTypes()
        // await this.getDepartments()
    }

    componentWillUnmount = () => {
        this.props.closeSnackbar()
    }

    // API
    getCallTypes = async () => {
        const { loaders } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.getCallTypes) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getCallTypes: true } }))
            try {
                const getCallTypesResponse = await axios.get('/api/calltypes', config)
                if (getCallTypesResponse && getCallTypesResponse.data && getCallTypesResponse.data.success) {
                    let callTypes = getCallTypesResponse.data.callTypes
                    this.setState(prevState => ({
                        callTypes,
                        filteredCallTypes: callTypes,
                        loaders: { ...prevState.loaders, getCallTypes: false }
                    }))
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: getCallTypesResponse.data.errors },
                        loaders: { ...prevState.loaders, getCallTypes: false }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getCallTypes: false }
                }))
            }
        }
    }

    // General
    handleErrors = () => {
        const { callType } = this.state
        let errors = { ...this.state.errors }

        let fields = [
            {
                path: 'callType.callType',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'callType.icon1',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'callType.icon2',
                type: 'string',
                shouldCheck: callType.icon2 && callType.icon2?.length > 0
            },
            {
                path: 'callType.default',
                type: 'bool',
                shouldCheck: true
            }
        ]

        let frontEndErrors = handleErrors(this.state, errors, fields)
        return frontEndErrors
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleSearchInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state), () => {
            this.searchCallTypes()
        })
    }

    handleModal = (modal, extra) => {
        const { modals } = this.state
        switch (modal) {
            case 'createNewCallType':
                if (modals.createNewCallType) {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, [modal]: !prevState.modals[modal] },
                        callType: initialState.callType,
                        errors: initialState.errors
                    }))
                } else {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, [modal]: !prevState.modals[modal] },
                        errors: initialState.errors
                    }))
                }
            case 'editCallType':
                if (modals.editCallType) {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, editCallType: false },
                        callType: initialState.callType,
                        errors: initialState.errors
                    }))
                } else {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, editCallType: true },
                        callType: extra
                    }))
                }
            default:
                return this.setState(prevState => ({ modals: { ...prevState.modals, [modal]: !prevState.modals[modal] } }))
        }
    }

    swapCallTypeIcons = () => {
        const { callType } = this.state
        this.setState({
            callType: {
                ...callType,
                icon1: callType.icon2,
                icon2: callType.icon1
            }
        })
    }

    searchCallTypes = () => {
        const { search } = this.state.info
        const { callTypes } = this.state

        if (search && search.length > 0) {
            const fuseResult = new Fuse(callTypes, {
                keys: [
                    { name: 'callType', weight: 0.8 }
                ],
                threshold: .5,
                includeMatches: true
            })
            let filteredCallTypes = fuseResult.search(search).map(resultObject => {
                return {
                    ...resultObject.item,
                }
            })

            this.setState({ filteredCallTypes })
        } else {
            this.setState({
                filteredCallTypes: [...callTypes]
            })
        }
    }

    resetSearch = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                search: ""
            }
        }), this.searchCallTypes)
    }

    // CRUD
    createCallType = async () => {
        let { loaders, callType } = this.state

        if (!loaders.createNewCallType) {

            const { success, errors } = this.handleErrors()
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, createNewCallType: true } }))
                const { accessToken } = this.props
                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                try {
                    const createCallTypeResponse = await axios.post('/api/calltypes', { callTypeInformation: { ...callType } }, config)
                    if (createCallTypeResponse && createCallTypeResponse.data && createCallTypeResponse.data.success) {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, createNewCallType: false }
                        }))
                        this.handleModal('createNewCallType')
                        this.getCallTypes()
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Call Type created successfully", "success")
                    } else {
                        this.setState(prevState => ({
                            errors: { ...prevState.errors, messages: createCallTypeResponse.data.errors },
                            loaders: { ...prevState.loaders, createNewCallType: false }
                        }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, createNewCallType: false }
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            } else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }
        }
    }

    updateCallType = async (type) => {
        const { callType, filteredCallTypes, loaders } = this.state

        if (!loaders.updateCallType) {
            const { success, errors } = this.handleErrors()
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, updateCallType: true } }))

                let formattedCallType = {
                    ...callType
                }

                const config = {
                    headers: {
                        Authorization: this.props.accessToken
                    }
                }

                try {
                    let updateCallTypeResponse = await axios.put(`/api/calltypes/${callType._id}`, formattedCallType, config)

                    if (updateCallTypeResponse.data.success) {
                        this.setState(prevState => ({ loaders: { ...prevState.loaders, updateCallType: false } }))
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Call Type updated successfully", "success")
                        let updatedCallTypes = filteredCallTypes.map(callTypeObject => {
                            if (callTypeObject._id === callType._id) {

                                return {
                                    ...callType,
                                    ...(updateCallTypeResponse.data?.callType ?? {})
                                }
                            } else {
                                return callTypeObject
                            }
                        })
                        this.setState(prevState => ({
                            callTypes: updatedCallTypes,
                            filteredCallTypes: updatedCallTypes
                        }), () => this.getCallTypes())
                        return true
                    } else {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, updateCallType: false },
                        }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, updateCallType: false },
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            }
            else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }

        }
    }

    deleteCallType = async () => {
        const { loaders, callType } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.deleteCallType) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, deleteCallType: true } }))
            try {
                const deleteCallTypeResponse = await axios.delete(`/api/calltypes/${callType._id}`, config)
                if (deleteCallTypeResponse && deleteCallTypeResponse.data && deleteCallTypeResponse.data.success) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, deleteCallType: false }
                    }))
                    this.handleModal('editCallType')
                    this.getCallTypes()
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Call Type deleted successfully", "success")
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: deleteCallTypeResponse.data.errors },
                        loaders: { ...prevState.loaders, deleteCallType: false }
                    }))
                }
            } catch (e) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, deleteCallType: false }
                }))
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
            }
        }
    }

    render() {
        const { authenticatedUser } = this.props
        const { callType, errors, modals, loaders, info, filteredCallTypes } = this.state
        return (
            <>
                {modals.createNewCallType &&
                    <CreateNewCallTypeModal
                        handleInputChange={this.handleInputChange}
                        handleClose={() => this.handleModal('createNewCallType')}
                        createCallType={this.createCallType}
                        authenticatedUser={authenticatedUser}
                        callType={callType}
                        errors={errors}
                        loaders={loaders}
                        open={modals.createNewCallType}
                        swapCallTypeIcons={this.swapCallTypeIcons}
                    />
                }
                {modals.editCallType &&
                    <EditCallTypeModal
                        handleInputChange={this.handleInputChange}
                        handleClose={() => this.handleModal('editCallType')}
                        deleteCallType={this.deleteCallType}
                        callType={callType}
                        errors={errors}
                        loaders={loaders}
                        open={modals.editCallType}
                        updateCallType={this.updateCallType}
                        authenticatedUser={authenticatedUser}
                        swapCallTypeIcons={this.swapCallTypeIcons}
                    />
                }
                <Grid
                    xs={12}
                    container
                    style={{ display: 'flex', flex: 1, justifyContent: 'space-between', marginTop: 8, borderRadius: 0, marginBottom: 12 }}
                >
                    <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}
                    >
                        <Button
                            onClick={() => this.handleModal('createNewCallType')}
                            variant='outlined'
                        >
                            <AddBoxIcon
                                style={{ marginRight: 8 }}
                            />
                            Add Call Type
                        </Button>
                    </Grid>
                    <Grid
                        style={{ display: 'flex', paddingRight: 24 }}
                        item
                    >
                        <GeneralTextField
                            format="search"
                            gridStyle={{ width: 350 }}
                            placeholder='Search call types...'
                            statePath={`info.search`}
                            value={info.search}
                            handleInputChange={this.handleSearchInputChange}
                            resetSearch={this.resetSearch}
                        />
                    </Grid>
                </Grid>
                <TableContainer
                    component={Paper}
                    elevation={0}
                    style={{
                        borderRadius: 0,
                        marginBottom: 64
                    }}
                >
                    <Table
                        style={{ minWidth: 650 }}
                        aria-label='Call Types'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Call Type
                                </TableCell>
                                <TableCell>
                                    Icon 1
                                </TableCell>
                                <TableCell>
                                    Icon 2
                                </TableCell>
                                <TableCell>
                                    Default
                                </TableCell>
                                <TableCell
                                    align='right'
                                >

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loaders.getCallTypes &&
                                <TableRow>
                                    <TableCell
                                        style={{ paddingTop: 96, paddingBottom: 96 }}
                                        colSpan={6}
                                        align="center"
                                    >
                                        <CircularProgress
                                            size={36}
                                        />

                                    </TableCell>
                                </TableRow>
                            }
                            {!loaders.getCallTypes && filteredCallTypes.map((callType) =>
                                <TableRow
                                    key={callType.callType}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.handleModal('editCallType', callType)}
                                >
                                    <TableCell
                                        component='th'
                                    >
                                        {callType.callType}
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                    >
                                        {callType.icon1 &&
                                            <MaterialIcon size='tiny' icon={callType.icon1} color={theme.palette.primary.main} preloader={<></>} />
                                        }
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                    >
                                        {callType.icon2 &&
                                            <MaterialIcon size='tiny' icon={callType.icon2} color={theme.palette.primary.main} preloader={<></>} />
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {`${callType.default}`}
                                    </TableCell>
                                    <TableCell
                                        align='right'
                                        width='10'
                                    >
                                        <IconButton
                                            onClick={() => this.handleModal('editCallType', callType)}
                                            size='small'
                                            disableFocusRipple={true}
                                            disableRipple={true}
                                        >
                                            <SettingsIcon />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
}

export default withSnackbar(withStyles(null, { withTheme: true })(CallTypes))