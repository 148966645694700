const materialIcons = {
    "host":"fonts.gstatic.com",
    "asset_url_pattern":"/s/i/{family}/{icon}/v{version}/{asset}",
    "families":[
       "Material Icons",
       "Material Icons Outlined",
       "Material Icons Round",
       "Material Icons Sharp",
       "Material Icons Two Tone"
    ],
    "icons":[
       {
          "name":"10k",

          "tags":[
             "10000",
             "10K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"10mp",

          "tags":[
             "10",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"11mp",

          "tags":[
             "11",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"12mp",

          "tags":[
             "12",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"13mp",

          "tags":[
             "13",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"14mp",

          "tags":[
             "14",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"15mp",

          "tags":[
             "15",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"16mp",

          "tags":[
             "16",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"17mp",

          "tags":[
             "17",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"18mp",

          "tags":[
             "18",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"19mp",

          "tags":[
             "19",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"1k",

          "tags":[
             "1000",
             "1K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"1k_plus",

          "tags":[
             "+",
             "1000",
             "1K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "plus",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"1x_mobiledata",

          "tags":[
             "1x",
             "alphabet",
             "cellular",
             "character",
             "digit",
             "font",
             "letter",
             "mobile",
             "mobiledata",
             "network",
             "number",
             "phone",
             "signal",
             "speed",
             "symbol",
             "text",
             "type",
             "wifi"
          ],

       },
       {
          "name":"20mp",

          "tags":[
             "20",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"21mp",

          "tags":[
             "21",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"22mp",

          "tags":[
             "22",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"23mp",

          "tags":[
             "23",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"24mp",

          "tags":[
             "24",
             "camera",
             "digits",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "numbers",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"2k",

          "tags":[
             "2000",
             "2K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"2k_plus",

          "tags":[
             "+",
             "2k",
             "alphabet",
             "character",
             "digit",
             "font",
             "letter",
             "number",
             "plus",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"2mp",

          "tags":[
             "2",
             "camera",
             "digit",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "number",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"30fps",

          "tags":[
             "30fps",
             "alphabet",
             "camera",
             "character",
             "digit",
             "font",
             "fps",
             "frames",
             "letter",
             "number",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"30fps_select",

          "tags":[
             "30",
             "camera",
             "digits",
             "fps",
             "frame",
             "frequency",
             "image",
             "numbers",
             "per",
             "rate",
             "second",
             "seconds",
             "select",
             "video"
          ],

       },
       {
          "name":"360",

          "tags":[
             "360",
             "arrow",
             "av",
             "camera",
             "direction",
             "rotate",
             "rotation",
             "vr"
          ],

       },
       {
          "name":"3d_rotation",

          "tags":[
             "3",
             "3d",
             "D",
             "alphabet",
             "arrow",
             "arrows",
             "av",
             "camera",
             "character",
             "digit",
             "font",
             "letter",
             "number",
             "rotation",
             "symbol",
             "text",
             "type",
             "vr"
          ],

       },
       {
          "name":"3g_mobiledata",

          "tags":[
             "3g",
             "alphabet",
             "cellular",
             "character",
             "digit",
             "font",
             "letter",
             "mobile",
             "mobiledata",
             "network",
             "number",
             "phone",
             "signal",
             "speed",
             "symbol",
             "text",
             "type",
             "wifi"
          ],

       },
       {
          "name":"3k",

          "tags":[
             "3000",
             "3K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"3k_plus",

          "tags":[
             "+",
             "3000",
             "3K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "plus",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"3mp",

          "tags":[
             "3",
             "camera",
             "digit",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "number",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"3p",

          "tags":[
             "3",
             "3p",
             "account",
             "avatar",
             "bubble",
             "chat",
             "comment",
             "communicate",
             "face",
             "human",
             "message",
             "party",
             "people",
             "person",
             "profile",
             "speech",
             "user"
          ],

       },
       {
          "name":"4g_mobiledata",

          "tags":[
             "4g",
             "alphabet",
             "cellular",
             "character",
             "digit",
             "font",
             "letter",
             "mobile",
             "mobiledata",
             "network",
             "number",
             "phone",
             "signal",
             "speed",
             "symbol",
             "text",
             "type",
             "wifi"
          ],

       },
       {
          "name":"4g_plus_mobiledata",

          "tags":[
             "4g",
             "alphabet",
             "cellular",
             "character",
             "digit",
             "font",
             "letter",
             "mobile",
             "mobiledata",
             "network",
             "number",
             "phone",
             "plus",
             "signal",
             "speed",
             "symbol",
             "text",
             "type",
             "wifi"
          ],

       },
       {
          "name":"4k",

          "tags":[
             "4000",
             "4K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"4k_plus",

          "tags":[
             "+",
             "4000",
             "4K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "plus",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"4mp",

          "tags":[
             "4",
             "camera",
             "digit",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "number",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"5g",

          "tags":[
             "5g",
             "alphabet",
             "cellular",
             "character",
             "data",
             "digit",
             "font",
             "letter",
             "mobile",
             "network",
             "number",
             "phone",
             "signal",
             "speed",
             "symbol",
             "text",
             "type",
             "wifi"
          ],

       },
       {
          "name":"5k",

          "tags":[
             "5000",
             "5K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"5k_plus",

          "tags":[
             "+",
             "5000",
             "5K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "plus",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"5mp",

          "tags":[
             "5",
             "camera",
             "digit",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "number",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"60fps",

          "tags":[
             "60fps",
             "camera",
             "digit",
             "fps",
             "frames",
             "number",
             "symbol",
             "video"
          ],

       },
       {
          "name":"60fps_select",

          "tags":[
             "60",
             "camera",
             "digits",
             "fps",
             "frame",
             "frequency",
             "numbers",
             "per",
             "rate",
             "second",
             "seconds",
             "select",
             "video"
          ],

       },
       {
          "name":"6_ft_apart",

          "tags":[
             "6",
             "apart",
             "body",
             "covid",
             "distance",
             "feet",
             "ft",
             "human",
             "people",
             "person",
             "social"
          ],

       },
       {
          "name":"6k",

          "tags":[
             "6000",
             "6K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"6k_plus",

          "tags":[
             "+",
             "6000",
             "6K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "plus",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"6mp",

          "tags":[
             "6",
             "camera",
             "digit",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "number",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"7k",

          "tags":[
             "7000",
             "7K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"7k_plus",

          "tags":[
             "+",
             "7000",
             "7K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "plus",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"7mp",

          "tags":[
             "7",
             "camera",
             "digit",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "number",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"8k",

          "tags":[
             "8000",
             "8K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"8k_plus",

          "tags":[
             "+",
             "7000",
             "8K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "plus",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"8mp",

          "tags":[
             "8",
             "camera",
             "digit",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "number",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"9k",

          "tags":[
             "9000",
             "9K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"9k_plus",

          "tags":[
             "+",
             "9000",
             "9K",
             "alphabet",
             "character",
             "digit",
             "display",
             "font",
             "letter",
             "number",
             "pixel",
             "pixels",
             "plus",
             "resolution",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"9mp",

          "tags":[
             "9",
             "camera",
             "digit",
             "font",
             "image",
             "letters",
             "megapixel",
             "megapixels",
             "mp",
             "number",
             "pixel",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"ac_unit",

          "tags":[
             "ac",
             "air",
             "cold",
             "conditioner",
             "flake",
             "snow",
             "temperature",
             "unit",
             "weather",
             "winter"
          ],

       },
       {
          "name":"access_alarm",

          "tags":[
             
          ],

       },
       {
          "name":"access_alarms",

          "tags":[
             
          ],

       },
       {
          "name":"access_time",

          "tags":[
             
          ],

       },
       {
          "name":"access_time_filled",

          "tags":[
             
          ],

       },
       {
          "name":"accessibility",

          "tags":[
             "accessibility",
             "accessible",
             "body",
             "handicap",
             "help",
             "human",
             "people",
             "person"
          ],

       },
       {
          "name":"accessibility_new",

          "tags":[
             "accessibility",
             "accessible",
             "body",
             "handicap",
             "help",
             "human",
             "people",
             "person"
          ],

       },
       {
          "name":"accessible",

          "tags":[
             "accessibility",
             "accessible",
             "body",
             "handicap",
             "help",
             "human",
             "people",
             "person",
             "wheelchair"
          ],

       },
       {
          "name":"accessible_forward",

          "tags":[
             "accessibility",
             "accessible",
             "body",
             "forward",
             "handicap",
             "help",
             "human",
             "people",
             "person",
             "wheelchair"
          ],

       },
       {
          "name":"account_balance",

          "tags":[
             "account",
             "balance",
             "bank",
             "bill",
             "card",
             "cash",
             "coin",
             "commerce",
             "credit",
             "currency",
             "dollars",
             "finance",
             "money",
             "online",
             "pay",
             "payment"
          ],

       },
       {
          "name":"account_balance_wallet",

          "tags":[
             "account",
             "balance",
             "bank",
             "bill",
             "card",
             "cash",
             "coin",
             "commerce",
             "credit",
             "currency",
             "dollars",
             "finance",
             "money",
             "online",
             "pay",
             "payment",
             "wallet"
          ],

       },
       {
          "name":"account_box",

          "tags":[
             "account",
             "avatar",
             "box",
             "face",
             "human",
             "people",
             "person",
             "profile",
             "square",
             "thumbnail",
             "user"
          ],

       },
       {
          "name":"account_circle",

          "tags":[
             "account",
             "avatar",
             "circle",
             "face",
             "human",
             "people",
             "person",
             "profile",
             "thumbnail",
             "user"
          ],

       },
       {
          "name":"account_tree",

          "tags":[
             "account",
             "analytics",
             "chart",
             "connect",
             "data",
             "diagram",
             "flow",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "process",
             "square",
             "statistics",
             "structure",
             "tracking",
             "tree"
          ],

       },
       {
          "name":"ad_units",

          "tags":[
             "Android",
             "OS",
             "ad",
             "banner",
             "cell",
             "device",
             "hardware",
             "iOS",
             "mobile",
             "notification",
             "notifications",
             "phone",
             "tablet",
             "top",
             "units"
          ],

       },
       {
          "name":"adb",

          "tags":[
             "adb",
             "android",
             "bridge",
             "debug"
          ],

       },
       {
          "name":"add",

          "tags":[
             "+",
             "add",
             "create",
             "new",
             "new symbol",
             "plus",
             "symbol"
          ],

       },
       {
          "name":"add_a_photo",

          "tags":[
             "+",
             "a photo",
             "add",
             "camera",
             "lens",
             "new",
             "photography",
             "picture",
             "plus",
             "symbol"
          ],

       },
       {
          "name":"add_alarm",

          "tags":[
             
          ],

       },
       {
          "name":"add_alert",

          "tags":[
             "+",
             "active",
             "add",
             "alarm",
             "alert",
             "bell",
             "chime",
             "new",
             "notifications",
             "notify",
             "plus",
             "reminder",
             "ring",
             "sound",
             "symbol"
          ],

       },
       {
          "name":"add_box",

          "tags":[
             "add",
             "box",
             "new square",
             "plus",
             "symbol"
          ],

       },
       {
          "name":"add_business",

          "tags":[
             "+",
             "add",
             "business",
             "market",
             "plus",
             "retail",
             "shop",
             "shopping",
             "store",
             "storefront"
          ],

       },
       {
          "name":"add_chart",

          "tags":[
             "+",
             "add",
             "analytics",
             "bar",
             "bars",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "new",
             "plus",
             "statistics",
             "symbol",
             "tracking"
          ],

       },
       {
          "name":"add_circle",

          "tags":[
             "+",
             "add",
             "circle",
             "create",
             "new",
             "plus"
          ],

       },
       {
          "name":"add_circle_outline",

          "tags":[
             "+",
             "add",
             "circle",
             "create",
             "new",
             "outline",
             "plus"
          ],

       },
       {
          "name":"add_comment",

          "tags":[
             "+",
             "add",
             "bubble",
             "chat",
             "comment",
             "communicate",
             "feedback",
             "message",
             "new",
             "plus",
             "speech",
             "symbol"
          ],

       },
       {
          "name":"add_ic_call",

          "tags":[
             "+",
             "add",
             "call",
             "cell",
             "contact",
             "device",
             "hardware",
             "ic",
             "mobile",
             "new",
             "phone",
             "plus",
             "symbol",
             "telephone"
          ],

       },
       {
          "name":"add_link",

          "tags":[
             "add",
             "attach",
             "clip",
             "link",
             "new",
             "plus",
             "symbol"
          ],

       },
       {
          "name":"add_location",

          "tags":[
             "+",
             "add",
             "destination",
             "direction",
             "location",
             "maps",
             "new",
             "pin",
             "place",
             "plus",
             "stop",
             "symbol"
          ],

       },
       {
          "name":"add_location_alt",

          "tags":[
             "+",
             "add",
             "alt",
             "destination",
             "direction",
             "location",
             "maps",
             "new",
             "pin",
             "place",
             "plus",
             "stop",
             "symbol"
          ],

       },
       {
          "name":"add_moderator",

          "tags":[
             "+",
             "add",
             "certified",
             "moderator",
             "new",
             "plus",
             "privacy",
             "private",
             "protect",
             "protection",
             "security",
             "shield",
             "symbol",
             "verified"
          ],

       },
       {
          "name":"add_photo_alternate",

          "tags":[
             "+",
             "add",
             "alternate",
             "image",
             "landscape",
             "mountain",
             "mountains",
             "new",
             "photo",
             "photography",
             "picture",
             "plus",
             "symbol"
          ],

       },
       {
          "name":"add_reaction",

          "tags":[
             
          ],

       },
       {
          "name":"add_road",

          "tags":[
             "+",
             "add",
             "destination",
             "direction",
             "highway",
             "maps",
             "new",
             "plus",
             "road",
             "stop",
             "street",
             "symbol",
             "traffic"
          ],

       },
       {
          "name":"add_shopping_cart",

          "tags":[
             "add",
             "card",
             "cart",
             "cash",
             "checkout",
             "coin",
             "commerce",
             "credit",
             "currency",
             "dollars",
             "money",
             "online",
             "pay",
             "payment",
             "plus",
             "shopping"
          ],

       },
       {
          "name":"add_task",

          "tags":[
             "+",
             "add",
             "approve",
             "check",
             "circle",
             "completed",
             "increase",
             "mark",
             "ok",
             "plus",
             "select",
             "task",
             "tick",
             "yes"
          ],

       },
       {
          "name":"add_to_drive",

          "tags":[
             "+",
             "add",
             "app",
             "backup",
             "cloud",
             "drive",
             "files",
             "folders",
             "gdrive",
             "google",
             "plus",
             "storage",
             "to"
          ],

       },
       {
          "name":"add_to_home_screen",

          "tags":[
             "Android",
             "OS",
             "add to",
             "arrow",
             "cell",
             "device",
             "hardware",
             "home",
             "iOS",
             "mobile",
             "phone",
             "screen",
             "tablet",
             "up"
          ],

       },
       {
          "name":"add_to_photos",

          "tags":[
             "add",
             "collection",
             "image",
             "landscape",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "photos",
             "picture",
             "plus",
             "to"
          ],

       },
       {
          "name":"add_to_queue",

          "tags":[
             "+",
             "Android",
             "OS",
             "add",
             "chrome",
             "desktop",
             "device",
             "display",
             "hardware",
             "iOS",
             "mac",
             "monitor",
             "new",
             "plus",
             "queue",
             "screen",
             "symbol",
             "to",
             "web",
             "window"
          ],

       },
       {
          "name":"addchart",

          "tags":[
             "add",
             "analytics",
             "bar",
             "bars",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "plus",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"adjust",

          "tags":[
             "adjust",
             "alter",
             "center",
             "circle",
             "circles",
             "control",
             "dot",
             "edit",
             "filter",
             "fix",
             "image",
             "mix",
             "move",
             "setting",
             "slider",
             "sort",
             "switch",
             "target",
             "tune"
          ],

       },
       {
          "name":"admin_panel_settings",

          "tags":[
             "account",
             "admin",
             "avatar",
             "certified",
             "face",
             "human",
             "panel",
             "people",
             "person",
             "privacy",
             "private",
             "profile",
             "protect",
             "protection",
             "security",
             "settings",
             "shield",
             "user",
             "verified"
          ],

       },
       {
          "name":"ads_click",

          "tags":[
             
          ],

       },
       {
          "name":"agriculture",

          "tags":[
             "agriculture",
             "automobile",
             "car",
             "cars",
             "cultivation",
             "farm",
             "harvest",
             "maps",
             "tractor",
             "transport",
             "travel",
             "truck",
             "vehicle"
          ],

       },
       {
          "name":"air",

          "tags":[
             "air",
             "blowing",
             "breeze",
             "flow",
             "wave",
             "weather",
             "wind"
          ],

       },
       {
          "name":"airline_seat_flat",

          "tags":[
             "airline",
             "body",
             "business",
             "class",
             "first",
             "flat",
             "human",
             "people",
             "person",
             "rest",
             "seat",
             "sleep",
             "travel"
          ],

       },
       {
          "name":"airline_seat_flat_angled",

          "tags":[
             "airline",
             "angled",
             "body",
             "business",
             "class",
             "first",
             "flat",
             "human",
             "people",
             "person",
             "rest",
             "seat",
             "sleep",
             "travel"
          ],

       },
       {
          "name":"airline_seat_individual_suite",

          "tags":[
             "airline",
             "body",
             "business",
             "class",
             "first",
             "human",
             "individual",
             "people",
             "person",
             "rest",
             "seat",
             "sleep",
             "suite",
             "travel"
          ],

       },
       {
          "name":"airline_seat_legroom_extra",

          "tags":[
             "airline",
             "body",
             "extra",
             "feet",
             "human",
             "leg",
             "legroom",
             "people",
             "person",
             "seat",
             "sitting",
             "space",
             "travel"
          ],

       },
       {
          "name":"airline_seat_legroom_normal",

          "tags":[
             "airline",
             "body",
             "feet",
             "human",
             "leg",
             "legroom",
             "normal",
             "people",
             "person",
             "seat",
             "sitting",
             "space",
             "travel"
          ],

       },
       {
          "name":"airline_seat_legroom_reduced",

          "tags":[
             "airline",
             "body",
             "feet",
             "human",
             "leg",
             "legroom",
             "people",
             "person",
             "reduced",
             "seat",
             "sitting",
             "space",
             "travel"
          ],

       },
       {
          "name":"airline_seat_recline_extra",

          "tags":[
             "airline",
             "body",
             "extra",
             "feet",
             "human",
             "leg",
             "legroom",
             "people",
             "person",
             "seat",
             "sitting",
             "space",
             "travel"
          ],

       },
       {
          "name":"airline_seat_recline_normal",

          "tags":[
             "airline",
             "body",
             "extra",
             "feet",
             "human",
             "leg",
             "legroom",
             "normal",
             "people",
             "person",
             "recline",
             "seat",
             "sitting",
             "space",
             "travel"
          ],

       },
       {
          "name":"airline_stops",

          "tags":[
             
          ],

       },
       {
          "name":"airlines",

          "tags":[
             
          ],

       },
       {
          "name":"airplane_ticket",

          "tags":[
             "airplane",
             "airport",
             "boarding",
             "flight",
             "fly",
             "maps",
             "pass",
             "ticket",
             "transportation",
             "travel"
          ],

       },
       {
          "name":"airplanemode_active",

          "tags":[
             "active",
             "airplane",
             "airplanemode",
             "flight",
             "mode",
             "on",
             "signal"
          ],

       },
       {
          "name":"airplanemode_inactive",

          "tags":[
             "airplane",
             "airplanemode",
             "airport",
             "disabled",
             "enabled",
             "flight",
             "fly",
             "inactive",
             "maps",
             "mode",
             "off",
             "offline",
             "on",
             "slash",
             "transportation",
             "travel"
          ],

       },
       {
          "name":"airplay",

          "tags":[
             "airplay",
             "apple",
             "arrow",
             "cast",
             "connect",
             "control",
             "desktop",
             "device",
             "display",
             "monitor",
             "screen",
             "signal",
             "tv"
          ],

       },
       {
          "name":"airport_shuttle",

          "tags":[
             "airport",
             "automobile",
             "car",
             "cars",
             "commercial",
             "delivery",
             "direction",
             "maps",
             "mini",
             "public",
             "shuttle",
             "transport",
             "transportation",
             "travel",
             "truck",
             "van",
             "vehicle"
          ],

       },
       {
          "name":"alarm",

          "tags":[
             "alarm",
             "alart",
             "bell",
             "clock",
             "countdown",
             "date",
             "notification",
             "schedule",
             "time"
          ],

       },
       {
          "name":"alarm_add",

          "tags":[
             "+",
             "add",
             "alarm",
             "alart",
             "bell",
             "clock",
             "countdown",
             "date",
             "new",
             "notification",
             "plus",
             "schedule",
             "symbol",
             "time"
          ],

       },
       {
          "name":"alarm_off",

          "tags":[
             "alarm",
             "alart",
             "bell",
             "clock",
             "disabled",
             "duration",
             "enabled",
             "notification",
             "off",
             "on",
             "slash",
             "time",
             "timer",
             "watch"
          ],

       },
       {
          "name":"alarm_on",

          "tags":[
             "alarm",
             "alart",
             "bell",
             "clock",
             "disabled",
             "duration",
             "enabled",
             "notification",
             "off",
             "on",
             "slash",
             "time",
             "timer",
             "watch"
          ],

       },
       {
          "name":"album",

          "tags":[
             "album",
             "artist",
             "audio",
             "bvb",
             "cd",
             "computer",
             "data",
             "disk",
             "file",
             "music",
             "record",
             "sound",
             "storage",
             "track",
             "vinyl"
          ],

       },
       {
          "name":"align_horizontal_center",

          "tags":[
             "align",
             "alignment",
             "center",
             "format",
             "horizontal",
             "layout",
             "lines",
             "paragraph",
             "rule",
             "rules",
             "style",
             "text"
          ],

       },
       {
          "name":"align_horizontal_left",

          "tags":[
             "align",
             "alignment",
             "format",
             "horizontal",
             "layout",
             "left",
             "lines",
             "paragraph",
             "rule",
             "rules",
             "style",
             "text"
          ],

       },
       {
          "name":"align_horizontal_right",

          "tags":[
             "align",
             "alignment",
             "format",
             "horizontal",
             "layout",
             "lines",
             "paragraph",
             "right",
             "rule",
             "rules",
             "style",
             "text"
          ],

       },
       {
          "name":"align_vertical_bottom",

          "tags":[
             "align",
             "alignment",
             "bottom",
             "format",
             "layout",
             "lines",
             "paragraph",
             "rule",
             "rules",
             "style",
             "text",
             "vertical"
          ],

       },
       {
          "name":"align_vertical_center",

          "tags":[
             "align",
             "alignment",
             "center",
             "format",
             "layout",
             "lines",
             "paragraph",
             "rule",
             "rules",
             "style",
             "text",
             "vertical"
          ],

       },
       {
          "name":"align_vertical_top",

          "tags":[
             "align",
             "alignment",
             "format",
             "layout",
             "lines",
             "paragraph",
             "rule",
             "rules",
             "style",
             "text",
             "top",
             "vertical"
          ],

       },
       {
          "name":"all_inbox",

          "tags":[
             "Inbox",
             "all",
             "delivered",
             "delivery",
             "email",
             "mail",
             "message",
             "send"
          ],

       },
       {
          "name":"all_inclusive",

          "tags":[
             "all",
             "endless",
             "forever",
             "inclusive",
             "infinity",
             "loop",
             "mobius",
             "neverending",
             "strip",
             "sustainability",
             "sustainable"
          ],

       },
       {
          "name":"all_out",

          "tags":[
             "all",
             "circle",
             "out",
             "shape"
          ],

       },
       {
          "name":"alt_route",

          "tags":[
             "alt",
             "alternate",
             "alternative",
             "arrows",
             "direction",
             "maps",
             "navigation",
             "options",
             "other",
             "route",
             "routes",
             "split",
             "symbol"
          ],

       },
       {
          "name":"alternate_email",

          "tags":[
             "@",
             "address",
             "alternate",
             "contact",
             "email",
             "tag"
          ],

       },
       {
          "name":"analytics",

          "tags":[
             "analytics",
             "assessment",
             "bar",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"anchor",

          "tags":[
             "anchor",
             "google",
             "logo"
          ],

       },
       {
          "name":"android",

          "tags":[
             "android",
             "character",
             "logo",
             "mascot",
             "toy"
          ],

       },
       {
          "name":"animation",

          "tags":[
             "animation",
             "circles",
             "motion",
             "movie",
             "moving",
             "video"
          ],

       },
       {
          "name":"announcement",

          "tags":[
             "!",
             "alert",
             "announcement",
             "attention",
             "bubble",
             "caution",
             "chat",
             "comment",
             "communicate",
             "danger",
             "error",
             "exclamation",
             "feedback",
             "important",
             "mark",
             "message",
             "notification",
             "speech",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"aod",

          "tags":[
             "Android",
             "OS",
             "always",
             "aod",
             "device",
             "display",
             "hardware",
             "homescreen",
             "iOS",
             "mobile",
             "on",
             "phone",
             "tablet"
          ],

       },
       {
          "name":"apartment",

          "tags":[
             "accommodation",
             "apartment",
             "architecture",
             "building",
             "city",
             "company",
             "estate",
             "flat",
             "home",
             "house",
             "office",
             "places",
             "real",
             "residence",
             "residential",
             "shelter",
             "units",
             "workplace"
          ],

       },
       {
          "name":"api",

          "tags":[
             "api",
             "developer",
             "development",
             "enterprise",
             "software"
          ],

       },
       {
          "name":"app_blocking",

          "tags":[
             "Android",
             "OS",
             "app",
             "applications",
             "cancel",
             "cell",
             "device",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "stop",
             "tablet"
          ],

       },
       {
          "name":"app_registration",

          "tags":[
             "app",
             "apps",
             "edit",
             "pencil",
             "register",
             "registration"
          ],

       },
       {
          "name":"app_settings_alt",

          "tags":[
             "Android",
             "OS",
             "app",
             "applications",
             "cell",
             "device",
             "gear",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "setting",
             "settings",
             "tablet"
          ],

       },
       {
          "name":"approval",

          "tags":[
             "apply",
             "approval",
             "approvals",
             "approve",
             "certificate",
             "certification",
             "disapproval",
             "drive",
             "file",
             "impression",
             "ink",
             "mark",
             "postage",
             "stamp"
          ],

       },
       {
          "name":"apps",

          "tags":[
             "all",
             "applications",
             "apps",
             "circles",
             "collection",
             "dots",
             "grid",
             "squares"
          ],

       },
       {
          "name":"apps_outage",

          "tags":[
             
          ],

       },
       {
          "name":"architecture",

          "tags":[
             "architecture",
             "art",
             "compass",
             "design",
             "draw",
             "drawing",
             "engineering",
             "geometric",
             "tool"
          ],

       },
       {
          "name":"archive",

          "tags":[
             "archive",
             "inbox",
             "mail",
             "store"
          ],

       },
       {
          "name":"area_chart",

          "tags":[
             
          ],

       },
       {
          "name":"arrow_back",

          "tags":[
             "app",
             "application",
             "arrow",
             "back",
             "components",
             "direction",
             "interface",
             "left",
             "navigation",
             "previous",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"arrow_back_ios",

          "tags":[
             "app",
             "application",
             "arrow",
             "back",
             "chevron",
             "components",
             "direction",
             "interface",
             "ios",
             "left",
             "navigation",
             "previous",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"arrow_back_ios_new",

          "tags":[
             "app",
             "application",
             "arrow",
             "back",
             "chevron",
             "components",
             "direction",
             "interface",
             "ios",
             "left",
             "navigation",
             "new",
             "previous",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"arrow_circle_down",

          "tags":[
             "arrow",
             "circle",
             "direction",
             "down",
             "navigation"
          ],

       },
       {
          "name":"arrow_circle_up",

          "tags":[
             "arrow",
             "circle",
             "direction",
             "navigation",
             "up"
          ],

       },
       {
          "name":"arrow_downward",

          "tags":[
             "app",
             "application",
             "arrow",
             "components",
             "direction",
             "down",
             "downward",
             "interface",
             "navigation",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"arrow_drop_down",

          "tags":[
             "app",
             "application",
             "arrow",
             "components",
             "direction",
             "down",
             "drop",
             "interface",
             "navigation",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"arrow_drop_down_circle",

          "tags":[
             "app",
             "application",
             "arrow",
             "circle",
             "components",
             "direction",
             "down",
             "drop",
             "interface",
             "navigation",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"arrow_drop_up",

          "tags":[
             "app",
             "application",
             "arrow",
             "components",
             "direction",
             "drop",
             "interface",
             "navigation",
             "screen",
             "site",
             "ui",
             "up",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"arrow_forward",

          "tags":[
             "app",
             "application",
             "arrow",
             "arrows",
             "components",
             "direction",
             "forward",
             "interface",
             "navigation",
             "right",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"arrow_forward_ios",

          "tags":[
             "app",
             "application",
             "arrow",
             "chevron",
             "components",
             "direction",
             "forward",
             "interface",
             "ios",
             "navigation",
             "next",
             "right",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"arrow_left",

          "tags":[
             "app",
             "application",
             "arrow",
             "back",
             "backstack",
             "backward",
             "components",
             "direction",
             "interface",
             "left",
             "navigation",
             "previous",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"arrow_right",

          "tags":[
             "app",
             "application",
             "arrow",
             "components",
             "continue",
             "direction",
             "forward",
             "interface",
             "navigation",
             "right",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"arrow_right_alt",

          "tags":[
             "alt",
             "arrow",
             "arrows",
             "direction",
             "east",
             "navigation",
             "pointing",
             "right"
          ],

       },
       {
          "name":"arrow_upward",

          "tags":[
             "app",
             "application",
             "arrow",
             "components",
             "direction",
             "interface",
             "navigation",
             "screen",
             "site",
             "ui",
             "up",
             "upward",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"art_track",

          "tags":[
             "album",
             "art",
             "artist",
             "audio",
             "image",
             "music",
             "photo",
             "photography",
             "picture",
             "sound",
             "track",
             "tracks"
          ],

       },
       {
          "name":"article",

          "tags":[
             "article",
             "clarify",
             "doc",
             "document",
             "file",
             "news",
             "page",
             "paper",
             "text",
             "writing"
          ],

       },
       {
          "name":"aspect_ratio",

          "tags":[
             "aspect",
             "expand",
             "image",
             "ratio",
             "resize",
             "scale",
             "size",
             "square"
          ],

       },
       {
          "name":"assessment",

          "tags":[
             "analytics",
             "assessment",
             "bar",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"assignment",

          "tags":[
             "assignment",
             "clipboard",
             "doc",
             "document",
             "text",
             "writing"
          ],

       },
       {
          "name":"assignment_ind",

          "tags":[
             "account",
             "assignment",
             "clipboard",
             "doc",
             "document",
             "face",
             "ind",
             "people",
             "person",
             "profile",
             "user"
          ],

       },
       {
          "name":"assignment_late",

          "tags":[
             "!",
             "alert",
             "assignment",
             "attention",
             "caution",
             "clipboard",
             "danger",
             "doc",
             "document",
             "error",
             "exclamation",
             "important",
             "late",
             "mark",
             "notification",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"assignment_return",

          "tags":[
             "arrow",
             "assignment",
             "back",
             "clipboard",
             "doc",
             "document",
             "left",
             "retun"
          ],

       },
       {
          "name":"assignment_returned",

          "tags":[
             "arrow",
             "assignment",
             "clipboard",
             "doc",
             "document",
             "down",
             "returned"
          ],

       },
       {
          "name":"assignment_turned_in",

          "tags":[
             "approve",
             "assignment",
             "check",
             "clipboard",
             "complete",
             "doc",
             "document",
             "done",
             "in",
             "mark",
             "ok",
             "select",
             "tick",
             "turn",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"assistant",

          "tags":[
             "assistant",
             "bubble",
             "chat",
             "comment",
             "communicate",
             "feedback",
             "message",
             "recommendation",
             "speech",
             "star",
             "suggestion",
             "twinkle"
          ],

       },
       {
          "name":"assistant_direction",

          "tags":[
             "assistant",
             "destination",
             "direction",
             "location",
             "maps",
             "navigate",
             "navigation",
             "pin",
             "place",
             "right",
             "stop"
          ],

       },
       {
          "name":"assistant_photo",

          "tags":[
             "assistant",
             "flag",
             "photo",
             "recommendation",
             "smart",
             "star",
             "suggestion"
          ],

       },
       {
          "name":"atm",

          "tags":[
             "alphabet",
             "atm",
             "automated",
             "bill",
             "card",
             "cart",
             "cash",
             "character",
             "coin",
             "commerce",
             "credit",
             "currency",
             "dollars",
             "font",
             "letter",
             "machine",
             "money",
             "online",
             "pay",
             "payment",
             "shopping",
             "symbol",
             "teller",
             "text",
             "type"
          ],

       },
       {
          "name":"attach_email",

          "tags":[
             "attach",
             "attachment",
             "clip",
             "compose",
             "email",
             "envelop",
             "letter",
             "link",
             "mail",
             "message",
             "send"
          ],

       },
       {
          "name":"attach_file",

          "tags":[
             "add",
             "attach",
             "clip",
             "file",
             "link",
             "mail",
             "media"
          ],

       },
       {
          "name":"attach_money",

          "tags":[
             "attach",
             "bill",
             "card",
             "cash",
             "coin",
             "commerce",
             "cost",
             "credit",
             "currency",
             "dollars",
             "finance",
             "money",
             "online",
             "pay",
             "payment",
             "symbol"
          ],

       },
       {
          "name":"attachment",

          "tags":[
             "attach",
             "attachment",
             "clip",
             "compose",
             "file",
             "image",
             "link"
          ],

       },
       {
          "name":"attractions",

          "tags":[
             "amusement",
             "attractions",
             "entertainment",
             "ferris",
             "fun",
             "maps",
             "park",
             "places",
             "wheel"
          ],

       },
       {
          "name":"attribution",

          "tags":[
             "attribute",
             "attribution",
             "body",
             "copyright",
             "copywriter",
             "human",
             "people",
             "person"
          ],

       },
       {
          "name":"audiotrack",

          "tags":[
             "audio",
             "audiotrack",
             "key",
             "music",
             "note",
             "sound",
             "track"
          ],

       },
       {
          "name":"auto_awesome",

          "tags":[
             "auto",
             "awesome",
             "edit",
             "editing",
             "enhance",
             "filter",
             "image",
             "photography",
             "photos",
             "setting",
             "stars"
          ],

       },
       {
          "name":"auto_awesome_mosaic",

          "tags":[
             "auto",
             "awesome",
             "edit",
             "editing",
             "filter",
             "grid",
             "image",
             "layout",
             "mosaic",
             "photographs",
             "photography",
             "photos",
             "pictures",
             "setting"
          ],

       },
       {
          "name":"auto_awesome_motion",

          "tags":[
             "animation",
             "auto",
             "awesome",
             "edit",
             "editing",
             "filter",
             "image",
             "live",
             "motion",
             "photographs",
             "photography",
             "photos",
             "pictures",
             "setting",
             "video"
          ],

       },
       {
          "name":"auto_delete",

          "tags":[
             "auto",
             "bin",
             "can",
             "clock",
             "date",
             "delete",
             "garbage",
             "remove",
             "schedule",
             "time",
             "trash"
          ],

       },
       {
          "name":"auto_fix_high",

          "tags":[
             "auto",
             "edit",
             "erase",
             "fix",
             "high",
             "magic",
             "modify",
             "star",
             "stars",
             "wand"
          ],

       },
       {
          "name":"auto_fix_normal",

          "tags":[
             "auto",
             "edit",
             "erase",
             "fix",
             "magic",
             "modify",
             "normal",
             "star",
             "stars",
             "wand"
          ],

       },
       {
          "name":"auto_fix_off",

          "tags":[
             "auto",
             "disabled",
             "edit",
             "enabled",
             "erase",
             "fix",
             "magic",
             "modify",
             "off",
             "on",
             "slash",
             "star",
             "stars",
             "wand"
          ],

       },
       {
          "name":"auto_graph",

          "tags":[
             "analytics",
             "auto",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "line",
             "measure",
             "metrics",
             "stars",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"auto_stories",

          "tags":[
             "audiobook",
             "auto",
             "book",
             "page",
             "reading",
             "stories",
             "story"
          ],

       },
       {
          "name":"autofps_select",

          "tags":[
             "A",
             "alphabet",
             "auto",
             "character",
             "font",
             "fps",
             "frame",
             "frequency",
             "letter",
             "per",
             "rate",
             "second",
             "seconds",
             "select",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"autorenew",

          "tags":[
             "around",
             "arrow",
             "arrows",
             "autorenew",
             "cache",
             "cached",
             "direction",
             "inprogress",
             "load",
             "loading refresh",
             "navigation",
             "renew",
             "rotate",
             "turn"
          ],

       },
       {
          "name":"av_timer",

          "tags":[
             "av",
             "clock",
             "countdown",
             "duration",
             "minutes",
             "seconds",
             "time",
             "timer",
             "watch"
          ],

       },
       {
          "name":"baby_changing_station",

          "tags":[
             "babies",
             "baby",
             "bathroom",
             "body",
             "changing",
             "child",
             "children",
             "father",
             "human",
             "infant",
             "kids",
             "mother",
             "newborn",
             "people",
             "person",
             "station",
             "toddler",
             "wc",
             "young"
          ],

       },
       {
          "name":"back_hand",

          "tags":[
             
          ],

       },
       {
          "name":"backpack",

          "tags":[
             "back",
             "backpack",
             "bag",
             "book",
             "bookbag",
             "knapsack",
             "pack",
             "storage",
             "travel"
          ],

       },
       {
          "name":"backspace",

          "tags":[
             "arrow",
             "back",
             "backspace",
             "cancel",
             "clear",
             "correct",
             "delete",
             "erase",
             "remove"
          ],

       },
       {
          "name":"backup",

          "tags":[
             "arrow",
             "backup",
             "cloud",
             "data",
             "drive",
             "files folders",
             "storage",
             "up",
             "upload"
          ],

       },
       {
          "name":"backup_table",

          "tags":[
             "backup",
             "drive",
             "files folders",
             "format",
             "layout",
             "stack",
             "storage",
             "table"
          ],

       },
       {
          "name":"badge",

          "tags":[
             "account",
             "avatar",
             "badge",
             "card",
             "certified",
             "employee",
             "face",
             "human",
             "identification",
             "name",
             "people",
             "person",
             "profile",
             "security",
             "user",
             "work"
          ],

       },
       {
          "name":"bakery_dining",

          "tags":[
             "bakery",
             "bread",
             "breakfast",
             "brunch",
             "croissant",
             "dining",
             "food"
          ],

       },
       {
          "name":"balcony",

          "tags":[
             "architecture",
             "balcony",
             "doors",
             "estate",
             "home",
             "house",
             "maps",
             "out",
             "outside",
             "place",
             "real",
             "residence",
             "residential",
             "stay",
             "terrace",
             "window"
          ],

       },
       {
          "name":"ballot",

          "tags":[
             "ballot",
             "bulllet",
             "election",
             "list",
             "point",
             "poll",
             "vote"
          ],

       },
       {
          "name":"bar_chart",

          "tags":[
             "analytics",
             "bar",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"batch_prediction",

          "tags":[
             "batch",
             "bulb",
             "idea",
             "light",
             "prediction"
          ],

       },
       {
          "name":"bathroom",

          "tags":[
             "bath",
             "bathroom",
             "closet",
             "home",
             "house",
             "place",
             "plumbing",
             "room",
             "shower",
             "sprinkler",
             "wash",
             "water",
             "wc"
          ],

       },
       {
          "name":"bathtub",

          "tags":[
             "bath",
             "bathing",
             "bathroom",
             "bathtub",
             "home",
             "hotel",
             "human",
             "person",
             "shower",
             "travel",
             "tub"
          ],

       },
       {
          "name":"battery_alert",

          "tags":[
             "!",
             "alert",
             "attention",
             "battery",
             "caution",
             "cell",
             "charge",
             "danger",
             "error",
             "exclamation",
             "important",
             "mark",
             "mobile",
             "notification",
             "power",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"battery_charging_full",

          "tags":[
             "battery",
             "bolt",
             "cell",
             "charge",
             "charging",
             "full",
             "lightening",
             "mobile",
             "power",
             "thunderbolt"
          ],

       },
       {
          "name":"battery_full",

          "tags":[
             "battery",
             "cell",
             "charge",
             "full",
             "mobile",
             "power"
          ],

       },
       {
          "name":"battery_saver",

          "tags":[
             "+",
             "add",
             "battery",
             "charge",
             "charging",
             "new",
             "plus",
             "power",
             "saver",
             "symbol"
          ],

       },
       {
          "name":"battery_std",

          "tags":[
             "battery",
             "cell",
             "charge",
             "mobile",
             "plus",
             "power",
             "standard",
             "std"
          ],

       },
       {
          "name":"battery_unknown",

          "tags":[
             "?",
             "assistance",
             "battery",
             "cell",
             "charge",
             "help",
             "info",
             "information",
             "mobile",
             "power",
             "punctuation",
             "question mark",
             "support",
             "symbol",
             "unknown"
          ],

       },
       {
          "name":"beach_access",

          "tags":[
             "access",
             "beach",
             "places",
             "summer",
             "sunny",
             "umbrella"
          ],

       },
       {
          "name":"bed",

          "tags":[
             "bed",
             "bedroom",
             "double",
             "full",
             "furniture",
             "home",
             "hotel",
             "house",
             "king",
             "night",
             "pillows",
             "queen",
             "rest",
             "room",
             "size",
             "sleep"
          ],

       },
       {
          "name":"bedroom_baby",

          "tags":[
             "babies",
             "baby",
             "bedroom",
             "child",
             "children",
             "home",
             "horse",
             "house",
             "infant",
             "kid",
             "newborn",
             "rocking",
             "room",
             "toddler",
             "young"
          ],

       },
       {
          "name":"bedroom_child",

          "tags":[
             "bed",
             "bedroom",
             "child",
             "children",
             "furniture",
             "home",
             "hotel",
             "house",
             "kid",
             "night",
             "pillows",
             "rest",
             "room",
             "size",
             "sleep",
             "twin",
             "young"
          ],

       },
       {
          "name":"bedroom_parent",

          "tags":[
             "bed",
             "bedroom",
             "double",
             "full",
             "furniture",
             "home",
             "hotel",
             "house",
             "king",
             "night",
             "parent",
             "pillows",
             "queen",
             "rest",
             "room",
             "sizem master",
             "sleep"
          ],

       },
       {
          "name":"bedtime",

          "tags":[
             
          ],

       },
       {
          "name":"beenhere",

          "tags":[
             "approve",
             "archive",
             "beenhere",
             "bookmark",
             "check",
             "complete",
             "done",
             "favorite",
             "label",
             "library",
             "mark",
             "ok",
             "read",
             "reading",
             "remember",
             "ribbon",
             "save",
             "select",
             "tag",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"bento",

          "tags":[
             "bento",
             "box",
             "dinner",
             "food",
             "lunch",
             "meal",
             "restaurant",
             "takeout"
          ],

       },
       {
          "name":"bike_scooter",

          "tags":[
             "automobile",
             "bike",
             "car",
             "cars",
             "maps",
             "scooter",
             "transportation",
             "vehicle",
             "vespa"
          ],

       },
       {
          "name":"biotech",

          "tags":[
             "biotech",
             "chemistry",
             "laboratory",
             "microscope",
             "research",
             "science",
             "technology"
          ],

       },
       {
          "name":"blender",

          "tags":[
             "appliance",
             "blender",
             "cooking",
             "electric",
             "juicer",
             "kitchen",
             "machine",
             "vitamix"
          ],

       },
       {
          "name":"block",

          "tags":[
             "avoid",
             "block",
             "cancel",
             "close",
             "entry",
             "exit",
             "no",
             "prohibited",
             "quit",
             "remove",
             "stop"
          ],

       },
       {
          "name":"bloodtype",

          "tags":[
             "blood",
             "bloodtype",
             "donate",
             "droplet",
             "emergency",
             "hospital",
             "medicine",
             "negative",
             "positive",
             "type",
             "water"
          ],

       },
       {
          "name":"bluetooth",

          "tags":[
             "bluetooth",
             "cast",
             "connect",
             "connection",
             "device",
             "paring",
             "streaming",
             "symbol",
             "wireless"
          ],

       },
       {
          "name":"bluetooth_audio",

          "tags":[
             "audio",
             "bluetooth",
             "connect",
             "connection",
             "device",
             "music",
             "signal",
             "sound",
             "symbol"
          ],

       },
       {
          "name":"bluetooth_connected",

          "tags":[
             "bluetooth",
             "cast",
             "connect",
             "connection",
             "device",
             "paring",
             "streaming",
             "symbol",
             "wireless"
          ],

       },
       {
          "name":"bluetooth_disabled",

          "tags":[
             "bluetooth",
             "cast",
             "connect",
             "connection",
             "device",
             "disabled",
             "enabled",
             "off",
             "offline",
             "on",
             "paring",
             "slash",
             "streaming",
             "symbol",
             "wireless"
          ],

       },
       {
          "name":"bluetooth_drive",

          "tags":[
             "automobile",
             "bluetooth",
             "car",
             "cars",
             "cast",
             "connect",
             "connection",
             "device",
             "drive",
             "maps",
             "paring",
             "streaming",
             "symbol",
             "transportation",
             "travel",
             "vehicle",
             "wireless"
          ],

       },
       {
          "name":"bluetooth_searching",

          "tags":[
             "bluetooth",
             "connection",
             "device",
             "paring",
             "search",
             "searching",
             "symbol"
          ],

       },
       {
          "name":"blur_circular",

          "tags":[
             "blur",
             "circle",
             "circular",
             "dots",
             "edit",
             "editing",
             "effect",
             "enhance",
             "filter"
          ],

       },
       {
          "name":"blur_linear",

          "tags":[
             "blur",
             "dots",
             "edit",
             "editing",
             "effect",
             "enhance",
             "filter",
             "linear"
          ],

       },
       {
          "name":"blur_off",

          "tags":[
             "blur",
             "disabled",
             "dots",
             "edit",
             "editing",
             "effect",
             "enabled",
             "enhance",
             "off",
             "on",
             "slash"
          ],

       },
       {
          "name":"blur_on",

          "tags":[
             "blur",
             "disabled",
             "dots",
             "edit",
             "editing",
             "effect",
             "enabled",
             "enhance",
             "filter",
             "off",
             "on",
             "slash"
          ],

       },
       {
          "name":"bolt",

          "tags":[
             "bolt",
             "electric",
             "energy",
             "fast",
             "flash",
             "lightning",
             "power",
             "thunderbolt"
          ],

       },
       {
          "name":"book",

          "tags":[
             "book",
             "bookmark",
             "favorite",
             "label",
             "library",
             "read",
             "reading",
             "remember",
             "ribbon",
             "save",
             "tag"
          ],

       },
       {
          "name":"book_online",

          "tags":[
             "Android",
             "OS",
             "admission",
             "appointment",
             "book",
             "cell",
             "device",
             "event",
             "hardware",
             "iOS",
             "mobile",
             "online",
             "pass",
             "phone",
             "reservation",
             "tablet",
             "ticket"
          ],

       },
       {
          "name":"bookmark",

          "tags":[
             "archive",
             "bookmark",
             "favorite",
             "follow",
             "label",
             "library",
             "read",
             "reading",
             "remember",
             "ribbon",
             "save",
             "tag"
          ],

       },
       {
          "name":"bookmark_add",

          "tags":[
             "+",
             "add",
             "bookmark",
             "favorite",
             "plus",
             "remember",
             "ribbon",
             "save",
             "symbol"
          ],

       },
       {
          "name":"bookmark_added",

          "tags":[
             "added",
             "approve",
             "bookmark",
             "check",
             "complete",
             "done",
             "favorite",
             "mark",
             "ok",
             "remember",
             "save",
             "select",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"bookmark_border",

          "tags":[
             "archive",
             "bookmark",
             "border",
             "favorite",
             "label",
             "library",
             "read",
             "reading",
             "remember",
             "ribbon",
             "save",
             "tag"
          ],

       },
       {
          "name":"bookmark_remove",

          "tags":[
             "bookmark",
             "delete",
             "favorite",
             "minus",
             "remember",
             "remove",
             "ribbon",
             "save",
             "subtract"
          ],

       },
       {
          "name":"bookmarks",

          "tags":[
             "bookmark",
             "bookmarks",
             "favorite",
             "label",
             "layers",
             "library",
             "multiple",
             "read",
             "reading",
             "remember",
             "ribbon",
             "save",
             "stack",
             "tag"
          ],

       },
       {
          "name":"border_all",

          "tags":[
             "all",
             "border",
             "doc",
             "edit",
             "editing",
             "editor",
             "sheet",
             "spreadsheet",
             "stroke",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"border_bottom",

          "tags":[
             "border",
             "bottom",
             "doc",
             "edit",
             "editing",
             "editor",
             "sheet",
             "spreadsheet",
             "stroke",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"border_clear",

          "tags":[
             "border",
             "clear",
             "doc",
             "edit",
             "editing",
             "editor",
             "sheet",
             "spreadsheet",
             "stroke",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"border_color",

          "tags":[
             "all",
             "border",
             "doc",
             "edit",
             "editing",
             "editor",
             "pen",
             "pencil",
             "sheet",
             "spreadsheet",
             "stroke",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"border_horizontal",

          "tags":[
             "border",
             "doc",
             "edit",
             "editing",
             "editor",
             "horizontal",
             "sheet",
             "spreadsheet",
             "stroke",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"border_inner",

          "tags":[
             "border",
             "doc",
             "edit",
             "editing",
             "editor",
             "inner",
             "sheet",
             "spreadsheet",
             "stroke",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"border_left",

          "tags":[
             "border",
             "doc",
             "edit",
             "editing",
             "editor",
             "left",
             "sheet",
             "spreadsheet",
             "stroke",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"border_outer",

          "tags":[
             "border",
             "doc",
             "edit",
             "editing",
             "editor",
             "outer",
             "sheet",
             "spreadsheet",
             "stroke",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"border_right",

          "tags":[
             "border",
             "doc",
             "edit",
             "editing",
             "editor",
             "right",
             "sheet",
             "spreadsheet",
             "stroke",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"border_style",

          "tags":[
             "border",
             "color",
             "doc",
             "edit",
             "editing",
             "editor",
             "sheet",
             "spreadsheet",
             "stroke",
             "style",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"border_top",

          "tags":[
             "border",
             "doc",
             "edit",
             "editing",
             "editor",
             "sheet",
             "spreadsheet",
             "stroke",
             "text",
             "top",
             "type",
             "writing"
          ],

       },
       {
          "name":"border_vertical",

          "tags":[
             "border",
             "doc",
             "edit",
             "editing",
             "editor",
             "sheet",
             "spreadsheet",
             "stroke",
             "text",
             "type",
             "vertical",
             "writing"
          ],

       },
       {
          "name":"branding_watermark",

          "tags":[
             "branding",
             "components",
             "copyright",
             "design",
             "emblem",
             "format",
             "identity",
             "interface",
             "layout",
             "logo",
             "screen",
             "site",
             "stamp",
             "ui",
             "ux",
             "watermark",
             "web",
             "website",
             "window"
          ],

       },
       {
          "name":"breakfast_dining",

          "tags":[
             "bakery",
             "bread",
             "breakfast",
             "butter",
             "dining",
             "food",
             "toast"
          ],

       },
       {
          "name":"brightness_1",

          "tags":[
             "1",
             "brightness",
             "circle",
             "control",
             "cresent",
             "level",
             "moon",
             "screen"
          ],

       },
       {
          "name":"brightness_2",

          "tags":[
             "2",
             "brightness",
             "circle",
             "control",
             "cresent",
             "level",
             "moon",
             "screen"
          ],

       },
       {
          "name":"brightness_3",

          "tags":[
             "3",
             "brightness",
             "circle",
             "control",
             "cresent",
             "level",
             "moon",
             "screen"
          ],

       },
       {
          "name":"brightness_4",

          "tags":[
             "4",
             "brightness",
             "circle",
             "control",
             "cresent",
             "level",
             "moon",
             "screen",
             "sun"
          ],

       },
       {
          "name":"brightness_5",

          "tags":[
             "5",
             "brightness",
             "circle",
             "control",
             "cresent",
             "level",
             "moon",
             "screen",
             "sun"
          ],

       },
       {
          "name":"brightness_6",

          "tags":[
             "6",
             "brightness",
             "circle",
             "control",
             "cresent",
             "level",
             "moon",
             "screen",
             "sun"
          ],

       },
       {
          "name":"brightness_7",

          "tags":[
             "7",
             "brightness",
             "circle",
             "control",
             "cresent",
             "level",
             "moon",
             "screen",
             "sun"
          ],

       },
       {
          "name":"brightness_auto",

          "tags":[
             "A",
             "auto",
             "brightness",
             "control",
             "display",
             "level",
             "mobile",
             "monitor",
             "phone",
             "screen",
             "sun"
          ],

       },
       {
          "name":"brightness_high",

          "tags":[
             "auto",
             "brightness",
             "control",
             "high",
             "mobile",
             "monitor",
             "phone",
             "sun"
          ],

       },
       {
          "name":"brightness_low",

          "tags":[
             "auto",
             "brightness",
             "control",
             "low",
             "mobile",
             "monitor",
             "phone",
             "sun"
          ],

       },
       {
          "name":"brightness_medium",

          "tags":[
             "auto",
             "brightness",
             "control",
             "medium",
             "mobile",
             "monitor",
             "phone",
             "sun"
          ],

       },
       {
          "name":"broken_image",

          "tags":[
             "broken",
             "corrupt",
             "error",
             "image",
             "landscape",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture",
             "torn"
          ],

       },
       {
          "name":"browser_not_supported",

          "tags":[
             "browser",
             "disabled",
             "enabled",
             "internet",
             "not",
             "off",
             "on",
             "page",
             "screen",
             "site",
             "slash",
             "supported",
             "web",
             "website",
             "www"
          ],

       },
       {
          "name":"browser_updated",

          "tags":[
             
          ],

       },
       {
          "name":"brunch_dining",

          "tags":[
             "breakfast",
             "brunch",
             "champaign",
             "dining",
             "drink",
             "food",
             "lunch",
             "meal"
          ],

       },
       {
          "name":"brush",

          "tags":[
             "art",
             "brush",
             "design",
             "draw",
             "edit",
             "editing",
             "paint",
             "painting",
             "tool"
          ],

       },
       {
          "name":"bubble_chart",

          "tags":[
             "analytics",
             "bar",
             "bars",
             "bubble",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"bug_report",

          "tags":[
             "animal",
             "bug",
             "fix",
             "insect",
             "issue",
             "problem",
             "report",
             "testing",
             "virus",
             "warning"
          ],

       },
       {
          "name":"build",

          "tags":[
             "adjust",
             "build",
             "fix",
             "repair",
             "tool",
             "wrench"
          ],

       },
       {
          "name":"build_circle",

          "tags":[
             "adjust",
             "build",
             "circle",
             "fix",
             "repair",
             "tool",
             "wrench"
          ],

       },
       {
          "name":"bungalow",

          "tags":[
             "architecture",
             "bungalow",
             "cottage",
             "estate",
             "home",
             "house",
             "maps",
             "place",
             "real",
             "residence",
             "residential",
             "stay",
             "traveling"
          ],

       },
       {
          "name":"burst_mode",

          "tags":[
             "burst",
             "image",
             "landscape",
             "mode",
             "mountain",
             "mountains",
             "multiple",
             "photo",
             "photography",
             "picture"
          ],

       },
       {
          "name":"bus_alert",

          "tags":[
             "!",
             "alert",
             "attention",
             "automobile",
             "bus",
             "car",
             "cars",
             "caution",
             "danger",
             "error",
             "exclamation",
             "important",
             "maps",
             "mark",
             "notification",
             "symbol",
             "transportation",
             "vehicle",
             "warning"
          ],

       },
       {
          "name":"business",

          "tags":[
             "apartment",
             "architecture",
             "building",
             "business",
             "company",
             "estate",
             "home",
             "place",
             "real",
             "residence",
             "residential",
             "shelter"
          ],

       },
       {
          "name":"business_center",

          "tags":[
             "bag",
             "baggage",
             "briefcase",
             "business",
             "case",
             "center",
             "places",
             "purse",
             "suitcase",
             "work"
          ],

       },
       {
          "name":"cabin",

          "tags":[
             "architecture",
             "cabin",
             "camping",
             "cottage",
             "estate",
             "home",
             "house",
             "log",
             "maps",
             "place",
             "real",
             "residence",
             "residential",
             "stay",
             "traveling",
             "wood"
          ],

       },
       {
          "name":"cable",

          "tags":[
             "cable",
             "connect",
             "connection",
             "device",
             "electronics",
             "usb",
             "wire"
          ],

       },
       {
          "name":"cached",

          "tags":[
             "around",
             "arrows",
             "cache",
             "cached",
             "inprogress",
             "load",
             "loading refresh",
             "renew",
             "rotate"
          ],

       },
       {
          "name":"cake",

          "tags":[
             "baked",
             "birthday",
             "cake",
             "candles",
             "celebration",
             "dessert",
             "food",
             "frosting",
             "party",
             "pastries",
             "pastry",
             "social",
             "sweet"
          ],

       },
       {
          "name":"calculate",

          "tags":[
             "+",
             "-",
             "=",
             "calculate",
             "count",
             "finance calculator",
             "math"
          ],

       },
       {
          "name":"calendar_today",

          "tags":[
             "calendar",
             "date",
             "day",
             "event",
             "month",
             "schedule",
             "today"
          ],

       },
       {
          "name":"calendar_view_day",

          "tags":[
             "calendar",
             "date",
             "day",
             "event",
             "format",
             "grid",
             "layout",
             "month",
             "schedule",
             "today",
             "view",
             "week"
          ],

       },
       {
          "name":"calendar_view_month",

          "tags":[
             "calendar",
             "date",
             "day",
             "event",
             "format",
             "grid",
             "layout",
             "month",
             "schedule",
             "today",
             "view"
          ],

       },
       {
          "name":"calendar_view_week",

          "tags":[
             "calendar",
             "date",
             "day",
             "event",
             "format",
             "grid",
             "layout",
             "month",
             "schedule",
             "today",
             "view",
             "week"
          ],

       },
       {
          "name":"call",

          "tags":[
             "call",
             "cell",
             "contact",
             "device",
             "hardware",
             "mobile",
             "phone",
             "telephone"
          ],

       },
       {
          "name":"call_end",

          "tags":[
             "call",
             "cell",
             "contact",
             "device",
             "end",
             "hardware",
             "mobile",
             "phone",
             "telephone"
          ],

       },
       {
          "name":"call_made",

          "tags":[
             "arrow",
             "call",
             "device",
             "made",
             "mobile"
          ],

       },
       {
          "name":"call_merge",

          "tags":[
             "arrow",
             "call",
             "device",
             "merge",
             "mobile"
          ],

       },
       {
          "name":"call_missed",

          "tags":[
             "arrow",
             "call",
             "device",
             "missed",
             "mobile"
          ],

       },
       {
          "name":"call_missed_outgoing",

          "tags":[
             "arrow",
             "call",
             "device",
             "missed",
             "mobile",
             "outgoing"
          ],

       },
       {
          "name":"call_received",

          "tags":[
             "arrow",
             "call",
             "device",
             "mobile",
             "received"
          ],

       },
       {
          "name":"call_split",

          "tags":[
             "arrow",
             "call",
             "device",
             "mobile",
             "split"
          ],

       },
       {
          "name":"call_to_action",

          "tags":[
             "action",
             "alert",
             "bar",
             "call",
             "components",
             "cta",
             "design",
             "info",
             "information",
             "interface",
             "layout",
             "message",
             "notification",
             "screen",
             "site",
             "to",
             "ui",
             "ux",
             "web",
             "website",
             "window"
          ],

       },
       {
          "name":"camera",

          "tags":[
             "album",
             "aperture",
             "camera",
             "lens",
             "photo",
             "photography",
             "picture",
             "record",
             "screenshot",
             "shutter"
          ],

       },
       {
          "name":"camera_alt",

          "tags":[
             "alt",
             "camera",
             "image",
             "photo",
             "photography",
             "picture"
          ],

       },
       {
          "name":"camera_enhance",

          "tags":[
             "camera",
             "enhance",
             "important",
             "lens",
             "photo",
             "photography",
             "picture",
             "quality",
             "special",
             "star"
          ],

       },
       {
          "name":"camera_front",

          "tags":[
             "body",
             "camera",
             "front",
             "human",
             "lens",
             "mobile",
             "person",
             "phone",
             "photography",
             "portrait",
             "selfie"
          ],

       },
       {
          "name":"camera_indoor",

          "tags":[
             "architecture",
             "building",
             "camera",
             "estate",
             "film",
             "filming",
             "home",
             "house",
             "image",
             "indoor",
             "inside",
             "motion",
             "nest",
             "picture",
             "place",
             "real",
             "residence",
             "residential",
             "shelter",
             "video",
             "videography"
          ],

       },
       {
          "name":"camera_outdoor",

          "tags":[
             "architecture",
             "building",
             "camera",
             "estate",
             "film",
             "filming",
             "home",
             "house",
             "image",
             "motion",
             "nest",
             "outdoor",
             "outside",
             "picture",
             "place",
             "real",
             "residence",
             "residential",
             "shelter",
             "video",
             "videography"
          ],

       },
       {
          "name":"camera_rear",

          "tags":[
             "camera",
             "front",
             "lens",
             "mobile",
             "phone",
             "photo",
             "photography",
             "picture",
             "portrait",
             "rear",
             "selfie"
          ],

       },
       {
          "name":"camera_roll",

          "tags":[
             "camera",
             "film",
             "image",
             "library",
             "photo",
             "photography",
             "roll"
          ],

       },
       {
          "name":"cameraswitch",

          "tags":[
             "arrows",
             "camera",
             "cameraswitch",
             "flip",
             "rotate",
             "swap",
             "switch",
             "view"
          ],

       },
       {
          "name":"campaign",

          "tags":[
             "alert",
             "announcement",
             "campaign",
             "loud",
             "megaphone",
             "microphone",
             "notification",
             "speaker"
          ],

       },
       {
          "name":"cancel",

          "tags":[
             "cancel",
             "circle",
             "close",
             "exit",
             "stop",
             "x"
          ],

       },
       {
          "name":"cancel_presentation",

          "tags":[
             "cancel",
             "close",
             "device",
             "exit",
             "no",
             "present",
             "presentation",
             "quit",
             "remove",
             "screen",
             "slide",
             "stop",
             "website",
             "window",
             "x"
          ],

       },
       {
          "name":"cancel_schedule_send",

          "tags":[
             "cancel",
             "email",
             "mail",
             "no",
             "quit",
             "remove",
             "schedule",
             "send",
             "share",
             "stop",
             "x"
          ],

       },
       {
          "name":"car_rental",

          "tags":[
             "automobile",
             "car",
             "cars",
             "key",
             "maps",
             "rental",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"car_repair",

          "tags":[
             "automobile",
             "car",
             "cars",
             "maps",
             "repair",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"card_giftcard",

          "tags":[
             "account",
             "balance",
             "bill",
             "card",
             "cart",
             "cash",
             "certificate",
             "coin",
             "commerce",
             "credit",
             "currency",
             "dollars",
             "gift",
             "giftcard",
             "money",
             "online",
             "pay",
             "payment",
             "present",
             "shopping"
          ],

       },
       {
          "name":"card_membership",

          "tags":[
             "bill",
             "bookmark",
             "card",
             "cash",
             "certificate",
             "coin",
             "commerce",
             "cost",
             "credit",
             "currency",
             "dollars",
             "inance",
             "loyalty",
             "membership",
             "money",
             "online",
             "pay",
             "payment",
             "shopping",
             "subscription"
          ],

       },
       {
          "name":"card_travel",

          "tags":[
             "bill",
             "card",
             "cash",
             "coin",
             "commerce",
             "cost",
             "credit",
             "currency",
             "dollars",
             "inance",
             "membership",
             "miles",
             "money",
             "online",
             "pay",
             "payment",
             "travel",
             "trip"
          ],

       },
       {
          "name":"carpenter",

          "tags":[
             "building",
             "carpenter",
             "construction",
             "cutting",
             "handyman",
             "repair",
             "saw",
             "tool"
          ],

       },
       {
          "name":"cases",

          "tags":[
             "bag",
             "baggage",
             "briefcase",
             "business",
             "case",
             "cases",
             "purse",
             "suitcase"
          ],

       },
       {
          "name":"casino",

          "tags":[
             "casino",
             "dice",
             "dots",
             "entertainment",
             "gamble",
             "gambling",
             "game",
             "games",
             "luck",
             "places"
          ],

       },
       {
          "name":"cast",

          "tags":[
             "Android",
             "OS",
             "airplay",
             "cast",
             "chrome",
             "connect",
             "desktop",
             "device",
             "display",
             "hardware",
             "iOS",
             "mac",
             "monitor",
             "screen",
             "screencast",
             "streaming",
             "television",
             "tv",
             "web",
             "window",
             "wireless"
          ],

       },
       {
          "name":"cast_connected",

          "tags":[
             "Android",
             "OS",
             "airplay",
             "cast",
             "chrome",
             "connect",
             "desktop",
             "device",
             "display",
             "hardware",
             "iOS",
             "mac",
             "monitor",
             "screen",
             "screencast",
             "streaming",
             "television",
             "tv",
             "web",
             "window",
             "wireless"
          ],

       },
       {
          "name":"cast_for_education",

          "tags":[
             "Android",
             "OS",
             "airplay",
             "cast",
             "chrome",
             "connect",
             "desktop",
             "device",
             "display",
             "education",
             "for",
             "hardware",
             "iOS",
             "learning",
             "lessons teaching",
             "mac",
             "monitor",
             "screen",
             "screencast",
             "streaming",
             "television",
             "tv",
             "web",
             "window",
             "wireless"
          ],

       },
       {
          "name":"catching_pokemon",

          "tags":[

             "pokestop",
             "travel"
          ],

       },
       {
          "name":"category",

          "tags":[

             "collection",
             "items",
             "product",
             "sort",
             "square",
             "triangle"
          ],

       },
       {
          "name":"celebration",

          "tags":[
             "activity",
             "birthday",
             "celebration",
             "event",
             "fun",
             "party"
          ],

       },
       {
          "name":"cell_wifi",

          "tags":[
             "cell",
             "connection",
             "data",
             "internet",
             "mobile",
             "network",
             "phone",
             "service",
             "signal",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"center_focus_strong",

          "tags":[
             "camera",
             "center",
             "focus",
             "image",
             "lens",
             "photo",
             "photography",
             "strong",
             "zoom"
          ],

       },
       {
          "name":"center_focus_weak",

          "tags":[
             "camera",
             "center",
             "focus",
             "image",
             "lens",
             "photo",
             "photography",
             "weak",
             "zoom"
          ],

       },
       {
          "name":"chair",

          "tags":[
             "chair",
             "comfort",
             "couch",
             "decoration",
             "furniture",
             "home",
             "house",
             "living",
             "lounging",
             "loveseat",
             "room",
             "seat",
             "seating",
             "sofa"
          ],

       },
       {
          "name":"chair_alt",

          "tags":[
             "cahir",
             "furniture",
             "home",
             "house",
             "kitchen",
             "lounging",
             "seating",
             "table"
          ],

       },
       {
          "name":"chalet",

          "tags":[
             "architecture",
             "chalet",
             "cottage",
             "estate",
             "home",
             "house",
             "maps",
             "place",
             "real",
             "residence",
             "residential",
             "stay",
             "traveling"
          ],

       },
       {
          "name":"change_circle",

          "tags":[
             "around",
             "arrows",
             "change",
             "circle",
             "direction",
             "navigation",
             "rotate"
          ],

       },
       {
          "name":"change_history",

          "tags":[
             "change",
             "history",
             "shape",
             "triangle"
          ],

       },
       {
          "name":"charging_station",

          "tags":[
             "Android",
             "OS",
             "battery",
             "bolt",
             "cell",
             "charging",
             "device",
             "electric",
             "hardware",
             "iOS",
             "lightning",
             "mobile",
             "phone",
             "station",
             "tablet",
             "thunderbolt"
          ],

       },
       {
          "name":"chat",

          "tags":[
             "bubble",
             "chat",
             "comment",
             "communicate",
             "feedback",
             "message",
             "speech"
          ],

       },
       {
          "name":"chat_bubble",

          "tags":[
             "bubble",
             "chat",
             "comment",
             "communicate",
             "feedback",
             "message",
             "speech"
          ],

       },
       {
          "name":"chat_bubble_outline",

          "tags":[
             "bubble",
             "chat",
             "comment",
             "communicate",
             "feedback",
             "message",
             "outline",
             "speech"
          ],

       },
       {
          "name":"check",

          "tags":[
             "check",
             "complete",
             "confirm",
             "correct",
             "done",
             "enter",
             "mark",
             "ok",
             "okay",
             "purchased",
             "select",
             "success",
             "tick",
             "yes"
          ],

       },
       {
          "name":"check_box",

          "tags":[
             "approved",
             "box",
             "button",
             "check",
             "component",
             "control",
             "form",
             "mark",
             "ok",
             "select",
             "selected",
             "selection",
             "tick",
             "toggle",
             "ui",
             "yes"
          ],

       },
       {
          "name":"check_box_outline_blank",

          "tags":[
             "blank",
             "box",
             "button",
             "check",
             "component",
             "control",
             "deselected",
             "empty",
             "form",
             "outline",
             "select",
             "selection",
             "square",
             "tick",
             "toggle",
             "ui"
          ],

       },
       {
          "name":"check_circle",

          "tags":[
             "approve",
             "check",
             "circle",
             "complete",
             "done",
             "download",
             "mark",
             "ok",
             "select",
             "success",
             "tick",
             "upload",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"check_circle_outline",

          "tags":[
             "approve",
             "check",
             "circle",
             "complete",
             "done",
             "finished",
             "mark",
             "ok",
             "outline",
             "select",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"checklist",

          "tags":[
             
          ],

       },
       {
          "name":"checklist_rtl",

          "tags":[
             
          ],

       },
       {
          "name":"checkroom",

          "tags":[
             "checkroom",
             "closet",
             "clothes",
             "coat check",
             "hanger"
          ],

       },
       {
          "name":"chevron_left",

          "tags":[
             "arrow",
             "arrows",
             "back",
             "chevron",
             "direction",
             "left",
             "triangle"
          ],

       },
       {
          "name":"chevron_right",

          "tags":[
             "arrow",
             "arrows",
             "chevron",
             "direction",
             "forward",
             "right",
             "triangle"
          ],

       },
       {
          "name":"child_care",

          "tags":[
             "babies",
             "baby",
             "care",
             "child",
             "children",
             "face",
             "infant",
             "kids",
             "newborn",
             "toddler",
             "young"
          ],

       },
       {
          "name":"child_friendly",

          "tags":[
             "baby",
             "care",
             "carriage",
             "child",
             "children",
             "friendly",
             "infant",
             "kid",
             "newborn",
             "stroller",
             "toddler",
             "young"
          ],

       },
       {
          "name":"chrome_reader_mode",

          "tags":[
             "chrome",
             "mode",
             "read",
             "reader",
             "text"
          ],

       },
       {
          "name":"circle",

          "tags":[
             "button",
             "circle",
             "full",
             "geometry",
             "moon",
             "radio"
          ],

       },
       {
          "name":"circle_notifications",

          "tags":[
             "active",
             "alarm",
             "alert",
             "bell",
             "chime",
             "circle",
             "notifications",
             "notify",
             "reminder",
             "ring",
             "sound"
          ],

       },
       {
          "name":"class",

          "tags":[
             "archive",
             "book",
             "bookmark",
             "class",
             "favorite",
             "label",
             "library",
             "read",
             "reading",
             "remember",
             "ribbon",
             "save",
             "tag"
          ],

       },
       {
          "name":"clean_hands",

          "tags":[
             "bacteria",
             "clean",
             "disinfect",
             "germs",
             "gesture",
             "hand",
             "hands",
             "sanitize",
             "sanitizer"
          ],

       },
       {
          "name":"cleaning_services",

          "tags":[
             "clean",
             "cleaning",
             "dust",
             "services",
             "sweep"
          ],

       },
       {
          "name":"clear",

          "tags":[
             "back",
             "cancel",
             "clear",
             "correct",
             "delete",
             "erase",
             "exit",
             "x"
          ],

       },
       {
          "name":"clear_all",

          "tags":[
             "all",
             "clear",
             "doc",
             "document",
             "format",
             "lines",
             "list"
          ],

       },
       {
          "name":"close",

          "tags":[
             "cancel",
             "close",
             "exit",
             "stop",
             "x"
          ],

       },
       {
          "name":"close_fullscreen",

          "tags":[
             "action",
             "arrow",
             "arrows",
             "close",
             "collapse",
             "direction",
             "full",
             "fullscreen",
             "minimize",
             "screen"
          ],

       },
       {
          "name":"closed_caption",

          "tags":[
             "accessible",
             "alphabet",
             "caption",
             "cc",
             "character",
             "closed",
             "decoder",
             "font",
             "language",
             "letter",
             "media",
             "movies",
             "subtitle",
             "subtitles",
             "symbol",
             "text",
             "tv",
             "type"
          ],

       },
       {
          "name":"closed_caption_disabled",

          "tags":[
             "accessible",
             "alphabet",
             "caption",
             "cc",
             "character",
             "closed",
             "decoder",
             "disabled",
             "enabled",
             "font",
             "language",
             "letter",
             "media",
             "movies",
             "off",
             "on",
             "slash",
             "subtitle",
             "subtitles",
             "symbol",
             "text",
             "tv",
             "type"
          ],

       },
       {
          "name":"closed_caption_off",

          "tags":[
             "accessible",
             "alphabet",
             "caption",
             "cc",
             "character",
             "closed",
             "decoder",
             "font",
             "language",
             "letter",
             "media",
             "movies",
             "off",
             "outline",
             "subtitle",
             "subtitles",
             "symbol",
             "text",
             "tv",
             "type"
          ],

       },
       {
          "name":"cloud",

          "tags":[
             "cloud",
             "connection",
             "internet",
             "network",
             "sky",
             "upload"
          ],

       },
       {
          "name":"cloud_circle",

          "tags":[
             "app",
             "application",
             "backup",
             "circle",
             "cloud",
             "connection",
             "drive",
             "files",
             "folders",
             "internet",
             "network",
             "sky",
             "storage",
             "upload"
          ],

       },
       {
          "name":"cloud_done",

          "tags":[
             "app",
             "application",
             "approve",
             "backup",
             "check",
             "cloud",
             "complete",
             "connection",
             "done",
             "drive",
             "files",
             "folders",
             "internet",
             "mark",
             "network",
             "ok",
             "select",
             "sky",
             "storage",
             "tick",
             "upload",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"cloud_download",

          "tags":[
             "app",
             "application",
             "arrow",
             "backup",
             "cloud",
             "connection",
             "down",
             "download",
             "drive",
             "files",
             "folders",
             "internet",
             "network",
             "sky",
             "storage",
             "upload"
          ],

       },
       {
          "name":"cloud_off",

          "tags":[
             "app",
             "application",
             "backup",
             "cloud",
             "connection",
             "disabled",
             "drive",
             "enabled",
             "files",
             "folders",
             "internet",
             "network",
             "off",
             "offline",
             "on",
             "sky",
             "slash",
             "storage",
             "upload"
          ],

       },
       {
          "name":"cloud_queue",

          "tags":[
             "cloud",
             "connection",
             "internet",
             "network",
             "queue",
             "sky",
             "upload"
          ],

       },
       {
          "name":"cloud_upload",

          "tags":[
             "app",
             "application",
             "arrow",
             "backup",
             "cloud",
             "connection",
             "download",
             "drive",
             "files",
             "folders",
             "internet",
             "network",
             "sky",
             "storage",
             "up",
             "upload"
          ],

       },
       {
          "name":"co2",

          "tags":[
             
          ],

       },
       {
          "name":"code",

          "tags":[
             "brackets",
             "code",
             "css",
             "develop",
             "developer",
             "engineer",
             "engineering",
             "html",
             "platform"
          ],

       },
       {
          "name":"code_off",

          "tags":[
             "brackets",
             "code",
             "css",
             "develop",
             "developer",
             "disabled",
             "enabled",
             "engineer",
             "engineering",
             "html",
             "off",
             "on",
             "platform",
             "slash"
          ],

       },
       {
          "name":"coffee",

          "tags":[
             "beverage",
             "coffee",
             "cup",
             "drink",
             "mug",
             "plate",
             "set",
             "tea"
          ],

       },
       {
          "name":"coffee_maker",

          "tags":[
             "appliances",
             "beverage",
             "coffee",
             "cup",
             "drink",
             "machine",
             "maker",
             "mug"
          ],

       },
       {
          "name":"collections",

          "tags":[
             "album",
             "collections",
             "gallery",
             "image",
             "landscape",
             "library",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture",
             "stack"
          ],

       },
       {
          "name":"collections_bookmark",

          "tags":[
             "album",
             "archive",
             "bookmark",
             "collections",
             "favorite",
             "gallery",
             "label",
             "library",
             "read",
             "reading",
             "remember",
             "ribbon",
             "save",
             "stack",
             "tag"
          ],

       },
       {
          "name":"color_lens",

          "tags":[
             "art",
             "color",
             "lens",
             "paint",
             "pallet"
          ],

       },
       {
          "name":"colorize",

          "tags":[
             "color",
             "colorize",
             "dropper",
             "extract",
             "eye",
             "picker",
             "tool"
          ],

       },
       {
          "name":"comment",

          "tags":[
             "bubble",
             "chat",
             "comment",
             "communicate",
             "feedback",
             "message",
             "outline",
             "speech"
          ],

       },
       {
          "name":"comment_bank",

          "tags":[
             "archive",
             "bank",
             "bookmark",
             "bubble",
             "cchat",
             "comment",
             "communicate",
             "favorite",
             "label",
             "library",
             "message",
             "remember",
             "ribbon",
             "save",
             "speech",
             "tag"
          ],

       },
       {
          "name":"comments_disabled",

          "tags":[
             
          ],

       },
       {
          "name":"commute",

          "tags":[
             "automobile",
             "car",
             "commute",
             "direction",
             "maps",
             "public",
             "train",
             "transportation",
             "trip",
             "vehicle"
          ],

       },
       {
          "name":"compare",

          "tags":[
             "adjust",
             "adjustment",
             "compare",
             "edit",
             "editing",
             "edits",
             "enhance",
             "fix",
             "image",
             "images",
             "photo",
             "photography",
             "photos",
             "scan",
             "settings"
          ],

       },
       {
          "name":"compare_arrows",

          "tags":[
             "arrow",
             "arrows",
             "collide",
             "compare",
             "direction",
             "left",
             "pressure",
             "push",
             "right",
             "together"
          ],

       },
       {
          "name":"compass_calibration",

          "tags":[
             "calibration",
             "compass",
             "connection",
             "internet",
             "location",
             "maps",
             "network",
             "refresh",
             "service",
             "signal",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"compost",

          "tags":[
             
          ],

       },
       {
          "name":"compress",

          "tags":[
             "arrow",
             "arrows",
             "collide",
             "compress",
             "pressure",
             "push",
             "together"
          ],

       },
       {
          "name":"computer",

          "tags":[
             "Android",
             "OS",
             "chrome",
             "computer",
             "desktop",
             "device",
             "hardware",
             "iOS",
             "mac",
             "monitor",
             "web",
             "window"
          ],

       },
       {
          "name":"confirmation_number",

          "tags":[
             "admission",
             "confirmation",
             "entertainment",
             "event",
             "number",
             "ticket"
          ],

       },
       {
          "name":"connect_without_contact",

          "tags":[
             "communicating",
             "connect",
             "contact",
             "distance",
             "people",
             "signal",
             "social",
             "socialize",
             "without"
          ],

       },
       {
          "name":"connected_tv",

          "tags":[
             "Android",
             "OS",
             "airplay",
             "chrome",
             "connect",
             "connected",
             "desktop",
             "device",
             "display",
             "hardware",
             "iOS",
             "mac",
             "monitor",
             "screen",
             "screencast",
             "streaming",
             "television",
             "tv",
             "web",
             "window",
             "wireless"
          ],

       },
       {
          "name":"connecting_airports",

          "tags":[
             
          ],

       },
       {
          "name":"construction",

          "tags":[
             "build",
             "carpenter",
             "construction",
             "equipment",
             "fix",
             "hammer",
             "improvement",
             "industrial",
             "industry",
             "repair",
             "tools",
             "wrench"
          ],

       },
       {
          "name":"contact_mail",

          "tags":[
             "account",
             "address",
             "avatar",
             "communicate",
             "contact",
             "email",
             "face",
             "human",
             "info",
             "information",
             "mail",
             "message",
             "people",
             "person",
             "profile",
             "user"
          ],

       },
       {
          "name":"contact_page",

          "tags":[
             "account",
             "avatar",
             "contact",
             "data",
             "doc",
             "document",
             "drive",
             "face",
             "file",
             "folder",
             "folders",
             "human",
             "page",
             "people",
             "person",
             "profile",
             "sheet",
             "slide",
             "storage",
             "user",
             "writing"
          ],

       },
       {
          "name":"contact_phone",

          "tags":[
             "account",
             "avatar",
             "call",
             "communicate",
             "contact",
             "face",
             "human",
             "info",
             "information",
             "message",
             "mobile",
             "people",
             "person",
             "phone",
             "profile",
             "user"
          ],

       },
       {
          "name":"contact_support",

          "tags":[
             "?",
             "bubble",
             "chat",
             "comment",
             "communicate",
             "contact",
             "help",
             "info",
             "information",
             "mark",
             "message",
             "punctuation",
             "question",
             "speech",
             "support",
             "symbol",
             "vquestion mark"
          ],

       },
       {
          "name":"contactless",

          "tags":[
             "bluetooth",
             "cash",
             "connect",
             "connection",
             "connectivity",
             "contact",
             "contactless",
             "credit",
             "device",
             "finance",
             "pay",
             "payment",
             "signal",
             "transaction",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"contacts",

          "tags":[
             "account",
             "avatar",
             "call",
             "cell",
             "contacts",
             "face",
             "human",
             "info",
             "information",
             "mobile",
             "people",
             "person",
             "phone",
             "profile",
             "user"
          ],

       },
       {
          "name":"content_copy",

          "tags":[
             "content",
             "copy",
             "cut",
             "doc",
             "document",
             "duplicate",
             "file",
             "multiple",
             "past"
          ],

       },
       {
          "name":"content_cut",

          "tags":[
             "content",
             "copy",
             "cut",
             "doc",
             "document",
             "file",
             "past",
             "scissors",
             "trim"
          ],

       },
       {
          "name":"content_paste",

          "tags":[
             "clipboard",
             "content",
             "copy",
             "cut",
             "doc",
             "document",
             "file",
             "multiple",
             "past"
          ],

       },
       {
          "name":"content_paste_off",

          "tags":[
             "clipboard",
             "content",
             "disabled",
             "doc",
             "document",
             "enabled",
             "file",
             "off",
             "on",
             "paste",
             "slash"
          ],

       },
       {
          "name":"control_camera",

          "tags":[
             "adjust",
             "arrow",
             "arrows",
             "camera",
             "center",
             "control",
             "direction",
             "left",
             "move",
             "right"
          ],

       },
       {
          "name":"control_point",

          "tags":[
             "+",
             "add",
             "circle",
             "control",
             "plus",
             "point"
          ],

       },
       {
          "name":"control_point_duplicate",

          "tags":[
             "+",
             "add",
             "circle",
             "control",
             "duplicate",
             "multiple",
             "new",
             "plus",
             "point",
             "symbol"
          ],

       },
       {
          "name":"copy_all",

          "tags":[
             "all",
             "content",
             "copy",
             "cut",
             "doc",
             "document",
             "file",
             "multiple",
             "page",
             "paper",
             "past"
          ],

       },
       {
          "name":"copyright",

          "tags":[
             "alphabet",
             "c",
             "character",
             "circle",
             "copyright",
             "emblem",
             "font",
             "legal",
             "letter",
             "owner",
             "symbol",
             "text"
          ],

       },
       {
          "name":"coronavirus",

          "tags":[
             "19",
             "bacteria",
             "coronavirus",
             "covid",
             "disease",
             "germs",
             "illness",
             "sick",
             "social"
          ],

       },
       {
          "name":"corporate_fare",

          "tags":[
             "architecture",
             "building",
             "business",
             "corporate",
             "estate",
             "fare",
             "organization",
             "place",
             "real",
             "residence",
             "residential",
             "shelter"
          ],

       },
       {
          "name":"cottage",

          "tags":[
             "architecture",
             "beach",
             "cottage",
             "estate",
             "home",
             "house",
             "lake",
             "lodge",
             "maps",
             "place",
             "real",
             "residence",
             "residential",
             "stay",
             "traveling"
          ],

       },
       {
          "name":"countertops",

          "tags":[
             "counter",
             "countertops",
             "home",
             "house",
             "kitchen",
             "sink",
             "table",
             "tops"
          ],

       },
       {
          "name":"create",

          "tags":[
             "compose",
             "create",
             "edit",
             "editing",
             "input",
             "new",
             "pen",
             "pencil",
             "write",
             "writing"
          ],

       },
       {
          "name":"create_new_folder",

          "tags":[
             "+",
             "add",
             "create",
             "data",
             "doc",
             "document",
             "drive",
             "file",
             "folder",
             "new",
             "plus",
             "sheet",
             "slide",
             "storage",
             "symbol"
          ],

       },
       {
          "name":"credit_card",

          "tags":[
             "bill",
             "card",
             "cash",
             "coin",
             "commerce",
             "cost",
             "credit",
             "currency",
             "dollars",
             "finance",
             "money",
             "online",
             "pay",
             "payment",
             "price",
             "shopping",
             "symbol"
          ],

       },
       {
          "name":"credit_card_off",

          "tags":[
             "card",
             "charge",
             "commerce",
             "cost",
             "credit",
             "disabled",
             "enabled",
             "finance",
             "money",
             "off",
             "online",
             "pay",
             "payment",
             "slash"
          ],

       },
       {
          "name":"credit_score",

          "tags":[
             "approve",
             "bill",
             "card",
             "cash",
             "check",
             "coin",
             "commerce",
             "complete",
             "cost",
             "credit",
             "currency",
             "dollars",
             "done",
             "finance",
             "loan",
             "mark",
             "money",
             "ok",
             "online",
             "pay",
             "payment",
             "score",
             "select",
             "symbol",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"crib",

          "tags":[
             "babies",
             "baby",
             "bassinet",
             "bed",
             "child",
             "children",
             "cradle",
             "crib",
             "infant",
             "kid",
             "newborn",
             "sleeping",
             "toddler"
          ],

       },
       {
          "name":"crop",

          "tags":[
             "adjust",
             "adjustments",
             "area",
             "crop",
             "edit",
             "editing",
             "frame",
             "image",
             "images",
             "photo",
             "photos",
             "rectangle",
             "settings",
             "size",
             "square"
          ],

       },
       {
          "name":"crop_16_9",

          "tags":[
             "16",
             "9",
             "adjust",
             "adjustments",
             "area",
             "by",
             "crop",
             "edit",
             "editing",
             "frame",
             "image",
             "images",
             "photo",
             "photos",
             "rectangle",
             "settings",
             "size",
             "square"
          ],

       },
       {
          "name":"crop_3_2",

          "tags":[
             "2",
             "3",
             "adjust",
             "adjustments",
             "area",
             "by",
             "crop",
             "edit",
             "editing",
             "frame",
             "image",
             "images",
             "photo",
             "photos",
             "rectangle",
             "settings",
             "size",
             "square"
          ],

       },
       {
          "name":"crop_5_4",

          "tags":[
             "4",
             "5",
             "adjust",
             "adjustments",
             "area",
             "by",
             "crop",
             "edit",
             "editing settings",
             "frame",
             "image",
             "images",
             "photo",
             "photos",
             "rectangle",
             "size",
             "square"
          ],

       },
       {
          "name":"crop_7_5",

          "tags":[
             "5",
             "7",
             "adjust",
             "adjustments",
             "area",
             "by",
             "crop",
             "editing",
             "frame",
             "image",
             "images",
             "photo",
             "photos",
             "rectangle",
             "settings",
             "size",
             "square"
          ],

       },
       {
          "name":"crop_din",

          "tags":[
             "adjust",
             "adjustments",
             "area",
             "crop",
             "din",
             "edit",
             "editing",
             "frame",
             "image",
             "images",
             "photo",
             "photos",
             "rectangle",
             "settings",
             "size",
             "square"
          ],

       },
       {
          "name":"crop_free",

          "tags":[
             "adjust",
             "adjustments",
             "crop",
             "edit",
             "editing",
             "focus",
             "frame",
             "free",
             "image",
             "photo",
             "photos",
             "settings",
             "size",
             "zoom"
          ],

       },
       {
          "name":"crop_landscape",

          "tags":[
             "adjust",
             "adjustments",
             "area",
             "crop",
             "edit",
             "editing",
             "frame",
             "image",
             "images",
             "landscape",
             "photo",
             "photos",
             "settings",
             "size"
          ],

       },
       {
          "name":"crop_original",

          "tags":[
             "adjust",
             "adjustments",
             "area",
             "crop",
             "edit",
             "editing",
             "frame",
             "image",
             "images",
             "original",
             "photo",
             "photos",
             "picture",
             "settings",
             "size"
          ],

       },
       {
          "name":"crop_portrait",

          "tags":[
             "adjust",
             "adjustments",
             "area",
             "crop",
             "edit",
             "editing",
             "frame",
             "image",
             "images",
             "photo",
             "photos",
             "portrait",
             "rectangle",
             "settings",
             "size",
             "square"
          ],

       },
       {
          "name":"crop_rotate",

          "tags":[
             "adjust",
             "adjustments",
             "area",
             "arrow",
             "arrows",
             "crop",
             "edit",
             "editing",
             "frame",
             "image",
             "images",
             "photo",
             "photos",
             "rotate",
             "settings",
             "size",
             "turn"
          ],

       },
       {
          "name":"crop_square",

          "tags":[
             "adjust",
             "adjustments",
             "area",
             "crop",
             "edit",
             "editing",
             "frame",
             "image",
             "images",
             "photo",
             "photos",
             "rectangle",
             "settings",
             "size",
             "square"
          ],

       },
       {
          "name":"cruelty_free",

          "tags":[
             
          ],

       },
       {
          "name":"dangerous",

          "tags":[
             "broken",
             "danger",
             "dangerous",
             "fix",
             "no",
             "sign",
             "stop",
             "update",
             "warning",
             "wrong",
             "x"
          ],

       },
       {
          "name":"dark_mode",

          "tags":[
             "app",
             "application",
             "dark",
             "device",
             "interface",
             "mode",
             "moon",
             "night",
             "silent",
             "theme",
             "ui",
             "ux",
             "website"
          ],

       },
       {
          "name":"dashboard",

          "tags":[
             "cards",
             "dashboard",
             "format",
             "layout",
             "rectangle",
             "shapes",
             "square",
             "web",
             "website"
          ],

       },
       {
          "name":"dashboard_customize",

          "tags":[
             "cards",
             "customize",
             "dashboard",
             "format",
             "layout",
             "rectangle",
             "shapes",
             "square",
             "web",
             "website"
          ],

       },
       {
          "name":"data_exploration",

          "tags":[
             
          ],

       },
       {
          "name":"data_saver_off",

          "tags":[
             "analytics",
             "bar",
             "bars",
             "chart",
             "data",
             "diagram",
             "donut",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "off",
             "on",
             "ring",
             "saver",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"data_saver_on",

          "tags":[
             "+",
             "add",
             "analytics",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "new",
             "on",
             "plus",
             "ring",
             "saver",
             "statistics",
             "symbol",
             "tracking"
          ],

       },
       {
          "name":"data_usage",

          "tags":[
             "analytics",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "statistics",
             "tracking",
             "usage"
          ],

       },
       {
          "name":"date_range",

          "tags":[
             "calendar",
             "date",
             "day",
             "event",
             "month",
             "range",
             "remember",
             "reminder",
             "schedule",
             "time",
             "today",
             "week"
          ],

       },
       {
          "name":"deck",

          "tags":[
             "chairs",
             "deck",
             "home",
             "house",
             "outdoors",
             "outside",
             "patio",
             "social",
             "terrace",
             "umbrella",
             "yard"
          ],

       },
       {
          "name":"dehaze",

          "tags":[
             "adjust",
             "dehaze",
             "edit",
             "editing",
             "enhance",
             "haze",
             "image",
             "lines",
             "photo",
             "photography",
             "remove"
          ],

       },
       {
          "name":"delete",

          "tags":[
             "bin",
             "can",
             "delete",
             "garbage",
             "remove",
             "trash"
          ],

       },
       {
          "name":"delete_forever",

          "tags":[
             "bin",
             "can",
             "cancel",
             "delete",
             "exit",
             "forever",
             "garbage",
             "remove",
             "trash",
             "x"
          ],

       },
       {
          "name":"delete_outline",

          "tags":[
             "bin",
             "can",
             "delete",
             "garbage",
             "outline",
             "remove",
             "trash"
          ],

       },
       {
          "name":"delete_sweep",

          "tags":[
             "bin",
             "can",
             "delete",
             "garbage",
             "remove",
             "sweep",
             "trash"
          ],

       },
       {
          "name":"delivery_dining",

          "tags":[
             "delivery",
             "dining",
             "food",
             "meal",
             "restaurant",
             "scooter",
             "takeout",
             "transportation",
             "vehicle",
             "vespa"
          ],

       },
       {
          "name":"departure_board",

          "tags":[
             "automobile",
             "board",
             "bus",
             "car",
             "cars",
             "clock",
             "departure",
             "maps",
             "public",
             "schedule",
             "time",
             "transportation",
             "travel",
             "vehicle"
          ],

       },
       {
          "name":"description",

          "tags":[
             "article",
             "data",
             "description",
             "doc",
             "document",
             "drive",
             "file",
             "folder",
             "folders",
             "notes",
             "page",
             "paper",
             "sheet",
             "slide",
             "text",
             "writing"
          ],

       },
       {
          "name":"design_services",

          "tags":[
             
          ],

       },
       {
          "name":"desktop_access_disabled",

          "tags":[
             "Android",
             "OS",
             "access",
             "chrome",
             "desktop",
             "device",
             "disabled",
             "display",
             "enabled",
             "hardware",
             "iOS",
             "mac",
             "monitor",
             "off",
             "offline",
             "on",
             "screen",
             "slash",
             "web",
             "window"
          ],

       },
       {
          "name":"desktop_mac",

          "tags":[
             "Android",
             "OS",
             "chrome",
             "desktop",
             "device",
             "display",
             "hardware",
             "iOS",
             "mac",
             "monitor",
             "screen",
             "web",
             "window"
          ],

       },
       {
          "name":"desktop_windows",

          "tags":[
             "Android",
             "OS",
             "chrome",
             "desktop",
             "device",
             "display",
             "hardware",
             "iOS",
             "mac",
             "monitor",
             "screen",
             "television",
             "tv",
             "web",
             "window",
             "windows"
          ],

       },
       {
          "name":"details",

          "tags":[
             "details",
             "edit",
             "editing",
             "enhance",
             "image",
             "photo",
             "photography",
             "sharpen",
             "triangle"
          ],

       },
       {
          "name":"developer_board",

          "tags":[
             "board",
             "chip",
             "computer",
             "developer",
             "development",
             "hardware",
             "microchip",
             "processor"
          ],

       },
       {
          "name":"developer_board_off",

          "tags":[
             "board",
             "chip",
             "computer",
             "developer",
             "development",
             "disabled",
             "enabled",
             "hardware",
             "microchip",
             "off",
             "on",
             "processor",
             "slash"
          ],

       },
       {
          "name":"developer_mode",

          "tags":[
             "Android",
             "OS",
             "bracket",
             "cell",
             "code",
             "developer",
             "development",
             "device",
             "engineer",
             "hardware",
             "iOS",
             "mobile",
             "mode",
             "phone",
             "tablet"
          ],

       },
       {
          "name":"device_hub",

          "tags":[
             "Android",
             "OS",
             "circle",
             "computer",
             "desktop",
             "device",
             "hardware",
             "hub",
             "iOS",
             "laptop",
             "mobile",
             "monitor",
             "phone",
             "square",
             "tablet",
             "triangle",
             "watch",
             "wearable",
             "web"
          ],

       },
       {
          "name":"device_thermostat",

          "tags":[
             "celsius",
             "device",
             "fahrenheit",
             "meter",
             "temp",
             "temperature",
             "thermometer",
             "thermostat"
          ],

       },
       {
          "name":"device_unknown",

          "tags":[
             "?",
             "Android",
             "OS",
             "assistance",
             "cell",
             "device",
             "hardware",
             "help",
             "iOS",
             "info",
             "information",
             "mobile",
             "phone",
             "punctuation",
             "question mark",
             "support",
             "symbol",
             "tablet",
             "unknown"
          ],

       },
       {
          "name":"devices",

          "tags":[
             "Android",
             "OS",
             "computer",
             "desktop",
             "device",
             "hardware",
             "iOS",
             "laptop",
             "mobile",
             "monitor",
             "phone",
             "tablet",
             "watch",
             "wearable",
             "web"
          ],

       },
       {
          "name":"devices_other",

          "tags":[
             "Android",
             "OS",
             "ar",
             "cell",
             "chrome",
             "desktop",
             "device",
             "gadget",
             "hardware",
             "iOS",
             "ipad",
             "mac",
             "mobile",
             "monitor",
             "other",
             "phone",
             "tablet",
             "vr",
             "watch",
             "wearables",
             "window"
          ],

       },
       {
          "name":"dialer_sip",

          "tags":[
             "alphabet",
             "call",
             "cell",
             "character",
             "contact",
             "device",
             "dialer",
             "font",
             "hardware",
             "initiation",
             "internet",
             "letter",
             "mobile",
             "over",
             "phone",
             "protocol",
             "routing",
             "session",
             "sip",
             "symbol",
             "telephone",
             "text",
             "type",
             "voice"
          ],

       },
       {
          "name":"dialpad",

          "tags":[
             "buttons",
             "call",
             "contact",
             "device",
             "dial",
             "dialpad",
             "dots",
             "mobile",
             "numbers",
             "pad",
             "phone"
          ],

       },
       {
          "name":"dining",

          "tags":[
             "cafe",
             "cafeteria",
             "cutlery",
             "diner",
             "dining",
             "eat",
             "eating",
             "fork",
             "room",
             "spoon"
          ],

       },
       {
          "name":"dinner_dining",

          "tags":[
             "breakfast",
             "dining",
             "dinner",
             "food",
             "fork",
             "lunch",
             "meal",
             "restaurant",
             "spaghetti",
             "utensils"
          ],

       },
       {
          "name":"directions",

          "tags":[
             "arrow",
             "directions",
             "maps",
             "right",
             "route",
             "sign",
             "traffic"
          ],

       },
       {
          "name":"directions_bike",

          "tags":[
             "bicycle",
             "bike",
             "direction",
             "directions",
             "human",
             "maps",
             "person",
             "public",
             "route",
             "transportation"
          ],

       },
       {
          "name":"directions_boat",

          "tags":[
             "automobile",
             "boat",
             "car",
             "cars",
             "direction",
             "directions",
             "ferry",
             "maps",
             "public",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"directions_boat_filled",

          "tags":[
             "automobile",
             "boat",
             "car",
             "cars",
             "direction",
             "directions",
             "ferry",
             "filled",
             "maps",
             "public",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"directions_bus",

          "tags":[
             "automobile",
             "bus",
             "car",
             "cars",
             "directions",
             "maps",
             "public",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"directions_bus_filled",

          "tags":[
             "automobile",
             "bus",
             "car",
             "cars",
             "direction",
             "directions",
             "filled",
             "maps",
             "public",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"directions_car",

          "tags":[
             "automobile",
             "car",
             "cars",
             "direction",
             "directions",
             "maps",
             "public",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"directions_car_filled",

          "tags":[
             "automobile",
             "car",
             "cars",
             "direction",
             "directions",
             "filled",
             "maps",
             "public",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"directions_off",

          "tags":[
             "arrow",
             "directions",
             "disabled",
             "enabled",
             "maps",
             "off",
             "on",
             "right",
             "route",
             "sign",
             "slash",
             "traffic"
          ],

       },
       {
          "name":"directions_railway",

          "tags":[
             "automobile",
             "car",
             "cars",
             "direction",
             "directions",
             "maps",
             "public",
             "railway",
             "train",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"directions_railway_filled",

          "tags":[
             "automobile",
             "car",
             "cars",
             "direction",
             "directions",
             "filled",
             "maps",
             "public",
             "railway",
             "train",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"directions_run",

          "tags":[
             "body",
             "directions",
             "human",
             "jogging",
             "maps",
             "people",
             "person",
             "route",
             "run",
             "running",
             "walk"
          ],

       },
       {
          "name":"directions_subway",

          "tags":[
             "automobile",
             "car",
             "cars",
             "direction",
             "directions",
             "maps",
             "public",
             "rail",
             "subway",
             "train",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"directions_subway_filled",

          "tags":[
             "automobile",
             "car",
             "cars",
             "direction",
             "directions",
             "filled",
             "maps",
             "public",
             "rail",
             "subway",
             "train",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"directions_transit",

          "tags":[
             "automobile",
             "car",
             "cars",
             "direction",
             "directions",
             "maps",
             "public",
             "rail",
             "subway",
             "train",
             "transit",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"directions_transit_filled",

          "tags":[
             "automobile",
             "car",
             "cars",
             "direction",
             "directions",
             "filled",
             "maps",
             "public",
             "rail",
             "subway",
             "train",
             "transit",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"directions_walk",

          "tags":[
             "body",
             "direction",
             "directions",
             "human",
             "jogging",
             "maps",
             "people",
             "person",
             "route",
             "run",
             "walk"
          ],

       },
       {
          "name":"dirty_lens",

          "tags":[
             "camera",
             "dirty",
             "lens",
             "photo",
             "photography",
             "picture",
             "splat"
          ],

       },
       {
          "name":"disabled_by_default",

          "tags":[
             "box",
             "by",
             "cancel",
             "close",
             "default",
             "disabled",
             "exit",
             "no",
             "quit",
             "remove",
             "square",
             "stop",
             "x"
          ],

       },
       {
          "name":"disabled_visible",

          "tags":[
             
          ],

       },
       {
          "name":"disc_full",

          "tags":[
             "!",
             "alert",
             "attention",
             "caution",
             "cd",
             "danger",
             "disc",
             "error",
             "exclamation",
             "full",
             "important",
             "mark",
             "music",
             "notification",
             "storage",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"dns",

          "tags":[
             "address",
             "bars",
             "dns",
             "domain",
             "information",
             "ip",
             "list",
             "lookup",
             "name",
             "server",
             "system"
          ],

       },
       {
          "name":"do_disturb",

          "tags":[
             "cancel",
             "close",
             "denied",
             "deny",
             "disturb",
             "do",
             "remove",
             "silence",
             "stop"
          ],

       },
       {
          "name":"do_disturb_alt",

          "tags":[
             "cancel",
             "close",
             "denied",
             "deny",
             "disturb",
             "do",
             "remove",
             "silence",
             "stop"
          ],

       },
       {
          "name":"do_disturb_off",

          "tags":[
             "cancel",
             "close",
             "denied",
             "deny",
             "disabled",
             "disturb",
             "do",
             "enabled",
             "off",
             "on",
             "remove",
             "silence",
             "slash",
             "stop"
          ],

       },
       {
          "name":"do_disturb_on",

          "tags":[
             "cancel",
             "close",
             "denied",
             "deny",
             "disabled",
             "disturb",
             "do",
             "enabled",
             "off",
             "on",
             "remove",
             "silence",
             "slash",
             "stop"
          ],

       },
       {
          "name":"do_not_disturb",

          "tags":[
             "cancel",
             "close",
             "denied",
             "deny",
             "disturb",
             "do",
             "remove",
             "silence",
             "stop"
          ],

       },
       {
          "name":"do_not_disturb_alt",

          "tags":[
             "cancel",
             "close",
             "denied",
             "deny",
             "disturb",
             "do",
             "remove",
             "silence",
             "stop"
          ],

       },
       {
          "name":"do_not_disturb_off",

          "tags":[
             "cancel",
             "close",
             "denied",
             "deny",
             "disabled",
             "disturb",
             "do",
             "enabled",
             "off",
             "on",
             "remove",
             "silence",
             "slash",
             "stop"
          ],

       },
       {
          "name":"do_not_disturb_on",

          "tags":[
             "cancel",
             "close",
             "denied",
             "deny",
             "disabled",
             "disturb",
             "do",
             "enabled",
             "off",
             "on",
             "remove",
             "silence",
             "slash",
             "stop"
          ],

       },
       {
          "name":"do_not_disturb_on_total_silence",

          "tags":[
             "busy",
             "disturb",
             "do",
             "mute",
             "no",
             "not",
             "on total",
             "quiet",
             "silence"
          ],

       },
       {
          "name":"do_not_step",

          "tags":[
             "boot",
             "disabled",
             "do",
             "enabled",
             "feet",
             "foot",
             "not",
             "off",
             "on",
             "shoe",
             "slash",
             "sneaker",
             "step"
          ],

       },
       {
          "name":"do_not_touch",

          "tags":[
             "disabled",
             "do",
             "enabled",
             "fingers",
             "gesture",
             "hand",
             "not",
             "off",
             "on",
             "slash",
             "touch"
          ],

       },
       {
          "name":"dock",

          "tags":[
             "Android",
             "OS",
             "cell",
             "charging",
             "connector",
             "device",
             "dock",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "power",
             "station",
             "tablet"
          ],

       },
       {
          "name":"document_scanner",

          "tags":[
             
          ],

       },
       {
          "name":"domain",

          "tags":[
             "apartment",
             "architecture",
             "building",
             "business",
             "domain",
             "estate",
             "home",
             "place",
             "real",
             "residence",
             "residential",
             "shelter",
             "web",
             "www"
          ],

       },
       {
          "name":"domain_disabled",

          "tags":[
             "apartment",
             "architecture",
             "building",
             "business",
             "company",
             "disabled",
             "domain",
             "enabled",
             "estate",
             "home",
             "internet",
             "maps",
             "off",
             "office",
             "offline",
             "on",
             "place",
             "real",
             "residence",
             "residential",
             "slash",
             "web",
             "website"
          ],

       },
       {
          "name":"domain_verification",

          "tags":[
             "app",
             "application desktop",
             "approve",
             "check",
             "complete",
             "design",
             "domain",
             "done",
             "interface",
             "internet",
             "layout",
             "mark",
             "ok",
             "screen",
             "select",
             "site",
             "tick",
             "ui",
             "ux",
             "validate",
             "verification",
             "verified",
             "web",
             "website",
             "window",
             "www",
             "yes"
          ],

       },
       {
          "name":"done",

          "tags":[
             "approve",
             "check",
             "complete",
             "done",
             "mark",
             "ok",
             "select",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"done_all",

          "tags":[
             "all",
             "approve",
             "check",
             "complete",
             "done",
             "layers",
             "mark",
             "multiple",
             "ok",
             "select",
             "stack",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"done_outline",

          "tags":[
             "all",
             "approve",
             "check",
             "complete",
             "done",
             "mark",
             "ok",
             "outline",
             "select",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"donut_large",

          "tags":[
             "analytics",
             "chart",
             "data",
             "diagram",
             "donut",
             "graph",
             "infographic",
             "inprogress",
             "large",
             "measure",
             "metrics",
             "pie",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"donut_small",

          "tags":[
             "analytics",
             "chart",
             "data",
             "diagram",
             "donut",
             "graph",
             "infographic",
             "inprogress",
             "measure",
             "metrics",
             "pie",
             "small",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"door_back",

          "tags":[
             "back",
             "closed",
             "door",
             "doorway",
             "entrance",
             "exit",
             "home",
             "house",
             "way"
          ],

       },
       {
          "name":"door_front",

          "tags":[
             "closed",
             "door",
             "doorway",
             "entrance",
             "exit",
             "front",
             "home",
             "house",
             "way"
          ],

       },
       {
          "name":"door_sliding",

          "tags":[
             "auto",
             "automatic",
             "door",
             "doorway",
             "double",
             "entrance",
             "exit",
             "glass",
             "home",
             "house",
             "sliding",
             "two"
          ],

       },
       {
          "name":"doorbell",

          "tags":[
             "alarm",
             "bell",
             "door",
             "doorbell",
             "home",
             "house",
             "ringing"
          ],

       },
       {
          "name":"double_arrow",

          "tags":[
             "arrow",
             "arrows",
             "direction",
             "double",
             "multiple",
             "navigation",
             "right"
          ],

       },
       {
          "name":"downhill_skiing",

          "tags":[
             "athlete",
             "athletic",
             "body",
             "downhill",
             "entertainment",
             "exercise",
             "hobby",
             "human",
             "people",
             "person",
             "ski social",
             "skiing",
             "snow",
             "sports",
             "travel",
             "winter"
          ],

       },
       {
          "name":"download",

          "tags":[
             "arrow",
             "down",
             "download",
             "downloads",
             "drive",
             "install",
             "upload"
          ],

       },
       {
          "name":"download_done",

          "tags":[
             "arrow",
             "arrows",
             "check",
             "done",
             "down",
             "download",
             "downloads",
             "drive",
             "install",
             "installed",
             "ok",
             "tick",
             "upload"
          ],

       },
       {
          "name":"download_for_offline",

          "tags":[
             "arrow",
             "circle",
             "down",
             "download",
             "for offline",
             "install",
             "upload"
          ],

       },
       {
          "name":"downloading",

          "tags":[
             "arrow",
             "circle",
             "down",
             "download",
             "downloading",
             "downloads",
             "install",
             "pending",
             "progress",
             "upload"
          ],

       },
       {
          "name":"drafts",

          "tags":[
             "document",
             "draft",
             "drafts",
             "email",
             "file",
             "letter",
             "mail",
             "message",
             "read"
          ],

       },
       {
          "name":"drag_handle",

          "tags":[
             "app",
             "application ui",
             "components",
             "design",
             "drag",
             "handle",
             "interface",
             "layout",
             "menu",
             "move",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website",
             "window"
          ],

       },
       {
          "name":"drag_indicator",

          "tags":[
             "app",
             "application",
             "circles",
             "components",
             "design",
             "dots",
             "drag",
             "drop",
             "indicator",
             "interface",
             "layout",
             "mobile",
             "monitor",
             "move",
             "phone",
             "screen",
             "shape",
             "shift",
             "site",
             "tablet",
             "ui",
             "ux",
             "web",
             "website",
             "window"
          ],

       },
       {
          "name":"draw",

          "tags":[
             
          ],

       },
       {
          "name":"drive_eta",

          "tags":[
             "automobile",
             "car",
             "cars",
             "destination",
             "direction",
             "drive",
             "estimate",
             "eta",
             "maps",
             "public",
             "transportation",
             "travel",
             "trip",
             "vehicle"
          ],

       },
       {
          "name":"drive_file_move",

          "tags":[
             "arrow",
             "arrows",
             "data",
             "direction",
             "doc",
             "document",
             "drive",
             "file",
             "folder",
             "folders",
             "move",
             "right",
             "sheet",
             "side",
             "slide",
             "storage"
          ],

       },
       {
          "name":"drive_file_move_rtl",

          "tags":[
             "drive",
             "file",
             "move"
          ],

       },
       {
          "name":"drive_file_rename_outline",

          "tags":[
             "compose",
             "create",
             "draft",
             "drive",
             "edit",
             "editing",
             "file",
             "input",
             "pen",
             "pencil",
             "rename",
             "write",
             "writing"
          ],

       },
       {
          "name":"drive_folder_upload",

          "tags":[
             "arrow",
             "data",
             "doc",
             "document",
             "drive",
             "file",
             "folder",
             "sheet",
             "slide",
             "storage",
             "up",
             "upload"
          ],

       },
       {
          "name":"dry",

          "tags":[
             "air",
             "bathroom",
             "dry",
             "dryer",
             "fingers",
             "gesture",
             "hand",
             "wc"
          ],

       },
       {
          "name":"dry_cleaning",

          "tags":[
             "cleaning",
             "dry",
             "hanger",
             "hotel",
             "laundry",
             "places",
             "service",
             "towel"
          ],

       },
       {
          "name":"duo",

          "tags":[
             "call",
             "chat",
             "conference",
             "device",
             "duo",
             "video"
          ],

       },
       {
          "name":"dvr",

          "tags":[
             "Android",
             "OS",
             "audio",
             "chrome",
             "computer",
             "desktop",
             "device",
             "display",
             "dvr",
             "electronic",
             "hardware",
             "iOS",
             "list",
             "mac",
             "monitor",
             "record",
             "recorder",
             "screen",
             "tv",
             "video",
             "web",
             "window"
          ],

       },
       {
          "name":"dynamic_feed",

          "tags":[
             "dynamic",
             "feed",
             "layer",
             "multiple",
             "post"
          ],

       },
       {
          "name":"dynamic_form",

          "tags":[
             "bolt",
             "code",
             "dynamic",
             "electric",
             "fast",
             "form",
             "lightning",
             "lists",
             "questionnaire",
             "thunderbolt"
          ],

       },
       {
          "name":"e_mobiledata",

          "tags":[
             "alphabet",
             "data",
             "e",
             "font",
             "letter",
             "mobile",
             "mobiledata",
             "text",
             "type"
          ],

       },
       {
          "name":"earbuds",

          "tags":[
             "accessory",
             "audio",
             "earbuds",
             "earphone",
             "headphone",
             "listen",
             "music",
             "sound"
          ],

       },
       {
          "name":"earbuds_battery",

          "tags":[
             "accessory",
             "audio",
             "battery",
             "charging",
             "earbuds",
             "earphone",
             "headphone",
             "listen",
             "music",
             "sound"
          ],

       },
       {
          "name":"east",

          "tags":[
             "arrow",
             "directional",
             "east",
             "maps",
             "navigation",
             "right"
          ],

       },
       {
          "name":"edgesensor_high",

          "tags":[
             "Android",
             "OS",
             "cell",
             "device",
             "edge",
             "hardware",
             "high",
             "iOS",
             "mobile",
             "move",
             "phone",
             "sensitivity",
             "sensor",
             "tablet",
             "vibrate"
          ],

       },
       {
          "name":"edgesensor_low",

          "tags":[
             "Android",
             "cell",
             "device",
             "edge",
             "hardware",
             "iOS",
             "low",
             "mobile",
             "move",
             "phone",
             "sensitivity",
             "sensor",
             "tablet",
             "vibrate"
          ],

       },
       {
          "name":"edit",

          "tags":[
             "compose",
             "create",
             "edit",
             "editing",
             "input",
             "new",
             "pen",
             "pencil",
             "write",
             "writing"
          ],

       },
       {
          "name":"edit_attributes",

          "tags":[
             "approve",
             "attribution",
             "check",
             "complete",
             "done",
             "edit",
             "mark",
             "ok",
             "select",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"edit_calendar",

          "tags":[
             
          ],

       },
       {
          "name":"edit_location",

          "tags":[
             "destination",
             "direction",
             "edit",
             "location",
             "maps",
             "pen",
             "pencil",
             "pin",
             "place",
             "stop"
          ],

       },
       {
          "name":"edit_location_alt",

          "tags":[
             "alt",
             "edit",
             "location",
             "pen",
             "pencil",
             "pin"
          ],

       },
       {
          "name":"edit_note",

          "tags":[
             
          ],

       },
       {
          "name":"edit_notifications",

          "tags":[
             "active",
             "alarm",
             "alert",
             "bell",
             "chime",
             "compose",
             "create",
             "draft",
             "edit",
             "editing",
             "input",
             "new",
             "notifications",
             "notify",
             "pen",
             "pencil",
             "reminder",
             "ring",
             "sound",
             "write",
             "writing"
          ],

       },
       {
          "name":"edit_off",

          "tags":[
             "compose",
             "create",
             "disabled",
             "draft",
             "edit",
             "editing",
             "enabled",
             "input",
             "new",
             "off",
             "offline",
             "on",
             "pen",
             "pencil",
             "slash",
             "write",
             "writing"
          ],

       },
       {
          "name":"edit_road",

          "tags":[
             "destination",
             "direction",
             "edit",
             "highway",
             "maps",
             "pen",
             "pencil",
             "road",
             "street",
             "traffic"
          ],

       },
       {
          "name":"eject",

          "tags":[
             "disc",
             "drive",
             "dvd",
             "eject",
             "remove",
             "triangle",
             "usb"
          ],

       },
       {
          "name":"elderly",

          "tags":[
             "body",
             "cane",
             "elderly",
             "human",
             "old",
             "people",
             "person",
             "senior"
          ],

       },
       {
          "name":"electric_bike",

          "tags":[
             "automobile",
             "bike",
             "car",
             "cars",
             "maps",
             "scooter",
             "transportation",
             "vehicle",
             "vespa"
          ],

       },
       {
          "name":"electric_car",

          "tags":[
             "automobile",
             "car",
             "cars",
             "electric",
             "electricity",
             "maps",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"electric_moped",

          "tags":[
             "automobile",
             "bike",
             "car",
             "cars",
             "maps",
             "scooter",
             "transportation",
             "vehicle",
             "vespa"
          ],

       },
       {
          "name":"electric_rickshaw",

          "tags":[
             "automobile",
             "car",
             "cars",
             "india",
             "maps",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"electric_scooter",

          "tags":[
             "automobile",
             "bike",
             "car",
             "cars",
             "maps",
             "scooter",
             "transportation",
             "vehicle",
             "vespa"
          ],

       },
       {
          "name":"electrical_services",

          "tags":[
             "charge",
             "cord",
             "electric",
             "electrical",
             "plug",
             "power",
             "services",
             "wire"
          ],

       },
       {
          "name":"elevator",

          "tags":[
             "body",
             "down",
             "elevator",
             "human",
             "people",
             "person",
             "up"
          ],

       },
       {
          "name":"email",

          "tags":[
             "email",
             "envelop",
             "letter",
             "mail",
             "message",
             "send"
          ],

       },
       {
          "name":"emergency",

          "tags":[
             
          ],

       },
       {
          "name":"emoji_emotions",

          "tags":[
             "emoji",
             "emotions",
             "expressions",
             "face",
             "feelings",
             "glad",
             "happiness",
             "happy",
             "like",
             "mood",
             "person",
             "pleased",
             "smile",
             "smiling",
             "social",
             "survey"
          ],

       },
       {
          "name":"emoji_events",

          "tags":[
             "achievement",
             "award",
             "chalice",
             "champion",
             "cup",
             "emoji",
             "events",
             "first",
             "prize",
             "reward",
             "sport",
             "trophy",
             "winner"
          ],

       },
       {
          "name":"emoji_flags",

          "tags":[
             "country",
             "destination",
             "emoji",
             "flags",
             "landmark",
             "location",
             "mark",
             "milepost",
             "milestone",
             "nation",
             "place",
             "pole",
             "save",
             "social",
             "world"
          ],

       },
       {
          "name":"emoji_food_beverage",

          "tags":[
             "beverage",
             "coffee",
             "cup",
             "drink",
             "emoji",
             "mug",
             "plate",
             "set",
             "tea"
          ],

       },
       {
          "name":"emoji_nature",

          "tags":[
             "animal",
             "bee",
             "bug",
             "daisy",
             "emoji",
             "flower",
             "insect",
             "ladybug",
             "nature",
             "petals",
             "spring",
             "summer"
          ],

       },
       {
          "name":"emoji_objects",

          "tags":[
             "bulb",
             "creative",
             "emoji",
             "idea",
             "light",
             "objects",
             "solution",
             "thinking"
          ],

       },
       {
          "name":"emoji_people",

          "tags":[
             "arm",
             "body",
             "emoji",
             "greeting",
             "human",
             "people",
             "person",
             "social",
             "waving"
          ],

       },
       {
          "name":"emoji_symbols",

          "tags":[
             "ampersand",
             "character",
             "emoji",
             "hieroglyph",
             "music",
             "note",
             "percent",
             "sign",
             "symbols"
          ],

       },
       {
          "name":"emoji_transportation",

          "tags":[
             "architecture",
             "automobile",
             "building",
             "car",
             "cars",
             "direction",
             "emoji",
             "estate",
             "maps",
             "place",
             "public",
             "real",
             "residence",
             "residential",
             "shelter",
             "transportation",
             "travel",
             "vehicle"
          ],

       },
       {
          "name":"engineering",

          "tags":[
             "body",
             "cogs",
             "cogwheel",
             "construction",
             "engineering",
             "fixing",
             "gears",
             "hat",
             "helmet",
             "human",
             "maintenance",
             "people",
             "person",
             "setting",
             "worker"
          ],

       },
       {
          "name":"enhanced_encryption",

          "tags":[
             "+",
             "add",
             "encryption",
             "enhanced",
             "lock",
             "locked",
             "new",
             "password",
             "plus",
             "privacy",
             "private",
             "protection",
             "safety",
             "secure",
             "security",
             "symbol"
          ],

       },
       {
          "name":"equalizer",

          "tags":[
             "adjustment",
             "analytics",
             "chart",
             "data",
             "equalizer",
             "graph",
             "measure",
             "metrics",
             "music",
             "noise",
             "sound",
             "static",
             "statistics",
             "tracking",
             "volume"
          ],

       },
       {
          "name":"error",

          "tags":[
             "!",
             "alert",
             "attention",
             "caution",
             "circle",
             "danger",
             "error",
             "exclamation",
             "important",
             "mark",
             "notification",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"error_outline",

          "tags":[
             "!",
             "alert",
             "attention",
             "caution",
             "circle",
             "danger",
             "error",
             "exclamation",
             "important",
             "mark",
             "notification",
             "outline",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"escalator",

          "tags":[
             "down",
             "escalator",
             "staircase",
             "up"
          ],

       },
       {
          "name":"escalator_warning",

          "tags":[
             "body",
             "child",
             "escalator",
             "human",
             "kid",
             "parent",
             "people",
             "person",
             "warning"
          ],

       },
       {
          "name":"euro",

          "tags":[
             "bill",
             "card",
             "cash",
             "coin",
             "commerce",
             "cost",
             "credit",
             "currency",
             "dollars",
             "euro",
             "euros",
             "finance",
             "money",
             "online",
             "pay",
             "payment",
             "price",
             "shopping",
             "symbol"
          ],

       },
       {
          "name":"euro_symbol",

          "tags":[
             "bill",
             "card",
             "cash",
             "coin",
             "commerce",
             "cost",
             "credit",
             "currency",
             "dollars",
             "euro",
             "finance",
             "money",
             "online",
             "pay",
             "payment",
             "symbol"
          ],

       },
       {
          "name":"ev_station",

          "tags":[
             "automobile",
             "car",
             "cars",
             "charging",
             "electric",
             "electricity",
             "ev",
             "maps",
             "places",
             "station",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"event",

          "tags":[
             "calendar",
             "date",
             "day",
             "event",
             "mark",
             "month",
             "range",
             "remember",
             "reminder",
             "today",
             "week"
          ],

       },
       {
          "name":"event_available",

          "tags":[
             "approve",
             "available",
             "calendar",
             "check",
             "complete",
             "date",
             "done",
             "event",
             "mark",
             "ok",
             "schedule",
             "select",
             "tick",
             "time",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"event_busy",

          "tags":[
             "busy",
             "calendar",
             "cancel",
             "close",
             "date",
             "event",
             "exit",
             "no",
             "remove",
             "schedule",
             "stop",
             "time",
             "unavailable",
             "x"
          ],

       },
       {
          "name":"event_note",

          "tags":[
             "calendar",
             "date",
             "event",
             "note",
             "schedule",
             "text",
             "time",
             "writing"
          ],

       },
       {
          "name":"event_seat",

          "tags":[
             "assign",
             "assigned",
             "chair",
             "event",
             "furniture",
             "reservation",
             "row",
             "seat",
             "section",
             "sit"
          ],

       },
       {
          "name":"exit_to_app",

          "tags":[
             "app",
             "application",
             "arrow",
             "components",
             "design",
             "exit",
             "export",
             "interface",
             "layout",
             "leave",
             "mobile",
             "monitor",
             "move",
             "phone",
             "screen",
             "site",
             "tablet",
             "to",
             "ui",
             "ux",
             "web",
             "website",
             "window"
          ],

       },
       {
          "name":"expand",

          "tags":[
             "arrow",
             "arrows",
             "compress",
             "enlarge",
             "expand",
             "grow",
             "move",
             "push",
             "together"
          ],

       },
       {
          "name":"expand_circle_down",

          "tags":[
             
          ],

       },
       {
          "name":"expand_less",

          "tags":[
             "arrow",
             "arrows",
             "chevron",
             "collapse",
             "direction",
             "expand",
             "expandable",
             "less",
             "list",
             "up"
          ],

       },
       {
          "name":"expand_more",

          "tags":[
             "arrow",
             "arrows",
             "chevron",
             "collapse",
             "direction",
             "down",
             "expand",
             "expandable",
             "list",
             "more"
          ],

       },
       {
          "name":"explicit",

          "tags":[
             "adult",
             "alphabet",
             "character",
             "content",
             "e",
             "explicit",
             "font",
             "language",
             "letter",
             "media",
             "movies",
             "music",
             "parent",
             "rating",
             "supervision",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"explore",

          "tags":[
             "compass",
             "destination",
             "direction",
             "east",
             "explore",
             "location",
             "maps",
             "needle",
             "north",
             "south",
             "travel",
             "west"
          ],

       },
       {
          "name":"explore_off",

          "tags":[
             "compass",
             "destination",
             "direction",
             "disabled",
             "east",
             "enabled",
             "explore",
             "location",
             "maps",
             "needle",
             "north",
             "off",
             "on",
             "slash",
             "south",
             "travel",
             "west"
          ],

       },
       {
          "name":"exposure",

          "tags":[
             "add",
             "brightness",
             "contrast",
             "edit",
             "editing",
             "effect",
             "exposure",
             "image",
             "minus",
             "photo",
             "photography",
             "picture",
             "plus",
             "settings",
             "subtract"
          ],

       },
       {
          "name":"exposure_neg_1",

          "tags":[
             "1",
             "brightness",
             "contrast",
             "digit",
             "edit",
             "editing",
             "effect",
             "exposure",
             "image",
             "neg",
             "negative",
             "number",
             "photo",
             "photography",
             "settings",
             "symbol"
          ],

       },
       {
          "name":"exposure_neg_2",

          "tags":[
             "2",
             "brightness",
             "contrast",
             "digit",
             "edit",
             "editing",
             "effect",
             "exposure",
             "image",
             "neg",
             "negative",
             "number",
             "photo",
             "photography",
             "settings",
             "symbol"
          ],

       },
       {
          "name":"exposure_plus_1",

          "tags":[
             "1",
             "add",
             "brightness",
             "contrast",
             "digit",
             "edit",
             "editing",
             "effect",
             "exposure",
             "image",
             "number",
             "photo",
             "photography",
             "plus",
             "settings",
             "symbol"
          ],

       },
       {
          "name":"exposure_plus_2",

          "tags":[
             "2",
             "add",
             "brightness",
             "contrast",
             "digit",
             "edit",
             "editing",
             "effect",
             "exposure",
             "image",
             "number",
             "photo",
             "photography",
             "plus",
             "settings",
             "symbol"
          ],

       },
       {
          "name":"exposure_zero",

          "tags":[
             "0",
             "brightness",
             "contrast",
             "digit",
             "edit",
             "editing",
             "effect",
             "exposure",
             "image",
             "number",
             "photo",
             "photography",
             "settings",
             "symbol",
             "zero"
          ],

       },
       {
          "name":"extension",

          "tags":[
             "app",
             "extended",
             "extension",
             "game",
             "jigsaw",
             "plugin add",
             "puzzle",
             "shape"
          ],

       },
       {
          "name":"extension_off",

          "tags":[
             "disabled",
             "enabled",
             "extended",
             "extension",
             "jigsaw",
             "off",
             "on",
             "piece",
             "puzzle",
             "shape",
             "slash"
          ],

       },
       {
          "name":"face",

          "tags":[
             "account",
             "emoji",
             "eyes",
             "face",
             "human",
             "lock",
             "log",
             "login",
             "logout",
             "people",
             "person",
             "profile",
             "recognition",
             "security",
             "social",
             "thumbnail",
             "unlock",
             "user"
          ],

       },
       {
          "name":"face_retouching_natural",

          "tags":[
             "edit",
             "editing",
             "effect",
             "emoji",
             "emotion",
             "face",
             "faces",
             "image",
             "natural",
             "photo",
             "photography",
             "retouch",
             "retouching",
             "settings",
             "star",
             "tag"
          ],

       },
       {
          "name":"face_retouching_off",

          "tags":[
             "disabled",
             "edit",
             "editing",
             "effect",
             "emoji",
             "emotion",
             "enabled",
             "face",
             "faces",
             "image",
             "natural",
             "off",
             "on",
             "photo",
             "photography",
             "retouch",
             "retouching",
             "settings",
             "slash",
             "tag"
          ],

       },
       {
          "name":"facebook",

          "tags":[
             "application",
             "apps",
             "f",
             "facebook",
             "logo",
             "post",
             "social"
          ],

       },
       {
          "name":"fact_check",

          "tags":[
             "approve",
             "check",
             "complete",
             "done",
             "fact",
             "list",
             "mark",
             "ok",
             "select",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"family_restroom",

          "tags":[
             "bathroom",
             "child",
             "children",
             "family",
             "father",
             "kids",
             "mother",
             "parents",
             "restroom",
             "wc"
          ],

       },
       {
          "name":"fast_forward",

          "tags":[
             "control",
             "fast",
             "ff",
             "forward",
             "media",
             "music",
             "play",
             "speed",
             "time",
             "tv",
             "video"
          ],

       },
       {
          "name":"fast_rewind",

          "tags":[
             "back",
             "control",
             "fast",
             "media",
             "music",
             "play",
             "rewind",
             "speed",
             "time",
             "tv",
             "video"
          ],

       },
       {
          "name":"fastfood",

          "tags":[
             "drink",
             "fastfood",
             "food",
             "hamburger",
             "maps",
             "meal",
             "places"
          ],

       },
       {
          "name":"favorite",

          "tags":[
             "appreciate",
             "favorite",
             "heart",
             "like",
             "love",
             "remember",
             "save",
             "shape"
          ],

       },
       {
          "name":"favorite_border",

          "tags":[
             "border",
             "favorite",
             "heart",
             "like",
             "love",
             "outline",
             "remember",
             "save",
             "shape"
          ],

       },
       {
          "name":"featured_play_list",

          "tags":[
             "collection",
             "featured",
             "highlighted",
             "list",
             "music",
             "play",
             "playlist",
             "recommended"
          ],

       },
       {
          "name":"featured_video",

          "tags":[
             "advertised",
             "advertisment",
             "featured",
             "highlighted",
             "recommended",
             "video",
             "watch"
          ],

       },
       {
          "name":"feed",

          "tags":[
             "article",
             "feed",
             "headline",
             "information",
             "news",
             "newspaper",
             "paper",
             "public",
             "social",
             "timeline"
          ],

       },
       {
          "name":"feedback",

          "tags":[
             "!",
             "alert",
             "announcement",
             "attention",
             "bubble",
             "caution",
             "chat",
             "comment",
             "communicate",
             "danger",
             "error",
             "exclamation",
             "feedback",
             "important",
             "mark",
             "message",
             "notification",
             "speech",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"female",

          "tags":[
             "female",
             "gender",
             "girl",
             "lady",
             "social",
             "symbol",
             "woman",
             "women"
          ],

       },
       {
          "name":"fence",

          "tags":[
             "backyard",
             "barrier",
             "boundaries",
             "boundary",
             "fence",
             "home",
             "house",
             "protection",
             "yard"
          ],

       },
       {
          "name":"festival",

          "tags":[
             "circus",
             "event",
             "festival",
             "local",
             "maps",
             "places",
             "tent",
             "tour",
             "travel"
          ],

       },
       {
          "name":"fiber_dvr",

          "tags":[
             "alphabet",
             "character",
             "digital",
             "dvr",
             "electronics",
             "fiber",
             "font",
             "letter",
             "network",
             "record",
             "recorder",
             "symbol",
             "text",
             "tv",
             "type",
             "video"
          ],

       },
       {
          "name":"fiber_manual_record",

          "tags":[
             "circle",
             "dot",
             "fiber",
             "manual",
             "play",
             "record",
             "watch"
          ],

       },
       {
          "name":"fiber_new",

          "tags":[
             "alphabet",
             "character",
             "fiber",
             "font",
             "letter",
             "network",
             "new",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"fiber_pin",

          "tags":[
             "alphabet",
             "character",
             "fiber",
             "font",
             "letter",
             "network",
             "pin",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"fiber_smart_record",

          "tags":[
             "circle",
             "dot",
             "fiber",
             "play",
             "record",
             "smart",
             "watch"
          ],

       },
       {
          "name":"file_copy",

          "tags":[
             "content",
             "copy",
             "cut",
             "doc",
             "document",
             "duplicate",
             "file",
             "multiple",
             "past"
          ],

       },
       {
          "name":"file_download",

          "tags":[
             "arrow",
             "arrows",
             "down",
             "download",
             "downloads",
             "drive",
             "export",
             "file",
             "install",
             "upload"
          ],

       },
       {
          "name":"file_download_done",

          "tags":[
             "arrow",
             "arrows",
             "check",
             "done",
             "down",
             "download",
             "downloads",
             "drive",
             "file",
             "install",
             "installed",
             "tick",
             "upload"
          ],

       },
       {
          "name":"file_download_off",

          "tags":[
             "arrow",
             "disabled",
             "down",
             "download",
             "drive",
             "enabled",
             "export",
             "file",
             "install",
             "off",
             "on",
             "save",
             "slash",
             "upload"
          ],

       },
       {
          "name":"file_present",

          "tags":[
             "clip",
             "data",
             "doc",
             "document",
             "drive",
             "file",
             "folder",
             "folders",
             "note",
             "paper",
             "present",
             "reminder",
             "sheet",
             "slide",
             "storage",
             "writing"
          ],

       },
       {
          "name":"file_upload",

          "tags":[
             "arrow",
             "arrows",
             "download",
             "drive",
             "export",
             "file",
             "up",
             "upload"
          ],

       },
       {
          "name":"filter",

          "tags":[
             "edit",
             "editing",
             "effect",
             "filter",
             "image",
             "landscape",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture",
             "settings"
          ],

       },
       {
          "name":"filter_1",

          "tags":[
             "1",
             "digit",
             "edit",
             "editing",
             "effect",
             "filter",
             "image",
             "images",
             "multiple",
             "number",
             "photography",
             "picture",
             "pictures",
             "settings",
             "stack",
             "symbol"
          ],

       },
       {
          "name":"filter_2",

          "tags":[
             "2",
             "digit",
             "edit",
             "editing",
             "effect",
             "filter",
             "image",
             "images",
             "multiple",
             "number",
             "photography",
             "picture",
             "pictures",
             "settings",
             "stack",
             "symbol"
          ],

       },
       {
          "name":"filter_3",

          "tags":[
             "3",
             "digit",
             "edit",
             "editing",
             "effect",
             "filter",
             "image",
             "images",
             "multiple",
             "number",
             "photography",
             "picture",
             "pictures",
             "settings",
             "stack",
             "symbol"
          ],

       },
       {
          "name":"filter_4",

          "tags":[
             "4",
             "digit",
             "edit",
             "editing",
             "effect",
             "filter",
             "image",
             "images",
             "multiple",
             "number",
             "photography",
             "picture",
             "pictures",
             "settings",
             "stack",
             "symbol"
          ],

       },
       {
          "name":"filter_5",

          "tags":[
             "5",
             "digit",
             "edit",
             "editing",
             "effect",
             "filter",
             "image",
             "images",
             "multiple",
             "number",
             "photography",
             "picture",
             "pictures",
             "settings",
             "stack",
             "symbol"
          ],

       },
       {
          "name":"filter_6",

          "tags":[
             "6",
             "digit",
             "edit",
             "editing",
             "effect",
             "filter",
             "image",
             "images",
             "multiple",
             "number",
             "photography",
             "picture",
             "pictures",
             "settings",
             "stack",
             "symbol"
          ],

       },
       {
          "name":"filter_7",

          "tags":[
             "7",
             "digit",
             "edit",
             "editing",
             "effect",
             "filter",
             "image",
             "images",
             "multiple",
             "number",
             "photography",
             "picture",
             "pictures",
             "settings",
             "stack",
             "symbol"
          ],

       },
       {
          "name":"filter_8",

          "tags":[
             "8",
             "digit",
             "edit",
             "editing",
             "effect",
             "filter",
             "image",
             "images",
             "multiple",
             "number",
             "photography",
             "picture",
             "pictures",
             "settings",
             "stack",
             "symbol"
          ],

       },
       {
          "name":"filter_9",

          "tags":[
             "9",
             "digit",
             "edit",
             "editing",
             "effect",
             "filter",
             "image",
             "images",
             "multiple",
             "number",
             "photography",
             "picture",
             "pictures",
             "settings",
             "stack",
             "symbol"
          ],

       },
       {
          "name":"filter_9_plus",

          "tags":[
             "+",
             "9",
             "digit",
             "edit",
             "editing",
             "effect",
             "filter",
             "image",
             "images",
             "multiple",
             "number",
             "photography",
             "picture",
             "pictures",
             "plus",
             "settings",
             "stack",
             "symbol"
          ],

       },
       {
          "name":"filter_alt",

          "tags":[
             "alt",
             "edit",
             "filter",
             "funnel",
             "options",
             "refine",
             "sift"
          ],

       },
       {
          "name":"filter_b_and_w",

          "tags":[
             "and",
             "b",
             "black",
             "contrast",
             "edit",
             "editing",
             "effect",
             "filter",
             "grayscale",
             "image",
             "images",
             "photography",
             "picture",
             "pictures",
             "settings",
             "w",
             "white"
          ],

       },
       {
          "name":"filter_center_focus",

          "tags":[
             "camera",
             "center",
             "dot",
             "edit",
             "filter",
             "focus",
             "image",
             "photo",
             "photography",
             "picture"
          ],

       },
       {
          "name":"filter_drama",

          "tags":[
             "cloud",
             "drama",
             "edit",
             "editing",
             "effect",
             "filter",
             "image",
             "photo",
             "photography",
             "picture",
             "sky camera"
          ],

       },
       {
          "name":"filter_frames",

          "tags":[
             "boarders",
             "border",
             "camera",
             "center",
             "edit",
             "editing",
             "effect",
             "filter",
             "filters",
             "focus",
             "frame",
             "frames",
             "image",
             "options",
             "photo",
             "photography",
             "picture"
          ],

       },
       {
          "name":"filter_hdr",

          "tags":[
             "camera",
             "edit",
             "editing",
             "effect",
             "filter",
             "hdr",
             "image",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture"
          ],

       },
       {
          "name":"filter_list",

          "tags":[
             "filter",
             "lines",
             "list",
             "organize",
             "sort"
          ],

       },
       {
          "name":"filter_none",

          "tags":[
             "filter",
             "multiple",
             "none",
             "stack"
          ],

       },
       {
          "name":"filter_tilt_shift",

          "tags":[
             "blur",
             "center",
             "edit",
             "editing",
             "effect",
             "filter",
             "focus",
             "image",
             "images",
             "photography",
             "picture",
             "pictures",
             "shift",
             "tilt"
          ],

       },
       {
          "name":"filter_vintage",

          "tags":[
             "edit",
             "editing",
             "effect",
             "filter",
             "flower",
             "image",
             "images",
             "photography",
             "picture",
             "pictures",
             "vintage"
          ],

       },
       {
          "name":"find_in_page",

          "tags":[
             "data",
             "doc",
             "document",
             "drive",
             "file",
             "find",
             "folder",
             "folders",
             "glass",
             "in",
             "look",
             "magnify",
             "magnifying",
             "page",
             "paper",
             "search",
             "see",
             "sheet",
             "slide",
             "writing"
          ],

       },
       {
          "name":"find_replace",

          "tags":[
             "around",
             "arrows",
             "find",
             "glass",
             "inprogress",
             "load",
             "loading refresh",
             "look",
             "magnify",
             "magnifying",
             "renew",
             "replace",
             "rotate",
             "search",
             "see"
          ],

       },
       {
          "name":"fingerprint",

          "tags":[
             "finger",
             "fingerprint",
             "id",
             "identification",
             "identity",
             "print",
             "reader",
             "thumbprint",
             "verification"
          ],

       },
       {
          "name":"fire_extinguisher",

          "tags":[
             "emergency",
             "extinguisher",
             "fire",
             "water"
          ],

       },
       {
          "name":"fireplace",

          "tags":[
             "chimney",
             "fire",
             "fireplace",
             "flame",
             "home",
             "house",
             "living",
             "pit",
             "place",
             "room",
             "warm",
             "winter"
          ],

       },
       {
          "name":"first_page",

          "tags":[
             "arrow",
             "back",
             "chevron",
             "first",
             "left",
             "page",
             "rewind"
          ],

       },
       {
          "name":"fit_screen",

          "tags":[
             "enlarge",
             "fit",
             "format",
             "layout",
             "reduce",
             "scale",
             "screen",
             "size"
          ],

       },
       {
          "name":"fitness_center",

          "tags":[
             "athlete",
             "center",
             "dumbbell",
             "exercise",
             "fitness",
             "gym",
             "hobby",
             "places",
             "sport",
             "weights",
             "workout"
          ],

       },
       {
          "name":"flag",

          "tags":[
             "country",
             "flag",
             "goal",
             "mark",
             "nation",
             "report",
             "start"
          ],

       },
       {
          "name":"flaky",

          "tags":[
             "approve",
             "check",
             "close",
             "complete",
             "contrast",
             "done",
             "exit",
             "flaky",
             "mark",
             "no",
             "ok",
             "options",
             "select",
             "stop",
             "tick",
             "verified",
             "x",
             "yes"
          ],

       },
       {
          "name":"flare",

          "tags":[
             "bright",
             "edit",
             "editing",
             "effect",
             "flare",
             "image",
             "images",
             "light",
             "photography",
             "picture",
             "pictures",
             "sparkle",
             "sun"
          ],

       },
       {
          "name":"flash_auto",

          "tags":[
             "a",
             "auto",
             "bolt",
             "camera",
             "electric",
             "fast",
             "flash",
             "lightning",
             "thunderbolt"
          ],

       },
       {
          "name":"flash_off",

          "tags":[
             "bolt",
             "camera",
             "disabled",
             "electric",
             "enabled",
             "fast",
             "flash",
             "lightning",
             "off",
             "on",
             "slash",
             "thunderbolt"
          ],

       },
       {
          "name":"flash_on",

          "tags":[
             "bolt",
             "camera",
             "disabled",
             "electric",
             "enabled",
             "fast",
             "flash",
             "lightning",
             "off",
             "on",
             "slash",
             "thunderbolt"
          ],

       },
       {
          "name":"flashlight_off",

          "tags":[
             "disabled",
             "enabled",
             "flash",
             "flashlight",
             "light",
             "off",
             "on",
             "slash"
          ],

       },
       {
          "name":"flashlight_on",

          "tags":[
             "disabled",
             "enabled",
             "flash",
             "flashlight",
             "light",
             "off",
             "on",
             "slash"
          ],

       },
       {
          "name":"flatware",

          "tags":[
             "cafe",
             "cafeteria",
             "cutlery",
             "diner",
             "dining",
             "eat",
             "eating",
             "fork",
             "room",
             "spoon"
          ],

       },
       {
          "name":"flight",

          "tags":[
             "air",
             "airplane",
             "airport",
             "flight",
             "plane",
             "transportation",
             "travel",
             "trip"
          ],

       },
       {
          "name":"flight_class",

          "tags":[
             
          ],

       },
       {
          "name":"flight_land",

          "tags":[
             "airport",
             "arrival",
             "arriving",
             "flight",
             "fly",
             "land",
             "landing",
             "plane",
             "transportation",
             "travel"
          ],

       },
       {
          "name":"flight_takeoff",

          "tags":[
             "airport",
             "departed",
             "departing",
             "flight",
             "fly",
             "landing",
             "plane",
             "takeoff",
             "transportation",
             "travel"
          ],

       },
       {
          "name":"flip",

          "tags":[
             "edit",
             "editing",
             "flip",
             "image",
             "orientation",
             "scan scanning"
          ],

       },
       {
          "name":"flip_camera_android",

          "tags":[
             "android",
             "camera",
             "center",
             "edit",
             "editing",
             "flip",
             "image",
             "mobile",
             "orientation",
             "rotate",
             "turn"
          ],

       },
       {
          "name":"flip_camera_ios",

          "tags":[
             "android",
             "camera",
             "edit",
             "editing",
             "flip",
             "image",
             "ios",
             "mobile",
             "orientation",
             "rotate",
             "turn"
          ],

       },
       {
          "name":"flip_to_back",

          "tags":[
             "arrange",
             "arrangement",
             "back",
             "flip",
             "format",
             "front",
             "layout",
             "move",
             "order",
             "sort",
             "to"
          ],

       },
       {
          "name":"flip_to_front",

          "tags":[
             "arrange",
             "arrangement",
             "back",
             "flip",
             "format",
             "front",
             "layout",
             "move",
             "order",
             "sort",
             "to"
          ],

       },
       {
          "name":"flourescent",

          "tags":[
             "bright",
             "flourescent",
             "lamp",
             "light",
             "lightbulb"
          ],

       },
       {
          "name":"flutter_dash",

          "tags":[
             "bird",
             "dash",
             "flutter"
          ],

       },
       {
          "name":"fmd_bad",

          "tags":[
             "!",
             "alert",
             "attention",
             "bad",
             "caution",
             "danger",
             "destination",
             "direction",
             "error",
             "exclamation",
             "fmd",
             "important",
             "location",
             "maps",
             "mark",
             "notification",
             "pin",
             "place",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"fmd_good",

          "tags":[
             "destination",
             "direction",
             "fmd",
             "good",
             "location",
             "maps",
             "pin",
             "place",
             "stop"
          ],

       },
       {
          "name":"folder",

          "tags":[
             "data",
             "doc",
             "document",
             "drive",
             "file",
             "folder",
             "folders",
             "sheet",
             "slide",
             "storage"
          ],

       },
       {
          "name":"folder_open",

          "tags":[
             "data",
             "doc",
             "document",
             "drive",
             "file",
             "folder",
             "folders",
             "open",
             "sheet",
             "slide",
             "storage"
          ],

       },
       {
          "name":"folder_shared",

          "tags":[
             "account",
             "collaboration",
             "data",
             "doc",
             "document",
             "drive",
             "face",
             "file",
             "folder",
             "human",
             "people",
             "person",
             "profile",
             "share",
             "shared",
             "sheet",
             "slide",
             "storage",
             "team",
             "user"
          ],

       },
       {
          "name":"folder_special",

          "tags":[
             "bookmark",
             "data",
             "doc",
             "document",
             "drive",
             "favorite",
             "file",
             "folder",
             "highlight",
             "important",
             "marked",
             "save",
             "saved",
             "shape",
             "sheet",
             "slide",
             "special",
             "star",
             "storage"
          ],

       },
       {
          "name":"follow_the_signs",

          "tags":[
             "arrow",
             "body",
             "directional",
             "follow",
             "human",
             "people",
             "person",
             "right",
             "signs",
             "social",
             "the"
          ],

       },
       {
          "name":"font_download",

          "tags":[
             "A",
             "alphabet",
             "character",
             "download",
             "font",
             "letter",
             "square",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"font_download_off",

          "tags":[
             "alphabet",
             "character",
             "disabled",
             "download",
             "enabled",
             "font",
             "letter",
             "off",
             "on",
             "slash",
             "square",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"food_bank",

          "tags":[
             "architecture",
             "bank",
             "building",
             "charity",
             "eat",
             "estate",
             "food",
             "fork",
             "house",
             "knife",
             "meal",
             "place",
             "real",
             "residence",
             "residential",
             "shelter",
             "utensils"
          ],

       },
       {
          "name":"format_align_center",

          "tags":[
             "align",
             "alignment",
             "center",
             "doc",
             "edit",
             "editing",
             "editor",
             "format",
             "sheet",
             "spreadsheet",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_align_justify",

          "tags":[
             "align",
             "alignment",
             "doc",
             "edit",
             "editing",
             "editor",
             "format",
             "justify",
             "sheet",
             "spreadsheet",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_align_left",

          "tags":[
             "align",
             "alignment",
             "doc",
             "edit",
             "editing",
             "editor",
             "format",
             "left",
             "sheet",
             "spreadsheet",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_align_right",

          "tags":[
             "align",
             "alignment",
             "doc",
             "edit",
             "editing",
             "editor",
             "format",
             "right",
             "sheet",
             "spreadsheet",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_bold",

          "tags":[
             "B",
             "alphabet",
             "bold",
             "character",
             "doc",
             "edit",
             "editing",
             "editor",
             "font",
             "format",
             "letter",
             "sheet",
             "spreadsheet",
             "styles",
             "symbol",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_clear",

          "tags":[
             "T",
             "alphabet",
             "character",
             "clear",
             "disabled",
             "doc",
             "edit",
             "editing",
             "editor",
             "enabled",
             "font",
             "format",
             "letter",
             "off",
             "on",
             "sheet",
             "slash",
             "spreadsheet",
             "style",
             "symbol",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_color_fill",

          "tags":[
             "bucket",
             "color",
             "doc",
             "edit",
             "editing",
             "editor",
             "fill",
             "format",
             "paint",
             "sheet",
             "spreadsheet",
             "style",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_color_reset",

          "tags":[
             "clear",
             "color",
             "disabled",
             "doc",
             "droplet",
             "edit",
             "editing",
             "editor",
             "enabled",
             "fill",
             "format",
             "off",
             "on",
             "paint",
             "reset",
             "sheet",
             "slash",
             "spreadsheet",
             "style",
             "text",
             "type",
             "water",
             "writing"
          ],

       },
       {
          "name":"format_color_text",

          "tags":[
             "color",
             "doc",
             "edit",
             "editing",
             "editor",
             "fill",
             "format",
             "paint",
             "sheet",
             "spreadsheet",
             "style",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_indent_decrease",

          "tags":[
             "align",
             "alignment",
             "decrease",
             "doc",
             "edit",
             "editing",
             "editor",
             "format",
             "indent",
             "indentation",
             "paragraph",
             "sheet",
             "spreadsheet",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_indent_increase",

          "tags":[
             "align",
             "alignment",
             "doc",
             "edit",
             "editing",
             "editor",
             "format",
             "increase",
             "indent",
             "indentation",
             "paragraph",
             "sheet",
             "spreadsheet",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_italic",

          "tags":[
             "alphabet",
             "character",
             "doc",
             "edit",
             "editing",
             "editor",
             "font",
             "format",
             "italic",
             "letter",
             "sheet",
             "spreadsheet",
             "style",
             "symbol",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_line_spacing",

          "tags":[
             "align",
             "alignment",
             "doc",
             "edit",
             "editing",
             "editor",
             "format",
             "line",
             "sheet",
             "spacing",
             "spreadsheet",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_list_bulleted",

          "tags":[
             "align",
             "alignment",
             "bulleted",
             "doc",
             "edit",
             "editing",
             "editor",
             "format",
             "list",
             "notes",
             "sheet",
             "spreadsheet",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_list_numbered",

          "tags":[
             "align",
             "alignment",
             "digit",
             "doc",
             "edit",
             "editing",
             "editor",
             "format",
             "list",
             "notes",
             "number",
             "numbered",
             "sheet",
             "spreadsheet",
             "symbol",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_list_numbered_rtl",

          "tags":[
             "align",
             "alignment",
             "digit",
             "doc",
             "edit",
             "editing",
             "editor",
             "format",
             "list",
             "notes",
             "number",
             "numbered",
             "rtl",
             "sheet",
             "spreadsheet",
             "symbol",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_paint",

          "tags":[
             "brush",
             "color",
             "doc",
             "edit",
             "editing",
             "editor",
             "fill",
             "format",
             "paint",
             "roller",
             "sheet",
             "spreadsheet",
             "style",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_quote",

          "tags":[
             "doc",
             "edit",
             "editing",
             "editor",
             "format",
             "quotation",
             "quote",
             "sheet",
             "spreadsheet",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_shapes",

          "tags":[
             "alphabet",
             "character",
             "color",
             "doc",
             "edit",
             "editing",
             "editor",
             "fill",
             "font",
             "format",
             "letter",
             "paint",
             "shapes",
             "sheet",
             "spreadsheet",
             "style",
             "symbol",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_size",

          "tags":[
             "alphabet",
             "character",
             "color",
             "doc",
             "edit",
             "editing",
             "editor",
             "fill",
             "font",
             "format",
             "letter",
             "paint",
             "sheet",
             "size",
             "spreadsheet",
             "style",
             "symbol",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_strikethrough",

          "tags":[
             "alphabet",
             "character",
             "doc",
             "edit",
             "editing",
             "editor",
             "font",
             "format",
             "letter",
             "sheet",
             "spreadsheet",
             "strikethrough",
             "style",
             "symbol",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_textdirection_l_to_r",

          "tags":[
             "align",
             "alignment",
             "doc",
             "edit",
             "editing",
             "editor",
             "format",
             "ltr",
             "sheet",
             "spreadsheet",
             "text",
             "textdirection",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_textdirection_r_to_l",

          "tags":[
             "align",
             "alignment",
             "doc",
             "edit",
             "editing",
             "editor",
             "format",
             "rtl",
             "sheet",
             "spreadsheet",
             "text",
             "textdirection",
             "type",
             "writing"
          ],

       },
       {
          "name":"format_underlined",

          "tags":[
             "alphabet",
             "character",
             "doc",
             "edit",
             "editing",
             "editor",
             "font",
             "format",
             "letter",
             "line",
             "sheet",
             "spreadsheet",
             "style",
             "symbol",
             "text",
             "type",
             "under",
             "underlined",
             "writing"
          ],

       },
       {
          "name":"forum",

          "tags":[
             "bubble",
             "chat",
             "comment",
             "communicate",
             "community",
             "conversation",
             "feedback",
             "forum",
             "hub",
             "message",
             "speech"
          ],

       },
       {
          "name":"forward",

          "tags":[
             "arrow",
             "forward",
             "mail",
             "message",
             "playback",
             "right",
             "sent"
          ],

       },
       {
          "name":"forward_10",

          "tags":[
             "10",
             "arrow",
             "control",
             "controls",
             "digit",
             "fast",
             "forward",
             "music",
             "number",
             "play",
             "seconds",
             "symbol",
             "video"
          ],

       },
       {
          "name":"forward_30",

          "tags":[
             "30",
             "arrow",
             "control",
             "controls",
             "digit",
             "fast",
             "forward",
             "music",
             "number",
             "seconds",
             "symbol",
             "video"
          ],

       },
       {
          "name":"forward_5",

          "tags":[
             "10",
             "5",
             "arrow",
             "control",
             "controls",
             "digit",
             "fast",
             "forward",
             "music",
             "number",
             "seconds",
             "symbol",
             "video"
          ],

       },
       {
          "name":"forward_to_inbox",

          "tags":[
             "arrow",
             "email",
             "envelop",
             "forward",
             "inbox",
             "letter",
             "mail",
             "message",
             "send",
             "to"
          ],

       },
       {
          "name":"foundation",

          "tags":[
             "architecture",
             "base",
             "basis",
             "building",
             "construction",
             "estate",
             "foundation",
             "home",
             "house",
             "real",
             "residential"
          ],

       },
       {
          "name":"free_breakfast",

          "tags":[
             "beverage",
             "breakfast",
             "cafe",
             "coffee",
             "cup",
             "drink",
             "free",
             "mug",
             "tea"
          ],

       },
       {
          "name":"free_cancellation",

          "tags":[
             
          ],

       },
       {
          "name":"front_hand",

          "tags":[
             
          ],

       },
       {
          "name":"fullscreen",

          "tags":[
             "adjust",
             "app",
             "application",
             "components",
             "full",
             "fullscreen",
             "interface",
             "screen",
             "site",
             "size",
             "ui",
             "ux",
             "view",
             "web",
             "website"
          ],

       },
       {
          "name":"fullscreen_exit",

          "tags":[
             "adjust",
             "app",
             "application",
             "components",
             "exit",
             "full",
             "fullscreen",
             "interface",
             "screen",
             "site",
             "size",
             "ui",
             "ux",
             "view",
             "web",
             "website"
          ],

       },
       {
          "name":"functions",

          "tags":[
             "average",
             "calculate",
             "count",
             "doc",
             "edit",
             "editing",
             "editor",
             "functions",
             "math",
             "sheet",
             "spreadsheet",
             "style",
             "sum",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"g_mobiledata",

          "tags":[
             "alphabet",
             "character",
             "data",
             "font",
             "g",
             "letter",
             "mobile",
             "network",
             "service",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"g_translate",

          "tags":[
             "emblem",
             "g",
             "google",
             "language",
             "logo",
             "mark",
             "speaking",
             "speech",
             "translate",
             "translator",
             "words"
          ],

       },
       {
          "name":"gamepad",

          "tags":[
             "buttons",
             "console",
             "controller",
             "device",
             "game",
             "gamepad",
             "gaming",
             "playstation",
             "video"
          ],

       },
       {
          "name":"games",

          "tags":[
             "adjust",
             "arrow",
             "arrows",
             "control",
             "controller",
             "direction",
             "games",
             "gaming",
             "left",
             "move",
             "right"
          ],

       },
       {
          "name":"garage",

          "tags":[
             "automobile",
             "automotive",
             "car",
             "cars",
             "direction",
             "garage",
             "maps",
             "transportation",
             "travel",
             "vehicle"
          ],

       },
       {
          "name":"gavel",

          "tags":[
             "agreement",
             "contract",
             "court",
             "document",
             "gavel",
             "government",
             "judge",
             "law",
             "mallet",
             "official",
             "police",
             "rule",
             "rules",
             "terms"
          ],

       },
       {
          "name":"generating_tokens",

          "tags":[
             
          ],

       },
       {
          "name":"gesture",

          "tags":[
             "drawing",
             "finger",
             "gesture",
             "gestures",
             "hand",
             "motion"
          ],

       },
       {
          "name":"get_app",

          "tags":[
             "app",
             "arrow",
             "arrows",
             "down",
             "download",
             "downloads",
             "export",
             "get",
             "install",
             "play",
             "upload"
          ],

       },
       {
          "name":"gif",

          "tags":[
             "alphabet",
             "animated",
             "animation",
             "bitmap",
             "character",
             "font",
             "format",
             "gif",
             "graphics",
             "interchange",
             "letter",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"gif_box",

          "tags":[
             
          ],

       },
       {
          "name":"gite",

          "tags":[
             "architecture",
             "estate",
             "gite",
             "home",
             "hostel",
             "house",
             "maps",
             "place",
             "real",
             "residence",
             "residential",
             "stay",
             "traveling"
          ],

       },
       {
          "name":"golf_course",

          "tags":[
             "athlete",
             "athletic",
             "ball",
             "club",
             "course",
             "entertainment",
             "flag",
             "golf",
             "golfer",
             "golfing",
             "hobby",
             "hole",
             "places",
             "putt",
             "sports"
          ],

       },
       {
          "name":"gpp_bad",

          "tags":[
             "bad",
             "cancel",
             "certified",
             "close",
             "error",
             "exit",
             "gpp",
             "no",
             "privacy",
             "private",
             "protect",
             "protection",
             "remove",
             "security",
             "shield",
             "sim",
             "stop",
             "verified",
             "x"
          ],

       },
       {
          "name":"gpp_good",

          "tags":[
             "certified",
             "check",
             "good",
             "gpp",
             "ok",
             "pass",
             "security",
             "shield",
             "sim",
             "tick"
          ],

       },
       {
          "name":"gpp_maybe",

          "tags":[
             "!",
             "alert",
             "attention",
             "caution",
             "certified",
             "danger",
             "error",
             "exclamation",
             "gpp",
             "important",
             "mark",
             "maybe",
             "notification",
             "privacy",
             "private",
             "protect",
             "protection",
             "security",
             "shield",
             "sim",
             "symbol",
             "verified",
             "warning"
          ],

       },
       {
          "name":"gps_fixed",

          "tags":[
             "destination",
             "direction",
             "fixed",
             "gps",
             "location",
             "maps",
             "pin",
             "place",
             "pointer",
             "stop",
             "tracking"
          ],

       },
       {
          "name":"gps_not_fixed",

          "tags":[
             "destination",
             "direction",
             "disabled",
             "enabled",
             "gps",
             "location",
             "maps",
             "not fixed",
             "off",
             "on",
             "online",
             "place",
             "pointer",
             "slash",
             "tracking"
          ],

       },
       {
          "name":"gps_off",

          "tags":[
             "destination",
             "direction",
             "disabled",
             "enabled",
             "gps",
             "location",
             "maps",
             "not fixed",
             "off",
             "offline",
             "on",
             "place",
             "pointer",
             "slash",
             "tracking"
          ],

       },
       {
          "name":"grade",

          "tags":[
             "achievement",
             "grade",
             "important",
             "marked",
             "rate",
             "reward",
             "saved",
             "shape",
             "special",
             "star"
          ],

       },
       {
          "name":"gradient",

          "tags":[
             "color",
             "edit",
             "editing",
             "effect",
             "filter",
             "gradient",
             "image",
             "images",
             "photography",
             "picture",
             "pictures"
          ],

       },
       {
          "name":"grading",

          "tags":[
             "approve",
             "check",
             "complete",
             "document",
             "done",
             "feedback",
             "grade",
             "grading",
             "mark",
             "ok",
             "review",
             "reviewed",
             "select",
             "tick",
             "validate",
             "verified",
             "writing",
             "yes"
          ],

       },
       {
          "name":"grain",

          "tags":[
             "dots",
             "edit",
             "editing",
             "effect",
             "filter",
             "grain",
             "image",
             "images",
             "photography",
             "picture",
             "pictures"
          ],

       },
       {
          "name":"graphic_eq",

          "tags":[
             "audio",
             "eq",
             "equalizer",
             "graphic",
             "music",
             "recording",
             "sound",
             "voice"
          ],

       },
       {
          "name":"grass",

          "tags":[
             "backyard",
             "fodder",
             "grass",
             "ground",
             "home",
             "lawn",
             "plant",
             "turf",
             "yard"
          ],

       },
       {
          "name":"grid_3x3",

          "tags":[
             "3",
             "grid",
             "layout",
             "line",
             "space"
          ],

       },
       {
          "name":"grid_4x4",

          "tags":[
             "4",
             "by",
             "grid",
             "layout",
             "lines",
             "space"
          ],

       },
       {
          "name":"grid_goldenratio",

          "tags":[
             "golden",
             "goldenratio",
             "grid",
             "layout",
             "lines",
             "ratio",
             "space"
          ],

       },
       {
          "name":"grid_off",

          "tags":[
             "collage",
             "disabled",
             "enabled",
             "grid",
             "image",
             "layout",
             "off",
             "on",
             "slash",
             "view"
          ],

       },
       {
          "name":"grid_on",

          "tags":[
             "collage",
             "disabled",
             "enabled",
             "grid",
             "image",
             "layout",
             "off",
             "on",
             "slash",
             "view"
          ],

       },
       {
          "name":"grid_view",

          "tags":[
             "app",
             "application square",
             "blocks",
             "components",
             "dashboard",
             "design",
             "grid",
             "interface",
             "layout",
             "screen",
             "site",
             "tiles",
             "ui",
             "ux",
             "view",
             "web",
             "website",
             "window"
          ],

       },
       {
          "name":"group",

          "tags":[
             "accounts",
             "committee",
             "face",
             "family",
             "friends",
             "group",
             "humans",
             "network",
             "people",
             "persons",
             "profiles",
             "social",
             "team",
             "users"
          ],

       },
       {
          "name":"group_add",

          "tags":[
             "accounts",
             "add",
             "committee",
             "face",
             "family",
             "friends",
             "group",
             "humans",
             "increase",
             "more",
             "network",
             "people",
             "persons",
             "plus",
             "profiles",
             "social",
             "team",
             "users"
          ],

       },
       {
          "name":"group_off",

          "tags":[
             
          ],

       },
       {
          "name":"group_remove",

          "tags":[
             
          ],

       },
       {
          "name":"group_work",

          "tags":[
             "alliance",
             "collaboration",
             "group",
             "partnership",
             "team",
             "teamwork",
             "together",
             "work"
          ],

       },
       {
          "name":"groups",

          "tags":[
             "body",
             "club",
             "collaboration",
             "crowd",
             "gathering",
             "groups",
             "human",
             "meeting",
             "people",
             "person",
             "social",
             "teams"
          ],

       },
       {
          "name":"h_mobiledata",

          "tags":[
             "alphabet",
             "character",
             "data",
             "font",
             "h",
             "letter",
             "mobile",
             "network",
             "service",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"h_plus_mobiledata",

          "tags":[
             "+",
             "alphabet",
             "character",
             "data",
             "font",
             "h",
             "letter",
             "mobile",
             "network",
             "plus",
             "service",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"hail",

          "tags":[
             "body",
             "hail",
             "human",
             "people",
             "person",
             "pick",
             "public",
             "stop",
             "taxi",
             "transportation"
          ],

       },
       {
          "name":"handyman",

          "tags":[
             "build",
             "construction",
             "fix",
             "hammer",
             "handyman",
             "repair",
             "screw",
             "screwdriver",
             "tools"
          ],

       },
       {
          "name":"hardware",

          "tags":[
             "break",
             "construction",
             "hammer",
             "hardware",
             "nail",
             "repair",
             "tool"
          ],

       },
       {
          "name":"hd",

          "tags":[
             "alphabet",
             "character",
             "definition",
             "display",
             "font",
             "hd",
             "high",
             "letter",
             "movie",
             "movies",
             "quality",
             "resolution",
             "screen",
             "symbol",
             "text",
             "tv",
             "type",
             "video"
          ],

       },
       {
          "name":"hdr_auto",

          "tags":[
             "A",
             "alphabet",
             "auto",
             "camera",
             "character",
             "circle",
             "dynamic",
             "font",
             "hdr",
             "high",
             "letter",
             "photo",
             "range",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"hdr_auto_select",

          "tags":[
             "+",
             "A",
             "alphabet",
             "auto",
             "camera",
             "character",
             "circle",
             "dynamic",
             "font",
             "hdr",
             "high",
             "letter",
             "photo",
             "range",
             "select",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"hdr_enhanced_select",

          "tags":[
             "add",
             "alphabet",
             "character",
             "dynamic",
             "enhance",
             "font",
             "hdr",
             "high",
             "letter",
             "plus",
             "range",
             "select",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"hdr_off",

          "tags":[
             "alphabet",
             "character",
             "disabled",
             "dynamic",
             "enabled",
             "enhance",
             "font",
             "hdr",
             "high",
             "letter",
             "off",
             "on",
             "range",
             "select",
             "slash",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"hdr_off_select",

          "tags":[
             "alphabet",
             "camera",
             "character",
             "circle",
             "disabled",
             "dynamic",
             "enabled",
             "font",
             "hdr",
             "high",
             "letter",
             "off",
             "on",
             "photo",
             "range",
             "select",
             "slash",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"hdr_on",

          "tags":[
             "add",
             "alphabet",
             "character",
             "dynamic",
             "enhance",
             "font",
             "hdr",
             "high",
             "letter",
             "on",
             "plus",
             "range",
             "select",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"hdr_on_select",

          "tags":[
             "+",
             "alphabet",
             "camera",
             "character",
             "circle",
             "dynamic",
             "font",
             "hdr",
             "high",
             "letter",
             "on",
             "photo",
             "range",
             "select",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"hdr_plus",

          "tags":[
             "+",
             "add",
             "alphabet",
             "character",
             "circle",
             "dynamic",
             "enhance",
             "font",
             "hdr",
             "high",
             "letter",
             "plus",
             "range",
             "select",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"hdr_strong",

          "tags":[
             "circles",
             "dots",
             "dynamic",
             "enhance",
             "hdr",
             "high",
             "range",
             "strong"
          ],

       },
       {
          "name":"hdr_weak",

          "tags":[
             "circles",
             "dots",
             "dynamic",
             "enhance",
             "hdr",
             "high",
             "range",
             "weak"
          ],

       },
       {
          "name":"headphones",

          "tags":[
             "accessory",
             "audio",
             "device",
             "ear",
             "earphone",
             "headphones",
             "headset",
             "listen",
             "music",
             "sound"
          ],

       },
       {
          "name":"headphones_battery",

          "tags":[
             "accessory",
             "audio",
             "battery",
             "charging",
             "device",
             "ear",
             "earphone",
             "headphones",
             "headset",
             "listen",
             "music",
             "sound"
          ],

       },
       {
          "name":"headset",

          "tags":[
             "accessory",
             "audio",
             "device",
             "ear",
             "earbuds",
             "earphone",
             "headphones",
             "headset",
             "listen",
             "music",
             "sound"
          ],

       },
       {
          "name":"headset_mic",

          "tags":[
             "accessory",
             "audio",
             "chat",
             "device",
             "ear",
             "earphone",
             "headphones",
             "headset",
             "listen",
             "mic",
             "music",
             "sound",
             "talk"
          ],

       },
       {
          "name":"headset_off",

          "tags":[
             "accessory",
             "audio",
             "chat",
             "device",
             "disabled",
             "ear",
             "earphone",
             "enabled",
             "headphones",
             "headset",
             "listen",
             "mic",
             "music",
             "off",
             "on",
             "slash",
             "sound",
             "talk"
          ],

       },
       {
          "name":"healing",

          "tags":[
             "bandage",
             "edit",
             "editing",
             "emergency",
             "fix",
             "healing",
             "hospital",
             "image",
             "medicine"
          ],

       },
       {
          "name":"health_and_safety",

          "tags":[
             "+",
             "add",
             "and",
             "certified",
             "health",
             "plus",
             "privacy",
             "private",
             "protect",
             "protection",
             "safety",
             "security",
             "shield",
             "symbol",
             "verified"
          ],

       },
       {
          "name":"hearing",

          "tags":[
             "accessibility",
             "accessible",
             "aid",
             "ear",
             "handicap",
             "hearing",
             "help",
             "impaired",
             "listen",
             "sound",
             "volume"
          ],

       },
       {
          "name":"hearing_disabled",

          "tags":[
             "accessibility",
             "accessible",
             "aid",
             "disabled",
             "ear",
             "enabled",
             "handicap",
             "hearing",
             "help",
             "impaired",
             "listen",
             "off",
             "on",
             "slash",
             "sound",
             "volume"
          ],

       },
       {
          "name":"height",

          "tags":[
             "arrow",
             "color",
             "doc",
             "down",
             "edit",
             "editing",
             "editor",
             "fill",
             "format",
             "height",
             "paint",
             "sheet",
             "spreadsheet",
             "style",
             "text",
             "type",
             "up",
             "writing"
          ],

       },
       {
          "name":"help",

          "tags":[
             "?",
             "assistance",
             "circle",
             "help",
             "info",
             "information",
             "punctuation",
             "question mark",
             "shape",
             "support",
             "symbol"
          ],

       },
       {
          "name":"help_center",

          "tags":[
             "?",
             "assistance",
             "center",
             "help",
             "info",
             "information",
             "punctuation",
             "question mark",
             "support",
             "symbol"
          ],

       },
       {
          "name":"help_outline",

          "tags":[
             "?",
             "assistance",
             "circle",
             "help",
             "info",
             "information",
             "outline",
             "punctuation",
             "question mark",
             "shape",
             "support",
             "symbol"
          ],

       },
       {
          "name":"hevc",

          "tags":[
             "alphabet",
             "character",
             "coding",
             "efficiency",
             "font",
             "hevc",
             "high",
             "letter",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"hide_image",

          "tags":[
             "disabled",
             "enabled",
             "hide",
             "image",
             "landscape",
             "mountain",
             "mountains",
             "off",
             "on",
             "photo",
             "photography",
             "picture",
             "slash"
          ],

       },
       {
          "name":"hide_source",

          "tags":[
             "circle",
             "disabled",
             "enabled",
             "hide",
             "off",
             "offline",
             "on",
             "shape",
             "slash",
             "source"
          ],

       },
       {
          "name":"high_quality",

          "tags":[
             "alphabet",
             "character",
             "definition",
             "display",
             "font",
             "high",
             "hq",
             "letter",
             "movie",
             "movies",
             "quality",
             "resolution",
             "screen",
             "symbol",
             "text",
             "tv",
             "type"
          ],

       },
       {
          "name":"highlight",

          "tags":[
             "color",
             "doc",
             "edit",
             "editing",
             "editor",
             "emphasize",
             "fill",
             "flash",
             "format",
             "highlight",
             "light",
             "paint",
             "sheet",
             "spreadsheet",
             "style",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"highlight_alt",

          "tags":[
             "alt",
             "arrow",
             "box",
             "click",
             "cursor",
             "draw",
             "focus",
             "highlight",
             "pointer",
             "select",
             "selection",
             "target"
          ],

       },
       {
          "name":"highlight_off",

          "tags":[
             "cancel",
             "close",
             "exit",
             "highlight",
             "no",
             "off",
             "quit",
             "remove",
             "stop",
             "x"
          ],

       },
       {
          "name":"hiking",

          "tags":[
             "backpacking",
             "bag",
             "climbing",
             "duffle",
             "hiking",
             "mountain",
             "social",
             "sports",
             "stick",
             "trail",
             "travel",
             "walking"
          ],

       },
       {
          "name":"history",

          "tags":[
             "arrow",
             "back",
             "backwards",
             "clock",
             "date",
             "history",
             "refresh",
             "renew",
             "reverse",
             "rotate",
             "schedule",
             "time",
             "turn"
          ],

       },
       {
          "name":"history_edu",

          "tags":[
             "document",
             "edu",
             "education",
             "feather",
             "history",
             "letter",
             "paper",
             "pen",
             "quill",
             "school",
             "story",
             "tools",
             "write",
             "writing"
          ],

       },
       {
          "name":"history_toggle_off",

          "tags":[
             "clock",
             "date",
             "history",
             "off",
             "schedule",
             "time",
             "toggle"
          ],

       },
       {
          "name":"holiday_village",

          "tags":[
             "architecture",
             "beach",
             "camping",
             "cottage",
             "estate",
             "holiday",
             "home",
             "house",
             "lake",
             "lodge",
             "maps",
             "place",
             "real",
             "residence",
             "residential",
             "stay",
             "traveling",
             "vacation",
             "village"
          ],

       },
       {
          "name":"home",

          "tags":[
             "address",
             "app",
             "application--house",
             "architecture",
             "building",
             "components",
             "design",
             "estate",
             "home",
             "homepage",
             "house",
             "interface",
             "layout",
             "place",
             "real",
             "residence",
             "residential",
             "screen",
             "shelter",
             "site",
             "structure",
             "ui",
             "unit",
             "ux",
             "web",
             "website",
             "window"
          ],

       },
       {
          "name":"home_max",

          "tags":[
             "device",
             "gadget",
             "hardware",
             "home",
             "internet",
             "iot",
             "max",
             "nest",
             "smart",
             "things"
          ],

       },
       {
          "name":"home_mini",

          "tags":[
             "Internet",
             "device",
             "gadget",
             "hardware",
             "home",
             "iot",
             "mini",
             "nest",
             "smart",
             "things"
          ],

       },
       {
          "name":"home_repair_service",

          "tags":[
             "box",
             "equipment",
             "fix",
             "home",
             "kit",
             "mechanic",
             "repair",
             "repairing",
             "service",
             "tool",
             "toolbox",
             "tools",
             "workshop"
          ],

       },
       {
          "name":"home_work",

          "tags":[
             "architecture",
             "building",
             "estate",
             "home",
             "place",
             "real",
             "residence",
             "residential",
             "shelter",
             "work"
          ],

       },
       {
          "name":"horizontal_distribute",

          "tags":[
             "alignment",
             "distribute",
             "format",
             "horizontal",
             "layout",
             "lines",
             "paragraph",
             "rule",
             "rules",
             "style",
             "text"
          ],

       },
       {
          "name":"horizontal_rule",

          "tags":[
             "gmail",
             "horizontal",
             "line",
             "novitas",
             "rule"
          ],

       },
       {
          "name":"horizontal_split",

          "tags":[
             "bars",
             "format",
             "horizontal",
             "layout",
             "lines",
             "split",
             "stacked"
          ],

       },
       {
          "name":"hot_tub",

          "tags":[
             "bath",
             "bathing",
             "bathroom",
             "bathtub",
             "hot",
             "hotel",
             "human",
             "jacuzzi",
             "person",
             "shower",
             "spa",
             "steam",
             "travel",
             "tub",
             "water"
          ],

       },
       {
          "name":"hotel",

          "tags":[
             "body",
             "hotel",
             "human",
             "people",
             "person",
             "sleep",
             "stay",
             "travel",
             "trip"
          ],

       },
       {
          "name":"hotel_class",

          "tags":[
             
          ],

       },
       {
          "name":"hourglass_bottom",

          "tags":[
             "bottom",
             "countdown",
             "half",
             "hourglass",
             "loading",
             "minute",
             "minutes",
             "time",
             "wait",
             "waiting"
          ],

       },
       {
          "name":"hourglass_disabled",

          "tags":[
             "clock",
             "countdown",
             "disabled",
             "empty",
             "enabled",
             "hourglass",
             "loading",
             "minute",
             "minutes",
             "off",
             "on",
             "slash",
             "time",
             "wait",
             "waiting"
          ],

       },
       {
          "name":"hourglass_empty",

          "tags":[
             "countdown",
             "empty",
             "hourglass",
             "loading",
             "minutes",
             "time",
             "wait",
             "waiting"
          ],

       },
       {
          "name":"hourglass_full",

          "tags":[
             "countdown",
             "full",
             "hourglass",
             "loading",
             "minutes",
             "time",
             "wait",
             "waiting"
          ],

       },
       {
          "name":"hourglass_top",

          "tags":[
             "countdown",
             "half",
             "hourglass",
             "loading",
             "minute",
             "minutes",
             "time",
             "top",
             "wait",
             "waiting"
          ],

       },
       {
          "name":"house",

          "tags":[
             "architecture",
             "building",
             "estate",
             "family",
             "home",
             "homepage",
             "house",
             "place",
             "places",
             "real",
             "residence",
             "residential",
             "shelter"
          ],

       },
       {
          "name":"house_siding",

          "tags":[
             "architecture",
             "building",
             "construction",
             "estate",
             "exterior",
             "facade",
             "home",
             "house",
             "real",
             "residential",
             "siding"
          ],

       },
       {
          "name":"houseboat",

          "tags":[
             "architecture",
             "beach",
             "boat",
             "estate",
             "floating",
             "home",
             "house",
             "houseboat",
             "maps",
             "place",
             "real",
             "residence",
             "residential",
             "sea",
             "stay",
             "traveling",
             "vacation"
          ],

       },
       {
          "name":"how_to_reg",

          "tags":[
             "approve",
             "ballot",
             "check",
             "complete",
             "done",
             "election",
             "how",
             "mark",
             "ok",
             "poll",
             "register",
             "registration",
             "select",
             "tick",
             "to reg",
             "validate",
             "verified",
             "vote",
             "yes"
          ],

       },
       {
          "name":"how_to_vote",

          "tags":[
             "ballot",
             "election",
             "how",
             "poll",
             "to",
             "vote"
          ],

       },
       {
          "name":"http",

          "tags":[
             "alphabet",
             "character",
             "font",
             "http",
             "letter",
             "symbol",
             "text",
             "transfer",
             "type",
             "url",
             "website"
          ],

       },
       {
          "name":"https",

          "tags":[
             "https",
             "lock",
             "locked",
             "password",
             "privacy",
             "private",
             "protection",
             "safety",
             "secure",
             "security"
          ],

       },
       {
          "name":"hvac",

          "tags":[
             "air",
             "conditioning",
             "heating",
             "hvac",
             "ventilation"
          ],

       },
       {
          "name":"ice_skating",

          "tags":[
             "athlete",
             "athletic",
             "entertainment",
             "exercise",
             "hobby",
             "ice",
             "shoe",
             "skates",
             "skating",
             "social",
             "sports",
             "travel"
          ],

       },
       {
          "name":"icecream",

          "tags":[
             "cream",
             "dessert",
             "food",
             "ice",
             "icecream",
             "snack"
          ],

       },
       {
          "name":"image",

          "tags":[
             "frame",
             "image",
             "landscape",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture"
          ],

       },
       {
          "name":"image_aspect_ratio",

          "tags":[
             "aspect",
             "image",
             "photo",
             "photography",
             "picture",
             "ratio",
             "rectangle",
             "square"
          ],

       },
       {
          "name":"image_not_supported",

          "tags":[
             "disabled",
             "enabled",
             "image",
             "landscape",
             "mountain",
             "mountains",
             "not",
             "off",
             "on",
             "photo",
             "photography",
             "picture",
             "slash",
             "supported"
          ],

       },
       {
          "name":"image_search",

          "tags":[
             "find",
             "glass",
             "image",
             "landscape",
             "look",
             "magnify",
             "magnifying",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture",
             "search",
             "see"
          ],

       },
       {
          "name":"imagesearch_roller",

          "tags":[
             "art",
             "image",
             "imagesearch",
             "paint",
             "roller",
             "search"
          ],

       },
       {
          "name":"import_contacts",

          "tags":[
             "address",
             "book",
             "contacts",
             "import",
             "info",
             "information",
             "open"
          ],

       },
       {
          "name":"import_export",

          "tags":[
             "arrow",
             "arrows",
             "direction",
             "down",
             "explort",
             "import",
             "up"
          ],

       },
       {
          "name":"important_devices",

          "tags":[
             "Android",
             "OS",
             "desktop",
             "devices",
             "hardware",
             "iOS",
             "important",
             "mobile",
             "monitor",
             "phone",
             "star",
             "tablet",
             "web"
          ],

       },
       {
          "name":"inbox",

          "tags":[
             "archive",
             "email",
             "inbox",
             "incoming",
             "mail",
             "message"
          ],

       },
       {
          "name":"incomplete_circle",

          "tags":[
             
          ],

       },
       {
          "name":"indeterminate_check_box",

          "tags":[
             "app",
             "application",
             "box",
             "button",
             "check",
             "components",
             "control",
             "design",
             "form",
             "indeterminate",
             "interface",
             "screen",
             "select",
             "selected",
             "selection",
             "site",
             "square",
             "toggle",
             "ui",
             "undetermined",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"info",

          "tags":[
             "alert",
             "announcment",
             "assistance",
             "details",
             "help",
             "i",
             "info",
             "information",
             "service",
             "support"
          ],

       },
       {
          "name":"input",

          "tags":[
             "arrow",
             "box",
             "download",
             "input",
             "login",
             "move",
             "right"
          ],

       },
       {
          "name":"insert_chart",

          "tags":[
             "analytics",
             "bar",
             "bars",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "insert",
             "measure",
             "metrics",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"insert_chart_outlined",

          "tags":[
             "analytics",
             "bar",
             "bars",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "insert",
             "measure",
             "metrics",
             "outlined",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"insert_comment",

          "tags":[
             "add",
             "bubble",
             "chat",
             "comment",
             "feedback",
             "insert",
             "message"
          ],

       },
       {
          "name":"insert_drive_file",

          "tags":[
             "doc",
             "drive",
             "file",
             "format",
             "insert",
             "sheet",
             "slide"
          ],

       },
       {
          "name":"insert_emoticon",

          "tags":[
             "account",
             "emoji",
             "emoticon",
             "face",
             "happy",
             "human",
             "insert",
             "people",
             "person",
             "profile",
             "sentiment",
             "smile",
             "user"
          ],

       },
       {
          "name":"insert_invitation",

          "tags":[
             "calendar",
             "date",
             "day",
             "event",
             "insert",
             "invitation",
             "mark",
             "month",
             "range",
             "remember",
             "reminder",
             "today",
             "week"
          ],

       },
       {
          "name":"insert_link",

          "tags":[
             "add",
             "attach",
             "clip",
             "file",
             "insert",
             "link",
             "mail",
             "media"
          ],

       },
       {
          "name":"insert_photo",

          "tags":[
             "image",
             "insert",
             "landscape",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture"
          ],

       },
       {
          "name":"insights",

          "tags":[
             "analytics",
             "bar",
             "bars",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "insights",
             "measure",
             "metrics",
             "stars",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"integration_instructions",

          "tags":[
             "brackets",
             "clipboard",
             "code",
             "css",
             "develop",
             "developer",
             "doc",
             "document",
             "engineer",
             "engineering clipboard",
             "html",
             "instructions",
             "integration",
             "platform"
          ],

       },
       {
          "name":"interests",

          "tags":[
             
          ],

       },
       {
          "name":"inventory",

          "tags":[
             "buy",
             "check",
             "clipboard",
             "commerce",
             "e-commerce",
             "inventory",
             "list",
             "purchase",
             "shop",
             "store"
          ],

       },
       {
          "name":"inventory_2",

          "tags":[
             "archive",
             "box",
             "file",
             "inventory",
             "organize",
             "packages",
             "product",
             "stock",
             "storage",
             "supply"
          ],

       },
       {
          "name":"invert_colors",

          "tags":[
             "colors",
             "drop",
             "droplet",
             "edit",
             "editing",
             "hue",
             "invert",
             "inverted",
             "palette",
             "tone",
             "water"
          ],

       },
       {
          "name":"invert_colors_off",

          "tags":[
             "colors",
             "disabled",
             "drop",
             "droplet",
             "enabled",
             "hue",
             "invert",
             "inverted",
             "off",
             "offline",
             "on",
             "opacity",
             "palette",
             "slash",
             "tone",
             "water"
          ],

       },
       {
          "name":"ios_share",

          "tags":[
             "arrow",
             "arrows",
             "button",
             "direction",
             "internet",
             "ios",
             "link",
             "send",
             "share",
             "sharing",
             "social",
             "up",
             "web",
             "website"
          ],

       },
       {
          "name":"iron",

          "tags":[
             "appliance",
             "clothes",
             "electric",
             "iron",
             "ironing",
             "machine",
             "object"
          ],

       },
       {
          "name":"iso",

          "tags":[
             "add",
             "edit",
             "editing",
             "effect",
             "image",
             "iso",
             "minus",
             "photography",
             "picture",
             "plus",
             "sensor",
             "shutter",
             "speed",
             "subtract"
          ],

       },
       {
          "name":"kayaking",

          "tags":[
             "athlete",
             "athletic",
             "body",
             "canoe",
             "entertainment",
             "exercise",
             "hobby",
             "human",
             "kayak",
             "kayaking",
             "lake",
             "paddle",
             "paddling",
             "people",
             "person",
             "rafting",
             "river",
             "row",
             "social",
             "sports",
             "summer",
             "travel",
             "water"
          ],

       },
       {
          "name":"keyboard",

          "tags":[
             "computer",
             "device",
             "hardware",
             "input",
             "keyboard",
             "keypad",
             "letter",
             "office",
             "text",
             "type"
          ],

       },
       {
          "name":"keyboard_alt",

          "tags":[
             "alt",
             "computer",
             "device",
             "hardware",
             "input",
             "keyboard",
             "keypad",
             "letter",
             "office",
             "text",
             "type"
          ],

       },
       {
          "name":"keyboard_arrow_down",

          "tags":[
             "arrow",
             "arrows",
             "down",
             "keyboard"
          ],

       },
       {
          "name":"keyboard_arrow_left",

          "tags":[
             "arrow",
             "arrows",
             "keyboard",
             "left"
          ],

       },
       {
          "name":"keyboard_arrow_right",

          "tags":[
             "arrow",
             "arrows",
             "keyboard",
             "right"
          ],

       },
       {
          "name":"keyboard_arrow_up",

          "tags":[
             "arrow",
             "arrows",
             "keyboard",
             "up"
          ],

       },
       {
          "name":"keyboard_backspace",

          "tags":[
             "arrow",
             "back",
             "backspace",
             "keyboard",
             "left"
          ],

       },
       {
          "name":"keyboard_capslock",

          "tags":[
             "arrow",
             "capslock",
             "keyboard",
             "up"
          ],

       },
       {
          "name":"keyboard_hide",

          "tags":[
             "arrow",
             "computer",
             "device",
             "down",
             "hardware",
             "hide",
             "input",
             "keyboard",
             "keypad",
             "text"
          ],

       },
       {
          "name":"keyboard_return",

          "tags":[
             "arrow",
             "back",
             "keyboard",
             "left",
             "return"
          ],

       },
       {
          "name":"keyboard_tab",

          "tags":[
             "arrow",
             "keyboard",
             "next",
             "right",
             "tab"
          ],

       },
       {
          "name":"keyboard_voice",

          "tags":[
             "keyboard",
             "mic",
             "microphone",
             "noise",
             "record",
             "recorder",
             "speaker",
             "voice"
          ],

       },
       {
          "name":"king_bed",

          "tags":[
             "bed",
             "bedroom",
             "double",
             "furniture",
             "home",
             "hotel",
             "house",
             "king",
             "night",
             "pillows",
             "queen",
             "rest",
             "room",
             "sleep"
          ],

       },
       {
          "name":"kitchen",

          "tags":[
             "appliance",
             "cold",
             "food",
             "fridge",
             "home",
             "house",
             "ice",
             "kitchen",
             "places",
             "refrigerator",
             "storage"
          ],

       },
       {
          "name":"kitesurfing",

          "tags":[
             "athlete",
             "athletic",
             "beach",
             "body",
             "entertainment",
             "exercise",
             "hobby",
             "human",
             "kitesurfing",
             "people",
             "person",
             "social",
             "sports",
             "surf",
             "travel",
             "water"
          ],

       },
       {
          "name":"label",

          "tags":[
             "favorite",
             "indent",
             "label",
             "library",
             "mail",
             "remember",
             "save",
             "stamp",
             "sticker",
             "tag"
          ],

       },
       {
          "name":"label_important",

          "tags":[
             "favorite",
             "important",
             "indent",
             "label",
             "library",
             "mail",
             "remember",
             "save",
             "stamp",
             "sticker",
             "tag",
             "wing"
          ],

       },
       {
          "name":"label_off",

          "tags":[
             "disabled",
             "enabled",
             "favorite",
             "indent",
             "label",
             "library",
             "mail",
             "off",
             "on",
             "remember",
             "save",
             "slash",
             "stamp",
             "sticker",
             "tag",
             "wing"
          ],

       },
       {
          "name":"landscape",

          "tags":[
             "image",
             "landscape",
             "mountain",
             "mountains",
             "nature",
             "photo",
             "photography",
             "picture"
          ],

       },
       {
          "name":"language",

          "tags":[
             "globe",
             "internet",
             "language",
             "planet",
             "website",
             "world",
             "www"
          ],

       },
       {
          "name":"laptop",

          "tags":[
             "Android",
             "OS",
             "chrome",
             "computer",
             "connect",
             "desktop",
             "device",
             "display",
             "hardware",
             "iOS",
             "laptop",
             "link",
             "mac",
             "monitor",
             "smart",
             "tv",
             "web",
             "windows"
          ],

       },
       {
          "name":"laptop_chromebook",

          "tags":[
             "Android",
             "OS",
             "chrome",
             "chromebook",
             "device",
             "display",
             "hardware",
             "iOS",
             "laptop",
             "mac chromebook",
             "monitor",
             "screen",
             "web",
             "window"
          ],

       },
       {
          "name":"laptop_mac",

          "tags":[
             "Android",
             "OS",
             "chrome",
             "device",
             "display",
             "hardware",
             "iOS",
             "laptop",
             "mac",
             "monitor",
             "screen",
             "web",
             "window"
          ],

       },
       {
          "name":"laptop_windows",

          "tags":[
             "Android",
             "OS",
             "chrome",
             "device",
             "display",
             "hardware",
             "iOS",
             "laptop",
             "mac",
             "monitor",
             "screen",
             "web",
             "window",
             "windows"
          ],

       },
       {
          "name":"last_page",

          "tags":[
             "app",
             "application",
             "arrow",
             "chevron",
             "components",
             "end",
             "forward",
             "interface",
             "last",
             "page",
             "right",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"launch",

          "tags":[
             "app",
             "application",
             "arrow",
             "box",
             "components",
             "interface",
             "launch",
             "new",
             "open",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website",
             "window"
          ],

       },
       {
          "name":"layers",

          "tags":[
             "arrange",
             "disabled",
             "enabled",
             "interaction",
             "layers",
             "maps",
             "off",
             "on",
             "overlay",
             "pages",
             "slash",
             "stack"
          ],

       },
       {
          "name":"layers_clear",

          "tags":[
             "arrange",
             "clear",
             "delete",
             "disabled",
             "enabled",
             "interaction",
             "layers",
             "maps",
             "off",
             "on",
             "overlay",
             "pages",
             "slash"
          ],

       },
       {
          "name":"leaderboard",

          "tags":[
             "analytics",
             "bar",
             "bars",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "leaderboard",
             "measure",
             "metrics",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"leak_add",

          "tags":[
             "add",
             "connection",
             "data",
             "leak",
             "link",
             "network",
             "service",
             "signals",
             "synce",
             "wireless"
          ],

       },
       {
          "name":"leak_remove",

          "tags":[
             "connection",
             "data",
             "disabled",
             "enabled",
             "leak",
             "link",
             "network",
             "off",
             "offline",
             "on",
             "remove",
             "service",
             "signals",
             "slash",
             "synce",
             "wireless"
          ],

       },
       {
          "name":"legend_toggle",

          "tags":[
             "analytics",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "legend",
             "measure",
             "metrics",
             "monitoring",
             "stackdriver",
             "statistics",
             "toggle",
             "tracking"
          ],

       },
       {
          "name":"lens",

          "tags":[
             "circle",
             "full",
             "geometry",
             "lens",
             "moon"
          ],

       },
       {
          "name":"lens_blur",

          "tags":[
             "blur",
             "camera",
             "dim",
             "dot",
             "effect",
             "foggy",
             "fuzzy",
             "image",
             "lens",
             "photo",
             "soften"
          ],

       },
       {
          "name":"library_add",

          "tags":[
             "+",
             "add",
             "collection",
             "layers",
             "library",
             "multiple",
             "music",
             "new",
             "plus",
             "save",
             "stacked",
             "symbol",
             "video"
          ],

       },
       {
          "name":"library_add_check",

          "tags":[
             "add",
             "approve",
             "check",
             "collection",
             "complete",
             "done",
             "layers",
             "library",
             "mark",
             "multiple",
             "music",
             "ok",
             "select",
             "stacked",
             "tick",
             "validate",
             "verified",
             "video",
             "yes"
          ],

       },
       {
          "name":"library_books",

          "tags":[
             "add",
             "album",
             "audio",
             "book",
             "books",
             "collection",
             "library",
             "read",
             "reading"
          ],

       },
       {
          "name":"library_music",

          "tags":[
             "add",
             "album",
             "audio",
             "collection",
             "library",
             "music",
             "song",
             "sounds"
          ],

       },
       {
          "name":"light",

          "tags":[
             "bulb",
             "ceiling",
             "hanging",
             "inside",
             "interior",
             "lamp",
             "light",
             "lighting",
             "pendent",
             "room"
          ],

       },
       {
          "name":"light_mode",

          "tags":[
             "bright",
             "brightness",
             "day",
             "device",
             "light",
             "lighting",
             "mode",
             "mornng",
             "sky",
             "sun",
             "sunny"
          ],

       },
       {
          "name":"lightbulb",

          "tags":[
             "alert",
             "announcement",
             "idea",
             "info",
             "information",
             "learning",
             "light",
             "lightbulb",
             "mode",
             "on"
          ],

       },
       {
          "name":"line_style",

          "tags":[
             "dash",
             "dotted",
             "line",
             "rule",
             "spacing",
             "style"
          ],

       },
       {
          "name":"line_weight",

          "tags":[
             "height",
             "line",
             "size",
             "spacing",
             "style",
             "thickness",
             "weight"
          ],

       },
       {
          "name":"linear_scale",

          "tags":[
             "app",
             "application",
             "components",
             "design",
             "interface",
             "layout",
             "linear",
             "measure",
             "menu",
             "scale",
             "screen",
             "site",
             "slider",
             "ui",
             "ux",
             "web",
             "website",
             "window"
          ],

       },
       {
          "name":"link",

          "tags":[
             "chain",
             "clip",
             "connection",
             "external",
             "hyperlink",
             "link",
             "linked",
             "links",
             "multimedia",
             "unlisted",
             "url"
          ],

       },
       {
          "name":"link_off",

          "tags":[
             "attached",
             "chain",
             "clip",
             "connection",
             "disabled",
             "enabled",
             "link",
             "linked",
             "links",
             "multimedia",
             "off",
             "on",
             "slash",
             "unlink",
             "url"
          ],

       },
       {
          "name":"linked_camera",

          "tags":[
             "camera",
             "connect",
             "connection",
             "lens",
             "linked",
             "network",
             "photo",
             "photography",
             "picture",
             "signal",
             "signals",
             "sync",
             "wireless"
          ],

       },
       {
          "name":"liquor",

          "tags":[
             "alcohol",
             "bar",
             "bottle",
             "club",
             "cocktail",
             "drink",
             "food",
             "liquor",
             "party",
             "store",
             "wine"
          ],

       },
       {
          "name":"list",

          "tags":[
             "file",
             "format",
             "index",
             "list",
             "menu",
             "options"
          ],

       },
       {
          "name":"list_alt",

          "tags":[
             "alt",
             "box",
             "contained",
             "format",
             "lines",
             "list",
             "order",
             "reorder",
             "stacked",
             "title"
          ],

       },
       {
          "name":"live_help",

          "tags":[
             "?",
             "assistance",
             "bubble",
             "chat",
             "comment",
             "communicate",
             "help",
             "info",
             "information",
             "live",
             "message",
             "punctuation",
             "question mark",
             "speech",
             "support",
             "symbol"
          ],

       },
       {
          "name":"live_tv",

          "tags":[
             "Android",
             "OS",
             "antennas hardware",
             "chrome",
             "desktop",
             "device",
             "iOS",
             "live",
             "mac",
             "monitor",
             "movie",
             "play",
             "stream",
             "television",
             "tv",
             "web",
             "window"
          ],

       },
       {
          "name":"living",

          "tags":[
             "chair",
             "comfort",
             "couch",
             "decoration",
             "furniture",
             "home",
             "house",
             "living",
             "lounging",
             "loveseat",
             "room",
             "seat",
             "seating",
             "sofa"
          ],

       },
       {
          "name":"local_activity",

          "tags":[
             "activity",
             "event",
             "local",
             "star",
             "things",
             "ticket"
          ],

       },
       {
          "name":"local_airport",

          "tags":[
             "air",
             "airplane",
             "airport",
             "flight",
             "plane",
             "transportation",
             "travel",
             "trip"
          ],

       },
       {
          "name":"local_atm",

          "tags":[
             "atm",
             "bill",
             "card",
             "cart",
             "cash",
             "coin",
             "commerce",
             "credit",
             "currency",
             "dollars",
             "local",
             "money",
             "online",
             "pay",
             "payment",
             "shopping",
             "symbol"
          ],

       },
       {
          "name":"local_bar",

          "tags":[
             "alcohol",
             "bar",
             "bottle",
             "club",
             "cocktail",
             "drink",
             "food",
             "liquor",
             "local",
             "wine"
          ],

       },
       {
          "name":"local_cafe",

          "tags":[
             "bottle",
             "cafe",
             "coffee",
             "cup",
             "drink",
             "food",
             "restaurant",
             "tea"
          ],

       },
       {
          "name":"local_car_wash",

          "tags":[
             "automobile",
             "car",
             "cars",
             "local",
             "maps",
             "transportation",
             "travel",
             "vehicle",
             "wash"
          ],

       },
       {
          "name":"local_convenience_store",

          "tags":[
             "--",
             "24",
             "bill",
             "building",
             "business",
             "card",
             "cash",
             "coin",
             "commerce",
             "company",
             "convenience",
             "credit",
             "currency",
             "dollars",
             "local",
             "maps",
             "market",
             "money",
             "new",
             "online",
             "pay",
             "payment",
             "plus",
             "shop",
             "shopping",
             "store",
             "storefront",
             "symbol"
          ],

       },
       {
          "name":"local_dining",

          "tags":[
             "dining",
             "eat",
             "food",
             "fork",
             "knife",
             "local",
             "meal",
             "restaurant",
             "spoon"
          ],

       },
       {
          "name":"local_drink",

          "tags":[
             "cup",
             "drink",
             "drop",
             "droplet",
             "liquid",
             "local",
             "park",
             "water"
          ],

       },
       {
          "name":"local_fire_department",

          "tags":[
             "911",
             "department",
             "fire",
             "firefighter",
             "flame",
             "hot"
          ],

       },
       {
          "name":"local_florist",

          "tags":[
             "florist",
             "flower",
             "local",
             "shop"
          ],

       },
       {
          "name":"local_gas_station",

          "tags":[
             "auto",
             "car",
             "gas",
             "local",
             "oil",
             "station",
             "vehicle"
          ],

       },
       {
          "name":"local_grocery_store",

          "tags":[
             "grocery",
             "market",
             "shop",
             "store"
          ],

       },
       {
          "name":"local_hospital",

          "tags":[
             "911",
             "aid",
             "cross",
             "emergency",
             "first",
             "hospital",
             "local",
             "medicine"
          ],

       },
       {
          "name":"local_hotel",

          "tags":[
             
          ],

       },
       {
          "name":"local_laundry_service",

          "tags":[
             "cleaning",
             "clothing",
             "dry",
             "dryer",
             "hotel",
             "laundry",
             "local",
             "service",
             "washer"
          ],

       },
       {
          "name":"local_library",

          "tags":[
             "book",
             "community learning",
             "library",
             "local",
             "read"
          ],

       },
       {
          "name":"local_mall",

          "tags":[
             "bag",
             "bill",
             "building",
             "business",
             "buy",
             "card",
             "cart",
             "cash",
             "coin",
             "commerce",
             "credit",
             "currency",
             "dollars",
             "handbag",
             "local",
             "mall",
             "money",
             "online",
             "pay",
             "payment",
             "shop",
             "shopping",
             "store",
             "storefront"
          ],

       },
       {
          "name":"local_movies",

          "tags":[
             
          ],

       },
       {
          "name":"local_offer",

          "tags":[
             "deal",
             "discount",
             "offer",
             "price",
             "shop",
             "shopping",
             "store",
             "tag"
          ],

       },
       {
          "name":"local_parking",

          "tags":[
             "alphabet",
             "auto",
             "car",
             "character",
             "font",
             "garage",
             "letter",
             "local",
             "park",
             "parking",
             "symbol",
             "text",
             "type",
             "vehicle"
          ],

       },
       {
          "name":"local_pharmacy",

          "tags":[
             "911",
             "aid",
             "cross",
             "emergency",
             "first",
             "hospital",
             "local",
             "medicine",
             "pharmacy",
             "places"
          ],

       },
       {
          "name":"local_phone",

          "tags":[
             "booth",
             "call",
             "communication",
             "phone",
             "telecommunication"
          ],

       },
       {
          "name":"local_pizza",

          "tags":[
             "drink",
             "fastfood",
             "food",
             "local",
             "meal",
             "pizza"
          ],

       },
       {
          "name":"local_play",

          "tags":[
             
          ],

       },
       {
          "name":"local_police",

          "tags":[
             "911",
             "badge",
             "law",
             "local",
             "officer",
             "police",
             "protect",
             "protection",
             "security",
             "shield"
          ],

       },
       {
          "name":"local_post_office",

          "tags":[
             "delivery",
             "email",
             "envelop",
             "letter",
             "local",
             "mail",
             "message",
             "office",
             "package",
             "parcel",
             "post",
             "postal",
             "send",
             "stamp"
          ],

       },
       {
          "name":"local_printshop",

          "tags":[
             "draft",
             "fax",
             "ink",
             "local",
             "machine",
             "office",
             "paper",
             "print",
             "printer",
             "printshop",
             "send"
          ],

       },
       {
          "name":"local_see",

          "tags":[
             "camera",
             "lens",
             "local",
             "photo",
             "photography",
             "picture",
             "see"
          ],

       },
       {
          "name":"local_shipping",

          "tags":[
             "automobile",
             "car",
             "cars",
             "delivery",
             "letter",
             "local",
             "mail",
             "maps",
             "office",
             "package",
             "parcel",
             "post",
             "postal",
             "send",
             "shipping",
             "shopping",
             "stamp",
             "transportation",
             "truck",
             "vehicle"
          ],

       },
       {
          "name":"local_taxi",

          "tags":[
             "automobile",
             "cab",
             "call",
             "car",
             "cars",
             "direction",
             "local",
             "lyft",
             "maps",
             "public",
             "taxi",
             "transportation",
             "uber",
             "vehicle",
             "yellow"
          ],

       },
       {
          "name":"location_city",

          "tags":[
             "apartments",
             "architecture",
             "buildings",
             "business",
             "city",
             "estate",
             "home",
             "landscape",
             "location",
             "place",
             "real",
             "residence",
             "residential",
             "shelter",
             "town",
             "urban"
          ],

       },
       {
          "name":"location_disabled",

          "tags":[
             "destination",
             "direction",
             "disabled",
             "enabled",
             "location",
             "maps",
             "off",
             "on",
             "pin",
             "place",
             "pointer",
             "slash",
             "stop",
             "tracking"
          ],

       },
       {
          "name":"location_off",

          "tags":[
             "destination",
             "direction",
             "disabled",
             "enabled",
             "location",
             "maps",
             "off",
             "on",
             "pin",
             "place",
             "slash",
             "stop"
          ],

       },
       {
          "name":"location_on",

          "tags":[
             "destination",
             "direction",
             "disabled",
             "enabled",
             "location",
             "maps",
             "off",
             "on",
             "pin",
             "place",
             "slash",
             "stop"
          ],

       },
       {
          "name":"location_searching",

          "tags":[
             "destination",
             "direction",
             "location",
             "maps",
             "pin",
             "place",
             "pointer",
             "searching",
             "stop",
             "tracking"
          ],

       },
       {
          "name":"lock",

          "tags":[
             "lock",
             "locked",
             "password",
             "privacy",
             "private",
             "protection",
             "safety",
             "secure",
             "security"
          ],

       },
       {
          "name":"lock_clock",

          "tags":[
             "clock",
             "date",
             "lock",
             "locked",
             "password",
             "privacy",
             "private",
             "protection",
             "safety",
             "schedule",
             "secure",
             "security",
             "time"
          ],

       },
       {
          "name":"lock_open",

          "tags":[
             "lock",
             "open",
             "password",
             "privacy",
             "private",
             "protection",
             "safety",
             "secure",
             "security",
             "unlocked"
          ],

       },
       {
          "name":"login",

          "tags":[
             "access",
             "app",
             "application",
             "arrow",
             "components",
             "design",
             "enter",
             "in",
             "interface",
             "left",
             "log",
             "login",
             "screen",
             "sign",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"logout",

          "tags":[
             "app",
             "application",
             "arrow",
             "components",
             "design",
             "exit",
             "interface",
             "leave",
             "log",
             "login",
             "logout",
             "right",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"looks",

          "tags":[
             "circle",
             "half",
             "looks",
             "rainbow"
          ],

       },
       {
          "name":"looks_3",

          "tags":[
             "3",
             "digit",
             "looks",
             "numbers",
             "square",
             "symbol"
          ],

       },
       {
          "name":"looks_4",

          "tags":[
             "4",
             "digit",
             "looks",
             "numbers",
             "square",
             "symbol"
          ],

       },
       {
          "name":"looks_5",

          "tags":[
             "5",
             "digit",
             "looks",
             "numbers",
             "square",
             "symbol"
          ],

       },
       {
          "name":"looks_6",

          "tags":[
             "6",
             "digit",
             "looks",
             "numbers",
             "square",
             "symbol"
          ],

       },
       {
          "name":"looks_one",

          "tags":[
             "1",
             "digit",
             "looks",
             "numbers",
             "square",
             "symbol"
          ],

       },
       {
          "name":"looks_two",

          "tags":[
             "2",
             "digit",
             "looks",
             "numbers",
             "square",
             "symbol"
          ],

       },
       {
          "name":"loop",

          "tags":[
             "around",
             "arrow",
             "arrows",
             "direction",
             "inprogress",
             "load",
             "loading refresh",
             "loop",
             "music",
             "navigation",
             "renew",
             "rotate",
             "turn"
          ],

       },
       {
          "name":"loupe",

          "tags":[
             "+",
             "add",
             "details",
             "focus",
             "glass",
             "loupe",
             "magnifying",
             "new",
             "plus",
             "symbol"
          ],

       },
       {
          "name":"low_priority",

          "tags":[
             "arrange",
             "arrow",
             "backward",
             "bottom",
             "list",
             "low",
             "move",
             "order",
             "priority"
          ],

       },
       {
          "name":"loyalty",

          "tags":[
             "card",
             "credit",
             "loyalty",
             "membership",
             "miles",
             "points",
             "program",
             "subscription heart",
             "tag",
             "travel",
             "trip"
          ],

       },
       {
          "name":"lte_mobiledata",

          "tags":[
             "alphabet",
             "character",
             "data",
             "font",
             "internet",
             "letter",
             "lte",
             "mobile",
             "network",
             "speed",
             "symbol",
             "text",
             "type",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"lte_plus_mobiledata",

          "tags":[
             "+",
             "alphabet",
             "character",
             "data",
             "font",
             "internet",
             "letter",
             "lte",
             "mobile",
             "network",
             "plus",
             "speed",
             "symbol",
             "text",
             "type",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"luggage",

          "tags":[
             "airport",
             "bag",
             "baggage",
             "carry",
             "flight",
             "hotel",
             "luggage",
             "on",
             "suitcase",
             "travel",
             "trip"
          ],

       },
       {
          "name":"lunch_dining",

          "tags":[
             "breakfast",
             "dining",
             "dinner",
             "drink",
             "fastfood",
             "food",
             "hamburger",
             "lunch",
             "meal"
          ],

       },
       {
          "name":"mail",

          "tags":[
             "email",
             "envelop",
             "envelope",
             "inbox",
             "letter",
             "mail",
             "message",
             "send"
          ],

       },
       {
          "name":"mail_outline",

          "tags":[
             "email",
             "envelop",
             "letter",
             "mail",
             "message",
             "outline",
             "send"
          ],

       },
       {
          "name":"male",

          "tags":[
             "boy",
             "gender",
             "male",
             "man",
             "social",
             "symbol"
          ],

       },
       {
          "name":"manage_accounts",

          "tags":[
             "accounts",
             "change",
             "details service-human",
             "face",
             "gear",
             "manage",
             "options",
             "people",
             "person",
             "profile",
             "settings",
             "user"
          ],

       },
       {
          "name":"manage_search",

          "tags":[
             "glass",
             "history",
             "magnifying",
             "manage",
             "search",
             "text"
          ],

       },
       {
          "name":"map",

          "tags":[
             "destination",
             "direction",
             "location",
             "map",
             "maps",
             "pin",
             "place",
             "route",
             "stop",
             "travel"
          ],

       },
       {
          "name":"maps_home_work",

          "tags":[
             "building",
             "home",
             "house",
             "maps",
             "office",
             "work"
          ],

       },
       {
          "name":"maps_ugc",

          "tags":[
             "+",
             "add",
             "bubble",
             "comment",
             "communicate",
             "feedback",
             "maps",
             "message",
             "new",
             "plus",
             "speech",
             "symbol",
             "ugc"
          ],

       },
       {
          "name":"margin",

          "tags":[
             "design",
             "layout",
             "margin",
             "padding",
             "size",
             "square"
          ],

       },
       {
          "name":"mark_as_unread",

          "tags":[
             "as",
             "envelop",
             "letter",
             "mail",
             "mark",
             "post",
             "postal",
             "read",
             "receive",
             "send",
             "unread"
          ],

       },
       {
          "name":"mark_chat_read",

          "tags":[
             "approve",
             "bubble",
             "chat",
             "check",
             "comment",
             "communicate",
             "complete",
             "done",
             "mark",
             "message",
             "ok",
             "read",
             "select",
             "sent",
             "speech",
             "tick",
             "verified",
             "yes"
          ],

       },
       {
          "name":"mark_chat_unread",

          "tags":[
             "bubble",
             "chat",
             "circle",
             "comment",
             "communicate",
             "mark",
             "message",
             "notification",
             "speech",
             "unread"
          ],

       },
       {
          "name":"mark_email_read",

          "tags":[
             "approve",
             "check",
             "complete",
             "done",
             "email",
             "envelop",
             "letter",
             "mail",
             "mark",
             "message",
             "note",
             "ok",
             "read",
             "select",
             "send",
             "sent",
             "tick",
             "yes"
          ],

       },
       {
          "name":"mark_email_unread",

          "tags":[
             "check",
             "circle",
             "email",
             "envelop",
             "letter",
             "mail",
             "mark",
             "message",
             "note",
             "notification",
             "send",
             "unread"
          ],

       },
       {
          "name":"markunread",

          "tags":[
             "email",
             "envelop",
             "letter",
             "mail",
             "markunread",
             "message",
             "send",
             "unread"
          ],

       },
       {
          "name":"markunread_mailbox",

          "tags":[
             "deliver",
             "envelop",
             "letter",
             "mail",
             "mailbox",
             "markunread",
             "post",
             "postal",
             "postbox",
             "receive",
             "send",
             "unread"
          ],

       },
       {
          "name":"masks",

          "tags":[
             "air",
             "cover",
             "covid",
             "face",
             "hospital",
             "masks",
             "medical",
             "pollution",
             "protection",
             "respirator",
             "sick",
             "social"
          ],

       },
       {
          "name":"maximize",

          "tags":[
             "app",
             "application",
             "components",
             "design",
             "interface",
             "line",
             "maximize",
             "screen",
             "shape",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"media_bluetooth_off",

          "tags":[
             "bluetooth",
             "connect",
             "connection",
             "connectivity",
             "device",
             "disabled",
             "enabled",
             "media",
             "music",
             "note",
             "off",
             "offline",
             "on",
             "paring",
             "signal",
             "slash",
             "symbol",
             "wireless"
          ],

       },
       {
          "name":"media_bluetooth_on",

          "tags":[
             "bluetooth",
             "connect",
             "connection",
             "connectivity",
             "device",
             "disabled",
             "enabled",
             "media",
             "music",
             "note",
             "off",
             "on",
             "online",
             "paring",
             "signal",
             "slash",
             "symbol",
             "wireless"
          ],

       },
       {
          "name":"mediation",

          "tags":[
             "alternative",
             "compromise",
             "mediation",
             "negotiation",
             "party",
             "structure"
          ],

       },
       {
          "name":"medical_services",

          "tags":[
             "aid",
             "bag",
             "briefcase",
             "emergency",
             "first",
             "kit",
             "medical",
             "medicine",
             "services"
          ],

       },
       {
          "name":"medication",

          "tags":[
             "doctor",
             "drug",
             "emergency",
             "hospital",
             "medication",
             "medicine",
             "pharmacy",
             "pills",
             "prescription"
          ],

       },
       {
          "name":"meeting_room",

          "tags":[
             "building",
             "door",
             "doorway",
             "entrance",
             "home",
             "house",
             "interior",
             "meeting",
             "office",
             "open",
             "places",
             "room"
          ],

       },
       {
          "name":"memory",

          "tags":[
             "card",
             "chip",
             "digital",
             "memory",
             "micro",
             "processor",
             "sd",
             "storage"
          ],

       },
       {
          "name":"menu",

          "tags":[
             "app",
             "application",
             "components",
             "hamburger",
             "interface",
             "line",
             "lines",
             "menu",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"menu_book",

          "tags":[
             "book",
             "dining",
             "food",
             "meal",
             "menu",
             "restaurant"
          ],

       },
       {
          "name":"menu_open",

          "tags":[
             "app",
             "application",
             "arrow",
             "components",
             "hamburger",
             "interface",
             "left",
             "line",
             "lines",
             "menu",
             "open",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"merge_type",

          "tags":[
             "arrow",
             "combine",
             "direction",
             "format",
             "merge",
             "text",
             "type"
          ],

       },
       {
          "name":"message",

          "tags":[
             "bubble",
             "chat",
             "comment",
             "communicate",
             "feedback",
             "message",
             "speech"
          ],

       },
       {
          "name":"mic",

          "tags":[
             "hear",
             "hearing",
             "mic",
             "microphone",
             "noise",
             "record",
             "search",
             "sound",
             "speech",
             "voice"
          ],

       },
       {
          "name":"mic_external_off",

          "tags":[
             "audio",
             "disabled",
             "enabled",
             "external",
             "mic",
             "microphone",
             "off",
             "on",
             "slash",
             "sound",
             "voice"
          ],

       },
       {
          "name":"mic_external_on",

          "tags":[
             "audio",
             "disabled",
             "enabled",
             "external",
             "mic",
             "microphone",
             "off",
             "on",
             "slash",
             "sound",
             "voice"
          ],

       },
       {
          "name":"mic_none",

          "tags":[
             "hear",
             "hearing",
             "mic",
             "microphone",
             "noise",
             "none",
             "record",
             "sound",
             "voice"
          ],

       },
       {
          "name":"mic_off",

          "tags":[
             "audio",
             "disabled",
             "enabled",
             "hear",
             "hearing",
             "mic",
             "microphone",
             "noise",
             "off",
             "on",
             "record",
             "recording",
             "slash",
             "sound",
             "voice"
          ],

       },
       {
          "name":"microwave",

          "tags":[
             "appliance",
             "cooking",
             "electric",
             "heat",
             "home",
             "house",
             "kitchen",
             "machine",
             "microwave"
          ],

       },
       {
          "name":"military_tech",

          "tags":[
             "army",
             "award",
             "badge",
             "honor",
             "medal",
             "merit",
             "military",
             "order",
             "privilege",
             "prize",
             "rank",
             "reward",
             "ribbon",
             "soldier",
             "star",
             "status",
             "tech",
             "trophy",
             "win",
             "winner"
          ],

       },
       {
          "name":"minimize",

          "tags":[
             "app",
             "application",
             "components",
             "design",
             "interface",
             "line",
             "minimize",
             "screen",
             "shape",
             "site",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"miscellaneous_services",

          "tags":[
             
          ],

       },
       {
          "name":"missed_video_call",

          "tags":[
             "arrow",
             "call",
             "camera",
             "film",
             "filming",
             "hardware",
             "image",
             "missed",
             "motion",
             "picture",
             "record",
             "video",
             "videography"
          ],

       },
       {
          "name":"mms",

          "tags":[
             "bubble",
             "chat",
             "comment",
             "communicate",
             "feedback",
             "image",
             "landscape",
             "message",
             "mms",
             "mountain",
             "mountains",
             "multimedia",
             "photo",
             "photography",
             "picture",
             "speech"
          ],

       },
       {
          "name":"mobile_friendly",

          "tags":[
             "Android",
             "OS",
             "approve",
             "cell",
             "check",
             "complete",
             "device",
             "done",
             "friendly",
             "hardware",
             "iOS",
             "mark",
             "mobile",
             "ok",
             "phone",
             "select",
             "tablet",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"mobile_off",

          "tags":[
             "Android",
             "OS",
             "cell",
             "device",
             "disabled",
             "enabled",
             "hardware",
             "iOS",
             "mobile",
             "off",
             "on",
             "phone",
             "silence",
             "slash",
             "tablet"
          ],

       },
       {
          "name":"mobile_screen_share",

          "tags":[
             "Android",
             "OS",
             "cast",
             "cell",
             "device",
             "hardware",
             "iOS",
             "mirror",
             "mobile",
             "monitor",
             "phone",
             "screen",
             "screencast",
             "share",
             "stream",
             "streaming",
             "tablet",
             "tv",
             "wireless"
          ],

       },
       {
          "name":"mobiledata_off",

          "tags":[
             "arrow",
             "data",
             "disabled",
             "down",
             "enabled",
             "internet",
             "mobile",
             "network",
             "off",
             "on",
             "slash",
             "speed",
             "up",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"mode",

          "tags":[
             "compose",
             "create",
             "draft",
             "draw",
             "edit",
             "mode",
             "pen",
             "pencil",
             "write"
          ],

       },
       {
          "name":"mode_comment",

          "tags":[
             "bubble",
             "chat",
             "comment",
             "communicate",
             "feedback",
             "message",
             "mode comment",
             "speech"
          ],

       },
       {
          "name":"mode_edit",

          "tags":[
             "compose",
             "create",
             "draft",
             "draw",
             "edit",
             "mode",
             "pen",
             "pencil",
             "write"
          ],

       },
       {
          "name":"mode_edit_outline",

          "tags":[
             "compose",
             "create",
             "draft",
             "draw",
             "edit",
             "mode",
             "outline",
             "pen",
             "pencil",
             "write"
          ],

       },
       {
          "name":"mode_night",

          "tags":[
             "dark",
             "disturb",
             "mode",
             "moon",
             "night",
             "sleep",
             "weather"
          ],

       },
       {
          "name":"mode_of_travel",

          "tags":[
             
          ],

       },
       {
          "name":"mode_standby",

          "tags":[
             "disturb",
             "mode",
             "power",
             "sleep",
             "standby",
             "target"
          ],

       },
       {
          "name":"model_training",

          "tags":[
             "arrow",
             "bulb",
             "idea",
             "inprogress",
             "light",
             "load",
             "loading",
             "model",
             "refresh",
             "renew",
             "restore",
             "reverse",
             "rotate",
             "training"
          ],

       },
       {
          "name":"monetization_on",

          "tags":[
             "bill",
             "card",
             "cash",
             "circle",
             "coin",
             "commerce",
             "cost",
             "credit",
             "currency",
             "dollars",
             "finance",
             "monetization",
             "money",
             "on",
             "online",
             "pay",
             "payment",
             "shopping",
             "symbol"
          ],

       },
       {
          "name":"money",

          "tags":[
             "100",
             "bill",
             "card",
             "cash",
             "coin",
             "commerce",
             "cost",
             "credit",
             "currency",
             "digit",
             "dollars",
             "finance",
             "money",
             "number",
             "online",
             "pay",
             "payment",
             "price",
             "shopping",
             "symbol"
          ],

       },
       {
          "name":"money_off",

          "tags":[
             "bill",
             "card",
             "cart",
             "cash",
             "coin",
             "commerce",
             "credit",
             "currency",
             "disabled",
             "dollars",
             "enabled",
             "money",
             "off",
             "on",
             "online",
             "pay",
             "payment",
             "shopping",
             "slash",
             "symbol"
          ],

       },
       {
          "name":"money_off_csred",

          "tags":[
             "bill",
             "card",
             "cart",
             "cash",
             "coin",
             "commerce",
             "credit",
             "csred",
             "currency",
             "disabled",
             "dollars",
             "enabled",
             "money",
             "off",
             "on",
             "online",
             "pay",
             "payment",
             "shopping",
             "slash",
             "symbol"
          ],

       },
       {
          "name":"monitor",

          "tags":[
             "Android",
             "OS",
             "chrome",
             "device",
             "display",
             "hardware",
             "iOS",
             "mac",
             "monitor",
             "screen",
             "web",
             "window"
          ],

       },
       {
          "name":"monitor_weight",

          "tags":[
             "body",
             "device",
             "diet",
             "health",
             "monitor",
             "scale",
             "smart",
             "weight"
          ],

       },
       {
          "name":"monochrome_photos",

          "tags":[
             "black",
             "camera",
             "image",
             "monochrome",
             "photo",
             "photography",
             "photos",
             "picture",
             "white"
          ],

       },
       {
          "name":"mood",

          "tags":[
             "emoji",
             "emotions",
             "expressions",
             "face",
             "feelings",
             "glad",
             "happiness",
             "happy",
             "like",
             "mood",
             "person",
             "pleased",
             "smile",
             "smiling",
             "social",
             "survey"
          ],

       },
       {
          "name":"mood_bad",

          "tags":[
             "bad",
             "disappointment",
             "dislike",
             "emoji",
             "emotions",
             "expressions",
             "face",
             "feelings",
             "mood",
             "person",
             "rating",
             "social",
             "survey",
             "unhappiness",
             "unhappy",
             "unpleased",
             "unsmile",
             "unsmiling"
          ],

       },
       {
          "name":"moped",

          "tags":[
             "automobile",
             "car",
             "cars",
             "direction",
             "maps",
             "moped",
             "motorized",
             "public",
             "scooter",
             "transportation",
             "vehicle",
             "vespa"
          ],

       },
       {
          "name":"more",

          "tags":[
             "3",
             "archive",
             "bookmark",
             "dots",
             "etc",
             "favorite",
             "indent",
             "label",
             "more",
             "remember",
             "save",
             "stamp",
             "sticker",
             "tab",
             "tag",
             "three"
          ],

       },
       {
          "name":"more_horiz",

          "tags":[
             "3",
             "app",
             "application",
             "components",
             "dots",
             "etc",
             "horiz",
             "horizontal",
             "interface",
             "ios",
             "more",
             "screen",
             "site",
             "three",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"more_time",

          "tags":[
             "+",
             "add",
             "clock",
             "date",
             "more",
             "new",
             "plus",
             "schedule",
             "symbol",
             "time"
          ],

       },
       {
          "name":"more_vert",

          "tags":[
             "3",
             "android",
             "app",
             "application",
             "components",
             "dots",
             "etc",
             "interface",
             "more",
             "screen",
             "site",
             "three",
             "ui",
             "ux",
             "vert",
             "vertical",
             "web",
             "website"
          ],

       },
       {
          "name":"motion_photos_auto",

          "tags":[
             "A",
             "alphabet",
             "animation",
             "auto",
             "automatic",
             "character",
             "circle",
             "font",
             "gif",
             "letter",
             "live",
             "motion",
             "photos",
             "symbol",
             "text",
             "type",
             "video"
          ],

       },
       {
          "name":"motion_photos_off",

          "tags":[
             "animation",
             "circle",
             "disabled",
             "enabled",
             "motion",
             "off",
             "on",
             "photos",
             "slash",
             "video"
          ],

       },
       {
          "name":"motion_photos_on",

          "tags":[
             "animation",
             "circle",
             "disabled",
             "enabled",
             "motion",
             "off",
             "on",
             "photos",
             "play",
             "slash",
             "video"
          ],

       },
       {
          "name":"motion_photos_pause",

          "tags":[
             
          ],

       },
       {
          "name":"motion_photos_paused",

          "tags":[
             "animation",
             "circle",
             "motion",
             "pause",
             "paused",
             "photos",
             "video"
          ],

       },
       {
          "name":"mouse",

          "tags":[
             "click",
             "computer",
             "cursor",
             "device",
             "hardware",
             "mouse",
             "wireless"
          ],

       },
       {
          "name":"move_to_inbox",

          "tags":[
             "archive",
             "arrow",
             "down",
             "email",
             "envelop",
             "inbox",
             "incoming",
             "letter",
             "mail",
             "message",
             "move to",
             "send"
          ],

       },
       {
          "name":"movie",

          "tags":[
             "cinema",
             "film",
             "media",
             "movie",
             "slate",
             "video"
          ],

       },
       {
          "name":"movie_creation",

          "tags":[
             "clapperboard",
             "creation",
             "film",
             "movie",
             "movies",
             "slate",
             "video"
          ],

       },
       {
          "name":"movie_filter",

          "tags":[
             "clapperboard",
             "creation",
             "film",
             "filter",
             "movie",
             "movies",
             "slate",
             "stars",
             "video"
          ],

       },
       {
          "name":"moving",

          "tags":[
             "arrow",
             "direction",
             "moving",
             "navigation",
             "travel",
             "up"
          ],

       },
       {
          "name":"mp",

          "tags":[
             "alphabet",
             "character",
             "font",
             "image",
             "letter",
             "megapixel",
             "mp",
             "photo",
             "photography",
             "pixels",
             "quality",
             "resolution",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"multiline_chart",

          "tags":[
             "analytics",
             "bar",
             "bars",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "line",
             "measure",
             "metrics",
             "multiple",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"multiple_stop",

          "tags":[
             "arrows",
             "directions",
             "dots",
             "left",
             "maps",
             "multiple",
             "navigation",
             "right",
             "stop"
          ],

       },
       {
          "name":"museum",

          "tags":[
             "architecture",
             "attraction",
             "building",
             "estate",
             "event",
             "exhibition",
             "explore",
             "local",
             "museum",
             "palces",
             "real",
             "see",
             "shop",
             "store",
             "tour"
          ],

       },
       {
          "name":"music_note",

          "tags":[
             "audio",
             "audiotrack",
             "key",
             "music",
             "note",
             "sound",
             "track"
          ],

       },
       {
          "name":"music_off",

          "tags":[
             "audio",
             "audiotrack",
             "disabled",
             "enabled",
             "key",
             "music",
             "note",
             "off",
             "on",
             "slash",
             "sound",
             "track"
          ],

       },
       {
          "name":"music_video",

          "tags":[
             "band",
             "music",
             "mv",
             "recording",
             "screen",
             "tv",
             "video",
             "watch"
          ],

       },
       {
          "name":"my_location",

          "tags":[
             "destination",
             "direction",
             "location",
             "maps",
             "navigation",
             "pin",
             "place",
             "point",
             "stop"
          ],

       },
       {
          "name":"nat",

          "tags":[
             "communication",
             "nat"
          ],

       },
       {
          "name":"nature",

          "tags":[
             "forest",
             "nature",
             "outdoor",
             "outside",
             "park",
             "tree",
             "wilderness"
          ],

       },
       {
          "name":"nature_people",

          "tags":[
             "activity",
             "body",
             "forest",
             "human",
             "nature",
             "outdoor",
             "outside",
             "park",
             "people",
             "person",
             "tree",
             "wilderness"
          ],

       },
       {
          "name":"navigate_before",

          "tags":[
             "arrow",
             "arrows",
             "before",
             "direction",
             "left",
             "navigate"
          ],

       },
       {
          "name":"navigate_next",

          "tags":[
             "arrow",
             "arrows",
             "direction",
             "navigate",
             "next",
             "right"
          ],

       },
       {
          "name":"navigation",

          "tags":[
             "destination",
             "direction",
             "location",
             "maps",
             "navigation",
             "pin",
             "place",
             "point",
             "stop"
          ],

       },
       {
          "name":"near_me",

          "tags":[
             "destination",
             "direction",
             "location",
             "maps",
             "me",
             "navigation",
             "near",
             "pin",
             "place",
             "point",
             "stop"
          ],

       },
       {
          "name":"near_me_disabled",

          "tags":[
             "destination",
             "direction",
             "disabled",
             "enabled",
             "location",
             "maps",
             "me",
             "navigation",
             "near",
             "off",
             "on",
             "pin",
             "place",
             "point",
             "slash"
          ],

       },
       {
          "name":"nearby_error",

          "tags":[
             "!",
             "alert",
             "attention",
             "caution",
             "danger",
             "error",
             "exclamation",
             "important",
             "mark",
             "nearby",
             "notification",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"nearby_off",

          "tags":[
             "disabled",
             "enabled",
             "nearby",
             "off",
             "on",
             "slash"
          ],

       },
       {
          "name":"network_cell",

          "tags":[
             "cell",
             "cellular",
             "data",
             "internet",
             "mobile",
             "network",
             "phone",
             "speed",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"network_check",

          "tags":[
             "check",
             "connect",
             "connection",
             "internet",
             "meter",
             "network",
             "signal",
             "speed",
             "tick",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"network_locked",

          "tags":[
             "alert",
             "available",
             "cellular",
             "connection",
             "data",
             "error",
             "internet",
             "lock",
             "locked",
             "mobile",
             "network",
             "not",
             "privacy",
             "private",
             "protection",
             "restricted",
             "safety",
             "secure",
             "security",
             "service",
             "signal",
             "warning",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"network_wifi",

          "tags":[
             "cell",
             "cellular",
             "data",
             "internet",
             "mobile",
             "network",
             "phone",
             "speed",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"new_label",

          "tags":[
             
          ],

       },
       {
          "name":"new_releases",

          "tags":[
             "!",
             "alert",
             "attention",
             "burst",
             "caution",
             "danger",
             "error",
             "exclamation",
             "important",
             "mark",
             "new",
             "notification",
             "release",
             "releases",
             "star",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"next_plan",

          "tags":[
             "arrow",
             "circle",
             "next",
             "plan",
             "right"
          ],

       },
       {
          "name":"next_week",

          "tags":[
             "arrow",
             "bag",
             "baggage",
             "briefcase",
             "business",
             "case",
             "next",
             "suitcase",
             "week"
          ],

       },
       {
          "name":"nfc",

          "tags":[
             "communication",
             "data",
             "field",
             "mobile",
             "near",
             "nfc",
             "wireless"
          ],

       },
       {
          "name":"night_shelter",

          "tags":[
             "architecture",
             "bed",
             "building",
             "estate",
             "homeless",
             "house",
             "night",
             "place",
             "real",
             "shelter",
             "sleep"
          ],

       },
       {
          "name":"nightlife",

          "tags":[
             "alcohol",
             "bar",
             "bottle",
             "club",
             "cocktail",
             "dance",
             "drink",
             "food",
             "glass",
             "liquor",
             "music",
             "nightlife",
             "note",
             "wine"
          ],

       },
       {
          "name":"nightlight",

          "tags":[
             "dark",
             "disturb",
             "mode",
             "moon",
             "night",
             "nightlight",
             "sleep",
             "weather"
          ],

       },
       {
          "name":"nightlight_round",

          "tags":[
             "dark",
             "half",
             "light",
             "mode",
             "moon",
             "night",
             "nightlight",
             "round"
          ],

       },
       {
          "name":"nights_stay",

          "tags":[
             "cloud",
             "crescent",
             "dark",
             "mode",
             "moon",
             "nights",
             "phases",
             "silence",
             "silent",
             "sky",
             "stay",
             "time",
             "weather"
          ],

       },
       {
          "name":"no_accounts",

          "tags":[
             "account",
             "accounts",
             "avatar",
             "disabled",
             "enabled",
             "face",
             "human",
             "no",
             "off",
             "offline",
             "on",
             "people",
             "person",
             "profile",
             "slash",
             "thumbnail",
             "unavailable",
             "unidentifiable",
             "unknown",
             "user"
          ],

       },
       {
          "name":"no_backpack",

          "tags":[
             "accessory",
             "backpack",
             "bag",
             "bookbag",
             "knapsack",
             "no",
             "pack",
             "travel"
          ],

       },
       {
          "name":"no_cell",

          "tags":[
             "Android",
             "OS",
             "cell",
             "device",
             "disabled",
             "enabled",
             "hardware",
             "iOS",
             "mobile",
             "no",
             "off",
             "on",
             "phone",
             "slash",
             "tablet"
          ],

       },
       {
          "name":"no_drinks",

          "tags":[
             "alcohol",
             "beverage",
             "bottle",
             "cocktail",
             "drink",
             "drinks",
             "food",
             "liquor",
             "no",
             "wine"
          ],

       },
       {
          "name":"no_encryption",

          "tags":[
             "disabled",
             "enabled",
             "encryption",
             "lock",
             "no",
             "off",
             "on",
             "password",
             "safety",
             "security",
             "slash"
          ],

       },
       {
          "name":"no_encryption_gmailerrorred",

          "tags":[
             "disabled",
             "enabled",
             "encryption",
             "error",
             "gmail",
             "lock",
             "locked",
             "no",
             "off",
             "on",
             "slash"
          ],

       },
       {
          "name":"no_flash",

          "tags":[
             "bolt",
             "camera",
             "disabled",
             "enabled",
             "flash",
             "image",
             "lightning",
             "no",
             "off",
             "on",
             "photo",
             "photography",
             "picture",
             "slash",
             "thunderbolt"
          ],

       },
       {
          "name":"no_food",

          "tags":[
             "disabled",
             "drink",
             "enabled",
             "fastfood",
             "food",
             "hamburger",
             "meal",
             "no",
             "off",
             "on",
             "slash"
          ],

       },
       {
          "name":"no_luggage",

          "tags":[
             "bag",
             "baggage",
             "carry",
             "disabled",
             "enabled",
             "luggage",
             "no",
             "off",
             "on",
             "slash",
             "suitcase",
             "travel"
          ],

       },
       {
          "name":"no_meals",

          "tags":[
             "dining",
             "disabled",
             "eat",
             "enabled",
             "food",
             "fork",
             "knife",
             "meal",
             "meals",
             "no",
             "off",
             "on",
             "restaurant",
             "slash",
             "spoon",
             "utensils"
          ],

       },
       {
          "name":"no_meeting_room",

          "tags":[
             "building",
             "disabled",
             "door",
             "doorway",
             "enabled",
             "entrance",
             "home",
             "house",
             "interior",
             "meeting",
             "no",
             "off",
             "office",
             "on",
             "open",
             "places",
             "room",
             "slash"
          ],

       },
       {
          "name":"no_photography",

          "tags":[
             "camera",
             "disabled",
             "enabled",
             "image",
             "no",
             "off",
             "on",
             "photo",
             "photography",
             "picture",
             "slash"
          ],

       },
       {
          "name":"no_sim",

          "tags":[
             "camera",
             "card",
             "device",
             "eject",
             "insert",
             "memory",
             "no",
             "phone",
             "sim",
             "storage"
          ],

       },
       {
          "name":"no_stroller",

          "tags":[
             "baby",
             "care",
             "carriage",
             "child",
             "children",
             "disabled",
             "enabled",
             "infant",
             "kid",
             "newborn",
             "no",
             "off",
             "on",
             "parents",
             "slash",
             "stroller",
             "toddler",
             "young"
          ],

       },
       {
          "name":"no_transfer",

          "tags":[
             "automobile",
             "bus",
             "car",
             "cars",
             "direction",
             "disabled",
             "enabled",
             "maps",
             "no",
             "off",
             "on",
             "public",
             "slash",
             "transfer",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"nordic_walking",

          "tags":[
             "athlete",
             "athletic",
             "body",
             "entertainment",
             "exercise",
             "hiking",
             "hobby",
             "human",
             "nordic",
             "people",
             "person",
             "social",
             "sports",
             "travel",
             "walker",
             "walking"
          ],

       },
       {
          "name":"north",

          "tags":[
             "arrow",
             "directional",
             "maps",
             "navigation",
             "north",
             "up"
          ],

       },
       {
          "name":"north_east",

          "tags":[
             "arrow",
             "east",
             "maps",
             "navigation",
             "noth",
             "right",
             "up"
          ],

       },
       {
          "name":"north_west",

          "tags":[
             "arrow",
             "directional",
             "left",
             "maps",
             "navigation",
             "north",
             "up",
             "west"
          ],

       },
       {
          "name":"not_accessible",

          "tags":[
             "accessibility",
             "accessible",
             "body",
             "handicap",
             "help",
             "human",
             "not",
             "person",
             "wheelchair"
          ],

       },
       {
          "name":"not_interested",

          "tags":[
             "cancel",
             "close",
             "dislike",
             "exit",
             "interested",
             "no",
             "not",
             "off",
             "quit",
             "remove",
             "stop",
             "x"
          ],

       },
       {
          "name":"not_listed_location",

          "tags":[
             "?",
             "assistance",
             "destination",
             "direction",
             "help",
             "info",
             "information",
             "listed",
             "location",
             "maps",
             "not",
             "pin",
             "place",
             "punctuation",
             "question mark",
             "stop",
             "support",
             "symbol"
          ],

       },
       {
          "name":"not_started",

          "tags":[
             "circle",
             "media",
             "not",
             "pause",
             "play",
             "started",
             "video"
          ],

       },
       {
          "name":"note",

          "tags":[
             "bookmark",
             "message",
             "note",
             "paper"
          ],

       },
       {
          "name":"note_add",

          "tags":[
             "+",
             "-doc",
             "add",
             "data",
             "document",
             "drive",
             "file",
             "folder",
             "folders",
             "new",
             "note",
             "page",
             "paper",
             "plus",
             "sheet",
             "slide",
             "symbol",
             "writing"
          ],

       },
       {
          "name":"note_alt",

          "tags":[
             "alt",
             "clipboard",
             "document",
             "file",
             "memo",
             "note",
             "page",
             "paper",
             "writing"
          ],

       },
       {
          "name":"notes",

          "tags":[
             "comment",
             "doc",
             "document",
             "note",
             "notes",
             "text",
             "write",
             "writing"
          ],

       },
       {
          "name":"notification_add",

          "tags":[
             "+",
             "active",
             "add",
             "alarm",
             "alert",
             "bell",
             "chime",
             "notification",
             "notifications",
             "notify",
             "plus",
             "reminder",
             "ring",
             "sound",
             "symbol"
          ],

       },
       {
          "name":"notification_important",

          "tags":[
             "!",
             "active",
             "alarm",
             "alert",
             "attention",
             "bell",
             "caution",
             "chime",
             "danger",
             "error",
             "exclamation",
             "important",
             "mark",
             "notification",
             "notifications",
             "notify",
             "reminder",
             "ring",
             "sound",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"notifications",

          "tags":[
             "active",
             "alarm",
             "alert",
             "bell",
             "chime",
             "notifications",
             "notify",
             "reminder",
             "ring",
             "sound"
          ],

       },
       {
          "name":"notifications_active",

          "tags":[
             "active",
             "alarm",
             "alert",
             "bell",
             "chime",
             "notifications",
             "notify",
             "reminder",
             "ring",
             "ringing",
             "sound"
          ],

       },
       {
          "name":"notifications_none",

          "tags":[
             "alarm",
             "alert",
             "bell",
             "none",
             "notifications",
             "notify",
             "reminder",
             "sound"
          ],

       },
       {
          "name":"notifications_off",

          "tags":[
             "active",
             "alarm",
             "alert",
             "bell",
             "chime",
             "disabled",
             "enabled",
             "notifications",
             "notify",
             "off",
             "offline",
             "on",
             "reminder",
             "ring",
             "slash",
             "sound"
          ],

       },
       {
          "name":"notifications_paused",

          "tags":[
             "active",
             "alarm",
             "alert",
             "bell",
             "chime",
             "ignore",
             "notifications",
             "notify",
             "paused",
             "quiet",
             "reminder",
             "ring --- pause",
             "sleep",
             "snooze",
             "sound",
             "z",
             "zzz"
          ],

       },
       {
          "name":"offline_bolt",

          "tags":[
             "bolt",
             "circle",
             "electric",
             "fast",
             "lightning",
             "offline",
             "thunderbolt"
          ],

       },
       {
          "name":"offline_pin",

          "tags":[
             "approve",
             "check",
             "checkmark",
             "circle",
             "complete",
             "done",
             "mark",
             "offline",
             "ok",
             "pin",
             "select",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"offline_share",

          "tags":[
             "Android",
             "OS",
             "arrow",
             "cell",
             "connect",
             "device",
             "direction",
             "hardware",
             "iOS",
             "link",
             "mobile",
             "multiple",
             "offline",
             "phone",
             "right",
             "share",
             "tablet"
          ],

       },
       {
          "name":"ondemand_video",

          "tags":[
             "Android",
             "OS",
             "chrome",
             "demand",
             "desktop",
             "device",
             "hardware",
             "iOS",
             "mac",
             "monitor",
             "ondemand",
             "play",
             "television",
             "tv",
             "video",
             "web",
             "window"
          ],

       },
       {
          "name":"online_prediction",

          "tags":[
             "bulb",
             "connection",
             "idea",
             "light",
             "network",
             "online",
             "prediction",
             "signal",
             "wireless"
          ],

       },
       {
          "name":"opacity",

          "tags":[
             "color",
             "drop",
             "droplet",
             "hue",
             "invert",
             "inverted",
             "opacity",
             "palette",
             "tone",
             "water"
          ],

       },
       {
          "name":"open_in_browser",

          "tags":[
             "arrow",
             "browser",
             "in",
             "open",
             "site",
             "up",
             "web",
             "website",
             "window"
          ],

       },
       {
          "name":"open_in_full",

          "tags":[
             "action",
             "arrow",
             "arrows",
             "expand",
             "full",
             "grow",
             "in",
             "move",
             "open"
          ],

       },
       {
          "name":"open_in_new",

          "tags":[
             "app",
             "application",
             "arrow",
             "box",
             "components",
             "in",
             "interface",
             "new",
             "open",
             "screen",
             "site",
             "ui",
             "ux",
             "web",
             "website",
             "window"
          ],

       },
       {
          "name":"open_in_new_off",

          "tags":[
             "arrow",
             "box",
             "disabled",
             "enabled",
             "export",
             "in",
             "new",
             "off",
             "on",
             "open",
             "slash",
             "window"
          ],

       },
       {
          "name":"open_with",

          "tags":[
             "arrow",
             "arrows",
             "direction",
             "expand",
             "move",
             "open",
             "pan",
             "with"
          ],

       },
       {
          "name":"other_houses",

          "tags":[
             "architecture",
             "cottage",
             "estate",
             "home",
             "house",
             "houses",
             "maps",
             "other",
             "place",
             "real",
             "residence",
             "residential",
             "stay",
             "traveling"
          ],

       },
       {
          "name":"outbound",

          "tags":[
             
          ],

       },
       {
          "name":"outbox",

          "tags":[
             "box",
             "mail",
             "outbox",
             "send",
             "sent"
          ],

       },
       {
          "name":"outdoor_grill",

          "tags":[
             "barbecue",
             "bbq",
             "charcoal",
             "cooking",
             "grill",
             "home",
             "house",
             "outdoor",
             "outside"
          ],

       },
       {
          "name":"outlet",

          "tags":[
             "connect",
             "connecter",
             "electricity",
             "outlet",
             "plug",
             "power"
          ],

       },
       {
          "name":"outlined_flag",

          "tags":[
             "country",
             "flag",
             "goal",
             "mark",
             "nation",
             "outlined",
             "report",
             "start"
          ],

       },
       {
          "name":"padding",

          "tags":[
             "design",
             "layout",
             "margin",
             "padding",
             "size",
             "square"
          ],

       },
       {
          "name":"pages",

          "tags":[
             "article",
             "gplus",
             "pages",
             "paper",
             "post",
             "star"
          ],

       },
       {
          "name":"pageview",

          "tags":[
             "doc",
             "document",
             "find",
             "glass",
             "magnifying",
             "page",
             "paper",
             "search",
             "view"
          ],

       },
       {
          "name":"paid",

          "tags":[
             "circle",
             "currency",
             "money",
             "paid",
             "payment",
             "transaction"
          ],

       },
       {
          "name":"palette",

          "tags":[
             "art",
             "color",
             "colors",
             "filters",
             "paint",
             "palette"
          ],

       },
       {
          "name":"pan_tool",

          "tags":[
             "fingers",
             "gesture",
             "hand",
             "hands",
             "human",
             "move",
             "pan",
             "scan",
             "stop",
             "tool"
          ],

       },
       {
          "name":"panorama",

          "tags":[
             "angle",
             "image",
             "mountain",
             "mountains",
             "panorama",
             "photo",
             "photography",
             "picture",
             "view",
             "wide"
          ],

       },
       {
          "name":"panorama_fish_eye",

          "tags":[
             "angle",
             "circle",
             "eye",
             "fish",
             "image",
             "panorama",
             "photo",
             "photography",
             "picture",
             "wide"
          ],

       },
       {
          "name":"panorama_horizontal",

          "tags":[
             "angle",
             "horizontal",
             "image",
             "panorama",
             "photo",
             "photography",
             "picture",
             "wide"
          ],

       },
       {
          "name":"panorama_horizontal_select",

          "tags":[
             "angle",
             "horizontal",
             "image",
             "panorama",
             "photo",
             "photography",
             "picture",
             "select",
             "wide"
          ],

       },
       {
          "name":"panorama_photosphere",

          "tags":[
             "angle",
             "horizontal",
             "image",
             "panorama",
             "photo",
             "photography",
             "photosphere",
             "picture",
             "wide"
          ],

       },
       {
          "name":"panorama_photosphere_select",

          "tags":[
             "angle",
             "horizontal",
             "image",
             "panorama",
             "photo",
             "photography",
             "photosphere",
             "picture",
             "select",
             "wide"
          ],

       },
       {
          "name":"panorama_vertical",

          "tags":[
             "angle",
             "image",
             "panorama",
             "photo",
             "photography",
             "picture",
             "vertical",
             "wide"
          ],

       },
       {
          "name":"panorama_vertical_select",

          "tags":[
             "angle",
             "image",
             "panorama",
             "photo",
             "photography",
             "picture",
             "select",
             "vertical",
             "wide"
          ],

       },
       {
          "name":"panorama_wide_angle",

          "tags":[
             "angle",
             "image",
             "panorama",
             "photo",
             "photography",
             "picture",
             "wide"
          ],

       },
       {
          "name":"panorama_wide_angle_select",

          "tags":[
             "angle",
             "image",
             "panorama",
             "photo",
             "photography",
             "picture",
             "select",
             "wide"
          ],

       },
       {
          "name":"paragliding",

          "tags":[
             "athlete",
             "athletic",
             "body",
             "entertainment",
             "exercise",
             "fly",
             "gliding",
             "hobby",
             "human",
             "parachute",
             "paragliding",
             "people",
             "person",
             "sky",
             "skydiving",
             "social",
             "sports",
             "travel"
          ],

       },
       {
          "name":"park",

          "tags":[
             "attraction",
             "fresh",
             "local",
             "nature",
             "outside",
             "park",
             "plant",
             "tree"
          ],

       },
       {
          "name":"party_mode",

          "tags":[
             "camera",
             "lens",
             "mode",
             "party",
             "photo",
             "photography",
             "picture"
          ],

       },
       {
          "name":"password",

          "tags":[
             "key",
             "login",
             "password",
             "pin",
             "security",
             "star",
             "unlock"
          ],

       },
       {
          "name":"pattern",

          "tags":[
             "key",
             "login",
             "password",
             "pattern",
             "pin",
             "security",
             "star",
             "unlock"
          ],

       },
       {
          "name":"pause",

          "tags":[
             "control",
             "controls",
             "media",
             "music",
             "pause",
             "player",
             "video"
          ],

       },
       {
          "name":"pause_circle",

          "tags":[
             
          ],

       },
       {
          "name":"pause_circle_filled",

          "tags":[
             "circle",
             "control",
             "controls",
             "filled",
             "media",
             "music",
             "pause",
             "video"
          ],

       },
       {
          "name":"pause_circle_outline",

          "tags":[
             "circle",
             "control",
             "controls",
             "media",
             "music",
             "outline",
             "pause",
             "video"
          ],

       },
       {
          "name":"pause_presentation",

          "tags":[
             "app",
             "application desktop",
             "device",
             "pause",
             "present",
             "presentation",
             "screen",
             "share",
             "site",
             "slides",
             "web",
             "website",
             "window",
             "www"
          ],

       },
       {
          "name":"payment",

          "tags":[
             "bill",
             "card",
             "cash",
             "coin",
             "commerce",
             "cost",
             "credit",
             "currency",
             "dollars",
             "finance",
             "money",
             "online",
             "pay",
             "payment",
             "price",
             "shopping",
             "symbol"
          ],

       },
       {
          "name":"payments",

          "tags":[
             "bill",
             "card",
             "cash",
             "coin",
             "commerce",
             "cost",
             "credit",
             "currency",
             "dollars",
             "finance",
             "layer",
             "money",
             "multiple",
             "online",
             "pay",
             "payment",
             "payments",
             "price",
             "shopping",
             "symbol"
          ],

       },
       {
          "name":"pedal_bike",

          "tags":[
             "automobile",
             "bicycle",
             "bike",
             "car",
             "cars",
             "maps",
             "pedal",
             "public",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"pending",

          "tags":[
             "circle",
             "dots",
             "loading",
             "pending",
             "progress",
             "wait",
             "waiting"
          ],

       },
       {
          "name":"pending_actions",

          "tags":[
             "actions",
             "clipboard",
             "clock",
             "date",
             "doc",
             "document",
             "pending",
             "remember",
             "schedule",
             "time"
          ],

       },
       {
          "name":"people",

          "tags":[
             "accounts",
             "committee",
             "community",
             "face",
             "family",
             "friends",
             "group",
             "humans",
             "network",
             "people",
             "persons",
             "profiles",
             "social",
             "team",
             "users"
          ],

       },
       {
          "name":"people_alt",

          "tags":[
             "accounts",
             "committee",
             "face",
             "family",
             "friends",
             "humans",
             "network",
             "people",
             "persons",
             "profiles",
             "social",
             "team",
             "users"
          ],

       },
       {
          "name":"people_outline",

          "tags":[
             "accounts",
             "committee",
             "face",
             "family",
             "friends",
             "humans",
             "network",
             "outline",
             "people",
             "persons",
             "profiles",
             "social",
             "team",
             "users"
          ],

       },
       {
          "name":"perm_camera_mic",

          "tags":[
             "camera",
             "image",
             "microphone",
             "min",
             "perm",
             "photo",
             "photography",
             "picture",
             "speaker"
          ],

       },
       {
          "name":"perm_contact_calendar",

          "tags":[
             "account",
             "calendar",
             "contact",
             "date",
             "face",
             "human",
             "information",
             "people",
             "perm",
             "person",
             "profile",
             "schedule",
             "time",
             "user"
          ],

       },
       {
          "name":"perm_data_setting",

          "tags":[
             "data",
             "gear",
             "info",
             "information",
             "perm",
             "settings"
          ],

       },
       {
          "name":"perm_device_information",

          "tags":[
             "Android",
             "OS",
             "alert",
             "announcement",
             "device",
             "hardware",
             "i",
             "iOS",
             "info",
             "information",
             "mobile",
             "perm",
             "phone",
             "tablet"
          ],

       },
       {
          "name":"perm_identity",

          "tags":[
             "account",
             "avatar",
             "face",
             "human",
             "identity",
             "people",
             "perm",
             "person",
             "profile",
             "thumbnail",
             "user"
          ],

       },
       {
          "name":"perm_media",

          "tags":[
             "collection",
             "data",
             "doc",
             "document",
             "file",
             "folder",
             "folders",
             "image",
             "landscape",
             "media",
             "mountain",
             "mountains",
             "perm",
             "photo",
             "photography",
             "picture",
             "storage"
          ],

       },
       {
          "name":"perm_phone_msg",

          "tags":[
             "bubble",
             "call",
             "cell",
             "chat",
             "comment",
             "communicate",
             "contact",
             "device",
             "message",
             "msg",
             "perm",
             "phone",
             "recording",
             "speech",
             "telephone",
             "voice"
          ],

       },
       {
          "name":"perm_scan_wifi",

          "tags":[
             "alert",
             "announcement",
             "connection",
             "info",
             "information",
             "internet",
             "network",
             "perm",
             "scan",
             "service",
             "signal",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"person",

          "tags":[
             "account",
             "face",
             "human",
             "people",
             "person",
             "profile",
             "user"
          ],

       },
       {
          "name":"person_add",

          "tags":[
             "+",
             "account",
             "add",
             "avatar",
             "face",
             "friend",
             "human",
             "new",
             "people",
             "person",
             "plus",
             "profile",
             "symbol",
             "user"
          ],

       },
       {
          "name":"person_add_alt",

          "tags":[
             "+",
             "account",
             "add",
             "face",
             "human",
             "people",
             "person",
             "plus",
             "profile",
             "user"
          ],

       },
       {
          "name":"person_add_alt_1",

          "tags":[
             
          ],

       },
       {
          "name":"person_add_disabled",

          "tags":[
             "+",
             "account",
             "add",
             "disabled",
             "enabled",
             "face",
             "human",
             "new",
             "off",
             "offline",
             "on",
             "people",
             "person",
             "plus",
             "profile",
             "slash",
             "symbol",
             "user"
          ],

       },
       {
          "name":"person_off",

          "tags":[
             "account",
             "avatar",
             "disabled",
             "enabled",
             "face",
             "human",
             "off",
             "on",
             "people",
             "person",
             "profile",
             "slash",
             "user"
          ],

       },
       {
          "name":"person_outline",

          "tags":[
             "account",
             "face",
             "human",
             "outline",
             "people",
             "person",
             "profile",
             "user"
          ],

       },
       {
          "name":"person_pin",

          "tags":[
             "account",
             "avatar",
             "destination",
             "direction",
             "face",
             "human",
             "location",
             "maps",
             "people",
             "person",
             "pin",
             "place",
             "profile",
             "stop",
             "user"
          ],

       },
       {
          "name":"person_pin_circle",

          "tags":[
             "account",
             "circle",
             "destination",
             "direction",
             "face",
             "human",
             "location",
             "maps",
             "people",
             "person",
             "pin",
             "place",
             "profile",
             "stop",
             "user"
          ],

       },
       {
          "name":"person_remove",

          "tags":[
             "account",
             "avatar",
             "delete",
             "face",
             "human",
             "minus",
             "people",
             "person",
             "profile",
             "remove",
             "unfriend",
             "user"
          ],

       },
       {
          "name":"person_remove_alt_1",

          "tags":[
             
          ],

       },
       {
          "name":"person_search",

          "tags":[
             "account",
             "avatar",
             "face",
             "find",
             "glass",
             "human",
             "look",
             "magnify",
             "magnifying",
             "people",
             "person",
             "profile",
             "search",
             "user"
          ],

       },
       {
          "name":"personal_injury",

          "tags":[
             
          ],

       },
       {
          "name":"personal_video",

          "tags":[
             "Android",
             "OS",
             "cam",
             "chrome",
             "desktop",
             "device",
             "hardware",
             "iOS",
             "mac",
             "monitor",
             "personal",
             "television",
             "tv",
             "video",
             "web",
             "window"
          ],

       },
       {
          "name":"pest_control",

          "tags":[
             "bug",
             "control",
             "exterminator",
             "insects",
             "pest"
          ],

       },
       {
          "name":"pest_control_rodent",

          "tags":[
             "control",
             "exterminator",
             "mice",
             "pest",
             "rodent"
          ],

       },
       {
          "name":"pets",

          "tags":[
             "animal",

             "paw",
             "pet"
          ],

       },
       {
          "name":"phone",

          "tags":[
             "call",
             "cell",
             "contact",
             "device",
             "hardware",
             "mobile",
             "phone",
             "telephone"
          ],

       },
       {
          "name":"phone_android",

          "tags":[
             "OS",
             "android",
             "cell",
             "device",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "tablet"
          ],

       },
       {
          "name":"phone_bluetooth_speaker",

          "tags":[
             "bluetooth",
             "call",
             "cell",
             "connect",
             "connection",
             "connectivity",
             "contact",
             "device",
             "hardware",
             "mobile",
             "phone",
             "signal",
             "speaker",
             "symbol",
             "telephone",
             "wireless"
          ],

       },
       {
          "name":"phone_callback",

          "tags":[
             "arrow",
             "call",
             "callback",
             "cell",
             "contact",
             "device",
             "down",
             "hardware",
             "mobile",
             "phone",
             "telephone"
          ],

       },
       {
          "name":"phone_disabled",

          "tags":[
             "call",
             "cell",
             "contact",
             "device",
             "disabled",
             "enabled",
             "hardware",
             "mobile",
             "off",
             "offline",
             "on",
             "phone",
             "slash",
             "telephone"
          ],

       },
       {
          "name":"phone_enabled",

          "tags":[
             "call",
             "cell",
             "contact",
             "device",
             "enabled",
             "hardware",
             "mobile",
             "phone",
             "telephone"
          ],

       },
       {
          "name":"phone_forwarded",

          "tags":[
             "arrow",
             "call",
             "cell",
             "contact",
             "device",
             "direction",
             "forwarded",
             "hardware",
             "mobile",
             "phone",
             "right",
             "telephone"
          ],

       },
       {
          "name":"phone_in_talk",

          "tags":[
             "call",
             "cell",
             "connection",
             "contact",
             "data",
             "device",
             "hardware",
             "in",
             "mobile",
             "network",
             "phone",
             "scan",
             "service",
             "signal",
             "sound",
             "speaker",
             "talk",
             "telephone",
             "waves",
             "wireless"
          ],

       },
       {
          "name":"phone_iphone",

          "tags":[
             "Android",
             "OS",
             "cell",
             "device",
             "hardware",
             "iOS",
             "iphone",
             "mobile",
             "phone",
             "tablet"
          ],

       },
       {
          "name":"phone_locked",

          "tags":[
             "call",
             "cell",
             "contact",
             "device",
             "hardware",
             "lock",
             "locked",
             "mobile",
             "password",
             "phone",
             "privacy",
             "private",
             "protection",
             "safety",
             "secure",
             "security",
             "telephone"
          ],

       },
       {
          "name":"phone_missed",

          "tags":[
             "arrow",
             "call",
             "cell",
             "contact",
             "device",
             "hardware",
             "missed",
             "mobile",
             "phone",
             "telephone"
          ],

       },
       {
          "name":"phone_paused",

          "tags":[
             "call",
             "cell",
             "contact",
             "device",
             "hardware",
             "mobile",
             "pause",
             "paused",
             "phone",
             "telephone"
          ],

       },
       {
          "name":"phonelink",

          "tags":[
             "Android",
             "OS",
             "chrome",
             "computer",
             "connect",
             "desktop",
             "device",
             "hardware",
             "iOS",
             "link",
             "mac",
             "mobile",
             "phone",
             "phonelink",
             "sync",
             "tablet",
             "web",
             "windows"
          ],

       },
       {
          "name":"phonelink_erase",

          "tags":[
             "Android",
             "OS",
             "cancel",
             "cell",
             "close",
             "connection",
             "device",
             "erase",
             "exit",
             "hardware",
             "iOS",
             "mobile",
             "no",
             "phone",
             "phonelink",
             "remove",
             "stop",
             "tablet",
             "x"
          ],

       },
       {
          "name":"phonelink_lock",

          "tags":[
             "Android",
             "OS",
             "cell",
             "connection",
             "device",
             "erase",
             "hardware",
             "iOS",
             "lock",
             "locked",
             "mobile",
             "password",
             "phone",
             "phonelink",
             "privacy",
             "private",
             "protection",
             "safety",
             "secure",
             "security",
             "tablet"
          ],

       },
       {
          "name":"phonelink_off",

          "tags":[
             "Android",
             "OS",
             "chrome",
             "computer",
             "connect",
             "desktop",
             "device",
             "disabled",
             "enabled",
             "hardware",
             "iOS",
             "link",
             "mac",
             "mobile",
             "off",
             "on",
             "phone",
             "phonelink",
             "slash",
             "sync",
             "tablet",
             "web",
             "windows"
          ],

       },
       {
          "name":"phonelink_ring",

          "tags":[
             "Android",
             "OS",
             "cell",
             "connection",
             "data",
             "device",
             "hardware",
             "iOS",
             "mobile",
             "network",
             "phone",
             "phonelink",
             "ring",
             "service",
             "signal",
             "tablet",
             "wireless"
          ],

       },
       {
          "name":"phonelink_setup",

          "tags":[
             "Android",
             "OS",
             "call",
             "chat",
             "device",
             "hardware",
             "iOS",
             "info",
             "mobile",
             "phone",
             "phonelink",
             "settings",
             "setup",
             "tablet",
             "text"
          ],

       },
       {
          "name":"photo",

          "tags":[
             "image",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture"
          ],

       },
       {
          "name":"photo_album",

          "tags":[
             "album",
             "archive",
             "bookmark",
             "image",
             "label",
             "library",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture",
             "ribbon",
             "save",
             "tag"
          ],

       },
       {
          "name":"photo_camera",

          "tags":[
             "camera",
             "image",
             "photo",
             "photography",
             "picture"
          ],

       },
       {
          "name":"photo_camera_back",

          "tags":[
             "back",
             "camera",
             "image",
             "landscape",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture",
             "rear"
          ],

       },
       {
          "name":"photo_camera_front",

          "tags":[
             "account",
             "camera",
             "face",
             "front",
             "human",
             "image",
             "people",
             "person",
             "photo",
             "photography",
             "picture",
             "portrait",
             "profile",
             "user"
          ],

       },
       {
          "name":"photo_filter",

          "tags":[
             "filter",
             "filters",
             "image",
             "photo",
             "photography",
             "picture",
             "star",
             "stars"
          ],

       },
       {
          "name":"photo_library",

          "tags":[
             "album",
             "image",
             "library",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture"
          ],

       },
       {
          "name":"photo_size_select_actual",

          "tags":[
             "actual",
             "image",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture",
             "select",
             "size"
          ],

       },
       {
          "name":"photo_size_select_large",

          "tags":[
             "adjust",
             "album",
             "edit",
             "editing",
             "image",
             "large",
             "library",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture",
             "select",
             "size"
          ],

       },
       {
          "name":"photo_size_select_small",

          "tags":[
             "adjust",
             "album",
             "edit",
             "editing",
             "image",
             "large",
             "library",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture",
             "select",
             "size",
             "small"
          ],

       },
       {
          "name":"piano",

          "tags":[
             "instrument",
             "keyboard",
             "keys",
             "music",
             "musical",
             "piano",
             "social"
          ],

       },
       {
          "name":"piano_off",

          "tags":[
             "disabled",
             "enabled",
             "instrument",
             "keyboard",
             "keys",
             "music",
             "musical",
             "off",
             "on",
             "piano",
             "slash",
             "social"
          ],

       },
       {
          "name":"picture_as_pdf",

          "tags":[
             "alphabet",
             "as",
             "character",
             "document",
             "file",
             "font",
             "image",
             "letter",
             "multiple",
             "pdf",
             "photo",
             "photography",
             "picture",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"picture_in_picture",

          "tags":[
             "crop",
             "cropped",
             "overlap",
             "photo",
             "picture",
             "position",
             "shape"
          ],

       },
       {
          "name":"picture_in_picture_alt",

          "tags":[
             "crop",
             "cropped",
             "overlap",
             "photo",
             "picture",
             "position",
             "shape"
          ],

       },
       {
          "name":"pie_chart",

          "tags":[
             "analytics",
             "bar",
             "bars",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "pie",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"pie_chart_outline",

          "tags":[
             "analytics",
             "bar",
             "bars",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "outline",
             "pie",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"pin",

          "tags":[
             "1",
             "2",
             "3",
             "digit",
             "key",
             "login",
             "logout",
             "number",
             "password",
             "pattern",
             "pin",
             "security",
             "star",
             "symbol",
             "unlock"
          ],

       },
       {
          "name":"pin_drop",

          "tags":[
             "destination",
             "direction",
             "drop",
             "location",
             "maps",
             "navigation",
             "pin",
             "place",
             "stop"
          ],

       },
       {
          "name":"pin_end",

          "tags":[
             
          ],

       },
       {
          "name":"pin_invoke",

          "tags":[
             
          ],

       },
       {
          "name":"pivot_table_chart",

          "tags":[
             "analytics",
             "arrow",
             "arrows",
             "bar",
             "bars",
             "chart",
             "data",
             "diagram",
             "direction",
             "drive",
             "edit",
             "editing",
             "graph",
             "grid",
             "infographic",
             "measure",
             "metrics",
             "pivot",
             "rotate",
             "sheet",
             "statistics",
             "table",
             "tracking"
          ],

       },
       {
          "name":"place",

          "tags":[
             "destination",
             "direction",
             "location",
             "maps",
             "navigation",
             "pin",
             "place",
             "point",
             "stop"
          ],

       },
       {
          "name":"plagiarism",

          "tags":[
             "doc",
             "document",
             "find",
             "glass",
             "look",
             "magnifying",
             "page",
             "paper",
             "plagiarism",
             "search",
             "see"
          ],

       },
       {
          "name":"play_arrow",

          "tags":[
             "arrow",
             "control",
             "controls",
             "media",
             "music",
             "play",
             "player",
             "start",
             "video"
          ],

       },
       {
          "name":"play_circle",

          "tags":[
             
          ],

       },
       {
          "name":"play_circle_filled",

          "tags":[
             "arrow",
             "circle",
             "control",
             "controls",
             "media",
             "music",
             "play",
             "video"
          ],

       },
       {
          "name":"play_circle_outline",

          "tags":[
             "arrow",
             "circle",
             "control",
             "controls",
             "media",
             "music",
             "outline",
             "play",
             "video"
          ],

       },
       {
          "name":"play_disabled",

          "tags":[
             "control",
             "controls",
             "disabled",
             "enabled",
             "media",
             "music",
             "off",
             "on",
             "play",
             "slash",
             "video"
          ],

       },
       {
          "name":"play_for_work",

          "tags":[
             "arrow",
             "circle",
             "down",
             "google",
             "half",
             "play",
             "work"
          ],

       },
       {
          "name":"play_lesson",

          "tags":[
             "audio",
             "book",
             "bookmark",
             "digital",
             "ebook",
             "lesson",
             "multimedia",
             "play",
             "play lesson",
             "read",
             "reading",
             "ribbon"
          ],

       },
       {
          "name":"playlist_add",

          "tags":[
             "+",
             "add",
             "collection",
             "list",
             "music",
             "new",
             "playlist",
             "plus",
             "symbol"
          ],

       },
       {
          "name":"playlist_add_check",

          "tags":[
             "add",
             "approve",
             "check",
             "collection",
             "complete",
             "done",
             "list",
             "mark",
             "music",
             "ok",
             "playlist",
             "select",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"playlist_play",

          "tags":[
             "arow",
             "collection",
             "list",
             "music",
             "play",
             "playlist"
          ],

       },
       {
          "name":"plumbing",

          "tags":[
             "build",
             "construction",
             "fix",
             "handyman",
             "plumbing",
             "repair",
             "tools",
             "wrench"
          ],

       },
       {
          "name":"plus_one",

          "tags":[
             "1",
             "add",
             "digit",
             "increase",
             "number",
             "one",
             "plus",
             "symbol"
          ],

       },
       {
          "name":"podcasts",

          "tags":[
             "broadcast",
             "casting",
             "network",
             "podcasts",
             "signal",
             "transmitting",
             "wireless"
          ],

       },
       {
          "name":"point_of_sale",

          "tags":[
             "checkout",
             "cost",
             "machine",
             "merchant",
             "money",
             "of",
             "pay",
             "payment",
             "point",
             "pos",
             "retail",
             "sale",
             "system",
             "transaction"
          ],

       },
       {
          "name":"policy",

          "tags":[
             "certified",
             "find",
             "glass",
             "legal",
             "look",
             "magnify",
             "magnifying",
             "policy",
             "privacy",
             "private",
             "protect",
             "protection",
             "search",
             "security",
             "see",
             "shield",
             "verified"
          ],

       },
       {
          "name":"poll",

          "tags":[
             "analytics",
             "bar",
             "bars",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "poll",
             "statistics",
             "survey",
             "tracking",
             "vote"
          ],

       },
       {
          "name":"polymer",

          "tags":[
             "emblem",
             "logo",
             "mark",
             "polymer"
          ],

       },
       {
          "name":"pool",

          "tags":[
             "athlete",
             "athletic",
             "beach",
             "body",
             "entertainment",
             "exercise",
             "hobby",
             "human",
             "ocean",
             "people",
             "person",
             "places",
             "pool",
             "sea",
             "sports",
             "swim",
             "swimming",
             "water"
          ],

       },
       {
          "name":"portable_wifi_off",

          "tags":[
             "connection",
             "data",
             "disabled",
             "enabled",
             "internet",
             "network",
             "off",
             "offline",
             "on",
             "portable",
             "service",
             "signal",
             "slash",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"portrait",

          "tags":[
             "account",
             "face",
             "human",
             "people",
             "person",
             "photo",
             "picture",
             "portrait",
             "profile",
             "user"
          ],

       },
       {
          "name":"post_add",

          "tags":[
             "+",
             "add",
             "data",
             "doc",
             "document",
             "drive",
             "file",
             "folder",
             "folders",
             "page",
             "paper",
             "plus",
             "post",
             "sheet",
             "slide",
             "text",
             "writing"
          ],

       },
       {
          "name":"power",

          "tags":[
             "charge",
             "cord",
             "electric",
             "electrical",
             "outlet",
             "plug",
             "power"
          ],

       },
       {
          "name":"power_input",

          "tags":[
             "input",
             "lines",
             "power",
             "supply"
          ],

       },
       {
          "name":"power_off",

          "tags":[
             "charge",
             "cord",
             "disabled",
             "electric",
             "electrical",
             "enabled",
             "off",
             "on",
             "outlet",
             "plug",
             "power",
             "slash"
          ],

       },
       {
          "name":"power_settings_new",

          "tags":[
             "info",
             "information",
             "off",
             "on",
             "power",
             "save",
             "settings",
             "shutdown"
          ],

       },
       {
          "name":"precision_manufacturing",

          "tags":[
             "arm",
             "automatic",
             "chain",
             "conveyor",
             "crane",
             "factory",
             "industry",
             "machinery",
             "manufacturing",
             "mechanical",
             "precision",
             "production",
             "repairing",
             "robot",
             "supply",
             "warehouse"
          ],

       },
       {
          "name":"pregnant_woman",

          "tags":[
             "baby",
             "birth",
             "body",
             "female",
             "human",
             "lady",
             "maternity",
             "mom",
             "mother",
             "people",
             "person",
             "pregnant",
             "women"
          ],

       },
       {
          "name":"present_to_all",

          "tags":[
             "all",
             "arrow",
             "present",
             "presentation",
             "screen",
             "share",
             "site",
             "slides",
             "to",
             "web",
             "website"
          ],

       },
       {
          "name":"preview",

          "tags":[
             "design",
             "eye",
             "layout",
             "preview",
             "reveal",
             "screen",
             "see",
             "show",
             "site",
             "view",
             "web",
             "website",
             "window",
             "www"
          ],

       },
       {
          "name":"price_change",

          "tags":[
             "arrows",
             "bill",
             "card",
             "cash",
             "change",
             "coin",
             "commerce",
             "cost",
             "credit",
             "currency",
             "dollars",
             "down",
             "finance",
             "money",
             "online",
             "pay",
             "payment",
             "price",
             "shopping",
             "symbol",
             "up"
          ],

       },
       {
          "name":"price_check",

          "tags":[
             "approve",
             "bill",
             "card",
             "cash",
             "check",
             "coin",
             "commerce",
             "complete",
             "cost",
             "credit",
             "currency",
             "dollars",
             "done",
             "finance",
             "mark",
             "money",
             "ok",
             "online",
             "pay",
             "payment",
             "price",
             "select",
             "shopping",
             "symbol",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"print",

          "tags":[
             "draft",
             "fax",
             "ink",
             "machine",
             "office",
             "paper",
             "print",
             "printer",
             "send"
          ],

       },
       {
          "name":"print_disabled",

          "tags":[
             "disabled",
             "enabled",
             "off",
             "on",
             "paper",
             "print",
             "printer",
             "slash"
          ],

       },
       {
          "name":"priority_high",

          "tags":[
             "!",
             "alert",
             "attention",
             "caution",
             "danger",
             "error",
             "exclamation",
             "high",
             "important",
             "mark",
             "notification",
             "priority",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"privacy_tip",

          "tags":[
             "alert",
             "announcment",
             "assistance",
             "certified",
             "details",
             "help",
             "i",
             "info",
             "information",
             "privacy",
             "private",
             "protect",
             "protection",
             "security",
             "service",
             "shield",
             "support",
             "tip",
             "verified"
          ],

       },
       {
          "name":"private_connectivity",

          "tags":[
             
          ],

       },
       {
          "name":"production_quantity_limits",

          "tags":[
             "!",
             "alert",
             "attention",
             "bill",
             "card",
             "cart",
             "cash",
             "caution",
             "coin",
             "commerce",
             "credit",
             "currency",
             "danger",
             "dollars",
             "error",
             "exclamation",
             "important",
             "limits",
             "mark",
             "money",
             "notification",
             "online",
             "pay",
             "payment",
             "production",
             "quantity",
             "shopping",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"psychology",

          "tags":[
             "behavior",
             "body",
             "brain",
             "cognitive",
             "function",
             "gear",
             "head",
             "human",
             "intellectual",
             "mental",
             "mind",
             "people",
             "person",
             "preferences",
             "psychiatric",
             "psychology",
             "science",
             "settings",
             "social",
             "therapy",
             "thinking",
             "thoughts"
          ],

       },
       {
          "name":"public",

          "tags":[
             "earth",
             "global",
             "globe",
             "map",
             "network",
             "planet",
             "public",
             "social",
             "space",
             "web",
             "world"
          ],

       },
       {
          "name":"public_off",

          "tags":[
             "disabled",
             "earth",
             "enabled",
             "global",
             "globe",
             "map",
             "network",
             "off",
             "on",
             "planet",
             "public",
             "slash",
             "social",
             "space",
             "web",
             "world"
          ],

       },
       {
          "name":"publish",

          "tags":[
             "arrow",
             "cloud",
             "file",
             "import",
             "publish",
             "up",
             "upload"
          ],

       },
       {
          "name":"published_with_changes",

          "tags":[
             "approve",
             "arrow",
             "arrows",
             "changes",
             "check",
             "complete",
             "done",
             "inprogress",
             "load",
             "loading",
             "mark",
             "ok",
             "published",
             "refresh",
             "renew",
             "replace",
             "rotate",
             "select",
             "tick",
             "validate",
             "verified",
             "with",
             "yes"
          ],

       },
       {
          "name":"push_pin",

          "tags":[
             "location",
             "marker",
             "pin",
             "place",
             "push",
             "remember",
             "save"
          ],

       },
       {
          "name":"qr_code",

          "tags":[
             "barcode",
             "camera",
             "code",
             "media",
             "product",
             "qr",
             "quick",
             "response",
             "smartphone",
             "url",
             "urls"
          ],

       },
       {
          "name":"qr_code_2",

          "tags":[
             "barcode",
             "camera",
             "code",
             "media",
             "product",
             "qr",
             "quick",
             "response",
             "smartphone",
             "url",
             "urls"
          ],

       },
       {
          "name":"qr_code_scanner",

          "tags":[
             "barcode",
             "camera",
             "code",
             "media",
             "product",
             "qr",
             "quick",
             "response",
             "scanner",
             "smartphone",
             "url",
             "urls"
          ],

       },
       {
          "name":"query_builder",

          "tags":[
             "builder",
             "clock",
             "date",
             "query",
             "schedule",
             "time"
          ],

       },
       {
          "name":"query_stats",

          "tags":[
             
          ],

       },
       {
          "name":"question_answer",

          "tags":[
             "answer",
             "bubble",
             "chat",
             "comment",
             "communicate",
             "conversation",
             "feedback",
             "message",
             "question",
             "speech",
             "talk"
          ],

       },
       {
          "name":"queue",

          "tags":[
             "add",
             "collection",
             "layers",
             "list",
             "multiple",
             "music",
             "playlist",
             "queue",
             "stack",
             "stream",
             "video"
          ],

       },
       {
          "name":"queue_music",

          "tags":[
             "collection",
             "list",
             "music",
             "playlist",
             "queue"
          ],

       },
       {
          "name":"queue_play_next",

          "tags":[
             "+",
             "add",
             "arrow",
             "desktop",
             "device",
             "display",
             "hardware",
             "monitor",
             "new",
             "next",
             "play",
             "plus",
             "queue",
             "screen",
             "steam",
             "symbol",
             "tv"
          ],

       },
       {
          "name":"quickreply",

          "tags":[
             "bolt",
             "bubble",
             "chat",
             "comment",
             "communicate",
             "fast",
             "lightning",
             "message",
             "quick",
             "quickreply",
             "reply",
             "speech",
             "thunderbolt"
          ],

       },
       {
          "name":"quiz",

          "tags":[
             "?",
             "assistance",
             "faq",
             "help",
             "info",
             "information",
             "punctuation",
             "question mark",
             "quiz",
             "support",
             "symbol",
             "test"
          ],

       },
       {
          "name":"r_mobiledata",

          "tags":[
             "alphabet",
             "character",
             "data",
             "font",
             "letter",
             "mobile",
             "r",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"radar",

          "tags":[
             "detect",
             "military",
             "near",
             "network",
             "position",
             "radar",
             "scan"
          ],

       },
       {
          "name":"radio",

          "tags":[
             "antenna",
             "audio",
             "device",
             "frequency",
             "hardware",
             "listen",
             "media",
             "music",
             "player",
             "radio",
             "signal",
             "tune"
          ],

       },
       {
          "name":"radio_button_checked",

          "tags":[
             "app",
             "application",
             "bullet",
             "button",
             "checked",
             "circle",
             "components",
             "design",
             "form",
             "interface",
             "off",
             "on",
             "point",
             "radio",
             "record",
             "screen",
             "select",
             "selected",
             "site",
             "toggle",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"radio_button_unchecked",

          "tags":[
             "bullet",
             "button",
             "circle",
             "deselected",
             "form",
             "off",
             "on",
             "point",
             "radio",
             "record",
             "select",
             "toggle",
             "unchecked"
          ],

       },
       {
          "name":"railway_alert",

          "tags":[
             "!",
             "alert",
             "attention",
             "automobile",
             "bike",
             "car",
             "cars",
             "caution",
             "danger",
             "direction",
             "error",
             "exclamation",
             "important",
             "maps",
             "mark",
             "notification",
             "public",
             "railway",
             "scooter",
             "subway",
             "symbol",
             "train",
             "transportation",
             "vehicle",
             "vespa",
             "warning"
          ],

       },
       {
          "name":"ramen_dining",

          "tags":[
             "breakfast",
             "dining",
             "dinner",
             "drink",
             "fastfood",
             "food",
             "lunch",
             "meal",
             "noodles",
             "ramen",
             "restaurant"
          ],

       },
       {
          "name":"rate_review",

          "tags":[
             "comment",
             "feedback",
             "pen",
             "pencil",
             "rate",
             "review",
             "stars",
             "write"
          ],

       },
       {
          "name":"raw_off",

          "tags":[
             "alphabet",
             "character",
             "disabled",
             "enabled",
             "font",
             "image",
             "letter",
             "off",
             "on",
             "original",
             "photo",
             "photography",
             "raw",
             "slash",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"raw_on",

          "tags":[
             "alphabet",
             "character",
             "disabled",
             "enabled",
             "font",
             "image",
             "letter",
             "off",
             "on",
             "original",
             "photo",
             "photography",
             "raw",
             "slash",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"read_more",

          "tags":[
             "arrow",
             "more",
             "read",
             "text"
          ],

       },
       {
          "name":"real_estate_agent",

          "tags":[
             
          ],

       },
       {
          "name":"receipt",

          "tags":[
             "bill",
             "credit",
             "invoice",
             "paper",
             "payment",
             "receipt",
             "sale"
          ],

       },
       {
          "name":"receipt_long",

          "tags":[
             "bill",
             "check",
             "document",
             "list",
             "long",
             "paper",
             "paperwork",
             "receipt",
             "record",
             "store",
             "transaction"
          ],

       },
       {
          "name":"recent_actors",

          "tags":[
             "account",
             "actors",
             "avatar",
             "card",
             "cards",
             "carousel",
             "face",
             "human",
             "layers",
             "list",
             "people",
             "person",
             "profile",
             "recent",
             "thumbnail",
             "user"
          ],

       },
       {
          "name":"recommend",

          "tags":[
             "approved",
             "circle",
             "confirm",
             "favorite",
             "gesture",
             "hand",
             "like",
             "reaction",
             "recommend",
             "social",
             "support",
             "thumbs",
             "up",
             "well"
          ],

       },
       {
          "name":"record_voice_over",

          "tags":[
             "account",
             "face",
             "human",
             "over",
             "people",
             "person",
             "profile",
             "record",
             "recording",
             "speak",
             "speaking",
             "speech",
             "transcript",
             "user",
             "voice"
          ],

       },
       {
          "name":"recycling",

          "tags":[
             
          ],

       },
       {
          "name":"redeem",

          "tags":[
             "bill",
             "card",
             "cart",
             "cash",
             "certificate",
             "coin",
             "commerce",
             "credit",
             "currency",
             "dollars",
             "gift",
             "giftcard",
             "money",
             "online",
             "pay",
             "payment",
             "present",
             "redeem",
             "shopping"
          ],

       },
       {
          "name":"redo",

          "tags":[
             "arrow",
             "backward",
             "forward",
             "next",
             "redo",
             "repeat",
             "rotate",
             "undo"
          ],

       },
       {
          "name":"reduce_capacity",

          "tags":[
             "arrow",
             "body",
             "capacity",
             "covid",
             "decrease",
             "down",
             "human",
             "people",
             "person",
             "reduce",
             "social"
          ],

       },
       {
          "name":"refresh",

          "tags":[
             "around",
             "arrow",
             "arrows",
             "direction",
             "inprogress",
             "load",
             "loading refresh",
             "navigation",
             "refresh",
             "renew",
             "right",
             "rotate",
             "turn"
          ],

       },
       {
          "name":"remember_me",

          "tags":[
             "Android",
             "OS",
             "avatar",
             "device",
             "hardware",
             "human",
             "iOS",
             "identity",
             "me",
             "mobile",
             "people",
             "person",
             "phone",
             "profile",
             "remember",
             "tablet",
             "user"
          ],

       },
       {
          "name":"remove",

          "tags":[
             "can",
             "delete",
             "minus",
             "negative",
             "remove",
             "substract",
             "trash"
          ],

       },
       {
          "name":"remove_circle",

          "tags":[
             "block",
             "can",
             "circle",
             "delete",
             "minus",
             "negative",
             "remove",
             "substract",
             "trash"
          ],

       },
       {
          "name":"remove_circle_outline",

          "tags":[
             "block",
             "can",
             "circle",
             "delete",
             "minus",
             "negative",
             "outline",
             "remove",
             "substract",
             "trash"
          ],

       },
       {
          "name":"remove_done",

          "tags":[
             "approve",
             "check",
             "complete",
             "disabled",
             "done",
             "enabled",
             "finished",
             "mark",
             "multiple",
             "off",
             "ok",
             "on",
             "remove",
             "select",
             "slash",
             "tick",
             "yes"
          ],

       },
       {
          "name":"remove_from_queue",

          "tags":[
             "desktop",
             "device",
             "display",
             "from",
             "hardware",
             "monitor",
             "queue",
             "remove",
             "screen",
             "steam"
          ],

       },
       {
          "name":"remove_moderator",

          "tags":[
             "certified",
             "disabled",
             "enabled",
             "moderator",
             "off",
             "on",
             "privacy",
             "private",
             "protect",
             "protection",
             "remove",
             "security",
             "shield",
             "slash",
             "verified"
          ],

       },
       {
          "name":"remove_red_eye",

          "tags":[
             "eye",
             "iris",
             "look",
             "looking",
             "preview",
             "red",
             "remove",
             "see",
             "sight",
             "vision"
          ],

       },
       {
          "name":"remove_shopping_cart",

          "tags":[
             "card",
             "cart",
             "cash",
             "checkout",
             "coin",
             "commerce",
             "credit",
             "currency",
             "disabled",
             "dollars",
             "enabled",
             "off",
             "on",
             "online",
             "pay",
             "payment",
             "remove",
             "shopping",
             "slash",
             "tick"
          ],

       },
       {
          "name":"reorder",

          "tags":[
             "format",
             "lines",
             "list",
             "order",
             "reorder",
             "stacked"
          ],

       },
       {
          "name":"repeat",

          "tags":[
             "arrow",
             "arrows",
             "control",
             "controls",
             "media",
             "music",
             "repeat",
             "video"
          ],

       },
       {
          "name":"repeat_on",

          "tags":[
             "arrow",
             "arrows",
             "control",
             "controls",
             "media",
             "music",
             "on",
             "repeat",
             "video"
          ],

       },
       {
          "name":"repeat_one",

          "tags":[
             "1",
             "arrow",
             "arrows",
             "control",
             "controls",
             "digit",
             "media",
             "music",
             "number",
             "one",
             "repeat",
             "symbol",
             "video"
          ],

       },
       {
          "name":"repeat_one_on",

          "tags":[
             "arrow",
             "arrows",
             "control",
             "controls",
             "digit",
             "media",
             "music",
             "number",
             "on",
             "one",
             "repeat",
             "symbol",
             "video"
          ],

       },
       {
          "name":"replay",

          "tags":[
             "arrow",
             "arrows",
             "control",
             "controls",
             "music",
             "refresh",
             "renew",
             "repeat",
             "replay",
             "video"
          ],

       },
       {
          "name":"replay_10",

          "tags":[
             "10",
             "arrow",
             "arrows",
             "control",
             "controls",
             "digit",
             "music",
             "number",
             "refresh",
             "renew",
             "repeat",
             "replay",
             "symbol",
             "ten",
             "video"
          ],

       },
       {
          "name":"replay_30",

          "tags":[
             "30",
             "arrow",
             "arrows",
             "control",
             "controls",
             "digit",
             "music",
             "number",
             "refresh",
             "renew",
             "repeat",
             "replay",
             "symbol",
             "thirty",
             "video"
          ],

       },
       {
          "name":"replay_5",

          "tags":[
             "5",
             "arrow",
             "arrows",
             "control",
             "controls",
             "digit",
             "five",
             "music",
             "number",
             "refresh",
             "renew",
             "repeat",
             "replay",
             "symbol",
             "video"
          ],

       },
       {
          "name":"replay_circle_filled",

          "tags":[
             "arrow",
             "arrows",
             "circle",
             "control",
             "controls",
             "filled",
             "music",
             "refresh",
             "renew",
             "repeat",
             "replay",
             "video"
          ],

       },
       {
          "name":"reply",

          "tags":[
             "arrow",
             "backward",
             "left",
             "mail",
             "message",
             "reply",
             "send",
             "share"
          ],

       },
       {
          "name":"reply_all",

          "tags":[
             "all",
             "arrow",
             "backward",
             "group",
             "left",
             "mail",
             "message",
             "multiple",
             "reply",
             "send",
             "share"
          ],

       },
       {
          "name":"report",

          "tags":[
             "!",
             "alert",
             "attention",
             "caution",
             "danger",
             "error",
             "exclamation",
             "important",
             "mark",
             "notification",
             "octagon",
             "report",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"report_gmailerrorred",

          "tags":[
             "!",
             "alert",
             "attention",
             "caution",
             "danger",
             "error",
             "exclamation",
             "gmail",
             "gmailerrorred",
             "important",
             "mark",
             "notification",
             "octagon",
             "report",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"report_off",

          "tags":[
             "!",
             "alert",
             "attention",
             "caution",
             "danger",
             "disabled",
             "enabled",
             "error",
             "exclamation",
             "important",
             "mark",
             "notification",
             "octagon",
             "off",
             "offline",
             "on",
             "report",
             "slash",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"report_problem",

          "tags":[
             "!",
             "alert",
             "attention",
             "caution",
             "danger",
             "error",
             "exclamation",
             "feedback",
             "important",
             "mark",
             "notification",
             "problem",
             "report",
             "symbol",
             "triangle",
             "warning"
          ],

       },
       {
          "name":"request_page",

          "tags":[
             
          ],

       },
       {
          "name":"request_quote",

          "tags":[
             "bill",
             "card",
             "cash",
             "coin",
             "commerce",
             "cost",
             "credit",
             "currency",
             "dollars",
             "finance",
             "money",
             "online",
             "pay",
             "payment",
             "price",
             "quote",
             "request",
             "shopping",
             "symbol"
          ],

       },
       {
          "name":"reset_tv",

          "tags":[
             "arrow",
             "device",
             "hardware",
             "monitor",
             "reset",
             "television",
             "tv"
          ],

       },
       {
          "name":"restart_alt",

          "tags":[
             "alt",
             "around",
             "arrow",
             "inprogress",
             "load",
             "loading refresh",
             "reboot",
             "renew",
             "repeat",
             "reset",
             "restart"
          ],

       },
       {
          "name":"restaurant",

          "tags":[
             "breakfast",
             "dining",
             "dinner",
             "eat",
             "food",
             "fork",
             "knife",
             "local",
             "lunch",
             "meal",
             "places",
             "restaurant",
             "spoon",
             "utensils"
          ],

       },
       {
          "name":"restaurant_menu",

          "tags":[
             "book",
             "dining",
             "eat",
             "food",
             "fork",
             "knife",
             "local",
             "meal",
             "menu",
             "restaurant",
             "spoon"
          ],

       },
       {
          "name":"restore",

          "tags":[
             "arrow",
             "back",
             "backwards",
             "clock",
             "date",
             "history",
             "refresh",
             "renew",
             "restore",
             "reverse",
             "rotate",
             "schedule",
             "time",
             "turn"
          ],

       },
       {
          "name":"restore_from_trash",

          "tags":[
             "arrow",
             "back",
             "backwards",
             "clock",
             "date",
             "history",
             "refresh",
             "renew",
             "restore",
             "reverse",
             "rotate",
             "schedule",
             "time",
             "turn"
          ],

       },
       {
          "name":"restore_page",

          "tags":[
             "arrow",
             "data",
             "doc",
             "file",
             "page",
             "paper",
             "refresh",
             "restore",
             "rotate",
             "sheet",
             "storage"
          ],

       },
       {
          "name":"reviews",

          "tags":[
             "bubble",
             "chat",
             "comment",
             "communicate",
             "feedback",
             "message",
             "rate",
             "rating",
             "recommendation",
             "reviews",
             "speech"
          ],

       },
       {
          "name":"rice_bowl",

          "tags":[
             "bowl",
             "dinner",
             "food",
             "lunch",
             "meal",
             "restaurant",
             "rice"
          ],

       },
       {
          "name":"ring_volume",

          "tags":[
             "call",
             "calling",
             "cell",
             "contact",
             "device",
             "hardware",
             "incoming",
             "mobile",
             "phone",
             "ring",
             "ringer",
             "sound",
             "telephone",
             "volume"
          ],

       },
       {
          "name":"roofing",

          "tags":[
             "architecture",
             "building",
             "chimney",
             "construction",
             "estate",
             "home",
             "house",
             "real",
             "residence",
             "residential",
             "roof",
             "roofing",
             "service",
             "shelter"
          ],

       },
       {
          "name":"room",

          "tags":[
             "destination",
             "direction",
             "location",
             "maps",
             "pin",
             "place",
             "room",
             "stop"
          ],

       },
       {
          "name":"room_preferences",

          "tags":[
             "building",
             "door",
             "doorway",
             "entrance",
             "gear",
             "home",
             "house",
             "interior",
             "office",
             "open",
             "preferences",
             "room",
             "settings"
          ],

       },
       {
          "name":"room_service",

          "tags":[
             "alert",
             "bell",
             "delivery",
             "hotel",
             "notify",
             "room",
             "service"
          ],

       },
       {
          "name":"rotate_90_degrees_ccw",

          "tags":[
             "90",
             "arrow",
             "arrows",
             "ccw",
             "degrees",
             "direction",
             "edit",
             "editing",
             "image",
             "photo",
             "rotate",
             "turn"
          ],

       },
       {
          "name":"rotate_left",

          "tags":[
             "around",
             "arrow",
             "direction",
             "inprogress",
             "left",
             "load",
             "loading refresh",
             "renew",
             "rotate",
             "turn"
          ],

       },
       {
          "name":"rotate_right",

          "tags":[
             "around",
             "arrow",
             "direction",
             "inprogress",
             "load",
             "loading refresh",
             "renew",
             "right",
             "rotate",
             "turn"
          ],

       },
       {
          "name":"rounded_corner",

          "tags":[
             "adjust",
             "corner",
             "edit",
             "rounded",
             "shape",
             "square",
             "transform"
          ],

       },
       {
          "name":"router",

          "tags":[
             "box",
             "cable",
             "connection",
             "hardware",
             "internet",
             "network",
             "router",
             "signal",
             "wifi"
          ],

       },
       {
          "name":"rowing",

          "tags":[
             "activity",
             "boat",
             "body",
             "canoe",
             "human",
             "people",
             "person",
             "row",
             "rowing",
             "sport",
             "water"
          ],

       },
       {
          "name":"rss_feed",

          "tags":[
             "application",
             "blog",
             "connection",
             "data",
             "feed",
             "internet",
             "network",
             "rss",
             "service",
             "signal",
             "website",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"rsvp",

          "tags":[
             "alphabet",
             "character",
             "font",
             "invitation",
             "invite",
             "letter",
             "plaît",
             "respond",
             "rsvp",
             "répondez",
             "sil",
             "symbol",
             "text",
             "type",
             "vous"
          ],

       },
       {
          "name":"rtt",

          "tags":[
             "call",
             "real",
             "rrt",
             "text",
             "time"
          ],

       },
       {
          "name":"rule",

          "tags":[
             "approve",
             "check",
             "complete",
             "done",
             "incomplete",
             "line",
             "mark",
             "missing",
             "no",
             "ok",
             "rule",
             "select",
             "tick",
             "validate",
             "verified",
             "wrong",
             "x",
             "yes"
          ],

       },
       {
          "name":"rule_folder",

          "tags":[
             "approve",
             "cancel",
             "check",
             "close",
             "complete",
             "data",
             "doc",
             "document",
             "done",
             "drive",
             "exit",
             "file",
             "folder",
             "mark",
             "no",
             "ok",
             "remove",
             "rule",
             "select",
             "sheet",
             "slide",
             "storage",
             "tick",
             "validate",
             "verified",
             "x",
             "yes"
          ],

       },
       {
          "name":"run_circle",

          "tags":[
             "body",
             "circle",
             "exercise",
             "human",
             "people",
             "person",
             "run",
             "running"
          ],

       },
       {
          "name":"running_with_errors",

          "tags":[
             "!",
             "alert",
             "attention",
             "caution",
             "danger",
             "duration",
             "error",
             "errors",
             "exclamation",
             "important",
             "mark",
             "notification",
             "process",
             "processing",
             "running",
             "symbol",
             "time",
             "warning",
             "with"
          ],

       },
       {
          "name":"rv_hookup",

          "tags":[
             "arrow",
             "attach",
             "automobile",
             "automotive",
             "back",
             "car",
             "cars",
             "connect",
             "direction",
             "hookup",
             "left",
             "maps",
             "public",
             "right",
             "rv",
             "trailer",
             "transportation",
             "travel",
             "truck",
             "van",
             "vehicle"
          ],

       },
       {
          "name":"safety_divider",

          "tags":[
             "apart",
             "distance",
             "divider",
             "safety",
             "separate",
             "social",
             "space"
          ],

       },
       {
          "name":"sailing",

          "tags":[
             "boat",
             "entertainment",
             "fishing",
             "hobby",
             "ocean",
             "sailboat",
             "sailing",
             "sea",
             "social sports",
             "travel",
             "water"
          ],

       },
       {
          "name":"sanitizer",

          "tags":[
             "bacteria",
             "bottle",
             "clean",
             "covid",
             "disinfect",
             "germs",
             "pump",
             "sanitizer"
          ],

       },
       {
          "name":"satellite",

          "tags":[
             "bluetooth",
             "connect",
             "connection",
             "connectivity",
             "data",
             "device",
             "image",
             "internet",
             "landscape",
             "location",
             "maps",
             "mountain",
             "mountains",
             "network",
             "photo",
             "photography",
             "picture",
             "satellite",
             "scan",
             "service",
             "signal",
             "symbol",
             "wireless-- wifi"
          ],

       },
       {
          "name":"save",

          "tags":[
             "data",
             "disk",
             "document",
             "drive",
             "file",
             "floppy",
             "multimedia",
             "save",
             "storage"
          ],

       },
       {
          "name":"save_alt",

          "tags":[
             "alt",
             "arrow",
             "disk",
             "document",
             "down",
             "file",
             "floppy",
             "multimedia",
             "save"
          ],

       },
       {
          "name":"saved_search",

          "tags":[
             "find",
             "glass",
             "important",
             "look",
             "magnify",
             "magnifying",
             "marked",
             "saved",
             "search",
             "see",
             "star"
          ],

       },
       {
          "name":"savings",

          "tags":[
             "bank",
             "bill",
             "card",
             "cash",
             "coin",
             "commerce",
             "cost",
             "credit",
             "currency",
             "dollars",
             "finance",
             "money",
             "online",
             "pay",
             "payment",
             "pig",
             "piggy",
             "savings",
             "symbol"
          ],

       },
       {
          "name":"scanner",

          "tags":[
             "copy",
             "device",
             "hardware",
             "machine",
             "scan",
             "scanner"
          ],

       },
       {
          "name":"scatter_plot",

          "tags":[
             "analytics",
             "bar",
             "bars",
             "chart",
             "circles",
             "data",
             "diagram",
             "dot",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "plot",
             "scatter",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"schedule",

          "tags":[
             "clock",
             "date",
             "schedule",
             "time"
          ],

       },
       {
          "name":"schedule_send",

          "tags":[
             "calendar",
             "clock",
             "date",
             "email",
             "letter",
             "mail",
             "remember",
             "schedule",
             "send",
             "share",
             "time"
          ],

       },
       {
          "name":"schema",

          "tags":[
             
          ],

       },
       {
          "name":"school",

          "tags":[
             "academy",
             "achievement",
             "cap",
             "class",
             "college",
             "education",
             "graduation",
             "hat",
             "knowledge",
             "learning",
             "school",
             "university"
          ],

       },
       {
          "name":"science",

          "tags":[
             "beaker",
             "chemical",
             "chemistry",
             "experiment",
             "flask",
             "glass",
             "laboratory",
             "research",
             "science",
             "tube"
          ],

       },
       {
          "name":"score",

          "tags":[
             "2k",
             "alphabet",
             "analytics",
             "bar",
             "bars",
             "character",
             "chart",
             "data",
             "diagram",
             "digit",
             "font",
             "graph",
             "infographic",
             "letter",
             "measure",
             "metrics",
             "number",
             "score",
             "statistics",
             "symbol",
             "text",
             "tracking",
             "type"
          ],

       },
       {
          "name":"screen_lock_landscape",

          "tags":[
             "Android",
             "OS",
             "device",
             "hardware",
             "iOS",
             "landscape",
             "lock",
             "mobile",
             "phone",
             "rotate",
             "screen",
             "tablet"
          ],

       },
       {
          "name":"screen_lock_portrait",

          "tags":[
             "Android",
             "OS",
             "device",
             "hardware",
             "iOS",
             "lock",
             "mobile",
             "phone",
             "portrait",
             "rotate",
             "screen",
             "tablet"
          ],

       },
       {
          "name":"screen_lock_rotation",

          "tags":[
             "Android",
             "OS",
             "arrow",
             "device",
             "hardware",
             "iOS",
             "lock",
             "mobile",
             "phone",
             "rotate",
             "rotation",
             "screen",
             "tablet",
             "turn"
          ],

       },
       {
          "name":"screen_rotation",

          "tags":[
             "Android",
             "OS",
             "arrow",
             "device",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "rotate",
             "rotation",
             "screen",
             "tablet",
             "turn"
          ],

       },
       {
          "name":"screen_search_desktop",

          "tags":[
             "Android",
             "OS",
             "arrow",
             "desktop",
             "device",
             "hardware",
             "iOS",
             "lock",
             "monitor",
             "rotate",
             "screen",
             "web"
          ],

       },
       {
          "name":"screen_share",

          "tags":[
             "Android",
             "OS",
             "arrow",
             "cast",
             "chrome",
             "device",
             "display",
             "hardware",
             "iOS",
             "laptop",
             "mac",
             "mirror",
             "monitor",
             "screen",
             "share",
             "steam",
             "streaming",
             "web",
             "window"
          ],

       },
       {
          "name":"screenshot",

          "tags":[
             "Android",
             "OS",
             "cell",
             "crop",
             "device",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "screen",
             "screenshot",
             "tablet"
          ],

       },
       {
          "name":"sd",

          "tags":[
             "alphabet",
             "camera",
             "card",
             "character",
             "data",
             "device",
             "digital",
             "drive",
             "flash",
             "font",
             "image",
             "letter",
             "memory",
             "photo",
             "sd",
             "secure",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"sd_card",

          "tags":[
             "camera",
             "card",
             "digital",
             "memory",
             "photos",
             "sd",
             "secure",
             "storage"
          ],

       },
       {
          "name":"sd_card_alert",

          "tags":[
             "!",
             "alert",
             "attention",
             "camera",
             "card",
             "caution",
             "danger",
             "digital",
             "error",
             "exclamation",
             "important",
             "mark",
             "memory",
             "notification",
             "photos",
             "sd",
             "secure",
             "storage",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"sd_storage",

          "tags":[
             "camera",
             "card",
             "data",
             "digital",
             "memory",
             "sd",
             "secure",
             "storage"
          ],

       },
       {
          "name":"search",

          "tags":[
             "filter",
             "find",
             "glass",
             "look",
             "magnify",
             "magnifying",
             "search",
             "see"
          ],

       },
       {
          "name":"search_off",

          "tags":[
             "cancel",
             "close",
             "disabled",
             "enabled",
             "find",
             "glass",
             "look",
             "magnify",
             "magnifying",
             "off",
             "on",
             "search",
             "see",
             "slash",
             "stop",
             "x"
          ],

       },
       {
          "name":"security",

          "tags":[
             "certified",
             "privacy",
             "private",
             "protect",
             "protection",
             "security",
             "shield",
             "verified"
          ],

       },
       {
          "name":"security_update",

          "tags":[
             "Android",
             "OS",
             "arrow",
             "device",
             "down",
             "download",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "security",
             "tablet",
             "update"
          ],

       },
       {
          "name":"security_update_good",

          "tags":[
             "Android",
             "OS",
             "checkmark",
             "device",
             "good",
             "hardware",
             "iOS",
             "mobile",
             "ok",
             "phone",
             "security",
             "tablet",
             "tick",
             "update"
          ],

       },
       {
          "name":"security_update_warning",

          "tags":[
             "!",
             "Android",
             "OS",
             "alert",
             "attention",
             "caution",
             "danger",
             "device",
             "download",
             "error",
             "exclamation",
             "hardware",
             "iOS",
             "important",
             "mark",
             "mobile",
             "notification",
             "phone",
             "security",
             "symbol",
             "tablet",
             "update",
             "warning"
          ],

       },
       {
          "name":"segment",

          "tags":[
             "alignment",
             "fonts",
             "format",
             "lines",
             "list",
             "paragraph",
             "part",
             "piece",
             "rule",
             "rules",
             "segment",
             "style",
             "text"
          ],

       },
       {
          "name":"select_all",

          "tags":[
             "all",
             "select",
             "selection",
             "square",
             "tool"
          ],

       },
       {
          "name":"self_improvement",

          "tags":[
             "body",
             "calm",
             "care",
             "chi",
             "human",
             "improvement",
             "meditate",
             "meditation",
             "people",
             "person",
             "relax",
             "self",
             "sitting",
             "wellbeing",
             "yoga",
             "zen"
          ],

       },
       {
          "name":"sell",

          "tags":[
             "bill",
             "card",
             "cart",
             "cash",
             "coin",
             "commerce",
             "credit",
             "currency",
             "dollars",
             "money",
             "online",
             "pay",
             "payment",
             "price",
             "sell",
             "shopping",
             "tag"
          ],

       },
       {
          "name":"send",

          "tags":[
             "email",
             "mail",
             "message",
             "paper",
             "plane",
             "reply",
             "right",
             "send",
             "share"
          ],

       },
       {
          "name":"send_and_archive",

          "tags":[
             "archive",
             "arrow",
             "down",
             "download",
             "email",
             "letter",
             "mail",
             "save",
             "send",
             "share"
          ],

       },
       {
          "name":"send_to_mobile",

          "tags":[
             "Android",
             "OS",
             "arrow",
             "device",
             "export",
             "forward",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "right",
             "send",
             "share",
             "tablet",
             "to"
          ],

       },
       {
          "name":"sensor_door",

          "tags":[
             
          ],

       },
       {
          "name":"sensor_window",

          "tags":[
             
          ],

       },
       {
          "name":"sensors",

          "tags":[
             "connection",
             "network",
             "scan",
             "sensors",
             "signal",
             "wireless"
          ],

       },
       {
          "name":"sensors_off",

          "tags":[
             "connection",
             "disabled",
             "enabled",
             "network",
             "off",
             "on",
             "scan",
             "sensors",
             "signal",
             "slash",
             "wireless"
          ],

       },
       {
          "name":"sentiment_dissatisfied",

          "tags":[
             "angry",
             "disappointed",
             "dislike",
             "dissatisfied",
             "emotions",
             "expressions",
             "face",
             "feelings",
             "frown",
             "mood",
             "person",
             "sad",
             "sentiment",
             "survey",
             "unhappy",
             "unsatisfied",
             "upset"
          ],

       },
       {
          "name":"sentiment_neutral",

          "tags":[
             "emotionless",
             "emotions",
             "expressions",
             "face",
             "feelings",
             "fine",
             "indifference",
             "mood",
             "neutral",
             "okay",
             "person",
             "sentiment",
             "survey"
          ],

       },
       {
          "name":"sentiment_satisfied",

          "tags":[
             "emotions",
             "expressions",
             "face",
             "feelings",
             "glad",
             "happiness",
             "happy",
             "like",
             "mood",
             "person",
             "pleased",
             "satisfied",
             "sentiment",
             "smile",
             "smiling",
             "survey"
          ],

       },
       {
          "name":"sentiment_satisfied_alt",

          "tags":[
             "account",
             "alt",
             "emoji",
             "face",
             "happy",
             "human",
             "people",
             "person",
             "profile",
             "satisfied",
             "sentiment",
             "smile",
             "user"
          ],

       },
       {
          "name":"sentiment_very_dissatisfied",

          "tags":[
             "angry",
             "disappointed",
             "dislike",
             "dissatisfied",
             "emotions",
             "expressions",
             "face",
             "feelings",
             "mood",
             "person",
             "sad",
             "sentiment",
             "sorrow",
             "survey",
             "unhappy",
             "unsatisfied",
             "upset",
             "very"
          ],

       },
       {
          "name":"sentiment_very_satisfied",

          "tags":[
             "emotions",
             "expressions",
             "face",
             "feelings",
             "glad",
             "happiness",
             "happy",
             "like",
             "mood",
             "person",
             "pleased",
             "satisfied",
             "sentiment",
             "smile",
             "smiling",
             "survey",
             "very"
          ],

       },
       {
          "name":"set_meal",

          "tags":[
             "chopsticks",
             "dinner",
             "fish",
             "food",
             "lunch",
             "meal",
             "restaurant",
             "set",
             "teishoku"
          ],

       },
       {
          "name":"settings",

          "tags":[
             "application",
             "change",
             "details",
             "gear",
             "info",
             "information",
             "options",
             "personal",
             "service",
             "settings"
          ],

       },
       {
          "name":"settings_accessibility",

          "tags":[
             "accessibility",
             "body",
             "details",
             "human",
             "information",
             "people",
             "person",
             "personal",
             "preferences",
             "profile",
             "settings",
             "user"
          ],

       },
       {
          "name":"settings_applications",

          "tags":[
             "application",
             "change",
             "details",
             "gear",
             "info",
             "information",
             "options",
             "personal",
             "service",
             "settings"
          ],

       },
       {
          "name":"settings_backup_restore",

          "tags":[
             "arrow",
             "back",
             "backup",
             "backwards",
             "refresh",
             "restore",
             "reverse",
             "rotate",
             "settings"
          ],

       },
       {
          "name":"settings_bluetooth",

          "tags":[
             "bluetooth",
             "connect",
             "connection",
             "connectivity",
             "device",
             "settings",
             "signal",
             "symbol"
          ],

       },
       {
          "name":"settings_brightness",

          "tags":[
             "brightness",
             "dark",
             "filter",
             "light",
             "mode",
             "setting",
             "settings"
          ],

       },
       {
          "name":"settings_cell",

          "tags":[
             "Android",
             "OS",
             "cell",
             "device",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "settings",
             "tablet"
          ],

       },
       {
          "name":"settings_ethernet",

          "tags":[
             "arrows",
             "computer",
             "connect",
             "connection",
             "connectivity",
             "dots",
             "ethernet",
             "internet",
             "network",
             "settings",
             "wifi"
          ],

       },
       {
          "name":"settings_input_antenna",

          "tags":[
             "airplay",
             "antenna",
             "arrows",
             "cast",
             "computer",
             "connect",
             "connection",
             "connectivity",
             "dots",
             "input",
             "internet",
             "network",
             "screencast",
             "settings",
             "stream",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"settings_input_component",

          "tags":[
             "audio",
             "av",
             "cable",
             "cables",
             "component",
             "connect",
             "connection",
             "connectivity",
             "input",
             "internet",
             "plug",
             "points",
             "settings",
             "video",
             "wifi"
          ],

       },
       {
          "name":"settings_input_composite",

          "tags":[
             "component",
             "composite",
             "connection",
             "connectivity",
             "input",
             "plug",
             "points",
             "settings"
          ],

       },
       {
          "name":"settings_input_hdmi",

          "tags":[
             "cable",
             "connection",
             "connectivity",
             "definition",
             "hdmi",
             "high",
             "input",
             "plug",
             "plugin",
             "points",
             "settings",
             "video",
             "wire"
          ],

       },
       {
          "name":"settings_input_svideo",

          "tags":[
             "cable",
             "connection",
             "connectivity",
             "definition",
             "input",
             "plug",
             "plugin",
             "points",
             "settings",
             "standard",
             "svideo",
             "video"
          ],

       },
       {
          "name":"settings_overscan",

          "tags":[
             "arrows",
             "expand",
             "image",
             "photo",
             "picture",
             "scan",
             "settings"
          ],

       },
       {
          "name":"settings_phone",

          "tags":[
             "call",
             "cell",
             "contact",
             "device",
             "hardware",
             "mobile",
             "phone",
             "settings",
             "telephone"
          ],

       },
       {
          "name":"settings_power",

          "tags":[
             "info",
             "information",
             "off",
             "on",
             "power",
             "save",
             "settings",
             "shutdown"
          ],

       },
       {
          "name":"settings_remote",

          "tags":[
             "bluetooth",
             "connection",
             "connectivity",
             "device",
             "remote",
             "settings",
             "signal",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"settings_suggest",

          "tags":[
             "change",
             "details",
             "gear",
             "options",
             "recommendation",
             "service",
             "settings",
             "suggest",
             "suggestion",
             "system"
          ],

       },
       {
          "name":"settings_system_daydream",

          "tags":[
             "backup",
             "cloud",
             "daydream",
             "drive",
             "settings",
             "storage",
             "system"
          ],

       },
       {
          "name":"settings_voice",

          "tags":[
             "mic",
             "microphone",
             "record",
             "recorder",
             "settings",
             "speaker",
             "voice"
          ],

       },
       {
          "name":"share",

          "tags":[
             "android",
             "connect",
             "contect",
             "link",
             "media",
             "multimedia",
             "multiple",
             "network",
             "options",
             "send",
             "share",
             "shared",
             "sharing",
             "social"
          ],

       },
       {
          "name":"share_location",

          "tags":[
             "destination",
             "direction",
             "gps",
             "location",
             "maps",
             "pin",
             "place",
             "share",
             "stop",
             "tracking"
          ],

       },
       {
          "name":"shield",

          "tags":[
             "certified",
             "privacy",
             "private",
             "protect",
             "protection",
             "secure",
             "security",
             "shield",
             "verified"
          ],

       },
       {
          "name":"shop",

          "tags":[
             "bag",
             "bill",
             "buy",
             "card",
             "cart",
             "cash",
             "coin",
             "commerce",
             "credit",
             "currency",
             "dollars",
             "google",
             "money",
             "online",
             "pay",
             "payment",
             "play",
             "shop",
             "shopping",
             "store"
          ],

       },
       {
          "name":"shop_2",

          "tags":[
             
          ],

       },
       {
          "name":"shop_two",

          "tags":[
             "add",
             "arrow",
             "buy",
             "cart",
             "google",
             "play",
             "purchase",
             "shop",
             "shopping",
             "two"
          ],

       },
       {
          "name":"shopping_bag",

          "tags":[
             "bag",
             "bill",
             "business",
             "buy",
             "card",
             "cart",
             "cash",
             "coin",
             "commerce",
             "credit",
             "currency",
             "dollars",
             "money",
             "online",
             "pay",
             "payment",
             "shop",
             "shopping",
             "store",
             "storefront"
          ],

       },
       {
          "name":"shopping_basket",

          "tags":[
             "add",
             "basket",
             "bill",
             "buy",
             "card",
             "cart",
             "cash",
             "checkout",
             "coin",
             "commerce",
             "credit",
             "currency",
             "dollars",
             "money",
             "online",
             "pay",
             "payment",
             "shopping"
          ],

       },
       {
          "name":"shopping_cart",

          "tags":[
             "add",
             "bill",
             "buy",
             "card",
             "cart",
             "cash",
             "checkout",
             "coin",
             "commerce",
             "credit",
             "currency",
             "dollars",
             "money",
             "online",
             "pay",
             "payment",
             "shopping"
          ],

       },
       {
          "name":"short_text",

          "tags":[
             "brief",
             "comment",
             "doc",
             "document",
             "note",
             "short",
             "text",
             "write",
             "writing"
          ],

       },
       {
          "name":"shortcut",

          "tags":[
             "arrow",
             "direction",
             "forward",
             "right",
             "shortcut"
          ],

       },
       {
          "name":"show_chart",

          "tags":[
             "analytics",
             "bar",
             "bars",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "line",
             "measure",
             "metrics",
             "presentation",
             "show chart",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"shower",

          "tags":[
             "bath",
             "bathroom",
             "closet",
             "home",
             "house",
             "place",
             "plumbing",
             "room",
             "shower",
             "sprinkler",
             "wash",
             "water",
             "wc"
          ],

       },
       {
          "name":"shuffle",

          "tags":[
             "arrow",
             "arrows",
             "control",
             "controls",
             "music",
             "random",
             "shuffle",
             "video"
          ],

       },
       {
          "name":"shuffle_on",

          "tags":[
             "arrow",
             "arrows",
             "control",
             "controls",
             "music",
             "on",
             "random",
             "shuffle",
             "video"
          ],

       },
       {
          "name":"shutter_speed",

          "tags":[
             "aperture",
             "camera",
             "duration",
             "image",
             "lens",
             "photo",
             "photography",
             "photos",
             "picture",
             "setting",
             "shutter",
             "speed",
             "stop",
             "time",
             "timer",
             "watch"
          ],

       },
       {
          "name":"sick",

          "tags":[
             "covid",
             "discomfort",
             "emotions",
             "expressions",
             "face",
             "feelings",
             "fever",
             "flu",
             "ill",
             "mood",
             "pain",
             "person",
             "sick",
             "survey",
             "upset"
          ],

       },
       {
          "name":"signal_cellular_0_bar",

          "tags":[
             "0",
             "bar",
             "cell",
             "cellular",
             "data",
             "internet",
             "mobile",
             "network",
             "phone",
             "signal",
             "speed",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"signal_cellular_4_bar",

          "tags":[
             "4",
             "bar",
             "cell",
             "cellular",
             "data",
             "internet",
             "mobile",
             "network",
             "phone",
             "signal",
             "speed",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"signal_cellular_alt",

          "tags":[
             "alt",
             "analytics",
             "bar",
             "cell",
             "cellular",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "internet",
             "measure",
             "metrics",
             "mobile",
             "network",
             "phone",
             "signal",
             "statistics",
             "tracking",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"signal_cellular_connected_no_internet_0_bar",

          "tags":[
             "!",
             "0",
             "alert",
             "attention",
             "bar",
             "caution",
             "cell",
             "cellular",
             "connected",
             "danger",
             "data",
             "error",
             "exclamation",
             "important",
             "internet",
             "mark",
             "mobile",
             "network",
             "no",
             "notification",
             "phone",
             "signal",
             "symbol",
             "warning",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"signal_cellular_connected_no_internet_4_bar",

          "tags":[
             "!",
             "4",
             "alert",
             "attention",
             "bar",
             "caution",
             "cell",
             "cellular",
             "connected",
             "danger",
             "data",
             "error",
             "exclamation",
             "important",
             "internet",
             "mark",
             "mobile",
             "network",
             "no",
             "notification",
             "phone",
             "signal",
             "symbol",
             "warning",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"signal_cellular_no_sim",

          "tags":[
             "camera",
             "card",
             "cellular",
             "chip",
             "device",
             "disabled",
             "enabled",
             "memory",
             "no",
             "off",
             "offline",
             "on",
             "phone",
             "signal",
             "sim",
             "slash",
             "storage"
          ],

       },
       {
          "name":"signal_cellular_nodata",

          "tags":[
             "cell",
             "cellular",
             "data",
             "internet",
             "mobile",
             "network",
             "no",
             "nodata",
             "offline",
             "phone",
             "quit",
             "signal",
             "wifi",
             "wireless",
             "x"
          ],

       },
       {
          "name":"signal_cellular_null",

          "tags":[
             "cell",
             "cellular",
             "data",
             "internet",
             "mobile",
             "network",
             "null",
             "phone",
             "signal",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"signal_cellular_off",

          "tags":[
             "cell",
             "cellular",
             "data",
             "disabled",
             "enabled",
             "internet",
             "mobile",
             "network",
             "off",
             "offline",
             "on",
             "phone",
             "signal",
             "slash",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"signal_wifi_0_bar",

          "tags":[
             "0",
             "bar",
             "cell",
             "cellular",
             "data",
             "internet",
             "mobile",
             "network",
             "phone",
             "signal",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"signal_wifi_4_bar",

          "tags":[
             "4",
             "bar",
             "cell",
             "cellular",
             "data",
             "internet",
             "mobile",
             "network",
             "phone",
             "signal",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"signal_wifi_4_bar_lock",

          "tags":[
             "4",
             "bar",
             "cell",
             "cellular",
             "data",
             "internet",
             "lock",
             "locked",
             "mobile",
             "network",
             "password",
             "phone",
             "privacy",
             "private",
             "protection",
             "safety",
             "secure",
             "security",
             "signal",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"signal_wifi_bad",

          "tags":[
             "bad",
             "bar",
             "cancel",
             "cell",
             "cellular",
             "close",
             "data",
             "exit",
             "internet",
             "mobile",
             "network",
             "no",
             "phone",
             "quit",
             "remove",
             "signal",
             "stop",
             "wifi",
             "wireless",
             "x"
          ],

       },
       {
          "name":"signal_wifi_connected_no_internet_4",

          "tags":[
             "4",
             "cell",
             "cellular",
             "connected",
             "data",
             "internet",
             "mobile",
             "network",
             "no",
             "offline",
             "phone",
             "signal",
             "wifi",
             "wireless",
             "x"
          ],

       },
       {
          "name":"signal_wifi_off",

          "tags":[
             "cell",
             "cellular",
             "data",
             "disabled",
             "enabled",
             "internet",
             "mobile",
             "network",
             "off",
             "on",
             "phone",
             "signal",
             "slash",
             "speed",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"signal_wifi_statusbar_4_bar",

          "tags":[
             "4",
             "bar",
             "cell",
             "cellular",
             "data",
             "internet",
             "mobile",
             "network",
             "phone",
             "signal",
             "speed",
             "statusbar",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"signal_wifi_statusbar_connected_no_internet_4",

          "tags":[
             "!",
             "4",
             "alert",
             "attention",
             "caution",
             "cell",
             "cellular",
             "connected",
             "danger",
             "data",
             "error",
             "exclamation",
             "important",
             "internet",
             "mark",
             "mobile",
             "network",
             "no",
             "notification",
             "phone",
             "signal",
             "speed",
             "statusbar",
             "symbol",
             "warning",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"signal_wifi_statusbar_null",

          "tags":[
             "cell",
             "cellular",
             "data",
             "internet",
             "mobile",
             "network",
             "null",
             "phone",
             "signal",
             "speed",
             "statusbar",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"sim_card",

          "tags":[
             "camera",
             "card",
             "chip",
             "device",
             "memory",
             "phone",
             "sim",
             "storage"
          ],

       },
       {
          "name":"sim_card_alert",

          "tags":[
             "!",
             "alert",
             "attention",
             "camera",
             "card",
             "caution",
             "danger",
             "digital",
             "error",
             "exclamation",
             "important",
             "mark",
             "memory",
             "notification",
             "photos",
             "sd",
             "secure",
             "storage",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"sim_card_download",

          "tags":[
             "arrow",
             "camera",
             "card",
             "chip",
             "device",
             "down",
             "download",
             "memory",
             "phone",
             "sim",
             "storage"
          ],

       },
       {
          "name":"single_bed",

          "tags":[
             "bed",
             "bedroom",
             "double",
             "furniture",
             "home",
             "hotel",
             "house",
             "king",
             "night",
             "pillows",
             "queen",
             "rest",
             "room",
             "single",
             "sleep",
             "twin"
          ],

       },
       {
          "name":"sip",

          "tags":[
             "alphabet",
             "call",
             "character",
             "dialer",
             "font",
             "initiation",
             "internet",
             "letter",
             "over",
             "phone",
             "protocol",
             "routing",
             "session",
             "sip",
             "symbol",
             "text",
             "type",
             "voice"
          ],

       },
       {
          "name":"skateboarding",

          "tags":[
             "athlete",
             "athletic",
             "body",
             "entertainment",
             "exercise",
             "hobby",
             "human",
             "people",
             "person",
             "skate",
             "skateboarder",
             "skateboarding",
             "social",
             "sports"
          ],

       },
       {
          "name":"skip_next",

          "tags":[
             "arrow",
             "control",
             "controls",
             "forward",
             "music",
             "next",
             "play",
             "previous",
             "skip",
             "transport",
             "video"
          ],

       },
       {
          "name":"skip_previous",

          "tags":[
             "arrow",
             "backward",
             "control",
             "controls",
             "music",
             "next",
             "play",
             "previous",
             "skip",
             "transport",
             "video"
          ],

       },
       {
          "name":"sledding",

          "tags":[
             "athlete",
             "athletic",
             "body",
             "entertainment",
             "exercise",
             "hobby",
             "human",
             "people",
             "person",
             "sled",
             "sledding",
             "sledge",
             "snow",
             "social",
             "sports",
             "travel",
             "winter"
          ],

       },
       {
          "name":"slideshow",

          "tags":[
             "movie",
             "photos",
             "play",
             "slideshow",
             "square",
             "video",
             "view"
          ],

       },
       {
          "name":"slow_motion_video",

          "tags":[
             "arrow",
             "control",
             "controls",
             "motion",
             "music",
             "play",
             "slow",
             "speed",
             "video"
          ],

       },
       {
          "name":"smart_button",

          "tags":[
             "action",
             "auto",
             "button",
             "components",
             "composer",
             "function",
             "interface",
             "site",
             "smart",
             "special",
             "stars",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"smart_display",

          "tags":[
             "airplay",
             "cast",
             "chrome",
             "connect",
             "device",
             "display",
             "play",
             "screen",
             "screencast",
             "smart",
             "stream",
             "television",
             "tv",
             "video",
             "wireless"
          ],

       },
       {
          "name":"smart_screen",

          "tags":[
             "Android",
             "OS",
             "airplay",
             "cast",
             "cell",
             "connect",
             "device",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "screen",
             "screencast",
             "smart",
             "stream",
             "tablet",
             "video"
          ],

       },
       {
          "name":"smart_toy",

          "tags":[
             "games",
             "robot",
             "smart",
             "toy"
          ],

       },
       {
          "name":"smartphone",

          "tags":[
             "Android",
             "OS",
             "call",
             "cell",
             "chat",
             "device",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "smartphone",
             "tablet",
             "text"
          ],

       },
       {
          "name":"smoke_free",

          "tags":[
             "cigarette",
             "disabled",
             "enabled",
             "free",
             "never",
             "no",
             "off",
             "on",
             "places",
             "prohibited",
             "slash",
             "smoke",
             "smoking",
             "tobacco",
             "warning",
             "zone"
          ],

       },
       {
          "name":"smoking_rooms",

          "tags":[
             "allowed",
             "cigarette",
             "places",
             "rooms",
             "smoke",
             "smoking",
             "tobacco",
             "zone"
          ],

       },
       {
          "name":"sms",

          "tags":[
             "3",
             "bubble",
             "chat",
             "communication",
             "conversation",
             "dots",
             "message",
             "more",
             "service",
             "sms",
             "speech",
             "three"
          ],

       },
       {
          "name":"sms_failed",

          "tags":[
             "!",
             "alert",
             "attention",
             "bubbles",
             "caution",
             "chat",
             "communication",
             "conversation",
             "danger",
             "error",
             "exclamation",
             "failed",
             "important",
             "mark",
             "message",
             "notification",
             "service",
             "sms",
             "speech",
             "symbol",
             "warning"
          ],

       },
       {
          "name":"snippet_folder",

          "tags":[
             "data",
             "doc",
             "document",
             "drive",
             "file",
             "folder",
             "sheet",
             "slide",
             "snippet",
             "storage"
          ],

       },
       {
          "name":"snooze",

          "tags":[
             "alarm",
             "bell",
             "clock",
             "duration",
             "notification",
             "snooze",
             "time",
             "timer",
             "watch",
             "z"
          ],

       },
       {
          "name":"snowboarding",

          "tags":[
             "athlete",
             "athletic",
             "body",
             "entertainment",
             "exercise",
             "hobby",
             "human",
             "people",
             "person",
             "snow",
             "snowboarding",
             "social",
             "sports",
             "travel",
             "winter"
          ],

       },
       {
          "name":"snowmobile",

          "tags":[
             "automobile",
             "car",
             "direction",
             "skimobile",
             "snow",
             "snowmobile",
             "social",
             "sports",
             "transportation",
             "travel",
             "vehicle",
             "winter"
          ],

       },
       {
          "name":"snowshoeing",

          "tags":[
             "body",
             "human",
             "people",
             "person",
             "snow",
             "snowshoe",
             "snowshoeing",
             "sports",
             "travel",
             "winter"
          ],

       },
       {
          "name":"soap",

          "tags":[
             "bathroom",
             "clean",
             "fingers",
             "gesture",
             "hand",
             "soap",
             "wash",
             "wc"
          ],

       },
       {
          "name":"social_distance",

          "tags":[
             "6",
             "apart",
             "body",
             "distance",
             "ft",
             "human",
             "people",
             "person",
             "social",
             "space"
          ],

       },
       {
          "name":"sort",

          "tags":[
             "filter",
             "find",
             "lines",
             "list",
             "organize",
             "sort"
          ],

       },
       {
          "name":"sort_by_alpha",

          "tags":[
             "alphabet",
             "alphabetize",
             "az",
             "by alpha",
             "character",
             "font",
             "letter",
             "list",
             "order",
             "organize",
             "sort",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"soup_kitchen",

          "tags":[
             
          ],

       },
       {
          "name":"source",

          "tags":[
             "code",
             "composer",
             "content",
             "creation",
             "data",
             "doc",
             "document",
             "file",
             "folder",
             "mode",
             "source",
             "storage",
             "view"
          ],

       },
       {
          "name":"south",

          "tags":[
             "arrow",
             "directional",
             "down",
             "maps",
             "navigation",
             "south"
          ],

       },
       {
          "name":"south_east",

          "tags":[
             "arrow",
             "directional",
             "down",
             "east",
             "maps",
             "navigation",
             "right",
             "south"
          ],

       },
       {
          "name":"south_west",

          "tags":[
             "arrow",
             "directional",
             "down",
             "left",
             "maps",
             "navigation",
             "south",
             "west"
          ],

       },
       {
          "name":"spa",

          "tags":[
             "aromatherapy",
             "flower",
             "healthcare",
             "leaf",
             "massage",
             "meditation",
             "nature",
             "petals",
             "places",
             "relax",
             "spa",
             "wellbeing",
             "wellness"
          ],

       },
       {
          "name":"space_bar",

          "tags":[
             "bar",
             "keyboard",
             "line",
             "space"
          ],

       },
       {
          "name":"space_dashboard",

          "tags":[
             
          ],

       },
       {
          "name":"speaker",

          "tags":[
             "box",
             "electronic",
             "loud",
             "music",
             "sound",
             "speaker",
             "stereo",
             "system",
             "video"
          ],

       },
       {
          "name":"speaker_group",

          "tags":[
             "box",
             "electronic",
             "group",
             "loud",
             "multiple",
             "music",
             "sound",
             "speaker",
             "stereo",
             "system",
             "video"
          ],

       },
       {
          "name":"speaker_notes",

          "tags":[
             "bubble",
             "chat",
             "comment",
             "communicate",
             "format",
             "list",
             "message",
             "notes",
             "speaker",
             "speech",
             "text"
          ],

       },
       {
          "name":"speaker_notes_off",

          "tags":[
             "bubble",
             "chat",
             "comment",
             "communicate",
             "disabled",
             "enabled",
             "format",
             "list",
             "message",
             "notes",
             "off",
             "on",
             "slash",
             "speaker",
             "speech",
             "text"
          ],

       },
       {
          "name":"speaker_phone",

          "tags":[
             "Android",
             "OS",
             "cell",
             "device",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "sound",
             "speaker",
             "tablet",
             "volume"
          ],

       },
       {
          "name":"speed",

          "tags":[
             "arrow",
             "control",
             "controls",
             "fast",
             "gauge",
             "meter",
             "motion",
             "music",
             "slow",
             "speed",
             "speedometer",
             "velocity",
             "video"
          ],

       },
       {
          "name":"spellcheck",

          "tags":[
             "a",
             "alphabet",
             "approve",
             "character",
             "check",
             "font",
             "letter",
             "mark",
             "ok",
             "processor",
             "select",
             "spell",
             "spellcheck",
             "symbol",
             "text",
             "tick",
             "type",
             "word",
             "write",
             "yes"
          ],

       },
       {
          "name":"splitscreen",

          "tags":[
             "grid",
             "layout",
             "multitasking",
             "screen",
             "split",
             "splitscreen",
             "two"
          ],

       },
       {
          "name":"sports",

          "tags":[
             "athlete",
             "athletic",
             "ball",
             "basketball",
             "blowing",
             "coach",
             "entertainment",
             "exercise",
             "game",
             "hobby",
             "instrument",
             "live",
             "referee",
             "soccer",
             "social",
             "sound",
             "sports",
             "trophy",
             "warning",
             "whistle"
          ],

       },
       {
          "name":"sports_bar",

          "tags":[
             "alcohol",
             "bar",
             "beer",
             "drink",
             "liquor",
             "pint",
             "places",
             "pub",
             "sports"
          ],

       },
       {
          "name":"sports_baseball",

          "tags":[
             "athlete",
             "athletic",
             "ball",
             "baseball",
             "entertainment",
             "exercise",
             "game",
             "hobby",
             "social",
             "sports"
          ],

       },
       {
          "name":"sports_basketball",

          "tags":[
             "athlete",
             "athletic",
             "ball",
             "basketball",
             "entertainment",
             "exercise",
             "game",
             "hobby",
             "social",
             "sports"
          ],

       },
       {
          "name":"sports_cricket",

          "tags":[
             "athlete",
             "athletic",
             "ball",
             "bat",
             "cricket",
             "entertainment",
             "exercise",
             "game",
             "hobby",
             "social",
             "sports"
          ],

       },
       {
          "name":"sports_esports",

          "tags":[
             "controller",
             "entertainment",
             "esports",
             "game",
             "gamepad",
             "gaming",
             "hobby",
             "online",
             "social",
             "sports",
             "video"
          ],

       },
       {
          "name":"sports_football",

          "tags":[
             "athlete",
             "athletic",
             "ball",
             "entertainment",
             "exercise",
             "football",
             "game",
             "hobby",
             "social",
             "sports"
          ],

       },
       {
          "name":"sports_golf",

          "tags":[
             "athlete",
             "athletic",
             "ball",
             "club",
             "entertainment",
             "exercise",
             "game",
             "golf",
             "golfer",
             "golfing",
             "hobby",
             "social",
             "sports"
          ],

       },
       {
          "name":"sports_handball",

          "tags":[
             "athlete",
             "athletic",
             "ball",
             "body",
             "entertainment",
             "exercise",
             "game",
             "handball",
             "hobby",
             "human",
             "people",
             "person",
             "social",
             "sports"
          ],

       },
       {
          "name":"sports_hockey",

          "tags":[
             "athlete",
             "athletic",
             "entertainment",
             "exercise",
             "game",
             "hobby",
             "hockey",
             "social",
             "sports",
             "sticks"
          ],

       },
       {
          "name":"sports_kabaddi",

          "tags":[
             "athlete",
             "athletic",
             "body",
             "combat",
             "entertainment",
             "exercise",
             "fighting",
             "game",
             "hobby",
             "human",
             "kabaddi",
             "people",
             "person",
             "social",
             "sports",
             "wrestle",
             "wrestling"
          ],

       },
       {
          "name":"sports_mma",

          "tags":[
             "arts",
             "athlete",
             "athletic",
             "boxing",
             "combat",
             "entertainment",
             "exercise",
             "fighting",
             "game",
             "glove",
             "hobby",
             "martial",
             "mixed",
             "mma",
             "social",
             "sports"
          ],

       },
       {
          "name":"sports_motorsports",

          "tags":[
             "athlete",
             "athletic",
             "automobile",
             "bike",
             "drive",
             "driving",
             "entertainment",
             "helmet",
             "hobby",
             "motorcycle",
             "motorsports",
             "protect",
             "social",
             "sports",
             "vehicle"
          ],

       },
       {
          "name":"sports_rugby",

          "tags":[
             "athlete",
             "athletic",
             "ball",
             "entertainment",
             "exercise",
             "game",
             "hobby",
             "rugby",
             "social",
             "sports"
          ],

       },
       {
          "name":"sports_score",

          "tags":[
             "destination",
             "flag",
             "goal",
             "score",
             "sports"
          ],

       },
       {
          "name":"sports_soccer",

          "tags":[
             "athlete",
             "athletic",
             "ball",
             "entertainment",
             "exercise",
             "football",
             "game",
             "hobby",
             "soccer",
             "social",
             "sports"
          ],

       },
       {
          "name":"sports_tennis",

          "tags":[
             "athlete",
             "athletic",
             "ball",
             "bat",
             "entertainment",
             "exercise",
             "game",
             "hobby",
             "racket",
             "social",
             "sports",
             "tennis"
          ],

       },
       {
          "name":"sports_volleyball",

          "tags":[
             "athlete",
             "athletic",
             "ball",
             "entertainment",
             "exercise",
             "game",
             "hobby",
             "social",
             "sports",
             "volleyball"
          ],

       },
       {
          "name":"square_foot",

          "tags":[
             "construction",
             "feet",
             "foot",
             "inches",
             "length",
             "measurement",
             "ruler",
             "school",
             "set",
             "square",
             "tools"
          ],

       },
       {
          "name":"stacked_bar_chart",

          "tags":[
             "analytics",
             "bar",
             "chart-chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "stacked",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"stacked_line_chart",

          "tags":[
             "analytics",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "line",
             "measure",
             "metrics",
             "stacked",
             "statistics",
             "tracking"
          ],

       },
       {
          "name":"stairs",

          "tags":[
             "down",
             "staircase",
             "stairs",
             "up"
          ],

       },
       {
          "name":"star",

          "tags":[
             "best",
             "bookmark",
             "favorite",
             "highlight",
             "ranking",
             "rate",
             "rating",
             "save",
             "star",
             "toggle"
          ],

       },
       {
          "name":"star_border",

          "tags":[
             "best",
             "bookmark",
             "border",
             "favorite",
             "highlight",
             "outline",
             "ranking",
             "rate",
             "rating",
             "save",
             "star",
             "toggle"
          ],

       },
       {
          "name":"star_border_purple500",

          "tags":[
             "500",
             "best",
             "bookmark",
             "border",
             "favorite",
             "highlight",
             "outline",
             "purple",
             "ranking",
             "rate",
             "rating",
             "save",
             "star",
             "toggle"
          ],

       },
       {
          "name":"star_half",

          "tags":[
             "0.5",
             "1/2",
             "achievement",
             "bookmark",
             "favorite",
             "half",
             "highlight",
             "important",
             "marked",
             "ranking",
             "rate",
             "rating",
             "rating rank",
             "reward",
             "save",
             "saved",
             "shape",
             "special",
             "star",
             "toggle"
          ],

       },
       {
          "name":"star_outline",

          "tags":[
             "bookmark",
             "favorite",
             "half",
             "highlight",
             "ranking",
             "rate",
             "rating",
             "save",
             "star",
             "toggle"
          ],

       },
       {
          "name":"star_purple500",

          "tags":[
             "500",
             "best",
             "bookmark",
             "favorite",
             "highlight",
             "purple",
             "ranking",
             "rate",
             "rating",
             "save",
             "star",
             "toggle"
          ],

       },
       {
          "name":"star_rate",

          "tags":[
             "achievement",
             "bookmark",
             "favorite",
             "highlight",
             "important",
             "marked",
             "ranking",
             "rate",
             "rating rank",
             "reward",
             "save",
             "saved",
             "shape",
             "special",
             "star"
          ],

       },
       {
          "name":"stars",

          "tags":[
             "achievement",
             "bookmark",
             "circle",
             "favorite",
             "highlight",
             "important",
             "marked",
             "ranking",
             "rate",
             "rating rank",
             "reward",
             "save",
             "saved",
             "shape",
             "special",
             "star"
          ],

       },
       {
          "name":"stay_current_landscape",

          "tags":[
             "Android",
             "OS",
             "current",
             "device",
             "hardware",
             "iOS",
             "landscape",
             "mobile",
             "phone",
             "stay",
             "tablet"
          ],

       },
       {
          "name":"stay_current_portrait",

          "tags":[
             "Android",
             "OS",
             "current",
             "device",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "portrait",
             "stay",
             "tablet"
          ],

       },
       {
          "name":"stay_primary_landscape",

          "tags":[
             "Android",
             "OS",
             "current",
             "device",
             "hardware",
             "iOS",
             "landscape",
             "mobile",
             "phone",
             "primary",
             "stay",
             "tablet"
          ],

       },
       {
          "name":"stay_primary_portrait",

          "tags":[
             "Android",
             "OS",
             "current",
             "device",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "portrait",
             "primary",
             "stay",
             "tablet"
          ],

       },
       {
          "name":"sticky_note_2",

          "tags":[
             "2",
             "bookmark",
             "mark",
             "message",
             "note",
             "paper",
             "sticky",
             "text",
             "writing"
          ],

       },
       {
          "name":"stop",

          "tags":[
             "control",
             "controls",
             "music",
             "pause",
             "play",
             "player",
             "square",
             "stop",
             "video"
          ],

       },
       {
          "name":"stop_circle",

          "tags":[
             
          ],

       },
       {
          "name":"stop_screen_share",

          "tags":[
             "Android",
             "OS",
             "arrow",
             "cast",
             "chrome",
             "device",
             "disabled",
             "display",
             "enabled",
             "hardware",
             "iOS",
             "laptop",
             "mac",
             "mirror",
             "monitor",
             "off",
             "offline",
             "on",
             "screen",
             "share",
             "slash",
             "steam",
             "stop",
             "streaming",
             "web",
             "window"
          ],

       },
       {
          "name":"storage",

          "tags":[
             "computer",
             "data",
             "drive",
             "memory",
             "storage"
          ],

       },
       {
          "name":"store",

          "tags":[
             "bill",
             "building",
             "business",
             "card",
             "cash",
             "coin",
             "commerce",
             "company",
             "credit",
             "currency",
             "dollars",
             "market",
             "money",
             "online",
             "pay",
             "payment",
             "shop",
             "shopping",
             "store",
             "storefront"
          ],

       },
       {
          "name":"store_mall_directory",

          "tags":[
             "directory",
             "mall",
             "store"
          ],

       },
       {
          "name":"storefront",

          "tags":[
             "business",
             "buy",
             "cafe",
             "commerce",
             "front",
             "market",
             "places",
             "restaurant",
             "retail",
             "sell",
             "shop",
             "shopping",
             "store",
             "storefront"
          ],

       },
       {
          "name":"storm",

          "tags":[
             "forecast",
             "hurricane",
             "storm",
             "temperature",
             "twister",
             "weather",
             "wind"
          ],

       },
       {
          "name":"straighten",

          "tags":[
             "length",
             "measure",
             "measurement",
             "ruler",
             "size",
             "straighten"
          ],

       },
       {
          "name":"stream",

          "tags":[
             "cast",
             "connected",
             "feed",
             "live",
             "network",
             "signal",
             "stream",
             "wireless"
          ],

       },
       {
          "name":"streetview",

          "tags":[
             "maps",
             "street",
             "streetview",
             "view"
          ],

       },
       {
          "name":"strikethrough_s",

          "tags":[
             "alphabet",
             "character",
             "cross",
             "doc",
             "edit",
             "editing",
             "editor",
             "font",
             "letter",
             "out",
             "s",
             "sheet",
             "spreadsheet",
             "strikethrough",
             "styles",
             "symbol",
             "text",
             "type",
             "writing"
          ],

       },
       {
          "name":"stroller",

          "tags":[
             "baby",
             "care",
             "carriage",
             "child",
             "children",
             "infant",
             "kid",
             "newborn",
             "stroller",
             "toddler",
             "young"
          ],

       },
       {
          "name":"style",

          "tags":[
             "booklet",
             "cards",
             "filters",
             "options",
             "style",
             "tags"
          ],

       },
       {
          "name":"subdirectory_arrow_left",

          "tags":[
             "arrow",
             "directory",
             "down",
             "left",
             "navigation",
             "sub",
             "subdirectory"
          ],

       },
       {
          "name":"subdirectory_arrow_right",

          "tags":[
             "arrow",
             "directory",
             "down",
             "navigation",
             "right",
             "sub",
             "subdirectory"
          ],

       },
       {
          "name":"subject",

          "tags":[
             "alignment",
             "doc",
             "document",
             "email",
             "full",
             "justify",
             "list",
             "note",
             "subject",
             "text",
             "writing"
          ],

       },
       {
          "name":"subscript",

          "tags":[
             "2",
             "doc",
             "edit",
             "editing",
             "editor",
             "gmail",
             "novitas",
             "sheet",
             "spreadsheet",
             "style",
             "subscript",
             "symbol",
             "text",
             "writing",
             "x"
          ],

       },
       {
          "name":"subscriptions",

          "tags":[
             "enroll",
             "list",
             "media",
             "order",
             "play",
             "signup",
             "subs",
             "subscribe",
             "subscriptions"
          ],

       },
       {
          "name":"subtitles",

          "tags":[
             "accessible",
             "caption",
             "cc",
             "character",
             "closed",
             "decoder",
             "language",
             "media",
             "movies",
             "subtitle",
             "subtitles",
             "tv"
          ],

       },
       {
          "name":"subtitles_off",

          "tags":[
             "accessibility",
             "accessible",
             "caption",
             "cc",
             "closed",
             "disabled",
             "enabled",
             "language",
             "off",
             "on",
             "slash",
             "subtitle",
             "subtitles",
             "translate",
             "video"
          ],

       },
       {
          "name":"subway",

          "tags":[
             "automobile",
             "bike",
             "car",
             "cars",
             "maps",
             "rail",
             "scooter",
             "subway",
             "train",
             "transportation",
             "travel",
             "tunnel",
             "underground",
             "vehicle",
             "vespa"
          ],

       },
       {
          "name":"summarize",

          "tags":[
             "doc",
             "document",
             "list",
             "menu",
             "note",
             "report",
             "summary"
          ],

       },
       {
          "name":"superscript",

          "tags":[
             "2",
             "doc",
             "edit",
             "editing",
             "editor",
             "gmail",
             "novitas",
             "sheet",
             "spreadsheet",
             "style",
             "superscript",
             "symbol",
             "text",
             "writing",
             "x"
          ],

       },
       {
          "name":"supervised_user_circle",

          "tags":[
             "account",
             "avatar",
             "circle",
             "control",
             "face",
             "human",
             "parental",
             "parents",
             "people",
             "person",
             "profile",
             "supervised",
             "supervisor",
             "user"
          ],

       },
       {
          "name":"supervisor_account",

          "tags":[
             "account",
             "avatar",
             "control",
             "face",
             "human",
             "parental",
             "parental control",
             "parents",
             "people",
             "person",
             "profile",
             "supervised",
             "supervisor",
             "user"
          ],

       },
       {
          "name":"support",

          "tags":[
             "assist",
             "buoy",
             "help",
             "life",
             "lifebuoy",
             "rescue",
             "safe",
             "safety",
             "support"
          ],

       },
       {
          "name":"support_agent",

          "tags":[
             "agent",
             "care",
             "customer",
             "face",
             "headphone",
             "person",
             "representative",
             "service",
             "support"
          ],

       },
       {
          "name":"surfing",

          "tags":[
             "athlete",
             "athletic",
             "beach",
             "body",
             "entertainment",
             "exercise",
             "hobby",
             "human",
             "people",
             "person",
             "sea",
             "social sports",
             "sports",
             "summer",
             "surfing",
             "water"
          ],

       },
       {
          "name":"surround_sound",

          "tags":[
             "circle",
             "signal",
             "sound",
             "speaker",
             "surround",
             "system",
             "volumn",
             "wireless"
          ],

       },
       {
          "name":"swap_calls",

          "tags":[
             "arrow",
             "arrows",
             "calls",
             "device",
             "direction",
             "mobile",
             "share",
             "swap"
          ],

       },
       {
          "name":"swap_horiz",

          "tags":[
             "arrow",
             "arrows",
             "back",
             "forward",
             "horizontal",
             "swap"
          ],

       },
       {
          "name":"swap_horizontal_circle",

          "tags":[
             "arrow",
             "arrows",
             "back",
             "circle",
             "forward",
             "horizontal",
             "swap"
          ],

       },
       {
          "name":"swap_vert",

          "tags":[
             "arrow",
             "arrows",
             "direction",
             "down",
             "navigation",
             "swap",
             "up",
             "vert",
             "vertical"
          ],

       },
       {
          "name":"swap_vertical_circle",

          "tags":[
             "arrow",
             "arrows",
             "circle",
             "down",
             "swap",
             "up",
             "vertical"
          ],

       },
       {
          "name":"swipe",

          "tags":[
             "arrow",
             "arrows",
             "fingers",
             "gesture",
             "hand",
             "hands",
             "swipe",
             "touch"
          ],

       },
       {
          "name":"switch_access_shortcut",
          "popularity":8,

          "tags":[
             
          ],

       },
       {
          "name":"switch_access_shortcut_add",

          "tags":[
             
          ],

       },
       {
          "name":"switch_account",

          "tags":[
             "account",
             "choices",
             "face",
             "human",
             "multiple",
             "options",
             "people",
             "person",
             "profile",
             "social",
             "switch",
             "user"
          ],

       },
       {
          "name":"switch_camera",

          "tags":[
             "arrow",
             "arrows",
             "camera",
             "photo",
             "photography",
             "picture",
             "switch"
          ],

       },
       {
          "name":"switch_left",

          "tags":[
             "arrows",
             "directional",
             "left",
             "navigation",
             "switch",
             "toggle"
          ],

       },
       {
          "name":"switch_right",

          "tags":[
             "arrows",
             "directional",
             "navigation",
             "right",
             "switch",
             "toggle"
          ],

       },
       {
          "name":"switch_video",

          "tags":[
             "arrow",
             "arrows",
             "camera",
             "photography",
             "switch",
             "video",
             "videos"
          ],

       },
       {
          "name":"sync",

          "tags":[
             "360",
             "around",
             "arrow",
             "arrows",
             "direction",
             "inprogress",
             "load",
             "loading refresh",
             "renew",
             "rotate",
             "sync",
             "turn"
          ],

       },
       {
          "name":"sync_alt",

          "tags":[
             "alt",
             "arrow",
             "arrows",
             "horizontal",
             "internet",
             "sync",
             "technology",
             "up",
             "update",
             "wifi"
          ],

       },
       {
          "name":"sync_disabled",

          "tags":[
             "360",
             "around",
             "arrow",
             "arrows",
             "direction",
             "disabled",
             "enabled",
             "inprogress",
             "load",
             "loading refresh",
             "off",
             "on",
             "renew",
             "rotate",
             "slash",
             "sync",
             "turn"
          ],

       },
       {
          "name":"sync_problem",

          "tags":[
             "!",
             "360",
             "alert",
             "around",
             "arrow",
             "arrows",
             "attention",
             "caution",
             "danger",
             "direction",
             "error",
             "exclamation",
             "important",
             "inprogress",
             "load",
             "loading refresh",
             "mark",
             "notification",
             "problem",
             "renew",
             "rotate",
             "symbol",
             "sync",
             "turn",
             "warning"
          ],

       },
       {
          "name":"system_security_update",

          "tags":[
             "Android",
             "OS",
             "arrow",
             "cell",
             "device",
             "down",
             "hardware",
             "iOS",
             "mobile",
             "phone",
             "security",
             "system",
             "tablet",
             "update"
          ],

       },
       {
          "name":"system_security_update_good",

          "tags":[
             "Android",
             "OS",
             "approve",
             "cell",
             "check",
             "complete",
             "device",
             "done",
             "good",
             "hardware",
             "iOS",
             "mark",
             "mobile",
             "ok",
             "phone",
             "security",
             "select",
             "system",
             "tablet",
             "tick",
             "update",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"system_security_update_warning",

          "tags":[
             "!",
             "Android",
             "OS",
             "alert",
             "attention",
             "caution",
             "cell",
             "danger",
             "device",
             "error",
             "exclamation",
             "hardware",
             "iOS",
             "important",
             "mark",
             "mobile",
             "notification",
             "phone",
             "security",
             "symbol",
             "system",
             "tablet",
             "update",
             "warning"
          ],

       },
       {
          "name":"system_update",

          "tags":[
             "Android",
             "OS",
             "arrow",
             "arrows",
             "cell",
             "device",
             "direction",
             "down",
             "download",
             "hardware",
             "iOS",
             "install",
             "mobile",
             "phone",
             "system",
             "tablet",
             "update"
          ],

       },
       {
          "name":"system_update_alt",

          "tags":[
             "arrow",
             "down",
             "download",
             "export",
             "system",
             "update"
          ],

       },
       {
          "name":"tab",

          "tags":[
             "browser",
             "computer",
             "document",
             "documents",
             "folder",
             "internet",
             "tab",
             "tabs",
             "web",
             "website",
             "window",
             "windows"
          ],

       },
       {
          "name":"tab_unselected",

          "tags":[
             "browser",
             "computer",
             "document",
             "documents",
             "folder",
             "internet",
             "tab",
             "tabs",
             "unselected",
             "web",
             "website",
             "window",
             "windows"
          ],

       },
       {
          "name":"table_chart",

          "tags":[
             "analytics",
             "bar",
             "bars",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic grid",
             "measure",
             "metrics",
             "statistics",
             "table",
             "tracking"
          ],

       },
       {
          "name":"table_rows",

          "tags":[
             "grid",
             "layout",
             "lines",
             "rows",
             "stacked",
             "table"
          ],

       },
       {
          "name":"table_view",

          "tags":[
             "format",
             "grid",
             "group",
             "layout",
             "multiple",
             "table",
             "view"
          ],

       },
       {
          "name":"tablet",

          "tags":[
             "Android",
             "OS",
             "device",
             "hardware",
             "iOS",
             "ipad",
             "mobile",
             "tablet",
             "web"
          ],

       },
       {
          "name":"tablet_android",

          "tags":[
             "OS",
             "android",
             "device",
             "hardware",
             "iOS",
             "ipad",
             "mobile",
             "tablet",
             "web"
          ],

       },
       {
          "name":"tablet_mac",

          "tags":[
             "Android",
             "OS",
             "device",
             "hardware",
             "iOS",
             "ipad",
             "mobile",
             "tablet mac",
             "web"
          ],

       },
       {
          "name":"tag",

          "tags":[
             "hash",
             "hashtag",
             "key",
             "media",
             "number",
             "pound",
             "social",
             "tag",
             "trend"
          ],

       },
       {
          "name":"tag_faces",

          "tags":[
             "emoji",
             "emotion",
             "faces",
             "happy",
             "satisfied",
             "smile",
             "tag"
          ],

       },
       {
          "name":"takeout_dining",

          "tags":[
             "box",
             "container",
             "delivery",
             "dining",
             "food",
             "meal",
             "restaurant",
             "takeout"
          ],

       },
       {
          "name":"tap_and_play",

          "tags":[
             "Android",
             "OS wifi",
             "cell",
             "connection",
             "device",
             "hardware",
             "iOS",
             "internet",
             "mobile",
             "network",
             "phone",
             "play",
             "signal",
             "tablet",
             "tap",
             "to",
             "wireless"
          ],

       },
       {
          "name":"tapas",

          "tags":[
             "appetizer",
             "brunch",
             "dinner",
             "food",
             "lunch",
             "restaurant",
             "snack",
             "tapas"
          ],

       },
       {
          "name":"task",

          "tags":[
             "approve",
             "check",
             "complete",
             "data",
             "doc",
             "document",
             "done",
             "drive",
             "file",
             "folder",
             "folders",
             "mark",
             "ok",
             "page",
             "paper",
             "select",
             "sheet",
             "slide",
             "task",
             "tick",
             "validate",
             "verified",
             "writing",
             "yes"
          ],

       },
       {
          "name":"task_alt",

          "tags":[
             "approve",
             "check",
             "circle",
             "complete",
             "done",
             "mark",
             "ok",
             "select",
             "task",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"taxi_alert",

          "tags":[
             "!",
             "alert",
             "attention",
             "automobile",
             "cab",
             "car",
             "cars",
             "caution",
             "danger",
             "direction",
             "error",
             "exclamation",
             "important",
             "lyft",
             "maps",
             "mark",
             "notification",
             "public",
             "symbol",
             "taxi",
             "transportation",
             "uber",
             "vehicle",
             "warning",
             "yellow"
          ],

       },
       {
          "name":"terrain",

          "tags":[
             "geography",
             "landscape",
             "mountain",
             "terrain"
          ],

       },
       {
          "name":"text_fields",

          "tags":[
             "T",
             "add",
             "alphabet",
             "character",
             "field",
             "fields",
             "font",
             "input",
             "letter",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"text_format",

          "tags":[
             "alphabet",
             "character",
             "font",
             "format",
             "letter",
             "square A",
             "style",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"text_rotate_up",

          "tags":[
             "A",
             "alphabet",
             "arrow",
             "character",
             "field",
             "font",
             "letter",
             "move",
             "rotate",
             "symbol",
             "text",
             "type",
             "up"
          ],

       },
       {
          "name":"text_rotate_vertical",

          "tags":[
             "A",
             "alphabet",
             "arrow",
             "character",
             "down",
             "field",
             "font",
             "letter",
             "move",
             "rotate",
             "symbol",
             "text",
             "type",
             "vertical"
          ],

       },
       {
          "name":"text_rotation_angledown",

          "tags":[
             "A",
             "alphabet",
             "angledown",
             "arrow",
             "character",
             "field",
             "font",
             "letter",
             "move",
             "rotate",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"text_rotation_angleup",

          "tags":[
             "A",
             "alphabet",
             "angleup",
             "arrow",
             "character",
             "field",
             "font",
             "letter",
             "move",
             "rotate",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"text_rotation_down",

          "tags":[
             "A",
             "alphabet",
             "arrow",
             "character",
             "dow",
             "field",
             "font",
             "letter",
             "move",
             "rotate",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"text_rotation_none",

          "tags":[
             "A",
             "alphabet",
             "arrow",
             "character",
             "field",
             "font",
             "letter",
             "move",
             "none",
             "rotate",
             "symbol",
             "text",
             "type"
          ],

       },
       {
          "name":"text_snippet",

          "tags":[
             "data",
             "doc",
             "document",
             "file",
             "note",
             "notes",
             "snippet",
             "storage",
             "text",
             "writing"
          ],

       },
       {
          "name":"textsms",

          "tags":[
             "bubble",
             "chat",
             "comment",
             "communicate",
             "dots",
             "feedback",
             "message",
             "speech",
             "textsms"
          ],

       },
       {
          "name":"texture",

          "tags":[
             "diagonal",
             "lines",
             "pattern",
             "stripes",
             "texture"
          ],

       },
       {
          "name":"theater_comedy",

          "tags":[
             "broadway",
             "comedy",
             "event",
             "movie",
             "musical",
             "places",
             "show",
             "standup",
             "theater",
             "tour",
             "watch"
          ],

       },
       {
          "name":"theaters",

          "tags":[
             "film",
             "movie",
             "movies",
             "show",
             "showtimes",
             "theater",
             "theaters",
             "watch"
          ],

       },
       {
          "name":"thermostat",

          "tags":[
             "forecast",
             "temperature",
             "thermostat",
             "weather"
          ],

       },
       {
          "name":"thermostat_auto",

          "tags":[
             "A",
             "auto",
             "celsius",
             "fahrenheit",
             "meter",
             "temp",
             "temperature",
             "thermometer",
             "thermostat"
          ],

       },
       {
          "name":"thumb_down",

          "tags":[
             "ate",
             "dislike",
             "down",
             "downvote",
             "favorite",
             "fingers",
             "gesture",
             "hand",
             "hands",
             "like",
             "rank",
             "ranking",
             "rating",
             "thumb"
          ],

       },
       {
          "name":"thumb_down_alt",

          "tags":[
             "bad",
             "decline",
             "disapprove",
             "dislike",
             "down",
             "feedback",
             "hate",
             "negative",
             "no",
             "reject",
             "social",
             "thumb",
             "veto",
             "vote"
          ],

       },
       {
          "name":"thumb_down_off_alt",

          "tags":[
             "bad",
             "decline",
             "disapprove",
             "dislike",
             "down",
             "feedback",
             "hate",
             "negative",
             "no",
             "reject",
             "sad",
             "social",
             "thumb",
             "veto",
             "vote"
          ],

       },
       {
          "name":"thumb_up",

          "tags":[
             "favorite",
             "fingers",
             "gesture",
             "hand",
             "hands",
             "like",
             "rank",
             "ranking",
             "rate",
             "rating",
             "thumb",
             "up",
             "upvote"
          ],

       },
       {
          "name":"thumb_up_alt",

          "tags":[
             "agreed",
             "approved",
             "confirm",
             "correct",
             "favorite",
             "feedback",
             "good",
             "happy",
             "like",
             "okay",
             "positive",
             "satisfaction",
             "social",
             "thumb",
             "up",
             "vote",
             "yes"
          ],

       },
       {
          "name":"thumb_up_off_alt",

          "tags":[
             "agreed",
             "approved",
             "confirm",
             "correct",
             "favorite",
             "feedback",
             "good",
             "happy",
             "like",
             "okay",
             "positive",
             "satisfaction",
             "social",
             "thumb",
             "up",
             "vote",
             "yes"
          ],

       },
       {
          "name":"thumbs_up_down",

          "tags":[
             "dislike",
             "down",
             "favorite",
             "fingers",
             "gesture",
             "hands",
             "like",
             "rate",
             "rating",
             "thumbs",
             "up"
          ],

       },
       {
          "name":"time_to_leave",

          "tags":[
             "automobile",
             "car",
             "cars",
             "destination",
             "direction",
             "drive",
             "estimate",
             "eta",
             "maps",
             "public",
             "transportation",
             "travel",
             "trip",
             "vehicle"
          ],

       },
       {
          "name":"timelapse",

          "tags":[
             "duration",
             "motion",
             "photo",
             "time",
             "timelapse",
             "timer",
             "video"
          ],

       },
       {
          "name":"timeline",

          "tags":[
             "data",
             "history",
             "line",
             "movement",
             "point",
             "points",
             "timeline",
             "tracking",
             "trending",
             "zigzag"
          ],

       },
       {
          "name":"timer",

          "tags":[
             "alarm",
             "alart",
             "bell",
             "clock",
             "disabled",
             "duration",
             "enabled",
             "notification",
             "off",
             "on",
             "slash",
             "stop",
             "time",
             "timer",
             "watch"
          ],

       },
       {
          "name":"timer_10",

          "tags":[
             "10",
             "digits",
             "duration",
             "number",
             "numbers",
             "seconds",
             "time",
             "timer"
          ],

       },
       {
          "name":"timer_10_select",

          "tags":[
             "10",
             "alphabet",
             "camera",
             "character",
             "digit",
             "font",
             "letter",
             "number",
             "seconds",
             "select",
             "symbol",
             "text",
             "timer",
             "type"
          ],

       },
       {
          "name":"timer_3",

          "tags":[
             "3",
             "digits",
             "duration",
             "number",
             "numbers",
             "seconds",
             "time",
             "timer"
          ],

       },
       {
          "name":"timer_3_select",

          "tags":[
             "3",
             "alphabet",
             "camera",
             "character",
             "digit",
             "font",
             "letter",
             "number",
             "seconds",
             "select",
             "symbol",
             "text",
             "timer",
             "type"
          ],

       },
       {
          "name":"timer_off",

          "tags":[
             "alarm",
             "alart",
             "bell",
             "clock",
             "disabled",
             "duration",
             "enabled",
             "notification",
             "off",
             "on",
             "slash",
             "stop",
             "time",
             "timer",
             "watch"
          ],

       },
       {
          "name":"tips_and_updates",

          "tags":[
             
          ],

       },
       {
          "name":"title",

          "tags":[
             "T",
             "alphabet",
             "character",
             "font",
             "header",
             "letter",
             "subject",
             "symbol",
             "text",
             "title",
             "type"
          ],

       },
       {
          "name":"toc",

          "tags":[
             "content",
             "format",
             "lines",
             "list",
             "order",
             "reorder",
             "stacked",
             "table",
             "title",
             "titles",
             "toc"
          ],

       },
       {
          "name":"today",

          "tags":[
             "calendar",
             "date",
             "day",
             "event",
             "mark",
             "month",
             "remember",
             "reminder",
             "schedule",
             "time",
             "today"
          ],

       },
       {
          "name":"toggle_off",

          "tags":[
             "active",
             "app",
             "application",
             "components",
             "configuration",
             "control",
             "design",
             "disable",
             "inable",
             "inactive",
             "interface",
             "off",
             "on",
             "selection",
             "settings",
             "site",
             "slider",
             "switch",
             "toggle",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"toggle_on",

          "tags":[
             "active",
             "app",
             "application",
             "components",
             "configuration",
             "control",
             "design",
             "disable",
             "inable",
             "inactive",
             "interface",
             "off",
             "on",
             "selection",
             "settings",
             "site",
             "slider",
             "switch",
             "toggle",
             "ui",
             "ux",
             "web",
             "website"
          ],

       },
       {
          "name":"toll",

          "tags":[
             "bill",
             "booth",
             "car",
             "card",
             "cash",
             "coin",
             "commerce",
             "credit",
             "currency",
             "dollars",
             "highway",
             "money",
             "online",
             "pay",
             "payment",
             "ticket",
             "toll"
          ],

       },
       {
          "name":"tonality",

          "tags":[
             "circle",
             "edit",
             "editing",
             "filter",
             "image",
             "photography",
             "picture",
             "tonality"
          ],

       },
       {
          "name":"topic",

          "tags":[
             "data",
             "doc",
             "document",
             "drive",
             "file",
             "folder",
             "sheet",
             "slide",
             "storage",
             "topic"
          ],

       },
       {
          "name":"touch_app",

          "tags":[
             "app",
             "command",
             "fingers",
             "gesture",
             "hand",
             "press",
             "tap",
             "touch"
          ],

       },
       {
          "name":"tour",

          "tags":[
             "destination",
             "flag",
             "places",
             "tour",
             "travel",
             "visit"
          ],

       },
       {
          "name":"toys",

          "tags":[
             "car",
             "games",
             "kids",
             "toys",
             "windmill"
          ],

       },
       {
          "name":"track_changes",

          "tags":[
             "bullseye",
             "changes",
             "circle",
             "evolve",
             "lines",
             "movement",
             "rotate",
             "shift",
             "target",
             "track"
          ],

       },
       {
          "name":"traffic",

          "tags":[
             "direction",
             "light",
             "maps",
             "signal",
             "street",
             "traffic"
          ],

       },
       {
          "name":"train",

          "tags":[
             "automobile",
             "car",
             "cars",
             "direction",
             "maps",
             "public",
             "rail",
             "subway",
             "train",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"tram",

          "tags":[
             "automobile",
             "car",
             "cars",
             "direction",
             "maps",
             "public",
             "rail",
             "subway",
             "train",
             "tram",
             "transportation",
             "vehicle"
          ],

       },
       {
          "name":"transfer_within_a_station",

          "tags":[
             "a",
             "arrow",
             "arrows",
             "body",
             "direction",
             "human",
             "left",
             "maps",
             "people",
             "person",
             "public",
             "right",
             "route",
             "station",
             "stop",
             "transfer",
             "transportation",
             "vehicle",
             "walk",
             "within"
          ],

       },
       {
          "name":"transform",

          "tags":[
             "adjust",
             "crop",
             "edit",
             "editing",
             "image",
             "photo",
             "picture",
             "transform"
          ],

       },
       {
          "name":"transgender",

          "tags":[
             "female",
             "gender",
             "lgbt",
             "male",
             "neutrual",
             "social",
             "transgender"
          ],

       },
       {
          "name":"transit_enterexit",

          "tags":[
             "arrow",
             "direction",
             "enterexit",
             "maps",
             "navigation",
             "route",
             "transit",
             "transportation"
          ],

       },
       {
          "name":"translate",

          "tags":[
             "language",
             "speaking",
             "speech",
             "translate",
             "translator",
             "words"
          ],

       },
       {
          "name":"travel_explore",

          "tags":[
             "earth",
             "explore",
             "find",
             "glass",
             "global",
             "globe",
             "look",
             "magnify",
             "magnifying",
             "map",
             "network",
             "planet",
             "search",
             "see",
             "social",
             "space",
             "travel",
             "web",
             "world"
          ],

       },
       {
          "name":"trending_down",

          "tags":[
             "analytics",
             "arrow",
             "data",
             "diagram",
             "down",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "movement",
             "rate",
             "rating",
             "statistics",
             "tracking",
             "trending"
          ],

       },
       {
          "name":"trending_flat",

          "tags":[
             "arrow",
             "change",
             "data",
             "flat",
             "metric",
             "movement",
             "rate",
             "right",
             "track",
             "tracking",
             "trending"
          ],

       },
       {
          "name":"trending_up",

          "tags":[
             "analytics",
             "arrow",
             "data",
             "diagram",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "movement",
             "rate",
             "rating",
             "statistics",
             "tracking",
             "trending",
             "up"
          ],

       },
       {
          "name":"trip_origin",

          "tags":[
             "circle",
             "departure",
             "origin",
             "trip"
          ],

       },
       {
          "name":"try",

          "tags":[
             "bookmark",
             "bubble",
             "chat",
             "comment",
             "communicate",
             "favorite",
             "feedback",
             "highlight",
             "important",
             "marked",
             "message",
             "save",
             "saved",
             "shape",
             "special",
             "speech",
             "star",
             "try"
          ],

       },
       {
          "name":"tty",

          "tags":[
             "call",
             "cell",
             "contact",
             "deaf",
             "device",
             "hardware",
             "impaired",
             "mobile",
             "phone",
             "speech",
             "talk",
             "telephone",
             "text",
             "tty"
          ],

       },
       {
          "name":"tune",

          "tags":[
             "adjust",
             "edit",
             "editing",
             "options",
             "setting",
             "settings",
             "tune"
          ],

       },
       {
          "name":"tungsten",

          "tags":[
             "electricity",
             "indoor",
             "lamp",
             "light",
             "lightbulb",
             "setting",
             "tungsten"
          ],

       },
       {
          "name":"turned_in",

          "tags":[
             "archive",
             "bookmark",
             "favorite",
             "in",
             "label",
             "library",
             "read",
             "reading",
             "remember",
             "ribbon",
             "save",
             "tag",
             "turned"
          ],

       },
       {
          "name":"turned_in_not",

          "tags":[
             "archive",
             "bookmark",
             "favorite",
             "in",
             "label",
             "library",
             "not",
             "read",
             "reading",
             "remember",
             "ribbon",
             "save",
             "tag",
             "turned"
          ],

       },
       {
          "name":"tv",

          "tags":[
             "device",
             "display",
             "linear",
             "living room",
             "monitor",
             "screen",
             "screencast",
             "stream",
             "television",
             "tv",
             "video",
             "wireless"
          ],

       },
       {
          "name":"tv_off",

          "tags":[
             "Android",
             "OS",
             "chrome",
             "desktop",
             "device",
             "disabled",
             "enabled",
             "hardware",
             "iOS",
             "mac",
             "monitor",
             "off",
             "on",
             "slash",
             "television",
             "tv",
             "web",
             "window"
          ],

       },
       {
          "name":"two_wheeler",

          "tags":[
             "automobile",
             "bike",
             "car",
             "cars",
             "direction",
             "maps",
             "motorcycle",
             "public",
             "scooter",
             "sport",
             "transportation",
             "travel",
             "twom wheeler",
             "vehicle"
          ],

       },
       {
          "name":"umbrella",

          "tags":[
             "beach",
             "protection",
             "rain",
             "sun",
             "sunny",
             "umbrella"
          ],

       },
       {
          "name":"unarchive",

          "tags":[
             "archive",
             "arrow",
             "inbox",
             "mail",
             "store",
             "unarchive",
             "undo",
             "up"
          ],

       },
       {
          "name":"undo",

          "tags":[
             "arrow",
             "backward",
             "mail",
             "previous",
             "redo",
             "repeat",
             "rotate",
             "undo"
          ],

       },
       {
          "name":"unfold_less",

          "tags":[
             "arrow",
             "arrows",
             "chevron",
             "collapse",
             "direction",
             "expand",
             "expandable",
             "inward",
             "less",
             "list",
             "navigation",
             "unfold",
             "up"
          ],

       },
       {
          "name":"unfold_more",

          "tags":[
             "arrow",
             "arrows",
             "chevron",
             "collapse",
             "direction",
             "down",
             "expand",
             "expandable",
             "list",
             "more",
             "navigation",
             "unfold"
          ],

       },
       {
          "name":"unpublished",

          "tags":[
             "approve",
             "check",
             "circle",
             "complete",
             "disabled",
             "done",
             "enabled",
             "mark",
             "off",
             "ok",
             "on",
             "select",
             "slash",
             "tick",
             "unpublished",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"unsubscribe",

          "tags":[
             "cancel",
             "close",
             "email",
             "envelop",
             "esubscribe",
             "letter",
             "mail",
             "message",
             "newsletter",
             "off",
             "remove",
             "send",
             "unsubscribe"
          ],

       },
       {
          "name":"upcoming",

          "tags":[
             "alarm",
             "calendar",
             "mail",
             "message",
             "notification",
             "upcoming"
          ],

       },
       {
          "name":"update",

          "tags":[
             "arrow",
             "back",
             "backwards",
             "clock",
             "forward",
             "history",
             "load",
             "refresh",
             "reverse",
             "schedule",
             "time",
             "update"
          ],

       },
       {
          "name":"update_disabled",

          "tags":[
             "arrow",
             "back",
             "backwards",
             "clock",
             "date",
             "disabled",
             "enabled",
             "forward",
             "history",
             "load",
             "off",
             "on",
             "refresh",
             "reverse",
             "rotate",
             "schedule",
             "slash",
             "time",
             "update"
          ],

       },
       {
          "name":"upgrade",

          "tags":[
             "arrow",
             "export",
             "instal",
             "line",
             "replace",
             "up",
             "update",
             "upgrade"
          ],

       },
       {
          "name":"upload",

          "tags":[
             "arrow",
             "arrows",
             "download",
             "drive",
             "up",
             "upload"
          ],

       },
       {
          "name":"upload_file",

          "tags":[
             "arrow",
             "data",
             "doc",
             "document",
             "download",
             "drive",
             "file",
             "folder",
             "folders",
             "page",
             "paper",
             "sheet",
             "slide",
             "up",
             "upload",
             "writing"
          ],

       },
       {
          "name":"usb",

          "tags":[
             "cable",
             "connection",
             "device",
             "usb",
             "wire"
          ],

       },
       {
          "name":"usb_off",

          "tags":[
             "cable",
             "connection",
             "device",
             "off",
             "usb",
             "wire"
          ],

       },
       {
          "name":"verified",

          "tags":[
             "approve",
             "badge",
             "burst",
             "check",
             "complete",
             "done",
             "mark",
             "ok",
             "select",
             "star",
             "tick",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"verified_user",

          "tags":[
             "approve",
             "certified",
             "check",
             "complete",
             "done",
             "mark",
             "ok",
             "privacy",
             "private",
             "protect",
             "protection",
             "security",
             "select",
             "shield",
             "tick",
             "user",
             "validate",
             "verified",
             "yes"
          ],

       },
       {
          "name":"vertical_align_bottom",

          "tags":[
             "align",
             "alignment",
             "arrow",
             "bottom",
             "doc",
             "down",
             "edit",
             "editing",
             "editor",
             "sheet",
             "spreadsheet",
             "text",
             "type",
             "vertical",
             "writing"
          ],

       },
       {
          "name":"vertical_align_center",

          "tags":[
             "align",
             "alignment",
             "arrow",
             "center",
             "doc",
             "down",
             "edit",
             "editing",
             "editor",
             "sheet",
             "spreadsheet",
             "text",
             "type",
             "up",
             "vertical",
             "writing"
          ],

       },
       {
          "name":"vertical_align_top",

          "tags":[
             "align",
             "alignment",
             "arrow",
             "doc",
             "edit",
             "editing",
             "editor",
             "sheet",
             "spreadsheet",
             "text",
             "top",
             "type",
             "up",
             "vertical",
             "writing"
          ],

       },
       {
          "name":"vertical_distribute",

          "tags":[
             "alignment",
             "distribute",
             "format",
             "layout",
             "lines",
             "paragraph",
             "rule",
             "rules",
             "style",
             "text",
             "vertical"
          ],

       },
       {
          "name":"vertical_split",

          "tags":[
             "design",
             "format",
             "grid",
             "layout",
             "paragraph",
             "split",
             "text",
             "vertical",
             "website",
             "writing"
          ],

       },
       {
          "name":"vibration",

          "tags":[
             "Android",
             "OS",
             "alert",
             "cell",
             "device",
             "hardware",
             "iOS",
             "mobile",
             "mode",
             "motion",
             "notification",
             "phone",
             "silence",
             "silent",
             "tablet",
             "vibrate",
             "vibration"
          ],

       },
       {
          "name":"video_call",

          "tags":[
             "+",
             "add",
             "call",
             "camera",
             "chat",
             "conference",
             "film",
             "filming",
             "hardware",
             "image",
             "motion",
             "new",
             "picture",
             "plus",
             "symbol",
             "video",
             "videography"
          ],

       },
       {
          "name":"video_camera_back",

          "tags":[
             "back",
             "camera",
             "image",
             "landscape",
             "mountain",
             "mountains",
             "photo",
             "photography",
             "picture",
             "rear",
             "video"
          ],

       },
       {
          "name":"video_camera_front",

          "tags":[
             "account",
             "camera",
             "face",
             "front",
             "human",
             "image",
             "people",
             "person",
             "photo",
             "photography",
             "picture",
             "profile",
             "user",
             "video"
          ],

       },
       {
          "name":"video_label",

          "tags":[
             "label",
             "screen",
             "video",
             "window"
          ],

       },
       {
          "name":"video_library",

          "tags":[
             "arrow",
             "collection",
             "library",
             "play",
             "video"
          ],

       },
       {
          "name":"video_settings",

          "tags":[
             "change",
             "details",
             "gear",
             "info",
             "information",
             "options",
             "play",
             "screen",
             "service",
             "setting",
             "settings",
             "video",
             "window"
          ],

       },
       {
          "name":"video_stable",

          "tags":[
             "film",
             "filming",
             "recording",
             "setting",
             "stability",
             "stable",
             "taping",
             "video"
          ],

       },
       {
          "name":"videocam",

          "tags":[
             "cam",
             "camera",
             "conference",
             "film",
             "filming",
             "hardware",
             "image",
             "motion",
             "picture",
             "video",
             "videography"
          ],

       },
       {
          "name":"videocam_off",

          "tags":[
             "cam",
             "camera",
             "conference",
             "disabled",
             "enabled",
             "film",
             "filming",
             "hardware",
             "image",
             "motion",
             "off",
             "offline",
             "on",
             "picture",
             "slash",
             "video",
             "videography"
          ],

       },
       {
          "name":"videogame_asset",

          "tags":[
             "asset",
             "console",
             "controller",
             "device",
             "game",
             "gamepad",
             "gaming",
             "playstation",
             "video"
          ],

       },
       {
          "name":"videogame_asset_off",

          "tags":[
             "asset",
             "console",
             "controller",
             "device",
             "disabled",
             "enabled",
             "game",
             "gamepad",
             "gaming",
             "off",
             "on",
             "playstation",
             "slash",
             "video",
             "videogame"
          ],

       },
       {
          "name":"view_agenda",

          "tags":[
             "agenda",
             "cards",
             "design",
             "format",
             "grid",
             "layout",
             "stacked",
             "view",
             "website"
          ],

       },
       {
          "name":"view_array",

          "tags":[
             "array",
             "design",
             "format",
             "grid",
             "layout",
             "view",
             "website"
          ],

       },
       {
          "name":"view_carousel",

          "tags":[
             "cards",
             "carousel",
             "design",
             "format",
             "grid",
             "layout",
             "view",
             "website"
          ],

       },
       {
          "name":"view_column",

          "tags":[
             "column",
             "design",
             "format",
             "grid",
             "layout",
             "vertical",
             "view",
             "website"
          ],

       },
       {
          "name":"view_comfy",

          "tags":[
             "comfy",
             "grid",
             "layout",
             "pattern",
             "squares",
             "view"
          ],

       },
       {
          "name":"view_compact",

          "tags":[
             "compact",
             "grid",
             "layout",
             "pattern",
             "squares",
             "view"
          ],

       },
       {
          "name":"view_day",

          "tags":[
             "cards",
             "carousel",
             "day",
             "design",
             "format",
             "grid",
             "layout",
             "view",
             "website"
          ],

       },
       {
          "name":"view_headline",

          "tags":[
             "design",
             "format",
             "grid",
             "headline",
             "layout",
             "paragraph",
             "text",
             "view",
             "website"
          ],

       },
       {
          "name":"view_in_ar",

          "tags":[
             "3d",
             "ar",
             "augmented",
             "cube",
             "daydream",
             "headset",
             "in",
             "reality",
             "square",
             "view",
             "vr"
          ],

       },
       {
          "name":"view_list",

          "tags":[
             "design",
             "format",
             "grid",
             "layout",
             "lines",
             "list",
             "stacked",
             "view",
             "website"
          ],

       },
       {
          "name":"view_module",

          "tags":[
             "design",
             "format",
             "grid",
             "layout",
             "module",
             "square",
             "squares",
             "stacked",
             "view",
             "website"
          ],

       },
       {
          "name":"view_quilt",

          "tags":[
             "design",
             "format",
             "grid",
             "layout",
             "quilt",
             "square",
             "squares",
             "stacked",
             "view",
             "website"
          ],

       },
       {
          "name":"view_sidebar",

          "tags":[
             "design",
             "format",
             "grid",
             "layout",
             "sidebar",
             "view",
             "web"
          ],

       },
       {
          "name":"view_stream",

          "tags":[
             "design",
             "format",
             "grid",
             "layout",
             "lines",
             "list",
             "stacked",
             "stream",
             "view",
             "website"
          ],

       },
       {
          "name":"view_week",

          "tags":[
             "bars",
             "columns",
             "design",
             "format",
             "grid",
             "layout",
             "view",
             "website",
             "week"
          ],

       },
       {
          "name":"vignette",

          "tags":[
             "border",
             "edit",
             "editing",
             "filter",
             "gradient",
             "image",
             "photo",
             "photography",
             "setting",
             "vignette"
          ],

       },
       {
          "name":"villa",

          "tags":[
             "architecture",
             "beach",
             "estate",
             "home",
             "house",
             "maps",
             "place",
             "real",
             "residence",
             "residential",
             "traveling",
             "vacation stay",
             "villa"
          ],

       },
       {
          "name":"visibility",

          "tags":[
             "eye",
             "on",
             "reveal",
             "see",
             "show",
             "view",
             "visability"
          ],

       },
       {
          "name":"visibility_off",

          "tags":[
             "disabled",
             "enabled",
             "eye",
             "off",
             "on",
             "reveal",
             "see",
             "show",
             "slash",
             "view",
             "visability"
          ],

       },
       {
          "name":"voice_chat",

          "tags":[
             "bubble",
             "cam",
             "camera",
             "chat",
             "comment",
             "communicate",
             "facetime",
             "feedback",
             "message",
             "speech",
             "video",
             "voice"
          ],

       },
       {
          "name":"voice_over_off",

          "tags":[
             "account",
             "disabled",
             "enabled",
             "face",
             "human",
             "off",
             "on",
             "over",
             "people",
             "person",
             "profile",
             "recording",
             "slash",
             "speak",
             "speaking",
             "speech",
             "transcript",
             "user",
             "voice"
          ],

       },
       {
          "name":"voicemail",

          "tags":[
             "call",
             "device",
             "message",
             "missed",
             "mobile",
             "phone",
             "recording",
             "voice",
             "voicemail"
          ],

       },
       {
          "name":"volume_down",

          "tags":[
             "audio",
             "av",
             "control",
             "down",
             "music",
             "quieter",
             "shh",
             "soft",
             "sound",
             "speaker",
             "tv",
             "volume"
          ],

       },
       {
          "name":"volume_mute",

          "tags":[
             "audio",
             "control",
             "music",
             "mute",
             "sound",
             "speaker",
             "tv",
             "volume"
          ],

       },
       {
          "name":"volume_off",

          "tags":[
             "audio",
             "av",
             "control",
             "disabled",
             "enabled",
             "low",
             "music",
             "mute",
             "off",
             "on",
             "slash",
             "sound",
             "speaker",
             "tv",
             "volume"
          ],

       },
       {
          "name":"volume_up",

          "tags":[
             "audio",
             "control",
             "music",
             "sound",
             "speaker",
             "tv",
             "up",
             "volume"
          ],

       },
       {
          "name":"volunteer_activism",

          "tags":[
             "activism",
             "donation",
             "fingers",
             "gesture",
             "giving",
             "hand",
             "hands",
             "heart",
             "love",
             "sharing",
             "volunteer"
          ],

       },
       {
          "name":"vpn_key",

          "tags":[
             "code",
             "key",
             "lock",
             "network",
             "passcode",
             "password",
             "unlock",
             "vpn"
          ],

       },
       {
          "name":"vpn_lock",

          "tags":[
             "earth",
             "globe",
             "lock",
             "locked",
             "network",
             "password",
             "privacy",
             "private",
             "protection",
             "safety",
             "secure",
             "security",
             "virtual",
             "vpn",
             "world"
          ],

       },
       {
          "name":"vrpano",

          "tags":[
             "angle",
             "image",
             "landscape",
             "mountain",
             "mountains",
             "panorama",
             "photo",
             "photography",
             "picture",
             "view",
             "vrpano",
             "wide"
          ],

       },
       {
          "name":"wallpaper",

          "tags":[
             "background",
             "image",
             "landscape",
             "photo",
             "photography",
             "picture",
             "wallpaper"
          ],

       },
       {
          "name":"warning",

          "tags":[
             "!",
             "alert",
             "attention",
             "caution",
             "danger",
             "error",
             "exclamation",
             "important",
             "mark",
             "notification",
             "symbol",
             "triangle",
             "warning"
          ],

       },
       {
          "name":"warning_amber",

          "tags":[
             "!",
             "alert",
             "amber",
             "attention",
             "caution",
             "danger",
             "error",
             "exclamation",
             "important",
             "mark",
             "notification",
             "symbol",
             "triangle",
             "warning"
          ],

       },
       {
          "name":"wash",

          "tags":[
             "bathroom",
             "clean",
             "fingers",
             "gesture",
             "hand",
             "wash",
             "wc"
          ],

       },
       {
          "name":"watch",

          "tags":[
             "Android",
             "OS",
             "ar",
             "clock",
             "gadget",
             "iOS",
             "time",
             "vr",
             "watch",
             "wearables",
             "web",
             "wristwatch"
          ],

       },
       {
          "name":"watch_later",

          "tags":[
             "clock",
             "date",
             "later",
             "schedule",
             "time",
             "watch"
          ],

       },
       {
          "name":"water",

          "tags":[
             "aqua",
             "beach",
             "lake",
             "ocean",
             "river",
             "water",
             "waves",
             "weather"
          ],

       },
       {
          "name":"water_damage",

          "tags":[
             "architecture",
             "building",
             "damage",
             "drop",
             "droplet",
             "estate",
             "house",
             "leak",
             "plumbing",
             "real",
             "residence",
             "residential",
             "shelter",
             "water"
          ],

       },
       {
          "name":"water_drop",

          "tags":[
             
          ],

       },
       {
          "name":"waterfall_chart",

          "tags":[
             "analytics",
             "bar",
             "chart",
             "data",
             "diagram",
             "graph",
             "infographic",
             "measure",
             "metrics",
             "statistics",
             "tracking",
             "waterfall"
          ],

       },
       {
          "name":"waves",

          "tags":[
             "beach",
             "lake",
             "ocean",
             "pool",
             "river",
             "sea",
             "swim",
             "water",
             "wave",
             "waves"
          ],

       },
       {
          "name":"waving_hand",

          "tags":[
             
          ],

       },
       {
          "name":"wb_auto",

          "tags":[
             "A",
             "W",
             "alphabet",
             "auto",
             "automatic",
             "balance",
             "character",
             "edit",
             "editing",
             "font",
             "image",
             "letter",
             "photo",
             "photography",
             "symbol",
             "text",
             "type",
             "white",
             "wp"
          ],

       },
       {
          "name":"wb_cloudy",

          "tags":[
             "balance",
             "cloud",
             "cloudy",
             "edit",
             "editing",
             "white",
             "wp"
          ],

       },
       {
          "name":"wb_incandescent",

          "tags":[
             "balance",
             "bright",
             "edit",
             "editing",
             "incandescent",
             "light",
             "lighting",
             "setting",
             "settings",
             "white",
             "wp"
          ],

       },
       {
          "name":"wb_iridescent",

          "tags":[
             "balance",
             "bright",
             "edit",
             "editing",
             "iridescent",
             "light",
             "lighting",
             "setting",
             "settings",
             "white",
             "wp"
          ],

       },
       {
          "name":"wb_shade",

          "tags":[
             "balance",
             "house",
             "light",
             "lighting",
             "shade",
             "wb",
             "white"
          ],

       },
       {
          "name":"wb_sunny",

          "tags":[
             "balance",
             "bright",
             "light",
             "lighting",
             "sun",
             "sunny",
             "wb",
             "white"
          ],

       },
       {
          "name":"wb_twilight",

          "tags":[
             
          ],

       },
       {
          "name":"wc",

          "tags":[
             "bathroom",
             "closet",
             "female",
             "male",
             "man",
             "restroom",
             "room",
             "wash",
             "water",
             "wc",
             "women"
          ],

       },
       {
          "name":"web",

          "tags":[
             "browser",
             "internet",
             "page",
             "screen",
             "site",
             "web",
             "website",
             "www"
          ],

       },
       {
          "name":"web_asset",

          "tags":[
             "-website",
             "app",
             "application desktop",
             "asset",
             "browser",
             "design",
             "download",
             "image",
             "interface",
             "internet",
             "layout",
             "screen",
             "site",
             "ui",
             "ux",
             "video",
             "web",
             "website",
             "window",
             "www"
          ],

       },
       {
          "name":"web_asset_off",

          "tags":[
             "asset",
             "browser",
             "disabled",
             "enabled",
             "internet",
             "off",
             "on",
             "page",
             "screen",
             "slash",
             "web",
             "webpage",
             "website",
             "windows",
             "www"
          ],

       },
       {
          "name":"weekend",

          "tags":[
             "chair",
             "couch",
             "furniture",
             "home",
             "living",
             "lounge",
             "relax",
             "room",
             "weekend"
          ],

       },
       {
          "name":"west",

          "tags":[
             "arrow",
             "directional",
             "left",
             "maps",
             "navigation",
             "west"
          ],

       },
       {
          "name":"whatshot",

          "tags":[
             "arrow",
             "circle",
             "direction",
             "fire",
             "frames",
             "hot",
             "round",
             "whatshot"
          ],

       },
       {
          "name":"wheelchair_pickup",

          "tags":[
             "accessibility",
             "accessible",
             "body",
             "handicap",
             "help",
             "human",
             "person",
             "pickup",
             "wheelchair"
          ],

       },
       {
          "name":"where_to_vote",

          "tags":[
             "approve",
             "ballot",
             "check",
             "complete",
             "destination",
             "direction",
             "done",
             "location",
             "maps",
             "mark",
             "ok",
             "pin",
             "place",
             "poll",
             "select",
             "stop",
             "tick",
             "to",
             "validate election",
             "verified",
             "vote",
             "where",
             "yes"
          ],

       },
       {
          "name":"widgets",

          "tags":[
             "app",
             "box",
             "menu",
             "setting",
             "squares",
             "ui",
             "widgets"
          ],

       },
       {
          "name":"wifi",

          "tags":[
             "connection",
             "data",
             "internet",
             "network",
             "scan",
             "service",
             "signal",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"wifi_calling",

          "tags":[
             "call",
             "calling",
             "cell",
             "connect",
             "connection",
             "connectivity",
             "contact",
             "device",
             "hardware",
             "mobile",
             "phone",
             "signal",
             "telephone",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"wifi_calling_3",

          "tags":[
             "3",
             "calling",
             "cell",
             "cellular",
             "data",
             "internet",
             "mobile",
             "network",
             "phone",
             "speed",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"wifi_lock",

          "tags":[
             "cellular",
             "connection",
             "data",
             "internet",
             "lock",
             "locked",
             "mobile",
             "network",
             "password",
             "privacy",
             "private",
             "protection",
             "safety",
             "secure",
             "security",
             "service",
             "signal",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"wifi_off",

          "tags":[
             "connection",
             "data",
             "disabled",
             "enabled",
             "internet",
             "network",
             "off",
             "offline",
             "on",
             "scan",
             "service",
             "signal",
             "slash",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"wifi_protected_setup",

          "tags":[
             "around",
             "arrow",
             "arrows",
             "protected",
             "rotate",
             "setup",
             "wifi"
          ],

       },
       {
          "name":"wifi_tethering",

          "tags":[
             "cell",
             "cellular",
             "connection",
             "data",
             "internet",
             "mobile",
             "network",
             "phone",
             "scan",
             "service",
             "signal",
             "speed",
             "tethering",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"wifi_tethering_error_rounded",

          "tags":[
             "!",
             "alert",
             "attention",
             "caution",
             "cell",
             "cellular",
             "connection",
             "danger",
             "data",
             "error",
             "exclamation",
             "important",
             "internet",
             "mark",
             "mobile",
             "network",
             "notification",
             "phone",
             "rounded",
             "scan",
             "service",
             "signal",
             "speed",
             "symbol",
             "tethering",
             "warning",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"wifi_tethering_off",

          "tags":[
             "cell",
             "cellular",
             "connection",
             "data",
             "disabled",
             "enabled",
             "internet",
             "mobile",
             "network",
             "off",
             "offline",
             "on",
             "phone",
             "scan",
             "service",
             "signal",
             "slash",
             "speed",
             "tethering",
             "wifi",
             "wireless"
          ],

       },
       {
          "name":"window",

          "tags":[
             "close",
             "glass",
             "grid",
             "home",
             "house",
             "interior",
             "layout",
             "outside",
             "window"
          ],

       },
       {
          "name":"wine_bar",

          "tags":[
             "alcohol",
             "bar",
             "cocktail",
             "cup",
             "drink",
             "glass",
             "liquor",
             "wine"
          ],

       },
       {
          "name":"work",

          "tags":[
             "-briefcase",
             "bag",
             "baggage",
             "business",
             "case",
             "job",
             "suitcase",
             "work"
          ],

       },
       {
          "name":"work_off",

          "tags":[
             "bag",
             "baggage",
             "briefcase",
             "business",
             "case",
             "disabled",
             "enabled",
             "job",
             "off",
             "on",
             "slash",
             "suitcase",
             "work"
          ],

       },
       {
          "name":"work_outline",

          "tags":[
             "bag",
             "baggage",
             "briefcase",
             "business",
             "case",
             "job",
             "suitcase",
             "work"
          ],

       },
       {
          "name":"workspace_premium",

          "tags":[
             
          ],

       },
       {
          "name":"workspaces",

          "tags":[
             
          ],

       },
       {
          "name":"wrap_text",

          "tags":[
             "arrow writing",
             "doc",
             "edit",
             "editing",
             "editor",
             "sheet",
             "spreadsheet",
             "text",
             "type",
             "wrap",
             "write",
             "writing"
          ],

       },
       {
          "name":"wrong_location",

          "tags":[
             "cancel",
             "close",
             "destination",
             "direction",
             "exit",
             "location",
             "maps",
             "no",
             "pin",
             "place",
             "quit",
             "remove",
             "stop",
             "wrong",
             "x"
          ],

       },
       {
          "name":"wysiwyg",

          "tags":[
             "composer",
             "mode",
             "screen",
             "site",
             "software",
             "system",
             "text",
             "view",
             "visibility",
             "web",
             "website",
             "window",
             "wysiwyg"
          ],

       },
       {
          "name":"yard",

          "tags":[
             "backyard",
             "flower",
             "garden",
             "home",
             "house",
             "nature",
             "pettle",
             "plants",
             "yard"
          ],

       },
       {
          "name":"youtube_searched_for",

          "tags":[
             "arrow",
             "back",
             "backwards",
             "find",
             "glass",
             "history",
             "inprogress",
             "load",
             "loading",
             "look",
             "magnify",
             "magnifying",
             "refresh",
             "renew",
             "restore",
             "reverse",
             "rotate",
             "search",
             "see",
             "youtube"
          ],

       },
       {
          "name":"zoom_in",

          "tags":[
             "big",
             "bigger",
             "find",
             "glass",
             "grow",
             "in",
             "look",
             "magnify",
             "magnifying",
             "plus",
             "scale",
             "search",
             "see",
             "size",
             "zoom"
          ],

       },
       {
          "name":"zoom_out",

          "tags":[
             "find",
             "glass",
             "look",
             "magnify",
             "magnifying",
             "minus",
             "negative",
             "out",
             "scale",
             "search",
             "see",
             "size",
             "small",
             "smaller",
             "zoom"
          ],

       },
       {
          "name":"zoom_out_map",

          "tags":[
             "arrow",
             "arrows",
             "destination",
             "location",
             "maps",
             "move",
             "out",
             "place",
             "stop",
             "zoom"
          ],

       }
    ],
    "iconsNames": [
      "10k",
      "10mp",
      "11mp",
      "12mp",
      "13mp",
      "14mp",
      "15mp",
      "16mp",
      "17mp",
      "18mp",
      "19mp",
      "1k",
      "1k_plus",
      "1x_mobiledata",
      "20mp",
      "21mp",
      "22mp",
      "23mp",
      "24mp",
      "2k",
      "2k_plus",
      "2mp",
      "30fps",
      "30fps_select",
      "360",
      "3d_rotation",
      "3g_mobiledata",
      "3k",
      "3k_plus",
      "3mp",
      "3p",
      "4g_mobiledata",
      "4g_plus_mobiledata",
      "4k",
      "4k_plus",
      "4mp",
      "5g",
      "5k",
      "5k_plus",
      "5mp",
      "60fps",
      "60fps_select",
      "6_ft_apart",
      "6k",
      "6k_plus",
      "6mp",
      "7k",
      "7k_plus",
      "7mp",
      "8k",
      "8k_plus",
      "8mp",
      "9k",
      "9k_plus",
      "9mp",
      "ac_unit",
      "access_alarm",
      "access_alarms",
      "access_time",
      "access_time_filled",
      "accessibility",
      "accessibility_new",
      "accessible",
      "accessible_forward",
      "account_balance",
      "account_balance_wallet",
      "account_box",
      "account_circle",
      "account_tree",
      "ad_units",
      "adb",
      "add",
      "add_a_photo",
      "add_alarm",
      "add_alert",
      "add_box",
      "add_business",
      "add_chart",
      "add_circle",
      "add_circle_outline",
      "add_comment",
      "add_ic_call",
      "add_link",
      "add_location",
      "add_location_alt",
      "add_moderator",
      "add_photo_alternate",
      "add_reaction",
      "add_road",
      "add_shopping_cart",
      "add_task",
      "add_to_drive",
      "add_to_home_screen",
      "add_to_photos",
      "add_to_queue",
      "addchart",
      "adjust",
      "admin_panel_settings",
      "ads_click",
      "agriculture",
      "air",
      "airline_seat_flat",
      "airline_seat_flat_angled",
      "airline_seat_individual_suite",
      "airline_seat_legroom_extra",
      "airline_seat_legroom_normal",
      "airline_seat_legroom_reduced",
      "airline_seat_recline_extra",
      "airline_seat_recline_normal",
      "airline_stops",
      "airlines",
      "airplane_ticket",
      "airplanemode_active",
      "airplanemode_inactive",
      "airplay",
      "airport_shuttle",
      "alarm",
      "alarm_add",
      "alarm_off",
      "alarm_on",
      "album",
      "align_horizontal_center",
      "align_horizontal_left",
      "align_horizontal_right",
      "align_vertical_bottom",
      "align_vertical_center",
      "align_vertical_top",
      "all_inbox",
      "all_inclusive",
      "all_out",
      "alt_route",
      "alternate_email",
      "analytics",
      "anchor",
      "android",
      "animation",
      "announcement",
      "aod",
      "apartment",
      "api",
      "app_blocking",
      "app_registration",
      "app_settings_alt",
      "approval",
      "apps",
      "apps_outage",
      "architecture",
      "archive",
      "area_chart",
      "arrow_back",
      "arrow_back_ios",
      "arrow_back_ios_new",
      "arrow_circle_down",
      "arrow_circle_up",
      "arrow_downward",
      "arrow_drop_down",
      "arrow_drop_down_circle",
      "arrow_drop_up",
      "arrow_forward",
      "arrow_forward_ios",
      "arrow_left",
      "arrow_right",
      "arrow_right_alt",
      "arrow_upward",
      "art_track",
      "article",
      "aspect_ratio",
      "assessment",
      "assignment",
      "assignment_ind",
      "assignment_late",
      "assignment_return",
      "assignment_returned",
      "assignment_turned_in",
      "assistant",
      "assistant_direction",
      "assistant_photo",
      "atm",
      "attach_email",
      "attach_file",
      "attach_money",
      "attachment",
      "attractions",
      "attribution",
      "audiotrack",
      "auto_awesome",
      "auto_awesome_mosaic",
      "auto_awesome_motion",
      "auto_delete",
      "auto_fix_high",
      "auto_fix_normal",
      "auto_fix_off",
      "auto_graph",
      "auto_stories",
      "autofps_select",
      "autorenew",
      "av_timer",
      "baby_changing_station",
      "back_hand",
      "backpack",
      "backspace",
      "backup",
      "backup_table",
      "badge",
      "bakery_dining",
      "balcony",
      "ballot",
      "bar_chart",
      "batch_prediction",
      "bathroom",
      "bathtub",
      "battery_alert",
      "battery_charging_full",
      "battery_full",
      "battery_saver",
      "battery_std",
      "battery_unknown",
      "beach_access",
      "bed",
      "bedroom_baby",
      "bedroom_child",
      "bedroom_parent",
      "bedtime",
      "beenhere",
      "bento",
      "bike_scooter",
      "biotech",
      "blender",
      "block",
      "bloodtype",
      "bluetooth",
      "bluetooth_audio",
      "bluetooth_connected",
      "bluetooth_disabled",
      "bluetooth_drive",
      "bluetooth_searching",
      "blur_circular",
      "blur_linear",
      "blur_off",
      "blur_on",
      "bolt",
      "book",
      "book_online",
      "bookmark",
      "bookmark_add",
      "bookmark_added",
      "bookmark_border",
      "bookmark_remove",
      "bookmarks",
      "border_all",
      "border_bottom",
      "border_clear",
      "border_color",
      "border_horizontal",
      "border_inner",
      "border_left",
      "border_outer",
      "border_right",
      "border_style",
      "border_top",
      "border_vertical",
      "branding_watermark",
      "breakfast_dining",
      "brightness_1",
      "brightness_2",
      "brightness_3",
      "brightness_4",
      "brightness_5",
      "brightness_6",
      "brightness_7",
      "brightness_auto",
      "brightness_high",
      "brightness_low",
      "brightness_medium",
      "broken_image",
      "browser_not_supported",
      "browser_updated",
      "brunch_dining",
      "brush",
      "bubble_chart",
      "bug_report",
      "build",
      "build_circle",
      "bungalow",
      "burst_mode",
      "bus_alert",
      "business",
      "business_center",
      "cabin",
      "cable",
      "cached",
      "cake",
      "calculate",
      "calendar_today",
      "calendar_view_day",
      "calendar_view_month",
      "calendar_view_week",
      "call",
      "call_end",
      "call_made",
      "call_merge",
      "call_missed",
      "call_missed_outgoing",
      "call_received",
      "call_split",
      "call_to_action",
      "camera",
      "camera_alt",
      "camera_enhance",
      "camera_front",
      "camera_indoor",
      "camera_outdoor",
      "camera_rear",
      "camera_roll",
      "cameraswitch",
      "campaign",
      "cancel",
      "cancel_presentation",
      "cancel_schedule_send",
      "car_rental",
      "car_repair",
      "card_giftcard",
      "card_membership",
      "card_travel",
      "carpenter",
      "cases",
      "casino",
      "cast",
      "cast_connected",
      "cast_for_education",
      "catching_pokemon",
      "category",
      "celebration",
      "cell_wifi",
      "center_focus_strong",
      "center_focus_weak",
      "chair",
      "chair_alt",
      "chalet",
      "change_circle",
      "change_history",
      "charging_station",
      "chat",
      "chat_bubble",
      "chat_bubble_outline",
      "check",
      "check_box",
      "check_box_outline_blank",
      "check_circle",
      "check_circle_outline",
      "checklist",
      "checklist_rtl",
      "checkroom",
      "chevron_left",
      "chevron_right",
      "child_care",
      "child_friendly",
      "chrome_reader_mode",
      "circle",
      "circle_notifications",
      "class",
      "clean_hands",
      "cleaning_services",
      "clear",
      "clear_all",
      "close",
      "close_fullscreen",
      "closed_caption",
      "closed_caption_disabled",
      "closed_caption_off",
      "cloud",
      "cloud_circle",
      "cloud_done",
      "cloud_download",
      "cloud_off",
      "cloud_queue",
      "cloud_upload",
      "co2",
      "code",
      "code_off",
      "coffee",
      "coffee_maker",
      "collections",
      "collections_bookmark",
      "color_lens",
      "colorize",
      "comment",
      "comment_bank",
      "comments_disabled",
      "commute",
      "compare",
      "compare_arrows",
      "compass_calibration",
      "compost",
      "compress",
      "computer",
      "confirmation_number",
      "connect_without_contact",
      "connected_tv",
      "connecting_airports",
      "construction",
      "contact_mail",
      "contact_page",
      "contact_phone",
      "contact_support",
      "contactless",
      "contacts",
      "content_copy",
      "content_cut",
      "content_paste",
      "content_paste_off",
      "control_camera",
      "control_point",
      "control_point_duplicate",
      "copy_all",
      "copyright",
      "coronavirus",
      "corporate_fare",
      "cottage",
      "countertops",
      "create",
      "create_new_folder",
      "credit_card",
      "credit_card_off",
      "credit_score",
      "crib",
      "crop",
      "crop_16_9",
      "crop_3_2",
      "crop_5_4",
      "crop_7_5",
      "crop_din",
      "crop_free",
      "crop_landscape",
      "crop_original",
      "crop_portrait",
      "crop_rotate",
      "crop_square",
      "cruelty_free",
      "dangerous",
      "dark_mode",
      "dashboard",
      "dashboard_customize",
      "data_exploration",
      "data_saver_off",
      "data_saver_on",
      "data_usage",
      "date_range",
      "deck",
      "dehaze",
      "delete",
      "delete_forever",
      "delete_outline",
      "delete_sweep",
      "delivery_dining",
      "departure_board",
      "description",
      "design_services",
      "desktop_access_disabled",
      "desktop_mac",
      "desktop_windows",
      "details",
      "developer_board",
      "developer_board_off",
      "developer_mode",
      "device_hub",
      "device_thermostat",
      "device_unknown",
      "devices",
      "devices_other",
      "dialer_sip",
      "dialpad",
      "dining",
      "dinner_dining",
      "directions",
      "directions_bike",
      "directions_boat",
      "directions_boat_filled",
      "directions_bus",
      "directions_bus_filled",
      "directions_car",
      "directions_car_filled",
      "directions_off",
      "directions_railway",
      "directions_railway_filled",
      "directions_run",
      "directions_subway",
      "directions_subway_filled",
      "directions_transit",
      "directions_transit_filled",
      "directions_walk",
      "dirty_lens",
      "disabled_by_default",
      "disabled_visible",
      "disc_full",
      "dns",
      "do_disturb",
      "do_disturb_alt",
      "do_disturb_off",
      "do_disturb_on",
      "do_not_disturb",
      "do_not_disturb_alt",
      "do_not_disturb_off",
      "do_not_disturb_on",
      "do_not_disturb_on_total_silence",
      "do_not_step",
      "do_not_touch",
      "dock",
      "document_scanner",
      "domain",
      "domain_disabled",
      "domain_verification",
      "done",
      "done_all",
      "done_outline",
      "donut_large",
      "donut_small",
      "door_back",
      "door_front",
      "door_sliding",
      "doorbell",
      "double_arrow",
      "downhill_skiing",
      "download",
      "download_done",
      "download_for_offline",
      "downloading",
      "drafts",
      "drag_handle",
      "drag_indicator",
      "draw",
      "drive_eta",
      "drive_file_move",
      "drive_file_move_rtl",
      "drive_file_rename_outline",
      "drive_folder_upload",
      "dry",
      "dry_cleaning",
      "duo",
      "dvr",
      "dynamic_feed",
      "dynamic_form",
      "e_mobiledata",
      "earbuds",
      "earbuds_battery",
      "east",
      "edgesensor_high",
      "edgesensor_low",
      "edit",
      "edit_attributes",
      "edit_calendar",
      "edit_location",
      "edit_location_alt",
      "edit_note",
      "edit_notifications",
      "edit_off",
      "edit_road",
      "eject",
      "elderly",
      "electric_bike",
      "electric_car",
      "electric_moped",
      "electric_rickshaw",
      "electric_scooter",
      "electrical_services",
      "elevator",
      "email",
      "emergency",
      "emoji_emotions",
      "emoji_events",
      "emoji_flags",
      "emoji_food_beverage",
      "emoji_nature",
      "emoji_objects",
      "emoji_people",
      "emoji_symbols",
      "emoji_transportation",
      "engineering",
      "enhanced_encryption",
      "equalizer",
      "error",
      "error_outline",
      "escalator",
      "escalator_warning",
      "euro",
      "euro_symbol",
      "ev_station",
      "event",
      "event_available",
      "event_busy",
      "event_note",
      "event_seat",
      "exit_to_app",
      "expand",
      "expand_circle_down",
      "expand_less",
      "expand_more",
      "explicit",
      "explore",
      "explore_off",
      "exposure",
      "exposure_neg_1",
      "exposure_neg_2",
      "exposure_plus_1",
      "exposure_plus_2",
      "exposure_zero",
      "extension",
      "extension_off",
      "face",
      "face_retouching_natural",
      "face_retouching_off",
      "facebook",
      "fact_check",
      "family_restroom",
      "fast_forward",
      "fast_rewind",
      "fastfood",
      "favorite",
      "favorite_border",
      "featured_play_list",
      "featured_video",
      "feed",
      "feedback",
      "female",
      "fence",
      "festival",
      "fiber_dvr",
      "fiber_manual_record",
      "fiber_new",
      "fiber_pin",
      "fiber_smart_record",
      "file_copy",
      "file_download",
      "file_download_done",
      "file_download_off",
      "file_present",
      "file_upload",
      "filter",
      "filter_1",
      "filter_2",
      "filter_3",
      "filter_4",
      "filter_5",
      "filter_6",
      "filter_7",
      "filter_8",
      "filter_9",
      "filter_9_plus",
      "filter_alt",
      "filter_b_and_w",
      "filter_center_focus",
      "filter_drama",
      "filter_frames",
      "filter_hdr",
      "filter_list",
      "filter_none",
      "filter_tilt_shift",
      "filter_vintage",
      "find_in_page",
      "find_replace",
      "fingerprint",
      "fire_extinguisher",
      "fireplace",
      "first_page",
      "fit_screen",
      "fitness_center",
      "flag",
      "flaky",
      "flare",
      "flash_auto",
      "flash_off",
      "flash_on",
      "flashlight_off",
      "flashlight_on",
      "flatware",
      "flight",
      "flight_class",
      "flight_land",
      "flight_takeoff",
      "flip",
      "flip_camera_android",
      "flip_camera_ios",
      "flip_to_back",
      "flip_to_front",
      "flourescent",
      "flutter_dash",
      "fmd_bad",
      "fmd_good",
      "folder",
      "folder_open",
      "folder_shared",
      "folder_special",
      "follow_the_signs",
      "font_download",
      "font_download_off",
      "food_bank",
      "format_align_center",
      "format_align_justify",
      "format_align_left",
      "format_align_right",
      "format_bold",
      "format_clear",
      "format_color_fill",
      "format_color_reset",
      "format_color_text",
      "format_indent_decrease",
      "format_indent_increase",
      "format_italic",
      "format_line_spacing",
      "format_list_bulleted",
      "format_list_numbered",
      "format_list_numbered_rtl",
      "format_paint",
      "format_quote",
      "format_shapes",
      "format_size",
      "format_strikethrough",
      "format_textdirection_l_to_r",
      "format_textdirection_r_to_l",
      "format_underlined",
      "forum",
      "forward",
      "forward_10",
      "forward_30",
      "forward_5",
      "forward_to_inbox",
      "foundation",
      "free_breakfast",
      "free_cancellation",
      "front_hand",
      "fullscreen",
      "fullscreen_exit",
      "functions",
      "g_mobiledata",
      "g_translate",
      "gamepad",
      "games",
      "garage",
      "gavel",
      "generating_tokens",
      "gesture",
      "get_app",
      "gif",
      "gif_box",
      "gite",
      "golf_course",
      "gpp_bad",
      "gpp_good",
      "gpp_maybe",
      "gps_fixed",
      "gps_not_fixed",
      "gps_off",
      "grade",
      "gradient",
      "grading",
      "grain",
      "graphic_eq",
      "grass",
      "grid_3x3",
      "grid_4x4",
      "grid_goldenratio",
      "grid_off",
      "grid_on",
      "grid_view",
      "group",
      "group_add",
      "group_off",
      "group_remove",
      "group_work",
      "groups",
      "h_mobiledata",
      "h_plus_mobiledata",
      "hail",
      "handyman",
      "hardware",
      "hd",
      "hdr_auto",
      "hdr_auto_select",
      "hdr_enhanced_select",
      "hdr_off",
      "hdr_off_select",
      "hdr_on",
      "hdr_on_select",
      "hdr_plus",
      "hdr_strong",
      "hdr_weak",
      "headphones",
      "headphones_battery",
      "headset",
      "headset_mic",
      "headset_off",
      "healing",
      "health_and_safety",
      "hearing",
      "hearing_disabled",
      "height",
      "help",
      "help_center",
      "help_outline",
      "hevc",
      "hide_image",
      "hide_source",
      "high_quality",
      "highlight",
      "highlight_alt",
      "highlight_off",
      "hiking",
      "history",
      "history_edu",
      "history_toggle_off",
      "holiday_village",
      "home",
      "home_max",
      "home_mini",
      "home_repair_service",
      "home_work",
      "horizontal_distribute",
      "horizontal_rule",
      "horizontal_split",
      "hot_tub",
      "hotel",
      "hotel_class",
      "hourglass_bottom",
      "hourglass_disabled",
      "hourglass_empty",
      "hourglass_full",
      "hourglass_top",
      "house",
      "house_siding",
      "houseboat",
      "how_to_reg",
      "how_to_vote",
      "http",
      "https",
      "hvac",
      "ice_skating",
      "icecream",
      "image",
      "image_aspect_ratio",
      "image_not_supported",
      "image_search",
      "imagesearch_roller",
      "import_contacts",
      "import_export",
      "important_devices",
      "inbox",
      "incomplete_circle",
      "indeterminate_check_box",
      "info",
      "input",
      "insert_chart",
      "insert_chart_outlined",
      "insert_comment",
      "insert_drive_file",
      "insert_emoticon",
      "insert_invitation",
      "insert_link",
      "insert_photo",
      "insights",
      "integration_instructions",
      "interests",
      "inventory",
      "inventory_2",
      "invert_colors",
      "invert_colors_off",
      "ios_share",
      "iron",
      "iso",
      "kayaking",
      "keyboard",
      "keyboard_alt",
      "keyboard_arrow_down",
      "keyboard_arrow_left",
      "keyboard_arrow_right",
      "keyboard_arrow_up",
      "keyboard_backspace",
      "keyboard_capslock",
      "keyboard_hide",
      "keyboard_return",
      "keyboard_tab",
      "keyboard_voice",
      "king_bed",
      "kitchen",
      "kitesurfing",
      "label",
      "label_important",
      "label_off",
      "landscape",
      "language",
      "laptop",
      "laptop_chromebook",
      "laptop_mac",
      "laptop_windows",
      "last_page",
      "launch",
      "layers",
      "layers_clear",
      "leaderboard",
      "leak_add",
      "leak_remove",
      "legend_toggle",
      "lens",
      "lens_blur",
      "library_add",
      "library_add_check",
      "library_books",
      "library_music",
      "light",
      "light_mode",
      "lightbulb",
      "line_style",
      "line_weight",
      "linear_scale",
      "link",
      "link_off",
      "linked_camera",
      "liquor",
      "list",
      "list_alt",
      "live_help",
      "live_tv",
      "living",
      "local_activity",
      "local_airport",
      "local_atm",
      "local_bar",
      "local_cafe",
      "local_car_wash",
      "local_convenience_store",
      "local_dining",
      "local_drink",
      "local_fire_department",
      "local_florist",
      "local_gas_station",
      "local_grocery_store",
      "local_hospital",
      "local_hotel",
      "local_laundry_service",
      "local_library",
      "local_mall",
      "local_movies",
      "local_offer",
      "local_parking",
      "local_pharmacy",
      "local_phone",
      "local_pizza",
      "local_play",
      "local_police",
      "local_post_office",
      "local_printshop",
      "local_see",
      "local_shipping",
      "local_taxi",
      "location_city",
      "location_disabled",
      "location_off",
      "location_on",
      "location_searching",
      "lock",
      "lock_clock",
      "lock_open",
      "login",
      "logout",
      "looks",
      "looks_3",
      "looks_4",
      "looks_5",
      "looks_6",
      "looks_one",
      "looks_two",
      "loop",
      "loupe",
      "low_priority",
      "loyalty",
      "lte_mobiledata",
      "lte_plus_mobiledata",
      "luggage",
      "lunch_dining",
      "mail",
      "mail_outline",
      "male",
      "manage_accounts",
      "manage_search",
      "map",
      "maps_home_work",
      "maps_ugc",
      "margin",
      "mark_as_unread",
      "mark_chat_read",
      "mark_chat_unread",
      "mark_email_read",
      "mark_email_unread",
      "markunread",
      "markunread_mailbox",
      "masks",
      "maximize",
      "media_bluetooth_off",
      "media_bluetooth_on",
      "mediation",
      "medical_services",
      "medication",
      "meeting_room",
      "memory",
      "menu",
      "menu_book",
      "menu_open",
      "merge_type",
      "message",
      "mic",
      "mic_external_off",
      "mic_external_on",
      "mic_none",
      "mic_off",
      "microwave",
      "military_tech",
      "minimize",
      "miscellaneous_services",
      "missed_video_call",
      "mms",
      "mobile_friendly",
      "mobile_off",
      "mobile_screen_share",
      "mobiledata_off",
      "mode",
      "mode_comment",
      "mode_edit",
      "mode_edit_outline",
      "mode_night",
      "mode_of_travel",
      "mode_standby",
      "model_training",
      "monetization_on",
      "money",
      "money_off",
      "money_off_csred",
      "monitor",
      "monitor_weight",
      "monochrome_photos",
      "mood",
      "mood_bad",
      "moped",
      "more",
      "more_horiz",
      "more_time",
      "more_vert",
      "motion_photos_auto",
      "motion_photos_off",
      "motion_photos_on",
      "motion_photos_pause",
      "motion_photos_paused",
      "mouse",
      "move_to_inbox",
      "movie",
      "movie_creation",
      "movie_filter",
      "moving",
      "mp",
      "multiline_chart",
      "multiple_stop",
      "museum",
      "music_note",
      "music_off",
      "music_video",
      "my_location",
      "nat",
      "nature",
      "nature_people",
      "navigate_before",
      "navigate_next",
      "navigation",
      "near_me",
      "near_me_disabled",
      "nearby_error",
      "nearby_off",
      "network_cell",
      "network_check",
      "network_locked",
      "network_wifi",
      "new_label",
      "new_releases",
      "next_plan",
      "next_week",
      "nfc",
      "night_shelter",
      "nightlife",
      "nightlight",
      "nightlight_round",
      "nights_stay",
      "no_accounts",
      "no_backpack",
      "no_cell",
      "no_drinks",
      "no_encryption",
      "no_encryption_gmailerrorred",
      "no_flash",
      "no_food",
      "no_luggage",
      "no_meals",
      "no_meeting_room",
      "no_photography",
      "no_sim",
      "no_stroller",
      "no_transfer",
      "nordic_walking",
      "north",
      "north_east",
      "north_west",
      "not_accessible",
      "not_interested",
      "not_listed_location",
      "not_started",
      "note",
      "note_add",
      "note_alt",
      "notes",
      "notification_add",
      "notification_important",
      "notifications",
      "notifications_active",
      "notifications_none",
      "notifications_off",
      "notifications_paused",
      "offline_bolt",
      "offline_pin",
      "offline_share",
      "ondemand_video",
      "online_prediction",
      "opacity",
      "open_in_browser",
      "open_in_full",
      "open_in_new",
      "open_in_new_off",
      "open_with",
      "other_houses",
      "outbound",
      "outbox",
      "outdoor_grill",
      "outlet",
      "outlined_flag",
      "padding",
      "pages",
      "pageview",
      "paid",
      "palette",
      "pan_tool",
      "panorama",
      "panorama_fish_eye",
      "panorama_horizontal",
      "panorama_horizontal_select",
      "panorama_photosphere",
      "panorama_photosphere_select",
      "panorama_vertical",
      "panorama_vertical_select",
      "panorama_wide_angle",
      "panorama_wide_angle_select",
      "paragliding",
      "park",
      "party_mode",
      "password",
      "pattern",
      "pause",
      "pause_circle",
      "pause_circle_filled",
      "pause_circle_outline",
      "pause_presentation",
      "payment",
      "payments",
      "pedal_bike",
      "pending",
      "pending_actions",
      "people",
      "people_alt",
      "people_outline",
      "perm_camera_mic",
      "perm_contact_calendar",
      "perm_data_setting",
      "perm_device_information",
      "perm_identity",
      "perm_media",
      "perm_phone_msg",
      "perm_scan_wifi",
      "person",
      "person_add",
      "person_add_alt",
      "person_add_alt_1",
      "person_add_disabled",
      "person_off",
      "person_outline",
      "person_pin",
      "person_pin_circle",
      "person_remove",
      "person_remove_alt_1",
      "person_search",
      "personal_injury",
      "personal_video",
      "pest_control",
      "pest_control_rodent",
      "pets",
      "phone",
      "phone_android",
      "phone_bluetooth_speaker",
      "phone_callback",
      "phone_disabled",
      "phone_enabled",
      "phone_forwarded",
      "phone_in_talk",
      "phone_iphone",
      "phone_locked",
      "phone_missed",
      "phone_paused",
      "phonelink",
      "phonelink_erase",
      "phonelink_lock",
      "phonelink_off",
      "phonelink_ring",
      "phonelink_setup",
      "photo",
      "photo_album",
      "photo_camera",
      "photo_camera_back",
      "photo_camera_front",
      "photo_filter",
      "photo_library",
      "photo_size_select_actual",
      "photo_size_select_large",
      "photo_size_select_small",
      "piano",
      "piano_off",
      "picture_as_pdf",
      "picture_in_picture",
      "picture_in_picture_alt",
      "pie_chart",
      "pie_chart_outline",
      "pin",
      "pin_drop",
      "pin_end",
      "pin_invoke",
      "pivot_table_chart",
      "place",
      "plagiarism",
      "play_arrow",
      "play_circle",
      "play_circle_filled",
      "play_circle_outline",
      "play_disabled",
      "play_for_work",
      "play_lesson",
      "playlist_add",
      "playlist_add_check",
      "playlist_play",
      "plumbing",
      "plus_one",
      "podcasts",
      "point_of_sale",
      "policy",
      "poll",
      "polymer",
      "pool",
      "portable_wifi_off",
      "portrait",
      "post_add",
      "power",
      "power_input",
      "power_off",
      "power_settings_new",
      "precision_manufacturing",
      "pregnant_woman",
      "present_to_all",
      "preview",
      "price_change",
      "price_check",
      "print",
      "print_disabled",
      "priority_high",
      "privacy_tip",
      "private_connectivity",
      "production_quantity_limits",
      "psychology",
      "public",
      "public_off",
      "publish",
      "published_with_changes",
      "push_pin",
      "qr_code",
      "qr_code_2",
      "qr_code_scanner",
      "query_builder",
      "query_stats",
      "question_answer",
      "queue",
      "queue_music",
      "queue_play_next",
      "quickreply",
      "quiz",
      "r_mobiledata",
      "radar",
      "radio",
      "radio_button_checked",
      "radio_button_unchecked",
      "railway_alert",
      "ramen_dining",
      "rate_review",
      "raw_off",
      "raw_on",
      "read_more",
      "real_estate_agent",
      "receipt",
      "receipt_long",
      "recent_actors",
      "recommend",
      "record_voice_over",
      "recycling",
      "redeem",
      "redo",
      "reduce_capacity",
      "refresh",
      "remember_me",
      "remove",
      "remove_circle",
      "remove_circle_outline",
      "remove_done",
      "remove_from_queue",
      "remove_moderator",
      "remove_red_eye",
      "remove_shopping_cart",
      "reorder",
      "repeat",
      "repeat_on",
      "repeat_one",
      "repeat_one_on",
      "replay",
      "replay_10",
      "replay_30",
      "replay_5",
      "replay_circle_filled",
      "reply",
      "reply_all",
      "report",
      "report_gmailerrorred",
      "report_off",
      "report_problem",
      "request_page",
      "request_quote",
      "reset_tv",
      "restart_alt",
      "restaurant",
      "restaurant_menu",
      "restore",
      "restore_from_trash",
      "restore_page",
      "reviews",
      "rice_bowl",
      "ring_volume",
      "roofing",
      "room",
      "room_preferences",
      "room_service",
      "rotate_90_degrees_ccw",
      "rotate_left",
      "rotate_right",
      "rounded_corner",
      "router",
      "rowing",
      "rss_feed",
      "rsvp",
      "rtt",
      "rule",
      "rule_folder",
      "run_circle",
      "running_with_errors",
      "rv_hookup",
      "safety_divider",
      "sailing",
      "sanitizer",
      "satellite",
      "save",
      "save_alt",
      "saved_search",
      "savings",
      "scanner",
      "scatter_plot",
      "schedule",
      "schedule_send",
      "schema",
      "school",
      "science",
      "score",
      "screen_lock_landscape",
      "screen_lock_portrait",
      "screen_lock_rotation",
      "screen_rotation",
      "screen_search_desktop",
      "screen_share",
      "screenshot",
      "sd",
      "sd_card",
      "sd_card_alert",
      "sd_storage",
      "search",
      "search_off",
      "security",
      "security_update",
      "security_update_good",
      "security_update_warning",
      "segment",
      "select_all",
      "self_improvement",
      "sell",
      "send",
      "send_and_archive",
      "send_to_mobile",
      "sensor_door",
      "sensor_window",
      "sensors",
      "sensors_off",
      "sentiment_dissatisfied",
      "sentiment_neutral",
      "sentiment_satisfied",
      "sentiment_satisfied_alt",
      "sentiment_very_dissatisfied",
      "sentiment_very_satisfied",
      "set_meal",
      "settings",
      "settings_accessibility",
      "settings_applications",
      "settings_backup_restore",
      "settings_bluetooth",
      "settings_brightness",
      "settings_cell",
      "settings_ethernet",
      "settings_input_antenna",
      "settings_input_component",
      "settings_input_composite",
      "settings_input_hdmi",
      "settings_input_svideo",
      "settings_overscan",
      "settings_phone",
      "settings_power",
      "settings_remote",
      "settings_suggest",
      "settings_system_daydream",
      "settings_voice",
      "share",
      "share_location",
      "shield",
      "shop",
      "shop_2",
      "shop_two",
      "shopping_bag",
      "shopping_basket",
      "shopping_cart",
      "short_text",
      "shortcut",
      "show_chart",
      "shower",
      "shuffle",
      "shuffle_on",
      "shutter_speed",
      "sick",
      "signal_cellular_0_bar",
      "signal_cellular_4_bar",
      "signal_cellular_alt",
      "signal_cellular_connected_no_internet_0_bar",
      "signal_cellular_connected_no_internet_4_bar",
      "signal_cellular_no_sim",
      "signal_cellular_nodata",
      "signal_cellular_null",
      "signal_cellular_off",
      "signal_wifi_0_bar",
      "signal_wifi_4_bar",
      "signal_wifi_4_bar_lock",
      "signal_wifi_bad",
      "signal_wifi_connected_no_internet_4",
      "signal_wifi_off",
      "signal_wifi_statusbar_4_bar",
      "signal_wifi_statusbar_connected_no_internet_4",
      "signal_wifi_statusbar_null",
      "sim_card",
      "sim_card_alert",
      "sim_card_download",
      "single_bed",
      "sip",
      "skateboarding",
      "skip_next",
      "skip_previous",
      "sledding",
      "slideshow",
      "slow_motion_video",
      "smart_button",
      "smart_display",
      "smart_screen",
      "smart_toy",
      "smartphone",
      "smoke_free",
      "smoking_rooms",
      "sms",
      "sms_failed",
      "snippet_folder",
      "snooze",
      "snowboarding",
      "snowmobile",
      "snowshoeing",
      "soap",
      "social_distance",
      "sort",
      "sort_by_alpha",
      "soup_kitchen",
      "source",
      "south",
      "south_east",
      "south_west",
      "spa",
      "space_bar",
      "space_dashboard",
      "speaker",
      "speaker_group",
      "speaker_notes",
      "speaker_notes_off",
      "speaker_phone",
      "speed",
      "spellcheck",
      "splitscreen",
      "sports",
      "sports_bar",
      "sports_baseball",
      "sports_basketball",
      "sports_cricket",
      "sports_esports",
      "sports_football",
      "sports_golf",
      "sports_handball",
      "sports_hockey",
      "sports_kabaddi",
      "sports_mma",
      "sports_motorsports",
      "sports_rugby",
      "sports_score",
      "sports_soccer",
      "sports_tennis",
      "sports_volleyball",
      "square_foot",
      "stacked_bar_chart",
      "stacked_line_chart",
      "stairs",
      "star",
      "star_border",
      "star_border_purple500",
      "star_half",
      "star_outline",
      "star_purple500",
      "star_rate",
      "stars",
      "stay_current_landscape",
      "stay_current_portrait",
      "stay_primary_landscape",
      "stay_primary_portrait",
      "sticky_note_2",
      "stop",
      "stop_circle",
      "stop_screen_share",
      "storage",
      "store",
      "store_mall_directory",
      "storefront",
      "storm",
      "straighten",
      "stream",
      "streetview",
      "strikethrough_s",
      "stroller",
      "style",
      "subdirectory_arrow_left",
      "subdirectory_arrow_right",
      "subject",
      "subscript",
      "subscriptions",
      "subtitles",
      "subtitles_off",
      "subway",
      "summarize",
      "superscript",
      "supervised_user_circle",
      "supervisor_account",
      "support",
      "support_agent",
      "surfing",
      "surround_sound",
      "swap_calls",
      "swap_horiz",
      "swap_horizontal_circle",
      "swap_vert",
      "swap_vertical_circle",
      "swipe",
      "switch_access_shortcut",
      "switch_access_shortcut_add",
      "switch_account",
      "switch_camera",
      "switch_left",
      "switch_right",
      "switch_video",
      "sync",
      "sync_alt",
      "sync_disabled",
      "sync_problem",
      "system_security_update",
      "system_security_update_good",
      "system_security_update_warning",
      "system_update",
      "system_update_alt",
      "tab",
      "tab_unselected",
      "table_chart",
      "table_rows",
      "table_view",
      "tablet",
      "tablet_android",
      "tablet_mac",
      "tag",
      "tag_faces",
      "takeout_dining",
      "tap_and_play",
      "tapas",
      "task",
      "task_alt",
      "taxi_alert",
      "terrain",
      "text_fields",
      "text_format",
      "text_rotate_up",
      "text_rotate_vertical",
      "text_rotation_angledown",
      "text_rotation_angleup",
      "text_rotation_down",
      "text_rotation_none",
      "text_snippet",
      "textsms",
      "texture",
      "theater_comedy",
      "theaters",
      "thermostat",
      "thermostat_auto",
      "thumb_down",
      "thumb_down_alt",
      "thumb_down_off_alt",
      "thumb_up",
      "thumb_up_alt",
      "thumb_up_off_alt",
      "thumbs_up_down",
      "time_to_leave",
      "timelapse",
      "timeline",
      "timer",
      "timer_10",
      "timer_10_select",
      "timer_3",
      "timer_3_select",
      "timer_off",
      "tips_and_updates",
      "title",
      "toc",
      "today",
      "toggle_off",
      "toggle_on",
      "toll",
      "tonality",
      "topic",
      "touch_app",
      "tour",
      "toys",
      "track_changes",
      "traffic",
      "train",
      "tram",
      "transfer_within_a_station",
      "transform",
      "transgender",
      "transit_enterexit",
      "translate",
      "travel_explore",
      "trending_down",
      "trending_flat",
      "trending_up",
      "trip_origin",
      "try",
      "tty",
      "tune",
      "tungsten",
      "turned_in",
      "turned_in_not",
      "tv",
      "tv_off",
      "two_wheeler",
      "umbrella",
      "unarchive",
      "undo",
      "unfold_less",
      "unfold_more",
      "unpublished",
      "unsubscribe",
      "upcoming",
      "update",
      "update_disabled",
      "upgrade",
      "upload",
      "upload_file",
      "usb",
      "usb_off",
      "verified",
      "verified_user",
      "vertical_align_bottom",
      "vertical_align_center",
      "vertical_align_top",
      "vertical_distribute",
      "vertical_split",
      "vibration",
      "video_call",
      "video_camera_back",
      "video_camera_front",
      "video_label",
      "video_library",
      "video_settings",
      "video_stable",
      "videocam",
      "videocam_off",
      "videogame_asset",
      "videogame_asset_off",
      "view_agenda",
      "view_array",
      "view_carousel",
      "view_column",
      "view_comfy",
      "view_compact",
      "view_day",
      "view_headline",
      "view_in_ar",
      "view_list",
      "view_module",
      "view_quilt",
      "view_sidebar",
      "view_stream",
      "view_week",
      "vignette",
      "villa",
      "visibility",
      "visibility_off",
      "voice_chat",
      "voice_over_off",
      "voicemail",
      "volume_down",
      "volume_mute",
      "volume_off",
      "volume_up",
      "volunteer_activism",
      "vpn_key",
      "vpn_lock",
      "vrpano",
      "wallpaper",
      "warning",
      "warning_amber",
      "wash",
      "watch",
      "watch_later",
      "water",
      "water_damage",
      "water_drop",
      "waterfall_chart",
      "waves",
      "waving_hand",
      "wb_auto",
      "wb_cloudy",
      "wb_incandescent",
      "wb_iridescent",
      "wb_shade",
      "wb_sunny",
      "wb_twilight",
      "wc",
      "web",
      "web_asset",
      "web_asset_off",
      "weekend",
      "west",
      "whatshot",
      "wheelchair_pickup",
      "where_to_vote",
      "widgets",
      "wifi",
      "wifi_calling",
      "wifi_calling_3",
      "wifi_lock",
      "wifi_off",
      "wifi_protected_setup",
      "wifi_tethering",
      "wifi_tethering_error_rounded",
      "wifi_tethering_off",
      "window",
      "wine_bar",
      "work",
      "work_off",
      "work_outline",
      "workspace_premium",
      "workspaces",
      "wrap_text",
      "wrong_location",
      "wysiwyg",
      "yard",
      "youtube_searched_for",
      "zoom_in",
      "zoom_out",
       "zoom_out_map"
  ]
}
export default materialIcons