export const stateList = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY'
]

export const StateOptions = [
    {
        text: 'AL',
        value: 'AL'
    },
    {
        text: 'AK',
        value: 'AK'
    },
    {
        text: 'AZ',
        value: 'AZ'
    },
    {
        text: 'AR',
        value: 'AR'
    },
    {
        text: 'CA',
        value: 'CA'
    },
    {
        text: 'CO',
        value: 'CO'
    },
    {
        text: 'CT',
        value: 'CT'
    },
    {
        text: 'DE',
        value: 'DE'
    },
    {
        text: 'FL',
        value: 'FL'
    },
    {
        text: 'GA',
        value: 'GA'
    },
    {
        text: 'HI',
        value: 'HI'
    },
    {
        text: 'ID',
        value: 'ID'
    },
    {
        text: 'IL',
        value: 'IL'
    },
    {
        text: 'IN',
        value: 'IN'
    },
    {
        text: 'IA',
        value: 'IA'
    },
    {
        text: 'KS',
        value: 'KS'
    },
    {
        text: 'KY',
        value: 'KY'
    },
    {
        text: 'LA',
        value: 'LA'
    },
    {
        text: 'ME',
        value: 'ME'
    },
    {
        text: 'MD',
        value: 'MD'
    },
    {
        text: 'MA',
        value: 'MA'
    },
    {
        text: 'MI',
        value: 'MI'
    },
    {
        text: 'MN',
        value: 'MN'
    },
    {
        text: 'MS',
        value: 'MS'
    },
    {
        text: 'MO',
        value: 'MO'
    },
    {
        text: 'MT',
        value: 'MT'
    },
    {
        text: 'NE',
        value: 'NE'
    },
    {
        text: 'NV',
        value: 'NV'
    },
    {
        text: 'NH',
        value: 'NH'
    },
    {
        text: 'NJ',
        value: 'NJ'
    },
    {
        text: 'NM',
        value: 'NM'
    },
    {
        text: 'NY',
        value: 'NY'
    },
    {
        text: 'NC',
        value: 'NC'
    },
    {
        text: 'ND',
        value: 'ND'
    },
    {
        text: 'OH',
        value: 'OH'
    },
    {
        text: 'OK',
        value: 'OK'
    },
    {
        text: 'OR',
        value: 'OR'
    },
    {
        text: 'PA',
        value: 'PA'
    },
    {
        text: 'RI',
        value: 'RI'
    },
    {
        text: 'SC',
        value: 'SC'
    },
    {
        text: 'SD',
        value: 'SD'
    },
    {
        text: 'TN',
        value: 'TN'
    },
    {
        text: 'TX',
        value: 'TX'
    },
    {
        text: 'UT',
        value: 'UT'
    },
    {
        text: 'VT',
        value: 'VT'
    },
    {
        text: 'VA',
        value: 'VA'
    },
    {
        text: 'WA',
        value: 'WA'
    },
    {
        text: 'WV',
        value: 'WV'
    },
    {
        text: 'WI',
        value: 'WI'
    },
    {
        text: 'WY',
        value: 'WY'
    }
]

export const DispatchDurationOptions = [
    {
        text: '30 Seconds',
        value: 30
    },
    {
        text: '45 Seconds',
        value: 45
    },
    {
        text: '60 Seconds',
        value: 60
    },
    {
        text: '75 Seconds',
        value: 75
    },
    {
        text: '90 Seconds',
        value: 90
    },
]