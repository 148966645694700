// React
import React from 'react'

// Material UI
import { Grid, Switch, Typography } from '@material-ui/core'

class GeneralSwitchField extends React.Component {
    handleChange = (e) => {
        let value = e.target.checked
        const { statePath, handleInputChange } = this.props

        handleInputChange(this.props, { value, statePath })
    }

    render() {
        const props = this.props

        return (
            <Grid
                item
                style={{
                    flexDirection: 'row',
                    ...props.gridStyle
                }}
                xs={props.width}
                display='flex'
            >
                <Switch
                    id={props.id}
                    margin={props.margin}
                    size={props.size ? props.size : 'small'}
                    onChange={this.handleChange}
                    checked={props.value}
                    color='primary'
                />
                <Typography
                    variant='subtitle2'
                    gutterBottom
                    style={{
                        display: 'inline',
                        marginLeft: 8
                    }}
                >
                    {props.label}
                </Typography>
                <Typography
                    variant='body1'
                >
                    {props.description}
                </Typography>
                {props.error &&
                    <p
                        style={{ color: '#ff0000', margin: 0, marginTop: 8 }}
                    >
                        {props.error}
                    </p>
                }
            </Grid>
        )
    }
}

export default GeneralSwitchField
