// React
import React, { useEffect, useRef } from 'react'
// Material UI
import { Grid, Typography, Card, CardActionArea } from '@material-ui/core'
import PublicIcon from '@material-ui/icons/Public'
import CallIcon from '@material-ui/icons/Call'
import PersonIcon from '@material-ui/icons/Person'


const UserType = (props) => {
    const { handleUserType, authenticatedUser } = props

    const cardRef = useRef(null)

    useEffect(() => {
        cardRef.current?.focusVisible()
        return () => { }
    }, [])

    return (
        <>
            <Grid
                item
                xs={12}
            >
                <Typography>
                    What type of user do you want to create?
                </Typography>
            </Grid>
            {authenticatedUser.type === 'superAdmin' &&
                <>
                    <Grid
                        item
                        xs={4}
                    >
                        <Card
                            variant='outlined'
                        >
                            <CardActionArea
                                action={cardRef}
                                onClick={() => handleUserType('superAdmin')}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 200
                                }}
                            >
                                <PublicIcon
                                    fontSize='large'
                                    style={{ marginBottom: 16 }}
                                />
                                <Typography>
                                    Super Admin
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                        <Card
                            variant='outlined'
                        >
                            <CardActionArea
                                onClick={() => handleUserType('dispatcher')}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 200
                                }}
                            >
                                <CallIcon
                                    fontSize='large'
                                    style={{ marginBottom: 16 }}
                                />
                                <Typography>
                                    Dispatcher
                                </Typography>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </>
            }
            <Grid
                item
                xs={4}
            >
                <Card
                    variant='outlined'
                >
                    <CardActionArea
                        onClick={() => handleUserType('user')}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 200
                        }}
                    >
                        <PersonIcon
                            fontSize='large'
                            style={{ marginBottom: 16 }}
                        />
                        <Typography>
                            Department User
                        </Typography>
                    </CardActionArea>
                </Card>
            </Grid>
        </>
    )
}

export default UserType;
