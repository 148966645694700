// React
import React from 'react'

// React Router
import { withRouter } from 'react-router-dom'

// Material UI
import { Grid, CssBaseline, Typography, Paper, Button, withStyles, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

// Components
import GeneralTextField from '../general/TextField'

// Assets
import HomepagePhoto from '../../assets/homepage.jpg'
import LogoIcon from '../../assets/logo.png'

// Utilities
import axios from 'axios'
import { handleInputChange, handleErrors } from '../../utilities/handleChange'
import { ValidateAndTransformEmail } from '../../utilities/Validate'
import { ValidateAndTransformToBackendPhoneNumber } from '../../utilities/Validate'
import theme from '../../utilities/theme'


const initialState = {
    page: 'login',
    info: {
        email: '',
        password: ''
    },
    registrationInfo: {
        userName: '',
        email: '',
        phone: '',
        departmentName: ''
    },
    errors: {
        info: {

        },
        registrationInfo: {

        }
    },
    loaders: {
        login: false,
        forgotPassword: false,
        register: false
    },
    errorMessage: ''
}

class Login extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ...initialState,
            page: props.match.path === '/reset-password' ? 'forgotPassword' : 'login'
        }
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleErrors = () => {
        const { info } = this.state
        let errors = { ...this.state.errors }

        let fields = [
            {
                path: 'info.email',
                type: 'string',
                shouldCheck: true,
                validation: !!ValidateAndTransformEmail(info.email),
                validationMessage: "Please provide a valid email address."
            },
            {
                path: 'info.password',
                type: 'string',
                shouldCheck: true
            }
        ]

        let frontEndErrors = handleErrors(this.state, errors, fields)
        // console.log({info, frontEndErrors })
        return frontEndErrors
    }

    handleForgotPasswordErrors = () => {
        const { info } = this.state
        let errors = { ...this.state.errors }

        let fields = [
            {
                path: 'info.email',
                type: 'string',
                shouldCheck: true,
                validation: !!ValidateAndTransformEmail(info.email),
                validationMessage: "Please provide a valid email address."
            }
        ]

        let frontEndErrors = handleErrors(this.state, errors, fields)
        return frontEndErrors
    }

    handleRegisterErrors = () => {
        const { registrationInfo } = this.state
        let errors = { ...this.state.errors }

        let fields = [
            {
                path: 'registrationInfo.userName',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'registrationInfo.email',
                type: 'string',
                shouldCheck: true,
                validation: !!ValidateAndTransformEmail(registrationInfo.email),
                validationMessage: "Please provide a valid email address."
            },
            {
                path: 'registrationInfo.phone',
                type: 'string',
                shouldCheck: true,
                validation: !!ValidateAndTransformToBackendPhoneNumber(registrationInfo.phone),
                validationMessage: "Please provide a valid phone."
            },
            {
                path: 'registrationInfo.departmentName',
                type: 'string',
                shouldCheck: true
            }
        ]

        let frontEndErrors = handleErrors(this.state, errors, fields)
        return frontEndErrors
    }

    handleLogin = async (e) => {
        e.preventDefault()

        const { info, loaders } = this.state
        const { setToken } = this.props

        if (!loaders.login) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, login: true } }))
            const { success, errors } = this.handleErrors()

            if (success) {
                const userInformation = {
                    email: ValidateAndTransformEmail(info.email),
                    password: info.password
                }

                try {
                    let loginResponse = await axios.post(`/api/auth/login`, { ...userInformation }, {})
                    if (loginResponse?.data?.success) {
                        const data = loginResponse.data
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, login: false },
                            errors: initialState.errors, errorMessage: ''
                        }))
                        setToken({
                            accessToken: data.token,
                            refreshToken: data.refreshToken,
                            email: data.email,
                            id: data.id
                        })
                    }
                } catch (error) {
                    // console.log(error?.response?.data?.errors)
                    this.setState(prevState => ({
                        loaders: {
                            ...prevState.loaders,
                            login: false
                        },
                        errorMessage: !!error?.response?.data?.errors?.length ? error?.response?.data?.errors?.[0]?.message : 'Request failed due to connectivity issues. Check your internet connection or contact support if problem persists.'
                    }))
                }
            } else {
                this.setState(prevState => ({
                    errors,
                    loaders: { ...prevState.loaders, login: false }
                }))
            }
        }
    }

    handleForgotPassword = async (e) => {
        e.preventDefault()

        const { info, loaders } = this.state
        const { success, errors } = this.handleForgotPasswordErrors()
        if (success) {
            if (ValidateAndTransformEmail(info.email).split('@')[1] === 'firetext911.net') {
                this.setState({
                    errorMessage: 'To reset the password for this account, please contact FireTEXT support at our email. You can contact us via our website at firetext.net, email us at support@firetext.net, or call us at (870) 483-2535.'
                })
                return
            }

            if (!loaders.forgotPassword) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, forgotPassword: true } }))

                const userInformation = {
                    email: ValidateAndTransformEmail(info.email)
                }
                try {
                    let forgotPasswordResponse = await axios.post(`/api/auth/forgot-password`, { ...userInformation })
                    // console.log({ forgotPasswordResponse })
                    if (forgotPasswordResponse?.data?.success) {
                        this.setState(prevState => ({
                            ...initialState,
                            page: 'forgotPasswordConfirmation'
                        }))
                    }
                } catch (error) {
                    // console.log(error.response)
                    this.setState(prevState => ({
                        loaders: {
                            ...prevState.loaders,
                            forgotPassword: false
                        },
                        errorMessage: !!error?.response?.data?.errors?.length ? error?.response?.data?.errors?.[0]?.message : 'Request failed due to connectivity issues. Check your internet connection or contact support if problem persists.'
                    }))
                }
            }
        }
        else {
            this.setState(prevState => ({
                errors,
                loaders: { ...prevState.loaders, forgotPassword: false }
            }))
        }
    }

    handleRegister = async (e) => {
        e.preventDefault()

        const { registrationInfo, loaders } = this.state
        const { success, errors } = this.handleRegisterErrors()
        if (success) {
            if (!loaders.register) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, register: true } }))

                const userInformation = {
                    userName: registrationInfo.userName,
                    email: ValidateAndTransformEmail(registrationInfo.email),
                    phone: registrationInfo.phone,
                    departmentName: registrationInfo.departmentName
                }

                try {
                    let registerResponse = await axios.post(`/api/auth/registrationRequest`, { ...userInformation })
                    if (registerResponse?.data?.success) {
                        this.setState(prevState => ({
                            ...initialState,
                            page: 'registerConfirmation'
                        }))
                    }
                } catch (error) {
                    // console.log(error.response)
                    this.setState(prevState => ({
                        loaders: {
                            ...prevState.loaders,
                            register: false
                        },
                        errorMessage: !!error?.response?.data?.errors?.length ? error?.response?.data?.errors?.[0]?.message : 'Request failed due to connectivity issues. Check your internet connection or contact support if problem persists.'
                    }))
                }
            }
        }
        else {
            this.setState(prevState => ({
                errors,
                loaders: { ...prevState.loaders, register: false }
            }))
        }
    }

    render() {
        const { page, loaders, info, registrationInfo, errors, errorMessage } = this.state

        return (
            <>
                <Grid
                    container
                    component='main'
                    style={{ height: '100vh' }}
                >
                    <CssBaseline />
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                        component={Paper}
                        container
                        elevation={6}
                        square
                        style={{ backgroundColor: theme.palette.primary.background }}
                        alignItems='center'
                    >
                        <div
                            style={{
                                flex: 1,
                                margin: '64px 32px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Grid
                                item
                                container
                                justifyContent='center'
                                alignItems='center'
                                xs={12}
                                style={{ marginBottom: 32, display: 'flex', flexDirection: 'column' }}
                            >
                                <Grid
                                    item
                                    container
                                    justifyContent='center'
                                    alignItems='center'
                                    xs={12}
                                // style={{ marginBottom: 8 }}
                                >
                                    <img
                                        style={{
                                            height: 80,
                                            marginRight: 8,
                                            backgroundColor: theme.palette.primary.background,
                                            color: theme.palette.primary.dark
                                        }}
                                        src={LogoIcon}
                                        alt="FireTEXT"
                                    />
                                    <h1
                                        style={{ fontSize: 54 }}
                                    >
                                        FireTEXT<sup style={{ fontSize: 26, position: 'relative', top: -16 }}>®</sup>
                                    </h1>
                                </Grid>
                                <Typography
                                    variant="body1"
                                    style={{ color: '#212121' }}
                                >
                                    v{require('../../../package.json').version}
                                </Typography>
                            </Grid>

                            <Typography
                                variant='h4'
                                style={{ marginBottom: 24, textAlign: 'left', width: '100%' }}
                            >
                                {page === 'login' ? 'Sign in' : page === 'forgotPassword' ? 'Forgot password' : 'Registration Request'}
                            </Typography>

                            {errorMessage &&
                                <Alert
                                    severity="error"
                                    onClose={() => this.setState({ errorMessage: '' })}
                                    style={{ marginBottom: 16 }}
                                >
                                    {errorMessage}
                                </Alert>
                            }

                            {page === 'login' &&
                                <form onSubmit={this.handleLogin} >
                                    <Grid
                                        container
                                        item
                                        style={{ marginTop: 16 }}
                                        xs={12}
                                        spacing={2}
                                    >
                                        <GeneralTextField
                                            label='Email Address'
                                            placeholder='Enter email address...'
                                            statePath={`info.email`}
                                            value={info.email}
                                            error={errors.info.email}
                                            width={12}
                                            autoFocus={true}
                                            handleInputChange={this.handleInputChange}
                                        />
                                        <GeneralTextField
                                            width={12}
                                            label='Password'
                                            placeholder='Enter password...'
                                            type='password'
                                            statePath={`info.password`}
                                            value={info.password}
                                            error={errors.info.password}
                                            handleInputChange={this.handleInputChange}
                                        />
                                        <Grid
                                            xs={12}
                                            item
                                            style={{ marginTop: 16 }}
                                        >
                                            <Button
                                                type="submit"
                                                fullWidth
                                                color='secondary'
                                                variant='contained'
                                            >
                                                {loaders.login &&
                                                    <CircularProgress
                                                        size={18}
                                                        style={{ marginRight: 8 }}
                                                    />
                                                }
                                                Sign in
                                            </Button>
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            justifyContent='flex-end'
                                            alignItems='flex-end'
                                            container
                                            item
                                        >
                                            <Button
                                                type="button"
                                                size='small'
                                                color='primary'
                                                variant='outlined'
                                                onClick={() => this.setState({
                                                    ...initialState,
                                                    page: 'register',
                                                })}
                                                style={{ marginRight: 4 }}
                                            >
                                                Register
                                            </Button>
                                            <Button
                                                type="button"
                                                size='small'
                                                color='primary'
                                                variant='outlined'
                                                onClick={() => this.setState({
                                                    ...initialState,
                                                    page: 'forgotPassword',
                                                })}
                                            >
                                                Forgot your password?
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            }
                            {page === 'forgotPassword' &&
                                <form onSubmit={this.handleForgotPassword}>
                                    <Grid
                                        style={{ marginTop: 16 }}
                                        xs={12}
                                        spacing={2}
                                        container
                                        item
                                    >
                                        <GeneralTextField
                                            width={12}
                                            label='Email Address'
                                            placeholder='Enter email address...'
                                            statePath={`info.email`}
                                            value={info.email}
                                            error={errors.info.email}
                                            handleInputChange={this.handleInputChange}
                                        />

                                        <Grid
                                            xs={12}
                                            item
                                        >
                                            <Button
                                                type="submit"
                                                fullWidth
                                                color='secondary'
                                                variant='contained'
                                            >
                                                {loaders.forgotPassword &&
                                                    <CircularProgress
                                                        size={18}
                                                        style={{ marginRight: 8 }}
                                                    />
                                                }
                                                Send reset instructions
                                            </Button>
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            justifyContent='space-between'
                                            alignItems='flex-end'
                                            container
                                            item
                                        >
                                            <Button
                                                type="button"
                                                size='small'
                                                href='https://firetext.net/contact/'
                                                variant='outlined'
                                            >
                                                Forgot your email? &nbsp; <strong>Contact Support.</strong>
                                            </Button>
                                            <Button
                                                type="button"
                                                variant='contained'
                                                size='small'
                                                onClick={() => this.setState({
                                                    ...initialState,
                                                    page: 'login',
                                                })}
                                            >
                                                Back to login
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            }
                            {page === 'register' &&
                                <form onSubmit={this.handleRegister}>
                                    <Grid
                                        style={{ marginTop: 16 }}
                                        xs={12}
                                        spacing={2}
                                        container
                                        item
                                    >
                                        <GeneralTextField
                                            width={12}
                                            label='Full Name'
                                            placeholder='Enter your full name...'
                                            statePath={`registrationInfo.userName`}
                                            value={registrationInfo.userName}
                                            error={errors.registrationInfo.userName}
                                            handleInputChange={this.handleInputChange}
                                        />
                                        <GeneralTextField
                                            width={12}
                                            label='Email Address'
                                            placeholder='Enter email address...'
                                            statePath={`registrationInfo.email`}
                                            value={registrationInfo.email}
                                            error={errors.registrationInfo.email}
                                            handleInputChange={this.handleInputChange}
                                        />
                                        <GeneralTextField
                                            width={12}
                                            label='Phone Number'
                                            format='phoneNumber'
                                            placeholder='Enter phone number...'
                                            statePath={`registrationInfo.phone`}
                                            value={registrationInfo.phone}
                                            error={errors.registrationInfo.phone}
                                            handleInputChange={this.handleInputChange}
                                        />
                                        <GeneralTextField
                                            width={12}
                                            label='Department'
                                            placeholder='Enter department name...'
                                            statePath={`registrationInfo.departmentName`}
                                            value={registrationInfo.departmentName}
                                            error={errors.registrationInfo.departmentName}
                                            handleInputChange={this.handleInputChange}
                                        />

                                        <Grid
                                            xs={12}
                                            item
                                        >
                                            <Button
                                                type="submit"
                                                fullWidth
                                                color='secondary'
                                                variant='contained'
                                            >
                                                {loaders.register &&
                                                    <CircularProgress
                                                        size={18}
                                                        style={{ marginRight: 8 }}
                                                    />
                                                }
                                                Send registration request
                                            </Button>
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            justifyContent='space-between'
                                            alignItems='flex-end'
                                            container
                                            item
                                        >
                                            <Button
                                                type="button"
                                                size='small'
                                                href='https://firetext.net/contact/'
                                                variant='outlined'
                                            >
                                                Forgot your email? &nbsp; <strong>Contact Support.</strong>
                                            </Button>
                                            <Button
                                                type="button"
                                                variant='contained'
                                                size='small'
                                                onClick={() => this.setState({
                                                    ...initialState,
                                                    page: 'login',
                                                })}
                                            >
                                                Back to login
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            }
                            {page === 'forgotPasswordConfirmation' &&
                                <Alert
                                    severity="success"
                                    style={{ marginBottom: 16 }}
                                >
                                    A link with instructions to reset your password has been sent to the email provided.
                                </Alert>
                            }
                            {page === 'registerConfirmation' &&
                                <Alert
                                    severity="success"
                                    style={{ marginBottom: 16 }}
                                >
                                    Processing time can take up to 3 processing days. We will reach back to you. Thank you!
                                </Alert>
                            }
                        </div>
                    </Grid>
                    <Grid
                        container
                        item
                        alignItems='center'
                        justifyContent='center'
                        xs={false}
                        sm={4}
                        md={7}
                        style={{
                            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${HomepagePhoto})`,
                            backgroundRepeat: 'no-repeat', backgroundColor: theme.palette.primary.background, backgroundSize: 'cover',
                            backgroundPosition: 'center', padding: 64, display: 'flex', flex: 1, justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >


                        <Typography
                            style={{
                                color: '#fff', position: 'relative', zIndex: 1000, fontSize: 54, background: '#00000080',
                                textShadow: '0px 0px 1px #000000', textStroke: '1px #000000', fontWeight: 'bold',
                                padding: 10, borderRadius: 4
                            }}
                        >
                            Save lives. Respond faster.
                        </Typography>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default withRouter(withStyles(null, { withTheme: true })(Login))