// React
import React from 'react'

// React Router
import { withRouter } from 'react-router-dom'

// Material UI
import { Grid, CssBaseline, Typography, Paper, Button, CircularProgress, withStyles, Link } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

// Components
import GeneralTextField from '../general/TextField'
import GeneralCheckboxField from '../general/CheckboxField'

// Assets
import HomepagePhoto from '../../assets/homepage.jpg'
import LogoIcon from '../../assets/logo.png'

// Utilities
import axios from 'axios'
import { handleInputChange, handleErrors } from '../../utilities/handleChange'
import { ValidateAndTransformEmail, ValidateAndTransformToBackendPhoneNumber } from '../../utilities/Validate'
import theme from '../../utilities/theme'


const RegistrationKey = {
    email: 'email',
    phone: 'phone',
    both: 'both'
}

const initialState = {
    registeredWith: null,
    info: {
        firstName: '',
        lastName: '',
        phone: '',
        password: '',
        password2: ''
    },
    errorMessage: null,
    loading: false,
    errors: {
        info: {}
    },
    loaders: {
        resendActivationLink: false,
        activateAccount: false
    },
    valid: false,
    resendActivationLink: false,
    activationLinkSent: false,
    success: false,
    userAgreementToTermsAndPrivacy: false
}
class ActivateAccount extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...initialState,
            id: props.match.params.id,
            tempHash: props.match.params.tempHash,
        }
    }

    componentDidMount = async () => {
        localStorage.clear()
        this.validateActivation()
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleErrors = () => {
        const { info } = this.state
        let errors = { ...this.state.errors }

        let fields = [
            {
                path: 'info.firstName',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'info.lastName',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'info.email',
                type: 'string',
                shouldCheck: true,
                validation: !!ValidateAndTransformEmail(info.email),
                validationMessage: "Please provide a valid email address."
            },
            {
                path: 'info.phone',
                type: 'string',
                shouldCheck: true,
                validation: !!ValidateAndTransformToBackendPhoneNumber(info.phone),
                validationMessage: "Please provide a valid phone number."
            },
            {
                path: 'info.password',
                type: 'string',
                shouldCheck: true,
                validation: info.password && info.password.length > 6,
                validationMessage: 'Your password must be more than 6 characters.'
            },
            {
                path: 'info.password2',
                type: 'string',
                shouldCheck: true,
                validation: info.password === info.password2,
                validationMessage: 'Passwords do not match.'
            }
        ]

        let frontEndErrors = handleErrors(this.state, errors, fields)
        return frontEndErrors
    }

    validateActivation = async () => {
        const { id, tempHash } = this.state

        try {
            const validateActivationResponse = await axios.get(`/api/auth/validate-activation/${id}/${tempHash}`)
            //console.log({ validateActivationResponse })
            if (validateActivationResponse?.data?.success) {

                const user = { ...validateActivationResponse.data.user }

                let registeredWith = null
                if (user.email && user.phone) {
                    registeredWith = RegistrationKey.both
                }
                else if (user.email) {
                    registeredWith = RegistrationKey.email
                }
                else if (user.phone) {
                    registeredWith = RegistrationKey.phone
                }

                if (registeredWith) {
                    this.setState(prevState => ({
                        valid: true,
                        loading: false,
                        registeredWith,
                        info: {
                            ...prevState.info,
                            email: user.email,
                            phone: user.phone,
                            firstName: user.firstName,
                            lastName: user.lastName,
                        }
                    }))
                }
                else {
                    this.setState({ valid: false, loading: false, errorMessage: 'User key information (email/phone) is missing.' })
                }
            }
        } catch (error) {
            // console.log(error?.response?.data?.errors)

            this.setState({
                valid: false,
                loading: false,
                resendActivationLink: error?.response?.data?.errors?.[0]?.type === 'TOKEN_EXPIRED',
                errorMessage: !!error?.response?.data?.errors?.length ? error?.response?.data?.errors?.[0]?.message : 'Request failed due to connectivity issues. Check your internet connection or contact support if problem persists.'
            })

        }
    }

    activateAccount = async () => {
        const { id, tempHash, loaders, info, userAgreementToTermsAndPrivacy } = this.state

        if (!loaders.activateAccount) {
            this.setState(prevState => ({
                loaders: {
                    ...prevState.loaders,
                    activateAccount: true
                }
            }))

            const { success, errors } = this.handleErrors()
            if (success) {
                if (!userAgreementToTermsAndPrivacy) {
                    this.setState(prevState => ({
                        loaders: {
                            ...prevState.loaders,
                            activateAccount: false
                        }
                    }))
                    alert('Accept the terms and privacy policy.')
                    return
                }
                try {
                    const config = {
                        params: {}
                    }
                    const activateAccountResponse = await axios.post(`/api/auth/activate-account/${id}/${tempHash}`, { ...info, phone: ValidateAndTransformToBackendPhoneNumber(info.phone) }, config)
                    // console.log({activateAccountResponse})
                    if (activateAccountResponse?.data?.success) {
                        this.setState(prevState => ({
                            success: true,
                            errors: initialState.errors,
                            loaders: {
                                ...prevState.loaders,
                                activateAccount: false
                            }
                        }))
                    }
                } catch (error) {
                    // console.log(error?.response?.data?.errors)

                    this.setState(prevState => ({
                        success: false,
                        errors: initialState.errors,
                        loaders: {
                            ...prevState.loaders,
                            activateAccount: false
                        },
                        errorMessage: !!error?.response?.data?.errors?.length ? error?.response?.data?.errors?.[0]?.message : 'Request failed due to connectivity issues. Check your internet connection or contact support if problem persists.'
                    }))
                }
            } else {
                this.setState(prevState => ({
                    success: false,
                    errors,
                    loaders: {
                        ...prevState.loaders,
                        activateAccount: false
                    }
                }))
            }
        }
    }

    resendActivationLink = async () => {
        const { id, tempHash, loaders } = this.state

        if (!loaders.resendActivationLink) {
            this.setState(prevState => ({
                loaders: {
                    ...prevState.loaders,
                    resendActivationLink: true
                }
            }))
            try {
                let resendActivationResponse = await axios.put(`/api/auth/resend-activation/${id}/${tempHash}`)
                if (resendActivationResponse?.data?.success) {
                    this.setState(prevState => ({
                        loaders: {
                            ...prevState.loaders,
                            resendActivationLink: false
                        },
                        activationLinkSent: true,
                        resendActivationLink: false
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: {
                        ...prevState.loaders,
                        resendActivationLink: false
                    },
                    resendActivationLink: false,
                    errorMessage: !!error?.response?.data?.errors?.length ? error?.response?.data?.errors?.[0]?.message : 'Request failed due to connectivity issues. Check your internet connection or contact support if problem persists.'
                }))
            }
        }
    }

    render() {
        const { loading, loaders, valid, resendActivationLink, activationLinkSent, success, errorMessage, info, errors, registeredWith, userAgreementToTermsAndPrivacy } = this.state

        return (
            <>
                <Grid
                    container
                    component='main'
                    style={{ height: '100vh' }}
                >
                    <CssBaseline />
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                        component={Paper}
                        container
                        elevation={6}
                        square
                        alignItems='center'
                        style={{ backgroundColor: theme.palette.primary.background, color: theme.palette.primary.dark }}
                    >
                        <div
                            style={{
                                flex: 1,
                                margin: '64px 32px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Grid
                                container
                                item
                                justifyContent='center'
                                alignItems='center'
                                xs={12}
                            >
                                <img
                                    src={LogoIcon}
                                    alt="FireTEXT"
                                    style={{
                                        height: 80,
                                        margin: 8,
                                        backgroundColor: theme.palette.primary.background,
                                        color: theme.palette.primary.dark
                                    }}
                                />
                                <h1>
                                    FireTEXT<sup style={{ fontSize: 20, position: 'relative', top: -6 }}>®</sup>
                                </h1>
                            </Grid>
                            {loading ?
                                <div
                                    style={{ marginTop: 32 }}
                                >
                                    <CircularProgress />
                                </div>
                                :
                                <div
                                    style={{ marginTop: 32 }}
                                >
                                    {success ?
                                        <>
                                            <Alert
                                                severity="success"
                                                style={{ marginBottom: 16 }}
                                            >
                                                Your account has been successfully activated!
                                            </Alert>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginTop: 32
                                                }}
                                            >
                                                <Button
                                                    variant='contained'
                                                    href='/login'
                                                >
                                                    SIGN IN
                                                </Button>
                                            </div>
                                        </>
                                        :
                                        <>

                                            {errorMessage &&
                                                <Alert
                                                    severity="error"
                                                    onClose={() => this.setState({ errorMessage: '' })}
                                                    style={{ marginBottom: 16 }}
                                                >
                                                    {errorMessage}
                                                </Alert>
                                            }
                                            {valid &&
                                                <>
                                                    <Typography
                                                        align='center'
                                                        variant='subtitle1'
                                                    >
                                                        Enter basic account information to activate your account
                                                    </Typography>
                                                    <Grid
                                                        xs={12}
                                                        spacing={2}
                                                        container
                                                        item
                                                        style={{ marginTop: 16 }}
                                                    >
                                                        <GeneralTextField
                                                            width={12}
                                                            label='First Name *'
                                                            placeholder='Enter first name...'
                                                            statePath={`info.firstName`}
                                                            value={info.firstName}
                                                            error={errors.info.firstName}
                                                            handleInputChange={this.handleInputChange}
                                                        />
                                                        <GeneralTextField
                                                            width={12}
                                                            label='Last Name *'
                                                            placeholder='Enter last name...'
                                                            statePath={`info.lastName`}
                                                            value={info.lastName}
                                                            error={errors.info.lastName}
                                                            handleInputChange={this.handleInputChange}
                                                        />
                                                        <GeneralTextField
                                                            disabled={registeredWith === RegistrationKey.both || registeredWith === RegistrationKey.email}
                                                            width={12}
                                                            label='Email Address *'
                                                            placeholder='Enter email...'
                                                            statePath={`info.email`}
                                                            value={info.email}
                                                            error={errors.info.email}
                                                            handleInputChange={this.handleInputChange}
                                                        />
                                                        <GeneralTextField
                                                            width={12}
                                                            disabled={registeredWith === RegistrationKey.both || registeredWith === RegistrationKey.phone}
                                                            format='phoneNumber'
                                                            label='Phone Number *'
                                                            placeholder='Enter phone number...'
                                                            statePath={`info.phone`}
                                                            value={info.phone}
                                                            error={errors.info.phone}
                                                            handleInputChange={this.handleInputChange}
                                                        />
                                                        <GeneralTextField
                                                            width={12}
                                                            label='Password *'
                                                            placeholder='Enter password...'
                                                            type='password'
                                                            statePath={`info.password`}
                                                            value={info.password}
                                                            error={errors.info.password}
                                                            handleInputChange={this.handleInputChange}
                                                        />
                                                        <GeneralTextField
                                                            width={12}
                                                            label='Confirm Password *'
                                                            placeholder='Enter password again...'
                                                            type='password'
                                                            statePath={`info.password2`}
                                                            value={info.password2}
                                                            error={errors.info.password2}
                                                            handleInputChange={this.handleInputChange}
                                                        />
                                                        <GeneralCheckboxField
                                                            gridStyle={{ display: 'flex', alignItems: 'center', marginTop: 8 }}
                                                            width={12}
                                                            size='small'
                                                            label={
                                                                <Grid
                                                                    container
                                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                                >
                                                                    <Typography
                                                                        variant='body1'
                                                                    >
                                                                        I agree to the&nbsp;
                                                                    </Typography>
                                                                    <Link
                                                                        underline="always"
                                                                        variant='inherit'
                                                                        target='_blank'
                                                                        href='https://firetext.net/terms-of-service/'
                                                                        style={{ textUnderlineOffset: 2, marginBottom: 4 }}
                                                                    >
                                                                        {"terms"}
                                                                    </Link>
                                                                    <Typography
                                                                        variant='body1'
                                                                    >
                                                                        &nbsp;and&nbsp;
                                                                    </Typography>
                                                                    <Link
                                                                        underline="always"
                                                                        variant='inherit'
                                                                        target='_blank'
                                                                        href='https://firetext.net/privacy-policy/'
                                                                        style={{ textUnderlineOffset: 2, marginBottom: 4 }}
                                                                    >
                                                                        {"privacy policy"}
                                                                    </Link>
                                                                    <Typography
                                                                        variant='body1'
                                                                    >
                                                                        .
                                                                    </Typography>
                                                                </Grid>
                                                            }
                                                            statePath={`userAgreementToTermsAndPrivacy`}
                                                            value={userAgreementToTermsAndPrivacy}
                                                            error={errors.userAgreementToTermsAndPrivacy}
                                                            handleInputChange={this.handleInputChange}
                                                        />
                                                    </Grid>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            marginTop: 32
                                                        }}
                                                    >
                                                        <Button
                                                            onClick={this.activateAccount}
                                                            variant='contained'
                                                            color='secondary'
                                                        >
                                                            {loaders.activateAccount &&
                                                                <CircularProgress
                                                                    size={18}
                                                                    style={{ marginRight: 8 }}
                                                                />
                                                            }
                                                            Activate Account
                                                        </Button>
                                                    </div>
                                                </>
                                            }

                                            {resendActivationLink &&
                                                <>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            marginTop: 32
                                                        }}
                                                    >
                                                        <Button
                                                            variant='contained'
                                                            disabled={activationLinkSent}
                                                            onClick={this.resendActivationLink}
                                                        >
                                                            {loaders.resendActivationLink &&
                                                                <CircularProgress
                                                                    size={18}
                                                                    style={{ marginRight: 8 }}
                                                                />
                                                            }
                                                            Resend Activation Link
                                                        </Button>
                                                    </div>
                                                </>
                                            }

                                            {activationLinkSent &&
                                                <Alert
                                                    severity="success"
                                                    style={{ marginBottom: 16 }}
                                                >
                                                    The activation link has been sent to your email. Please use the new link to activate your account.
                                                </Alert>
                                            }

                                        </>
                                    }
                                </div>
                            }
                        </div>
                    </Grid>
                    <Grid
                        container
                        item
                        alignItems='center'
                        justifyContent='center'
                        xs={false}
                        sm={4}
                        md={7}
                        style={{
                            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${HomepagePhoto})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: theme.palette.primary.background,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            padding: 64
                        }}
                    >

                    </Grid>
                </Grid>
            </>
        )
    }
}

export default withRouter(withStyles(null, { withTheme: true })(ActivateAccount))