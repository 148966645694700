import React from 'react'
import ReactHlsPlayer from 'react-hls-player'
import { RadioUrl } from '../../App'

const RadioPlayer = ({ channelId }) => {
    const streamUrl = `${RadioUrl}/${channelId}/index.m3u8`

    return (
        <ReactHlsPlayer
            src={streamUrl}
            autoPlay={true}
            style={{ display: 'none' }}
            hlsConfig={{
                lowLatencyMode: true,
            }}
        />
    )
}

export default RadioPlayer;