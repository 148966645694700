import React, { useEffect, forwardRef, useImperativeHandle, useState, createRef, useRef, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { Grid } from '@material-ui/core'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
import axios from 'axios'
import MapLayers from './MapLayers'
import {
    addRecenterButton, addLayersToMap, asyncAddRequiredImagesToMap, createMarkerElement, mapError
} from './utils'
import UpgradeToPremium from '../upgradeToPremium'

mapboxgl.accessToken = 'pk.eyJ1IjoiZmlyZXRleHQiLCJhIjoiY2ptejZ3dnY1MHQyMDNwcGNlcTZ0NmNxMiJ9.k2hOGHWI3WvYZuryoOODhg'

const defaultZoom = 14

const DynamicMap = forwardRef(({ center, markers, accessToken, departmentId, containerStyle, mapStyle, zoom = defaultZoom, mapViewHeight = 400, departmentHasWeatherPermissions }, ref) => {
    const [map, setMap] = useState(null)
    const [mapStylesLoaded, setMapStylesLoaded] = useState(false)
    const [renderLayers, setRenderLayers] = useState(false)
    const [selectedLayers, setSelectedLayers] = useState(['station'])
    const [layerMarkers, setLayerMarkers] = useState({
        hydrantMarkers: [],
        hazmatMarkers: [],
        stationMarkers: [],
        aedMarkers: [],
        evacMarkers: [],
        tornadoSirenMarkers: [],
    })
    const [fetchMarkersLoading, setFetchMarkersLoading] = useState(false)
    const [upgradeToPremiumModalVisible, setUpgradeToPremiumModalVisible] = useState(false)
    const [isFullScreenMap, setIsFullScreenMap] = useState(false)

    const openUpgradeToPremiumModal = useCallback(() => {
        if (!isFullScreenMap) {
            setUpgradeToPremiumModalVisible(true)
        }
        else {
            let popup = new mapboxgl.Popup({
                anchor: 'center',
                closeButton: true,
                closeOnClick: true,
            })
            const addPopup = (el, lat, lng) => {
                const container = document.createElement('div')
                container.style.width = '60vw'
                container.style.height = 'auto'

                ReactDOM.render(
                    <UpgradeToPremium
                        isModal={false}
                        upgradeToPremiumText={'Premium departments have access to a range of weather map layers, including wild fires, fire outlooks, storm cells, flood reports, lightning strikes, and observations.'}
                    />
                    , container)
                popup
                    .setDOMContent(container)
                    .setMaxWidth("70vw")
                    .setLngLat(map.getCenter())
                    .addTo(map)
                    .on('close', () => {
                        ReactDOM.unmountComponentAtNode(container)
                        container.remove()
                    })
            }
            addPopup()
        }
    }, [isFullScreenMap, map])

    const closeUpgradeToPremiumModal = () => setUpgradeToPremiumModalVisible(false)

    const mapContainer = createRef(null)
    const markersRef = createRef([])
    const layersButtonsRef = useRef([])

    useImperativeHandle(ref, () => ({
        animateToCoordinates(coords) {
            animateToCoordinates(coords)
        }
    }))

    const animateToCoordinates = (coords) => {
        if (coords?.latitude && map?.flyTo) {
            map.flyTo({ center: [coords.longitude.toFixed(6), coords.latitude.toFixed(6)], defaultZoom })
        }
    }

    const fetchLayerMarkers = useCallback(async () => {
        if (!departmentId) return

        setFetchMarkersLoading(true)
        try {
            const config = {
                headers: { 'Authorization': accessToken }
            }
            const response = await axios.get(`/api/marker?departmentId=${departmentId}&layer=all`, config)

            if (response.data.success) {
                const markersResp = response.data.markers

                setLayerMarkers(prevState => ({
                    ...prevState,
                    hydrantMarkers: markersResp.filter(marker => marker.layer === 'hydrant'),
                    hazmatMarkers: markersResp.filter(marker => marker.layer === 'hazmat'),
                    stationMarkers: markersResp.filter(marker => marker.layer === 'station'),
                    aedMarkers: markersResp.filter(marker => marker.layer === 'aed'),
                    evacMarkers: markersResp.filter(marker => marker.layer === 'evac'),
                    tornadoSirenMarkers: markersResp.filter(marker => marker.layer === 'tornadoSiren'),
                }))
            }
        } catch (error) {
            alert('Marker Layers. Something went wrong.')
        }
        setFetchMarkersLoading(false)
    }, [accessToken, departmentId])

    useEffect(() => {
        const mapObject = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center,
            zoom,
        })
            .addControl(new mapboxgl.FullscreenControl({ container: document.querySelector('root') }))

        addRecenterButton(mapObject, center, defaultZoom)

        fetchLayerMarkers()

        mapObject.on('load', () => setMapStylesLoaded(true))
        mapObject.on('error', mapError)
        const onMapResize = () => {
            if (document.fullscreenElement) {
                setIsFullScreenMap(true)
            }
            else {
                setIsFullScreenMap(false)
            }
        }
        mapObject.on('resize', onMapResize)

        setMap(mapObject)

        const mapResizeListener = () => mapObject.resize()
        window.addEventListener('fullscreenchange', mapResizeListener)

        return () => {
            window.removeEventListener('fullscreenchange', mapResizeListener)
            mapObject.off('error', mapError)
            mapObject?.off('resize', onMapResize)
            mapObject.remove()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        fetchLayerMarkers()
    }, [departmentId, fetchLayerMarkers])

    useEffect(() => {
        if (!map || !mapStylesLoaded) return

        if (!!markersRef.current) return

        markersRef.current = markers.map((marker) => (
            new mapboxgl.Marker({
                element: createMarkerElement(marker),
                // anchor: 'bottom'
            }))
            .setLngLat(new mapboxgl.LngLat(marker.lng, marker.lat))
            .setPopup(new mapboxgl.Popup({ offset: 25 })
                .setHTML(`${marker.tooltipComponent}`)
                .setMaxWidth("400px")
            )
            .addTo(map)
        )

        return () => {
            if (!!markersRef.current?.length) {
                for (const marker of markersRef.current) {
                    marker.remove()
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markers, mapStylesLoaded])

    useEffect(() => {
        if (!map) return

        while (!!layersButtonsRef.current?.length) {
            const control = layersButtonsRef.current.pop()
            map.removeControl(control)
        }

        if (setRenderLayers) addLayersToMap(map, selectedLayers, setSelectedLayers, layersButtonsRef, departmentHasWeatherPermissions, openUpgradeToPremiumModal)
    }, [setRenderLayers, selectedLayers, layersButtonsRef, map, departmentHasWeatherPermissions, openUpgradeToPremiumModal])

    useEffect(() => {
        if (mapStylesLoaded && !renderLayers) {
            const asyncLogic = async () => {
                await asyncAddRequiredImagesToMap(map)
                setRenderLayers(true)
            }
            asyncLogic()
        }
    }, [mapStylesLoaded, map, renderLayers])

    return (
        <Grid
            style={containerStyle ?? { width: '100%', height: mapViewHeight }}
        >
            <UpgradeToPremium
                isModal={true}
                closeModal={closeUpgradeToPremiumModal}
                isOpen={upgradeToPremiumModalVisible}
                upgradeToPremiumText={'Premium departments have access to a range of weather map layers, including wild fires, fire outlooks, storm cells, flood reports, lightning strikes, and observations.'}
            />
            <div
                ref={mapContainer}
                style={mapStyle ?? { width: '100%', height: '100%' }}
            />
            {mapStylesLoaded && renderLayers && !fetchMarkersLoading &&
                <MapLayers
                    map={map}
                    layerMarkers={layerMarkers}
                    selectedLayers={selectedLayers}
                />
            }

        </Grid>
    )
})

export default DynamicMap;