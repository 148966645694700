import React from 'react'
// Router
import { withRouter } from 'react-router-dom'
// Material UI
import { Checkbox, Dialog, withStyles, DialogContent, DialogTitle, FormGroup, FormControlLabel, DialogActions } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

class DepartmetnsFilterModal extends React.Component {

    render() {
        const { open, filterOptions, handleFilterModal, activeFilters, setActiveFilters } = this.props

        return (
            <Dialog
                open={open}
                onClose={() => handleFilterModal(false)}
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        Filter Departments
                        <CloseIcon style={{ cursor: 'pointer' }} onClick={() => handleFilterModal(false)} />
                    </div>

                </DialogTitle>
                <DialogContent>
                    <div
                        style={{ marginBottom: 32 }}
                        key={'Departments filters'}
                    >

                        <FormGroup>
                            {filterOptions.map(option =>
                                <FormControlLabel
                                    key={option?.text}
                                    label={option.text}
                                    control={
                                        <Checkbox
                                            onChange={() => setActiveFilters(option.value)}
                                            checked={activeFilters.includes(option.value)}
                                        />
                                    }
                                />
                            )}
                        </FormGroup>
                    </div>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        )
    }
}

export default withRouter(withStyles(null, { withTheme: true })(DepartmetnsFilterModal))