import React, { useState } from 'react';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(2),
    },
    input: {
        position: 'absolute',
        opacity: 0,
        pointerEvents: 'none',
    },
    block: {
        width: '40px',
        height: '40px',
        margin: '0 5px',
        fontSize: '24px',
        textAlign: 'center',
        lineHeight: '40px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        userSelect: 'none',
        cursor: 'pointer',
    },
    focused: {
        borderColor: theme.palette.primary.main,
    },
}));

const VerificationCodeInput = ({ code, setCode }) => {
    const classes = useStyles();
    const [focused, setFocused] = useState(false);

    const handleChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,6}$/.test(value)) {
            setCode(value);
        }
    };

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    return (
        <Box className={classes.container}>
            <input
                type="text"
                value={code ? code : ''}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                maxLength={6}
                className={classes.input}
                autoFocus
            />
            {[...Array(6)].map((_, index) => (
                <Box
                    key={index}
                    className={`${classes.block} ${focused && classes.focused}`}
                    onClick={() => document.querySelector('input').focus()}
                >
                    {code ? code[index] : ''}
                </Box>
            ))}
        </Box>
    );
};

export default VerificationCodeInput;
