import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
import LayerMarkerPropertiesModal from './LayerMarkerPropertiesModal'
import { addSourceAndLayer, layerIds, removeSourceAndLayer, resetCursorOnLayerHover, setCursorOnLayerHover, sourceIds } from './utils'

const MapLayers = ({ map, layerMarkers, selectedLayers }) => {
    const hydrantLayerMarkers = layerMarkers.hydrantMarkers
    const hazmatLayerMarkers = layerMarkers.hazmatMarkers
    const stationLayerMarkers = layerMarkers.stationMarkers
    const aedLayerMarkers = layerMarkers.aedMarkers
    const evacLayerMarkers = layerMarkers.evacMarkers
    const tornadoSirenLayerMarkers = layerMarkers.tornadoSirenMarkers

    const [marker, setMarker] = useState(null)
    const [markerPropertiesModalVisible, setMarkerPropertiesModalVisible] = useState(false)
    const [isFullScreenMap, setIsFullScreenMap] = useState(false)

    useEffect(() => {
        setCursorOnLayerHover(map)

        const onMapResize = () => {
            if (document.fullscreenElement) {
                setIsFullScreenMap(true)
            }
            else {
                setIsFullScreenMap(false)
            }
        }
        map.on('resize', onMapResize)

        return () => {
            resetCursorOnLayerHover(map)

            map?.off('resize', onMapResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let onLayerMarkerClick
        let hydrantLayerMarkerClick
        let hazmatLayerMarkerClick
        let stationLayerMarkerClick
        let aedLayerMarkerClick
        let evacLayerMarkerClick
        let tornadoSirenLayerMarkerClick
        let popup
        if (isFullScreenMap) {
            onLayerMarkerClick = (markers) => (e) => {
                const selectedMarker = markers.filter(m => m._id === e.features[0].properties._id)?.[0] ?? null
                popup = new mapboxgl.Popup({
                    anchor: 'center',
                    closeButton: true,
                    closeOnClick: true,
                })

                const addPopup = (el, lat, lng) => {
                    const container = document.createElement('div')
                    container.style.width = '60vw'
                    container.style.height = 'auto'

                    ReactDOM.render(
                        <LayerMarkerPropertiesModal
                            visible={true}
                            isFullScreenMap={true}
                            marker={selectedMarker}
                            onClose={() => popup.remove()}
                        />
                        , container)
                    popup
                        .setLngLat(selectedMarker.point.geometry.coordinates)
                        .setDOMContent(container)
                        .setMaxWidth("70vw")
                        .addTo(map)
                        .on('close', () => {
                            ReactDOM.unmountComponentAtNode(container)
                            container.remove()
                        })
                }
                addPopup()
            }
            hydrantLayerMarkerClick = onLayerMarkerClick(hydrantLayerMarkers)
            hazmatLayerMarkerClick = onLayerMarkerClick(hazmatLayerMarkers)
            stationLayerMarkerClick = onLayerMarkerClick(stationLayerMarkers)
            aedLayerMarkerClick = onLayerMarkerClick(aedLayerMarkers)
            evacLayerMarkerClick = onLayerMarkerClick(evacLayerMarkers)
            tornadoSirenLayerMarkerClick = onLayerMarkerClick(tornadoSirenLayerMarkers)

            map.on('click', layerIds.redHydrant, hydrantLayerMarkerClick)
            map.on('click', layerIds.yellowHydrant, hydrantLayerMarkerClick)
            map.on('click', layerIds.greenHydrant, hydrantLayerMarkerClick)
            map.on('click', layerIds.hazmat, hazmatLayerMarkerClick)
            map.on('click', layerIds.station, stationLayerMarkerClick)
            map.on('click', layerIds.aed, aedLayerMarkerClick)
            map.on('click', layerIds.evac, evacLayerMarkerClick)
            map.on('click', layerIds.tornadoSiren, tornadoSirenLayerMarkerClick)
        }
        else {
            onLayerMarkerClick = (markers) => (e) => {
                const selectedMarker = markers.filter(m => m._id === e.features[0].properties._id)?.[0] ?? null
                if (selectedMarker) setMarker(selectedMarker)
                setMarkerPropertiesModalVisible(true)
            }

            hydrantLayerMarkerClick = onLayerMarkerClick(hydrantLayerMarkers)
            hazmatLayerMarkerClick = onLayerMarkerClick(hazmatLayerMarkers)
            stationLayerMarkerClick = onLayerMarkerClick(stationLayerMarkers)
            aedLayerMarkerClick = onLayerMarkerClick(aedLayerMarkers)
            evacLayerMarkerClick = onLayerMarkerClick(evacLayerMarkers)
            tornadoSirenLayerMarkerClick = onLayerMarkerClick(tornadoSirenLayerMarkers)

            map.on('click', layerIds.redHydrant, hydrantLayerMarkerClick)
            map.on('click', layerIds.yellowHydrant, hydrantLayerMarkerClick)
            map.on('click', layerIds.greenHydrant, hydrantLayerMarkerClick)
            map.on('click', layerIds.hazmat, hazmatLayerMarkerClick)
            map.on('click', layerIds.station, stationLayerMarkerClick)
            map.on('click', layerIds.aed, aedLayerMarkerClick)
            map.on('click', layerIds.evac, evacLayerMarkerClick)
            map.on('click', layerIds.tornadoSiren, tornadoSirenLayerMarkerClick)
        }

        return () => {
            map?.off?.('click', layerIds.redHydrant, hydrantLayerMarkerClick)
            map?.off?.('click', layerIds.yellowHydrant, hydrantLayerMarkerClick)
            map?.off?.('click', layerIds.greenHydrant, hydrantLayerMarkerClick)
            map?.off?.('click', layerIds.hazmat, hazmatLayerMarkerClick)
            map?.off?.('click', layerIds.station, stationLayerMarkerClick)
            map?.off?.('click', layerIds.aed, aedLayerMarkerClick)
            map?.off?.('click', layerIds.evac, evacLayerMarkerClick)
            map?.off?.('click', layerIds.tornadoSiren, tornadoSirenLayerMarkerClick)

            popup?.remove?.()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFullScreenMap])

    return (
        <>
            {marker !== null &&
                <LayerMarkerPropertiesModal
                    visible={markerPropertiesModalVisible}
                    marker={marker}
                    onClose={() => setMarkerPropertiesModalVisible(false)}
                />
            }

            {selectedLayers.includes('hydrant') &&
                <HydrantsLayer
                    map={map}
                    hydrantLayerMarkers={hydrantLayerMarkers}
                />
            }
            {selectedLayers.includes('hazmat') &&
                <HazmatsLayer
                    map={map}
                    hazmatLayerMarkers={hazmatLayerMarkers}
                />
            }
            {selectedLayers.includes('station') &&
                <StationsLayer
                    map={map}
                    stationLayerMarkers={stationLayerMarkers}
                />
            }
            {selectedLayers.includes('aed') &&
                <AedLayer
                    map={map}
                    aedLayerMarkers={aedLayerMarkers}
                />
            }
            {selectedLayers.includes('evac') &&
                <EvacLayer
                    map={map}
                    evacLayerMarkers={evacLayerMarkers}
                />
            }
            {selectedLayers.includes('tornadoSiren') &&
                <TornadoSirenLayer
                    map={map}
                    tornadoSirenLayerMarkers={tornadoSirenLayerMarkers}
                />
            }
            {selectedLayers.includes('weather') &&
                <WeatherLayer
                    map={map}
                />
            }
        </>
    )
}

const HydrantsLayer = ({ map, hydrantLayerMarkers }) => {
    useEffect(() => {
        const redHydrants = hydrantLayerMarkers.filter(m => m.point.properties.color === 'red')
        const yellowHydrants = hydrantLayerMarkers.filter(m => m.point.properties.color === 'yellow')
        const greenHydrants = hydrantLayerMarkers.filter(m => m.point.properties.color === 'green')

        const redHydrantsShape = {
            type: 'FeatureCollection',
            features: redHydrants.map(m => ({
                id: m._id,
                pid: m._id,
                type: 'Feature',
                properties: { ...m.point.properties, _id: m._id },
                geometry: m.point.geometry,
            }))
        }

        const yellowHydrantsShape = {
            type: 'FeatureCollection',
            features: yellowHydrants.map(m => ({
                id: m._id,
                type: 'Feature',
                properties: { ...m.point.properties, _id: m._id },
                geometry: m.point.geometry,
            }))
        }
        const greenHydrantsShape = {
            type: 'FeatureCollection',
            features: greenHydrants.map(m => ({
                id: m._id,
                type: 'Feature',
                properties: { ...m.point.properties, _id: m._id },
                geometry: m.point.geometry,
            }))
        }
        addSourceAndLayer(map, sourceIds.redHydrant, redHydrantsShape, layerIds.redHydrant, 'RedHydrantImage', 'road-label')
        addSourceAndLayer(map, sourceIds.yellowHydrant, yellowHydrantsShape, layerIds.yellowHydrant, 'YellowHydrantImage', 'road-label')
        addSourceAndLayer(map, sourceIds.greenHydrant, greenHydrantsShape, layerIds.greenHydrant, 'GreenHydrantImage', 'road-label')

        return () => {
            removeSourceAndLayer(map, sourceIds.redHydrant, layerIds.redHydrant)
            removeSourceAndLayer(map, sourceIds.yellowHydrant, layerIds.yellowHydrant)
            removeSourceAndLayer(map, sourceIds.greenHydrant, layerIds.greenHydrant)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}

const HazmatsLayer = ({ map, hazmatLayerMarkers }) => {
    useEffect(() => {
        const hazmatsShape = {
            type: 'FeatureCollection',
            features: hazmatLayerMarkers.map(m => ({
                id: m._id,
                type: 'Feature',
                properties: { ...m.point.properties, _id: m._id },
                geometry: m.point.geometry,
            }))
        }
        addSourceAndLayer(map, sourceIds.hazmat, hazmatsShape, layerIds.hazmat, 'HazmatImage', 'road-label')

        return () => {
            removeSourceAndLayer(map, sourceIds.hazmat, layerIds.hazmat)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}

const StationsLayer = ({ map, stationLayerMarkers }) => {
    useEffect(() => {
        const stationShape = {
            type: 'FeatureCollection',
            features: stationLayerMarkers.map(m => ({
                id: m._id,
                type: 'Feature',
                properties: { ...m.point.properties, _id: m._id },
                geometry: m.point.geometry,
            }))
        }
        addSourceAndLayer(map, sourceIds.station, stationShape, layerIds.station, 'StationImage', 'road-label')
        return () => {
            removeSourceAndLayer(map, sourceIds.station, layerIds.station)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}

const AedLayer = ({ map, aedLayerMarkers }) => {
    useEffect(() => {
        const aedShape = {
            type: 'FeatureCollection',
            features: aedLayerMarkers.map(m => ({
                id: m._id,
                type: 'Feature',
                properties: { ...m.point.properties, _id: m._id },
                geometry: m.point.geometry,
            }))
        }
        addSourceAndLayer(map, sourceIds.aed, aedShape, layerIds.aed, 'AedImage', 'road-label')
        return () => {
            removeSourceAndLayer(map, sourceIds.aed, layerIds.aed)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}

const EvacLayer = ({ map, evacLayerMarkers }) => {
    useEffect(() => {
        const evacShape = {
            type: 'FeatureCollection',
            features: evacLayerMarkers.map(m => ({
                id: m._id,
                type: 'Feature',
                properties: { ...m.point.properties, _id: m._id },
                geometry: m.point.geometry,
            }))
        }
        addSourceAndLayer(map, sourceIds.evac, evacShape, layerIds.evac, 'EvacImage', 'road-label')
        return () => {
            removeSourceAndLayer(map, sourceIds.evac, layerIds.evac)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}

const TornadoSirenLayer = ({ map, tornadoSirenLayerMarkers }) => {
    useEffect(() => {
        const tornadoSirenShape = {
            type: 'FeatureCollection',
            features: tornadoSirenLayerMarkers.map(m => ({
                id: m._id,
                type: 'Feature',
                properties: { ...m.point.properties, _id: m._id },
                geometry: m.point.geometry,
            }))
        }
        addSourceAndLayer(map, sourceIds.tornadoSiren, tornadoSirenShape, layerIds.tornadoSiren, 'TornadoSirenImage', 'road-label')
        return () => {
            removeSourceAndLayer(map, sourceIds.tornadoSiren, layerIds.tornadoSiren)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}

const WeatherLayer = ({ map }) => {
    useEffect(() => {
        // let source = MGLRasterTileSource(identifier: "stamen-watercolor", tileURLTemplates: ["https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg"], options: [ .tileSize: 256 ])
        // let rasterLayer = MGLRasterStyleLayer(identifier: "stamen-watercolor", source: source)
        map.addSource(sourceIds.weather, {
            'type': 'raster',
            'tiles': [
                "https://maps.aerisapi.com/lQ049QfdCsD0pwQM4PSzd_Eotm2Ljb7YNJ6AvQ3J4ehpFiaLd2WjS4eoPgD0ld/radar/{z}/{x}/{y}/current.png"
            ],
            'tileSize': 256
        })

        map.addLayer({
            id: layerIds.weather,
            type: 'raster',
            source: sourceIds.weather,

        }, 'landcover')

        return () => {
            removeSourceAndLayer(map, sourceIds.weather, layerIds.weather)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}

export default MapLayers;