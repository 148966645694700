// React
import React from 'react'
// Material UI
import { Grid, Typography, Button } from '@material-ui/core'

const RadioTab = ({
    department, playRadio
}) => {
    return (
        <Grid
            xs={12}
            spacing={2}
            container
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 32, marginTop: '20%', marginBottom: 12 }}
        >
            {!department.radioChannelObject.live ?
                <Typography>
                    {`Currently, there is no active radio stream on ${department.radioChannelObject?.description ? department.radioChannelObject.description : 'this channel'}.`}
                </Typography>
                :
                <React.Fragment>
                    <Typography>
                        {`Click below button to play ${department.radioChannelObject?.description ? department.radioChannelObject.description : 'this channel'}. You can pause/stop the radio from the left navigation menu.`}
                    </Typography>
                    <Button
                        onClick={() => playRadio(department.radioChannelObject)}
                        color='secondary'
                        variant='contained'
                        style={{ margin: 'auto', marginTop: 32 }}
                    >
                        Play Radio
                    </Button>
                </React.Fragment >
            }
        </Grid>
    )
}

export default RadioTab;
