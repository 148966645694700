// React
import React from 'react'

// Material UI
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DynamicMap from '../general/maps/DynamicMap'

class QuickAccessMapsModal extends React.Component {
    render() {
        const { departmentId, titleText, accessToken, departmentHasWeatherPermissions, open, handleClose } = this.props
        return (
            <Dialog
                onClose={handleClose}
                open={open}
                aria-labelledby='quick-access-maps-modal'
                maxWidth='md'
                fullWidth
            >
                <div
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <DialogTitle
                        id='create-dispatch-title'
                        onClose={handleClose}
                    >
                        {titleText}
                    </DialogTitle>
                    <IconButton
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogContent
                    style={{ overflowY: 'auto' }}
                    dividers
                >
                    <DynamicMap
                        departmentId={departmentId}
                        accessToken={accessToken}
                        center={[-103.485771, 40.954580]}
                        departmentHasWeatherPermissions={departmentHasWeatherPermissions}
                        markers={[]}
                        zoom={4}
                        mapViewHeight={'82vh'}
                    />

                </DialogContent>
            </Dialog>
        )
    }
}

export default QuickAccessMapsModal
