import React, { Component } from 'react'
// Material UI
import { withStyles, FormControl, Typography, TextField, Grid } from '@material-ui/core'
import MaterialIcon from 'material-icons-react'
import Autocomplete from '@material-ui/lab/Autocomplete'
// Utilities
import { throttle } from 'underscore'
import Fuse from 'fuse.js'
import googleMaterialIconsList from '../../../assets/google-material-icons'
import theme from '../../../utilities/theme'


const styles = theme => ({
    formControl: {
        margin: 8,
        minWidth: 120
    },
    root: {
        width: '50vW',
    },
    icon: {
        color: '#0095e2'
    },
    popper: {
        width: '100%',
        zIndex: theme.zIndex.modal,
    },
    /* Styles applied to the `Paper` component. */
    paper: {
        width: '100%',
        ...theme.typography.body1,
        overflow: 'hidden',
        margin: '4px 0',
    },
    /* Styles applied to the `listbox` component. */
    listbox: {
        width: '100%',
        listStyle: 'none',
        margin: 0,
        padding: '8px 0',
        maxHeight: '40vh',
        minHeight: '10vh',
        overflow: 'auto',
        display: 'flex',
        flexWrap: 'wrap'
    },
})

const formattedGoogleMaterialIconsList = googleMaterialIconsList.icons.map(icon => ({ ...icon, text: icon.name, value: icon.name }))
const iconsNamesList = googleMaterialIconsList.iconsNames

const getFilteredIconsList = (searchString) => {
    if (!searchString || !searchString.length) {
        return formattedGoogleMaterialIconsList.slice(0, 100).map(icon => ({ text: icon.name }))
    }

    const filter = {
        keys: [
            { name: 'name', weight: 0.8 },
            { name: 'tags', weight: 0.3 }
        ],
        useExtendedSearch: true,
    }
    const fuse = new Fuse(formattedGoogleMaterialIconsList, filter)
    const filteredList = fuse.search(`^${searchString}`)
    const formattedFilteredList = filteredList.slice(0, 100).map(fusedIcon => ({ text: fusedIcon.item.name }))

    return formattedFilteredList
}

export class IconsSelector extends Component {

    constructor(props) {
        super(props)
        this.state = {
            inputValue: ''
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let value = this.props.value
        if (value && prevProps.value !== value) {
            this.handleInputChange(value)
        }
    }

    handleInputChange = (inputValue) => {
        this.setState({ inputValue })
    }
    handleChange = (value) => {
        const data = { value: value?.text || '', statePath: this.props.statePath }

        this.props.handleInputChange && this.props.handleInputChange(null, data)
    }

    render() {
        const { inputValue } = this.state
        const filteredIconsList = getFilteredIconsList(inputValue)

        const { classes, label, value, error, size } = this.props
        // const formattedGoogleMaterialIconsList = googleMaterialIconsList.icons.slice(0, 100).map(icon => ({...icon, text: icon.name, value: icon.name}))

        return (
            <form className={classes.root} autoComplete="off">
                <FormControl className={classes.formControl}>
                    {label &&
                        <Typography
                            variant='subtitle2'
                        >
                            {label}
                        </Typography>
                    }
                    <Autocomplete
                        id={label + "-icon1Select"}
                        size={size || 'small'}
                        classes={{
                            root: classes.root,
                            popper: classes.popper,
                            paper: classes.paper,
                            listbox: classes.listbox
                        }}
                        filterOptions={(x) => x}
                        options={filteredIconsList}
                        onChange={(e, v) => this.handleChange(v)}
                        getOptionLabel={option => option.text || ''}
                        renderOption={
                            (option, state) => {
                                return <MaterialIcon key={option.text} size='tiny' icon={option.text || ''} color={theme.palette.primary.main} />
                            }
                        }
                        onInputChange={throttle((event, newInputValue) => {
                            this.handleInputChange(newInputValue)
                        }, 200)}
                        inputValue={inputValue}
                        renderInput={params => {
                            return (
                                <Grid
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <TextField
                                        {...params}
                                        placeholder={value || 'Search Icon'}
                                        size='small'
                                        margin="normal"
                                        style={{ width: '80%', marginTop: 8 }}
                                        variant="outlined"
                                    />
                                    {iconsNamesList.includes(value, 0) &&
                                        <MaterialIcon
                                            key={label + value}
                                            size={40}
                                            icon={value}
                                            color={theme.palette.primary.main}
                                        />
                                    }
                                </Grid>
                            )

                        }}
                    />
                    {error &&
                        <p
                            style={{ color: '#ff0000', margin: 0, marginTop: 1 }}
                        >
                            {error}
                        </p>
                    }
                </FormControl>
            </form>

        )
    }
}

export default withStyles(styles)(IconsSelector)
