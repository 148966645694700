import React, { useState } from 'react'
//MUI
import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core'
// DnD
import { useDrop } from 'react-dnd'
//utils
import theme from '../../../utilities/theme'
import DepartmentCard from './DepartmentCard'
//icons
import InfoIcon from '@material-ui/icons/Info'
//custom
import DeleteFavouriteDepartmentCard from './DeleteFromFavouriteCard'
import { DepartmentTypes } from '../../../utilities/Options'
import ActionConfirmation from '../../general/ActionConfirmation'

const allDepartmentTypes = [...DepartmentTypes.map(type => type.value), 'All Call']

const FavouriteDepartmentsBox = (props) => {
    const { favouriteDepartments, showResetBtn, moveFavouriteDepartment, moveDepartmentToFavourites, deleteDepartmentFromFavourite, openDispatchModal, openQuickAccessMapsModal, openCreateFiretextAnnouncementModal, isSuperAdmin, resetDragAndDropPreferences } = props

    const [confirmDeletion, setConfirmDeletionVisible] = useState(false)

    const [{ isOver }, drop] = useDrop({
        accept: allDepartmentTypes,
        collect: monitor => ({
            isOver: !!monitor.isOver()
        }),
        drop: (item, monitor) => {
            return isOver ? moveDepartmentToFavourites(item.deptTypeIdx, item._id) : null
        }
    })

    const renderableDepartments = favouriteDepartments.filter(dept => typeof dept?.name === 'string')

    return (
        <Grid
            xs={12}
            container
            item
            ref={drop}
        >
            <ActionConfirmation
                open={confirmDeletion}
                onClose={() => setConfirmDeletionVisible(false)}
                title='Reset favorites and departments order to default?'
                onConfirmAction={resetDragAndDropPreferences}
            />

            <Grid
                xs={12}
                item
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 16
                }}
            >
                <Typography
                    variant='h6'
                    style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                >
                    Favorites ({favouriteDepartments.length})
                </Typography>
                <Tooltip
                    style={{ marginLeft: 8 }}
                    title='Try drag and drop to add departments to favorites, to rearrange departments with in their categories and to rearrange the whole categories.'
                >
                    <InfoIcon />
                </Tooltip>

                {showResetBtn === true &&
                    <Tooltip
                        style={{ marginLeft: 8 }}
                        title='Reset favorites and departments order to default.'
                    >
                        <Button
                            style={{ background: theme.palette.primary.error, fontSize: 10, marginLeft: 8, padding: '2px 4px' }}
                            variant='contained'
                            size="small"
                            onClick={() => setConfirmDeletionVisible(true)}
                        >
                            Reset
                        </Button>
                    </Tooltip>
                }
            </Grid>

            <Grid
                container
                spacing={2}
            >
                {renderableDepartments.map((department, index) => (
                    <DepartmentCard
                        key={department.name}
                        department={department}
                        departmentType={'favourite'}
                        // deptTypeIdx={deptTypeIdx}
                        departmentIndex={index}
                        openDispatchModal={openDispatchModal}
                        openQuickAccessMapsModal={openQuickAccessMapsModal}
                        openCreateFiretextAnnouncementModal={openCreateFiretextAnnouncementModal}
                        isSuperAdmin={isSuperAdmin}
                        moveDepartment={moveFavouriteDepartment}
                        moveDepartmentToFavourites={moveDepartmentToFavourites}
                        deleteDepartmentFromFavourite={deleteDepartmentFromFavourite}
                    />
                ))}
                {renderableDepartments.length > 0 &&
                    <DeleteFavouriteDepartmentCard
                        deleteDepartmentFromFavourite={deleteDepartmentFromFavourite}
                    />
                }
            </Grid>
        </Grid>
    )
}

export default withStyles(null, { withTheme: true })(FavouriteDepartmentsBox);
