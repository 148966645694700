import { useState } from 'react'
// Material UI
import { Grid, Typography, CircularProgress, IconButton, Button, Link } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
// Components
import TypographyWithCopyText from '../../../general/TypographyWithCopyText'
import ActionConfirmation from '../../../general/ActionConfirmation'

const ApiComponent = ({
    department, update, authenticatedUser, socket
}) => {
    const [departmentApiKey, setDepartmentApiKey] = useState()
    const [renewDepartmentApiKeyLoading, setRenewDepartmentApiKeyLoading] = useState()
    const [generateDepartmentApiKeyLoading, setGenerateDepartmentApiKeyLoading] = useState()
    const [confirmRenewDepartmentApiKey, setConfirmRenewDepartmentApiKey] = useState()

    const renewDepartmentApiKey = async () => {
        if (renewDepartmentApiKeyLoading) return

        if (!socket) return
        if (authenticatedUser.type !== 'superAdmin') return

        setRenewDepartmentApiKeyLoading(true)

        socket.emit('renewDepartmentApiKey', department._id,
            (response) => {
                if (response.success) {
                    setDepartmentApiKey(response.apiKey)
                    setRenewDepartmentApiKeyLoading(false)
                }
                else setRenewDepartmentApiKeyLoading(false)
            }
        )
    }

    const generateDepartmentApiKey = async () => {
        if (generateDepartmentApiKeyLoading) return
        if (authenticatedUser.type !== 'superAdmin') return
        if (!socket) return

        setGenerateDepartmentApiKeyLoading(true)
        socket.emit('generateDepartmentApiKey', department._id,
            (response) => {
                if (response.success) {
                    setDepartmentApiKey(response.apiKey)
                    setGenerateDepartmentApiKeyLoading(false)
                }
                else setGenerateDepartmentApiKeyLoading(false)
            }
        )
    }

    const openConfirmRenewDepartmentApiKey = () => {
        setConfirmRenewDepartmentApiKey(true)
    }

    const closeConfirmRenewDepartmentApiKey = () => {
        setConfirmRenewDepartmentApiKey(false)
    }

    return (
        <>
            {department.featuresPermission.audioDispatches ?
                <Grid
                    container
                    xs={12}
                >
                    <Typography
                        variant='h6'
                        style={{ width: '100%', marginTop: 12, marginBottom: 12 }}
                        gutterBottom
                    >
                        Department API Key
                    </Typography>
                    {authenticatedUser.type === 'superAdmin' && update &&
                        <>
                            {!departmentApiKey ?
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 24, marginBottom: 24 }}
                                >
                                    <Typography
                                        variant='subtitle'
                                    >
                                        ****************
                                    </Typography>
                                    <IconButton
                                        onClick={generateDepartmentApiKey}
                                        size='small'
                                        style={{ marginLeft: 16 }}
                                    >
                                        {generateDepartmentApiKeyLoading ?
                                            <CircularProgress
                                                size={18}
                                                style={{ marginRight: 8 }}
                                            />
                                            :
                                            <VisibilityIcon />
                                        }
                                    </IconButton>

                                </div>
                                :
                                <div
                                    style={{ display: 'flex', flexDirection: 'column', marginTop: 24, marginBottom: 24 }}
                                >
                                    <TypographyWithCopyText
                                        text={departmentApiKey}
                                        variant='subtitle'
                                        gutterBottom
                                    />
                                    {/* <Button
                                        onClick={() => null}
                                        // onClick={openConfirmRenewDepartmentApiKey} // commented this for now, could be uncommented after fir-410
                                        color='secondary'
                                        variant='contained'
                                        style={{ marginTop: 16 }}
                                    >
                                        {renewDepartmentApiKeyLoading &&
                                            <CircularProgress
                                                size={18}
                                                style={{ marginTop: 16, marginRight: 8 }}
                                            />
                                        //when renew, handle sending alert to support that change it on FirePITCH also 
                                        }
                                        Renew API Key
                                    </Button> */}
                                </div>
                            }
                        </>
                    }
                </Grid>
                :
                <Grid
                    xs={12}
                    spacing={2}
                    container
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 32, marginTop: 12, marginBottom: 12 }}
                >
                    <Typography>
                        {"API Keys can only be generated for “Premier” departments. You can upgrade your account by contacting us from our website at "}
                        <Link
                            target='_blank'
                            href='https://firetext.net'
                            style={{ color: '#FFFFFF' }}
                            underline="always"
                        >
                            firetext.net
                        </Link>
                    </Typography>
                </Grid>
            }
            <ActionConfirmation
                open={confirmRenewDepartmentApiKey}
                onClose={closeConfirmRenewDepartmentApiKey}
                title="Are you sure you want to renew the api key?"
                description='Caution! Only renew the key if you think it is compromised. You will have to update the key on FirePITCH also.'
                onConfirmAction={renewDepartmentApiKey}
            />
        </>
    )
}

export default ApiComponent;
