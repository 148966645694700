// React
import React, { useEffect, useState, useRef, useLayoutEffect, useCallback } from 'react'
// Material UI
import { CircularProgress, Grid, Typography } from '@material-ui/core'
// Utilities
import moment from 'moment'
import axios from 'axios'
import DispatchHlsPlayer from './DispatchHlsPlayer'
import { RadioUrl } from '../../../App'

const mimeCodec = 'audio/webm; codecs="opus";'
const dynamoStreamSupported = 'MediaSource' in window && MediaSource.isTypeSupported(mimeCodec)

const NO_SOURCE_ERROR = 'noSource'

const PlayerType = {
    srcUrl: 'srcUrl',
    dynamoStream: 'dynamoStream',
    hlsStream: 'hlsStream'
}

const DispatchAudioPlayer = ({ dispatch: call }) => {
    const [dispatch, setDispatch] = useState(call)
    const [error, setError] = useState(null)
    const [playerType, setPlayerType] = useState(null)
    const audioRef = useRef(null)
    const callbackGetIsPlayerPaused = useRef()

    const hlsStreamUrl = `${RadioUrl}/dispatch/${dispatch?._id}.m3u8`

    useLayoutEffect(() => {
        const asyncLogic = async (dispatch) => {
            if (dispatch?._id) {
                if (dispatch.voiceUrl) {
                    setPlayerType(PlayerType.srcUrl)
                    return
                }
                else if (!isOlderThanNMinutes(2, dispatch.createdAt)) {
                    if (dynamoStreamSupported) {
                        setPlayerType(PlayerType.dynamoStream)
                        return
                    }
                    else if (hlsStreamUrl) {
                        const isHlsStreamAvailable = await checkHLSActive(hlsStreamUrl)
                        if (isHlsStreamAvailable) {
                            setPlayerType(PlayerType.hlsStream)
                            return
                        }
                    }
                }
            }
            if (!isOlderThanNMinutes(2, dispatch.createdAt)) setError(NO_SOURCE_ERROR)
        }
        asyncLogic(dispatch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setDispatch(call)
    }, [call])

    const sourceOpen = useCallback(async (mediaSource, dispatchId) => {
        let sourceBuffer = mediaSource.addSourceBuffer(mimeCodec)
        sourceBuffer.mode = 'sequence'
        let audioChunks = []
        let retries = 0
        let waitTime = 1.5 // this is the time after which we request new data, initially at 1,5 seconds, after first chunks loaded, we set it to every 5 seconds
        for (let nextChunk = 0; ;) {
            if (dispatch?.voiceUrl) return

            try {
                let data = await getChunksFromDynamoDB(dispatchId, nextChunk)
                if (data.Count && data.Count > 0) {
                    nextChunk += data.Count
                    for (let item of data.Items) {
                        let stringifiedChunks = item.chunks
                        for (let chunk of stringifiedChunks) {
                            let blob = await base64ToBlob(chunk)
                            audioChunks.push(blob)
                            await new Promise(async (resolve) => {
                                let arrayBuffer = await blob.arrayBuffer()
                                sourceBuffer.appendBuffer(arrayBuffer)
                                sourceBuffer.onupdateend = e => {
                                    sourceBuffer.onupdateend = null
                                    resolve()
                                }
                            })
                        }
                        if (item.isLastChunk) {
                            mediaSource.endOfStream()
                            return
                        }
                    }
                    waitTime = 5
                    await waitForNSeconds(5)
                }
                else if (retries > 12) {
                    mediaSource.endOfStream()
                    return
                }
                else {
                    await waitForNSeconds(waitTime)
                    retries++
                }
            }
            catch (e) {
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useLayoutEffect(() => {
        if (playerType === PlayerType.dynamoStream && audioRef.current) {
            let audio = audioRef.current
            let mediaSource = new MediaSource()
            audio.src = URL.createObjectURL(mediaSource)
            mediaSource.addEventListener('sourceopen', (_) => sourceOpen(mediaSource, dispatch._id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerType])

    useLayoutEffect(() => {
        if (error === NO_SOURCE_ERROR || isOlderThanNMinutes(5, dispatch.createdAt)) {
            if (dispatch.voiceUrl) {
                setPlayerType(PlayerType.srcUrl)
                setError(null)
            }
        }
        else if (dispatch.voiceUrl && playerType === PlayerType.dynamoStream && (audioRef.current.paused || audioRef.current.ended)) {
            setPlayerType(PlayerType.srcUrl)
        }
        else if (dispatch.voiceUrl && playerType === PlayerType.hlsStream && (callbackGetIsPlayerPaused.current())) {
            setPlayerType(PlayerType.srcUrl)
        }
    }, [dispatch, error, playerType])

    if (playerType === PlayerType.srcUrl) return (
        <audio
            src={dispatch.voiceUrl}
            controls
            preload="auto"
            style={{ padding: 8 }}
        />
    )
    else if (playerType === PlayerType.dynamoStream) return (
        <audio
            controls
            style={{ padding: 8 }}
            preload="auto"
            ref={audioRef}
        />
    )
    else if (playerType === PlayerType.hlsStream) return (
        <DispatchHlsPlayer
            hlsStreamUrl={hlsStreamUrl}
            callbackGetIsPlayerPaused={callbackGetIsPlayerPaused}
        />
    )
    else if (error) return (
        <Typography
            variant='body1'
            style={{ padding: 16 }}
        >
            Play back will be available after 45 seconds.
        </Typography>
    )
    else if (isOlderThanNMinutes(3, dispatch.createdAt)) return (
        <Typography
            variant='body1'
            style={{ padding: 16 }}
        >
            An error has occured while loading audio.
        </Typography>
    )

    return (
        <Grid
            item
            xs={12}
            style={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: 4 }}
        >
            <CircularProgress
                size={18}
                style={{ marginLeft: 16, marginBottom: 16 }}
            />
        </Grid>
    )
}

export default DispatchAudioPlayer;


async function getChunksFromDynamoDB(dispatchId, nextChunk) {
    return new Promise((resolve, reject) => {
        let url = `https://seebv1ux07.execute-api.us-east-1.amazonaws.com/dev?dispatchId=${dispatchId}&nextChunk=${nextChunk}`

        let xhr = new XMLHttpRequest()
        xhr.open("GET", url)

        xhr.setRequestHeader("Accept", "application/json")

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) resolve(JSON.parse(xhr.responseText))
                else reject("Request failed.")
            }
        }

        xhr.send()
    })

}

const base64ToBlob = async (base64) => {
    return new Promise((resolve, reject) => {
        fetch(base64).then(res => res.blob()).then(blob => resolve(blob))
    })
}

const waitForNSeconds = (n) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve()
        }, n * 1000);
    })
}

function isOlderThanNMinutes(n, timestamp) {
    const start = moment(timestamp)
    let end = moment() //now
    let duration = moment.duration(end.diff(start))
    let minutes = duration.asMinutes()
    return minutes > n
}

const checkHLSActive = async (url) => {
    await new Promise((res, rej) => {
        setTimeout(() => res(true), 6000) // wait for m3u8 file to be generated
    })

    let isStreamAvailable = false

    try {
        let res = await axios.get(url)
        isStreamAvailable = /2\d\d/.test('' + res.status)
    } catch (err) {
    }

    return isStreamAvailable
}