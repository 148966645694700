// React
import React, { useEffect } from 'react'
import { Prompt } from "react-router-dom"
// Material UI
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, CircularProgress, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
// Components
import GeneralTextField from '../../general/TextField'
import GeneralSwitchField from '../../general/SwitchField'
import CallTypeIconsMenu from './callTypeIconsMenu'

const CreateNewCallTypeModal = (props) => {
    const { handleInputChange, createCallType, callType, errors, loaders, open, swapCallTypeIcons } = props

    useEffect(() => {
        const beforeunload = (e) => {
            e.preventDefault()
            e.returnValue = true
        }

        window.addEventListener("beforeunload", beforeunload)
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        }
    }, [])

    const handleClose = () => {
        let shouldCloseModal = window.confirm(
            "Changes you made may not be saved. Are you sure you want to leave this page?"
        )
        if (!shouldCloseModal) return
        else props.handleClose()
    }

    return (
        <>
            <Prompt
                when={true}
                message={() => "Changes you made may not be saved. Are you sure you want to leave this page?"}
            />
            <Dialog
                onClose={!loaders.createNewCallType && handleClose}
                open={open}
                maxWidth='md'
                PaperProps={{ style: { flex: 1 } }}
            >
                <DialogTitle
                    disableTypography
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        variant='h6'
                    >
                        Add New Call Type
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        disabled={loaders.createNewCallType}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    dividers
                >
                    <Grid
                        xs={12}
                        spacing={2}
                        container
                    >
                        <GeneralTextField
                            autoFocus={true}
                            width={12}
                            label='Call Type *'
                            placeholder='Enter call type name...'
                            statePath={`callType.callType`}
                            value={callType.callType}
                            error={errors.callType.callType}
                            handleInputChange={handleInputChange}
                        />
                        <CallTypeIconsMenu
                            icon1={callType.icon1}
                            statePathIcon1={'callType.icon1'}
                            icon2={callType.icon2}
                            statePathIcon2={'callType.icon2'}
                            handleInputChange={handleInputChange}
                            errors={errors}
                            swapCallTypeIcons={swapCallTypeIcons}
                        />
                        <GeneralSwitchField
                            width={12}
                            size='normal'
                            label='Is Default'
                            statePath={`callType.default`}
                            value={callType.default}
                            error={errors.callType.default}
                            handleInputChange={handleInputChange}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={createCallType}
                        color='secondary'
                        variant='contained'
                    >
                        {loaders.createNewCallType &&
                            <CircularProgress
                                size={18}
                                style={{ marginRight: 8 }}
                            />
                        }
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreateNewCallTypeModal;
