import RedHydrantImage from '../../../assets/mapboxIcons/hydrant-active-red.png'
import YellowHydrantImage from '../../../assets/mapboxIcons/hydrant-active-yellow.png'
import GreenHydrantImage from '../../../assets/mapboxIcons/hydrant-active-green.png'
import StationImage from '../../../assets/mapboxIcons/station-icon.png'
import HazmatImage from '../../../assets/mapboxIcons/hazmat-icon.png'
import AedImage from '../../../assets/mapboxIcons/aed-icon.png'
import EvacImage from '../../../assets/mapboxIcons/evac-icon.png'
import TornadoSirenImage from '../../../assets/mapboxIcons/tornado-siren-icon.png'
import WeatherImage from '../../../assets/mapboxIcons/weather-icon.png'
import _ from 'underscore'

export const layerIds = {
    redHydrant: 'red-hydrant-markers-layer',
    yellowHydrant: 'yellow-hydrant-markers-layer',
    greenHydrant: 'green-hydrant-markers-layer',
    hazmat: 'hazmat-markers-layer',
    station: 'station-markers-layer',
    aed: 'aed-markers-layer',
    evac: 'evac-markers-layer',
    tornadoSiren: 'tornado-siren-markers-layer',
    weather: 'weather-markers-layer',
}

export const sourceIds = {
    redHydrant: 'red-hydrant-markers-source',
    yellowHydrant: 'yellow-hydrant-markers-source',
    greenHydrant: 'green-hydrant-markers-source',
    hazmat: 'hazmat-markers-source',
    station: 'station-markers-source',
    aed: 'aed-markers-source',
    evac: 'evac-markers-source',
    tornadoSiren: 'tornado-siren-markers-source',
    weather: 'weather-markers-source',
}

const MapRequiredImages = {
    RedHydrantImage,
    YellowHydrantImage,
    GreenHydrantImage,
    StationImage,
    HazmatImage,
    AedImage,
    EvacImage,
    TornadoSirenImage
}

// Helper function to create a custom marker element
export const createMarkerElement = (marker) => {
    const { type, iconURL, iconSize = '30px', responderTitle, responderIconAndTextColor } = marker
    const markerEl = document.createElement('div')
    markerEl.className = 'marker'
    if (type === 'dispatch') {
        markerEl.style.backgroundImage = `url(${iconURL})`
        markerEl.style.backgroundSize = 'cover'
        markerEl.style.width = iconSize
        markerEl.style.height = iconSize
        markerEl.style.borderRadius = '50%'
        markerEl.style.cursor = 'pointer'
    }
    if (type === 'responder') {
        markerEl.style.cursor = 'pointer'
        markerEl.style.height = '30px'
        markerEl.style.width = '30px'
        markerEl.style.backgroundColor = responderIconAndTextColor
        markerEl.style.borderRadius = '50%'
        markerEl.style.borderWidth = '1px'
        markerEl.style.borderColor = '#000000'
        markerEl.style.display = 'flex'
        markerEl.style.justifyContent = 'center'
        markerEl.style.alignItems = 'center'
        markerEl.style.fontWeight = 'bold'
        const textNode = document.createTextNode(responderTitle)
        markerEl.appendChild(textNode)
    }

    return markerEl
}

export const getResponderIconAndTextColor = (isThisResponderMe, responder, colorOpacity = 1.0) => {
    if (isThisResponderMe)
        return `rgba(255, 192, 0, ${colorOpacity})`
    else if (responder.responderStatus === 'arrivedAtScene')
        return `rgba(0, 137, 19, ${colorOpacity})`
    else if (responder.responderStatus === 'scene')
        return `rgba(0, 137, 19, ${colorOpacity})`
    else if (responder.responderStatus === 'station')
        return `rgba(0, 104, 247, ${colorOpacity})`
    else if (responder.responderStatus === 'standingBy')
        return `rgba(176, 114, 253, ${colorOpacity})`

    return `rgba(96, 96, 96, ${colorOpacity})`
}

export const getResponderIconAndTextColorHex = (isThisResponderMe, responder) => {
    if (isThisResponderMe)
        return `#ffc000`
    else if (responder.responderStatus === 'arrivedAtScene')
        return `#008913`
    else if (responder.responderStatus === 'scene')
        return `#008913`
    else if (responder.responderStatus === 'station')
        return `#0068f7`
    else if (responder.responderStatus === 'standingBy')
        return `#b072fd`

    return `#606060`
}

export const shouldRenderMap = (dispatchLocation, responders) => {
    return (!!dispatchLocation?.coordinates?.latitude || responders.filter(resp => !!resp?.coordinates?.latitude)?.length > 0)
}

export const asyncAddImage = (key, image, map) => {
    return new Promise((resolve, reject) => {
        const img = document.createElement('img')
        img.src = image
        img.style.width = '24px'
        img.style.height = '30px'
        img.style.objectFit = 'contain'
        img.onload = () => {
            map.addImage(key, img)
            resolve(true)
        }
    })
}

export const asyncAddRequiredImagesToMap = async (map) => {
    const keys = Object.keys(MapRequiredImages)
    for (const key of keys) {
        const image = MapRequiredImages[key]
        await asyncAddImage(key, image, map)
    }
}

export const mapError = (e) => alert('Maps. Something went wrong.')

export const addLayersToMap = (map, selectedLayers, setSelectedLayers, layersButtonsRef, departmentHasWeatherPermissions, openUpgradeToPremiumModal, closeUpgradeToPremiumModal) => {
    addStationLayer(map, selectedLayers, setSelectedLayers, layersButtonsRef)
    addAedLayer(map, selectedLayers, setSelectedLayers, layersButtonsRef)
    addEvacLayer(map, selectedLayers, setSelectedLayers, layersButtonsRef)
    addTornadoSirenLayer(map, selectedLayers, setSelectedLayers, layersButtonsRef)
    addHydrantLayer(map, selectedLayers, setSelectedLayers, layersButtonsRef)
    addHazmatLayer(map, selectedLayers, setSelectedLayers, layersButtonsRef)
    addWeatherLayer(map, selectedLayers, setSelectedLayers, layersButtonsRef, departmentHasWeatherPermissions, openUpgradeToPremiumModal)
}

export const addHydrantLayer = (map, selectedLayers, setSelectedLayers, layersButtonsRef) => {

    const button = document.createElement("div")
    button.className = "mapboxgl-ctrl mapboxgl-ctrl-group"
    button.innerHTML = `
        <button class="mapboxgl-ctrl-fullscreen" type="button" aria-label="Hydrant Layer">
            <span aria-hidden="true" title="Hydrant Layer"  class="layer-buttons">
                <img src="${RedHydrantImage}" style="height: 30px; width: 30px; object-fit: contain;" alt="hydrant-layer" />
            </span>
        </button>
    `
    button.style.border = selectedLayers.includes('hydrant') ? `3px solid ${'#ff9c00'}` : '3px solid transparent'

    const onClick = () => {
        if (selectedLayers.includes('hydrant')) setSelectedLayers(selectedLayers.filter(lyr => lyr !== 'hydrant'))
        else setSelectedLayers([...selectedLayers, 'hydrant'])
    }
    const onAdd = () => {
        button.addEventListener("click", onClick)
        return button
    }

    const onRemove = () => {
        button.remove()
    }

    const control = {
        onAdd: onAdd,
        onRemove: onRemove
    }
    layersButtonsRef.current.push(control)
    map.addControl(control, "bottom-right")
}

export const addStationLayer = (map, selectedLayers, setSelectedLayers, layersButtonsRef) => {
    const button = document.createElement("div")
    button.className = "mapboxgl-ctrl mapboxgl-ctrl-group"
    button.innerHTML = `
        <button class="mapboxgl-ctrl-fullscreen" type="button" aria-label="Station Layer">
            <span aria-hidden="true" title="Station Layer" class="layer-buttons">
                <img src="${StationImage}" style="height: 30px; width: 30px; object-fit: contain;" alt="station-layer" />
            </span>
        </button>
    `
    button.style.border = selectedLayers.includes('station') ? `3px solid ${'#ff9c00'}` : '3px solid transparent'

    const onClick = () => {
        if (selectedLayers.includes('station')) setSelectedLayers(selectedLayers.filter(lyr => lyr !== 'station'))
        else setSelectedLayers([...selectedLayers, 'station'])
    }

    const onAdd = () => {
        button.addEventListener("click", onClick)
        return button
    }

    const onRemove = () => {
        button.remove()
    }

    const control = {
        onAdd: onAdd,
        onRemove: onRemove
    }
    layersButtonsRef.current.push(control)
    map.addControl(control, "bottom-right")
}

export const addHazmatLayer = (map, selectedLayers, setSelectedLayers, layersButtonsRef) => {
    const button = document.createElement("div")
    button.className = "mapboxgl-ctrl mapboxgl-ctrl-group"
    button.innerHTML = `
        <button class="mapboxgl-ctrl-fullscreen" type="button" aria-label="Hazmat Layer">
            <span aria-hidden="true" title="Hazmat Layer" class="layer-buttons">
                <img src="${HazmatImage}" style="height: 28px; width: 28px; object-fit: contain;" alt="hazmat-layer" />
            </span>
        </button>
    `
    button.style.border = selectedLayers.includes('hazmat') ? `3px solid ${'#ff9c00'}` : '3px solid transparent'

    const onClick = () => {
        if (selectedLayers.includes('hazmat')) setSelectedLayers(selectedLayers.filter(lyr => lyr !== 'hazmat'))
        else setSelectedLayers([...selectedLayers, 'hazmat'])
    }

    const onAdd = () => {
        button.addEventListener("click", onClick)
        return button
    }

    const onRemove = () => {
        button.remove()
    }

    const control = {
        onAdd: onAdd,
        onRemove: onRemove
    }
    layersButtonsRef.current.push(control)
    map.addControl(control, "bottom-right")
}

export const addAedLayer = (map, selectedLayers, setSelectedLayers, layersButtonsRef) => {
    const button = document.createElement("div")
    button.className = "mapboxgl-ctrl mapboxgl-ctrl-group"
    button.innerHTML = `
        <button class="mapboxgl-ctrl-fullscreen" type="button" aria-label="AED Layer">
            <span aria-hidden="true" title="AED Layer" class="layer-buttons">
                <img src="${AedImage}" style="height: 28px; width: 28px; object-fit: contain;" alt="aed-layer" />
            </span>
        </button>
    `
    button.style.border = selectedLayers.includes('aed') ? `3px solid ${'#ff9c00'}` : '3px solid transparent'

    const onClick = () => {
        if (selectedLayers.includes('aed')) setSelectedLayers(selectedLayers.filter(lyr => lyr !== 'aed'))
        else setSelectedLayers([...selectedLayers, 'aed'])
    }

    const onAdd = () => {
        button.addEventListener("click", onClick)
        return button
    }

    const onRemove = () => {
        button.remove()
    }

    const control = {
        onAdd: onAdd,
        onRemove: onRemove
    }
    layersButtonsRef.current.push(control)
    map.addControl(control, "bottom-right")
}

export const addEvacLayer = (map, selectedLayers, setSelectedLayers, layersButtonsRef) => {
    const button = document.createElement("div")
    button.className = "mapboxgl-ctrl mapboxgl-ctrl-group"
    button.innerHTML = `
        <button class="mapboxgl-ctrl-fullscreen" type="button" aria-label="Evac Layer">
            <span aria-hidden="true" title="Evac Layer" class="layer-buttons">
                <img src="${EvacImage}" style="height: 28px; width: 28px; object-fit: contain;" alt="evac-layer" />
            </span>
        </button>
    `
    button.style.border = selectedLayers.includes('evac') ? `3px solid ${'#ff9c00'}` : '3px solid transparent'

    const onClick = () => {
        if (selectedLayers.includes('evac')) setSelectedLayers(selectedLayers.filter(lyr => lyr !== 'evac'))
        else setSelectedLayers([...selectedLayers, 'evac'])
    }

    const onAdd = () => {
        button.addEventListener("click", onClick)
        return button
    }

    const onRemove = () => {
        button.remove()
    }

    const control = {
        onAdd: onAdd,
        onRemove: onRemove
    }
    layersButtonsRef.current.push(control)
    map.addControl(control, "bottom-right")
}

export const addTornadoSirenLayer = (map, selectedLayers, setSelectedLayers, layersButtonsRef) => {
    const button = document.createElement("div")
    button.className = "mapboxgl-ctrl mapboxgl-ctrl-group"
    button.innerHTML = `
        <button class="mapboxgl-ctrl-fullscreen" type="button" aria-label="Tornado Siren Layer">
            <span aria-hidden="true" title="Tornado Siren Layer" class="layer-buttons">
                <img src="${TornadoSirenImage}" style="height: 28px; width: 28px; object-fit: contain;" alt="tornado-siren-layer" />
            </span>
        </button>
    `
    button.style.border = selectedLayers.includes('tornadoSiren') ? `3px solid ${'#ff9c00'}` : '3px solid transparent'

    const onClick = () => {
        if (selectedLayers.includes('tornadoSiren')) setSelectedLayers(selectedLayers.filter(lyr => lyr !== 'tornadoSiren'))
        else setSelectedLayers([...selectedLayers, 'tornadoSiren'])
    }

    const onAdd = () => {
        button.addEventListener("click", onClick)
        return button
    }

    const onRemove = () => {
        button.remove()
    }

    const control = {
        onAdd: onAdd,
        onRemove: onRemove
    }
    layersButtonsRef.current.push(control)
    map.addControl(control, "bottom-right")
}

export const addWeatherLayer = (map, selectedLayers, setSelectedLayers, layersButtonsRef, departmentHasWeatherPermissions, openUpgradeToPremiumModal) => {
    const button = document.createElement("div")
    button.className = "mapboxgl-ctrl mapboxgl-ctrl-group"
    button.innerHTML = `
        <button class="mapboxgl-ctrl-fullscreen" type="button" aria-label="Weather Layer">
            <span aria-hidden="true" title="Weather Layer" class="layer-buttons">
                <img src="${WeatherImage}" style="height: 28px; width: 28px; object-fit: contain;" alt="weather-layer" />
            </span>
        </button>
    `
    button.style.border = selectedLayers.includes('weather') ? `3px solid ${'#ff9c00'}` : '3px solid transparent'

    const onClick = () => {
        if (departmentHasWeatherPermissions) {
            if (selectedLayers.includes('weather')) setSelectedLayers(selectedLayers.filter(lyr => lyr !== 'weather'))
            else setSelectedLayers([...selectedLayers, 'weather'])
        }
        else {
            openUpgradeToPremiumModal()
        }
    }

    const onAdd = () => {
        button.addEventListener("click", onClick)
        return button
    }

    const onRemove = () => {
        button.remove()
    }

    const control = {
        onAdd: onAdd,
        onRemove: onRemove
    }
    layersButtonsRef.current.push(control)
    map.addControl(control, "bottom-right")
}

export const addRecenterButton = (map, center, zoom) => {
    const button = document.createElement("div")
    button.className = "mapboxgl-ctrl mapboxgl-ctrl-group"
    button.innerHTML = `
        <button class="mapboxgl-ctrl-fullscreen" type="button" aria-label="Recenter map">
            <span class="mapboxgl-ctrl-icon" aria-hidden="true" title="Recenter Map">
                <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                style="background-color: rgb(255, 255, 255); color: black; width: 28px; height: 28px;">
                <path
                    d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3-8c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3z">
                </path>
            </svg>
            </span>
        </button>
    `
    const onAdd = () => {
        button.addEventListener("click", () => {
            map.flyTo({ center, zoom })
        })
        return button
    }

    const onRemove = () => {
        button.removeEventListener("click", () => {
            map.flyTo({ center, zoom })
        })
    }

    map.addControl({
        onAdd: onAdd,
        onRemove: onRemove
    }, "bottom-right")
}

export const addSourceAndLayer = (map, sourceId, sourceData, layerId, image, belowLayerId = 'road-label') => {
    map.addSource(sourceId, {
        type: 'geojson',
        data: sourceData,
        generateId: false,
        promoteId: true
    })

    map.addLayer({
        id: layerId,
        type: 'symbol',
        source: sourceId,
        layout: {
            'icon-image': image,
            'icon-size': 1,
            "icon-allow-overlap": true,
            "icon-text-fit": 'height'
        }
    }, belowLayerId)
}

export const removeSourceAndLayer = (map, sourceId, layerId) => {
    try {
        if (!map?.getSource?.(sourceId)) return
        if (map?.getLayer(layerId)) {
            map.removeLayer(layerId)
        }
        map.removeSource(sourceId)
    } catch (error) {
        //
    }
}

export const formatDate = (unixTimestamp) => {
    if (typeof unixTimestamp !== 'number') return

    let d = new Date(unixTimestamp * 1000),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2)
        month = '0' + month
    if (day.length < 2)
        day = '0' + day

    return [day, month, year].join('-')
}

const setPointer = (map) => (e) => { map.getCanvas().style.cursor = 'pointer' }
const resetPointer = (map) => (e) => { map.getCanvas().style.cursor = 'grab' }

export const setCursorOnLayerHover = (map) => {
    const keys = Object.keys(layerIds)
    for (const key of keys) {
        map.on('mouseover', layerIds[key], setPointer(map))
        map.on('mouseleave', layerIds[key], resetPointer(map))
    }
}

export const resetCursorOnLayerHover = (map) => {
    const keys = Object.keys(layerIds)
    for (const key of keys) {
        map.off('mouseover', layerIds[key], setPointer(map))
        map.off('mouseleave', layerIds[key], resetPointer(map))
    }
}

export const populateResponders = (dispatch) => {
    const { respondersObjects } = dispatch
    const responders = dispatch.responders.map(responder => {
        let userObject = _.findWhere(respondersObjects, { _id: responder.userId })
        return {
            ...responder,
            firstName: userObject?.firstName,
            lastName: userObject?.lastName
        }
    })
    return responders
}