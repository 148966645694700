//React
import React from 'react'

// Material UI
import { Dialog, DialogTitle, DialogContent, Box, Grid, Typography, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

// Components
import RadioGroupField from '../../general/RadioGroupField'

// Utilities
import moment from 'moment'

class VerifyUserModal extends React.Component {
    render() {
        const { open, handleClose, user, handleGenerateCode, showVerificationCode, handleChannelChange } = this.props

        return (

            <Dialog
                onClose={() => handleClose(true)}
                open={open}
                maxWidth="md"
                PaperProps={{ style: { flex: 1 } }}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <DialogTitle
                        id='create-dispatch-title'
                        onClose={handleClose}
                    >
                        Verify User
                    </DialogTitle>
                    <IconButton
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={6}
                        >
                            <Typography
                                style={{ marginBottom: 16 }}
                            >
                                Generate verification code using:
                            </Typography>
                            <RadioGroupField
                                name="channel"
                                value={this.props.selectedChannel}
                                options={[
                                    { key: 'Push', value: 'push' },
                                    { key: 'Email', value: 'email', disabled: !user.email, },
                                    { key: 'Text', value: 'text', disabled: !user.phone }
                                ]}
                                labelPlacement="end"
                                handleInputChange={handleChannelChange}
                                isValueString={true}
                            />

                            {showVerificationCode && user.verificationCode && (
                                <Box mt={2} p={1} border={2} display="flex" justifyContent="center">
                                    {user.verificationCode &&
                                        user.verificationCode.split('').map((digit, index) => (
                                            <Typography key={index} variant="body1" style={{ marginRight: '8px' }}>
                                                {digit}
                                            </Typography>
                                        ))}
                                </Box>
                            )}

                            <Button onClick={handleGenerateCode} color="primary" variant="outlined" style={{ marginTop: 16 }}>
                                Generate Code
                            </Button>
                        </Grid>

                        <Grid
                            item
                            xs={6}
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}
                        >
                            {user.verificationCode ? (
                                <>
                                    <Typography> Last Generated Code was: {user.verificationCode}</Typography>
                                    <Typography> Channel: {user.verificationChannel}</Typography>
                                    <Typography>Generated For: {user.firstName} {user.lastName}</Typography>
                                    <Typography> Generated at {moment.tz(user.verificationTime, moment.tz.guess()).format('MM/DD/YY HH:mm:ss z')}</Typography>

                                </>
                            ) :
                                null
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }
}

export default VerifyUserModal;
