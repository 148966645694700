import React, { useLayoutEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'

// icons
import CloseIcon from '@material-ui/icons/Close'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const ViewAttachment = ({ attachmentModalOpen, currentAttachmentIndex: propsCurrentAttachmentIndex, allAttachments, closeAttachmentModal }) => {
    const [state, setState] = useState({
        currentAttachmentIndex: 0
    })

    const { currentAttachmentIndex } = state

    useLayoutEffect(() => {
        setState(prevState => ({
            ...prevState,
            currentAttachmentIndex: propsCurrentAttachmentIndex
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAttachment = (type) => {
        const attachmentsLength = allAttachments.length

        if (type === 'next') {
            let nextAttachmentIndex = currentAttachmentIndex + 1

            if (nextAttachmentIndex >= attachmentsLength)
                nextAttachmentIndex = 0

            setState(prevState => ({
                ...prevState,
                currentAttachmentIndex: nextAttachmentIndex
            }))
        }
        else if (type === 'last') {
            let lastAttachmentIndex = currentAttachmentIndex - 1

            if (lastAttachmentIndex < 0)
                lastAttachmentIndex = attachmentsLength - 1

            setState(prevState => ({
                ...prevState,
                currentAttachmentIndex: lastAttachmentIndex
            }))
        }
    }

    return (
        <Dialog
            id="lightbox"
            // maxWidth={'lg'}
            onClose={closeAttachmentModal}
            open={attachmentModalOpen}
            PaperProps={{
                style: {
                    maxWidth: '80vw'
                }
            }}
            BackDropProps={{
                style: { backgroundColor: 'black' }
            }}
        >
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4 style={{ padding: 0, margin: 0 }}>
                        {currentAttachmentIndex + 1} of {allAttachments.length}
                    </h4>
                    <CloseIcon style={{ cursor: 'pointer' }} onClick={closeAttachmentModal} />
                </div>
            </DialogTitle>
            <DialogContent
                style={{
                    padding: 0
                }}
            >
                <div
                    style={{
                        top: '10vh',
                        display: 'flex',
                        position: 'absolute',
                        width: '45vw',
                        height: '70vh'
                    }}
                >
                    <div
                        onClick={() => handleAttachment('last')}
                        style={{
                            width: '50%',
                            height: '100%',
                            cursor: 'w-resize'
                        }}
                    />
                    <div
                        onClick={() => handleAttachment('next')}
                        style={{
                            width: '50%',
                            height: '100%',
                            cursor: 'e-resize'
                        }}
                    />
                </div>
                <img
                    src={allAttachments[currentAttachmentIndex]?.url}
                    alt="dispatchAttachment"
                    style={{
                        width: '45vw',
                        height: '70vh',
                        objectFit: 'contain'
                    }}
                />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <ChevronLeftIcon style={{ cursor: 'pointer', fontSize: 56 }} size="lg" onClick={() => handleAttachment('last')} />
                    <ChevronRightIcon style={{ cursor: 'pointer', fontSize: 56 }} onClick={() => handleAttachment('next')} />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ViewAttachment;