// React
import React from 'react'
import { Prompt } from "react-router-dom"

// Material UI
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, CircularProgress, IconButton, Button, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
// Components
import GeneralTextField from '../../general/TextField'
import GeneralDropdownField from '../../general/DropdownField'
import GeneralCheckboxField from '../../general/CheckboxField'
import ActionConfirmation from '../../general/ActionConfirmation'
import NotificationSettings from './NotificationSettings'
import AlertSettings from './AlertSettings'
// Utilities
import _ from 'underscore'
import moment from 'moment'
import { UsersTypes, UserRolesInDepartment, DispatcherRolesInDepartment, EditMapsPermissionOptions } from '../../../utilities/Options'

class EditUserModal extends React.Component {
    state = {
        isUserUnsaved: false,
        confirmDeletion: false
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.beforeunload)
    }

    componentDidUpdate(prevProps, prevState) {
        const { isUserUnsaved } = this.state
        if (prevProps.user !== this.props.user) {
            const hasVerificationChanged =
                this.props.user.verificationCode !== prevProps.user.verificationCode ||
                this.props.user.verificationTime !== prevProps.user.verificationTime ||
                this.props.user.verificationChannel !== prevProps.user.verificationChannel

            const { verificationCode, verificationChannel, verificationTime, ...userWithoutVerification } = this.props.user
            this.setState({ user: userWithoutVerification })

            if (!isUserUnsaved && !hasVerificationChanged) {
                this.setState({ isUserUnsaved: true })
            }
        }
    }

    // componentDidUpdate(prevProps, prevState) {
    //     const { isUserUnsaved } = this.state
    //     if (prevProps.user !== this.props.user) {
    //       if (!isUserUnsaved ) {
    //         this.setState({ isUserUnsaved: true })
    //       }
    //     }
    //   }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.beforeunload)
    }

    beforeunload = (e) => {
        const { isUserUnsaved } = this.state
        if (isUserUnsaved) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    openConfirmDeletion = () => {
        this.setState({ confirmDeletion: true })
    }

    closeConfirmDeletion = () => {
        this.setState({ confirmDeletion: false })
    }

    handleClose = () => {
        const { isUserUnsaved } = this.state
        if (isUserUnsaved) {
            let shouldCloseModal = window.confirm(
                "Changes you made may not be saved. Are you sure you want to leave this page?"
            )
            if (!shouldCloseModal) return
            else this.setState({ isUserUnsaved: false })
        }
        this.props.handleClose()
    }

    updateUser = async (type) => {
        const response = await this.props.updateUser(type)
        const { isUserUnsaved } = this.state
        if (response && isUserUnsaved)
            this.setState({ isUserUnsaved: false })
    }

    render() {
        const { handleInputChange, handleUserTypeInputChange, handleAddDepartment, handleDeleteDepartment, handleSpecificDepartmentRoleChange,
            deleteUser, handleSelectInvite, user, departments, errors, loaders, open, authenticatedUser,
            handleForgotPassword, handleResendActivationLink, handleResendDepartmentInviteLink, specificDepartmentRole, handleSendTestSms,
            specificDepartmentEditMapPermissions, handleSpecificDepartmentEditMapPermissions, openVerifyUserModal
        } = this.props
        const { isUserUnsaved, confirmDeletion } = this.state
        const disabled = authenticatedUser?.type !== 'superAdmin'
        const disableButtons = loaders.updateUser || loaders.reactivateUser || loaders.deactivateUser || loaders.deleteUser || loaders.forgotPassword || loaders.resendActivationLink

        const userCurrentAndPendingDepartmentIds = [...(user?.departments || []).map(department => department.departmentId), ...(user?.invites || []).map(department => department.departmentId)]
        const departmentOptions = departments?.map(department => {
            if (userCurrentAndPendingDepartmentIds.includes(department.value))
                return { ...department, disabled: true }
            return department
        })

        const formatUserDeparmentsListForDropdown = (userDepartments) => {
            const formattedUserDeparmentsArray = userDepartments.map(userDepartment => {
                return {
                    text: _.findWhere(departments, { value: userDepartment.departmentId })?.text,
                    value: userDepartment.departmentId
                }
            })
            return formattedUserDeparmentsArray
        }

        const renderResendActivationButton = (disabled, authenticatedUser, userCurrentAndPendingDepartmentIds, user) => {
            if (!!user?.activated)
                return null

            const renderBtn = (
                <Button
                    onClick={() => handleResendActivationLink(user)}
                    disabled={disableButtons}
                    variant='outlined'
                >
                    {loaders.resendActivationLink &&
                        <CircularProgress
                            size={18}
                            style={{ marginRight: 8 }}
                        />
                    }
                    Resend Activation Link
                </Button>
            )

            if (disabled) {
                // check if reqUser is admin in editUser's modal 
                for (const department of authenticatedUser.departments) {
                    if (userCurrentAndPendingDepartmentIds.includes(department.departmentId)) {
                        if (department.role === 'departmentAdmin')
                            return renderBtn
                    }
                }
                return null
            }

            return renderBtn
        }

        const renderSendTestSms = (authenticatedUser, user) => {
            if (!user?._id)
                return null

            const renderBtn = (
                <Button
                    onClick={() => handleSendTestSms(user._id)}
                    disabled={disableButtons}
                    variant='outlined'
                    style={{ marginLeft: 4 }}
                >
                    {loaders.sendTestSms &&
                        <CircularProgress
                            size={18}
                            style={{ marginRight: 8 }}
                        />
                    }
                    SMS Test
                </Button>
            )

            if (disabled) {
                // check if reqUser is admin in editUser's modal 
                for (const department of authenticatedUser.departments) {
                    if (userCurrentAndPendingDepartmentIds.includes(department.departmentId)) {
                        if (department.role === 'departmentAdmin')
                            return renderBtn
                    }
                }
                return null
            }

            return renderBtn
        }
        const handleVerificationButtonClick = (disabled) => {
            if (!disabled) {
                const renderBtn = (
                    <Button
                        onClick={() => openVerifyUserModal()}
                        disabled={disableButtons}
                        variant='outlined'
                    >
                        VERIFICATION
                    </Button>
                )

                return renderBtn
            }
        }

        const renderSendResetPasswordButton = (disabled, user) => {
            if (!disabled && !!user?.activated) {
                const renderBtn = (
                    <Button
                        onClick={() => handleForgotPassword(user)}
                        disabled={disableButtons}
                        variant='outlined'
                    >
                        {loaders.forgotPassword &&
                            <CircularProgress
                                size={18}
                                style={{ marginRight: 8 }}
                            />
                        }
                        Send Reset Password Link
                    </Button>
                )

                return renderBtn
            }

            return null
        }

        const renderResendDepartmentInviteButton = (disabled, authenticatedUser, user) => {
            if (!disabled && user?.activated)
                return null

            const renderBtn = (
                <Button
                    onClick={() => handleResendDepartmentInviteLink(user)}
                    disabled={disableButtons}
                    variant='outlined'
                >
                    {loaders.resendDepartmentInviteLink &&
                        <CircularProgress
                            size={18}
                            style={{ marginRight: 8 }}
                        />
                    }
                    Resend Invite
                </Button>
            )

            if (disabled) {
                // check if reqUser is admin in editUser's modal 
                for (const department of authenticatedUser.departments) {
                    if (userCurrentAndPendingDepartmentIds.includes(department.departmentId)) {
                        if (department.role === 'departmentAdmin')
                            return renderBtn
                    }
                }
                return null
            }

            return renderBtn
        }

        const handleDispatcherDepartmentsChange = (e, data) => {
            const { value: selectedDepartmentIds } = data

            if (selectedDepartmentIds) {
                const formattedUserDepartments = selectedDepartmentIds.map(departmentId => ({ departmentId, role: user.type }))
                const formattedData = { ...data, value: formattedUserDepartments }

                handleInputChange(e, formattedData)
            }
        }

        return (
            <>
                <Prompt
                    when={isUserUnsaved}
                    message={() =>
                        "Changes you made may not be saved. Are you sure you want to leave this page?"
                    }
                />
                <Dialog
                    onClose={!disableButtons ? this.handleClose : undefined}
                    open={open}
                    maxWidth='md'
                    PaperProps={{ style: { flex: 1 } }}
                >
                    <DialogTitle
                        disableTypography
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            variant='h6'
                        >
                            Edit User {user.phoneNumberOnly ? '(Phone Number Only User)' : ''}
                        </Typography>
                        <div
                            style={{ display: 'flex' }}
                        >
                            {openVerifyUserModal && handleVerificationButtonClick(disabled)}
                            {!user.phoneNumberOnly && handleForgotPassword && renderSendResetPasswordButton(disabled, user)}
                            {handleResendDepartmentInviteLink && renderResendDepartmentInviteButton(disabled, authenticatedUser, user)}
                            {!user.phoneNumberOnly && handleResendActivationLink && renderResendActivationButton(disabled, authenticatedUser, userCurrentAndPendingDepartmentIds, user)}
                            {handleSendTestSms && renderSendTestSms(authenticatedUser, user)}
                            <IconButton
                                aria-label='close'
                                onClick={this.handleClose}
                                disabled={disableButtons}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent
                        dividers
                    >
                        <Grid
                            container
                            item
                            xs={12}
                        >
                            <Typography
                                style={{ marginLeft: 'auto' }}
                                variant='caption'
                            >
                                Created at {moment(user.createdAt).format('MM/DD/YYYY')}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            spacing={2}
                        >
                            {user.phoneNumberOnly &&
                                <GeneralCheckboxField
                                    gridStyle={{ display: 'flex', alignItems: 'center', marginTop: 8 }}
                                    width={12}
                                    size='small'
                                    label='Is this a phone number only user?'
                                    statePath={`user.phoneNumberOnly`}
                                    value={user.phoneNumberOnly}
                                    error={errors.user.phoneNumberOnly}
                                    handleInputChange={handleInputChange}
                                />
                            }
                            <GeneralTextField
                                autoFocus={true}
                                width={12}
                                label='First Name *'
                                placeholder='Enter first name...'
                                statePath={`user.firstName`}
                                value={user.firstName}
                                error={errors.user.firstName}
                                handleInputChange={handleInputChange}
                            />
                            <GeneralTextField
                                width={12}
                                label='Last Name *'
                                placeholder='Enter last name...'
                                statePath={`user.lastName`}
                                value={user.lastName}
                                error={errors.user.lastName}
                                handleInputChange={handleInputChange}
                            />
                            {!user.phoneNumberOnly &&
                                <GeneralTextField
                                    width={12}
                                    label='Email Address *'
                                    placeholder='Enter email address...'
                                    statePath={`user.email`}
                                    value={user.email}
                                    error={errors.user.email}
                                    handleInputChange={handleInputChange}
                                    labelTooltipTitle={disabled ? "if you update email, it remains unchanged until the confirmation link sent to the new email address is clicked." : null}
                                    labelTooltipIcon={disabled ? "info" : null}
                                />
                            }
                            <GeneralTextField
                                width={12}
                                format='phoneNumber'
                                label='Phone Number *'
                                placeholder='Enter phone number...'
                                statePath={`user.phone`}
                                value={user.phone}
                                error={errors.user.phone}
                                handleInputChange={handleInputChange}
                                labelTooltipTitle={disabled ? "If you update phone number, it remains unchanged until the confirmation link sent to the new phone number is clicked." : null}
                                labelTooltipIcon={disabled ? "info" : null}
                            />
                            {!disabled &&
                                <GeneralDropdownField
                                    options={!user.phoneNumberOnly ? UsersTypes : UsersTypes.filter(option => option.value === 'user')}
                                    width={12}
                                    label='User Type *'
                                    placeholder='Enter user type...'
                                    statePath={`user.type`}
                                    value={user.type}
                                    error={errors.user.type}
                                    handleInputChange={handleUserTypeInputChange}
                                />
                            }
                            {!disabled &&
                                <>
                                    {/*  Alerts Settings */}
                                    {!user.phoneNumberOnly &&
                                        <AlertSettings
                                            user={user}
                                            departments={departments.filter(dept => user.departments.findIndex(userDept => userDept.departmentId === dept._id) > -1)}
                                            handleInputChange={handleInputChange}
                                            errors={errors}
                                            containerStyle={{
                                                padding: 16
                                            }}
                                        />
                                    }
                                    {/*  Notifications Settings */}
                                    <NotificationSettings
                                        user={user}
                                        isSuperAdmin={authenticatedUser?.type === 'superAdmin' || user?.type === 'superAdmin'} // if authUser or edited user is superAdmin, they can unblock sms notificaitons
                                        departments={departments.filter(dept => user.departments.findIndex(userDept => userDept.departmentId === dept._id) > -1)}
                                        handleInputChange={handleInputChange}
                                        containerStyle={{
                                            padding: 16
                                        }}
                                    />
                                </>
                            }
                            {!disabled && user.type === 'user' &&
                                <>
                                    {user.departments.map((department, index) => (
                                        <React.Fragment
                                            key={`${department.key}`}
                                        >
                                            <GeneralDropdownField
                                                options={departmentOptions}
                                                format="autocomplete"
                                                width={4}
                                                hideLabel={index !== 0}
                                                label={`Departments *`}
                                                placeholder='Select department...'
                                                statePath={`user.departments.${index}.departmentId`}
                                                value={user.departments[index].departmentId}
                                                handleInputChange={handleInputChange}
                                                error={errors.user.departments[index]?.departmentId}
                                            />
                                            <GeneralDropdownField
                                                options={!user.phoneNumberOnly ? UserRolesInDepartment : UserRolesInDepartment.filter(option => option.value === 'subscriber')}
                                                width={4}
                                                hideLabel={index !== 0}
                                                label='Role *'
                                                placeholder='Select user role...'
                                                statePath={`user.departments.${index}.role`}
                                                value={user.departments[index].role}
                                                handleInputChange={handleInputChange}
                                                error={errors.user.departments[index]?.role}
                                            />
                                            <GeneralDropdownField
                                                options={EditMapsPermissionOptions}
                                                width={3}
                                                hideLabel={index !== 0}
                                                label='Edit Maps'
                                                placeholder='Maps edit permissions...'
                                                statePath={`user.departments.${index}.editMapsPermission`}
                                                value={user.departments[index].editMapsPermission ?? false}
                                                handleInputChange={handleInputChange}
                                                error={errors.user.departments[index]?.editMapsPermission}
                                            />
                                            <Grid
                                                item
                                                xs={1}
                                                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
                                            >
                                                <Tooltip
                                                    title='Delete department from user.'
                                                >
                                                    <IconButton
                                                        aria-label='close'
                                                        onClick={() => handleDeleteDepartment(department.key)}
                                                        disabled={disableButtons}
                                                    >
                                                        <RemoveIcon style={{ color: '#ff0000' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>

                                        </React.Fragment>
                                    ))}
                                    {user?.invites?.map((department, index) => (
                                        <React.Fragment
                                            key={department.departmentId}
                                        >
                                            <GeneralDropdownField
                                                disabled
                                                options={departmentOptions}
                                                width={6}
                                                hideLabel={index !== 0}
                                                label={`Pending Departments *`}
                                                placeholder='Select department...'
                                                statePath={`user.invites.${index}.departmentId`}
                                                value={user.invites[index].departmentId}
                                            />
                                            <GeneralDropdownField
                                                disabled
                                                options={UserRolesInDepartment}
                                                width={true}
                                                hideLabel={index !== 0}
                                                label='Role *'
                                                placeholder='Select user role...'
                                                statePath={`user.invites.${index}.role`}
                                                value={user.invites[index].role}
                                            />
                                            <Grid
                                                item
                                                xs={1}
                                                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
                                            >
                                                <Tooltip
                                                    title='Accept department invite for user.'
                                                >
                                                    <IconButton
                                                        aria-label='close'
                                                        onClick={() => handleSelectInvite(user.invites[index].departmentId)}
                                                        disabled={disableButtons}
                                                    >
                                                        <AddIcon style={{ color: '#00b300' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <Button
                                            onClick={handleAddDepartment}
                                            disabled={disableButtons}
                                            color='primary'
                                            variant='outlined'
                                        >
                                            {user.departments?.length ? 'Add Another Department' : 'Add Department'}
                                        </Button>
                                    </Grid>
                                </>
                            }
                            {!disabled && user.type === 'dispatcher' &&
                                <GeneralDropdownField
                                    options={departmentOptions}
                                    format='multiple'
                                    width={12}
                                    label='Departments *'
                                    placeholder='Select Departments...'
                                    statePath={`user.departments`}
                                    value={formatUserDeparmentsListForDropdown(user.departments)}
                                    handleInputChange={handleDispatcherDepartmentsChange}
                                    error={typeof errors.user.departments === 'string' && errors.user.departments}
                                />
                            }
                            {!disabled && user.type === 'dispatcher' && !!user?.invites?.length &&
                                <GeneralDropdownField
                                    disabled
                                    options={departmentOptions}
                                    format='multiple'
                                    width={12}
                                    label='Pending Departments *'
                                    statePath={`user.invites`}
                                    value={formatUserDeparmentsListForDropdown(user.invites)}
                                />
                            }
                            {disabled &&
                                <GeneralDropdownField
                                    disabled={specificDepartmentRole === 'dispatcher' ? true : false}
                                    options={specificDepartmentRole === 'dispatcher' ? DispatcherRolesInDepartment : !user.phoneNumberOnly ? UserRolesInDepartment : UserRolesInDepartment.filter(option => option.value === 'subscriber')}
                                    width={12}
                                    label='Department Role *'
                                    placeholder='Select a role...'
                                    statePath={null}
                                    value={specificDepartmentRole}
                                    handleInputChange={handleSpecificDepartmentRoleChange}
                                />
                            }
                            {disabled && specificDepartmentRole !== 'dispatcher' &&
                                <GeneralDropdownField
                                    options={EditMapsPermissionOptions}
                                    width={12}
                                    label='Edit Maps'
                                    placeholder='Maps edit permissions...'
                                    statePath={null}
                                    value={specificDepartmentEditMapPermissions ?? false}
                                    handleInputChange={handleSpecificDepartmentEditMapPermissions}
                                />
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {!disabled && user.activated && user.deactivated &&
                            <Button
                                onClick={() => this.updateUser('reactivate')}
                                disabled={disableButtons}
                                color='secondary'
                                variant='contained'
                                style={{ backgroundColor: '#00b300' }}
                            >
                                {loaders.reactivateUser &&
                                    <CircularProgress
                                        size={18}
                                        style={{ marginRight: 8 }}
                                    />
                                }
                                Re-enable
                            </Button>
                        }
                        {!disabled && user.activated && !user.deactivated &&
                            <Button
                                onClick={() => this.updateUser('deactivate')}
                                disabled={disableButtons}
                                color='secondary'
                                variant='contained'
                                style={{ backgroundColor: '#ff0000' }}
                            >
                                {loaders.deactivateUser &&
                                    <CircularProgress
                                        size={18}
                                        style={{ marginRight: 8 }}
                                    />
                                }
                                Disable
                            </Button>
                        }
                        {(!disabled || (specificDepartmentRole !== 'dispatcher')) &&
                            <div
                                style={{ display: 'flex', marginLeft: 'auto' }}
                            >
                                {deleteUser &&
                                    <Button
                                        disabled={disableButtons}
                                        onClick={this.openConfirmDeletion}
                                        color='secondary'
                                        variant='contained'
                                        style={{ backgroundColor: '#ff0000', marginRight: 4 }}
                                    >
                                        {loaders.deleteUser &&
                                            <CircularProgress
                                                size={18}
                                                style={{ marginRight: 8 }}
                                            />
                                        }
                                        {disabled ? "Remove" : "Delete"}
                                    </Button>
                                }
                                <Button
                                    disabled={disableButtons}
                                    onClick={this.updateUser}
                                    variant='contained'
                                    color='secondary'
                                >
                                    {loaders.updateUser &&
                                        <CircularProgress
                                            size={18}
                                            style={{ marginRight: 8 }}
                                        />
                                    }
                                    Update
                                </Button>
                            </div>
                        }
                    </DialogActions>
                </Dialog>
                <ActionConfirmation
                    open={confirmDeletion}
                    onClose={this.closeConfirmDeletion}
                    title={`Are you sure you want to ${disabled ? "remove" : "delete"} this User?`}
                    onConfirmAction={deleteUser}
                />
            </>
        )
    }
}

export default EditUserModal;
