import React from 'react'
import { Grid, IconButton, Button, Tooltip, Typography } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
// Components
import GeneralTextField from '../../../general/TextField'

const FacebookComponent = ({
    handleInputChange,
    department,
    loaders,
    handleAddFacebookPage,
    handleDeleteFacebookPage
}) => {
    return (
        <Grid
            container
            xs={12}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}
        >
            <Typography
                variant='h6'
                style={{ width: '100%', marginTop: 12, marginBottom: 12 }}
                gutterBottom
            >
                Facebook Pages
            </Typography>
            <Grid
                xs={12}
                spacing={2}
                container
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', marginTop: 12, marginBottom: 12 }}
            >
                {department.facebookPages?.map(({ pageId, pageName, key }, index) => (
                    <Grid
                        key={key}
                        container
                        spacing={2}
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <GeneralTextField
                            width={5}
                            label={index === 0 ? 'Page ID' : null}
                            placeholder='Facebook page id to receive notifications...'
                            statePath={`department.facebookPages.${index}.pageId`}
                            value={pageId}
                            handleInputChange={handleInputChange}
                        />
                        <GeneralTextField
                            width={5}
                            label={index === 0 ? 'Page Name' : null}
                            placeholder='Facebook page name...'
                            statePath={`department.facebookPages.${index}.pageName`}
                            value={pageName}
                            handleInputChange={handleInputChange}
                        />
                        <Tooltip
                            title='Delete facebook page from department.'
                        >
                            <IconButton
                                aria-label='close'
                                onClick={() => handleDeleteFacebookPage(key)}
                                disabled={loaders.createNewDepartment}
                            >
                                <RemoveIcon style={{ color: '#ff0000' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                ))}
                <Grid
                    item
                    xs={12}
                    style={{ marginTop: 16, }}
                >
                    <Button
                        onClick={handleAddFacebookPage}
                        disabled={loaders.createNewDepartment}
                        color='primary'
                        variant='outlined'
                    >
                        {department.facebookPages?.length ? 'Add Another Page' : 'Add Page'}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FacebookComponent;
