// React
import React from 'react'
// Material UI
import { Grid, Typography, withStyles } from '@material-ui/core'
// Utilities
import theme from '../../utilities/theme'
import moment from 'moment'

const getChatTime = (mongoTimestamp) => {
    return moment(mongoTimestamp).local().format("HH:mm")
}


const Message = ({ message, user, onViewAttachments }) => {
    const renderImage = (imagePath) => {
        if (imagePath) return (
            <img
                style={{ flex: 1, height: '60%', width: '60%', marginRight: 8, backgroundColor: theme.palette.primary.background, color: theme.palette.primary.dark, cursor: 'pointer', marginTop: 4 }}
                src={imagePath}
                alt="FireTEXT"
                onClick={() => onViewAttachments(imagePath)}
            />
        )
        return null
    }

    if (message.userId === user._id) {
        return (
            <Grid
                container
                direction='column'
                alignItems='flex-start'
                key={message._id}
                xs={12}
            >
                <Grid
                    container
                    direction='column'
                    key={message._id}
                    xs={12}
                    style={{ marginBottom: 16, background: '#e2fec9', paddingLeft: 8, marginLeft: 'auto', maxWidth: '60%', borderRadius: 2, borderBottomRightRadius: 14 }}
                >
                    {renderImage(message.imagePath)}
                    <Typography
                        style={{ marginTop: 4, maxWidth: '100%', wordWrap: 'break-word', color: 'black', fontSize: 14 }}
                    >
                        {message.message}
                    </Typography>
                    <Typography
                        style={{ fontSize: 12, marginLeft: 'auto', marginRight: 12, color: 'black' }}
                    >
                        {getChatTime(message.createdAt)}
                    </Typography>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid
                container
                direction='column'
                key={message._id}
                xs={12}
                style={{ marginBottom: 16, background: '#f6f4f4', paddingLeft: 8, marginRight: 'auto', maxWidth: '60%', borderRadius: 2, borderBottomLeftRadius: 14 }}
            >
                <Typography
                    variant='subtitle2'
                    style={{ fontWeight: 'bold', color: '#055f59', fontSize: 16 }}
                >
                    {message.userName}
                </Typography>
                {renderImage(message.imagePath)}
                <Typography
                    style={{ marginTop: 4, maxWidth: '100%', wordWrap: 'break-word', color: 'black', fontSize: 14 }}
                >
                    {message.message}
                </Typography>
                <Typography
                    style={{ fontSize: 12, marginLeft: 'auto', marginRight: 12, color: 'black' }}
                >
                    {getChatTime(message.createdAt)}
                </Typography>
            </Grid>
        )
    }
}

export default withStyles(null, { withTheme: true })(Message)
