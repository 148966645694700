// React
import React from 'react'
// React Router
import { withRouter } from 'react-router-dom'
// Material UI
import { Typography, withStyles, Grid, Button, CircularProgress, Paper } from '@material-ui/core'
// Utilities
import axios from 'axios'
import theme from '../../../utilities/theme'
import { UserRolesMap } from '../../../utilities/Options'
import { withSnackbar } from 'notistack'
import { OpenSnackBar } from '../../../utilities/handleSnackBar'

const findDepartment = (departmentObjects, departmentId) => {
    return departmentObjects.find(department => String(department._id) === String(departmentId)) || null
}

const findInvitor = (invitedByObjects, invitedBy) => {
    return invitedByObjects.find(user => String(user._id) === String(invitedBy)) || null
}

const initialState = {
    action: null,
    loaders: {
        processDepartmentInvite: false
    }
}

class DepartmentInvite extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...initialState,
            invite: props.invite
        }
    }

    processInvite = async (e, departmentId, action) => {
        e?.stopPropagation?.()
        const { user, accessToken } = this.props
        const { loaders } = this.state


        if (!loaders.processDepartmentInvite) {
            this.setState(prevState => ({
                action,
                loaders: {
                    ...prevState.loaders,
                    processDepartmentInvite: true
                }
            }))

            try {
                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                const body = {
                    action,
                    departmentId
                }
                await axios.patch(`/api/users/${user._id}/department-invites/`, body, config)

                this.setState(prevState => ({
                    action: null,
                    loaders: {
                        ...prevState.loaders,
                        processDepartmentInvite: false
                    }
                }))
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, `Invite ${action === 'accept' ? 'accepted' : 'declined'} successfully`, "success")
            } catch (error) {
                this.setState(prevState => ({
                    action: null,
                    loaders: {
                        ...prevState.loaders,
                        processDepartmentInvite: false
                    }
                }))
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Something went wrong!", "error")
            }
        }
    }

    render() {
        const { user, invite } = this.props
        const { action, loaders } = this.state

        return (
            <Paper
                key={invite?.departmentId}
                style={{ minWidth: '450px', padding: 16, marginBottom: 16, cursor: 'pointer', background: theme.palette.primary.dark, color: theme.palette.primary.light }}
                onClick={() => this.props.history.push('/department-invites')}
            >
                <Grid item container spacing={2} xs={12}>
                    <Grid
                        container
                        xs={4}
                        style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: findDepartment([...(user?.departmentObjects || []), ...(user?.inviteObjects || [])], invite?.departmentId)?.color || theme.palette.primary.main, }}
                    >
                        <Typography
                            style={{ color: 'inherit' }}
                        >
                            {findDepartment([...(user?.departmentObjects || []), ...(user?.inviteObjects || [])], invite?.departmentId)?.name || ''}
                        </Typography>
                    </Grid>

                    <Grid item xs={8} container>
                        <Grid item xs={12} container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography variant="body2" gutterBottom>
                                    Invited as: {UserRolesMap[invite?.role]}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Invited by: {`${findInvitor(user.invitedByObjects, invite?.invitedBy)?.firstName} ${findInvitor(user.invitedByObjects, invite?.invitedBy)?.lastName}` || ''}
                                </Typography>
                                <Typography variant="body2">
                                    Expires at: {new Date(invite?.expires).toDateString()}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container style={{ flex: 1, justifyContent: 'space-evenly' }}>
                                <Button
                                    size="medium"
                                    onClick={(e) => this.processInvite(e, invite?.departmentId, 'accept')}
                                    disabled={loaders.processDepartmentInvite}
                                    style={{ color: '#fff' }}
                                >
                                    {loaders.processDepartmentInvite && action === 'accept' &&
                                        <CircularProgress
                                            size={18}
                                            style={{ marginRight: 8, color: '#fff' }}
                                        />
                                    }
                                    Accept
                                </Button>
                                <Button
                                    size="medium"
                                    onClick={(e) => this.processInvite(e, invite?.departmentId, 'decline')}
                                    disabled={loaders.processDepartmentInvite}
                                    style={{ color: '#fff' }}
                                >
                                    {loaders.processDepartmentInvite && action === 'decline' &&
                                        <CircularProgress
                                            size={18}
                                            style={{ marginRight: 8, color: '#fff' }}
                                        />
                                    }
                                    Decline
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}

export default withRouter(withStyles(null, { withTheme: true })(withSnackbar(DepartmentInvite)))