// React
import React from 'react'
// Material UI
import { Grid, IconButton, withStyles, Tooltip, Button, CircularProgress } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import FilterListIcon from '@material-ui/icons/FilterList'
import { withSnackbar } from 'notistack'
// Components
import CreateNewUserModal from './CreateNewUserModal/index'
import EditUserModal from './EditUserModal'
import VerifyUserModal from './VerifyUserModal'
import GeneralTextField from '../../general/TextField'
import UsersFilterModal from './UsersFilterModal'
import UsersTable from './UsersTable'
// Utilities
import axios from 'axios'
import _ from 'underscore'
import { handleInputChange, handleErrors } from '../../../utilities/handleChange'
import { Email, ValidateAndTransformToBackendPhoneNumber } from '../../../utilities/Validate'
import { OpenSnackBar } from '../../../utilities/handleSnackBar'
import { SPECIFIC_DEPARTMENT } from './AlertSettings'
import { UserRolesInDepartment } from '../../../utilities/Options'


const initialState = {
    users: [],
    showVerifyUserModal: false,
    selectedChannel: 'push',
    showVerificationCode: false,
    info: {
        search: '',
        page: 1, //currentPage
        limit: 25, //itemsPerPage
        pagination: {
            "total": 0,
            "limit": 25,
            "currentPage": 1,
            "totalPages": 0,
            "previous": null,
            "next": null
        }
    },
    filteredUsers: [],
    departments: [],
    user: {
        email: null,
        phone: null,
        type: null,
        departments: [],
        verificationCode: null,
        verificationTime: null,
        verificationChannel: null,
        deactivated: false,
        phoneNumberOnly: false,
        alerts: {
            chat: {
                type: null,
                departmentIds: null
            },
            dispatch: {
                type: null,
                departmentIds: null
            },
            weather: {
                type: null
            }
        },
        notifications: {
            sms: 2,
            phoneCall: 1,
            push: 1,
            email: 1,
            availability: {
                type: "always",
                timeslots: []
            }
        }
    },
    errors: {
        user: {
            departments: [],
            alerts: {},
            notifications: {}
        },
        info: {

        }
    },
    modals: {
        createNewUser: false,
        editUser: false,
        filterModal: false
    },
    loaders: {
        getUsers: false,
        getDepartments: false,
        createNewUser: false,
        updateUser: false,
        reactivateUser: false,
        deactivateUser: false,
        deleteUser: false,
        forgotPassword: false,
        resendActivationLink: false,
        sendTestSms: false
    },
    activeFilters: {
        departments: [],
        type: []
    },
    filterOptions: {
        userTypeFilter: {
            name: 'User Types',
            key: 'type',
            options: [
                { text: 'Super Admin', value: 'superAdmin' },
                { text: 'Department Admin', value: 'departmentAdmin' },
                { text: 'Subscriber', value: 'subscriber' },
                { text: 'Dispatcher', value: 'dispatcher' }
            ]
        },
        departmentsFilter: {
            name: 'Departments',
            key: 'departments',
            options: []
        }
    }
}
class Users extends React.Component {
    state = initialState

    // Life cycle functions
    componentDidMount = async () => {
        await this.getUsers()
        await this.getDepartments()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.open && !this.props.open) {
            this.setState({ showVerificationCode: false })
        }
    }

    componentWillUnmount = () => {
        this.props.closeSnackbar()
    }

    onUserTypeChange = (type) => {
        let departments = this.state.user?.departments || []
        let invites = this.state.user?.invites || []
        let errors = this.state.errors
        switch (type) {
            case "superAdmin":
                departments = []
                invites = []
                break
            case "user":
                departments = (this.state.user?.departments || []).map(dept => ({ ...dept, role: "subscriber" }))
                if (!departments?.length) {
                    departments = [{ departmentId: null, role: null }]
                    errors = {
                        ...this.state.errors,
                        user: {
                            ...this.state.errors.user,
                            departments: [{}]
                        }
                    }
                }
                invites = (this.state.user?.invites || []).map(dept => ({ ...dept, role: "subscriber" }))
                break
            case "dispatcher":
                departments = (this.state.user?.departments || []).filter(dept => dept?.departmentId).map(dept => ({ ...dept, role: "dispatcher" }))
                invites = (this.state.user?.invites || []).map(dept => ({ ...dept, role: "dispatcher" }))
                break
            default:
                return
        }
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                departments,
                invites
            },
            errors
        }))
    }

    // API
    getUsers = async () => {
        const { loaders, info } = this.state
        const { page, limit, search } = info

        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            },
            params: {
                page,
                limit,
                search
            }
        }

        if (!loaders.getUsers) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getUsers: true } }))
            try {
                const getUsersResponse = await axios.get('/api/users', config)

                if (getUsersResponse && getUsersResponse.data && getUsersResponse.data.success) {
                    let users = [...getUsersResponse.data.users]
                    let pagination = getUsersResponse.data.pagination || initialState.info.pagination
                    this.setState(prevState => ({
                        users,
                        info: { ...prevState.info, pagination },
                        loaders: { ...prevState.loaders, getUsers: false }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getUsers: false }
                }))
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
            }
        }
    }

    getDepartments = async () => {
        const { loaders } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.getDepartments) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: true } }))
            try {
                const getDepartmentsResponse = await axios.get('/api/departments?type=all', config)
                if (getDepartmentsResponse && getDepartmentsResponse.data && getDepartmentsResponse.data.success) {
                    let departments = []
                    _.each(getDepartmentsResponse.data.departments, department => {
                        departments.push({
                            ...department,
                            value: department._id,
                            text: `${department.name} ${department.agency ?? ''}`
                        })
                    })
                    this.setState(prevState => ({
                        departments: departments.sort((a, b) => a.name.localeCompare(b.name)),
                        loaders: { ...prevState.loaders, getDepartments: false }
                    }), () => this.formatFiltersArray())
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getDepartments: false }
                }))
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
            }
        }
    }

    // General
    handleErrors = (requestType) => {
        const { user } = this.state

        let fields = []

        if (requestType === 'create') {
            fields = [
                {
                    path: 'user.email',
                    type: 'string',
                    shouldCheck: user.type !== 'user' || (user.type === 'user' && !user.phone),
                    validation: Email(user.email),
                    validationMessage: "Invalid email"
                },
                {
                    path: 'user.phone',
                    type: 'string',
                    shouldCheck: user.type === 'user' && !user.email,
                    validation: !!ValidateAndTransformToBackendPhoneNumber(user.phone),
                    validationMessage: "Invalid phone"
                },
                {
                    path: 'user.type',
                    type: 'string',
                    shouldCheck: true
                },
                {
                    path: 'user.departments',
                    type: 'objectArray',
                    keys: {
                        departmentId: {
                            type: 'string',
                            shouldCheck: true
                        },
                        role: {
                            type: 'string',
                            shouldCheck: true
                        }
                    },
                    shouldCheck: user.type !== 'superAdmin'
                },
                {
                    path: 'user.firstName',
                    type: 'string',
                    shouldCheck: user.phoneNumberOnly === true
                },
                {
                    path: 'user.lastName',
                    type: 'string',
                    shouldCheck: user.phoneNumberOnly === true
                },
            ]
        }
        else if (requestType === 'update') {
            fields = [
                {
                    path: 'user.email',
                    type: 'string',
                    shouldCheck: !user.phoneNumberOnly,
                    validation: Email(user.email),
                    validationMessage: "Invalid email"
                },
                {
                    path: 'user.phone',
                    type: 'string',
                    shouldCheck: true,
                    validation: !!ValidateAndTransformToBackendPhoneNumber(user.phone),
                    validationMessage: "Invalid phone"
                },
                {
                    path: 'user.firstName',
                    type: 'string',
                    shouldCheck: true
                },
                {
                    path: 'user.lastName',
                    type: 'string',
                    shouldCheck: true
                },
                {
                    path: 'user.type',
                    type: 'string',
                    shouldCheck: true
                },
                {
                    path: 'user.departments',
                    type: 'objectArray',
                    keys: {
                        departmentId: {
                            type: 'string',
                            shouldCheck: true
                        },
                        role: {
                            type: 'string',
                            shouldCheck: true
                        }
                    },
                    shouldCheck: user.type !== 'superAdmin' && user.departments?.length > 0
                },
                {
                    path: 'user.alerts.chat.departmentIds',
                    type: 'stringArray',
                    shouldCheck: user.alerts.chat.type === SPECIFIC_DEPARTMENT
                },
                {
                    path: 'user.alerts.dispatch.departmentIds',
                    type: 'stringArray',
                    shouldCheck: user.alerts.dispatch.type === SPECIFIC_DEPARTMENT
                }
            ]
        }
        else if (requestType === 'updateInactiveUser') {
            fields = [{
                path: 'user.type',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'user.departments',
                type: 'objectArray',
                keys: {
                    departmentId: {
                        type: 'string',
                        shouldCheck: true
                    },
                    role: {
                        type: 'string',
                        shouldCheck: true
                    }
                },
                shouldCheck: user.type !== 'superAdmin' && user.departments?.length > 0
            }
            ]
        }

        let errors = { ...this.state.errors }
        let frontEndErrors = handleErrors(this.state, errors, fields)
        return frontEndErrors
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleUserTypeInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state), () => { this.onUserTypeChange(data.value) })
    }

    handleFilterModal = (open) => {
        this.setState(prevState => ({
            modals: {
                ...prevState.modals,
                filterModal: open
            }
        }))
    }

    handleModal = (modal, user) => {
        const { modals } = this.state

        switch (modal) {
            case 'createNewUser':
                // Reset user if open, set type if not super admin
                return this.setState(prevState => ({
                    modals: { ...prevState.modals, [modal]: !prevState.modals[modal] },
                    user: initialState.user,
                    errors: initialState.errors
                }))
            case 'editUser':
                // Reset user if open, set user details if not open, and close modal.
                if (modals.editUser) {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, editUser: false },
                        user: initialState.user,
                        errors: initialState.errors
                    }))
                } else {
                    return this.setState(prevState => ({
                        modals: { ...prevState.modals, editUser: true },
                        user: {
                            ...user,
                            departments: user.departments.map((dept, idx) => ({
                                ...dept,
                                key: idx
                            }))
                        },
                        errors: {
                            ...initialState.errors,
                            user: {
                                ...initialState.errors.user,
                                departments: user.departments.map(department => { return { deactivated: null, departmentId: null, role: null } }),
                                alerts: {
                                    chat: {},
                                    dispatch: {}
                                }
                            }
                        }
                    }))
                }
            default:
                return this.setState(prevState => ({ modals: { ...prevState.modals, [modal]: !prevState.modals[modal] } }))
        }
    }

    // Filter and search functions
    formatFiltersArray = () => {
        const { departments } = this.state

        let filterOptions = {
            userTypeFilter: {
                name: 'User Types',
                key: 'type',
                options: [
                    { text: 'Super Admin', value: 'superAdmin' },
                    { text: 'Department Admin', value: 'departmentAdmin' },
                    { text: 'Subscriber', value: 'subscriber' },
                    { text: 'Dispatcher', value: 'dispatcher' }
                ]
            },
            departmentsFilter: {
                name: 'Departments',
                key: 'departments',
                options: departments
            }
        }

        this.setState({ filterOptions })
    }

    searchUsers = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page: 1
            }
        }), this.getUsers)
    }

    resetSearch = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                search: ''
            }
        }), this.getUsers)
    }

    setActiveFilters = (key, value) => {
        const { activeFilters } = this.state

        let updatedActiveFilters = { ...activeFilters }
        if (key === 'type') {
            if (activeFilters[key].includes(value)) {
                updatedActiveFilters = {
                    ...activeFilters,
                    [key]: activeFilters[key].filter(existingValue => value !== existingValue)
                }
            } else {
                updatedActiveFilters = {
                    ...activeFilters,
                    [key]: activeFilters[key].concat(value)
                }
            }
        }
        else if (key === 'departments') {
            updatedActiveFilters = {
                ...activeFilters,
                [key]: value
            }
        }

        this.setState({
            activeFilters: updatedActiveFilters
        })
    }

    resetFilters = () => {
        this.setState(prevState => ({
            activeFilters: initialState.activeFilters
        }))
    }

    checkIfFiltersSelected = () => {
        const { activeFilters } = this.state
        let someFiltersSelected = false
        _.mapObject(activeFilters, (val, key) => {
            if (val.length > 0) {
                someFiltersSelected = true
            }
        })
        return someFiltersSelected
    }

    filterUsers = () => {
        const { activeFilters, users } = this.state

        let someFiltersSelected = false
        _.mapObject(activeFilters, (val, key) => {
            if (!!val?.length) {
                someFiltersSelected = true
            }
        })

        let filteredUsers = users

        if (someFiltersSelected) {
            let departmentIdsFilter = activeFilters.departments
            if (departmentIdsFilter.length > 0) {
                filteredUsers = filteredUsers.filter((user) => {
                    let userDepartmentIds = user?.departments?.map(dept => dept.departmentId) || []
                    let userInvitedDepartmentIds = user?.invites?.map(dept => dept.departmentId) || []
                    for (let currentDepartmentId of departmentIdsFilter) {
                        if (userDepartmentIds.includes(currentDepartmentId) || userInvitedDepartmentIds.includes(currentDepartmentId)) return true
                    }
                    return false
                }).map(u => ({
                    ...u,
                    departments: u.departments?.filter(dept => activeFilters.departments.includes(dept.departmentId)),
                    invites: u.invites?.filter(dept => activeFilters.departments.includes(dept.departmentId))
                }))
            }

            let userTypeFilters = activeFilters.type
            if (userTypeFilters.length > 0) {
                filteredUsers = filteredUsers.filter((user) => {
                    for (let roleOrType of userTypeFilters) {
                        if (user.type === roleOrType || !!user.departments.filter(u => u.role === roleOrType)?.length) return true
                    }
                    return false
                })
            }
        }

        return filteredUsers
    }

    // CRUD
    createUser = async () => {
        let { loaders, user } = this.state

        if (!loaders.createNewUser) {

            const { success, errors } = this.handleErrors('create')
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, createNewUser: true } }))
                try {
                    const { accessToken } = this.props
                    const config = {
                        headers: {
                            Authorization: accessToken
                        }
                    }
                    let formattedUser = !user.phoneNumberOnly ?
                        {
                            ...user,
                            phone: ValidateAndTransformToBackendPhoneNumber(user.phone)
                        }
                        :
                        {
                            phoneNumberOnly: user.phoneNumberOnly,
                            email: null,
                            phone: ValidateAndTransformToBackendPhoneNumber(user.phone),
                            departments: user.departments,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            notifications: user.notifications,
                            type: user.type
                        }
                    const createUserResponse = await axios.post('/api/users', formattedUser, config) // requestType: createAccount sends the fully formatted email where no request just sends an email confirmation link
                    if (createUserResponse && createUserResponse.data && createUserResponse.data.success) {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, createNewUser: false }
                        }))
                        this.handleModal('createNewUser')
                        this.getUsers()
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "User created successfully", "success")
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, createNewUser: false }
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            } else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }
        }
    }

    updateUser = async (type) => {
        const { user, loaders } = this.state

        if (loaders.updateUser || loaders.deactivateUser || loaders.reactivateUser) return

        this.setState(prevState => ({
            loaders: {
                ...prevState.loaders,
                deactivateUser: type === 'deactivate',
                reactivateUser: type === 'reactivate',
                updateUser: type !== 'reactivate' && type !== 'deactivate'
            }
        }))

        let formattedUser = null
        let errors = null
        let updateInactiveUser = false

        const { success, errors: updateErrors } = this.handleErrors('update')
        if (success) {
            formattedUser = {
                ...user,
                phone: ValidateAndTransformToBackendPhoneNumber(user.phone),
                deactivated: type === 'deactivate' ? true : type === 'reactivate' ? false : user.deactivated,
                alerts: {
                    ...user.alerts,
                    chat: {
                        ...user.alerts.chat,
                        departmentIds: (Number(user.alerts.chat.type) !== 3) ? [] : user.alerts.chat.departmentIds
                    },
                    dispatch: {
                        ...user.alerts.dispatch,
                        departmentIds: (Number(user.alerts.dispatch.type) !== 3) ? [] : user.alerts.dispatch.departmentIds
                    },
                },
                notifications: {
                    ...user.notifications,
                    availability: {
                        ...user.notifications.availability,
                        timeslots: (user.notifications.availability.type !== 'custom') ? [] : user.notifications.availability.timeslots
                    }
                }
            }
        }
        else {
            errors = updateErrors

            if (!user.activated) {
                const { success: validateDepartmentsSuccess, errors: validateDepartmentsErrors } = this.handleErrors('updateInactiveUser')
                if (validateDepartmentsSuccess) {
                    updateInactiveUser = true
                    formattedUser = {
                        departments: user.departments,
                        invites: user.invites ?? [],
                        type: user.type,
                        email: user.email ?? null,
                        phone: ValidateAndTransformToBackendPhoneNumber(user.phone)
                    }
                }
                else {
                    errors = validateDepartmentsErrors
                }
            }
        }

        if (formattedUser) {
            try {
                const config = {
                    headers: { Authorization: this.props.accessToken },
                    params: { updateInactiveUser }
                }
                let updateUserResponse = await axios.put(`/api/users/${user._id}`, formattedUser, config)

                if (updateUserResponse.data.success) {
                    if (updateUserResponse.data?.responseMessage) {
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, updateUserResponse.data.responseMessage.text, updateUserResponse.data.responseMessage.type)
                        if (updateUserResponse.data.responseMessage.type === 'User resetted') {
                            this.setState(prevState => ({
                                modals: { ...prevState.modals, editUser: false },
                                user: initialState.user,
                                errors: initialState.errors,
                                loaders: {
                                    ...prevState.loaders,
                                    deactivateUser: false,
                                    reactivateUser: false,
                                    updateUser: false
                                }
                            }), this.getUsers)
                            return false
                        }
                    }
                    else {
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "User updated successfully", "success")
                    }
                    this.setState(prevState => ({
                        user: {
                            ...updateUserResponse.data.user,
                            departments: user.departments.map((dept, idx) => ({
                                ...dept,
                                key: idx
                            }))
                        },
                        loaders: {
                            ...prevState.loaders,
                            deactivateUser: false,
                            reactivateUser: false,
                            updateUser: false
                        }
                    }), this.getUsers)

                    return true
                }
            }
            catch (e) {
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                this.setState(prevState => ({
                    loaders: {
                        ...prevState.loaders,
                        deactivateUser: false,
                        reactivateUser: false,
                        updateUser: false
                    }
                }))
                return false
            }
        }

        alert('Please fill out all of the required fields.')
        this.setState(prevState => ({
            errors,
            loaders: {
                ...prevState.loaders,
                deactivateUser: false,
                reactivateUser: false,
                updateUser: false
            }
        }))
        return false

    }

    handleSelectInvite = (departmentId) => {
        const acceptedInvite = this.state.user.invites.find(invite => invite.departmentId === departmentId)

        if (!acceptedInvite) return

        const acceptedDepartment = {
            departmentId: acceptedInvite.departmentId,
            role: acceptedInvite.role,
            deactivated: false,
            key: Date.now()
        }
        return this.setState(prevState => ({
            user: {
                ...prevState.user,
                departments: [
                    ...prevState.user.departments,
                    acceptedDepartment
                ],
                invites: prevState.user.invites.filter(invite => invite.departmentId !== departmentId)
            },
            errors: {
                ...prevState.errors,
                user: {
                    ...prevState.errors.user,
                    departments: [...prevState.errors.user.departments, {}]
                }
            }
        }))
    }

    handleAddDepartment = () => {
        return this.setState(prevState => ({
            user: {
                ...prevState.user,
                departments: [
                    ...prevState.user.departments,
                    {
                        departmentId: null,
                        role: UserRolesInDepartment.find(type => type.text === 'Subscriber')?.value,
                        key: Date.now()
                    }
                ]
            },
            errors: {
                ...prevState.errors,
                user: {
                    ...prevState.errors.user,
                    departments: [...prevState.errors.user.departments, {}]
                }
            }
        }))
    }

    handleDeleteDepartment = (key) => {
        let index = this.state.user.departments?.findIndex?.(dept => dept?.key === key)
        index = index > -1 ? index : 0

        return this.setState(prevState => ({
            user: {
                ...prevState.user,
                departments: prevState.user.departments.filter((dept) => dept.key !== key)
            },
            errors: {
                ...prevState.errors,
                user: {
                    ...prevState.errors.user,
                    departments: prevState.errors.user.departments.filter((department, departmentIndex) => departmentIndex !== index)
                }
            }
        }))
    }

    handleUserType = (type) => {
        switch (type) {
            case 'dispatcher':
                return this.setState(prevState => ({
                    user: { ...prevState.user, type, departments: [] },
                    errors: {
                        ...prevState.errors,
                        user: {
                            ...prevState.errors.user,
                            departments: null
                        }
                    }
                }))
            case 'user':
                return this.setState(prevState => ({
                    user: {
                        ...prevState.user,
                        type,
                        departments: [{
                            departmentId: null,
                            role: UserRolesInDepartment.find(type => type.text === 'Subscriber')?.value,
                            key: Date.now()
                        }]
                    },
                    errors: {
                        ...prevState.errors,
                        user: {
                            ...prevState.errors.user,
                            departments: [{}]
                        }
                    }
                }))
            default:
                return this.setState(prevState => ({ user: { ...prevState.user, type } }))
        }
    }

    deleteUser = async () => {
        const { loaders, user } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.deleteUser) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, deleteUser: true } }))
            try {
                const deleteUserResponse = await axios.delete(`/api/users/${user._id}`, config)
                if (deleteUserResponse && deleteUserResponse.data && deleteUserResponse.data.success) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, deleteUser: false }
                    }))
                    this.handleModal('editUser')
                    this.getUsers()
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "User deleted successfully", "success")
                }
            } catch (e) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, deleteUser: false }
                }))
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
            }
        }
    }

    handleRemoveDepartment = () => {
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                departments: prevState.user.departments.filter((_, i) => i !== prevState.errors.user.departments.length - 1)
            },
            errors: {
                ...prevState.errors,
                user: {
                    ...prevState.errors.user,
                    departments: prevState.errors.user.departments.filter((_, i) => i !== prevState.errors.user.departments.length - 1)
                }
            }
        }))
    }

    handleForgotPassword = async (user) => {
        if (!this.state.loaders.forgotPassword) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, forgotPassword: true } }))

            try {
                const config = {
                    headers: {
                        Authorization: this.props.accessToken
                    }
                }

                const userInformation = {
                    email: user.email
                }
                let forgotPasswordResponse = await axios.post(`/api/users/reset-password`, { ...userInformation }, config)

                if (forgotPasswordResponse && forgotPasswordResponse.data && forgotPasswordResponse.data.success) {
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, `Sent reset password link to ${user.email}`, "success")
                }

            } catch (error) {
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Failed to send reset password link", "error")
            }

            this.setState(prevState => ({ loaders: { ...prevState.loaders, forgotPassword: false } }))
        }
    }

    handleSendTestSms = async (userId) => {
        if (this.state.loaders.sendTestSms) return
        if (!userId) return

        this.setState(prevState => ({ loaders: { ...prevState.loaders, sendTestSms: true } }))

        try {
            const config = {
                headers: {
                    Authorization: this.props.accessToken
                }
            }

            let sendTestSmsResponse = await axios.post(`/api/users/${userId}/send-sms`, null, config)

            if (sendTestSmsResponse && sendTestSmsResponse.data && sendTestSmsResponse.data.success) {
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, `SMS delivered to the carrier.`, "success")
            }

        } catch (error) {
            OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Failed to send SMS", "error")
        }

        this.setState(prevState => ({ loaders: { ...prevState.loaders, sendTestSms: false } }))
    }

    handleResendActivationLink = async (user) => {
        if (!this.state.loaders.resendActivationLink) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, resendActivationLink: true } }))
            try {
                const config = {
                    headers: {
                        Authorization: this.props.accessToken
                    }
                }

                const userInformation = {
                    userId: user._id
                }

                let resendActivationLinkResponse = await axios.post(`/api/users/resend-activation`, { ...userInformation }, config)

                if (!!resendActivationLinkResponse?.data?.success) {
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, `Resent activation link to ${user?.email ?? ''} ${user?.phone ?? ''}`, "success")
                }
            }
            catch (error) {
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Failed to resend activation link", "error")
            }
            this.setState(prevState => ({ loaders: { ...prevState.loaders, resendActivationLink: false } }))
        }
    }

    //pagination methods
    handleChangePage = (page) => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page
            }
        }), this.getUsers)
    }

    handleChangeRowsPerPage = (limit) => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page: 1,
                limit
            }
        }), this.getUsers)
    }

    handleGenerateCode = async () => {
        const { selectedChannel, user } = this.state
        const { accessToken } = this.props

        try {
            if (!user || !user._id) return

            const userId = user._id

            const config = {
                headers: {
                    Authorization: accessToken
                }
            }
            const response = await axios.post(`/api/users/${userId}/verification`, { channel: selectedChannel }, config)
            if (response.data.success) {
                this.setState({ showVerificationCode: true })
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Verification code sent successfully", "success")
                this.setState((prevState => ({
                    user: {
                        ...prevState.user,
                        verificationCode: response.data.verificationCode,
                        verificationTime: response.data.verificationTime,
                        verificationChannel: response.data.verificationChannel
                    },
                })), this.getUsers
                )
            }
        } catch (error) {
            OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
        }
    }

    handleChannelChange = (e, { value }) => {
        this.setState({ selectedChannel: value })
    }

    openVerifyUserModal = () => {
        this.setState({ showVerifyUserModal: true })
    }

    closeVerifyUserModal = () => {
        this.setState({ showVerifyUserModal: false })
        this.setState({ showVerificationCode: false })
        this.setState({ selectedChannel: 'push' })
    }

    render() {
        const { authenticatedUser } = this.props
        const { user, errors, modals, loaders, departments, info, activeFilters, filterOptions } = this.state
        const { search } = info

        return (
            <>
                <UsersFilterModal
                    open={modals.filterModal}
                    filterOptions={filterOptions}
                    activeFilters={activeFilters}
                    setActiveFilters={this.setActiveFilters}
                    filterName='users'
                    handleFilterModal={this.handleFilterModal}
                />
                {modals.createNewUser &&
                    <CreateNewUserModal
                        handleInputChange={this.handleInputChange}
                        handleAddDepartment={this.handleAddDepartment}
                        handleDeleteDepartment={this.handleDeleteDepartment}
                        handleUserType={this.handleUserType}
                        handleRemoveDepartment={this.handleRemoveDepartment}
                        handleClose={() => this.handleModal('createNewUser')}
                        createUser={this.createUser}
                        authenticatedUser={authenticatedUser}
                        user={user}
                        departments={departments}
                        errors={errors}
                        loaders={loaders}
                        open={modals.createNewUser}
                    />
                }
                {modals.editUser &&
                    <EditUserModal
                        handleAddDepartment={this.handleAddDepartment}
                        handleDeleteDepartment={this.handleDeleteDepartment}
                        handleForgotPassword={this.handleForgotPassword}
                        handleSendTestSms={this.handleSendTestSms}
                        handleResendActivationLink={this.handleResendActivationLink}
                        handleInputChange={this.handleInputChange}
                        handleUserTypeInputChange={this.handleUserTypeInputChange}
                        handleClose={() => this.handleModal('editUser')}
                        deleteUser={this.deleteUser}
                        handleSelectInvite={this.handleSelectInvite}
                        user={user}
                        departments={departments}
                        errors={errors}
                        loaders={loaders}
                        open={modals.editUser}
                        updateUser={this.updateUser}
                        authenticatedUser={authenticatedUser}
                        openVerifyUserModal={this.openVerifyUserModal}
                    />
                }
                <VerifyUserModal
                    open={this.state.showVerifyUserModal}
                    handleClose={this.closeVerifyUserModal}
                    user={user}
                    handleGenerateCode={this.handleGenerateCode}
                    showVerificationCode={this.state.showVerificationCode}
                    selectedChannel={this.state.selectedChannel}
                    handleChannelChange={this.handleChannelChange}

                />
                <Grid
                    container
                    item
                    xs={12}
                    style={{
                        display: 'flex', flex: 1, justifyContent: 'space-between', marginTop: 8,
                        borderRadius: 0,
                        marginBottom: 12
                    }}
                >
                    <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}
                    >
                        <Button
                            onClick={() => this.handleModal('createNewUser')}
                            variant='outlined'
                        >
                            <AddBoxIcon
                                style={{ marginRight: 8 }}
                            />
                            Add User
                        </Button>
                    </Grid>
                    <Grid
                        style={{ display: 'flex' }}
                        item
                    >
                        <GeneralTextField
                            format="search"
                            gridStyle={{ width: 350 }}
                            placeholder='Search users...'
                            statePath={`info.search`}
                            value={search}
                            handleInputChange={this.handleInputChange}
                            resetSearch={this.resetSearch}
                            onSearch={this.searchUsers}
                        />
                        <Tooltip
                            title='Filter users'
                        >
                            <IconButton
                                onClick={() => this.handleFilterModal(true)}
                            >
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        {
                            !!activeFilters.type?.length || !!activeFilters.departments.length ?
                                <Button
                                    variant='outlined'
                                    onClick={this.resetFilters}
                                    style={{ margin: '6px 12px' }}
                                >
                                    Clear Filters
                                </Button>
                                :
                                null
                        }
                    </Grid>
                </Grid>
                {loaders.getUsers || loaders.getDepartments ?
                    <Grid
                        item
                        xs={12}
                        style={{ height: '60vh', alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 24, marginBottom: 24 }}
                    >
                        <CircularProgress
                            size={36}
                        />
                    </Grid>
                    :
                    <UsersTable
                        users={this.filterUsers()}
                        loaders={loaders}
                        info={info}
                        handleChangePage={this.handleChangePage}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        handleModal={this.handleModal}
                        resetSearch={this.resetSearch}
                        resetFilters={this.resetFilters}
                        checkIfFiltersSelected={this.checkIfFiltersSelected}
                    />
                }
            </>
        )
    }
}

export default withSnackbar(withStyles(null, { withTheme: true })(Users))