// React
import React, { Component } from 'react'
import { Prompt } from "react-router-dom"
// Material UI
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, CircularProgress, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
// Components
import GeneralTextField from '../../general/TextField'
import GeneralSwitchField from '../../general/SwitchField'
import CallTypeIconsMenu from './callTypeIconsMenu'
import ActionConfirmation from '../../general/ActionConfirmation'

class EditCallTypeModal extends Component {
    state = {
        isCallTypeUnsaved: false,
        confirmDeletion: false
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.beforeunload)
    }

    componentDidUpdate(prevProps, prevState) {
        const { isCallTypeUnsaved } = this.state
        if (prevProps.callType !== this.props.callType && !isCallTypeUnsaved) {
            this.setState({ isCallTypeUnsaved: true })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.beforeunload)
    }

    beforeunload = (e) => {
        const { isCallTypeUnsaved } = this.state
        if (isCallTypeUnsaved) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    openConfirmDeletion = () => {
        this.setState({ confirmDeletion: true })
    }

    closeConfirmDeletion = () => {
        this.setState({ confirmDeletion: false })
    }

    handleClose = () => {
        const { isCallTypeUnsaved } = this.state
        if (isCallTypeUnsaved) {
            let shouldCloseModal = window.confirm(
                "Changes you made may not be saved. Are you sure you want to leave this page?"
            )
            if (!shouldCloseModal) return
            else this.setState({ isCallTypeUnsaved: false })
        }
        this.props.handleClose()
    }

    updateCallType = async () => {
        const response = await this.props.updateCallType()
        const { isCallTypeUnsaved } = this.state
        if (response && isCallTypeUnsaved) {
            this.setState({ isCallTypeUnsaved: false })
        }
    }

    render() {
        const { handleInputChange, deleteCallType, callType, errors, loaders, open, swapCallTypeIcons } = this.props
        const { isCallTypeUnsaved, confirmDeletion } = this.state

        return (
            <>
                <Prompt
                    when={isCallTypeUnsaved}
                    message={() =>
                        "Changes you made may not be saved. Are you sure you want to leave this page?"
                    }
                />
                <Dialog
                    onClose={!loaders.updateCallType && !loaders.deleteCallType && this.handleClose}
                    open={open}
                    maxWidth='md'
                    PaperProps={{ style: { flex: 1 } }}
                >
                    <DialogTitle
                        disableTypography
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            variant='h6'
                        >
                            Edit Call Type
                        </Typography>
                        <IconButton
                            aria-label='close'
                            onClick={this.handleClose}
                            disabled={loaders.updateCallType}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent
                        dividers
                    >
                        <Grid
                            xs={12}
                            spacing={2}
                            container
                        >
                            <GeneralTextField
                                autoFocus={true}
                                width={12}
                                label='Call Type *'
                                placeholder='Enter call type name...'
                                statePath={`callType.callType`}
                                value={callType.callType}
                                error={errors.callType.callType}
                                handleInputChange={handleInputChange}
                            />
                            <CallTypeIconsMenu
                                icon1={callType.icon1}
                                statePathIcon1={'callType.icon1'}
                                icon2={callType.icon2}
                                statePathIcon2={'callType.icon2'}
                                handleInputChange={handleInputChange}
                                errors={errors}
                                swapCallTypeIcons={swapCallTypeIcons}
                            />
                            <GeneralSwitchField
                                width={12}
                                size='normal'
                                label='Is Default'
                                statePath={`callType.default`}
                                value={callType.default}
                                error={errors.callType.default}
                                handleInputChange={handleInputChange}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <div
                            style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}
                        >
                            <Button
                                onClick={this.openConfirmDeletion}
                                variant='contained'
                                color='primary'
                                style={{ backgroundColor: '#ff0000' }}
                            >
                                {loaders.deleteCallType &&
                                    <CircularProgress
                                        size={18}
                                        style={{ marginRight: 8 }}
                                    />
                                }
                                Delete
                            </Button>
                            <Button
                                onClick={this.updateCallType}
                                color='secondary'
                                variant='contained'
                            >
                                {loaders.updateCallType &&
                                    <CircularProgress
                                        size={18}
                                        style={{ marginRight: 8 }}
                                    />
                                }
                                Update
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
                <ActionConfirmation
                    open={confirmDeletion}
                    onClose={this.closeConfirmDeletion}
                    title='Are you sure you want to delete this Call Type?'
                    onConfirmAction={deleteCallType}
                />
            </>
        )
    }
}

export default EditCallTypeModal;
