// React
import React, { createRef } from 'react'

// Material UI
import { Grid, TextField, Typography, IconButton, InputAdornment, withStyles, Tooltip } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import InfoIcon from '@material-ui/icons/Info'

// Utilities
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'
import { TransformToFrontendPhoneNumber } from '../../utilities/Validate'

const CssTextField = TextField


const PhoneNumberMask = (params) => {
    return (
        <MaskedInput
            className={"MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense"}
            mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
            ref={ref => {
                params.inputRef(ref ? ref.inputElement : null)
            }}
            {...params}
        />
    )
}

PhoneNumberMask.propTypes = {
    inputRef: PropTypes.func.isRequired,
}

const renderLabelTooltip = (labelTooltipTitle, labelTooltipIcon) => {
    let icon
    switch (labelTooltipIcon) {
        default:
            icon = <InfoIcon fontSize="small" />
    }
    return (
        <Tooltip
            placement='right-end'
            style={{ marginLeft: 8 }}
            title={labelTooltipTitle}
        >
            {icon}
        </Tooltip>
    )
}

class GeneralTextField extends React.Component {

    constructor() {
        super()
        this.maskRef = null
        this.state = {
            maskFocused: false
        }
        this.autocompleteRef = createRef()
        this.autocomplete = null
    }

    componentDidMount = async () => {
        if (this.props.format === 'googlePlacesAutocomplete')
            this.initGooglePlacesAutocomplete()
    }

    initGooglePlacesAutocomplete = () => {
        this.autocomplete = new window.google.maps.places.Autocomplete(this.autocompleteRef.current, { types: ['address'], componentRestrictions: { country: "us" } })

        this.autocomplete.addListener('place_changed', () => {
            const place = this.autocomplete.getPlace()
            if (this.props.setLocation)
                this.props.setLocation(place)
        })
    }

    handleChange = (e) => {
        let value = e.target.value
        const { statePath, handleInputChange } = this.props

        handleInputChange(this.props, { value, statePath })
    }

    handlePhoneNumberChange = (e) => {
        let value = TransformToFrontendPhoneNumber(e.target.value)
        const { statePath, handleInputChange } = this.props

        handleInputChange(this.props, { value, statePath })
    }

    render() {
        const props = this.props
        const { onSearch, resetSearch } = props

        switch (props.format) {
            case 'phoneNumber':
                return (
                    <Grid
                        item
                        style={props.gridStyle}
                        xs={props.width}
                        display='flex'
                    >
                        {!props.hideLabel &&
                            <Grid
                                style={{ display: 'flex', alignItems: 'flex-start' }}
                            >
                                <Typography
                                    variant='subtitle2'
                                    style={props.labelStyle}
                                    gutterBottom
                                >
                                    {props.label}
                                </Typography>
                                {props.labelTooltipTitle &&
                                    renderLabelTooltip(props.labelTooltipTitle, props.labelTooltipIcon)
                                }
                            </Grid>
                        }

                        <CssTextField
                            id='phoneNumberMask'
                            type={props.type}
                            fullWidth
                            size={props.size ? props.size : 'small'}
                            disabled={props.disabled}
                            variant={!props.variant ? 'outlined' : props.variant}
                            placeholder={props.placeholder}
                            error={props.error ? true : false}
                            value={TransformToFrontendPhoneNumber(props.value) ?? ''}
                            onChange={this.handlePhoneNumberChange}
                            InputProps={{
                                inputComponent: PhoneNumberMask,
                                startAdornment: props.startAdornment,
                            }}
                        />
                        {props.error &&
                            <p
                                style={{ color: '#ff0000', margin: 0, marginTop: 8 }}
                            >
                                {props.error}
                            </p>
                        }
                    </Grid>
                )
            case "search":
                return (
                    <Grid
                        item
                        style={props.gridStyle}
                        xs={props.width}
                        display='flex'
                    >
                        {!props.hideLabel &&
                            <Typography
                                variant='subtitle2'
                                style={props.labelStyle}
                                gutterBottom
                            >
                                {props.label}
                            </Typography>
                        }
                        <CssTextField
                            fullWidth
                            disabled={props.disabled}
                            name={props.name}
                            type={props.type}
                            autoFocus={props.autoFocus}
                            id={props.id}
                            margin={props.margin}
                            placeholder={props.placeholder}
                            size={props.size ? props.size : 'small'}
                            variant={!props.variant ? 'outlined' : props.variant}
                            defaultValue={props.defaultValue}
                            onChange={this.handleChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch?.()
                                }
                            }}
                            onFocus={props.handleInputFocus}
                            value={props.value ?? ''}
                            error={props.error ? true : false}
                            InputLabelProps={props.InputLabelProps}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position='start'
                                    >
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: props.value && (
                                    <IconButton
                                        aria-label="resetSearch"
                                        size="small"
                                        onClick={resetSearch}
                                    >
                                        <ClearIcon size={18} />
                                    </IconButton>
                                )
                            }}
                        />
                    </Grid>
                )
            case 'googlePlacesAutocomplete':
                return (
                    <Grid
                        item
                        xs={props.width}
                        display='flex'
                    >
                        {!props.hideLabel &&
                            <Typography
                                variant='subtitle2'
                                style={props.labelStyle}
                                gutterBottom
                            >
                                {props.label}
                            </Typography>
                        }
                        <CssTextField
                            fullWidth
                            disabled={props.disabled}
                            name={props.name}
                            type={props.type}
                            autoFocus={props.autoFocus}
                            id={props.id}
                            margin={props.margin}
                            placeholder={props.placeholder}
                            size={props.size ? props.size : 'small'}
                            variant={!props.variant ? 'outlined' : props.variant}
                            defaultValue={props.defaultValue}
                            onChange={this.handleChange}
                            onFocus={props.handleInputFocus}
                            value={props.value ?? ''}
                            error={props.error ? true : false}
                            InputLabelProps={props.InputLabelProps}
                            InputProps={{
                                inputRef: this.autocompleteRef,
                                startAdornment: props.startAdornment
                            }}
                        />
                        {props.error &&
                            <p
                                style={{ color: '#ff0000', margin: 0, marginTop: 8 }}
                            >
                                {props.error}
                            </p>
                        }
                    </Grid>
                )

            default:
                return (
                    <Grid
                        item
                        style={props.gridStyle}
                        xs={props.width}
                        display='flex'
                    >
                        {!props.hideLabel &&
                            <Grid
                                style={{ display: 'flex', alignItems: 'flex-start' }}
                            >
                                <Typography
                                    variant='subtitle2'
                                    style={props.labelStyle}
                                    gutterBottom
                                >
                                    {props.label}
                                </Typography>
                                {props.labelTooltipTitle &&
                                    renderLabelTooltip(props.labelTooltipTitle, props.labelTooltipIcon)
                                }
                            </Grid>
                        }
                        <CssTextField
                            fullWidth
                            multiline={props.multiline}
                            minRows={props.row ? props.row : null}
                            disabled={props.disabled}
                            name={props.name}
                            type={props.type}
                            autoFocus={props.autoFocus}
                            id={props.id}
                            margin={props.margin}
                            placeholder={props.placeholder}
                            size={props.size ? props.size : 'small'}
                            variant={!props.variant ? 'outlined' : props.variant}
                            defaultValue={props.defaultValue}
                            onChange={this.handleChange}
                            onFocus={props.handleInputFocus}
                            value={props.value ?? ''}
                            error={props.error ? true : false}
                            InputLabelProps={props.InputLabelProps}
                            InputProps={{
                                startAdornment: props.startAdornment,
                                readOnly: props.readOnly
                            }}
                        />
                        {props.error &&
                            <p
                                style={{ color: '#ff0000', margin: 0, marginTop: 8 }}
                            >
                                {props.error}
                            </p>
                        }
                    </Grid>
                )
        }
    }
}

export default withStyles(null, { withTheme: true })(GeneralTextField)
