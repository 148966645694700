import React, { useEffect, useState } from 'react'
// React Router
import { withRouter } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@material-ui/icons'
// MUI
import {
    TextField,
    Button,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Typography,
    Paper,
    withStyles,
    Grid,
    Container,
    Box,
    InputAdornment,
    CircularProgress,
    IconButton,
    Link,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Checkbox
} from '@material-ui/core'
import axios from 'axios'
import { withSnackbar } from 'notistack'
import { OpenSnackBar } from '../../utilities/handleSnackBar'
import GeneralTextField from '../general/TextField'
import { ValidateAndTransformEmail, ValidateAndTransformToBackendPhoneNumber } from '../../utilities/Validate'
import LogoIcon from '../../assets/logo.png'
import VerificationCodeInput from './verificationCodeInput'
import styles from './styles'
import counties from './countiesList'
import theme from '../../utilities/theme'

const endpointUrl = 'https://ysyj7eq71a.execute-api.us-east-1.amazonaws.com/main';


const AwwSignupForm = ({ showSideBar, enqueueSnackbar, closeSnackbar, classes }) => {
    const [step, setStep] = useState(1)
    const [email, setEmail] = useState('')
    const [emailCode, setEmailCode] = useState(null)
    const [phone, setPhone] = useState('')
    const [phoneCode, setPhoneCode] = useState(null)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [county, setCounty] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [loader, setLoader] = useState(null)
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [openTermsDialog, setOpenTermsDialog] = useState(false)

    useEffect(() => {
        return () => {
            closeSnackbar()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showAlert = (message, severity) => {
        OpenSnackBar(enqueueSnackbar, closeSnackbar, message, severity)
    }

    const sendEmail = async () => {
        if (loader === 'sendEmail') return

        setLoader('sendEmail')
        try {
            const validatedEmail = ValidateAndTransformEmail(email)
            if (validatedEmail) {
                const response = await axios.post(`${endpointUrl}/registerEmail`, { primaryKey: validatedEmail, type: 'email' })
                const { statusCode: status, body } = response.data
                const { message } = JSON.parse(body)

                if (status === 200) {
                    setStep(2)
                    showAlert('Email verification code sent.', 'info')
                } else if (status === 204) {
                    setStep(3)
                    showAlert('Email already verified.', 'info')
                } else {
                    showAlert(message ?? 'Error sending email verification code.', 'error')
                }
            }
            else {
                showAlert('Please enter valid email.', 'error')
            }
        } catch (error) {
            showAlert('Error sending email verification code.', 'error')
        }
        setLoader(null)
    }

    const verifyEmail = async () => {
        if (loader === 'verifyEmail') return

        setLoader('verifyEmail')
        try {
            const response = await axios.post(`${endpointUrl}/verifyEmail`, { primaryKey: ValidateAndTransformEmail(email), type: 'email', code: emailCode })
            const { statusCode: status, body } = response.data
            const { message } = JSON.parse(body)

            if (status === 200) {
                setStep(3)
                showAlert('Email verified successfully.', 'success')
            } else {
                showAlert(message ?? 'Incorrect verification code.', 'error')
            }
        } catch (error) {
            showAlert('Error verifying email.', 'error')
        }
        setLoader(null)
    }

    const resendEmailCode = async () => {
        if (loader === 'resendEmailCode') return

        setLoader('resendEmailCode')
        try {
            const response = await axios.post(`${endpointUrl}/registerEmail`, { primaryKey: ValidateAndTransformEmail(email), type: 'email' })
            const { statusCode: status, body } = response.data
            const { message } = JSON.parse(body)

            if (status === 200) {
                setStep(2)
                showAlert('New email verification code sent.', 'info')
            } else {
                showAlert(message ?? 'Error sending new email verification code.', 'error')
            }
        } catch (error) {
            showAlert('Error sending new email verification code.', 'error')
        }
        setLoader(null)
    }

    const sendPhone = async () => {
        if (loader === 'sendPhone') return

        setLoader('sendPhone')
        try {
            const validatedPhone = ValidateAndTransformToBackendPhoneNumber(phone)
            if (validatedPhone) {
                const response = await axios.post(`${endpointUrl}/registerEmail`, { primaryKey: validatedPhone, type: 'phone' })
                const { statusCode: status, body } = response.data
                const { message } = JSON.parse(body)

                if (status === 200) {
                    setStep(4)
                    showAlert('Phone verification code sent.', 'info')
                } else if (status === 204) {
                    setStep(5)
                    showAlert('Phone number already verified.', 'info')
                } else {
                    showAlert(message ?? 'Error sending phone verification code.', 'error')
                }
            }
            else {
                showAlert('Please enter valid phone number.', 'error')
            }
        } catch (error) {
            showAlert('Error sending phone verification code.', 'error')
        }
        setLoader(null)
    }

    const verifyPhone = async () => {
        if (loader === 'verifyPhone') return

        setLoader('verifyPhone')
        try {
            const response = await axios.post(`${endpointUrl}/verifyEmail`, { primaryKey: ValidateAndTransformToBackendPhoneNumber(phone), type: 'phone', code: phoneCode })
            const { statusCode: status, body } = response.data
            const { message } = JSON.parse(body)

            if (status === 200) {
                setStep(5)
                showAlert('Phone verified successfully.', 'success')
            } else {
                showAlert(message ?? 'Incorrect verification code.', 'error')
            }
        } catch (error) {
            showAlert('Error verifying phone.', 'error')
        }
        setLoader(null)
    }

    const resendPhoneCode = async () => {
        if (loader === 'resendPhoneCode') return

        setLoader('resendPhoneCode')
        try {
            const response = await axios.post(`${endpointUrl}/registerEmail`, { primaryKey: ValidateAndTransformToBackendPhoneNumber(phone), type: 'phone' })
            const { statusCode: status, body } = response.data
            const { message } = JSON.parse(body)

            if (status === 200) {
                showAlert('New phone verification code sent.', 'info')
                setStep(4)
            } else {
                showAlert(message ?? 'Error sending new phone verification code.', 'error')
            }
        } catch (error) {
            showAlert('Error sending new phone verification code.', 'error')
        }
        setLoader(null)
    }

    const submitForm = async () => {
        if (loader === 'submitForm') return

        setLoader('submitForm')
        const validatedEmail = ValidateAndTransformEmail(email)
        const validatedPhone = ValidateAndTransformToBackendPhoneNumber(phone)
        const isPasswordValid = password && password.length > 4 && (password === confirmPassword)

        if (!isPasswordValid) {
            showAlert('Passwords do not match.', 'error')
            setLoader(null)
            return
        }
        try {
            const response = await axios.post(`${endpointUrl}/registerUser`, {
                email: validatedEmail,
                phone: validatedPhone,
                firstName,
                lastName,
                departmentName: county,
                password
            })
            const { statusCode: status, body } = response.data
            const { message } = JSON.parse(body)

            if (status === 200) {
                showAlert('User registered successfully.', 'success')
                setTimeout(() => window.location.href = 'https://www.arkansasweatherwatchers.org/alert-submission', 3000)
            } else {
                showAlert(message ?? 'Error registering user.', 'error')
            }
        } catch (error) {
            showAlert('Error registering user.', 'error')
        }
        setLoader(null)
    }

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Grid container spacing={1} direction="column" alignItems="center" style={{ marginLeft: showSideBar ? -150 : 0 }}>
                <Grid item xs={12} className={classes.logoContainer}>
                    <img src={LogoIcon} alt="FireTEXT" className={classes.logo} />
                    <Typography variant="h1" className={classes.title}>
                        FireTEXT<sup className={classes.subtitle}>®</sup>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <img
                            src={'https://lirp.cdn-website.com/e059e0c6/dms3rep/multi/opt/4-28b69a8d-1920w.png'}
                            alt="arkansas-weather-watchers"
                            className={classes.img}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Paper className={classes.paper}>
                        <form id="AwwsignupForm">
                            {step === 1 && (
                                <div className="form-step" id="step1">
                                    <Typography variant="h5">Enter your E-mail Address</Typography>
                                    <FormControl className={classes.formControl}>
                                        <TextField
                                            label="Email"
                                            type="email"
                                            id="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        onClick={sendEmail}
                                        disabled={loader === 'sendEmail'}
                                    >
                                        {loader === 'sendEmail' &&
                                            <CircularProgress
                                                size={18}
                                                style={{ marginRight: 8 }}
                                            />
                                        }
                                        Send Verification Code
                                    </Button>
                                </div>
                            )}
                            {step === 2 && (
                                <div className="form-step" id="step2">
                                    <Typography variant="h5">Email Verification</Typography>
                                    <div id="emailVerification">
                                        <FormControl className={classes.formControl}>
                                            <VerificationCodeInput
                                                code={emailCode}
                                                setCode={setEmailCode}
                                            />
                                        </FormControl>
                                        <Button
                                            variant="contained"
                                            className={classes.button}
                                            onClick={verifyEmail}
                                            disabled={loader === 'verifyEmail' || loader === 'resendEmailCode'}
                                        >
                                            {loader === 'verifyEmail' &&
                                                <CircularProgress
                                                    size={18}
                                                    style={{ marginRight: 8 }}
                                                />
                                            }
                                            Verify Email
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className={classes.button}
                                            onClick={resendEmailCode}
                                            id="resendEmailCode"
                                            disabled={loader === 'verifyEmail' || loader === 'resendEmailCode'}
                                        >
                                            {loader === 'resendEmailCode' &&
                                                <CircularProgress
                                                    size={18}
                                                    style={{ marginRight: 8 }}
                                                />
                                            }
                                            Resend Code
                                        </Button>
                                    </div>
                                </div>
                            )}

                            {step === 3 && (
                                <div className="form-step" id="step3">
                                    <Typography variant="h5">Enter your Phone Number</Typography>
                                    <FormControl className={classes.formControl}>
                                        <GeneralTextField
                                            width={12}
                                            format='phoneNumber'
                                            label='Phone Number *'
                                            placeholder='Enter phone number...'
                                            statePath={``}
                                            value={phone}
                                            error={null}
                                            handleInputChange={(e, v) => setPhone(v.value)}
                                            startAdornment={<InputAdornment position="start">+1</InputAdornment>}
                                        />
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        onClick={sendPhone}
                                        disabled={loader === 'sendPhone'}
                                    >
                                        {loader === 'sendPhone' &&
                                            <CircularProgress
                                                size={18}
                                                style={{ marginRight: 8 }}
                                            />
                                        }
                                        Send Verification Code
                                    </Button>
                                </div>
                            )}
                            {step === 4 && (
                                <div className="form-step" id="step4">
                                    <Typography variant="h5">Phone Verification</Typography>
                                    <div id="phoneVerification" className={phoneCode ? '' : 'hidden'}>
                                        <FormControl className={classes.formControl}>
                                            <VerificationCodeInput
                                                code={phoneCode}
                                                setCode={setPhoneCode}
                                            />
                                        </FormControl>
                                        <Button
                                            variant="contained"
                                            className={classes.button}
                                            onClick={verifyPhone}
                                            disabled={loader === 'verifyPhone' || loader === 'resendPhoneCode'}
                                        >
                                            {loader === 'verifyPhone' &&
                                                <CircularProgress
                                                    size={18}
                                                    style={{ marginRight: 8 }}
                                                />
                                            }
                                            Verify Phone
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className={classes.button}
                                            onClick={resendPhoneCode}
                                            id="resendPhoneCode"
                                            disabled={loader === 'verifyPhone' || loader === 'resendPhoneCode'}
                                        >
                                            {loader === 'resendPhoneCode' &&
                                                <CircularProgress
                                                    size={18}
                                                    style={{ marginRight: 8 }}
                                                />
                                            }
                                            Resend Code
                                        </Button>
                                    </div>
                                </div>
                            )}

                            {step === 5 && (
                                <>
                                    <div className="form-step" id="step5">
                                        <Typography variant="h5">User Information</Typography>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor="county">County</InputLabel>
                                            <Select
                                                id="county"
                                                value={county}
                                                onChange={(e) => setCounty(e.target.value)}
                                                required
                                            >
                                                {counties.map((county, index) =>
                                                    <MenuItem key={`${county.val}-${index}`} value={county.val}>{county.label}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="First Name"
                                                type="text"
                                                id="firstName"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                required
                                            />
                                        </FormControl>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="Last Name"
                                                type="text"
                                                id="lastName"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                                required
                                            />
                                        </FormControl>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                id="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="Confirm Password"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                id="confirmPassword"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </FormControl>
                                        <Button
                                            variant="contained"
                                            className={classes.button}
                                            onClick={submitForm}
                                            disabled={loader === 'submitForm' || !termsAccepted}
                                        >
                                            {loader === 'submitForm' &&
                                                <CircularProgress
                                                    size={18}
                                                    style={{ marginRight: 8 }}
                                                />
                                            }
                                            Submit
                                        </Button>
                                    </div>
                                    <Box display="flex" alignItems="center" mt={2}>
                                        <Checkbox
                                            checked={termsAccepted}
                                            onChange={e => setTermsAccepted(e.target.checked)}
                                            color="primary"
                                        />
                                        <Typography variant="body2">
                                            I accept the
                                            <Link href="#" style={{ color: theme.palette.secondary.main }} onClick={() => setOpenTermsDialog(true)}>
                                                {" Terms of Service"}
                                            </Link>
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </form>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" className={classes.contactSupport}>
                        Having trouble with registration?{' '}
                        <Link href="https://firetext.net/support" target="_blank" style={{ color: theme.palette.secondary.main }}>
                            Contact support
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            <Dialog open={openTermsDialog} onClose={() => setOpenTermsDialog(false)}>
                <DialogTitle>Terms of Service</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        By subscribing to Arkansas Weather Watchers' alert service, you agree to receive text messages regarding severe weather alerts, updates, and related information. Please read the following terms carefully:
                    </DialogContentText>
                    <DialogContentText>
                        <strong>Service Description:</strong> Arkansas Weather Watchers, in partnership with FireTEXT® Dispatch Solutions, sends alerts via text messages to inform subscribers about severe weather events such as storms, tornado warnings, and other significant weather conditions.
                    </DialogContentText>
                    <DialogContentText>
                        <strong>Message Frequency:</strong> Subscribers may receive multiple messages per day during severe weather events or when critical updates are issued by relevant authorities.
                    </DialogContentText>
                    <DialogContentText>
                        <strong>Message Content:</strong> Text messages may include information about weather alerts, safety instructions, and notifications about Arkansas Weather Watchers' activities.
                    </DialogContentText>
                    <DialogContentText>
                        <strong>Cost:</strong> Standard message and data rates may apply depending on your mobile service provider.
                    </DialogContentText>
                    <DialogContentText>
                        <strong>Opt-Out:</strong> You can opt-out of receiving messages at any time by replying STOP to any message received from Arkansas Weather Watchers. You will no longer receive alerts after opting out, and your subscription will be terminated.
                    </DialogContentText>
                    <DialogContentText>
                        <strong>Privacy:</strong> Your privacy is important to us. Arkansas Weather Watchers nor FireTEXT® Dispatch Solutions will not share your personal information with third parties without your consent, except as required by law.
                    </DialogContentText>
                    <DialogContentText>
                        <strong>Support:</strong> For support or questions regarding the message service, please contact FireTEXT® Dispatch Solutions' support team at support@firetext.net.
                    </DialogContentText>
                    <DialogContentText>
                        By subscribing to our alert service, you acknowledge that you have read, understood, and agree to these terms of service. For more information, visit our website or contact our support team.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenTermsDialog(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Container >
    )
}

export default withRouter(withStyles(styles, { withTheme: true })(withSnackbar(AwwSignupForm)));