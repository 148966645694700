// React
import React from 'react'

// Material UI
import { Grid, Button, Typography, withStyles, CircularProgress } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'

// Components
import DispatchCallModal from './DispatchCallModal'
import CallHistory from './CallHistory'
import GeneralTextField from '../general/TextField'

// Utilities
import axios from 'axios'
import theme from '../../utilities/theme'
import { handleErrors, handleInputChange } from '../../utilities/handleChange'
import DepartmentsMenu from './DepartmentsMenu'
import { withSnackbar } from 'notistack'
import { OpenSnackBar } from '../../utilities/handleSnackBar'
import QuickAccessMapsModal from './QuickAccessMapsModal'
import { CreateNewFiretextAnnouncementModal } from '../settings/firetextAnnouncement/createNewFiretextAnnouncementModal'


const initialState = {
    dispatchModalOpen: false,
    dispatchDepartmentId: null,
    quickAccessMapsModalOpen: false,
    quickAccessMapsDepartmentId: null,
    departments: [],
    filteredDepartments: [],
    firetextAnnouncement: {
        text: '',
        departmentId: null,
        expiresMilli: null,
        sendPushNotification: true,
        sendEmailNotification: false,
    },
    createAnnouncementModalOpen: false,
    loaders: {
        getDepartments: false,
        sendDispatch: false,
        createNewFiretextAnnouncement: false
    },
    info: {
        search: '',
    },
    errors: {
        info: {

        }
    }
}
class Dashboard extends React.Component {
    state = initialState

    componentDidMount = async () => {
        await this.getDepartments()
    }

    componentWillUnmount = () => {
        this.props.closeSnackbar()
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    getDepartments = async () => {
        const { accessToken, user } = this.props
        const { loaders, info } = this.state
        const { search } = info

        if (!loaders.getDepartments) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: true } }))

            const config = {
                headers: { Authorization: accessToken },
                params: {
                    search
                }
            }

            try {
                const getDepartmentsResponse = await axios.get('/api/departments', config)

                let pagination = initialState.info.pagination

                if (getDepartmentsResponse.data.success) {
                    const departmentTypes = []
                    const allCallDepartments = {
                        type: 'All Call',
                        name: 'All Call',
                        departments: [],
                        expanded: true
                    }
                    pagination = getDepartmentsResponse.data.pagination || initialState.info.pagination
                    const filteredDepartments = user.type === 'user' ? this.filterAdministeredDepartments(getDepartmentsResponse.data.departments.filter(dept => dept.featuresPermission.manualDispatches === true)) : getDepartmentsResponse.data.departments.filter(dept => dept.featuresPermission.manualDispatches === true)

                    const departments = filteredDepartments.map(department => {
                        return {
                            ...department,
                            text: `${department.name} ${department.agency ?? ''}`,
                            value: department._id
                        }
                    })

                    const groupedDepartments = filteredDepartments.reduce((acc, department) => {
                        if (department.allCall) {
                            if (acc.allCallDepartments.departments.length > 0) {
                                return {
                                    ...acc,
                                    allCallDepartments: {
                                        ...acc.allCallDepartments,
                                        departments: [...acc.allCallDepartments.departments, department].sort((a, b) => a.name.localeCompare(b.name))
                                    }
                                }
                            } else {
                                return {
                                    ...acc,
                                    allCallDepartments: {
                                        ...acc.allCallDepartments,
                                        departments: [department]
                                    }
                                }
                            }
                        }

                        if (acc.departmentTypes.length > 0) {
                            const existingDepartmentTypeIndex = acc.departmentTypes.findIndex(departmentType => departmentType.type === department.type)

                            if (existingDepartmentTypeIndex === -1) {
                                return {
                                    ...acc,
                                    departmentTypes: [...acc.departmentTypes, {
                                        type: department.type,
                                        name: department.type,
                                        departments: [department],
                                        expanded: true
                                    }]
                                }
                            } else {
                                return {
                                    ...acc,
                                    departmentTypes: acc.departmentTypes.map((departmentType, departmentTypeIndex) => {
                                        if (departmentType.type === department.type) {
                                            return {
                                                ...departmentType,
                                                departments: [...departmentType.departments, department].sort((a, b) => a.name.localeCompare(b.name))
                                            }
                                        }

                                        return departmentType
                                    })
                                }
                            }
                        } else {
                            return {
                                ...acc,
                                departmentTypes: [{
                                    type: department.type,
                                    name: department.type,
                                    departments: [department],
                                    expanded: true
                                }]
                            }
                        }
                    }, { departmentTypes, allCallDepartments })

                    this.setState(prevState => ({
                        allDepartments: departments.sort((a, b) => a.name.localeCompare(b.name)),
                        departments: groupedDepartments.allCallDepartments?.departments?.length ? groupedDepartments.departmentTypes.concat(groupedDepartments.allCallDepartments) : groupedDepartments.departmentTypes,
                        info: { ...prevState.info, pagination },
                        loaders: {
                            ...prevState.loaders,
                            getDepartments: false
                        }
                    }))
                } else {
                    this.setState(prevState => ({
                        departments: [],
                        info: { ...prevState.info, pagination },
                        loaders: {
                            ...prevState.loaders,
                            getDepartments: false
                        }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    departments: [],
                    loaders: {
                        ...prevState.loaders,
                        getDepartments: false
                    }
                }))
            }
        }
    }

    filterAdministeredDepartments = (departments) => {
        const { user } = this.props

        const administeredDepartmentsIds = user?.departments?.filter(department => department.role === 'departmentAdmin').map(department => department.departmentId) || []

        const administeredDepartments = departments.filter(department => administeredDepartmentsIds.includes(department._id))
        return administeredDepartments
    }

    openDispatchModal = (departmentId) => {
        this.setState({ dispatchModalOpen: true, dispatchDepartmentId: departmentId })
    }

    closeDispatchModal = () => {
        this.setState({ dispatchModalOpen: false, dispatchDepartmentId: null })
    }

    openQuickAccessMapsModal = (departmentId) => {
        this.setState({ quickAccessMapsModalOpen: true, quickAccessMapsDepartmentId: departmentId })
    }

    closeQuickAccessMapsModal = () => {
        this.setState({ quickAccessMapsModalOpen: false, quickAccessMapsDepartmentId: null })
    }

    sendDispatch = async (dispatchInformation) => {
        try {
            const { accessToken } = this.props
            const config = {
                headers: {
                    Authorization: accessToken
                }
            }
            if (dispatchInformation.callType === 'none') {
                dispatchInformation.callTypeId = null
            }

            const dispatchFormData = new FormData()

            const { newAttachments, ...dispatch } = dispatchInformation

            for (const key of Object.keys(dispatch)) {
                dispatchFormData.append(key, typeof dispatch[key] === 'object' ? JSON.stringify(dispatch[key]) : dispatch[key])
            }
            if (newAttachments.length > 0) {
                for (const file of newAttachments) {
                    dispatchFormData.append('imageFilesArray', file)
                }
            }
            await axios.post('/api/dispatches', dispatchFormData, config)

        } catch (error) {
            OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
        }
    }

    sendDispatchHandler = async ({ dispatch, singleDepartmentId, multiDepartmentIds }) => {
        const { loaders } = this.state

        if (!loaders.sendDispatch) {
            this.setState(prevState => ({
                loaders: {
                    ...prevState.loaders,
                    sendDispatch: true
                }
            }))

            if (singleDepartmentId) {
                let dispatchInformation = { ...dispatch }
                dispatchInformation.departmentId = singleDepartmentId
                await this.sendDispatch(dispatchInformation)
            }
            else {
                for (const departmentId of multiDepartmentIds) {
                    let dispatchInformation = { ...dispatch }
                    dispatchInformation.departmentId = departmentId
                    await this.sendDispatch(dispatchInformation)
                }
            }

            this.closeDispatchModal()
            this.setState(prevState => ({
                loaders: {
                    ...prevState.loaders,
                    sendDispatch: false
                }
            }))

        }

    }

    handleExpandable = (departmentType) => {
        this.setState(prevState => ({
            departments: prevState.departments.map(department => {
                if (department.type === departmentType.type) {
                    return {
                        ...department,
                        expanded: !department.expanded
                    }
                } else {
                    return department
                }
            })
        }))
    }

    searchDepartments = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page: 1
            }
        }), this.getDepartments)
    }

    resetSearch = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                search: ''
            },
            departments: []
        }), this.getDepartments)
    }

    handleFiretextAnnouncementErrors = () => {
        let errors = { ...this.state.errors }

        let fields = [
            {
                path: 'firetextAnnouncement.text',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'firetextAnnouncement.expiresMilli',
                type: 'number',
                shouldCheck: true
            }
        ]

        let frontEndErrors = handleErrors(this.state, errors, fields)
        return frontEndErrors
    }

    createFiretextAnnouncement = async () => {
        let { loaders, firetextAnnouncement } = this.state

        if (!loaders.createNewFiretextAnnouncement) {

            const { success, errors } = this.handleFiretextAnnouncementErrors()
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, createNewFiretextAnnouncement: true } }))
                const { accessToken, enqueueSnackbar, closeSnackbar } = this.props
                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                try {
                    const createNewFiretextAnnouncementResponse = await axios.post('/api/firetextAnnouncements', firetextAnnouncement, config)
                    if (createNewFiretextAnnouncementResponse && createNewFiretextAnnouncementResponse.data && createNewFiretextAnnouncementResponse.data.success) {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, createNewFiretextAnnouncement: false }
                        }))
                        this.closeCreateFiretextAnnouncementModal()
                        OpenSnackBar(enqueueSnackbar, closeSnackbar, "Announcement added successfully", "success")
                    } else {
                        this.setState(prevState => ({
                            errors: { ...prevState.errors, messages: createNewFiretextAnnouncementResponse.data.errors },
                            loaders: { ...prevState.loaders, createNewFiretextAnnouncement: false }
                        }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, createNewFiretextAnnouncement: false }
                    }))
                    OpenSnackBar(enqueueSnackbar, closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            } else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }
        }
    }

    openCreateFiretextAnnouncementModal = (departmentId) => {
        this.setState({
            createAnnouncementModalOpen: true,
            firetextAnnouncement: {
                ...initialState.firetextAnnouncement,
                departmentId
            }
        })
    }

    closeCreateFiretextAnnouncementModal = () => {
        this.setState({
            createAnnouncementModalOpen: false,
            firetextAnnouncement: initialState.firetextAnnouncement
        })
    }

    render() {
        const { accessToken } = this.props
        const { departments, dispatchModalOpen, dispatchDepartmentId, quickAccessMapsModalOpen, quickAccessMapsDepartmentId, allDepartments, loaders, info, firetextAnnouncement, createAnnouncementModalOpen } = this.state
        const { search } = info
        const quickAccessMapsDepartment = allDepartments?.find(dept => dept._id === quickAccessMapsDepartmentId)
        const createAnnouncementDepartment = allDepartments?.find(dept => dept._id === firetextAnnouncement?.departmentId)

        return (
            <>
                {dispatchModalOpen &&
                    <DispatchCallModal
                        handleClose={this.closeDispatchModal}
                        sendDispatch={this.sendDispatchHandler}
                        open={dispatchModalOpen}
                        departmentId={dispatchDepartmentId}
                        departments={allDepartments}
                        loaders={loaders}
                        accessToken={accessToken}
                    />
                }
                {quickAccessMapsModalOpen &&
                    <QuickAccessMapsModal
                        departmentId={quickAccessMapsDepartmentId}
                        titleText={`${quickAccessMapsDepartment?.name ?? "Department"} Map`}
                        departmentHasWeatherPermissions={quickAccessMapsDepartment?.featuresPermission?.weather ?? false}
                        handleClose={this.closeQuickAccessMapsModal}
                        open={quickAccessMapsModalOpen}
                        accessToken={accessToken}
                    />
                }
                {createAnnouncementModalOpen &&
                    <CreateNewFiretextAnnouncementModal
                        open={createAnnouncementModalOpen}
                        firetextAnnouncement={firetextAnnouncement}
                        propsLoaders={loaders}
                        hasPushNotificationsPermission={createAnnouncementDepartment?.featuresPermission?.pushNotifications}
                        hasEmailNotificationsPermission={createAnnouncementDepartment?.featuresPermission?.emailNotifications}
                        handleInputChange={this.handleInputChange}
                        createFiretextAnnouncement={this.createFiretextAnnouncement}
                        handleClose={this.closeCreateFiretextAnnouncementModal}
                    />

                }
                <Grid
                    container
                    style={{ flex: 1, padding: 0, }}
                >
                    <Grid
                        item
                        xs={9}
                        style={{ padding: 16 }}
                    >
                        <Grid
                            container
                        >
                            <Grid
                                xs={12}
                                item
                                container
                                justifyContent='space-between'
                                style={{ marginBottom: 22, display: 'flex' }}
                            >
                                <Button
                                    variant='contained'
                                    style={{ background: theme.palette.primary.main, color: 'white' }}
                                    startIcon={<AddCircleIcon />}
                                    onClick={() => this.openDispatchModal()}
                                    size='large'
                                >
                                    New Dispatch
                                </Button>
                                <Grid
                                    container
                                    alignItems='center'
                                    style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}
                                >
                                    <GeneralTextField
                                        format="search"
                                        placeholder='Search departments'
                                        statePath={`info.search`}
                                        value={info.search}
                                        handleInputChange={this.handleInputChange}
                                        resetSearch={this.resetSearch}
                                        onSearch={this.searchDepartments}
                                        width={10}
                                        gridStyle={{ margin: 0, padding: 0 }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                style={{ maxHeight: '82vH', width: '100%', paddingRight: 8, overflowY: 'scroll', overflowX: 'hidden' }}
                            >
                                {loaders.getDepartments ?
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", height: '95vh' }}
                                    >
                                        <CircularProgress
                                            size={36}
                                        />
                                    </Grid>
                                    :
                                    <>
                                        {departments.length > 0 ?
                                            <DepartmentsMenu
                                                isSuperAdmin={this.props.user?.type === 'superAdmin'}
                                                filtered={!!info.search} // during search, we should retain favourites and incategory orders
                                                departments={departments}
                                                getDepartments={this.getDepartments}
                                                handleExpandable={this.handleExpandable}
                                                openDispatchModal={this.openDispatchModal}
                                                openQuickAccessMapsModal={this.openQuickAccessMapsModal}
                                                openCreateFiretextAnnouncementModal={this.openCreateFiretextAnnouncementModal}
                                            />
                                            :
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 96 }}
                                            >
                                                <Typography
                                                    variant='body1'
                                                >
                                                    No departments were found.
                                                </Typography>
                                                {search && search.length &&
                                                    <Button
                                                        variant="outlined"
                                                        size="small"
                                                        onClick={() => this.resetSearch()}
                                                    >
                                                        Reset Search
                                                    </Button>
                                                }
                                            </Grid>
                                        }
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                    >
                        <CallHistory
                            accessToken={this.props.accessToken}
                            user={this.props.user}
                            socket={this.props.socket}
                        />
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default withStyles(null, { withTheme: true })(withSnackbar(Dashboard))
