// React
import React, { useEffect } from 'react'
import { Prompt } from "react-router-dom"
// Material UI
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, CircularProgress, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
// Components
import GeneralTextField from '../../general/TextField'


const CreateNewAppVersionModal = (props) => {
    const { handleInputChange, createAppVersion, appVersion, errors, loaders, open } = props

    useEffect(() => {
        const beforeunload = (e) => {
            e.preventDefault()
            e.returnValue = true
        }

        window.addEventListener("beforeunload", beforeunload)
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        }
    }, [])

    const handleClose = () => {
        let shouldCloseModal = window.confirm(
            "Changes you made may not be saved. Are you sure you want to leave this page?"
        )
        if (!shouldCloseModal) return
        else props.handleClose()
    }

    return (
        <>
            <Prompt
                when={true}
                message={() => "Changes you made may not be saved. Are you sure you want to leave this page?"}
            />
            <Dialog
                onClose={!loaders.createNewAppVersion ? handleClose : undefined}
                open={open}
                maxWidth='md'
                PaperProps={{ style: { flex: 1 } }}
            >
                <DialogTitle
                    disableTypography
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        variant='h6'
                    >
                        Add New App Version
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        disabled={loaders.createNewAppVersion}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    dividers
                >
                    <Grid
                        xs={12}
                        spacing={2}
                        container
                    >
                        <GeneralTextField
                            autoFocus={true}
                            width={12}
                            label='Version Number *'
                            placeholder='Enter version number...'
                            statePath={`appVersion.versionNumber`}
                            value={appVersion.versionNumber}
                            error={errors.appVersion.versionNumber}
                            handleInputChange={handleInputChange}
                        />
                        <GeneralTextField
                            multiline={true}
                            autoFocus={true}
                            width={12}
                            label='Release Notes *'
                            placeholder='Enter release notes...'
                            statePath={`appVersion.releaseNotes`}
                            value={appVersion.releaseNotes}
                            error={errors.appVersion.releaseNotes}
                            handleInputChange={handleInputChange}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={createAppVersion}
                        color='secondary'
                        variant='contained'
                    >
                        {loaders.createNewAppVersion &&
                            <CircularProgress
                                size={18}
                                style={{ marginRight: 8 }}
                            />
                        }
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreateNewAppVersionModal;
