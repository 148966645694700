import React, { useCallback, useEffect, useRef, useState } from 'react'
// drag and drop
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import update from 'immutability-helper'
//dnd scrolling
import withScrolling, { createVerticalStrength, createHorizontalStrength } from 'react-dnd-scrolling'
//utils
import { find, findIndex, debounce } from 'underscore'
//custom
import DepartmentTypesBox from './departmentsMenuComponents/DepartmentTypesBox'
import FavouriteDepartmentsBox from './departmentsMenuComponents/FavouriteDepartmentsBox'
import DeepCopy from '../../utilities/deepCopy'

const Scrollzone = withScrolling('div')
const vStrength = createVerticalStrength(150)
const hStrength = createHorizontalStrength(150)


const DepartmentsMenu = (props) => {
    const { departments, getDepartments, handleExpandable, openDispatchModal, openQuickAccessMapsModal, openCreateFiretextAnnouncementModal, isSuperAdmin, filtered } = props

    const [sortedDepartments, setSortedDepartments] = useState([])
    const [favouriteDepartments, setFavouriteDepartments] = useState([])
    const [resetLoader, setReestLoader] = useState(false)

    const usedFavOrDnD = useRef(JSON.parse(localStorage.getItem('usedFavOrDnD')))

    useEffect(() => {
        const departmentsCopy = DeepCopy(departments)
        const { storedFavouriteDepartments, storedSortedDepartments } = getStoredDepartments()

        if (storedFavouriteDepartments) {
            const syncedFavDepts = syncFavouriteDepartmentsFromLocalStorageToBE(storedFavouriteDepartments, departmentsCopy)
            setFavouriteDepartments(syncedFavDepts)
        }

        if (storedSortedDepartments) {
            const syncedSortedDepartments = syncSortedDepartmentsFromLocalStorageToBE(storedSortedDepartments, departmentsCopy)
            setSortedDepartments(syncedSortedDepartments)
        }
        else setSortedDepartments(departmentsCopy)

    }, [departments])

    useEffect(() => {
        if (filtered) return

        localStorage.setItem('storedSortedDepartments', JSON.stringify(sortedDepartments))
    }, [sortedDepartments, filtered])

    useEffect(() => {
        if (filtered) return

        localStorage.setItem('storedFavouriteDepartments', JSON.stringify(favouriteDepartments))
    }, [favouriteDepartments, filtered])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const moveDepartmentTypesBox = useCallback(debounce((dragIndex, hoverIndex) => {
        if (isNaN(dragIndex) || isNaN(hoverIndex)) return

        if (!usedFavOrDnD.current) {
            localStorage.setItem('usedFavOrDnD', true)
            usedFavOrDnD.current = true
        }
        setSortedDepartments((prevDepartments) =>
            update(prevDepartments, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevDepartments[dragIndex]],
                ]
            })
        )
    }, 300), [setSortedDepartments])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const moveDepartment = useCallback(debounce((dragIndex, hoverIndex, deptTypeIdx) => {
        if (isNaN(dragIndex) || isNaN(hoverIndex)) return

        if (!usedFavOrDnD.current) {
            localStorage.setItem('usedFavOrDnD', true)
            usedFavOrDnD.current = true
        }

        setSortedDepartments((prevDepartments) =>
            update(prevDepartments, {
                [deptTypeIdx]: {
                    departments: {
                        $splice: [
                            [dragIndex, 1],
                            [hoverIndex, 0, prevDepartments[deptTypeIdx]?.departments[dragIndex]],
                        ]
                    }
                }
            })
        )
    }, 100), [setSortedDepartments])

    const moveFavouriteDepartment = (dragIndex, hoverIndex) => {
        if (isNaN(dragIndex) || isNaN(hoverIndex)) return

        if (!usedFavOrDnD.current) {
            localStorage.setItem('usedFavOrDnD', true)
            usedFavOrDnD.current = true
        }

        setFavouriteDepartments((prevDepartments) =>
            update(prevDepartments, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevDepartments[dragIndex]],
                ]
            })
        )
    }

    const moveDepartmentToFavourites = (deptTypeIdx, departmentId) => {
        if (isNaN(deptTypeIdx) || !departmentId) return

        if (!usedFavOrDnD.current) {
            localStorage.setItem('usedFavOrDnD', true)
            usedFavOrDnD.current = true
        }

        const checkIfAlreadyExists = findIndex(favouriteDepartments, { _id: departmentId })
        if (isNaN(checkIfAlreadyExists) || checkIfAlreadyExists >= 0) return

        const deptIdx = findIndex(sortedDepartments[deptTypeIdx]?.departments, { _id: departmentId })
        if (isNaN(deptIdx) || deptIdx < 0) return

        const targetDepartment = sortedDepartments[deptTypeIdx]?.departments[deptIdx]

        setFavouriteDepartments([...favouriteDepartments, targetDepartment])
    }

    const deleteDepartmentFromFavourite = (departmentId) => {
        if (!departmentId) return
        setFavouriteDepartments(prevDepartments => prevDepartments.filter(dept => dept._id !== departmentId))
    }

    const resetDragAndDropPreferences = async () => {
        if (!resetLoader) {
            setReestLoader(true)
            localStorage.removeItem('storedSortedDepartments')
            localStorage.removeItem('storedFavouriteDepartments')
            localStorage.removeItem('usedFavOrDnD')
            usedFavOrDnD.current = false
            await getDepartments()
            setReestLoader(false)
        }
    }

    const commonProps = {
        openDispatchModal,
        openQuickAccessMapsModal,
        openCreateFiretextAnnouncementModal,
        isSuperAdmin,
        moveDepartmentToFavourites,
        deleteDepartmentFromFavourite
    }
    return (
        <DndProvider backend={HTML5Backend}>
            <Scrollzone
                verticalStrength={vStrength}
                horizontalStrength={hStrength}
                style={{ width: '100%' }}
            >
                <FavouriteDepartmentsBox
                    favouriteDepartments={favouriteDepartments}
                    showResetBtn={usedFavOrDnD.current}
                    moveFavouriteDepartment={moveFavouriteDepartment}
                    resetDragAndDropPreferences={resetDragAndDropPreferences}
                    {...commonProps}
                />
                {sortedDepartments.map((departmentType, deptTypeIdx) => (
                    <DepartmentTypesBox
                        key={`${departmentType.type}-${deptTypeIdx}`}
                        departmentType={departmentType}
                        deptTypeIdx={deptTypeIdx}
                        handleExpandable={handleExpandable}
                        moveDepartment={moveDepartment}
                        moveDepartmentTypesBox={moveDepartmentTypesBox}
                        {...commonProps}
                    />
                ))}
            </Scrollzone>
        </DndProvider>
    )
}

export default DepartmentsMenu;


const getDepartmentsTypeIndexMap = (departments) => {
    const departmentTypesToIndexMap = {}
    departments.forEach((deptType, idx) => {
        departmentTypesToIndexMap[deptType.type] = idx
    })

    return departmentTypesToIndexMap
}

const getStoredDepartments = () => {
    const storedFavouriteDepartmentsString = localStorage.getItem('storedFavouriteDepartments')
    let storedFavouriteDepartments = null
    if (storedFavouriteDepartmentsString) {
        storedFavouriteDepartments = JSON.parse(storedFavouriteDepartmentsString)
    }

    const storedSortedDepartmentsString = localStorage.getItem('storedSortedDepartments')
    let storedSortedDepartments = null
    if (storedSortedDepartmentsString) {
        storedSortedDepartments = JSON.parse(storedSortedDepartmentsString)
    }

    return { storedFavouriteDepartments, storedSortedDepartments }
}

const syncFavouriteDepartmentsFromLocalStorageToBE = (storedFavouriteDepartments, departments) => {
    const comparedAndExtractedFavouriteDepartments = []
    let departmentTypesToIndexMap = getDepartmentsTypeIndexMap(departments)
    for (const dept of storedFavouriteDepartments) {
        const departmentsForTypeFromBE = departments[departmentTypesToIndexMap[dept.type]]?.departments ?? []
        if (find(departmentsForTypeFromBE, BeDept => BeDept._id === dept._id)) {
            comparedAndExtractedFavouriteDepartments.push(dept)
        }
    }

    return comparedAndExtractedFavouriteDepartments
}

const syncSortedDepartmentsFromLocalStorageToBE = (storedSortedDepartments, departmentsCopy) => {
    const syncedSortedDepartments = []

    for (const deptType of storedSortedDepartments) {
        let departmentTypesToIndexMap = getDepartmentsTypeIndexMap(departmentsCopy)

        const syncedSortedDepartmentsOfIndividualType = []

        const storedDepartmentsOfIndividualType = deptType.departments
        const deptTypeIndex = departmentTypesToIndexMap[deptType.type]
        if (deptTypeIndex >= 0) {
            const departmentType = departmentsCopy[deptTypeIndex]
            const departmentsOfIndividualType = DeepCopy(departmentType?.departments ?? [])

            for (const dept of storedDepartmentsOfIndividualType) {
                const index = findIndex(departmentsOfIndividualType, dpt => dpt._id === dept._id)

                if (index >= 0) {
                    const department = departmentsOfIndividualType.splice(index, 1)
                    syncedSortedDepartmentsOfIndividualType.push({ ...department[0] })
                }
            }

            if (departmentsOfIndividualType.length > 0) {
                syncedSortedDepartmentsOfIndividualType.push(...departmentsOfIndividualType)
            }

            syncedSortedDepartments.push({
                ...departmentType,
                departments: syncedSortedDepartmentsOfIndividualType
            })

            departmentsCopy.splice(deptTypeIndex, 1)
        }
    }
    syncedSortedDepartments.push(...departmentsCopy)

    return syncedSortedDepartments
}