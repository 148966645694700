import React from "react"
import PropTypes from "prop-types"
import { useTheme } from "@material-ui/core/styles"
import TableFooter from "@material-ui/core/TableFooter"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import IconButton from "@material-ui/core/IconButton"
import FirstPageIcon from "@material-ui/icons/FirstPage"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import LastPageIcon from "@material-ui/icons/LastPage"

function TablePaginationActions(props) {
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props

    const handleFirstPageButtonClick = (event) => {
        onPageChange("first")
    }

    const handleBackButtonClick = (event) => {
        onPageChange("prev")
    }

    const handleNextButtonClick = (event) => {
        onPageChange("next")
    }

    const handleLastPageButtonClick = (event) => {
        onPageChange("last")
    }

    return (
        <div
            style={{
                flexShrink: 0,
                marginLeft: 20,
            }}
        >
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    )
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    showAtTop: PropTypes.bool,
    showInGrid: PropTypes.bool
}

function TablePaginationComponent(props) {
    const {
        total: count,
        rowsPerPage,
        page,
        handleChangePage: propsHandleChangePage,
        handleChangeRowsPerPage,
        label,
        showAtTop,
        showInGrid
    } = props

    const handleChangePage = (selectedPage) => {
        switch (selectedPage) {
            case "first":
                propsHandleChangePage(1)
                break
            case "prev":
                propsHandleChangePage(page - 1)
                break
            case "next":
                propsHandleChangePage(page + 1)
                break
            case "last":
                propsHandleChangePage(Math.max(0, Math.ceil(count / rowsPerPage)))
                break
            default:
                propsHandleChangePage(1)
                break
        }
    }

    const PaginationComponent = (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page - 1} // -1 because zero index based page  in MUI
            SelectProps={{
                inputProps: { "aria-label": (label && label.length) ? label : "Rows per page" },
                native: true,
            }}
            labelRowsPerPage={(label && label.length) ? label : "Rows per page"}
            onPageChange={handleChangePage}
            onRowsPerPageChange={(event) =>
                handleChangeRowsPerPage(parseInt(event.target.value, 10))
            }
            ActionsComponent={TablePaginationActions}
            style={{ paddingLeft: "auto", minWidth: "100%" }}
        />
    )
    if (showInGrid === true) {
        return PaginationComponent
    }
    if (showAtTop === true) return (
        <TableHead>
            <TableRow>
                {PaginationComponent}
            </TableRow>
        </TableHead>
    )

    return (
        <TableFooter>
            <TableRow>
                {PaginationComponent}
            </TableRow>
        </TableFooter>
    )
}

export default TablePaginationComponent
