// React
import React from 'react'
// React Router
import { withRouter } from 'react-router-dom'
// Material UI
import { Grid, Typography, Paper, Button, CircularProgress, withStyles } from '@material-ui/core'
import theme from '../../../utilities/theme'
// Utilities
import axios from 'axios'
import { UserRolesMap } from '../../../utilities/Options'


const findDepartment = (departmentObjects, departmentId) => {
	return departmentObjects.find(department => String(department._id) === String(departmentId)) || null
}

const findInvitor = (invitedByObjects, invitedBy) => {
	return invitedByObjects.find(user => String(user._id) === String(invitedBy)) || null
}

class DepartmentInvites extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			user: props.user,
			action: null,
			loaders: {
				departmentInvite: -1
			}
		}
	}


	componentDidUpdate(prevProps, prevState) {
		if (prevProps.user !== this.props.user) {
			this.setState({
				user: this.props.user
			})
		}
	}

	processInvite = async (departmentId, action, departmentIndex) => {
		const { accessToken } = this.props
		const { user } = this.state
		const { loaders } = this.state


		if (loaders.departmentInvite === -1) {
			this.setState(prevState => ({
				action,
				loaders: {
					...prevState.loaders,
					departmentInvite: departmentIndex
				}
			}))

			try {
				const config = {
					headers: {
						Authorization: accessToken
					}
				}
				const body = {
					action,
					departmentId
				}
				await axios.patch(`/api/users/${user._id}/department-invites/`, body, config)

				this.setState(prevState => ({
					user: {
						...prevState.user,
						invites: prevState.user.invites.filter(invite => String(invite?.departmentId) !== String(departmentId))
					},
					action: null,
					loaders: {
						...prevState.loaders,
						departmentInvite: -1
					}
				}))
			} catch (error) {
				this.setState(prevState => ({
					action: null,
					loaders: {
						...prevState.loaders,
						departmentInvite: -1
					}
				}))
			}
		}
	}

	render() {
		const { user, loaders, action } = this.state
		const now = new Date().getTime()

		return (
			<div
				styles={{ flexGrow: 1 }}
			>
				<Grid
					container xs={12}
					style={{ padding: 16 }}
				>
					<Grid item xs={12}>
						<Typography
							variant="h6"
							gutterBottom
						>
							Department Invites
						</Typography>
					</Grid>

					<Grid item xs={12} container spacing={2}>
						{user?.invites?.map((invite, index) => (
							<Grid
								key={invite?.departmentId || index}
								item
								xs={12}
								lg={6}
							>
								{new Date(invite?.expires || null).getTime() > now &&
									<Paper
										key={invite?.departmentId}
										style={{ padding: 16, marginBottom: 16 }}
									>
										<Grid container spacing={2} xs={12}>
											<Grid
												container
												xs={4}
												style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: findDepartment([...(user?.departmentObjects || []), ...(user?.inviteObjects || [])], invite?.departmentId)?.color || theme.palette.primary.main, }}
											>
												<Typography
													style={{ color: theme.palette.primary.light }}
												>
													{findDepartment([...(user?.departmentObjects || []), ...(user?.inviteObjects || [])], invite?.departmentId)?.name || ''}
												</Typography>
											</Grid>

											<Grid item xs={8} container>
												<Grid item xs={12} container direction="column" spacing={2}>
													<Grid item xs>
														<Typography variant="body2" gutterBottom>
															Invited as: {UserRolesMap[invite?.role]}
														</Typography>
														<Typography variant="body2" gutterBottom>
															Invited by: {`${findInvitor(user.invitedByObjects, invite?.invitedBy)?.firstName} ${findInvitor(user.invitedByObjects, invite?.invitedBy)?.lastName}` || ''}
														</Typography>
														<Typography variant="body2" color="textSecondary">
															Expires at: {new Date(invite?.expires).toDateString()}
														</Typography>
													</Grid>
													<Grid item xs={12} container style={{ flex: 1, justifyContent: 'center' }}>
														<Button
															size="medium"
															onClick={() => this.processInvite(invite?.departmentId, 'accept', index)}
															disabled={loaders.departmentInvite !== -1}
														>
															{loaders.departmentInvite === index && action === 'accept' &&
																<CircularProgress size={18}
																	style={{ marginRight: 8 }}
																/>
															}
															Accept
														</Button>
														<Button
															size="medium"
															onClick={() => this.processInvite(invite?.departmentId, 'decline', index)}
															disabled={loaders.departmentInvite !== -1}
														>
															{loaders.departmentInvite === index && action === 'decline' &&
																<CircularProgress size={18}
																	style={{ marginRight: 8 }}
																/>
															}
															Decline
														</Button>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Paper>
								}
							</Grid>
						))}
						{!user?.invites?.length &&
							<Grid item xs={12} container style={{ marginTop: 32, flex: 1, justifyContent: 'center', alightItems: 'center' }}>
								<Typography
									variant='body1'
								>
									No invites were found.
								</Typography>
							</Grid>
						}
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default withRouter(withStyles(null, { withTheme: true })(DepartmentInvites))