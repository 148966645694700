// React
import React from 'react'

// Material UI
import { Grid, Select, withStyles, MenuItem, Typography, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

// Utilities
import _ from 'underscore'

const CssDropdownField = Select

const CssAutocomplete = Autocomplete

class GeneralDropdownField extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inputValue: '',
            multiDropdownValue: [],
            selectFocusCount: 0 // this makes sure that we only focus once on select dropdown.
        }
    }

    componentDidMount = () => {
        const { format, value, options } = this.props
        if (!format) {
            let inputValue = value
            if (typeof value === 'object' && value?.props) {
                inputValue = value.props.value
            }
            if (typeof value === 'object') {
                inputValue = value?.value || ''
            }
            this.setState({ inputValue })
        }
        else if (format === 'autocomplete') {
            this.setState({ inputValue: options?.find(option => option.value === value) || null })
        }
        else if (format === 'multiple') {
            const multiDropdownValue = _.uniq(value?.map(obj => obj.value))
            this.setState({ multiDropdownValue })
        }
    }

    handleChange = (e, value) => {
        const { format } = this.props

        if (!format) {
            if (typeof value === 'object' && value && value.props) {
                value = value.props.value
            }
        }

        let parentValue = value
        if (typeof value === 'object') {
            parentValue = value?.value || ''
        }

        const { statePath, handleInputChange } = this.props

        handleInputChange(this.props, { value: parentValue, statePath })
        this.setState({ inputValue: value })
    }

    handleAutocompleteChange = (e, value) => {
        if (value?.value) {
            let actualValue = value?.value

            const { statePath, handleInputChange } = this.props

            handleInputChange(this.props, { value: actualValue, statePath })
            this.setState({ inputValue: value })
        }
    }

    handleMultiChange = (array) => {
        const { statePath, handleInputChange } = this.props
        let value = _.uniq(array?.map(obj => obj.value))

        handleInputChange(this.props, { value, statePath })
        this.setState({ multiDropdownValue: array })

    }

    handleMultiChangeWithFixedOptions = (array) => {
        const { statePath, handleInputChange, fixedOptions } = this.props
        let value = _.uniq(array.map(obj => obj.value))

        let valueWithFixedOptionsRemoved = value.filter(option => fixedOptions.map(fixedOption => fixedOption.value).indexOf(option) === -1)

        handleInputChange(this.props, { value: valueWithFixedOptionsRemoved, statePath })
        this.setState({ multiDropdownValue: array })
    }

    render() {
        const props = this.props

        if (props?.options) {
            switch (props.format) {
                case 'autocomplete':
                    return (
                        <Grid
                            item
                            style={props.gridStyle}
                            xs={props.width}
                            display='flex'
                        >
                            {!props.hideLabel &&
                                <Typography
                                    variant='subtitle2'
                                    gutterBottom
                                >
                                    {props.label}
                                </Typography>
                            }
                            <CssAutocomplete
                                size={props.size ? props.size : 'small'}
                                autoHighlight={true}
                                autoFocus={props.autoFocus}
                                options={props.options}
                                getOptionLabel={(option) => option.text ?? ''}
                                getOptionSelected={(option, value) => option.value === value?.value}
                                getOptionDisabled={(option) => option.disabled === true}
                                renderOption={option => <>{option.text}</>}
                                value={this.state.inputValue ?? {}}
                                fullWidth
                                onChange={this.handleAutocompleteChange}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            inputRef={props.focusRef}
                                            placeholder={props.placeholder}
                                            size='small'
                                            variant={!props.variant ? 'outlined' : props.variant}
                                            error={props.error ? true : false}
                                            style={{ padding: 0 }}
                                            {...params}
                                        />
                                    )
                                }
                                }
                            />
                            {props.error &&
                                <p
                                    style={{ color: '#ff0000', margin: 0, marginTop: 8 }}
                                >
                                    {props.error}
                                </p>
                            }
                        </Grid>
                    )
                case 'multiple':
                    return (
                        <Grid
                            item
                            style={props.gridStyle}
                            xs={props.width}
                            display='flex'
                        >
                            {!props.hideLabel &&
                                <Typography
                                    variant='subtitle2'
                                    gutterBottom
                                >
                                    {props.label}
                                </Typography>
                            }
                            <CssAutocomplete
                                disabled={props.disabled}
                                autoHighlight={true}
                                id={props.label}
                                multiple
                                size={props.size ? props.size : 'small'}
                                options={props.options}
                                getOptionLabel={(option) => option.text ?? ''}
                                getOptionSelected={(option, value) => option.value === value?.value}
                                getOptionDisabled={(option) => option.disabled === true}
                                fullWidth
                                // filters the value from the object and sets the actual input value that is displayed.
                                onChange={(e, value) => this.handleMultiChange(value)}
                                // defaultValue = { props.value }
                                value={props.value ?? ''}
                                renderInput={params => {
                                    return (
                                        <TextField
                                            {...params}
                                            autoFocus={props.autoFocus}
                                            inputRef={props.focusRef}
                                            placeholder={(this.state.multiDropdownValue && this.state.multiDropdownValue.length > 0) ? '' : props.placeholder}
                                            size='small'
                                            variant={!props.variant ? 'outlined' : props.variant}
                                            error={props.error ? true : false}
                                            style={{ padding: 0 }}
                                        //value = { this.state.multiDropdownValue }
                                        />
                                    )
                                }
                                }
                            />
                            {props.error &&
                                <p
                                    style={{ color: '#ff0000', margin: 0, marginTop: 8 }}
                                >
                                    {props.error}
                                </p>
                            }
                        </Grid>
                    )
                default:
                    return (
                        <Grid
                            item
                            style={props.gridStyle}
                            xs={props.width}
                            display='flex'
                        >
                            {!props.hideLabel &&
                                <Typography
                                    variant='subtitle2'
                                    gutterBottom
                                >
                                    {props.label}
                                </Typography>
                            }
                            <CssDropdownField
                                disabled={props.disabled}
                                autoHighlight={true}
                                inputRef={input => {
                                    if (input?.focus !== null && props.autoFocus === true && this.state.selectFocusCount === 0) {
                                        try {
                                            input.focus()
                                            this.setState({ selectFocusCount: 1 })
                                        } catch (error) { }
                                    }
                                }}
                                autoFocus={props.autoFocus}
                                fullWidth
                                placeholder={props.placeholder}
                                variant={!props.variant ? 'outlined' : props.variant}
                                onChange={this.handleChange}
                                value={this.state.inputValue}
                                error={props.error ? true : false}
                                style={{ height: 40 }}
                            >
                                <MenuItem value='' style={{ display: 'none' }}></MenuItem>
                                {props.options.map(option =>
                                    <MenuItem key={option.value} value={option.value} disabled={option.disabled}> {option.text} </MenuItem>
                                )}
                            </CssDropdownField>
                            {props.error &&
                                <p
                                    style={{ color: '#ff0000', margin: 0, marginTop: 8 }}
                                >
                                    {props.error}
                                </p>
                            }
                        </Grid>
                    )
            }
        }
        else return null
    }
}

export default withStyles(null, { withTheme: true })(GeneralDropdownField)
