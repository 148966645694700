// React
import React from 'react'
// Material UI
import { Grid, TextField, Button, Tabs, Tab, Typography, withStyles, CircularProgress } from '@material-ui/core'
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff'
// Components
import Message from './Message'
import ViewAttachment from '../general/ViewAttachment'
// Utilities
import axios from 'axios'
import moment from 'moment'
import theme from '../../utilities/theme'

const timestampColor = '#d5ecf6'

const initialState = {
    departments: [],
    selectedDepartment: null,
    messages: [],
    message: '',
    sendingMessage: false,
    viewAttachments: false,
    currentAttachmentIndex: 0,
    loaders: {
        getDepartments: false,
        getMessages: false,
        sendMessage: false
    }
}

const StyledTabs = withStyles({
    indicator: {
        display: 'none'
    },
    width: '100%'
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

class Chat extends React.Component {
    state = initialState
    constructor(props) {
        super(props)
        this.msgScrollRef = React.createRef()
        this.currentTimestamp = React.createRef(null)
        this.prevTimestamp = React.createRef(null)
    }

    componentDidMount = async () => {
        await this.getDepartments()
        await this.getMessages()

        this.props.socket.on('newChatMessage', this.onNewChatMessage)
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevState.messages?.length === 0 && this.state.messages?.length > 0)
            || (prevState.loaders.getMessages === true && this.state.loaders.getMessages === false && this.state.messages?.length > 0)
        ) {
            if (this.msgScrollRef.current) {
                this.msgScrollRef.current.scrollIntoView({ behaviour: "smooth" })
            }
        }
    }

    componentWillUnmount = () => {
        this.props.socket.off('newChatMessage', this.onNewChatMessage)
    }

    onNewChatMessage = (message) => {
        const { selectedDepartment } = this.state
        let messageBox = document.getElementById('messageBox')

        if (selectedDepartment._id === message.departmentId) {
            this.setState(prevState => ({ messages: [...prevState.messages, message] }), () => {
                if (messageBox) {
                    messageBox.scrollTop = messageBox.scrollHeight - messageBox.clientHeight
                }
            })
        }
    }

    getDepartments = async () => {
        const { accessToken } = this.props
        const { loaders } = this.state

        if (!loaders.getDepartments) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: true } }))

            const config = {
                headers: { Authorization: accessToken }
            }

            try {
                const getDepartmentsResponse = await axios.get('/api/departments', config)

                if (getDepartmentsResponse.data.success) {
                    const departments = getDepartmentsResponse.data.departments.filter(dept => dept.featuresPermission.chat === true)
                    this.setState(prevState => ({
                        departments,
                        selectedDepartment: departments[0],
                        loaders: {
                            ...prevState.loaders,
                            getDepartments: false
                        }
                    }))
                } else {
                    this.setState(prevState => ({
                        departments: [],
                        loaders: {
                            ...prevState.loaders,
                            getDepartments: false
                        }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    departments: [],
                    loaders: {
                        ...prevState.loaders,
                        getDepartments: false
                    }
                }))
            }
        }
    }

    getMessages = async () => {
        const { accessToken } = this.props
        const { selectedDepartment, loaders } = this.state

        if (!loaders.getMessages) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getMessages: true } }))

            const config = {
                headers: { Authorization: accessToken }
            }

            try {
                const getMessagesResponse = await axios.get(`/api/messaging?departmentId=${selectedDepartment._id}`, config)

                if (getMessagesResponse.data.success) {
                    const messages = getMessagesResponse.data.messages

                    this.currentTimestamp.current = null
                    this.prevTimestamp.current = null

                    this.setState(prevState => ({
                        messages,
                        loaders: {
                            ...prevState.loaders,
                            getMessages: false
                        }
                    }))
                } else {
                    this.setState(prevState => ({
                        messages: [],
                        loaders: {
                            ...prevState.loaders,
                            getMessages: false
                        }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    messages: [],
                    loaders: {
                        ...prevState.loaders,
                        getMessages: false
                    }
                }))
            }
        }
    }

    handleChange = (e) => {
        this.setState({ message: e.target.value })
    }

    handleTabChange = (department) => {
        this.setState({ selectedDepartment: department }, () => this.getMessages())
    }

    sendMessage = () => {
        const { socket } = this.props
        const { selectedDepartment, message } = this.state

        if (!(message?.length > 0)) {
            alert('Type a message to send')
            return
        }
        this.setState(prevState => ({
            ...prevState,
            sendingMessage: true
        }))

        socket.emit(
            'newChatMessage',
            JSON.stringify({ departmentId: selectedDepartment._id, message }),
            (response) => {
                // console.log(response)
                this.setState(prevState => ({
                    ...prevState,
                    sendingMessage: false
                }))
            }
        )
        this.setState({ message: '' })
    }

    getChatDate = (mongoTimestamp) => {
        const formattedDate = moment(mongoTimestamp).local().format("DD MMMM YYYY")
        const today = moment().local().format('DD MMMM YYYY')
        const yesterday = moment().local().subtract(1, 'days').format('DD MMMM YYYY')

        if (formattedDate === today)
            return 'Today'
        else if (formattedDate === yesterday)
            return 'Yesterday'
        else
            return formattedDate
    }

    onViewAttachments = (imagePath) => {
        let attachmentIndex = this.state.messages.filter(msg => msg.imagePath).findIndex(msg => msg.imagePath === imagePath)
        this.setState(prevState => ({
            ...prevState,
            viewAttachments: true,
            currentAttachmentIndex: attachmentIndex !== -1 ? attachmentIndex : 0
        }))
    }

    onCloseAttachments = () => {
        this.setState(prevState => ({
            ...prevState,
            viewAttachments: false
        }))
    }

    render() {
        const { user } = this.props
        const { message, sendingMessage, departments, selectedDepartment, messages, viewAttachments, currentAttachmentIndex, loaders } = this.state

        if (loaders.getDepartments) return (
            <Grid
                item
                xs={12}
                style={{ height: '60vh', alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 24, marginBottom: 24 }}
            >
                <CircularProgress
                    size={36}
                />
            </Grid>
        )

        if (!departments?.length > 0) return (
            <Grid
                container
                style={{ flex: 1, padding: 0 }}
            >
                <Grid
                    item
                    xs={12}
                    style={{ height: '60vh', alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 24, marginBottom: 24 }}
                >
                    <Typography
                        variant='body1'
                    >
                        No departments were found.
                    </Typography>
                </Grid>
            </Grid>
        )

        return (
            <Grid
                container
                style={{ flex: 1, padding: 0 }}
            >
                <Grid
                    container
                    xs={9}
                    style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 50px)', paddingRight: 8 }}
                >
                    <Grid
                        container
                        justifyContent='space-between'
                        alignItems='space-between'
                        style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}
                    >
                        {viewAttachments ?
                            <ViewAttachment
                                attachmentModalOpen={viewAttachments}
                                currentAttachmentIndex={currentAttachmentIndex}
                                allAttachments={messages.filter(msg => msg.imagePath).map(msg => ({ url: msg.imagePath }))}
                                closeAttachmentModal={this.onCloseAttachments}
                            />
                            :
                            null
                        }
                        {loaders.getMessages ?
                            <Grid
                                item
                                xs={12}
                                style={{ alignItems: "center", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 24, marginBottom: 24 }}
                            >
                                <CircularProgress
                                    size={36}
                                />
                            </Grid>
                            :
                            messages && messages.length > 0 ?
                                <Grid
                                    container
                                    xs={12}
                                    id='messageBox'
                                    style={{ flexBasis: 'unset', marginTop: 'auto', overflow: 'auto', padding: 32 }}
                                >
                                    {messages.map((message) => {
                                        this.currentTimestamp.current = this.getChatDate(message.createdAt)

                                        if (this.currentTimestamp.current !== this.prevTimestamp.current) {
                                            this.prevTimestamp.current = this.currentTimestamp.current
                                            return (
                                                <>
                                                    <Grid
                                                        container
                                                        xs={12}
                                                        justifyContent='center'
                                                        alignItems='center'
                                                    >
                                                        <Typography
                                                            style={{ background: timestampColor, color: 'black', fontSize: 13, padding: 4, marginTop: 8, marginBottom: 8, borderRadius: 6 }}
                                                        >
                                                            {this.currentTimestamp.current}
                                                        </Typography>
                                                    </Grid>
                                                    <Message
                                                        key={message._id}
                                                        message={message}
                                                        user={user}
                                                        onViewAttachments={this.onViewAttachments}
                                                    />
                                                </>
                                            )
                                        }
                                        return (
                                            <Message
                                                key={message._id}
                                                message={message}
                                                user={user}
                                                onViewAttachments={this.onViewAttachments}
                                            />
                                        )
                                    })}
                                    <Grid
                                        ref={this.msgScrollRef}
                                        style={{ margin: 0, padding: 0 }}
                                    ></Grid>
                                </Grid>
                                :
                                <Grid
                                    container
                                    direction='column'
                                    xs={12}
                                    justifyContent='center'
                                    alignItems='center'
                                    style={{ flex: 1 }}
                                >
                                    <SpeakerNotesOffIcon
                                        style={{ fontSize: 72, color: theme.palette.primary.grey }}
                                    />
                                    <Typography
                                        style={{ fontSize: 18, color: theme.palette.primary.grey, marginTop: 16 }}
                                    >
                                        There are currently no messages in this department chat.
                                    </Typography>
                                </Grid>
                        }
                        <Grid
                            container
                            spacing={1}
                            xs={12}
                            style={{ flex: 0, padding: 32 }}
                        >
                            <Grid
                                item
                                xs={10}
                            >
                                <TextField
                                    autoFocus={true}
                                    label={sendingMessage ? 'Sending ...' : 'Send a message'}
                                    variant='outlined'
                                    minRowsows={3}
                                    multiline={true}
                                    onChange={this.handleChange}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            this.sendMessage()
                                            e.preventDefault()
                                        }
                                    }}
                                    value={message}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={2}
                                style={{ paddingRight: '0 !important', display: 'flex', flex: 1, alignItems: 'flex-start' }}
                            >
                                <Button
                                    onClick={!sendingMessage ? this.sendMessage : () => null}
                                    color='secondary'
                                    variant='contained'
                                    style={{ width: '100%' }}
                                >
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    xs={3}
                    container
                    style={{ backgroundColor: theme.palette.primary.backgroundGrey, paddingRight: 0, width: '100%', maxHeight: '75vh', overflow: 'auto' }}
                >
                    <StyledTabs
                        orientation='vertical'
                        value={selectedDepartment && selectedDepartment._id ? selectedDepartment._id : null}
                        variant="fullWidth"
                        style={{ width: '100%' }}
                    >
                        {departments && departments.map((department, index) => (
                            <Tab
                                disabled={loaders.getDepartments === true || loaders.getMessages === true || loaders.sendMessage === true}
                                key={department._id}
                                onClick={!sendingMessage ? () => this.handleTabChange(department) : () => null}
                                label={department.name}
                                value={department._id}
                                style={selectedDepartment?._id === department._id ? { textAlign: 'left', alignItems: 'flex-start !important', borderBottom: `1px solid ${theme.palette.primary.grey}`, backgroundColor: theme.palette.secondary.main } : { textAlign: 'left', alignItems: 'flex-start !important', borderBottom: `1px solid ${theme.palette.primary.grey}` }}
                            />
                        ))}
                    </StyledTabs>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(null, { withTheme: true })(Chat)
