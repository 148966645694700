import { forwardRef } from 'react'
// Material UI
import { Grid, Typography, IconButton, Button, Tooltip } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import RemoveIcon from '@material-ui/icons/Remove'
// Components
import GeneralTextField from '../../../general/TextField'
import GeneralDropdownField from '../../../general/DropdownField'
import GeneralCheckboxField from '../../../general/CheckboxField'
// Utilities
import { DispatchDurationOptions } from '../../../../utilities/info'
import { ToneTypes } from '../../../../utilities/Options'
import UpgradeToPremium from '../../../general/upgradeToPremium'

const FirePitchTab = ({
    handleInputChange, handleFloatInputChange,
    department, errors, loaders, addToneField, deleteToneField,
    radioChannels
}, focusRef) => {

    const radioChannelOptions = [
        {
            text: 'None',
            value: ''
        },
        ...radioChannels.map(channel => ({
            text: channel.description,
            value: channel._id
        }))
    ]


    return (
        department.featuresPermission.audioDispatches ?
            <Grid
                xs={12}
                spacing={2}
                container
            >
                <Grid
                    xs={12}
                    container
                    item
                    style={{ marginTop: 16 }}
                >
                    <Typography
                        variant='h6'
                    >
                        Radio Config
                    </Typography>
                </Grid>
                <Grid
                    spacing={2}
                    xs={12}
                    container
                    item
                >
                    <GeneralDropdownField
                        options={radioChannelOptions}
                        width={4}
                        label='Radio Channel'
                        placeholder='Attach radio channel...'
                        statePath={`department.radioChannelId`}
                        value={department.radioChannelId ?? ''}
                        error={errors.department.radioChannelId}
                        handleInputChange={handleInputChange}
                    />
                    <GeneralTextField
                        width={4}
                        label='Dispatcher Radio Frequency'
                        placeholder='Save the frequency at which disptcher radio is set'
                        statePath={`department.dispatcherFrequency`}
                        value={department.dispatcherFrequency}
                        error={errors.department.dispatcherFrequency}
                        handleInputChange={handleInputChange}
                    />

                    <GeneralDropdownField
                        options={DispatchDurationOptions}
                        format='autocomplete'
                        width={4}
                        label='Audio Dispatch Duration*'
                        placeholder='Select dispatch recording duration...'
                        statePath={`department.dispatchDuration`}
                        value={department.dispatchDuration}
                        error={errors.department.dispatchDuration}
                        handleInputChange={handleInputChange}
                    />
                </Grid>
                <Grid
                    xs={12}
                    container
                    item
                    style={{ marginTop: 16 }}
                >
                    <Typography
                        variant='h6'
                    >
                        FirePITCH Status Alerts
                    </Typography>
                </Grid>
                <GeneralCheckboxField
                    gridStyle={{ display: 'flex' }}
                    width={4}
                    size='small'
                    label='Send alerts to Department?'
                    statePath={`department.alertDepartmentForFirepitch`}
                    value={department.alertDepartmentForFirepitch}
                    error={errors.department.alertDepartmentForFirepitch}
                    handleInputChange={handleInputChange}
                />
                {department.alertDepartmentForFirepitch ?
                    <Grid
                        xs={12}
                        container
                        item
                    >
                        <GeneralTextField
                            width={8}
                            format='phoneNumber'
                            label='Phone number for FirePITCH status alerts'
                            placeholder={`Enter a phone number to receive FirePITCH status alerts...`}
                            statePath={`department.alertDepartmentForFirepitchContactNumber`}
                            value={department.alertDepartmentForFirepitchContactNumber}
                            error={errors.department.alertDepartmentForFirepitchContactNumber}
                            handleInputChange={handleInputChange}
                        />
                    </Grid>
                    :
                    null
                }
                <Grid
                    xs={12}
                    container
                    item
                    style={{ marginTop: 16 }}
                >
                    <Typography
                        variant='h6'
                    >
                        Configure Audio Tones
                    </Typography>
                    <Tooltip
                        style={{ marginLeft: 8 }}
                        title='Configure the radio tones that will trigger the recording to send to subscribers in your department directly from dispatch.'
                    >
                        <HelpIcon />
                    </Tooltip>
                </Grid>
                {department.tones.map((tone, index) =>
                    <Grid
                        key={'tones-' + index}
                        xs={12}
                        spacing={2}
                        item
                        container
                    >
                        <GeneralDropdownField
                            autoFocus={index === 0 ? true : false}
                            options={ToneTypes}
                            width={3}
                            label='Tone'
                            hideLabel={index !== 0}
                            placeholder='Enter tone...'
                            statePath={`department.tones.${index}.type`}
                            value={department.tones[index].type}
                            error={errors.department.tones[index].type}
                            handleInputChange={handleInputChange}
                        />
                        <GeneralTextField
                            type="number"
                            width={3}
                            label='Frequency'
                            hideLabel={index !== 0}
                            placeholder='Frequency (Hz)'
                            statePath={`department.tones.${index}.value`}
                            value={department.tones[index].value + ""}
                            error={errors.department.tones[index]?.value}
                            handleInputChange={handleFloatInputChange}
                        />
                        <GeneralTextField
                            type="number"
                            width={3}
                            label='Variance'
                            hideLabel={index !== 0}
                            placeholder='Variance (Hz)'
                            statePath={`department.tones.${index}.variance`}
                            value={department.tones[index].variance + ""}
                            error={errors.department.tones[index]?.variance}
                            handleInputChange={handleFloatInputChange}
                        />
                        <GeneralTextField
                            type="number"
                            width={2}
                            label='Duration'
                            hideLabel={index !== 0}
                            placeholder='Duration (s)'
                            statePath={`department.tones.${index}.length`}
                            value={department.tones[index].length + ""}
                            error={errors.department.tones[index]?.length}
                            handleInputChange={handleFloatInputChange}
                        />
                        <Grid
                            item
                            xs={1}
                            style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
                        >
                            <IconButton
                                aria-label='close'
                                onClick={() => deleteToneField(index)}
                                disabled={loaders.createNewDepartment}
                            >
                                <RemoveIcon style={{ color: '#ff0000' }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                >
                    <Button
                        ref={!department.tones.length ? focusRef : null}
                        onClick={addToneField}
                        disabled={loaders.createNewDepartment}
                        color='primary'
                        variant='outlined'
                    >
                        {!department.tones.length ? 'Add a tone' : 'Add another tone'}
                    </Button>
                </Grid>
            </Grid>
            :
            <Grid
                xs={12}
                spacing={2}
                container
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 32 }}
            >
                <UpgradeToPremium
                    isModal={false}
                    upgradeToPremiumText={'Automated dispatch and digital radio features are only available to premium departments.'}
                />
            </Grid>
    )
}

export default forwardRef(FirePitchTab);
