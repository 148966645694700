import React, { useEffect, useState } from 'react'
import { Typography, IconButton } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { withSnackbar } from 'notistack'
import { OpenSnackBar } from '../../utilities/handleSnackBar'

const TypographyWithCopyText = ({ text, enqueueSnackbar, closeSnackbar, ...props }) => {
    // eslint-disable-next-line no-unused-vars
    const [isCopied, setIsCopied] = useState(false)

    const handleCopy = () => {
        OpenSnackBar(enqueueSnackbar, closeSnackbar, "Copied", "info")
        setIsCopied(true)
    }

    useEffect(() => {

        return () => {
            closeSnackbar()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <CopyToClipboard text={text} onCopy={handleCopy}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <Typography {...props}>
                        {text}
                    </Typography>
                    <IconButton size='small' aria-label="copy" onClick={handleCopy}>
                        <FileCopyIcon />
                    </IconButton>
                </div>
            </CopyToClipboard>
        </>
    )
}

export default (withSnackbar(TypographyWithCopyText));