import { useEffect, useRef } from 'react'

const InactivityReload = () => {
    const inactivityTimeoutRef = useRef(null)

    const handleActivity = () => {
        clearTimeout(inactivityTimeoutRef.current)
        startInactivityTimeout()
    }

    const startInactivityTimeout = () => {
        inactivityTimeoutRef.current = setTimeout(() => {
            // Reload the page
            window.location.reload()
        }, 6 * 60 * 60 * 1000) // 6 hours in milliseconds
    }

    useEffect(() => {
        // Start the inactivity timeout when the component mounts
        startInactivityTimeout()

        // Event listeners for user activity
        document.addEventListener('mousemove', handleActivity)
        document.addEventListener('keydown', handleActivity)

        return () => {
            // Clean up event listeners when the component unmounts
            document.removeEventListener('mousemove', handleActivity)
            document.removeEventListener('keydown', handleActivity)
            clearTimeout(inactivityTimeoutRef.current)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null // Since this component doesn't render anything
}

export default InactivityReload
