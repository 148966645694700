// React
import React, { Component } from 'react'
import { Prompt } from "react-router-dom"
// Material UI
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, CircularProgress, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
// Components
import GeneralTextField from '../../general/TextField'
import GeneralSwitchField from '../../general/SwitchField'
// Utilities
import ActionConfirmation from '../../general/ActionConfirmation'

class EditRadioChannelModal extends Component {
    state = {
        isUnsaved: false,
        confirmDeletion: false
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.beforeunload)
    }

    componentDidUpdate(prevProps, prevState) {
        const { isUnsaved } = this.state
        if (prevProps.radioChannel !== this.props.radioChannel && !isUnsaved) {
            this.setState({ isUnsaved: true })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.beforeunload)
    }

    beforeunload = (e) => {
        const { isUnsaved } = this.state
        if (isUnsaved) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    openConfirmDeletion = () => {
        this.setState({ confirmDeletion: true })
    }

    closeConfirmDeletion = () => {
        this.setState({ confirmDeletion: false })
    }

    handleClose = () => {
        const { isUnsaved } = this.state
        if (isUnsaved) {
            let shouldCloseModal = window.confirm(
                "Changes you made may not be saved. Are you sure you want to leave this page?"
            )
            if (!shouldCloseModal) return
            else this.setState({ isUnsaved: false })
        }
        this.props.handleClose()
    }

    updateRadioChannel = async () => {
        const response = await this.props.updateRadioChannel()
        const { isUnsaved } = this.state
        if (response && isUnsaved) {
            this.setState({ isUnsaved: false })
        }
    }

    render() {
        const { handleInputChange, handleNumberInputChange, deleteRadioChannel, radioChannel, errors, loaders, open } = this.props
        const { isUnsaved, confirmDeletion } = this.state

        return (
            <>
                <Prompt
                    when={isUnsaved}
                    message={() =>
                        "Changes you made may not be saved. Are you sure you want to leave this page?"
                    }
                />
                <Dialog
                    onClose={!loaders.updateRadioChannel && !loaders.deleteRadioChannel ? this.handleClose : undefined}
                    open={open}
                    maxWidth='md'
                    PaperProps={{ style: { flex: 1 } }}
                >
                    <DialogTitle
                        disableTypography
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            variant='h6'
                        >
                            Edit Radio Channel
                        </Typography>
                        <IconButton
                            aria-label='close'
                            onClick={this.handleClose}
                            disabled={loaders.updateRadioChannel}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent
                        dividers
                    >
                        <Grid
                            xs={12}
                            spacing={2}
                            container
                        >
                            <GeneralTextField
                                autoFocus={true}
                                width={12}
                                label='Descriptions *'
                                placeholder='Enter channel description...'
                                statePath={`radioChannel.description`}
                                value={radioChannel.description}
                                error={errors.radioChannel.description}
                                handleInputChange={handleInputChange}
                            />
                            <GeneralTextField
                                type="number"
                                width={12}
                                label='Port *'
                                placeholder='Enter channel port...'
                                statePath={`radioChannel.radioChannelPort`}
                                value={radioChannel.radioChannelPort}
                                error={errors.radioChannel.radioChannelPort}
                                handleInputChange={handleNumberInputChange}
                            />
                            <GeneralSwitchField
                                width={12}
                                size='normal'
                                label='Is Streaming'
                                statePath={`radioChannel.live`}
                                value={radioChannel.live}
                                error={errors.radioChannel.live}
                                handleInputChange={handleInputChange}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <div
                            style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}
                        >
                            <Button
                                onClick={this.openConfirmDeletion}
                                color='primary'
                                variant='contained'
                                style={{ backgroundColor: '#ff0000' }}
                            >
                                {loaders.deleteRadioChannel &&
                                    <CircularProgress
                                        size={18}
                                        style={{ marginRight: 8 }}
                                    />
                                }
                                Delete
                            </Button>
                            <Button
                                onClick={this.updateRadioChannel}
                                color='secondary'
                                variant='contained'
                            >
                                {loaders.updateRadioChannel &&
                                    <CircularProgress
                                        size={18}
                                        style={{ marginRight: 8 }}
                                    />
                                }
                                Update
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
                <ActionConfirmation
                    open={confirmDeletion}
                    onClose={this.closeConfirmDeletion}
                    title='Are you sure you want to delete this channel?'
                    onConfirmAction={deleteRadioChannel}
                />
            </>
        )
    }
}

export default EditRadioChannelModal;
